import { Quote } from '@/models/dto/Quote'
import dayjs from 'dayjs'
import { PricingMethod } from '@/utils/enum'
import { QuoteInformation } from '@/models/QuoteInformation'
import { Stop, Trip, TripEstimation, Vehicle } from '@/models/dto'
import { Pricing } from '@/models/dto/Pricing'
import client from '@/services/quotes'
import { v4 } from 'uuid'
import types from '@/store/modules/types'
import deepClone from './deepClone'
import auth from '@/store/modules/auth'
import { getRecurringAmountDueNow } from './trip'

const TRIP_TYPE_ID_NONE = 0
const TRIP_TYPE_KEY_NONE = 'none'
const TRIP_TYPE_NONE = {
  id: 0,
  key: 'none',
  label: 'None',
  description: null,
}
const TRIP_PAYMENT_TYPE_DOWN_DEPOSIT = 2

export const withPricingMethod = (
  quote: Quote,
  pricingMethod: PricingMethod
): Quote => {
  if (!quote || !quote.trips) {
    return null
  }
  return {
    ...quote,
    trips: quote.trips.map((t) => ({ ...t, pricingMethod })),
  }
}

export const withAllPricingMethods = (quote: Quote): Quote => {
  if (!quote || !quote.trips) {
    return quote
  }
  const trips: any[] = quote.trips
    .map((trip) => ({
      ...trip,
      vehicles: trip.vehicles
        ?.filter((v) => !!v.vehicleTypeId)
        .map((v) => {
          const vehicleTypes = types.getVehicleTypes
          const vehicleType = vehicleTypes.find(
            (vt) => vt.id === v.vehicleTypeId
          )
          return {
            ...v,
            vehicleType,
          }
        }),
    }))
    .flatMap((trip) => [
      { ...trip, pricingMethod: PricingMethod.DAILY_RATE },
      { ...trip, pricingMethod: PricingMethod.HOURLY_RATE },
      { ...trip, pricingMethod: PricingMethod.MILEAGE_RATE },
    ])
  return { ...quote, trips }
}

export const getTripEstimations = (quote: Quote): Promise<TripEstimation[]> => {
  return client.tripEstimations(quote).then((res) => res.data)
}

export const getTripPricing = (quote: Quote): Promise<Pricing> => {
  return client.pricing(withAllPricingMethods(quote)).then((res) => res.data)
}

export const setStopIdsOnQuote = (quote: Quote): Quote => {
  const trips = quote.trips.map((trip) => {
    const stops = trip.stops.map((stop) => ({
      ...stop,
      id: v4(),
    }))
    return {
      ...trip,
      stops,
    }
  })
  return { ...quote, trips }
}

export const orderStopsOnQuote = (quote: Quote): Quote => {
  for (const trip of quote.trips) {
    trip.stops.sort((a, b) => (a.orderIndex > b.orderIndex ? 1 : -1))
  }
  return quote
}

export const setDepositAmountsOnQuote = (quote: Quote): Quote => {
  for (const trip of quote.trips) {
    if (trip.depositAmount == null) {
      trip.depositAmount = getRecurringAmountDueNow(trip)
    }
  }
  return quote
}

export const getQuoteInformation = async (
  quoteId: number
): Promise<QuoteInformation> => {
  const { data } = await client.byId(quoteId)
  let { quote } = data
  quote = setStopIdsOnQuote(quote)
  quote = orderStopsOnQuote(quote)
  quote = prepopulateQuote(quote)
  quote = setDepositAmountsOnQuote(quote)

  let tripEstimationsPromise = null
  let tripPricingPromise = null

  if (shouldFetchTripPricing(quote)) {
    tripPricingPromise = getTripPricing(quote)
    tripEstimationsPromise = getTripEstimations(quote)
  }

  const [q, estimations, pricing] = await Promise.all([
    quote,
    tripEstimationsPromise,
    tripPricingPromise,
  ])
  return { quote: q, estimations, pricing }
}

export const shouldFetchTripPricing = (quote: Quote): boolean => {
  for (const trip of quote.trips) {
    if (
      !trip.stops?.length ||
      trip.passengerCount == null ||
      trip.requiredDrivers == null
    ) {
      return false
    }
    for (const stop of trip.stops) {
      if (!stop.address) {
        return false
      }
    }
  }
  return true
}

// If a trip doesn't have any stops, add two empty stops
// to that trip
// If a trip doesn't have any vehicles, add an empty vehicle
// to that trip
// If a trip has 0 required drivers/passengers, null these
// values out
// If a trip has stops with valid datetime values, set date
// and time values
export const prepopulateQuote = (quote: Quote): Quote => {
  for (const trip of quote.trips) {
    if (!trip.stops.length) {
      trip.stops = [
        new Stop({ orderIndex: 0, pickupDatetime: '' }),
        new Stop({ orderIndex: 1, dropoffDatetime: '' }),
      ]
    }

    if (!trip.vehicles.length) {
      trip.vehicles = [new Vehicle()]
    }

    if (!trip.requiredDrivers) {
      trip.requiredDrivers = null
    }

    if (!trip.passengerCount) {
      trip.passengerCount = null
    }

    trip.stops.map((stop) => {
      const tz = stop?.address?.timeZone || auth.getUserTimeZone
      if (stop.pickupDatetime) {
        stop.pickupTime = dayjs(stop.pickupDatetime).tz(tz).format('HH:mm')
        stop.pickupDate = dayjs(stop.pickupDatetime).tz(tz).format('YYYY-MM-DD')
      }
      if (stop.dropoffDatetime) {
        stop.dropoffTime = dayjs(stop.dropoffDatetime).tz(tz).format('HH:mm')
        stop.dropoffDate = dayjs(stop.dropoffDatetime)
          .tz(tz)
          .format('YYYY-MM-DD')
      }
    })
  }
  return quote
}

// For each trip in the quote:
// - If there are any stops without addresses or vehicles
//   without quantities/vehicle types,
//   remove these items from the array
// - If the trip does not have a passenger count/required drivers, default to 0
// - If trip does not have a trip type, set it to none
export const cleanQuoteForSave = (q: Quote): Quote => {
  const quote = deepClone(q)
  for (const trip of quote.trips) {
    trip.stops = trip.stops
      .filter((stop) => !!stop.address)
      .map((stop, i) => ({ ...stop, orderIndex: i }))
    trip.vehicles = trip.vehicles.filter(
      (v) => v.vehicleTypeId != null && v.quantity > 0
    )
    trip.charges = trip.charges.map((charge) => {
      charge.amount = charge.amount || 0
      return charge
    })
    if (!trip.tripTypeId) {
      trip.tripTypeId = TRIP_TYPE_ID_NONE
      trip.tripTypeKey = TRIP_TYPE_KEY_NONE
      trip.tripType = TRIP_TYPE_NONE
    }

    if (trip.depositAmount < trip.total || trip.depositPercentage < 100) {
      trip.paymentTypeId = TRIP_PAYMENT_TYPE_DOWN_DEPOSIT
    }
    trip.requiredDrivers = trip.requiredDrivers || 0
    trip.passengerCount = trip.passengerCount || 0
  }
  return quote
}

export const validate = (q: Quote): boolean => {
  return validateCustomer(q) && validateTrip(q) && validatePayment(q)
}

export const validateCustomer = (q: Quote): boolean => {
  return !!q.customerId
}

export const validateTrip = (q: Quote): boolean => {
  let isValid = true
  for (const trip of q.trips) {
    const hasTripVehicleCheck = !!trip.vehicles.find((v) => !!v.vehicleTypeId)
    const hasAddressCheck = trip.stops.every((s) => !!s.address)
    if (
      !trip.tripType ||
      trip.tripType?.key === 'none' ||
      !Number(trip.passengerCount) ||
      !Number(trip.requiredDrivers) ||
      !hasTripVehicleCheck ||
      !hasAddressCheck
    ) {
      isValid = false
    }
  }
  return isValid
}

export const validatePayment = (q: Quote): boolean => {
  const activeMethodsCheck = q.paymentMethods.filter(
    ({ isActive }) => isActive
  )?.length

  let dueDateCheck = true
  for (const trip of q.trips) {
    if (trip.depositAmount !== trip.recurringTripTotal && !trip.dueDate) {
      dueDateCheck = false
    }
  }

  return !!activeMethodsCheck && dueDateCheck
}

export const expirationDataType = (typeId: number): 'weeks' | 'days' | null => {
  switch (typeId) {
    case 1:
      return 'days'
    case 2:
      return 'weeks'
    default:
      return null
  }
}
