
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import DocumentUpload from '@/components/DocumentUpload.vue'
import VehicleTypes from '@/components/VehicleTypes.vue'
import EditableAutocompleteAddress from '@/components/EditableAutocompleteAddress.vue'
import DriverInfoDriverPayTabDeleteConfirmation from '@/components/DriverInfoDriverPayTabDeleteConfirmation.vue'
import DriverInfoDriverPayTabSingleRatesForm from '@/components/DriverInfoDriverPayTabSingleRatesForm.vue'
import DriverInfoDriverPayTabFormHeader from '@/components/DriverInfoDriverPayTabFormHeader.vue'
import DriverInfoDriverPayTabSelectVehicleForm from '@/components/DriverInfoDriverPayTabSelectVehicleForm.vue'
import DriverInfoDriverPayTabBulkRatesForm from '@/components/DriverInfoDriverPayTabBulkRatesForm.vue'
import { DriverRate } from '@/models/DriverPay'
import driverPay from '@/services/driverpay'
import { EventBus } from '@/utils/eventBus'

@Component({
  components: {
    DocumentUpload,
    VehicleTypes,
    EditableAutocompleteAddress,
    DriverInfoDriverPayTabSingleRatesForm,
    DriverInfoDriverPayTabFormHeader,
    DriverInfoDriverPayTabSelectVehicleForm,
    DriverInfoDriverPayTabBulkRatesForm,
    DriverInfoDriverPayTabDeleteConfirmation,
  },
})
export default class DriverInfoDriverPayTab extends Vue {
  @Prop({ required: true }) driverIds!: number[]

  @Watch('rates', { deep: true })
  onRatesChange(): void {
    this.isDirty = true
  }

  displaySelectVehicleView = false
  displayDeleteConfirmation = false
  isDirty = false
  loading = false
  selectedRateVehicleTypeId = null

  get bulk(): boolean {
    return this.driverIds.length > 1
  }

  rates: DriverRate[] = []

  getRateByVehicleTypeId(vehicleTypeId: number): DriverRate {
    const rate = this.rates.find(
      (rate) => rate.vehicleType?.id === vehicleTypeId
    )
    return rate
  }

  handleRateDelete(): void {
    this.displayDeleteConfirmation = false
    this.handleRateChange({ delete: true }, this.selectedRateVehicleTypeId)
    this.rates = this.rates.filter((r) => !(!r.id && r.delete))
    this.selectedRateVehicleTypeId = null
  }

  // Get all the vehicleTypeIds in the rates
  get vehicleTypeIds(): number[] {
    return this.rates
      .filter((r) => !r.delete)
      .map((rate) => rate.vehicleType?.id)
  }

  handleRateChange(
    event: { [K in keyof DriverRate]?: DriverRate[K] },
    vehicleTypeId: number
  ): void {
    const rateIndex = this.rates.findIndex(
      (r) => r.vehicleType?.id === vehicleTypeId
    )

    let rate = this.rates[rateIndex]

    rate = { ...rate, ...event }

    this.$set(this.rates, rateIndex, rate)
    this.rates[rateIndex] = rate
  }

  handleSelectedVehiclesChange(event: number[]): void {
    for (const vehicleTypeId of event) {
      const rate: DriverRate = {
        id: null,
        driverId: this.driverIds[0],
        vehicleType: {
          id: vehicleTypeId,
          label: '',
        },
        hourlyRate: 0,
        dailyRate: 0,
        perDiem: 0,
        perTripMinHours: 0,
        perTripMinRate: 0,
        percentageBase: 0,
        percentageTotal: 0,
        overnightMinHours: 0,
        longTripMinHours: 0,
        delete: false,
      }
      this.rates.push(rate)
    }
    this.selectedRateVehicleTypeId = event[0]
    this.displaySelectVehicleView = false
  }

  async loadRates(): Promise<void> {
    this.loading = true
    const res = await driverPay.ratesByDriverId(this.driverIds[0])
    const rates = res.data
    if (!rates.some((r) => r.vehicleTypeId == null)) {
      rates.push({
        id: null,
        driverId: this.driverIds[0],
        vehicleType: {
          id: null,
          label: '',
        },
        hourlyRate: null,
        dailyRate: null,
        perDiem: null,
        perTripMinHours: null,
        perTripMinRate: null,
        percentageBase: null,
        percentageTotal: null,
        overnightMinHours: null,
        longTripMinHours: null,
        delete: false,
      })
    }
    this.rates = rates
    this.$nextTick(() => {
      this.isDirty = false
      this.loading = false
    })
  }

  async submit(): Promise<void> {
    if (!this.isDirty) {
      return
    }

    const ratesPayload = this.rates.map((r) => ({
      ...r,
      vehicleTypeId: r.vehicleType?.id,
    }))

    try {
      await driverPay.updateDriverRates(ratesPayload)
    } catch (e) {
      console.error(e)
    }
  }

  created(): void {
    EventBus.$on('edit-user:submit', this.submit)
  }

  destroyed(): void {
    EventBus.$off('edit-user:submit', this.submit)
  }

  mounted(): void {
    this.loadRates()
  }
}
