
import { Vue, Component, Prop } from 'vue-property-decorator'
import sidebar from '@/store/modules/sidebar'
import assignment from '@/store/modules/assignment'
import { pluralize } from '@/utils/string'

@Component
export default class AssignmentsSidebarHeader extends Vue {
  @Prop({ type: Boolean, default: false }) stats: boolean
  @Prop({ required: false, default: '20px' }) headerFontSize: string

  sidebar = sidebar
  pluralize = pluralize

  get loading(): boolean {
    return !assignment.getSelectedAssignments?.length
  }

  get useResDetailView(): boolean {
    return assignment.getIsNonDefaultView
  }

  get reservationId(): number {
    return assignment.getSelectedReservation?.reservationId
  }

  get managedId(): string {
    return assignment.getSelectedReservation?.managedId
  }

  get passengerCount(): number {
    return assignment.getSelectedReservation?.passengerCount
  }

  get areVehiclesFullyAssigned(): boolean {
    const [assigned, required] = this.vehicleAssignmentString.split('/')
    return assigned === required && required !== '0'
  }

  get vehicleAssignmentString(): string {
    const required = assignment.getSelectedAssignments
    const assigned = required?.filter((a) => !!a.id && a.isConfirmed)
    return `${assigned.length || 0}/${required.length || 0}`
  }

  get areDriversFullyAssigned(): boolean {
    const [assigned, required] = this.driverAssignmentString.split('/')
    return assigned === required && required !== '0'
  }

  get driverAssignmentString(): string {
    const required = assignment.getSelectedAssignments?.flatMap(
      (a) => a.drivers
    )
    const assigned = required?.filter((d) => !!d.id && d.isConfirmed)
    return `${assigned.length || 0}/${required.length || 0}`
  }

  get isGridViewAvailable(): boolean {
    if (!assignment.getSelectedReservation) {
      return false
    }

    return !!assignment.getSelectedReservation.startDate
  }

  close(): void {
    if (!assignment.getIsNonDefaultView) {
      assignment.clearSelected()
    } else {
      assignment.refresh()
    }
    sidebar.pop()
  }

  goToGridView(): void {
    assignment.setPreserveDataOnInit(true)
    this.$router.push({ name: 'assignments' })
  }
}
