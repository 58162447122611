import {
  ApiResult,
  DeactivateReservationsDTO,
  PaymentMethod,
  Reservation,
  ReservationAddChargeRequest,
  ReservationBusifyPayRequest,
  ReservationDetail,
  ReservationDetailCommentPayload,
  ReservationHashResponse,
  ReservationManualPaymentRequest,
  ReservationNotification,
  ReservationProcessPaymentRequest,
  ReservationProcessPaymentResult,
  ReservationReduceChargeRequest,
  ReservationStatusUpdate,
  ReservationTableViewResult,
} from '@/models/dto'

import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters } from '@/models/TableView'
import { apiBaseUrl } from '@/utils/env'
import { PaymentTransaction } from '@/models/dto/PaymentTransaction'
import { ReservationAddRefundRequest } from '@/models/dto/Refund'
import { CustomerBalanceSummary } from '@/models/dto/Customer'
import { TimeSeriesRequest, TimeSeriesResult } from '@/models/dto/Time'
import { InvoiceSettings, InvoiceSettingsResult } from '@/models/dto/Invoice'
import { EventType } from '@/models/EventType'

const httpService: HttpService = new HttpService()

export default {
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<ReservationTableViewResult>> {
    const { sorts, filters, pageSize = 10, page = 1, tz = null } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    if (tz) {
      query += `&actorTimeZone=${tz}`
    }

    query = encodeURI(query)
    const host = apiBaseUrl()
    const url = `https://${host}/tables/reservations?${query}`
    return httpService.get(url)
  },
  export(params: TableViewParameters): any {
    const { sorts, filters, selectedColumns } = params

    let query = ''
    if (sorts) {
      query += sorts
    }
    if (filters) {
      query += query.length ? `&${filters}` : `${filters}`
    }
    if (selectedColumns) {
      query += query.length ? `&${selectedColumns}` : `${selectedColumns}`
    }
    query = encodeURI(query)
    const host = apiBaseUrl()
    const url = `https://${host}/tables/reservations/export?${query}`
    return httpService.get(url, { responseType: 'blob' })
  },
  byId(id: number): Promise<AxiosResponse<Reservation>> {
    const host = apiBaseUrl()
    const url = `https://${host}/reservations/v2/${id}`
    return httpService.get(url)
  },
  byHash(hash: string): Promise<AxiosResponse<ReservationHashResponse>> {
    const host = apiBaseUrl()
    const url = `https://${host}/v2/reservations/hash/${hash}`
    return httpService.get(url)
  },
  addComment(
    payload: ReservationDetailCommentPayload
  ): Promise<AxiosResponse<number>> {
    const host = apiBaseUrl()
    const url = `https://${host}/reservations/${payload.reservation.id}/comments`
    return httpService.post<number, ReservationDetailCommentPayload>(
      url,
      payload
    )
  },
  accept(reservationId: number): Promise<AxiosResponse<ApiResult>> {
    const host = apiBaseUrl()
    const query = '?referralAcceptanceESignature='
    const url = `https://${host}/reservations/acceptReferral/${reservationId}${query}`
    return httpService.get(url)
  },
  reject(
    reservationId: number,
    rejectNote: string
  ): Promise<AxiosResponse<ApiResult>> {
    const host = apiBaseUrl()
    let query = `?notes=${rejectNote}`
    query = encodeURI(query)
    const url = `https://${host}/reservations/rejectReferral/${reservationId}${query}`
    return httpService.get(url)
  },
  createAssignment(payload): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${apiBaseUrl()}/tripAssignments/createAssignment`
    return httpService.post(url, payload)
  },
  createAssignments(
    payload,
    skipReferralSync: boolean
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${apiBaseUrl()}/tripAssignments/createAssignments?skipReferralSync=${skipReferralSync}`
    return httpService.post(url, payload)
  },
  deleteAssignments(
    ids: number[],
    skipReferralSync: boolean
  ): Promise<AxiosResponse<ApiResult>> {
    const payload = { deleteAssignmentIds: ids }
    return httpService.delete(
      `https://${apiBaseUrl()}/tripAssignments/deleteAssignments?skipReferralSync=${skipReferralSync}`,
      { data: payload }
    )
  },
  deleteAssignmentByReservationIds(
    reservationIds: number[]
  ): Promise<AxiosResponse<ApiResult>> {
    const payload = { reservationIds }
    return httpService.delete(
      `https://${apiBaseUrl()}/tripAssignments/reservation/delete`,
      { data: payload }
    )
  },
  updateTrackingLinkStatus(
    reservationDetail: Partial<Reservation>
  ): Promise<AxiosResponse<ReservationDetail>> {
    const url = `https://${apiBaseUrl()}/reservations/updateTrackingLinkStatus`
    return httpService.patch(url, reservationDetail)
  },
  updateReservationManagedId(
    reservationDetail: Partial<Reservation>
  ): Promise<AxiosResponse<ReservationDetail>> {
    const url = `https://${apiBaseUrl()}/reservations/updateReservationManagedId`
    return httpService.patch(url, reservationDetail)
  },
  getActiveReferral(
    parentResId: number
  ): Promise<AxiosResponse<ReservationDetail>> {
    const host = apiBaseUrl()
    const url = `https://${host}/reservations/getActiveReferral/${parentResId}`
    return httpService.get(url)
  },
  getTransactionsForReservation(
    reservationId: number
  ): Promise<AxiosResponse<PaymentTransaction[]>> {
    const url = `https://${apiBaseUrl()}/transaction/summary/${reservationId}?includeVoidedEntries=true`
    return httpService.get(url)
  },
  updateReservationStatus(
    payload: ReservationStatusUpdate
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${apiBaseUrl()}/reservations/updateReservationStatus`
    return httpService.patch(url, payload)
  },
  updateNotificationPreferences(
    payload: Partial<Reservation>
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${apiBaseUrl()}/reservations/updateNotificationPreferences`
    return httpService.patch(url, payload)
  },
  updateReservationReferredBy(
    payload: Partial<Reservation>
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${apiBaseUrl()}/reservations/updateReferredBy`
    return httpService.patch(url, payload)
  },
  updateReservationBookingCustomer(
    reservationId: number,
    customerId: number
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${apiBaseUrl()}/reservations/${reservationId}/updateBookingCustomer`
    return httpService.patch(url, { customerId })
  },
  updateReservationBillingCustomer(
    reservationId: number,
    billingCustomerId: number
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${apiBaseUrl()}/reservations/${reservationId}/updateBillingCustomer`
    return httpService.patch(url, { billingCustomerId })
  },
  updateReservationTripContact(
    reservationId: number,
    tripContactId: number
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${apiBaseUrl()}/reservations/${reservationId}/updateTripContact`
    return httpService.patch(url, { tripContactId })
  },
  updateReservationCustomerAccount(
    reservationId: number,
    customerAccountId: number
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${apiBaseUrl()}/reservations/${reservationId}/updateCustomerAccount`
    return httpService.patch(url, { customerAccountId })
  },
  updateReservationCustomerAccountGroup(
    reservationId: number,
    customerAccountGroupId: number
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${apiBaseUrl()}/reservations/${reservationId}/updateCustomerAccountGroup`
    return httpService.patch(url, { customerAccountGroupId })
  },
  updateReservationEventTypeId(
    reservationId: number,
    eventType: EventType
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${apiBaseUrl()}/reservations/${reservationId}/updateEventType`
    return httpService.patch(url, { eventTypeId: eventType.id })
  },
  downloadItinerary(hash: string): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl('pdf')}/pdf/driver-itinerary/${hash}.pdf`
    return httpService.get(url, { responseType: 'blob' })
  },
  openDriverItineraries(reservationHashes: string[]): void {
    const url = `https://${apiBaseUrl('pdf')}/pdf/driver-itinerary-group.pdf`
    const params = new URLSearchParams({ hashes: reservationHashes.join(',') })
    window.open(`${url}?${params.toString()}`)
  },
  addReservationPayment(
    payload: ReservationProcessPaymentRequest | ReservationManualPaymentRequest
  ): Promise<AxiosResponse<ReservationProcessPaymentResult>> {
    const url = `https://${apiBaseUrl()}/payment/addReservationsPayment`
    return httpService.post(url, payload)
  },
  addReservationPaymentCustomer(
    payload: ReservationProcessPaymentRequest | ReservationManualPaymentRequest,
    customerUserId: number
  ): Promise<AxiosResponse<ReservationProcessPaymentResult>> {
    const url = `https://${apiBaseUrl()}/payment/addReservationsPayment/customer?customerUserId=${customerUserId}`
    return httpService.post(url, payload)
  },
  addReservationRefund(
    payload: ReservationAddRefundRequest
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${apiBaseUrl()}/refund/addReservationRefunds`
    return httpService.post(url, [payload])
  },
  reservationTimeSeriesCustomerData(
    customerId: number,
    payload: TimeSeriesRequest
  ): Promise<AxiosResponse<TimeSeriesResult>> {
    const url = `https://${apiBaseUrl()}/reservations/timeSeries/${customerId}/customer`
    return httpService.post(url, payload)
  },
  reservationCustomerBalanceSummary(
    customerId: number
  ): Promise<AxiosResponse<CustomerBalanceSummary>> {
    const url = `https://${apiBaseUrl()}/reservations/balanceSummary/${customerId}/customer`
    return httpService.get(url)
  },
  reservationTimeSeriesCustomerAccountData(
    customerAccountId: number,
    payload: TimeSeriesRequest
  ): Promise<AxiosResponse<TimeSeriesResult>> {
    const url = `https://${apiBaseUrl()}/reservations/timeSeries/${customerAccountId}/customerAccount`
    return httpService.post(url, payload)
  },
  reservationCustomerAccountBalanceSummary(
    customerAccountId: number
  ): Promise<AxiosResponse<CustomerBalanceSummary>> {
    const url = `https://${apiBaseUrl()}/reservations/balanceSummary/${customerAccountId}/customerAccount`
    return httpService.get(url)
  },
  addReservationCharge(
    payload: ReservationAddChargeRequest
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${apiBaseUrl()}/charge/addReservationCharges`
    return httpService.post(url, [payload])
  },
  reduceReservationCharge(
    payload: ReservationReduceChargeRequest
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${apiBaseUrl()}/charge/reduceReservationCharges`
    return httpService.post(url, [payload])
  },
  resendConfirmation(
    reservationId: number,
    confirmationEmails: string[],
    timezone?: string
  ): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/reservations/${reservationId}/resendConfirmation`
    return httpService.post(url, { confirmationEmails, timezone })
  },
  resendReceipt(
    reservationId: number,
    receiptEmails: string[],
    timezone?: string
  ): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/reservations/${reservationId}/resendReceipt`
    return httpService.post(url, { receiptEmails, timezone })
  },
  sendDriverInfo(
    reservationId: number,
    tos: string[],
    timezone?: string
  ): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/reservations/${reservationId}/sendDriverInfo`
    return httpService.post(url, { tos, timezone })
  },
  sendDriverTripSheet(
    reservationId: number,
    tos: string[],
    timezone?: string
  ): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/reservations/${reservationId}/sendDriverTripSheet`
    return httpService.post(url, { tos, timezone })
  },
  sendDriverAssigned(
    reservationId: number,
    driverIds: number[],
    sendDriverAssignedEmail: boolean,
    sendDriverAppNotification: boolean
  ): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/reservations/${reservationId}/sendDriverAssigned`
    return httpService.post(url, {
      driverIds,
      sendDriverAssignedEmail,
      sendDriverAppNotification,
    })
  },
  getInvoiceSettings(
    reservationId: number
  ): Promise<AxiosResponse<InvoiceSettingsResult>> {
    const url = `https://${apiBaseUrl()}/invoiceSettings/${reservationId}`
    return httpService.get(url)
  },
  createInvoiceSettings(
    reservationId: number,
    payload: InvoiceSettings
  ): Promise<AxiosResponse<InvoiceSettingsResult>> {
    const url = `https://${apiBaseUrl()}/invoiceSettings/${reservationId}`
    return httpService.post(url, payload)
  },
  clearAmountDue(reservationIds: number[]): Promise<AxiosResponse<void>> {
    const url = `https://${apiBaseUrl()}/invoiceSettings/clearAmountDue`
    return httpService.post(url, { reservationIds })
  },
  deactivateReservations(
    payload: DeactivateReservationsDTO
  ): Promise<AxiosResponse<void>> {
    const url = `https://${apiBaseUrl()}/reservations/deactivate`
    return httpService.post(url, payload)
  },
  sendCancellationEmail(
    reservationId: number,
    tos: string[],
    note: string,
    timezone?: string
  ): Promise<AxiosResponse<void>> {
    const url = `https://${apiBaseUrl()}/reservations/${reservationId}/sendCancellationEmail`
    return httpService.post(url, { tos, note, timezone })
  },
  updateReservationNotifications(
    reservationId: number,
    payload: ReservationNotification[]
  ): Promise<AxiosResponse<void>> {
    const url = `https://${apiBaseUrl()}/reservations/notifications/${reservationId}`
    return httpService.patch(url, { notifications: payload })
  },
  offerTripAssignmentsToDrivers(
    reservationId: number,
    userIds: number[]
  ): Promise<AxiosResponse> {
    const payload = { userIds, reservationId }
    const url = `https://${apiBaseUrl()}/tripAssignments/offer`
    return httpService.post(url, payload)
  },
  clearDriverTripAssignmentOffers(
    reservationId: number,
    userIds: number[]
  ): Promise<AxiosResponse> {
    const payload = { userIds, reservationId }
    const url = `https://${apiBaseUrl()}/tripAssignments/clearDrivers`
    return httpService.post(url, payload)
  },
  duplicateReservation(reservationId: number): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/reservations/${reservationId}/duplicate`
    return httpService.post(url, {})
  },
  initializeReservationPayment(
    reservationIds: number[],
    amount: number,
    paymentMethod: PaymentMethod,
    processingFee: number,
    sessionId: string,
    apiKey: string
  ): Promise<any> {
    const payload = {
      reservationIds,
      amount,
      paymentMethod,
      processingFee,
      sessionId,
      apiKey,
    }
    const url = `https://${apiBaseUrl()}/reservations/payment/initialize`
    return httpService.post(url, payload)
  },
  addReservationsPaymentWithBusifyPay(
    payload: ReservationBusifyPayRequest
  ): Promise<any> {
    const url = `https://${apiBaseUrl()}/v2/payment/addReservationsPayment`
    return httpService.post(url, payload)
  },
  submitInvoiceWithBusifyPay(
    payload: ReservationBusifyPayRequest,
    customerUserId: number
  ): Promise<any> {
    const url = `https://${apiBaseUrl()}/v2/payment/addReservationsPayment/customer?customerUserId=${customerUserId}`
    return httpService.post(url, payload)
  },
  getExportPdfUrl(
    reservationHashes: string[],
    companyId: number,
    timeZone: string
  ): string {
    const hashes = reservationHashes.join(',')
    const url = `https://${apiBaseUrl(
      'pdf'
    )}/pdf/reservation/${companyId}/export?hashes=${hashes}&timeZone=${timeZone}`
    return url
  },
}
