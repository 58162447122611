import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { apiBaseUrl } from '@/utils/env'
import { ApiResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  async hasActiveSession(): Promise<boolean> {
    const url = `https://${apiBaseUrl()}/transformationSession/hasActiveSession`
    const response: AxiosResponse<ApiResult> = await httpService.get(url)
    return response.data.successful === true
  },

  async canTransform(params): Promise<boolean> {
    const url = `https://${apiBaseUrl()}/transformationSession/validateTransformation`
    const response = await httpService
      .post(url, params.payload)
      .catch((e) => e.response)
    return response.data.successful === true
  },

  stop(): Promise<unknown> {
    const url = `https://${apiBaseUrl()}/transformationSession/stop`
    return httpService.get(url)
  },

  start(params): Promise<unknown> {
    const url = `https://${apiBaseUrl()}/transformationSession/start`
    return httpService.post(url, params.payload).catch((e) => e.response)
  },
}
