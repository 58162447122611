import { DataTableColumn } from '@/models/DataTableColumn'
import { TableViewFilterValue } from '@/models/TableView'

export const isNotEmptyFilter = (
  value: TableViewFilterValue,
  column: DataTableColumn = null
): boolean => {
  if (column?.filterInterface === 'multi-select') {
    return (
      Array.isArray(value) &&
      value?.length &&
      value?.length !== column.filterDropdownItems?.length
    )
  }

  if (value === -1) {
    return false
  } else if (!value) {
    return false
  } else if (Array.isArray(value) && !value.length) {
    return false
  }
  return true
}

export const getEmptyValueForFilterColumn = (column: DataTableColumn) => {
  switch (column.filterInterface) {
    case 'select':
      return -1
    case 'date-range':
      return []
    case 'price-range':
      return []
    default:
      return undefined
  }
}
