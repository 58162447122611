import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { Company, CompanyResult } from '@/models/dto/Company'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { EventType } from '@/models/EventType'
import { NotificationsSetting } from '@/models/NotificationsSetting'
import { VehicleType } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Make an HTTP GET request to retrieve the companies tableview data for the specified parameters,
   * using the httpService object. The TableViewResult will contain a list of companies matching
   * the parameters as well as metadata about the result, such as the total number of items.
   *
   * @param1 = params: The parameters to use for the table view.
   */
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<Company>>> {
    const { sorts = null, filters = null, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }

    query = encodeURI(query)
    return httpService.get(`https://${apiBaseUrl()}/tables/companies?${query}`)
  },
  /**
   * Retrieves the company data for the specified ID.
   * @param1 = companyId: The ID of the company to retrieve.
   */
  byId(companyId: number): Promise<AxiosResponse<CompanyResult>> {
    const url = `https://${apiBaseUrl()}/companies/${companyId}`
    return httpService.get(url)
  },
  /**
   * Creates a new company with the specified data.
   * @param1 = company: The data for the company to create.
   */
  create(company: Partial<Company>): Promise<AxiosResponse<CompanyResult>> {
    return httpService.post(`https://${apiBaseUrl()}/companies/create`, company)
  },
  /**
   * Updates the company with the specified ID using the provided data.
   * @param1 = companyId: The ID of the company to update.
   * @param2 = company: The data to use for updating the company.
   */
  update(
    companyId: number,
    company: Partial<Company>
  ): Promise<AxiosResponse<string>> {
    return httpService.patch(
      `https://${apiBaseUrl()}/companies/${companyId}`,
      company
    )
  },
  /**
   * Retrieves the photo for the company with the specified ID.
   * @param1 = companyId: The ID of the company to retrieve the photo for.
   */
  getCompanyPhoto(companyId: number): Promise<AxiosResponse> {
    return httpService.get(
      `https://${apiBaseUrl()}/v2/companies/${companyId}/companyPhotos`
    )
  },
  /**
   * Uploads a photo for the company with the specified ID. The response will
   * be a boolean value indicating the result of the upload.
   * @param1 = companyId: The ID of the company to upload the photo for.
   * @param2 = photo: The photo to upload.
   */
  uploadCompanyPhoto(
    companyId: number,
    photo: FormData
  ): Promise<AxiosResponse<boolean>> {
    return httpService.post(
      `https://${apiBaseUrl()}/v2/photos/companies/${companyId}/companyPhotos`,
      photo
    )
  },
  /**
   * Retrieves the branding for the company with the specified ID.
   * @param1 = companyId: The ID of the company to retrieve the branding for.
   */
  getBranding(companyId: number): Promise<AxiosResponse> {
    return httpService.get(
      `https://${apiBaseUrl()}/companies/${companyId}/companyBrandings`
    )
  },
  /**
   * Makes an HTTP PUT request to upload the specified photo as branding for the specified company,
   * using the httpService object. The response will be a boolean indicating whether the upload
   * was successful.
   * @param1 = companyId: The ID of a company.
   * @param2 = type: The type of branding to upload.
   * @param3 = photo: The photo to upload as branding for the specified company.
   */
  uploadBranding(
    companyId: number,
    type: string,
    photo: FormData
  ): Promise<AxiosResponse<boolean>> {
    const url = `https://${apiBaseUrl()}/v2/photos/companies/${companyId}/companyBrandings/${type}`
    return httpService.put(url, photo)
  },
  /**
   * Makes a GET request to fetch the event types for the specified company.
   * @param1 = companyId: The ID of a company.
   */
  getCompanyEventTypes(companyId: number): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/eventTypes/${companyId}`
    return httpService.get(url)
  },
  /**
   * Makes a PATCH request to update the event types for the specified company.
   * @param1 = companyId: The ID of a company.
   * @param2 = eventTypes: An array of event types to update for the specified company.
   */
  updateEventTypes(
    companyId: number,
    eventTypes: EventType[]
  ): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/eventTypes/${companyId}`
    const request = { eventTypes }
    return httpService.patch(url, request)
  },
  /**
   * Makes a GET request to fetch the vehicle types for the specified company.
   * @param1 = companyId: The ID of a company.
   */
  getCompanyVehicleTypes(companyId: number): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/vehicleTypes/${companyId}`
    return httpService.get(url)
  },
  /**
   * Makes a PATCH request to update the vehicle types for the specified company.
   * @param1 = companyId: The ID of a company.
   * @param2 = vehicleTypes: An array of vehicle types to update for the specified company.
   */
  updateVehicleTypes(
    companyId: number,
    vehicleTypes: VehicleType[]
  ): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/vehicleTypes/${companyId}`
    const request = { vehicleTypes }
    return httpService.patch(url, request)
  },
  updateNotificationsSetting(
    companyId: number,
    notificationsSetting: NotificationsSetting
  ): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/notifications/companies/${companyId}`
    return httpService.patch(url, { ...notificationsSetting })
  },
}
