
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Action } from '@/models/dto/Action'

@Component({})
export default class CUAction<T extends Action> extends Vue {
  @Prop({ required: true }) readonly label!: string
  @Prop({ required: true }) readonly actions!: T[]
  @Prop({ required: false, default: 14 }) readonly size!: number
  @Prop({ required: false, default: 24 }) readonly iconSize!: number | string
  @Prop({ required: false, default: 'black' }) readonly color!: string
  @Prop({ required: false, default: '' }) readonly iconColor!: string
  @Prop({ required: false, default: 'regular' }) readonly labelWeight!: string
  @Prop({ required: false, default: null }) readonly maxWidth!: string
  @Prop({ required: false, default: null }) readonly width!: string
  @Prop({ type: Boolean, default: false }) readonly filled!: boolean
  @Prop({ type: Boolean, default: false }) readonly hideIcon!: boolean
  @Prop({ required: false, default: '' }) readonly itemClass!: string
}
