import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import cacheStore from '@/store/modules/localCache'
import { CacheEntry } from '@/models/cache'

export class HttpService {
  cacheStore = cacheStore
  post<T, P>(
    url: string,
    payload: P,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    cacheStore.clearCache()
    return axios.post<P, AxiosResponse<T>>(url, payload, config)
  }

  patch<T, P>(
    url: string,
    payload: P,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    cacheStore.clearCache()
    return axios.patch<P, AxiosResponse<T>>(url, payload, config)
  }

  put<T, P>(
    url: string,
    payload: P,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    cacheStore.clearCache()
    return axios.put<P, AxiosResponse<T>>(url, payload, config)
  }

  async get<T, P>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    cacheStore.loadFromCache<T>(url)
    const { response: cachedResponse } =
      (cacheStore.entries[url] as CacheEntry<T>) || {}

    if (cachedResponse !== undefined) {
      return Promise.resolve(cachedResponse)
    }

    const response = await axios.get<P, AxiosResponse<T>>(url, config)
    if (response.status === 200) {
      cacheStore.updateCache<T>({ key: url, response })
    }

    return response
  }

  delete<T, P>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    cacheStore.clearCache()
    return axios.delete<P, AxiosResponse<T>>(url, config)
  }
}
