
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import colors from '@/scss/_colors-export.scss'
import { countries, defaultMask } from '@/data/countries'
import Country from '@/models/dto/Country'
import { Rule } from '@/models/Rule'

@Component
export default class CUPhoneField extends Vue {
  @Prop({ required: false, default: null }) value: string
  @Prop({ required: false, default: null }) countryKey: string
  @Prop({ required: false, default: () => [] }) rules: Rule[]
  @Prop({ type: Boolean, default: false }) defaultUsa: boolean
  @Prop({ type: Boolean, default: false }) disabled: boolean

  open = false
  countries = countries
  searchTerm = ''
  selectedCountry = null

  @Watch('countryKey', { immediate: true })
  handleUpdateCode(countryKey: string): void {
    if (!this.countryKey && this.defaultUsa) {
      this.selectedCountry = this.countries.find(
        (c) => c.key === 'united_states'
      )
    } else {
      this.selectedCountry = this.countries.find((c) => c.key === countryKey)
    }
  }

  get selectedCountryKey(): string {
    return this.selectedCountry?.key
  }

  get selectedCountryCode(): string {
    return this.selectedCountry?.code
  }

  get selectedCountryFlag(): string {
    return this.selectedCountry?.flag || ''
  }

  get selectedCountryMask(): string[] {
    return [
      defaultMask,
      ...(this.selectedCountry?.mask ? this.selectedCountry.mask : []),
    ]
  }

  get borderColor(): string {
    return this.open ? colors['primary'] : colors['gray-border-mid']
  }

  get filteredCountries(): any[] {
    return this.countries.filter(
      (c) =>
        c.name.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
        c.code.includes(this.searchTerm)
    )
  }

  handleSelectCountry(country: Country): void {
    this.selectedCountry = country
    this.$emit('input', { phone: this.value, country: country.key })
    this.open = false
  }

  handlePhoneChange(phone: string): void {
    this.$emit('input', { phone, country: this.selectedCountry.key })
  }

  handleClickAvatar(): void {
    if (!this.disabled) {
      this.open = true
    }
  }
}
