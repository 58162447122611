
import { Vue, Component, Prop } from 'vue-property-decorator'
import types from '@/store/modules/types'
import { Type, VehicleType } from '@/models/dto'
@Component({})
export default class DriverInfoDriverPayTabSelectVehicleForm extends Vue {
  @Prop({ required: true }) selectedVehicleIds!: number[]

  vehicleTypeIdsToAdd: number[] = []

  get vehicleTypes(): VehicleType[] {
    return types.getVehicleTypes
      .filter((v) => v.id < 99) // Remove the unassigned type
      .filter((v) => !this.selectedVehicleIds.includes(v.id)) // We can't create a rate if it already exists
  }

  isVehicleSelected(vehicle: VehicleType): boolean {
    return this.selectedVehicleIds.includes(vehicle.id)
  }

  handleCheckboxChange(vehicle: VehicleType, value: boolean): void {
    this.vehicleTypeIdsToAdd = this.vehicleTypeIdsToAdd.filter(
      (id) => id !== vehicle.id
    )

    if (value) {
      this.vehicleTypeIdsToAdd.push(vehicle.id)
    }
  }

  handleCreate(): void {
    this.$emit('change', this.vehicleTypeIdsToAdd)
  }
}
