import op from 'simple-object-path'
import { distinctBy, mergeBy } from '@/utils/reducers'

export const removeItem = <T>(arr: Array<T>, value: T): Array<T> => {
  const index = arr.indexOf(value)
  if (index > -1) {
    arr.splice(index, 1)
  }
  return arr
}

export const nextID = <T>(xs: T[], key = 'id'): number => {
  return xs
    ? 1 + xs.map((x) => op(x, key)).reduce((x, y) => Math.max(x, y), 0)
    : 1
}

export const uniqueBy = <T>(xs: T[], key = 'id'): T[] => {
  return !xs ? [] : Object.values(xs.reduce(distinctBy(key), {}))
}

export const consolidateBy = <T>(
  xs: T[],
  key = 'id',
  // eslint-disable-next-line no-unused-vars
  mergeFn: (x: T, y: T) => T
): T[] => {
  return !xs ? [] : Object.values(xs.reduce(mergeBy(key, mergeFn), {}))
}

export const upsert = <T>(
  xs: T[],
  x: T,
  // eslint-disable-next-line no-unused-vars
  predicate: (t: T) => boolean
): T[] => {
  if (!xs || !x || !predicate) {
    return []
  }
  const idx = xs.findIndex(predicate)
  if (idx > -1) {
    const result = [...xs]
    result.splice(idx, 1, x)
    return result
  }
  return [...xs, x]
}

export const collapseWithCount = <T>(arr: T[]): T[] => {
  return Array.from(
    arr.reduce((map, item) => {
      const key = JSON.stringify(item)
      if (map.has(key)) {
        const existingItem = map.get(key)
        existingItem.count++
        Object.keys(item).map((prop) => {
          if (Array.isArray(item[prop]) && Array.isArray(existingItem[prop])) {
            existingItem[prop] = existingItem[prop].concat(item[prop])
          }
        })
      } else {
        map.set(key, { ...item, count: 1 })
      }
      return map
    }, new Map())
  ).map(([, obj]) => obj)
}
