import { Action, Module, VuexModule } from 'vuex-class-modules'

import store from '@/store/index'
import {
  CacheEntry,
  CacheUpdateProps,
  CacheSettingsType,
  CacheExpirySettings,
} from '@/models/cache'

const ONE_HOUR = 3600000
const TEN_MINUTES = 600000
const ONE_MINUTE = 60000
const CacheExpiry: CacheExpirySettings[] = [
  {
    regex: '/types\\/vehicle-types$',
    expiry: ONE_HOUR,
    persist: true,
  },
  {
    regex: '/tables\\/vehicleTypes\\?page=1&pageSize=-1$',
    expiry: ONE_HOUR,
    persist: true,
  },
  {
    regex: '/types\\/vehicle-types\\?enabledSyncOnly=false$',
    expiry: ONE_HOUR,
    persist: true,
  },
  {
    regex: '/types\\/charge-types$',
    expiry: TEN_MINUTES,
  },
  {
    regex: '/types\\/pricing-selection$',
    expiry: ONE_HOUR,
    persist: true,
  },
  {
    regex: '/user\\/\\d+$',
    expiry: TEN_MINUTES,
  },
  {
    regex: '/userProfile$',
    expiry: TEN_MINUTES,
  },
  {
    regex: '/tables\\/systemParameters\\?page=1&pageSize=-1$',
    expiry: TEN_MINUTES,
    persist: true,
  },
  {
    regex: '/companies\\/\\d+$',
    expiry: TEN_MINUTES,
  },
  {
    regex: '/user\\/createdBy$',
    expiry: ONE_MINUTE,
  },
  {
    regex: '/user\\/sentBy$',
    expiry: ONE_MINUTE,
  },
  {
    regex: '/savedViews\\/reservations_table_view\\/\\d+/\\d+$',
    expiry: TEN_MINUTES,
  },
  {
    regex: '/savedViews\\/quotes_table_view\\/\\d+/\\d+$',
    expiry: TEN_MINUTES,
  },
]

const CacheSettings: CacheSettingsType = {
  cacheExpiry: CacheExpiry,
  maxCacheEntries: 25,
}

/**
 * A module for caching API responses in local storage and in memory.
 */
@Module({ generateMutationSetters: true })
class LocalCache extends VuexModule {
  _cache: Record<string, CacheEntry<unknown>> = {}

  get entries(): Record<string, CacheEntry<unknown>> {
    return this._cache
  }

  @Action
  loadFromCache<T>(key: string): void {
    const { expiry } = this.getCacheInfo(key)
    if (expiry == null) {
      return
    }

    const memoizedEntry = this._cache[key] as CacheEntry<T> | undefined
    if (memoizedEntry) {
      const { timestamp } = memoizedEntry
      if (Date.now() - timestamp < expiry) {
        return
      }
    }

    const localEntry = localStorage.getItem(key)
    if (localEntry) {
      const { response, timestamp, persist } = JSON.parse(
        localEntry
      ) as CacheEntry<T>
      if (Date.now() - timestamp < expiry) {
        this._cache[key] = { response, timestamp, persist }
        return
      }
    }
  }

  @Action
  updateCache<T>(cacheUpdate: CacheUpdateProps<T>): void {
    const { expiry, persist } = this.getCacheInfo(cacheUpdate.key)
    if (expiry == null) {
      return
    }

    const { key, response } = cacheUpdate
    this._cache[key] = {
      response,
      persist,
      timestamp: Date.now(),
    }
    this.evictCacheIfNeeded()
    localStorage.setItem(key, JSON.stringify(this._cache[key]))
  }

  @Action
  clearCache(): void {
    for (const key of Object.keys(this._cache)) {
      const { persist } = this._cache[key]
      if (!persist) {
        localStorage.removeItem(key)
      }
    }

    this._cache = {}
  }

  private evictCacheIfNeeded(): void {
    const keys = Object.keys(this._cache)
    if (keys.length > CacheSettings.maxCacheEntries) {
      const oldestKey = keys.reduce((a, b) => {
        return this._cache[a].timestamp < this._cache[b].timestamp ? a : b
      })

      delete this._cache[oldestKey]
      localStorage.removeItem(oldestKey)
    }
  }

  private getCacheInfo(link: string): Partial<CacheExpirySettings> {
    for (const cacheExpirySetting of CacheSettings.cacheExpiry) {
      if (RegExp(cacheExpirySetting.regex).test(link)) {
        return {
          expiry: cacheExpirySetting.expiry,
          persist: cacheExpirySetting.persist,
        }
      }
    }

    return { expiry: undefined, persist: false }
  }
}

export default new LocalCache({ store, name: 'cache' })
