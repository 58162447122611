import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { Address, Reservation, ReservationDetailStop, Stop } from '@/models/dto'
import { anyNumberPattern, stateAbbreviationPattern } from './regex'
import plural from 'pluralize'
import { countries } from '@/data/countries'

dayjs.extend(utc)
dayjs.extend(timezone)

export const toKebab = (string: string): string => {
  return string
    .split('')
    .map((letter) => {
      if (/[A-Z]/.test(letter)) {
        return ` ${letter.toLowerCase()}`
      }
      return letter
    })
    .join('')
    .trim()
    .replace(/[_\s]+/g, '-')
}

export const toCamel = (string: string): string => {
  return toKebab(string)
    .split('-')
    .map((word, index) => {
      if (index === 0) {
        return word
      }
      return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase()
    })
    .join('')
}

export const toPascal = (string: string): string => {
  const interim = toCamel(string)
  return interim.slice(0, 1).toUpperCase() + interim.slice(1)
}

export const toTitle = (string: string): string => {
  return toKebab(string)
    .split('-')
    .map((word) => {
      return word.slice(0, 1).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

export const toSentence = (string: string): string => {
  const interim = toKebab(string).replace(/-/g, ' ')
  return interim.slice(0, 1).toUpperCase() + interim.slice(1)
}

export const toSnake = (string: string): string => {
  return toKebab(string).replace('-', '_')
}

export const pluralize = (
  count: number,
  noun: string,
  suffix = 's'
): string => {
  if (
    noun.endsWith('s') ||
    noun.endsWith('x') ||
    noun.endsWith('z') ||
    noun.endsWith('ch') ||
    noun.endsWith('sh')
  ) {
    suffix = 'es'
    return `${noun}${count !== 1 ? suffix : ''}`
  }

  return `${noun}${count !== 1 ? suffix : ''}`
}

export const percentageFormatter = (f: number, truncate = 2): string =>
  f || f === 0 ? `${f.toFixed(truncate)}%` : ''

export const currencyFilter = (input: number, showSign?: boolean): string => {
  if (isNaN(input)) {
    input = 0
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
  })
  const sign = !!showSign && input > 0 ? '+' : ''
  return `${sign}${formatter.format(input)}`
}

export const currencyFilterHideCents = (input: number): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
  })
  const amount = formatter.format(input)
  return `${amount.split('.')[0]}`
}

export const roundedCurrencyFilter = (input: number): string => {
  return `${currencyFilter(Math.round(input)).split('.')[0]}`
}

export const phoneFormatFilter = (input: string): string => {
  if (!input) {
    return ''
  }

  const inp = input.replace(/[^0-9]/gi, '')

  if (input[0] === '1' && input.length > 10) {
    return `(${inp.substring(1, 4)}) ${inp.substring(4, 7)}-${inp.substring(7)}`
  }

  return `(${inp.substring(0, 3)}) ${inp.substring(3, 6)}-${inp.substring(6)}`
}

export const countryPhoneFormatFilter = (
  input: string,
  countryKey = null
): string => {
  if (!input) {
    return ''
  }
  const c = countries.find((c) => c.key === countryKey)
  const inp = input.replace(/[^0-9]/gi, '')

  if (countryKey === 'united_states') {
    return phoneFormatFilter(input)
  } else if (countryKey === 'canada') {
    return `${c.code} ${inp.substring(0, 3)}-${inp.substring(
      3,
      6
    )}-${inp.substring(6)}`
  } else if (countryKey === 'czech_republic') {
    return `${c.code} ${inp.substring(0, 3)} ${inp.substring(
      3,
      6
    )} ${inp.substring(6)}`
  } else if (countryKey === 'brazil') {
    if (inp.length === 10) {
      return `${c.code} ${inp.substring(0, 2)} ${inp.substring(
        2,
        6
      )} ${inp.substring(6)}`
    } else {
      return `${c.code} ${inp.substring(0, 2)} ${inp.substring(
        2,
        7
      )} ${inp.substring(7)}`
    }
  } else if (countryKey === 'china') {
    if (inp.length === 10) {
      return `${c.code} ${inp.substring(0, 3)} ${inp.substring(
        3,
        6
      )} ${inp.substring(6)}`
    } else {
      return `${c.code} ${inp.substring(0, 3)} ${inp.substring(
        3,
        7
      )} ${inp.substring(7)}`
    }
  } else {
    return `${c?.code ? `${c.code} ` : ''}${inp}`
  }
}

export const formattedPhoneToNumber = (phoneNumber: string): string => {
  if (!phoneNumber) {
    return ''
  }
  return phoneNumber.replace(/[^0-9]/gi, '')
}

export const numberWithCommas = (number: number, decimals = 2): string => {
  if (!number) {
    return null
  }
  const parts = number.toFixed(decimals).split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export const formatAddressName = (a: Address): string => {
  if (!a) {
    return ''
  }

  let string = ''
  if (a.street1) {
    string = `${a.street1}`
    if (a.street2) {
      string = `${string} ${a.street2}`
    }
  }
  if (a.street1 && (a.city || a.state || a.postalCode)) {
    string = `${string}, `
  }
  if (a.city) {
    string = `${string}${a.city}`
  }
  if (a.city && (a.state || a.postalCode)) {
    string = `${string}, `
  }
  if (a.state) {
    string = `${string}${a.state}`
  }
  if (a.state && a.postalCode) {
    string = `${string} `
  }
  if (a.postalCode) {
    string = `${string}${a.postalCode}`
  }
  return string
}

export const formatStopAddress = (stop: ReservationDetailStop): string => {
  const address = stop.address
  const street1IsPresent = address.street1 && address.street1 !== ' '
  const street2IsPresent = address.street2 && address.street2 !== ' '

  let addressString = ''
  if (
    !street1IsPresent &&
    !street2IsPresent &&
    address.title &&
    address.title !== address.city
  ) {
    addressString = `${address.title}`
  } else {
    if (street1IsPresent) {
      addressString = `${address.street1}`
    }
    if (street2IsPresent) {
      addressString = `${addressString} ${address.street2}`
    }
  }
  if (address.city) {
    if (addressString.length) {
      addressString = `${addressString},`
    }
    addressString = `${addressString} ${address.city}`
  }
  if (address.state) {
    addressString = `${addressString}, ${address.state}`
  }
  return addressString
}

export const formatStopTime = (time: string, timezone: string): string => {
  const datetime = dayjs(time).tz(timezone)
  return `${datetime.format('MM/DD/YYYY')} • ${datetime.format('h:mm a')}`
}

export const formatDropoffTime = (stop: Stop): string => {
  return formatStopTime(stop.dropoffDatetime, stop.address.timeZone)
}

export const formatPickupTime = (stop: Stop): string => {
  return formatStopTime(stop.pickupDatetime, stop.address.timeZone)
}

export const cityFromAddressName = (addressName: string): string | null => {
  if (!addressName) {
    return null
  }
  const addressNameSplit = addressName.replace(anyNumberPattern, '').split(',')
  const stateIndex = addressNameSplit.findIndex((string) =>
    stateAbbreviationPattern.test(string)
  )
  return addressNameSplit[stateIndex - 1].trim()
}

export const formatFullName = <
  T extends { firstName: string; lastName: string }
>(
  obj: T
): string => {
  if (!obj) {
    return ''
  } else if (obj.firstName && obj.lastName) {
    return `${obj.firstName} ${obj.lastName}`
  }
  return obj.firstName || obj.lastName || ''
}

export const formatFullNameWithFirstLetterOfLastName = <
  T extends { firstName: string; lastName: string }
>(
  obj: T
): string => {
  if (!obj) {
    return ''
  } else if (obj.firstName && obj.lastName) {
    const shortLastName = obj.lastName ? `${obj.lastName.charAt(0)}.` : ''
    return `${obj.firstName} ${shortLastName}`.trim()
  }
  return obj.firstName || obj.lastName || ''
}

export const isNumerical = (str: string): boolean => {
  return !isNaN(Number.parseFloat(str))
}

export const duplicate = (str: string, count: number, sep = ' '): string => {
  return count <= 0 ? str : `${str}${sep}${duplicate(str, count - 1, sep)}`
}

export const capitalizeFirstLetter = (str: string): string => {
  if (!str) {
    return ''
  }
  return `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`
}

export const isValidHexCode = (str: string): boolean => {
  const reg = /^#([0-9a-f]{3}){1,2}$/i
  return reg.test(str)
}

export const stringToFloat = (event: string, precision = 2): number => {
  if (event == null) {
    return 0
  }
  return parseFloat(
    (Math.round(parseFloat(event.replace(/[^\d.-]/g, '')) * 100) / 100).toFixed(
      precision
    )
  )
}

export const htmlToString = (str: string): string => {
  if (!str) {
    return ''
  }
  return str
    .replaceAll(/<[^>]*>/g, ' ')
    .replaceAll(/\s+/g, ' ')
    .trim()
}

export const toPlural = (str: string): string => {
  if (!str) {
    return ''
  }
  return plural(str)
}

export const cleanEmptyHtml = (html: string): string => {
  if (!htmlToString(html)) {
    return ''
  }
  return html
}

export const httpHelper = (url: string): string => {
  if (!url) {
    return ''
  }
  if (url.toLowerCase().substring(0, 4) !== 'http') {
    return `https://${url}`
  }
  return url
}

// On a canvas element (not visible to user), make use of the
// measureText method to get the width of the text parameter
// in the font size/weight provided
export const getWidthOfTextInFont = (
  text: string,
  fontSize: string,
  fontWeight: string,
  localCanvas: any
): number => {
  if (!localCanvas) {
    localCanvas = document.createElement('canvas')
  }
  const canvas = localCanvas
  const context = canvas.getContext('2d')
  context.font = `${fontWeight} ${fontSize} Nunito, sans-serif`
  const metrics = context.measureText(text)
  return metrics.width
}

export const toTwoDigits = (val: string | number): string => {
  return `0${val}`.slice(-2)
}

export const sanitizeStringInput = (input: string): string => {
  if (!input) {
    return input
  }
  return input.replace(/[^a-zA-Z0-9\s,.!?-]/g, '')
}
