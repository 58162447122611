
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import op from 'simple-object-path'
import colors from '@/scss/_colors-export.scss'
import GrayDriver from '@/assets/images/GrayDriver.vue'
import { isNotEmptyInput } from '@/utils/validators'

@Component({
  components: {
    GrayDriver,
  },
})
export default class CUSelect extends Vue {
  @Prop({
    required: false,
    default: 'primary',
  })
  highlightColor: string

  @Prop({
    required: false,
    default: undefined,
  })
  borderColor: string

  @Prop({
    required: false,
    type: Boolean,
  })
  displaySelectAll: boolean

  @Prop({
    required: false,
    type: Boolean,
  })
  displayAllPlaceholder: boolean

  @Prop({
    required: false,
    type: Boolean,
  })
  displayCreateNew: boolean

  @Prop({
    required: false,
    type: Boolean,
  })
  createNewSelected: boolean

  @Prop({
    required: false,
    default: 'Create New',
  })
  createNewText: string

  @Prop({
    required: false,
    type: Boolean,
  })
  hideDropdownArrow!: boolean

  @Prop({
    required: false,
    default: null,
  })
  selectClass: string

  @Prop({
    required: false,
    type: Boolean,
  })
  tempCheckoutStyle: boolean

  @Prop({
    required: false,
    type: Boolean,
  })
  displaySelectAllInclusive: boolean

  @Prop({
    required: false,
    default: null,
  })
  avatar: string

  @Prop({
    required: false,
    default: null,
  })
  avatarIsIcon: boolean

  @Prop({
    required: false,
    default: 'bg-gray-2',
  })
  avatarIconColor: string

  @Prop({
    required: false,
    default: 'border-radius-top-left-10 border-radius-bottom-left-10',
  })
  avatarBorderRadius: string

  @Prop({
    required: false,
    default: 'border-radius-top-right-10 border-radius-bottom-right-10',
  })
  avatarRightBorderRadius: string

  @Prop({
    required: false,
    default: null,
  })
  tag: string

  @Prop({
    required: false,
    default: 'bg-gray-2',
  })
  tagColor: string

  // For selects with the `multiple` prop.
  // When selecting more than this index, display just (+# others)
  @Prop({
    required: false,
    type: Number,
  })
  aggregateSelectionIndex: number

  @Prop({
    required: false,
    type: Boolean,
  })
  collapseSelection

  @Prop({
    required: false,
    type: Boolean,
  })
  collapseSelectionBolded

  @Prop({
    required: false,
    default: 'text',
  })
  itemText!: string | ((_: any) => string)

  @Prop({
    required: false,
    default: 'value',
    type: String,
  })
  itemValue!: string

  @Prop({
    required: false,
  })
  items!: any[]

  @Prop({
    required: false,
    default: () => [],
  })
  value!: string | unknown[]

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  multiple!: boolean

  @Prop({
    type: Object,
    required: false,
    default: undefined,
  })
  menuProps!: Record<string, unknown>

  @Prop({
    required: false,
    type: Boolean,
  })
  searchable!: boolean

  @Prop({
    required: false,
    type: Boolean,
  })
  centeredLabel!: boolean

  @Prop({
    required: false,
    type: Boolean,
  })
  recurrence!: boolean

  @Prop({
    required: false,
    type: Boolean,
  })
  widget!: boolean

  @Prop({
    required: false,
    type: Boolean,
  })
  disabled!: boolean

  isAllToggled = false
  search = ''
  isSelection = null

  @Watch('value', { immediate: true })
  onValueChange(newVal: unknown[]): void {
    this.setIsSelection(newVal)
    if (!this.multiple) {
      return
    }

    if (newVal?.length === this.items?.length && newVal?.length) {
      this.isAllToggled = true
    } else if (this.displaySelectAllInclusive && !newVal.length) {
      this.isAllToggled = true
    } else {
      this.isAllToggled = false
    }
  }

  handleChange(event: any): void {
    this.search = ''
    this.setIsSelection(event)
  }

  setIsSelection(event: any): void {
    this.isSelection = !!isNotEmptyInput(event)
  }

  get selectMenuProps(): Record<string, string | boolean> {
    let menuProps = {
      closeOnContentClick: this.displayCreateNew && !this.searchable,
      offsetY: true,
      contentClass: this.searchable ? 'v-menu--searchable' : '',
    }
    if (this.menuProps) {
      menuProps = { ...menuProps, ...this.menuProps }
    }
    return menuProps
  }

  get selectedItemNames(): string[] {
    if (this.shouldDisplayAllSelection || !this.multiple) {
      return []
    }

    if (!Array.isArray(this.value)) {
      return []
    }

    const values = this.value

    const selectedItemNames = this.items
      .filter((item) => values.includes(op(item, this.itemValue)))
      .map((item) => op(item, this.itemText))
    return selectedItemNames
  }

  get collapsedItemCount(): number {
    return Math.max(this.selectedItemNames.length - 1, 0)
  }

  get listeners(): Record<string, unknown> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this
    return Object.assign({}, this.$listeners, {
      input(event: InputEvent) {
        vm.$emit('input', event)
      },
      change(event: InputEvent) {
        vm.$emit('change', event)
      },
    })
  }

  get hasItemTag(): boolean {
    return this.items.some((item) => item.tag)
  }

  get filteredItems(): any[] {
    if (this.searchable) {
      return this.items.filter((i) => {
        const query = (this.search || '').toLowerCase()
        const text =
          typeof this.itemText === 'function'
            ? (this.itemText(i) || '').toLowerCase()
            : (op(i, this.itemText) || '').toLowerCase()
        return (
          text.includes(query) || text.replaceAll(/\s/g, '').includes(query)
        )
      })
    }
    return this.items
  }

  get shouldDisplayAllSelection(): boolean {
    return this.value?.length === this.items?.length || !this.value?.length
  }

  toggleAllFilters(): void {
    if (this.value.length !== this.items.length) {
      if (this.itemValue) {
        this.$emit(
          'input',
          this.items.map((item) => item[this.itemValue])
        )
      } else {
        this.$emit('input', this.items)
      }
    } else {
      this.$emit('input', [])
    }
  }

  get cssVars(): Record<string, string> {
    const borderColorDefault =
      colors[this.borderColor] || colors['gray-border-mid']
    const borderColorActive =
      colors[this.borderColor] || this.borderColor || colors['primary']

    return {
      '--text-field-border-color-default': borderColorDefault,
      '--text-field-border-color-active': borderColorActive,
    }
  }

  handleClickAvatar(e): void {
    if (this.avatar !== 'driver' && this.avatar !== 'vehicle') {
      this.$emit('avatar:click')
    }
  }

  handleSelectCreateNew(): void {
    this.$emit('select:create-new')

    // Close the menu by getting the ref of the menu of the
    // v-select
    const selectRef: any = this.$refs['cu-select-ref']
    const menuRef = selectRef.$refs.menu
    if (menuRef) {
      menuRef.save()
    }
  }
}
