export interface VehicleDetailEntity {
  vehicleId: number | null
  vehicleName: string
  vehiclePhotoDTOs: VehiclePhotoDTO[]
  vehicleTypeName: string
  vehicleTypeId: string | number | null
  vehicleMake: string
  vehicleModel: string
  vehicleYear: number | null
  passengerCapacity: number | null
  vinNumber: string
  licensePlate: string
  garageId: number | null
  garageName: string
  companyId: number | null
  vehicleAmenityDTOs: VehicleAmenityDTO[]
  dummy: boolean
  active: boolean
}

// eslint-disable-next-line no-redeclare
export class VehicleDetailEntity implements VehicleDetailEntity {
  constructor(vehicleDetailEntity: VehicleDetailEntity) {
    Object.assign(this, vehicleDetailEntity)
  }
}

export interface VehicleAmenityDTO {
  amenityId: number
  amenityName: string
  isSupported: boolean
}

export interface VehiclePhotoDTO {
  vehiclePhotoId: number
  vehicleId: number
  imagePath: string
  primaryImage: boolean
  active: boolean
  file?: File
  label: string
}

export interface DeleteVehiclePhotoPayload {
  vehiclePhotoIds: number[]
}

export interface VehicleTimeOff {
  id: number
  vehicleId: number
  indefinite: boolean
  start: string
  end: string
  notes: string
}

// eslint-disable-next-line no-redeclare
export class VehicleTimeOff implements VehicleTimeOff {
  constructor(vehicleTimeOff: Partial<VehicleTimeOff>) {
    Object.assign(this, vehicleTimeOff)
  }
}
