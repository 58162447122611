
import { Component, Prop, Vue } from 'vue-property-decorator'
import { CustomerAccountGroup } from '@/models/dto/CustomerAccount'

@Component({})
export default class CompanyGroupSelector extends Vue {
  @Prop({ type: Boolean, default: false }) readonly readOnly!: boolean
  @Prop({ required: false, default: () => [] })
  readonly value!: CustomerAccountGroup[]

  addingGroup = false
  newGroupInput: string = null

  toggleAddGroup(): void {
    this.addingGroup = !this.addingGroup
  }

  addGroup(): void {
    if (!this.newGroupInput) {
      return
    }
    const group: CustomerAccountGroup = {
      customerAccountGroupId: null,
      customerAccountId: null,
      name: this.newGroupInput,
    }
    this.newGroupInput = ''
    this.$emit('input', [...this.value, group])
  }

  removeGroup(group: CustomerAccountGroup): void {
    const groups = this.value?.filter((g) => g !== group)
    this.$emit('input', groups)
  }
}
