
import { Vue, Component, Prop, Watch, Inject } from 'vue-property-decorator'
import { TableAction } from '@/models/TableAction'
import CUDataTableCell from '@/components/CUDataTableCell.vue'
import { DataTableColumn } from '@/models/DataTableColumn'
import { TableViewParameters } from '@/models/TableView'
import columnState from '@/store/modules/columns'
import CUDataTableActionRow from '@/components/CUDataTableActionRow.vue'
import _cloneDeep from 'lodash.clonedeep'

@Component({
  components: { CUDataTableActionRow, CUDataTableCell },
})
export default class CUDataTable extends Vue {
  selected = []
  get visibleColumns(): DataTableColumn[] {
    const cols = this.customizeColumns
      ? _cloneDeep(columnState.getVisibleColumns)
      : _cloneDeep(this.columns)

    // cols = cols.map((col) => {
    //   if (col.unsetWidthOnBreakpoint) {
    //     if (this.$vuetify.breakpoint[col.unsetWidthOnBreakpoint]) {
    //       col.width = null
    //     }
    //   }
    //   return col
    // })

    return cols
  }

  get mappedColumns(): any[] {
    return this.visibleColumns.map((col, index) => ({
      ...col,
      index,
      slotName: `item.${col.value}`,
      headerName: `header.${col.value}`,
    }))
  }

  get headerColumns(): any[] {
    return this.visibleColumns.map((col) => ({
      ...col,
      slotName: `header.${col.value}`,
      headerName: `header.${col.value}`,
    }))
  }

  @Prop({ required: false, default: () => ({}) }) readonly tableClasses: Record<
    string,
    string | boolean
  >

  @Prop({
    type: Array,
    required: false,
    default: undefined,
  })
  actions!: TableAction[]

  @Prop({
    type: Array,
    required: false,
    default: undefined,
  })
  items!: any[]

  @Prop({
    type: Array,
    required: true,
    default: [],
  })
  columns!: DataTableColumn[]

  @Prop({
    type: Number,
    required: true,
  })
  serverItemsLength!: number

  @Prop({
    type: Object,
    required: true,
  })
  options!: TableViewParameters

  @Prop({
    type: String,
    required: true,
  })
  itemKey!: string

  @Prop({
    type: String,
    required: false,
    default: 'No data found',
  })
  noDataText!: string

  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  showSelect!: boolean

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  flat!: boolean

  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  customizeColumns!: boolean

  @Inject({ default: false }) limitTableToViewHeight: boolean

  @Watch('items')
  updateSelectedItems(): void {
    this.selected = this.selected.reduce((arr, select) => {
      const newItem = this.items.find(
        (item) => item[this.itemKey] === select[this.itemKey]
      )
      if (newItem) {
        arr.push(newItem)
      }
      return arr
    }, [])
  }

  @Watch('options', { immediate: true, deep: true })
  optionsChange(e): void {
    this.$emit('update:options', e)
  }
  handlePdfDownload(): void {
    this.$emit('handle-pdf-download')
  }

  toggleSelectAll(): void {
    if (this.selected.length) {
      this.selected.splice(0)
    } else {
      this.selected = this.items
    }
  }

  get totalPages(): number {
    if (!this.serverItemsLength || !this.options?.pageSize) {
      return 0
    }
    return Math.ceil(this.serverItemsLength / this.options.pageSize)
  }
}
