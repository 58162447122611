import { NotificationsSetting } from '../NotificationsSetting'
import { Address } from './Address'
import { Company } from './Company'
import { Customer } from './Customer'
import { EnterpriseAccount } from './EnterpriseAccount'
import { Industry } from './Industry'
import { Note } from './Note'
import { Tag } from './Tag'

export interface CustomerAccountType {
  description: string
  id: number
  key: string
  label: string
}

export interface CustomerAccount {
  address: Address
  company: Company
  customerAccountId: number
  customerAccountType: CustomerAccountType
  email: string
  fax: string
  websiteUrl: string
  enterpriseAccount: EnterpriseAccount
  enterpriseAccountId: number
  industries: string
  industry: Industry
  name: string
  phone: string
  tags: Tag[]
  domain?: string
  owner?: string
  customerAccountGroups: CustomerAccountGroup[]
  sendQuotesAsInvoice?: boolean
  notificationsSetting: NotificationsSetting
}

export interface CustomerAccountRequest {
  address: Address
  companyId: number
  customerAccountReferralTerms: string
  email: string
  fax: string
  websiteUrl: string
  industryId: number
  lightLogoUrl: string
  name: string
  notes: string
  customers?: Customer[]
  customerAccountNotes: Note[]
  parentCustomerAccountId: string
  phone: string
  phoneCountryKey?: string
  referralTermsName: string
  tags: Tag[]
  teamLogoImage: string
  useCustomReferralTerms: boolean
  customerAccountGroups: Partial<CustomerAccountGroup>[]
}

export interface CustomerAccountDetailEntity {
  address: Address
  companyId: number
  customerAccountId: number
  customerAccountType: CustomerAccountType
  customerAccountTypeId: number
  customerAccountGroups: CustomerAccountGroup[]
  customerAccountNotes: Note[]
  customers: Customer[]
  email: string
  fax: string
  websiteUrl: string
  enterpriseAccountId: number
  industryId: number
  liveTracking: false
  name: string
  phone: string
  phoneCountryKey?: string
  owner: string
  useCustomReferralTerms: boolean
  sendQuotesAsInvoice: boolean
  notificationsSetting: NotificationsSetting
}

export interface CustomerAccountGroup {
  customerAccountGroupId: number
  customerAccountId: number
  name: string
}

// eslint-disable-next-line no-redeclare
export class CustomerAccount implements CustomerAccount {
  constructor(customerAccount?: Partial<CustomerAccount>) {
    Object.assign(this, customerAccount)
  }
}

// eslint-disable-next-line no-redeclare
export class CustomerAccountDetailEntity
  implements CustomerAccountDetailEntity
{
  constructor(customerAccount?: Partial<CustomerAccountDetailEntity>) {
    this.address = new Address()
    Object.assign(this, customerAccount)
  }
}

// eslint-disable-next-line no-redeclare
export class CustomerAccountRequest implements CustomerAccountRequest {
  constructor(customerAccount?: Partial<CustomerAccountRequest>) {
    Object.assign(this, customerAccount)
  }
}
