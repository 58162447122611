
import { VehicleTimeOff } from '@/models/dto'
import vehicle from '@/services/vehicle'
import auth from '@/store/modules/auth'
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
import dayjs from 'dayjs'
import { Vue, Component, Prop } from 'vue-property-decorator'
import VehicleTimeOffSidebar from './VehicleTimeOffSidebar.vue'

@Component
export default class VehicleSidebarAvailability extends Vue {
  @Prop({ required: true }) vehicleId: number

  dayjs = dayjs

  loading = true
  filterValue = 'Upcoming'
  timeOffs = []
  indefiniteTimeOff = new VehicleTimeOff({ indefinite: false })
  auth = auth

  get filteredTimeOffs(): VehicleTimeOff[] {
    if (this.filterValue === 'Upcoming') {
      return this.timeOffs.filter((t) =>
        dayjs(t.start).isSameOrAfter(dayjs(), 'minute')
      )
    } else if (this.filterValue === 'Past') {
      return this.timeOffs.filter((t) =>
        dayjs(t.end).isSameOrBefore(dayjs(), 'minute')
      )
    }
    return this.timeOffs.filter((t) => !t.indefinite)
  }

  get tz(): string {
    return (
      auth.getUser?.timeZone || auth.getCompanyTimeZone || 'America/New_York'
    )
  }

  formatDate(datetime: string): string {
    return dayjs
      .utc(datetime)
      .local()
      .tz(auth.getCompanyTimeZone)
      .format('MM/DD/YY')
  }

  formatTime(datetime: string): string {
    return dayjs
      .utc(datetime)
      .local()
      .tz(auth.getCompanyTimeZone)
      .format('h:mma z')
  }

  shouldDisplayStartTime(datetime: string): boolean {
    return !dayjs
      .utc(datetime)
      .tz(this.tz)
      .startOf('day')
      .isSame(dayjs.utc(datetime).tz(this.tz), 'minute')
  }

  shouldDisplayEndTime(datetime: string): boolean {
    return !dayjs
      .utc(datetime)
      .tz(this.tz)
      .endOf('day')
      .isSame(dayjs.utc(datetime).tz(this.tz), 'minute')
  }

  updateFilterValue(value: string): void {
    this.filterValue = value
  }

  handleUpdateIndefiniteToggle(value: boolean): void {
    this.indefiniteTimeOff.indefinite = value
    if (value && !this.indefiniteTimeOff?.id) {
      this.indefiniteTimeOff.start = dayjs
        .tz(dayjs(), this.tz)
        .add(-5, 'minute')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss')
    } else if (!value && this.indefiniteTimeOff?.id) {
      this.indefiniteTimeOff.end = `${dayjs
        .tz(dayjs(), this.tz)
        .add(-1, 'minute')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss')}Z`
    } else if (!value && !this.indefiniteTimeOff?.id) {
      this.indefiniteTimeOff = new VehicleTimeOff({ indefinite: false })
    }
    this.$emit('update:indefinite', this.indefiniteTimeOff)
  }

  handleUpdateIndefiniteNotes(notes: string): void {
    this.indefiniteTimeOff.notes = notes
    this.$emit('update:indefinite', this.indefiniteTimeOff)
  }

  handleAddNewTimeOff(): void {
    sidebar.push({
      component: VehicleTimeOffSidebar,
      props: {
        vehicleId: this.vehicleId,
      },
    })
  }

  handleEditTimeOff(vehicleTimeOff: VehicleTimeOff): void {
    sidebar.push({
      component: VehicleTimeOffSidebar,
      props: {
        vehicleId: this.vehicleId,
        vehicleTimeOff,
      },
    })
  }

  async handleDeleteTimeOff(vehicleTimeOff: VehicleTimeOff): Promise<void> {
    try {
      await vehicle.deleteTimeOff(this.vehicleId, vehicleTimeOff.id)
      EventBus.$emit(
        'snackbar:success',
        'Successfully deleted vehicle time off.'
      )
      if (vehicleTimeOff.id === this.indefiniteTimeOff?.id) {
        this.$emit('update:indefinite', null)
      }
      this.timeOffs = this.timeOffs.filter((t) => t.id !== vehicleTimeOff.id)
    } catch {
      EventBus.$emit('snackbar:error', 'Error deleting vehicle time off.')
    }
  }

  async load(): Promise<void> {
    this.loading = true
    const res = await vehicle.getTimeOff(this.vehicleId)
    this.timeOffs = res.data
    this.indefiniteTimeOff =
      res.data.filter((t) => t.indefinite)[0] ||
      new VehicleTimeOff({ indefinite: false })
    this.loading = false
  }

  mounted(): void {
    EventBus.$on('time-off:update', () => this.load())

    this.load()
  }

  beforeDestroy(): void {
    EventBus.$off('time-off:update', () => this.load())
  }
}
