var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"position-relative"},[_c('v-menu',{staticClass:"z-105",attrs:{"nudge-left":_vm.nudgeLeft,"nudge-bottom":_vm.nudgeBottom,"transition":"slide-y-transition","content-class":"v-menu__content--shadow-light overflow-hidden min-w-0 max-h-260","bottom":"","offset-overflow":"","attach":_vm.attach,"disabled":_vm.isIOS,"offset-y":"","rounded":"4","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.$attrs.label)?_c('label',{staticClass:"font-14"},[_vm._v(_vm._s(_vm.$attrs.label))]):_vm._e(),_c('CUTextField',_vm._g(_vm._b({ref:"activator-text-field",staticClass:"cu-time-picker__activator-text-field",class:{
          'v-input--widget': _vm.widget,
          'v-input--ios': _vm.isIOS,
          'v-input--empty': !_vm.inputString,
        },attrs:{"type":"time","value":_vm.inputString,"label":_vm.isIOS ? null : _vm.label,"disabled":_vm.disabled,"required":"","manual-border-color":"","border-color":_vm.borderColor},on:{"click":_vm.handleClick,"change":_vm.handleChange,"input":_vm.handleInput,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleKeyupEnter.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;_vm.open = false},"focusout":_vm.handleFocusOut},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [(!_vm.widget || !_vm.label)?_c('CUIcon',_vm._g({key:"disabled ? 'disabled-clock' : 'active-clock'",staticClass:"margin-r-1",class:{ 'margin-t-1': !_vm.widget },attrs:{"color":"bg-gray-4","height":"15px","width":"15px"}},!_vm.disabled ? on : {}),[_vm._v(" clock ")]):_vm._e()]},proxy:true}],null,true)},'CUTextField',_vm.$attrs,false),on))]}},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(props){return [_vm._t(slot,null,null,props)]}}})],null,true),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('div',{staticClass:"cu-time-picker d-flex position-relative padding-y-3 padding-x-1",style:(_vm.cssVars)},[_c('CUScroll',{staticClass:"min-w-60",attrs:{"height":"236px"}},_vm._l((_vm.hours),function(hour,hourIdx){return _c('div',{key:`time-picker-hour-${hour}-${hourIdx}`,staticClass:"d-flex align-center justify-center h-28 w-28 margin-l-3 rounded-circle margin-b-1 hover:background-bg-gray-2 cursor-pointer",class:{
            'is-current': _vm.currHour === hour && _vm.showCurrent,
            'is-active': _vm.selected.hour === hour,
          },on:{"mousedown":function($event){return _vm.setHour(hour)}}},[_c('p',[_vm._v(_vm._s(hour))])])}),0),_c('div',{staticClass:"max-h-236"},[_c('v-divider',{attrs:{"vertical":""}})],1),_c('CUScroll',{staticClass:"min-w-60",attrs:{"height":"236px"}},_vm._l((_vm.minutes),function(min,minIdx){return _c('div',{key:`time-picker-minute-${min}-${minIdx}`,staticClass:"d-flex align-center justify-center h-28 w-28 margin-l-3 rounded-circle margin-b-1 hover:background-bg-gray-2 cursor-pointer",class:{
            'is-current': _vm.currMin === min && _vm.showCurrent,
            'is-active': _vm.selected.minute === min,
          },on:{"mousedown":function($event){return _vm.setMinute(min)}}},[_c('p',[_vm._v(_vm._s(min))])])}),0),_c('div',{staticClass:"max-h-236"},[_c('v-divider',{attrs:{"vertical":""}})],1),_c('div',{staticClass:"padding-x-3 min-w-60"},_vm._l((['AM', 'PM']),function(meridiem,mIdx){return _c('div',{key:`time-picker-minute-${meridiem}-${mIdx}`,staticClass:"d-flex align-center justify-center h-28 w-28 rounded-circle margin-b-1 hover:background-bg-gray-2 cursor-pointer",class:{
            'is-current': _vm.currMeridiem === meridiem && _vm.showCurrent,
            'is-active': _vm.selected.meridiem === meridiem,
          },on:{"mousedown":function($event){return _vm.setMeridiem(meridiem)}}},[_c('p',[_vm._v(_vm._s(meridiem))])])}),0)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }