var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.tabs)?_c('div',{class:{ 'margin-r-n2': _vm.isSubTab }},[_c('v-tabs',{ref:"tabs",staticClass:"rounded-t-xl",class:{ 'v-tabs__subtab margin-l-n2 margin-r-2': _vm.isSubTab },attrs:{"color":_vm.tabColor,"hide-slider":!_vm.isSubTab,"active-class":!_vm.isSubTab ? `background-${_vm.backgroundColor} rounded-t-lg` : '',"prev-icon":"mdi-chevron-left","next-icon":"mdi-chevron-right","show-arrows":""},on:{"change":_vm.onTabChange},model:{value:(_vm.option),callback:function ($$v) {_vm.option=$$v},expression:"option"}},[_vm._l((_vm.tabs),function(tab,idx){return _c('v-tab',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => _vm.handleBlur(idx)),expression:"() => handleBlur(idx)"}],key:`${_vm.isSubTab ? 'subtab' : 'tab'}-v-tab-${
        tab.id ? `${tab.id}-${idx}` : idx
      }`,staticClass:"font-14",style:(_vm.getPaddingStyle(tab, idx)),attrs:{"name":idx === _vm.editTabIdx ? _vm.newTabName : tab.label},on:{"mouseover":function($event){return _vm.handleMouseOver(idx)},"mouseleave":_vm.handleMouseLeave,"click":function($event){return _vm.handleTabClick(tab)}}},[_c('span',{staticClass:"position-absolute d-flex align-center",class:{ 'padding-x-4 w-full': !_vm.isSubTab }},[(tab.error && !_vm.isSubTab)?_c('div',[_c('CUIcon',{staticClass:"margin-a-1",attrs:{"width":"10px","height":"10px","color":"error"}},[_vm._v(" warning ")])],1):_vm._e(),(idx !== _vm.editTabIdx)?_c('span',{staticClass:"white-space-nowrap overflow-hidden text-overflow-ellipsis",class:{
            'text-error': tab.error,
          }},[_vm._v(" "+_vm._s(tab.label)+" ")]):_vm._e(),(idx === _vm.editTabIdx)?_c('CUTextField',{staticClass:"v-input--denser margin-b-n3",style:({ minWidth: `${_vm.editFieldWidth}px` }),attrs:{"hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleSaveTabName(idx)}},model:{value:(_vm.newTabName),callback:function ($$v) {_vm.newTabName=$$v},expression:"newTabName"}}):_vm._e(),(
            tab.editable &&
            _vm.option === idx &&
            _vm.hover === idx &&
            _vm.editTabIdx === null
          )?_c('CUButton',{key:"trip-name-edit",staticClass:"align-center margin-l-2 justify-end hover:text-primary",attrs:{"icon":"","small":"","width":"18","height":"18"},on:{"click":function($event){return _vm.handleEditTabName(idx)}}},[_c('CUIcon',{attrs:{"color":"bg-gray-4","width":"18","height":"18"}},[_vm._v("edit")])],1):_vm._e(),(tab.editable && idx === _vm.editTabIdx)?[(_vm.newTabName.length > _vm.editFieldLimit)?_c('CUTooltip',{attrs:{"tooltip-text":"Character Limit Exceeded","top":""}},[_c('CUIcon',{staticClass:"d-flex margin-l-2",attrs:{"hide-title":"","color":"error","width":"16"}},[_vm._v(" warning ")])],1):_c('CUButton',{key:"trip-name-done",staticClass:"align-center margin-l-2 justify-end hover:text-primary",attrs:{"icon":"","small":"","width":"18","height":"18"},on:{"click":function($event){return _vm.handleSaveTabName(idx)}}},[_c('CUIcon',{attrs:{"color":"bg-gray-4","width":"18","height":"18"}},[_vm._v("done")])],1)]:_vm._e(),(
            (tab.removable &&
              _vm.option === idx &&
              _vm.hover === idx &&
              _vm.editTabIdx === null) ||
            idx === _vm.editTabIdx
          )?_c('CUButton',{staticClass:"align-center justify-end hover:text-primary margin-l-2",attrs:{"icon":"","small":"","width":"18","height":"18","color":"gray-icon-light"},on:{"click":function($event){return _vm.handleDeleteTab(idx)}}},[_c('CUIcon',{attrs:{"aria-label":idx === _vm.editTabIdx ? 'Cancel' : 'Remove',"color":"bg-gray-4","width":"18","height":"18"}},[_vm._v(" close ")])],1):_vm._e()],2)])}),_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.option),callback:function ($$v) {_vm.option=$$v},expression:"option"}},_vm._l((_vm.tabs),function(tab,idx){return _c('v-tab-item',{key:`${_vm.isSubTab ? 'subtab' : 'tab'}-${
          tab.id ? `${tab.id}-${idx}` : idx
        }-content`,staticClass:"border-radius-bottom-left-20 border-radius-bottom-right-20 border-radius-top-right-20",class:{
          [`background-${_vm.backgroundColor}`]: true,
          'border-radius-top-left-20': _vm.option > 0,
          'margin-l-2': _vm.isSubTab,
        },attrs:{"transition":false,"eager":tab.eager}},[(tab.subtabs)?_c('CUTabs',{ref:tab.ref || '',refInFor:true,staticClass:"margin-x-4 margin-t-3",attrs:{"is-sub-tab":"","tabs":tab.subtabs},on:{"tab:change":function($event){return _vm.$emit('tab:change', [_vm.option, ...$event])},"tab:delete":function($event){return _vm.$emit('tab:delete', [_vm.option, ...$event])},"tab:edit":function($event){return _vm.$emit('tab:edit', $event)}}}):(tab.component)?_c(tab.component,_vm._g(_vm._b({ref:tab.ref || '',refInFor:true,tag:"component"},'component',tab.props,false),tab.on)):_vm._e()],1)}),1),_vm._t("actions")],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }