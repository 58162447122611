
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import dayjs from 'dayjs'
import {
  selectedDateStyle,
  dragStyle,
  todayStyle,
  predefinedDates,
} from '@/data/datePicker'
import { PredefinedDate, DateRange } from '@/models/DatePicker'
import { numberToPixels } from '@/utils/style'
import {
  isoToJSDate,
  isoToMDY,
  jsDateToISO,
  isoToShortMonthYear,
} from '@/utils/date'
import CUDatePickerFooter from '@/components/CUDatePickerFooter.vue'
import colors from '@/scss/_colors-export.scss'
import { isoDateRegex, slashDateRegex } from '@/utils/regex'

@Component({
  components: { CUDatePickerFooter },
})
export default class CUDatePicker extends Vue {
  @Prop({
    required: false,
    default: '',
  })
  value!: string | string[]

  @Prop({
    required: false,
    default: '',
  })
  defaultDisplayedDate!: string

  @Prop({
    default: undefined,
    required: false,
  })
  width!: string | number | undefined

  @Prop({
    required: false,
    default: false,
    type: Boolean,
  })
  dense!: boolean

  @Prop({
    required: false,
    default: false,
    type: Boolean,
  })
  disabled!: boolean

  @Prop({
    required: false,
    default: false,
    type: Boolean,
  })
  isRange!: boolean

  @Prop({
    required: false,
    default: undefined,
  })
  label!: string

  @Prop({
    required: false,
    default: undefined,
    type: Boolean,
  })
  attach!: boolean

  @Prop({
    type: Boolean,
    default: false,
  })
  noIcon!: boolean

  @Prop({
    type: Boolean,
    default: false,
  })
  noClear!: boolean

  @Prop({
    type: Boolean,
    default: false,
  })
  constantWidth!: boolean

  @Prop({
    required: false,
    default: undefined,
  })
  nudgeLeft!: string

  @Prop({
    required: false,
    default: '3px',
  })
  nudgeBottom!: string

  @Prop({
    required: false,
    default: null,
  })
  customColor: string

  @Prop({
    type: Boolean,
    default: false,
  })
  displayAsButton!: boolean

  @Prop({
    type: Boolean,
    default: false,
  })
  widget!: boolean

  @Prop({
    type: Boolean,
    default: false,
  })
  hidePredefinedDates!: boolean

  predefinedDates = predefinedDates
  attributes = []
  open = false

  selectedPredefined = null
  selectedCalendarDate: Date = null
  selectedCalendarRange: DateRange = {
    start: undefined,
    end: undefined,
  }

  cachedStartDate = ''
  cachedEndDate = ''
  startDateInputValue = ''
  endDateInputValue = ''
  hasInput = false
  isDragging = false
  isHovered = false
  isActivatorFocused = false
  observer = null

  @Watch('selectedPredefined', { immediate: true })
  onPredefinedChange(): void {
    this.updateActivatorTextFieldValue(this.value)
  }

  get isMenuActive(): boolean {
    const menu: any = this.$refs.menu
    return menu.isActive
  }

  @Watch('open')
  onModalChange(isOpen: boolean): void {
    this.selectedPredefined = ''
    if (this.isRange) {
      this.refreshSelectedCalendarRange()
    } else {
      this.refreshSelectedCalendarDate()
    }

    // If this is a range picker, set the focus on the start input. Placing this in a
    // setTimeout to wait until menu items have finished rendering. This is a known
    // bug with Vuetify 2: https://github.com/vuetifyjs/vuetify/issues/4454
    if (this.isRange && isOpen) {
      setTimeout(() => {
        const footer: any = this.$refs.footer
        footer.focusStartInput()
      }, 100)
    }

    if (!this.defaultDisplayedDate || this.value || !isOpen) {
      return
    }
    this.$nextTick(() => {
      const cal: any = this.$refs.calendar
      if (cal) {
        cal.move(this.defaultDisplayedDate)
      }
    })
  }

  @Watch('value', { immediate: true })
  onValueChange(): void {
    if (this.isRange) {
      this.refreshSelectedCalendarRange()
    } else {
      this.refreshSelectedCalendarDate()
    }
  }

  @Watch('defaultDisplayedDate', { immediate: true })
  onDefaultDisplayedDateChange(date: string): void {
    if (!date) {
      return
    }

    const cal: any = this.$refs.calendar
    if (cal && !this.value) {
      cal.move(this.defaultDisplayedDate)
    }
  }

  isUserInputValidDate(input: string): boolean {
    return (
      dayjs(input).isValid() &&
      (isoDateRegex.test(input) || slashDateRegex.test(input))
    )
  }

  // Assumes the input is valid. Update the selected
  // date, and focus the calendar on the selected date
  setSingleCalendarDate(input: string): void {
    const date = isoToJSDate(dayjs(input).toISOString())
    this.selectedCalendarDate = date
    const calendar: any = this.$refs.calendar
    if (calendar) {
      calendar.move(date)
    }
    this.$emit('input', dayjs(input).format('YYYY-MM-DD'))
  }

  // On each input, check if the value is a valid date -- if it is,
  // update the calendar + set the date
  handleActivatorInput(e: string): void {
    if (!this.isUserInputValidDate(e)) {
      return
    }

    this.setSingleCalendarDate(e)
  }

  handleActivatorChange(e: string): void {
    // If this is an empty string, reset the text field value
    if (!e) {
      this.selectedCalendarDate = null
      this.$emit('input', '')
      return
    }

    // If the date change isn't valid, then reset the text field value
    // back to the existing date (if one exists), or back to an empty string
    if (
      !dayjs(e).isValid() ||
      (!isoDateRegex.test(e) && !slashDateRegex.test(e))
    ) {
      const existingDate = this.selectedCalendarDate
        ? dayjs(this.selectedCalendarDate).format('M/DD/YY')
        : ''
      const input: any = this.$refs['activator-text-field']
      input.$refs['v-text-field'].lazyValue = existingDate
      this.activatorTextFieldValue = existingDate
      return
    }

    this.setSingleCalendarDate(e)
  }

  get displayClearIcon(): boolean {
    return (
      this.isHovered &&
      !this.noClear &&
      this.activatorTextFieldValue &&
      !this.disabled
    )
  }

  get menuLeftOffset(): string {
    if (this.nudgeLeft) {
      return this.nudgeLeft
    }
    return this.attach ? '0px' : '40px'
  }

  get activatorWidth(): string | undefined {
    if (this.width) {
      return numberToPixels(this.width)
    }
    return undefined
  }
  activatorTextFieldValue = ''

  @Watch('value', { immediate: true })
  updateActivatorTextFieldValue(value): void {
    this.activatorTextFieldValue = this.getActivatorTextFieldValue(value)
  }

  getActivatorTextFieldValue(value: string | string[]): string {
    let text = ''
    const dateFormatter = this.displayAsButton ? isoToShortMonthYear : isoToMDY

    if (!value?.length) {
      return text
    }

    if (this.displayAsButton && this.isRange) {
      if (dayjs(value[0]).isSame(dayjs(value[1]), 'day')) {
        return `${dateFormatter(value[0])}`
      }

      if (!value[0] || !isoToMDY(value[0])) {
        return text
      }
      text = `${dateFormatter(value[0])}`

      if (!value[1] || !dateFormatter(value[1])) {
        return text
      }
      text = `${text} - ${dateFormatter(value[1])}`

      return text
    }

    if (!this.isRange) {
      if (value && typeof value === 'string') {
        return dateFormatter(value)
      } else {
        return ''
      }
    }
    if (this.selectedPredefined === 'future') {
      return 'In the Future'
    } else if (this.selectedPredefined === 'past') {
      return 'In the Past'
    }

    if (dayjs(value[0]).isSame(dayjs(value[1]), 'day')) {
      return `${dateFormatter(value[0])}`
    }

    if (!value[0] || !dateFormatter(value[0])) {
      return text
    }
    text = `${dateFormatter(value[0])}`

    if (!value[1] || !dateFormatter(value[1])) {
      return text
    }
    text = `${text} - ${dateFormatter(value[1])}`

    return text
  }

  handleStartDateInput(e: string): void {
    const parsedStartDate = dayjs(e)
    const parsedEndDate = dayjs(this.endDateInputValue, 'MM/DD/YY')

    // User typed an invalid string
    if (
      !parsedStartDate.isValid() ||
      (!isoDateRegex.test(e) && !slashDateRegex.test(e))
    ) {
      this.startDateInputValue = this.cachedStartDate
      return
    }

    // User entered a start date after the end date
    if (this.endDateInputValue && parsedEndDate.isBefore(parsedStartDate)) {
      const start = this.selectedCalendarRange.end
      this.startDateInputValue = this.endDateInputValue
      this.endDateInputValue = parsedStartDate.format('MM/DD/YY')
      this.selectedCalendarRange = {
        start,
        end: dayjs(e).toDate(),
      }
      return
    }

    this.startDateInputValue = parsedStartDate.format('MM/DD/YY')
    this.selectedCalendarRange = {
      ...this.selectedCalendarRange,
      start: dayjs(e).toDate(),
    }
  }

  handleEndDateInput(e: string): void {
    const parsedEndDate = dayjs(e)
    const parsedStartDate = dayjs(this.startDateInputValue, 'MM/DD/YY')

    // Invalid user input
    if (
      !parsedEndDate.isValid() ||
      (!isoDateRegex.test(e) && !slashDateRegex.test(e))
    ) {
      this.endDateInputValue = this.cachedEndDate
      return
    }

    // If end date is before start date, swap them
    if (this.startDateInputValue && parsedStartDate.isAfter(parsedEndDate)) {
      const end = this.selectedCalendarRange.start
      this.endDateInputValue = this.startDateInputValue
      this.startDateInputValue = parsedEndDate.format('MM/DD/YY')
      this.selectedCalendarRange = {
        start: dayjs(e).toDate(),
        end,
      }
      return
    }

    this.endDateInputValue = e
    this.selectedCalendarRange = {
      ...this.selectedCalendarRange,
      end: dayjs(e).toDate(),
    }
  }

  closeMenu(): void {
    this.open = false
    const menu: any = this.$refs['menu']
    menu.isActive = false
  }

  handleDayClick(): void {
    this.selectedPredefined = ''
    this.attributes = [todayStyle(this.customColor)]

    if (!this.isRange) {
      this.closeMenu()
      return
    }

    const cal: any = this.$refs['calendar']

    // Workaround for selecting the same dates as before--if selecting
    // the same range as before, we're getting a dayclick event but
    // not an input event. If we're dragging a date but haven't received
    // an input event, then abandon the current drag
    if (this.isDragging && !this.hasInput) {
      cal.dragValue = null
      this.isDragging = false
    } else if (this.hasInput) {
      this.hasInput = false
      this.isDragging = false
    } else {
      this.isDragging = true
    }
  }

  handleDatePickerInput(e): void {
    if (!e) {
      return
    }
    if (this.isRange) {
      this.selectedCalendarRange = e
      this.startDateInputValue = dayjs(e.start).format('MM/DD/YY')
      this.endDateInputValue = dayjs(e.end).format('MM/DD/YY')
    } else {
      this.$emit('input', dayjs(e).format('YYYY-MM-DD'))
      this.selectedCalendarDate = e
      if (!this.isActivatorFocused) {
        this.closeMenu()
      }
    }
    this.hasInput = true
  }

  clear(): void {
    if (this.isRange) {
      this.selectedCalendarRange = { start: null, end: null }
      this.startDateInputValue = ''
      this.endDateInputValue = ''
      this.cachedStartDate = ''
      this.cachedEndDate = ''
      this.selectedPredefined = ''

      this.$emit('input', [])
    } else {
      this.selectedCalendarDate = null
      this.$emit('input', '')
    }
  }

  refreshSelectedCalendarDate(): void {
    if (!this.value || typeof this.value !== 'string') {
      this.selectedCalendarDate = null
    } else {
      this.selectedCalendarDate = isoToJSDate(this.value)
    }
  }

  refreshSelectedCalendarRange(): void {
    let start
    let end

    if (!Array.isArray(this.value)) {
      return
    }

    if (this.value.length > 1) {
      if (!this.value[0] && this.value[1]) {
        const pastPredefined = predefinedDates.find((p) => p.key === 'past')
        this.handlePredefinedClick(pastPredefined)
      } else if (this.value[0] && !this.value[1]) {
        const futurePredefined = predefinedDates.find((p) => p.key === 'future')
        this.handlePredefinedClick(futurePredefined)
      } else {
        if (!this.isValueTodayOrTomorrow(this.value)) {
          this.selectedPredefined = ''
        }
        start = isoToJSDate(this.value[0])
        end = isoToJSDate(this.value[1])
        this.startDateInputValue = dayjs(isoToMDY(this.value[0])).format(
          'MM/DD/YY'
        )
        this.endDateInputValue = dayjs(isoToMDY(this.value[1])).format(
          'MM/DD/YY'
        )
      }
    } else if (this.value.length === 1) {
      start = isoToJSDate(this.value[0])
      end = isoToJSDate(this.value[0])
      this.startDateInputValue = isoToMDY(this.value[0])
      this.endDateInputValue = isoToMDY(this.value[0])
    } else {
      this.startDateInputValue = ''
      this.endDateInputValue = ''
    }

    this.selectedCalendarRange = { start, end }
  }

  buildInputPayload(): string[] | string {
    if (!this.isRange) {
      return dayjs(this.selectedCalendarDate).format('YYYY-MM-DD')
    }

    if (this.selectedPredefined) {
      switch (this.selectedPredefined) {
        case 'future':
          return [dayjs().format('YYYY-MM-DD'), null]
        case 'past':
          return [null, dayjs().format('YYYY-MM-DD')]
        default:
          break
      }
    }

    const dates = []
    if (!this.selectedCalendarRange.start) {
      return dates
    }
    dates.push(jsDateToISO(this.selectedCalendarRange.start))
    dates.push(jsDateToISO(this.selectedCalendarRange.end))
    return dates
  }

  confirmDates(): void {
    this.$emit('input', this.buildInputPayload())
    this.closeMenu()
  }

  handlePredefinedClick(predefined: PredefinedDate): void {
    this.selectedCalendarRange = {
      start: null,
      end: null,
    }
    this.selectedCalendarDate = null

    this.$nextTick(() => {
      this.selectedPredefined = predefined.key
      this.applyPredefined(predefined)
    })
  }

  applyPredefined({
    startDate,
    endDate,
    attributes = [todayStyle(this.customColor)],
    focusDate = false,
    startDateInputValue,
    endDateInputValue,
  }: PredefinedDate): void {
    this.selectedCalendarRange = {
      start: startDate,
      end: endDate,
    }
    this.attributes = attributes
    if (focusDate) {
      const calendarRef: any = this.$refs.calendar
      calendarRef.focusDate(startDate)
    }

    if (startDateInputValue) {
      this.startDateInputValue = startDateInputValue
    }

    if (endDateInputValue) {
      this.endDateInputValue = endDateInputValue
    }
  }

  isValueTodayOrTomorrow(value: string[]): boolean {
    const today = dayjs().format('YYYY-MM-DD')
    const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD')

    if (value[0] === today && value[1] === today) {
      return true
    } else if (value[0] === tomorrow && value[1] === tomorrow) {
      return true
    }

    return false
  }

  get selectedDateStyle(): any {
    return selectedDateStyle(this.customColor)
  }

  get dragStyle(): any {
    return dragStyle(this.customColor)
  }

  get cssVars(): Record<string, string> {
    const selectedColor =
      colors[this.customColor] || this.customColor || colors['secondary']
    const lightColor =
      colors[`${this.customColor}-light`] || this.customColor
        ? `${this.customColor}1A`
        : colors['secondary-light']

    return {
      '--vc-date-picker-selected-color': selectedColor,
      '--vc-date-picker-light-color': lightColor,
    }
  }

  mounted(): void {
    this.attributes = [todayStyle(this.customColor)]
  }
}
