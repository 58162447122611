import { Trip } from './Trip'
import { CheckoutPage, CheckoutType } from './Checkout'
import { Customer } from './Customer'
import { LeadSourceType } from './LeadSource'
import { UserDetail } from './User'
import { Type } from './Type'
import { Company } from './Company'
import { Status } from '@/models/dto/Status'
import { Priority } from '@/models/dto/Priority'
import { Address } from './Address'
import { PaymentMethodTypes, PaymentMethod } from './PaymentMethod'
import {
  CompanyPaymentGateway,
  PaymentGatewayConvertPayload,
  TokenizeCreditCardPayload,
} from '../PaymentGateway'
import { Email } from '@/models/dto/Email'
import { CompanyBaseFarePricingType } from './Pricing'
import { QuotePriorityTypeId, QuoteStatusTypeId } from '@/utils/enum'
import { Terms } from '@/models/dto/Terms'
import { PaymentTypeTypes } from '.'
import { Note } from './Note'
import { WidgetSubmission } from './Widget'
import { Itinerary } from './Itinerary'
import { CheckoutDocument } from '@/models/dto/CheckoutDocument'
import { File as CustomFile } from '@/models/dto/File'

export interface QuoteResponse {
  message?: string
  quote: Quote
  routingKey: string
  status: string
  successful: boolean
}

export interface Quote {
  isActive: boolean
  isConverted: boolean
  isConfirmed: boolean
  currencyType: string
  checkoutPageId?: number
  leadSourceTypeId: number
  quoteId: number
  quoteStatusId: number
  quoteStatus: Status
  quotePriorityId: number
  quotePriorityName: string
  quotePriority: Priority
  hash: string
  convertedOn?: string
  contractId?: number
  customerId: number
  customerName: string
  billingCustomerId: number
  customerAccountId: number
  customerAccountGroupId: number
  customerAccountGroupName: string
  companyId: number
  createdById: number
  modifiedById?: number
  createdOn: string
  updatedOn: string
  referredBy?: string
  referralId?: number
  referralTotal?: number
  referralMargin?: number
  referralPercentage?: number
  lastEmailTimestamp?: string
  charterUpQuote: boolean
  decisionDate: string
  expirationDate: string
  enableExpiration: boolean
  expirationType: number
  expirationOffset: number
  expireAfterFirstSend: boolean
  quoteNote?: Note
  expirationTimezone: string
  preferred?: boolean
  dueDate?: string
  leadSourceType: LeadSourceType
  source: string
  sourceCategory: number
  checkoutPage?: CheckoutType
  customer: Customer
  sentBy: UserDetail
  trips: Trip[]
  paymentMethods: PaymentMethod[]
  convertedPaymentMethodTypeId: number
  randomMultiplier?: number
  allowEcheckout: boolean
  attachPdfToEmail: boolean
  managedId: string
  quoteType: number
  termsId?: number
  contractName?: string
  contractIsOpen: boolean
  contractIsSigned: boolean
  contractStartDate?: string
  contractEndDate?: string
  contractNotes?: string
  contractBillingFrequency?: any
  contractBillingMethod?: any
  companyTerm?: any
  participatingInEmailCampaigns: boolean
  hasRecurrence: boolean
  firstPickupAddressId?: number
  firstDropoffAddressId?: number
  amount?: number
  customEmail?: boolean
  busNeededForEntireTrip: boolean
  total: number
  createdBy: UserDetail
  pricingTimeLabel: string
  priceForAllPricingMethods: boolean
  dropoffDate?: string
  pickupDate?: string
  bookedBy?: UserDetail
  enablePayLater: boolean
  enablePayFullAmountNow: boolean
  paymentPolicy?: string
  reservationIds: number[]
  baseFarePricingTypes?: CompanyBaseFarePricingType[]
  pricingMethodId?: number
  eventTypeId?: number
  sendAsInvoice?: boolean
  requireSignatureUponCheckout?: boolean
  itineraries?: Itinerary[]
  widgetSubmissions?: WidgetSubmission[]
  quoteConversionDetailsId?: number
  enablePONumber: boolean
  poNumber: string
  files?: CustomFile[]
  customerPhone?: string
  customerPhoneExtension?: string
  customerPhoneCountryKey?: string
}

export interface QuoteConvertManualPayload {
  meta: { billing: Record<never, never> }
  manual: boolean
  payment_method: PaymentMethodTypes
  payment_type: PaymentTypeTypes
  amountPaidAsCents?: number
  confirmationEmails?: string[]
  customer_notes?: string
  internal_notes?: string
  convertedBy?: number
  overrideExpiration: boolean
  checkoutDocument?: CheckoutDocument
  enablePONumber?: boolean
  poNumber?: string
  disableInvoiceCreation?: boolean
}

export interface QuoteConvertPaymentPayload {
  billing?: PaymentGatewayConvertPayload | TokenizeCreditCardPayload
  nonces?: string[]
  paymentProfileId?: number
  payment_gateway?: CompanyPaymentGateway
  payment_method: PaymentMethodTypes
  notes?: { note: string }
  confirmationEmails?: string[]
}

export interface QuoteConvertBilling {
  activeMethod: string
  address: Address
  cardNumber: string
  cardholderName: string
  exp_date: string
  expirationMonth: string
  expirationYear: string
  mask: string
  name: string
  securityCode: string
  type_label: string
}

export interface QuoteTableViewResult {
  quoteId: number
  isConverted: boolean
  isConfirmed: boolean
  active: boolean
  managedId: string
  hash: string
  referralTotal?: number
  referralMargin?: number
  referralPercentage: number
  noOfAcceptedReferrals: number
  noOfOfferedReferrals: number
  noOfRejectedReferrals: number
  percentAccepted: number
  percentOffered: number
  createdBy?: UserDetail
  bookedBy?: UserDetail
  customer?: UserDetail
  checkoutPage?: string
  checkoutPageLabel?: string
  leadSourceType?: Type
  leadSourceTypeLabel: string
  trips: Trip[]
  amount: number
  firstPickupAddress: string
  firstDropoffAddress: string
  reservations: number[]
  allReservations: number[]
  reservationIds: number[]
  reservationManagedIds: number[]
  tripTypes: string
  paymentTypes: string
  paymentMethodTypes: string
  vehicleTypes: string
  createdOn: string
  convertedOn: string
  decisionDate?: string
  expirationDate?: string
  enableExpiration?: boolean
  expirationType?: number
  expirationOffset?: number
  participatingInEmailCampaigns: boolean
  seenByClient: string
  pickupDate: string
  dropoffDate: string
  pickupLocation: string
  isCharterUpQuote: boolean
  bookingsPerYear?: number
  contractId?: number
  quoteType: number
  managedContractId?: number
  contractName?: string
  contractIsOpen?: boolean
  contractIsSigned?: boolean
  contractStatus: string
  hasRecurrence: boolean
  createdById: number
  createdByName: string
  customerId: number
  customerAccountId: number
  customerName: string
  customerAccountName: string
  phoneNumber?: string
  callerId?: string
  tagsString?: string
}

export interface CheckoutQuote {
  checkoutPage: CheckoutPage
  checkoutPage2: CheckoutPage
  colors?: any
  company: Company
  contractId: number
  convertedOn: string
  createdById: number
  createdOn: string
  currencyType: string
  customer: Customer
  dueDate: string
  expirationDate?: string
  enableExpiration?: boolean
  expirationType?: number
  expirationOffset?: number
  expireAfterFirstSend?: boolean
  enablePayLater: boolean
  enablePayFullAmountNow: boolean
  grandRecurringTotal: number
  hash: string
  isActive: boolean
  isConfirmed: boolean
  isConverted: boolean
  lastEmailTimestamp: string
  leadSourceType: LeadSourceType
  managedId: string
  message: string
  modifiedById: number
  paymentMethods: PaymentMethod[]
  convertedPaymentMethodTypeId: number
  paymentPolicy: string
  pdfUri: string
  quoteId: number
  recurringAmountDueToday: number
  referralMargin: string
  referralPercentage: string
  referralTotal: string
  reservationIds: number[]
  quoteStatusId: number
  status: string
  successful: boolean
  trips: Trip[]
  updatedOn: string
  temp: any // TODO: remove after backend changes
  terms: Terms[]
  sendAsInvoice?: boolean
  requireSignatureUponCheckout?: boolean
  enablePONumber?: boolean
  poNumber?: string
}

// eslint-disable-next-line no-redeclare
export class Quote implements Quote {
  constructor(quote?: Partial<Quote>) {
    this.isActive = true
    this.isConverted = false
    this.customerId = 0
    this.quoteType = 1
    this.quotePriorityId = QuotePriorityTypeId.None
    this.quoteStatusId = QuoteStatusTypeId.Lead
    this.trips = [new Trip()]
    Object.assign(this, quote)
  }

  public static status(
    quote: Quote
  ): 'lead' | 'sent' | 'converted' | 'lost' | 'dont_quote' | 'sold_out' {
    if (!quote) {
      return null
    }
    return quote.isConverted ? 'converted' : 'lead'
  }

  public static label(quote: Quote): string {
    const status = this.status(quote)
    return status ? status[0].toUpperCase() + status.substring(1) : ''
  }
}

export interface QuoteEmail extends Email {
  quoteId: number
  includePdf: boolean
  timezone: string
}

// eslint-disable-next-line no-redeclare
export class QuoteEmail implements QuoteEmail {
  constructor(quoteEmail?: Partial<QuoteEmail>) {
    Object.assign(this, quoteEmail)
  }
}
