export type AccessSetting = {
  enabled: boolean
  label: string
  roleName: string
}

export type AccessSettings = {
  quotes: AccessSetting
  reservations: AccessSetting
  dispatch: AccessSetting
  contacts: AccessSetting
  operations: AccessSetting
  companySettings: AccessSetting
  reservationSummary: AccessSetting
}

export const ACCESS_SETTINGS_ROLES = {
  QUOTES: 'can_view_quotes',
  RESERVATIONS: 'can_view_reservations',
  DISPATCH: 'can_view_dispatch',
  CONTACTS: 'can_view_contacts',
  OPERATIONS: 'can_view_operations',
  COMPANY_SETTINGS: 'can_view_company_settings',
  RESERVATION_SUMMARY: 'can_export_reservation_summary',
}

export const ACCESS_SETTINGS_LABELS = {
  QUOTES: 'Quotes',
  RESERVATIONS: 'Reservations',
  DISPATCH: 'Dispatch',
  CONTACTS: 'Contacts',
  OPERATIONS: 'Operations',
  COMPANY_SETTINGS: 'Company Settings',
  RESERVATION_SUMMARY: 'Reservation Summary',
}

export const DEFAULT_ACCESS_SETTINGS: AccessSettings = {
  quotes: {
    enabled: true,
    label: ACCESS_SETTINGS_LABELS.QUOTES,
    roleName: ACCESS_SETTINGS_ROLES.QUOTES,
  },
  reservations: {
    enabled: true,
    label: ACCESS_SETTINGS_LABELS.RESERVATIONS,
    roleName: ACCESS_SETTINGS_ROLES.RESERVATIONS,
  },
  dispatch: {
    enabled: true,
    label: ACCESS_SETTINGS_LABELS.DISPATCH,
    roleName: ACCESS_SETTINGS_ROLES.DISPATCH,
  },
  contacts: {
    enabled: true,
    label: ACCESS_SETTINGS_LABELS.CONTACTS,
    roleName: ACCESS_SETTINGS_ROLES.CONTACTS,
  },
  operations: {
    enabled: true,
    label: ACCESS_SETTINGS_LABELS.OPERATIONS,
    roleName: ACCESS_SETTINGS_ROLES.OPERATIONS,
  },
  companySettings: {
    enabled: true,
    label: ACCESS_SETTINGS_LABELS.COMPANY_SETTINGS,
    roleName: ACCESS_SETTINGS_ROLES.COMPANY_SETTINGS,
  },
  reservationSummary: {
    enabled: true,
    label: ACCESS_SETTINGS_LABELS.RESERVATION_SUMMARY,
    roleName: ACCESS_SETTINGS_ROLES.RESERVATION_SUMMARY,
  },
}
