
import { Note } from '@/models/dto/Note'
import sidebar from '@/store/modules/sidebar'
import { Vue, Component, Prop } from 'vue-property-decorator'
import _cloneDeep from 'lodash.clonedeep'
import reservation from '@/store/modules/reservation'
import { SourceCategory } from '@/utils/enum'

@Component({})
export default class ReservationNotesSidebar extends Vue {
  @Prop({ required: false, default: null }) tripNotes: Note
  @Prop({ required: false, default: null }) internalNotes: Note
  @Prop({ required: false, default: null }) driverNotes: Note

  newTripNote: Note = new Note()
  newInternalNote: Note = new Note()
  newDriverNote: Note = new Note()
  loading = false

  get isReferral(): boolean {
    return reservation.reservation?.sourceCategory === SourceCategory.REFERRAL
  }

  handleTripNoteTextChange(text: string): void {
    this.newTripNote.note = text
  }

  handleTripNoteHtmlChange(html: string): void {
    this.newTripNote.html = html
  }

  handleInternalNoteTextChange(text: string): void {
    this.newInternalNote.note = text
  }

  handleInternalNoteHtmlChange(html: string): void {
    this.newInternalNote.html = html
  }

  handleDriverNoteTextChange(text: string): void {
    this.newDriverNote.note = text
  }

  handleDriverNoteHtmlChange(html: string): void {
    this.newDriverNote.html = html
  }

  close(): void {
    sidebar.pop()
  }

  submit(): void {
    this.loading = true
    this.$emit('note:change', {
      trip: this.newTripNote,
      internal: this.newInternalNote,
      driver: this.newDriverNote,
    })
  }

  mounted(): void {
    this.newTripNote = this.tripNotes ? _cloneDeep(this.tripNotes) : new Note()
    this.newDriverNote = this.driverNotes
      ? _cloneDeep(this.driverNotes)
      : new Note()
    this.newInternalNote = this.internalNotes
      ? _cloneDeep(this.internalNotes)
      : new Note()
  }
}
