import { DRAFT, Invoice, InvoicePagedResult } from '@/models/Invoice'
import { PaystubPagedResult } from '@/models/DriverPay'
import { TableViewParameters } from '@/models/TableView'
import { client } from '@/services/apollo/config'
import { isoToJSDate } from '@/utils/date'
import { gql } from 'apollo-boost'
import { apiBaseUrl } from '@/utils/env'
import { HttpService } from './common/HttpService'
import dayjs from 'dayjs'

const httpService: HttpService = new HttpService()

// INVOICES

const LIST_INVOICES_QUERY = `
  query ListInvoices(
    $reservationId: Int
    $managedId: String
    $start: String
    $stop: String
    $driverIds: [Int]
    $status: [String]
    $sort: String
    $sortType: String
    $page: Int
    $size: Int
  ) {
    listInvoices(
      reservationId: $reservationId
      managedId: $managedId
      start: $start
      stop: $stop
      driverIds: $driverIds
      status: $status
      sort: $sort
      sortType: $sortType
      page: $page
      size: $size
    ) {
      content {
        id
        reservationId
        managedId
        start
        startTimezone
        stop
        stopTimezone
        status
        totalCharges
        duplicateIndex
        driver {
          userId
          name
        }
        company {
          customerAccountId
          name
        }
        bookingContact {
          userId
          name
        }
        reservation {
          reservationId
          reservationStatusKey
        }
      }
      totalElements
      totalPages
      numberOfElements
    }
  }
`

export async function fetchInvoices(
  params: TableViewParameters
): Promise<InvoicePagedResult> {
  const { page, pageSize, sorts, sortDesc, filters } = params
  const { start, driverIds, status, reservationId, managedId } = filters
  const startDate = start?.length >= 1 ? isoToJSDate(start[0]) : undefined
  const stopDate =
    start?.length === 2
      ? isoToJSDate(dayjs(start[1]).add(1, 'day').format('YYYY-MM-DD'))
      : undefined

  const result = await client.query({
    query: gql(LIST_INVOICES_QUERY),
    variables: {
      reservationId: filters.reservationId
        ? Number(filters.reservationId)
        : undefined,
      managedId: filters.managedId?.length ? filters.managedId : undefined,
      start: startDate,
      stop: stopDate,
      driverIds: driverIds?.map((driverId: string) => Number(driverId)),
      status,
      sort: sorts,
      sortType: sortDesc ? 'desc' : 'asc',
      page: page - 1,
      size: pageSize,
    },
    fetchPolicy: 'no-cache',
  })

  return result.data.listInvoices
}

export function exportInvoices(params: TableViewParameters): Promise<any> {
  const { page, pageSize, sorts, filters, sortDesc } = params
  const { start, driverIds, status, reservationId, managedId } = filters

  const startDate = start?.length >= 1 ? isoToJSDate(start[0]) : undefined
  const stopDate = start?.length === 2 ? isoToJSDate(start[1]) : undefined

  const host = apiBaseUrl()
  const url = `https://${host}/download/csv`
  return httpService.post(
    url,
    {
      operationName: 'ListInvoices',
      query: LIST_INVOICES_QUERY,
      variables: {
        reservationId: filters.reservationId
          ? Number(filters.reservationId)
          : undefined,
        managedId: filters.managedId?.length ? filters.managedId : undefined,
        start: startDate,
        stop: stopDate,
        driverIds: driverIds?.map((driverId: string) => Number(driverId)),
        status,
        sort: sorts,
        sortType: sortDesc ? 'desc' : 'asc',
        page: page - 1,
        size: pageSize,
      },
    },
    {
      responseType: 'blob',
    }
  )
}

const LIST_UNPAID_INVOICE_IDS = gql`
  query ListInvoices($status: [String], $page: Int, $size: Int) {
    listInvoices(status: $status, page: $page, size: $size) {
      content {
        id
        reservationId
      }
    }
  }
`

export async function getAllDraftInvoices(): Promise<{
  data: Partial<Invoice>[]
}> {
  const result = await client.query({
    query: LIST_UNPAID_INVOICE_IDS,
    variables: {
      status: [DRAFT],
      page: 0,
      size: 50,
    },
  })

  const invoices = result.data?.listInvoices?.content || []
  return { data: invoices }
}

const LIST_ALL_INVOICE_DRIVERS = gql`
  query ListInvoices($page: Int, $size: Int) {
    listInvoices(page: $page, size: $size) {
      content {
        driver {
          userId
          name
        }
      }
    }
  }
`

export async function getAllDrivers(): Promise<{
  data: Partial<Invoice>[]
}> {
  const result = await client.query({
    query: LIST_ALL_INVOICE_DRIVERS,
    variables: {
      page: 0,
      size: 100,
    },
  })

  const invoices = result.data?.listInvoices?.content || []
  return { data: invoices }
}

// PAYSTUBS

const LIST_PAYSTUBS_QUERY = `
  query ListPaystubs(
    $label: String
    $status: [String]
    $driverIds: [Int]
    $total: Float
    $start: String
    $stop: String
    $sort: String
    $sortType: String
    $page: Int
    $size: Int
  ) {
    listPaystubs(
      label: $label
      status: $status
      driverIds: $driverIds
      total: $total
      start: $start
      stop: $stop
      sort: $sort
      sortType: $sortType
      page: $page
      size: $size
    ) {
      content {
        driver {
          name
          userId
          email
        }
        id
        label
        paidDate
        earliestPickupTimestamp
        latestDropoffTimestamp
        status
        total
        reservationManagedIds
      }
      totalElements
      totalPages
      numberOfElements
    }
  }
`

export async function fetchPaystubs(
  params: TableViewParameters
): Promise<PaystubPagedResult> {
  const { page, pageSize, sorts, sortDesc, filters } = params
  const { driverIds, paidDate } = filters

  const label = filters?.label?.length ? filters.label : undefined

  const startDate = paidDate?.length >= 1 ? isoToJSDate(paidDate[0]) : undefined
  const stopDate = paidDate?.length === 2 ? isoToJSDate(paidDate[1]) : undefined

  const result = await client.query({
    query: gql(LIST_PAYSTUBS_QUERY),
    variables: {
      label,
      status: undefined,
      driverIds: driverIds?.map((driverId: string) => Number(driverId)),
      start: startDate,
      stop: stopDate,
      total: undefined,
      sort: sorts,
      sortType: sortDesc ? 'desc' : 'asc',
      page: page - 1,
      size: pageSize,
    },
    fetchPolicy: 'no-cache',
  })

  return result.data.listPaystubs
}

export function exportPaystubs(params: TableViewParameters): Promise<any> {
  const { page, pageSize, sorts, filters } = params
  const { start, driverIds, status, reservationId, label } = filters

  const startDate = start?.length >= 1 ? isoToJSDate(start[0]) : undefined
  const stopDate = start?.length === 2 ? isoToJSDate(start[1]) : undefined

  const host = apiBaseUrl()
  const url = `https://${host}/download/csv`
  return httpService.post(
    url,
    {
      operationName: 'ListPaystubs',
      query: LIST_PAYSTUBS_QUERY,
      variables: {
        reservationId: filters.reservationId
          ? Number(filters.reservationId)
          : undefined,
        managedId: filters.managedId?.length ? filters.managedId[0] : undefined,
        start: startDate,
        stop: stopDate,
        driverIds: driverIds?.map((driverId: string) => Number(driverId)),
        status,
        label,
        sort: sorts,
        page: page - 1,
        size: pageSize,
      },
    },
    {
      responseType: 'blob',
    }
  )
}
