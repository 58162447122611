import { UserDetail, UserRequest, UserResult, UsersResult } from '@/models/dto'
import { RoleRequest, RoleResult } from '@/models/dto/Role'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { HttpService } from '@/services/common/HttpService'
import { apiBaseUrl } from '@/utils/env'
import { filter } from '@/utils/filter'
import { AxiosResponse } from 'axios'

const httpService: HttpService = new HttpService()

export default {
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<UserDetail>>> {
    const { sorts = null, filters = null, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = apiBaseUrl()

    return httpService.get(`https://${host}/tables/users?${query}`)
  },
  usersWhoHaveSentQuotes(): Promise<AxiosResponse<UsersResult>> {
    return httpService.get(`https://${apiBaseUrl()}/user/sentBy`)
  },
  usersWhoHaveCreatedQuotes(): Promise<AxiosResponse<UsersResult>> {
    return httpService.get(`https://${apiBaseUrl()}/user/createdBy`)
  },
  byId(userId: number): Promise<AxiosResponse<UserResult>> {
    return httpService.get(`https://${apiBaseUrl()}/user/${userId}`)
  },
  byHash(userHash: string): Promise<AxiosResponse<UserResult>> {
    return httpService.post(`https://${apiBaseUrl()}/user/getUserByHash`, {
      userHash,
    })
  },
  delete(userId: number): Promise<AxiosResponse> {
    return httpService.delete(`https://${apiBaseUrl()}/user/${userId}`)
  },
  roles(userId: number): Promise<AxiosResponse<RoleResult>> {
    const host = apiBaseUrl()
    const url = `https://${host}/user/roles/${userId}`

    return httpService.get(url)
  },
  setPassword(
    userId: number,
    password: string
  ): Promise<AxiosResponse<UserResult>> {
    return httpService.post(
      `https://${apiBaseUrl()}/users/admin-set-password/`,
      {
        id: String(userId),
        password,
      }
    )
  },
  changePassword(
    userId: number,
    new_password: string,
    password: string
  ): Promise<AxiosResponse<UserResult>> {
    return httpService.post(`https://${apiBaseUrl()}/users/set-password/`, {
      id: String(userId),
      new_password,
      password,
    })
  },
  setPasswordWithHash(
    hash: string,
    password: string
  ): Promise<AxiosResponse<UserResult>> {
    const url = `https://${apiBaseUrl()}/password/reset`
    return httpService.post(url, { hash, password })
  },
  forgotPassword(email: string): Promise<AxiosResponse> {
    return httpService.post(`https://${apiBaseUrl()}/user/forgotPassword`, {
      email,
    })
  },
  validateUser(hash: string): Promise<AxiosResponse<UserDetail>> {
    const url = `https://${apiBaseUrl()}/user/validate`
    return httpService.post(url, { userHash: hash })
  },
  create(user: Partial<UserRequest>): Promise<AxiosResponse<UserResult>> {
    return httpService.post(`https://${apiBaseUrl()}/user`, user)
  },
  checkIfEmailExists(
    email: string
  ): Promise<AxiosResponse<{ exists: boolean }>> {
    return httpService.get(`https://${apiBaseUrl()}/users/check-email/${email}`)
  },
  update(userId: number, user: UserDetail): Promise<AxiosResponse<string>> {
    return httpService.patch(`https://${apiBaseUrl()}/user/${userId}`, user)
  },
  uploadUserPhoto(
    userId: number,
    photo: FormData
  ): Promise<AxiosResponse<boolean>> {
    return httpService.post(
      `https://${apiBaseUrl()}/v2/photos/users/${userId}/userPhotos`,
      photo
    )
  },
  getQuoteOwners({
    pageSize = 10,
    page = 1,
  }): Promise<AxiosResponse<TableViewResult<UserDetail>>> {
    // const query = `page=1&pageSize=-1&sort%5B0%5D%5Bfield%5D=lastName&sort%5B0%5D%5Bdir%5D=desc&shortHand=1&filterfs0l=or&filterfs0fs0fd=groupName&filterfs0fs0op=contains&filterfs0fs0v=user&filterfs0fs1fd=groupName&filterfs0fs1op=contains&filterfs0fs1v=admin`
    const filters = filter()
    const groupNameFilters = [
      {
        column: {
          _t_id: '1ce4a524-5112-432c-ad0f-9dedf6cc8afe',
          filterType: 'contains',
          filterProp: 'groupName',
          filterAsIs: true,
        },
        columnId: 'bd5101c8-cc23-4b3b-be62-e598d9e5e78d',
        value: 'admin',
      },
      {
        column: {
          _t_id: 'b702f7c4-bf74-416a-b0da-b8f803111d91',
          filterType: 'contains',
          filterProp: 'groupName',
          filterAsIs: true,
        },
        columnId: 'bd5101c8-cc23-4b3b-be62-e598d9e5e78d',
        value: 'user',
      },
    ]

    const topLevelFilter = filters.createParent('or')
    for (const filter of groupNameFilters) {
      // const parentFilter = this.filters.createParent('and', topLevelFilter)
      filters.add(topLevelFilter, filter)
    }

    let query = `page=${page}&pageSize=${pageSize}`
    query += `&${filters.asQueryParams()}`
    query = encodeURI(query)

    return httpService.get(`https://${apiBaseUrl()}/tables/users?${query}`)
  },
  determineRoles(payload: RoleRequest): Promise<AxiosResponse<RoleResult>> {
    return httpService.put(
      `https://${apiBaseUrl()}/user/determineRoles`,
      payload
    )
  },
}
