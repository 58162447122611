import { Type } from '@/models/dto/Type'

export interface LineItemChargeType extends Type {
  id: number
}

export interface LineItemSectionType extends Type {
  id: number
}

export interface LineItemCharge {
  id: number
  isActive: boolean
  isEnabled: boolean
  isAutomatic: boolean
  lineNumber: number
  name: string
  quantity: number
  percentage: number
  rate: number
  createdOn: string
  updatedOn: string
  tripId: number
  lineItemChargeTypeId: number
  lineItemSectionTypeId: number
  lineItemChargeType: LineItemChargeType
  lineItemSectionType: LineItemSectionType
  displayAmount?: number
}

// eslint-disable-next-line no-redeclare
export class LineItemCharge implements LineItemCharge {
  constructor(lineItemCharge?: Partial<LineItemCharge>) {
    this.isActive = true
    this.isEnabled = true
    this.isAutomatic = false
    this.quantity = 1
    Object.assign(this, lineItemCharge)
  }
}

export interface FlatCompanyChargeOption {
  id: number
  companyId: number
  isDefault: boolean // Required
  name: string
  percentage: number
  rate: number
  isPerDriver: boolean // Required
  isPerPassenger: boolean // Required
  isPerVehicle: boolean // Required
  lineItemChargeTypeId: number
  lineItemChargeTypeKey: string
  lineItemChargeTypeLabel: string
  lineItemSectionTypeId: number
  lineItemSectionTypeKey: string
  lineItemSectionTypeLabel: string
}

export interface CompanyChargeOption {
  id: number
  companyId: number
  isDefault: boolean // Required
  name: string
  percentage: number
  rate: number
  quantity?: number
  isPerDriver: boolean // Required
  isPerPassenger: boolean // Required
  isPerVehicle: boolean // Required
  lineItemChargeTypeId: number
  lineItemSectionTypeId: number
  lineItemChargeType: LineItemChargeType
  lineItemSectionType: LineItemSectionType
}

export interface CreateCompanyChargeOptionRequest {
  isDefault: boolean
  name: string
  lineItemChargeTypeId: number
  lineItemSectionTypeId: number
  percentage: number
  rate: number
  isPerDriver: boolean
  isPerPassenger: boolean
  isPerVehicle: boolean
}

// eslint-disable-next-line no-redeclare
export class CompanyChargeOption implements CompanyChargeOption {
  constructor(companyChargeOption?: Partial<CompanyChargeOption>) {
    this.isDefault = false
    Object.assign(this, companyChargeOption)
  }
}

export const toCompanyChargeOption = (
  flatCompanyChargeOption: FlatCompanyChargeOption
): CompanyChargeOption => {
  return flatCompanyChargeOption == null
    ? null
    : {
        id: flatCompanyChargeOption.id,
        companyId: flatCompanyChargeOption.companyId,
        isDefault: !!flatCompanyChargeOption.isDefault,
        name: flatCompanyChargeOption.name,
        percentage: flatCompanyChargeOption.percentage,
        rate: flatCompanyChargeOption.rate,
        isPerDriver: !!flatCompanyChargeOption.isPerDriver,
        isPerPassenger: !!flatCompanyChargeOption.isPerPassenger,
        isPerVehicle: !!flatCompanyChargeOption.isPerVehicle,
        lineItemChargeTypeId: flatCompanyChargeOption.lineItemChargeTypeId,
        lineItemSectionTypeId: flatCompanyChargeOption.lineItemSectionTypeId,
        lineItemChargeType: {
          id: flatCompanyChargeOption.lineItemChargeTypeId,
          key: flatCompanyChargeOption.lineItemChargeTypeKey,
          label: flatCompanyChargeOption.lineItemChargeTypeLabel,
        },
        lineItemSectionType: {
          id: flatCompanyChargeOption.lineItemSectionTypeId,
          key: flatCompanyChargeOption.lineItemSectionTypeKey,
          label: flatCompanyChargeOption.lineItemSectionTypeLabel,
        },
      }
}
