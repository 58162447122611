
import { Stop } from '@/models/dto'
import { Garage } from '@/models/dto/Garage'
import { FormattedNote } from '@/models/dto/Note'
import { stopTitle } from '@/utils/stop'
import { getWidthOfTextInFont } from '@/utils/string'
import { numberToPixels } from '@/utils/style'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({})
export default class StopWithNotes extends Vue {
  @Prop({ required: false, default: null }) stop: Stop
  @Prop({ required: false, default: null }) garage: Garage
  @Prop({ required: false, default: null }) garageNote: string
  @Prop({ required: false, default: [] }) addresses: string[]
  @Prop({ required: false, default: null }) title: string
  @Prop({ required: false, default: '200' }) addressMinWidth!: number
  @Prop({ type: Boolean, default: false }) addressMaxWidth!: boolean
  @Prop({ type: Boolean, default: false }) hideAddNotes: boolean
  @Prop({ type: Boolean, default: false }) showEditNote: boolean
  @Prop({ type: Boolean, default: false }) blueLink: boolean

  expanded = false
  canvas = null
  isMounted = false
  forceRefresh = 0
  addressWidth = 0
  debounce: number | null = null

  @Watch('formattedNotes')
  handleUpdatedNotes(): void {
    this.expanded = false
  }

  get ref(): string {
    return `stop-note-${this.stop?.stopId || this.garage?.garageId || 0}`
  }

  get stopTitle(): string {
    return this.stop ? stopTitle(this.stop) : null
  }

  get noteMaxWidth(): number {
    if (this.addressWidth > 260) {
      return this.addressWidth
    }
    return 260
  }

  get fullStopNote(): string {
    return (
      this.garageNote ||
      this.stop.stopNotes?.map((el) => el.html || el.note)?.join(' ') ||
      this.stop.notes
    )
  }

  get simpleNote(): string {
    return this.formattedNotes?.notes || ''
  }

  get formattedNotes(): FormattedNote {
    const r = this.forceRefresh
    const clearNewLines = /<[ul,li,/ul,/li,p,/p,br]*>/g
    if (!(this.stop || this.garage) || !this.isMounted) {
      return null
    }
    if (this.garage) {
      return {
        canExpand: this.canExpand(this.garageNote),
        notes: this.garageNote?.replaceAll(clearNewLines, ' '),
      }
    } else if (!this.stop?.stopNotes?.length && this.stop?.notes) {
      return {
        canExpand: this.canExpand(this.stop.notes),
        notes: this.stop.notes.replaceAll(clearNewLines, ' '),
      }
    }
    const reducedNote =
      this.stop?.stopNotes?.map((el) => el.html || el.note).join(' ') || ''
    return {
      canExpand: this.canExpand(reducedNote),
      notes: reducedNote.replaceAll(clearNewLines, ' '),
    }
  }

  get minWidth(): string {
    return this.$vuetify.breakpoint.width >= 700
      ? numberToPixels(this.addressMinWidth)
      : '200px'
  }

  canExpand(note: string): boolean {
    if (!note) {
      return false
    }
    return (
      getWidthOfTextInFont(
        note.replaceAll(/<[^>]*>/g, ''),
        '14px',
        '400',
        this.canvas
      ) >
        this.addressWidth - 82 || note.includes('<ul>')
    )
  }

  handleShowNote(note: FormattedNote): void {
    this.expanded = note.canExpand && !this.expanded
  }

  handleResize(): void {
    if (this.debounce) {
      window.clearTimeout(this.debounce)
    }
    this.debounce = window.setTimeout(() => {
      this.forceRefresh += 1
      const newWidth = (this.$refs[this.ref] as any)?.scrollWidth
      if (newWidth) {
        this.addressWidth = newWidth
      }
    }, 250)
  }

  mounted(): void {
    window.addEventListener('resize', this.handleResize)
    this.isMounted = true
    this.addressWidth = (this.$refs[this.ref] as any)?.scrollWidth || 0
  }

  unmounted(): void {
    window.removeEventListener('resize', this.handleResize)
  }
}
