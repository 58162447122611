import { Action, Module, VuexModule } from 'vuex-class-modules'
import store from '@/store'
import { Reservation } from '@/models/dto/Reservation'
import {
  Trip,
  TripEstimation,
  TripType,
  Vehicle,
  VehicleAssignment,
  VehicleType,
} from '@/models/dto'
import { Pricing, TripPricing } from '@/models/dto/Pricing'
import {
  ReservationInformation,
  ReservationLoadingInformation,
} from '@/models/ReservationInformation'
import { QuotePricing } from '@/models/QuotePricing'
import { TrackingResponse } from '@/models/dto/Tracking'
import { TripPricings } from '@/models/TripPricings'
import {
  convertTripPricingToBaseFarePricing,
  quotePricing,
} from '@/utils/pricing'
import { Invoice, InvoiceStatus } from '@/models/Invoice'
import { getInvoices, getTripPricing } from '@/utils/reservation'
import {
  BillingOverride,
  BillingOverrideViewResult,
} from '@/models/BillingOverride'
import { PaymentTransaction } from '@/models/dto/PaymentTransaction'
import { Quote } from '@/models/dto/Quote'
import { getMinutesBetweenDates } from '@/utils/time'
import { File as CustomFile } from '@/models/dto/File'
import { ReservationInvoiceDetails } from '@/models/dto/Quickbooks'
import quickbooks from '@/services/quickbooks'
import auth from './auth'
import { TripPricingDetail } from '@/models/dto/TripPricingDetail'

@Module({ generateMutationSetters: true })
class ReservationModule extends VuexModule {
  _reservation: Reservation = null
  _estimations: TripEstimation = null
  _trip: Trip = null
  _pricing: QuotePricing = null
  _assignments: VehicleAssignment[] = null
  _tracking: TrackingResponse = null
  _tripHasBeenModified = false
  _invoices: Invoice[] = []
  _billingOverrideView: BillingOverrideViewResult = null
  _transactions: PaymentTransaction[] = []
  _quote: Quote = null
  _reservationInvoiceDetails: ReservationInvoiceDetails = null

  get getTripPricings(): TripPricings {
    const trip: Trip = this.trip
    const tripPricings = trip?.tripPricings

    const pricingDaily: TripPricing = this.pricing?.daily[trip?.tripId]
    const pricingHourly: TripPricing = this.pricing?.hourly[trip?.tripId]
    const pricingMileage: TripPricing = this.pricing?.mileage[trip?.tripId]

    if (tripPricings) {
      let tripPricingDaily = tripPricings.find(
        (tp) => tp.pricingTypeKey === 'daily'
      ) as any
      let tripPricingHourly = tripPricings.find(
        (tp) => tp.pricingTypeKey === 'hourly'
      ) as any
      let tripPricingMileage = tripPricings.find(
        (tp) => tp.pricingTypeKey === 'mileage'
      ) as any

      tripPricingDaily = convertTripPricingToBaseFarePricing(
        tripPricingDaily,
        pricingDaily
      )
      tripPricingHourly = convertTripPricingToBaseFarePricing(
        tripPricingHourly,
        pricingHourly
      )
      tripPricingMileage = convertTripPricingToBaseFarePricing(
        tripPricingMileage,
        pricingMileage
      )

      if (tripPricingDaily && tripPricingHourly && tripPricingMileage) {
        return {
          pricingDaily: tripPricingDaily,
          pricingHourly: tripPricingHourly,
          pricingMileage: tripPricingMileage,
        }
      }
    }

    return { pricingDaily, pricingHourly, pricingMileage }
  }

  get reservation(): Reservation {
    return this._reservation
  }

  get reservationInvoiceDetails(): ReservationInvoiceDetails {
    return this._reservationInvoiceDetails
  }

  get isReservationSyncedWithQuickbooks(): boolean {
    return (
      (auth.isQuickbooksIntegrationEnabled &&
        !!this.reservationInvoiceDetails?.invoiceId) ||
      false
    )
  }

  get isReservationQuickbooksInvoiceVoided(): boolean {
    return this.reservationInvoiceDetails?.voided || false
  }

  get isReservationQuickbooksChargesAndPaymentsSyncEnabled(): boolean {
    return (
      this.reservationInvoiceDetails?.paymentsAndChargesSyncEnabled || false
    )
  }

  get isReservationQuickbooksInvoicePending(): boolean {
    return (
      this.reservationInvoiceDetails?.invoiceCreationStatus === 'PENDING' ||
      false
    )
  }

  get hasBillingOverrides(): boolean {
    return !!this._billingOverrideView?.vehicles?.length
  }

  get estimations(): TripEstimation {
    return this._estimations
  }

  get trip(): Trip {
    return this._trip
  }

  get pricing(): QuotePricing {
    return this._pricing
  }

  get assignments(): VehicleAssignment[] {
    return this._assignments
  }

  get tracking(): TrackingResponse {
    return this._tracking
  }

  get tripHasBeenModified(): boolean {
    return this._tripHasBeenModified
  }

  get invoices(): Invoice[] {
    return this._invoices
  }

  get baseFare(): number {
    const charges = this._trip.charges
    const baseFareCharge = charges.find((c) => c.chargeType.key === 'base_fare')
    if (baseFareCharge) {
      return baseFareCharge.amount
    }
    return 0
  }

  get baseFareCharge(): number {
    const baseFareTransaction = this._transactions
      .filter((t) => t.description === 'Base Fare')
      .sort((a, b) => (a.createdOn > b.createdOn ? 1 : -1))[0]
    return baseFareTransaction?.amount || 0
  }

  get firstTimeZone(): string {
    if (
      this._trip?.garageTimes?.departureTime &&
      this._trip?.garageTimes?.garage?.address?.timeZone
    ) {
      return this._trip?.garageTimes?.garage?.address?.timeZone
    }

    const firstStop = this._trip?.stops?.[0]
    return firstStop?.address?.timeZone
  }

  get firstDatetime(): string {
    if (this._trip?.garageTimes?.preTripArrivalTime) {
      return this._trip?.garageTimes?.preTripArrivalTime
    } else if (this._trip?.garageTimes?.departureTime) {
      return this._trip?.garageTimes?.departureTime
    } else {
      const firstStop = this._trip?.stops?.[0]
      return firstStop.pickupDatetime
    }
  }

  // Similar to the above firstDatetime, except we exclude a pre-trip arrival time
  get firstPostTripDatetime(): string {
    if (this._trip?.garageTimes?.departureTime) {
      return this._trip?.garageTimes?.departureTime
    } else {
      const firstStop = this._trip?.stops?.[0]
      return firstStop?.pickupDatetime
    }
  }

  get lastPostTripDatetime(): string {
    if (this._trip?.garageTimes?.returnTime) {
      return this._trip?.garageTimes?.returnTime
    } else {
      const lastStop = this._trip?.stops?.[this._trip?.stops?.length - 1]
      return lastStop?.dropoffDatetime
    }
  }

  get garageDepartureTime(): string {
    return this._trip?.garageTimes?.departureTime
  }

  get firstStopDatetime(): string {
    const firstStop = this._trip?.stops?.[0]
    return firstStop.pickupDatetime
  }

  get firstStopTimeZone(): string {
    const firstStop = this._trip?.stops?.[0]
    return firstStop?.address?.timeZone
  }

  get lastStopDatetime(): string {
    const lastStop = this._trip?.stops?.[this._trip?.stops?.length - 1]
    return lastStop.dropoffDatetime
  }

  get lastStopTimeZone(): string {
    const lastStop = this._trip?.stops?.[this._trip?.stops?.length - 1]
    return lastStop?.address?.timeZone
  }

  get lastDatetime(): string {
    if (this._trip?.garageTimes?.returnTime) {
      return this._trip?.garageTimes?.returnTime
    } else {
      const lastStop = this._trip?.stops?.[this._trip?.stops?.length - 1]
      return lastStop.dropoffDatetime
    }
  }

  get lastTimeZone(): string {
    if (this._trip?.garageTimes?.returnTime) {
      return this._trip?.garageTimes?.returnGarage?.address?.timeZone
    }

    const lastStop = this._trip?.stops?.[this._trip?.stops?.length - 1]
    return lastStop?.address?.timeZone
  }

  get billingOverrideView() {
    return this._billingOverrideView
  }

  get transactions(): PaymentTransaction[] {
    return this._transactions
  }

  get quote(): Quote {
    return this._quote
  }

  get postTripDistanceMeters(): number {
    const liveDistance = this._estimations?.distance || 0
    const deadDistance = this._estimations?.deadDistance || 0
    return liveDistance + deadDistance
  }

  get postTripDistanceMiles(): number {
    const totalDistance = this.postTripDistanceMeters
    const totalDistanceInMiles = (totalDistance / 1609.344).toFixed(2)
    return parseFloat(totalDistanceInMiles)
  }

  @Action
  setTransactions(transactions: PaymentTransaction[]): void {
    this._transactions = transactions
  }

  @Action
  setInvoices(invoices: Invoice[]): void {
    this._invoices = invoices
  }

  @Action
  setPartialReservationInvoiceDetails(
    partialInvoiceReservationDetails: Partial<ReservationInvoiceDetails>
  ): void {
    this._reservationInvoiceDetails = {
      ...this._reservationInvoiceDetails,
      ...partialInvoiceReservationDetails,
    }
  }

  @Action
  async fetchReservationInvoiceDetails(): Promise<void> {
    await this.retrieveReservationInvoiceDetails()
    if (this._reservationInvoiceDetails?.invoiceCreationStatus === 'PENDING') {
      this.retryInvoiceFetch()
    }
  }

  @Action
  async loadPricingInfo(): Promise<void> {
    const tripPricing = await getTripPricing(this.quote, this.trip)
    this.setPricing(tripPricing)
  }

  async retrieveReservationInvoiceDetails(): Promise<void> {
    try {
      const reservationInvoiceDetailsResponse =
        // eslint-disable-next-line no-await-in-loop
        await quickbooks.getInvoiceReservationDetails(
          auth.getCompanyId,
          this.reservation.reservationId
        )
      this._reservationInvoiceDetails =
        reservationInvoiceDetailsResponse?.data?.invoiceDetails
    } catch (e) {
      console.log(e)
      this._reservationInvoiceDetails = null
    }
  }

  async retryInvoiceFetch(): Promise<void> {
    // This will attempt to hit the endpoint every 3 seconds for a limit of 3 times in order to attempt to retrieve a non-pending status for the invoice
    for (let i = 0; i < 3; i++) {
      if (
        this._reservationInvoiceDetails?.invoiceCreationStatus === 'PENDING'
      ) {
        // eslint-disable-next-line no-await-in-loop
        await this.delay(3)
        // eslint-disable-next-line no-await-in-loop
        await this.retrieveReservationInvoiceDetails()
      }
    }
    if (
      this._reservationInvoiceDetails?.invoiceCreationStatus === 'FAILED' ||
      this._reservationInvoiceDetails?.invoiceCreationStatus === 'PENDING'
    ) {
      this._reservationInvoiceDetails = null
    }
  }

  delay(seconds: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(resolve, seconds * 1000)
    })
  }

  @Action
  async fetchInvoices(): Promise<void> {
    const driverPayInvoices = await getInvoices(this._reservation.reservationId)
    this.setInvoices(driverPayInvoices)
  }

  @Action
  updateInvoice({
    invoiceId,
    invoiceStatus,
    invoiceTotal,
  }: {
    invoiceId: string
    invoiceStatus: InvoiceStatus
    invoiceTotal: number
  }): void {
    const invoice = this._invoices.find((i) => i.id === invoiceId)
    if (!invoice) {
      return
    }
    invoice.status = invoiceStatus
    invoice.totalCharges = invoiceTotal
  }

  @Action
  setReservationInformation(
    reservationInformation: ReservationInformation
  ): void {
    this.setReservation(reservationInformation.reservation)
    this.setTrip(reservationInformation.trip)
    this.setTripEstimations(reservationInformation.tripEstimation)
    this.setTracking(reservationInformation.tracking)
    this.setAssignments(reservationInformation.assignments)
    this.setInvoices(reservationInformation.invoices)
    this.setQuote(reservationInformation.quote)
    if (reservationInformation.pricing) {
      this.setPricing(reservationInformation.pricing)
    }
  }

  @Action
  setReservation(reservation: Reservation): void {
    this._reservation = reservation
  }

  @Action
  setQuote(quote: Quote): void {
    this._quote = quote
  }

  @Action
  setTripEstimations(tripEstimations: TripEstimation): void {
    this._estimations = tripEstimations

    if (tripEstimations && this.trip) {
      const trip = this.trip
      trip.distance = tripEstimations.distance
      trip.drivingTime = tripEstimations.duration

      this.updateTrip(trip)
    }
  }

  @Action
  setTripPricingDetail(tripPricingDetail: TripPricingDetail): void {
    const trip = this._trip
    trip.tripPricingDetail = tripPricingDetail
    this.updateTrip(trip)
  }

  @Action
  setTrip(trip: Trip): void {
    this._trip = trip
  }

  @Action
  setTracking(tracking: TrackingResponse): void {
    this._tracking = tracking
  }

  @Action
  setPricing(pricing: Pricing): void {
    this._pricing = quotePricing(pricing)
  }

  @Action
  setAssignments(assignments: VehicleAssignment[]): void {
    this._assignments = assignments
  }

  @Action
  setBillingOverrideView(billingOverrideView: BillingOverrideViewResult) {
    const vehicles = billingOverrideView.vehicles

    const plannedMiles = this.postTripDistanceMiles
    const plannedMinutes = getMinutesBetweenDates(
      this.firstPostTripDatetime,
      this.lastPostTripDatetime
    )
    for (const vehicle of vehicles) {
      if (vehicle.billableMiles !== null) {
        vehicle.billableMileDifference = parseFloat(
          (vehicle.billableMiles - Number(plannedMiles)).toFixed(2)
        )
      }

      if (vehicle.billableMinutes !== null) {
        vehicle.billableMinuteDifference = parseFloat(
          (vehicle.billableMinutes - plannedMinutes).toFixed(2)
        )
      }
    }

    this._billingOverrideView = billingOverrideView
  }

  @Action
  clearBillingOverrideView() {
    this._billingOverrideView = null
  }

  @Action
  clear(): void {
    this._reservation = null
    this._estimations = null
    this._trip = null
    this._pricing = null
    this._estimations = null
    this._assignments = null
  }

  @Action
  updateReservation(reservation: Partial<Reservation>): void {
    this._reservation = {
      ...this._reservation,
      ...reservation,
    }
  }

  @Action
  updateTrip(trip: Partial<Trip>): void {
    const existing: Trip | undefined = this._trip
    if (existing) {
      this._trip = { ...existing, ...trip }
    }
  }

  @Action
  updateTripHasBeenModified(tripHasBeenModified: boolean): void {
    this._tripHasBeenModified = tripHasBeenModified
  }

  @Action
  updateBillingOverride({
    billingOverride,
    vehicleId,
  }: {
    billingOverride: Partial<BillingOverride>
    vehicleId: number
  }) {
    const existing = this._billingOverrideView.vehicles.find(
      (v) => v.vehicleId === vehicleId
    )
    if (!existing) {
      return
    }
    this._billingOverrideView.vehicles = this._billingOverrideView.vehicles.map(
      (v) => {
        if (v.vehicleId === vehicleId) {
          return { ...v, ...billingOverride }
        }
        return v
      }
    )
  }

  @Action
  toggleAffiliateOffer({
    affiliateOfferId,
    isActive,
  }: {
    affiliateOfferId: number
    isActive: boolean
  }) {
    this.reservation.affiliateOffers = this.reservation.affiliateOffers.map(
      (o) => {
        if (o.id === affiliateOfferId) {
          return { ...o, isActive }
        }
        return o
      }
    )
  }

  @Action
  updateFiles(files: CustomFile[]) {
    this._reservation.files = files
  }
}

export default new ReservationModule({ store, name: 'reservation' })
