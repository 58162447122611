
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Contact } from '@/models/Contact'
import { validationRules } from '@/utils/rules'
import { isValidEmail } from '@/utils/validators'
import { toTitle } from '@/utils/string'

@Component({})
export default class RecipientList extends Vue {
  @Prop({ required: false, default: () => [] }) readonly value!: Contact[]
  validation = validationRules
  editable = false
  recipient = ''

  label(contact: Contact): string {
    return contact.type ? toTitle(contact.type) : ''
  }

  handleUpdateRecipient(email: string): void {
    this.recipient = email
  }

  handleDeleteRecipient(idx: number): void {
    const recipients = [...this.value]
    recipients.splice(idx, 1)
    this.$emit('input', recipients)
  }

  handleAddRecipient(): void {
    this.editable = true
    this.recipient = ''
  }

  handleClearForm(): void {
    this.editable = false
    this.recipient = ''
  }

  handleConfirmRecipient(): void {
    if (!!this.recipient && isValidEmail(this.recipient)) {
      const recipients: Contact[] = [...this.value, { email: this.recipient }]
      this.$emit('input', recipients)
      this.handleClearForm()
    } else if (!this.recipient) {
      this.editable = false
    }
  }
}
