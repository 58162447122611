var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"d-flex flex-wrap align-stretch w-full margin-y-4 justify-space-between",style:({ gap: '16px' })},[(_vm.reservation.customerAccountId)?_c('CUCustomerAccountCard',{staticClass:"border-black border-1 max-w-one-half border-solid min-h-92 flex-basis-1 flex-grow-1",attrs:{"customer-account-id":_vm.reservation.customerAccountId,"summary":"","link-contact":"","hide-loader":"","label":"Company","background":"white","group-id":_vm.reservation.customerAccountGroupId}}):_vm._e(),(_vm.reservation.customerId && !_vm.isReferral)?_c('CUContactCard',{staticClass:"flex-basis-1 flex-grow-1 max-w-one-half",attrs:{"user-id":_vm.reservation.customerId,"contact-type":"Booking","label":"Booking","link-contact":"","hide-loader":""}}):(_vm.isReferral)?_c('CUContactCard',{staticClass:"flex-basis-1 max-w-one-half",attrs:{"contact":{ firstName: _vm.reservation.source },"label":"Referral","hide-loader":""}}):_vm._e(),(!_vm.isReferral)?[(_vm.reservation.tripContactId)?_c('CUContactCard',{staticClass:"flex-basis-1 flex-grow-1 max-w-344",attrs:{"user-id":_vm.reservation.tripContactId,"contact":_vm.tripContact,"actions":_vm.tripContactActions,"unset-width":"","link-contact":"","hide-email":"","hide-loader":"","label":"Trip"},on:{"contact:edit":function($event){return _vm.$emit('contact:edit', {
            contactId: _vm.reservation.tripContactId,
            contactType: 'Trip',
          })},"contact:change":function($event){return _vm.$emit('contact:change', 'Trip')},"contact:delete":function($event){return _vm.$emit('contact:update', {
            contact: null,
            contactType: 'Trip',
            tripId: null,
          })}}}):_vm._e(),_vm._l((_vm.affiliateOffers),function(affiliate,idx){return _c('div',{key:`affiliate-${affiliate.id}-${idx}`},[(affiliate.customerId)?_c('CUContactCard',{staticClass:"flex-basis-1 flex-grow-1 max-w-344",attrs:{"user-id":affiliate.customerId,"contact-type":"Affiliate","label":"Affiliate","link-contact":"","hide-loader":""}}):_vm._e()],1)}),(!_vm.reservation.tripContactId)?_c('div',{staticClass:"flex-basis-1 flex-grow-1 max-w-one-half padding-x-4"},[_c('CUButton',{staticClass:"font-bold margin-b-1 margin-x-n4",attrs:{"text":"","unset-width":"","color":"green"},on:{"click":function($event){return _vm.$emit('contact:change', 'Trip')}}},[_c('CUIcon',{staticClass:"margin-r-2",attrs:{"height":"20px","width":"20px"}},[_vm._v("add")]),_vm._v(" Add Trip Contact ")],1)],1):_vm._e()]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }