import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { apiBaseUrl } from '@/utils/env'
import {
  RateType,
  SendPaystubsRequest,
  UpdateChargesRequest,
  UpdatePaystubsBulkRequest,
} from '@/models/DriverPay'
import { DriverPaySettings } from '@/models/dto/DriverPaySettings'

const httpService: HttpService = new HttpService()

export default {
  byId(id: number): Promise<AxiosResponse<any>> {
    const url = `https://${apiBaseUrl()}/invoices?reservationId=${id}`
    return httpService.get(url)
  },
  updateInvoiceStatuses(
    invoiceIds: string[],
    status: string
  ): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/invoices/bulk`
    return httpService.put(url, { invoiceIds, status })
  },
  createPayStubs(invoiceIds: string[]): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/paystubs`
    return httpService.post(url, { invoiceIds })
  },
  invoiceById(id: string): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/invoices/${id}`
    return httpService.get(url)
  },
  chargesByInvoiceId(id: string): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/charges?invoiceId=${id}`
    return httpService.get(url)
  },
  updateInvoiceCharges(payload: UpdateChargesRequest): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/invoices/charges`
    return httpService.put(url, payload)
  },
  duplicateInvoice(invoiceId: string): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/invoices/${invoiceId}/duplicate`
    return httpService.post(url, {})
  },
  deleteInvoice(invoiceId: string): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/invoices/${invoiceId}`
    return httpService.delete(url, {})
  },
  paystubById(id: string): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/paystubs/${id}`
    return httpService.get(url)
  },
  getCompanyDefaults(companyId: number): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/settings/companyDefaults/${companyId}`
    return httpService.get(url)
  },
  updateCompanyDefaults(settings: DriverPaySettings): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/settings/companyDefaults`
    return httpService.post(url, settings)
  },
  updatePaystubsBulk(
    payload: UpdatePaystubsBulkRequest
  ): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/paystubs/bulk`
    return httpService.put(url, payload)
  },
  sendToDriver(payload: SendPaystubsRequest): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/paystubs/send`
    return httpService.post(url, payload)
  },
  ratesByDriverId(driverId: number): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/settings/driverDefaults/${driverId}`
    return httpService.get(url)
  },
  updateDriverRates(payload): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/settings/driverDefaults`
    return httpService.put(url, payload)
  },
  downloadPayStub(ids: string[]): Promise<AxiosResponse> {
    const idsString = ids.join(',')
    const url = `https://${apiBaseUrl(
      'pdf'
    )}/pdf/driver-pay-detail-${idsString}.pdf`
    return httpService.get(url, { responseType: 'blob' })
  },
  getCompanyRateTypes(): Promise<AxiosResponse<RateType[]>> {
    const url = `https://${apiBaseUrl()}/customRates`
    return httpService.get(url)
  },
  saveCompanyRateTypes(payload: RateType[]): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/customRates`
    return httpService.put(url, payload)
  },
  updateCompanyRateType(rate: RateType): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/customRates/${rate.id}`
    return httpService.put(url, rate)
  },
  addCompanyRateType(rate: RateType): Promise<AxiosResponse<RateType>> {
    const url = `https://${apiBaseUrl()}/customRates`
    return httpService.post(url, rate)
  },
}
