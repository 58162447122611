import { LineItemSectionTypes } from '@/utils/types'
import { LineItemCharge } from './LineItemCharge'
import { TripPricingSelection } from './Trip'
import { Type } from './Type'

export type SectionTypes = 'base_fare' | 'itemized_charge' | 'subtotal'

export interface TripPricingDetailSection {
  charges: LineItemCharge[]
  total: number
  lineItemChargeSectionType: Type
}

export interface TripPricingDetail {
  tripBaseFare: number
  pricingSelectionDTO?: TripPricingSelection
  sections: Record<string, TripPricingDetailSection>
}

export interface TripPricingDetailRequest {
  tripBaseFare: number
  charges: LineItemCharge[]
}

export interface TripPricingResponse {
  pricing: TripPricingDetail
}

// eslint-disable-next-line no-redeclare
export class TripPricingDetail implements TripPricingDetail {
  constructor(charges: LineItemCharge[] = []) {
    this.tripBaseFare = 0

    const baseFareSection = {
      charges: charges.filter((c) => c.lineItemSectionType.key === 'base_fare'),
      total: 0,
      lineItemChargeSectionType: LineItemSectionTypes.find(
        (t) => t.key === 'base_fare'
      ),
    }

    const itemizedChargeSection = {
      charges: charges.filter(
        (c) => c.lineItemSectionType.key === 'itemized_charge'
      ),
      total: 0,
      lineItemChargeSectionType: LineItemSectionTypes.find(
        (t) => t.key === 'itemized_charge'
      ),
    }

    const subtotalSection = {
      charges: charges.filter((c) => c.lineItemSectionType.key === 'subtotal'),
      total: 0,
      lineItemChargeSectionType: LineItemSectionTypes.find(
        (t) => t.key === 'subtotal'
      ),
    }

    this.sections = {
      base_fare: baseFareSection,
      itemized_charge: itemizedChargeSection,
      subtotal: subtotalSection,
    }
  }
}
