
import { Vue, Component, Prop } from 'vue-property-decorator'
import colors from '@/scss/_colors-export.scss'

@Component
export default class CUTextField extends Vue {
  @Prop({ required: false, default: undefined }) readonly borderColor: string
  @Prop({ type: Boolean, default: false }) readonly centered!: boolean
  @Prop({ type: Boolean, default: false }) readonly notOutlined!: boolean
  @Prop({ type: Boolean, default: false }) readonly url!: boolean
  @Prop({ type: Boolean, default: false }) readonly widget!: boolean
  @Prop({ type: Boolean, default: false }) readonly manualBorderColor!: boolean
  @Prop({ type: String, default: undefined })
  readonly activeBorderColor!: string

  focus(): void {
    const textField: any = this.$refs['v-text-field']
    textField.focus()
  }

  get listeners(): Record<string, unknown> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this
    return Object.assign({}, this.$listeners, {
      input(event: InputEvent) {
        vm.$emit('input', event)
      },
    })
  }

  get cssVars(): Record<string, string> {
    const borderColorDefault =
      colors[this.borderColor] ||
      (this.manualBorderColor ? this.borderColor : colors['gray-border-mid'])
    const borderColorActive =
      colors[this.activeBorderColor] ||
      colors[this.borderColor] ||
      this.borderColor ||
      colors['primary']

    return {
      '--text-field-border-color-default': borderColorDefault,
      '--text-field-border-color-active': borderColorActive,
    }
  }
}
