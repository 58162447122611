
import { Component, Prop, Vue } from 'vue-property-decorator'
import RecipientList from '@/components/RecipientList.vue'
import { validateRules } from '@/utils/rules'
import auth from '@/store/modules/auth'

@Component({
  components: { RecipientList },
})
export default class SendDriverTripSheetSidebar extends Vue {
  @Prop({ required: false, default: () => [] })
  readonly drivers!: { email: string; type: string }[]

  recipients: { email: string; type: string }[] = []

  download(): void {
    this.$emit('download')
  }

  // POST LAUNCH
  // preview(): void {
  //   this.$emit('preview', auth.getUser)
  // }

  cancel(): void {
    this.$emit('cancel')
  }

  submit(): void {
    validateRules(this).then((isValid) => {
      if (isValid) {
        this.$emit('notify', this.recipients)
      }
    })
  }

  mounted(): void {
    this.recipients = [...this.drivers]
  }
}
