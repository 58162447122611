import { AxiosResponse } from 'axios'
import { NotificationsSetting } from '../NotificationsSetting'
import { TableViewResult } from '../TableView'
import { Address } from './Address'
import { Company } from './Company'
import { CustomerAccount } from './CustomerAccount'
import { Industry } from './Industry'
import { Note } from './Note'

export interface Customer {
  customerId: number
  firstName: string
  lastName: string
  title: string
  email: string
  address: Address
  customerAccountId?: any
  customerAccountName?: string
  customerNotes?: Note[]
  companyId: number
  industryId: number
  industryDescription?: any
  phone: string
  phoneExtension?: any
  phoneCountryKey?: string
  fax?: any
  active: boolean
  isBlacklisted: boolean
  bookings: number
  organization: string
  notes?: any
  createdOn: Date
  updatedOn: Date
  customerAccount?: any
  company?: any
  id: number
  industry: Industry
  sendQuotesAsInvoice?: boolean
  notificationsSetting: NotificationsSetting
}

export interface ContactRequest {
  active: boolean
  address: Address
  companyId: number
  customerAccountId: number
  customerAccountName: string
  email: string
  firstName: string
  industryDescription: string
  industryId: number
  lastName: string
  phone: string
  phoneExtension?: string
  phoneCountryKey?: string
  title?: string
  owner?: string
  companies?: { text: string; value: number }[]
  sendQuotesAsInvoice?: boolean
}

export interface CreateCustomerResponse {
  customer: CreateCustomerResponseObject
}

export interface CreateCustomerResponseObject {
  userId: number
  firstName: string
  lastName: string
  email: string
  phone: string
  groupName?: string
  groupId: number
  companyId: number
  linkedAccountProfileId?: number
  locale?: string
  timeZone?: string
  companyName?: string
  company?: Company
  group?: string
  applicationId: number
  active: boolean
  isSignedUp: boolean
  userRoleNames?: string[]
  customerId?: number
  customerAccountId?: number
  industryId?: number
  customerAccountName?: string
  customerAccount?: CustomerAccount
  title?: string
  sendDriverAssignmentEmailAlerts: boolean
  sendDriverOnTheWayEmailAlerts: boolean
  sendDriverNearbyEmailAlerts: boolean
  address?: Address
}

export interface SimpleContact {
  customer: Customer
  isBooking?: boolean
  firstName: string
  lastName: string
  id?: number
  customerId: number
  email: string
  phone: string
  phoneExtension?: string
  phoneCountryKey?: string
  company: CustomerAccount
  customerAccountName?: string
  customerAccountId?: number
  title: string
  owner: string
}

export type ContactTypeKey = 'Billing' | 'Booking' | 'Trip' | ''
export interface CustomerRequest {
  customer: Customer
}

// eslint-disable-next-line no-redeclare
export class ContactRequest implements ContactRequest {
  constructor(customer?: Partial<ContactRequest>) {
    Object.assign(this, customer)
  }
}

// eslint-disable-next-line no-redeclare
export class Customer implements Customer {
  constructor(customer?: Partial<Customer>) {
    Object.assign(this, customer)
  }
}

// eslint-disable-next-line no-redeclare
export class SimpleContact implements SimpleContact {
  constructor(contact?: Partial<SimpleContact>) {
    Object.assign(this, contact)
  }
}

export interface CustomerBalanceSummary {
  customerBalanceSummaryDTO: {
    totalBalance: number
    totalOverdueBalance: number
  }
}

export interface CustomersTVCache {
  response: Promise<AxiosResponse<TableViewResult<Customer>>>
  timestamp: number
}
