
import colors from '@/scss/_colors-export.scss'
import { toTwoDigits } from '@/utils/string'
import dayjs from 'dayjs'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { isIOSDevice } from '@/utils/device'
@Component
export default class CUTimePicker extends Vue {
  @Prop({ required: false, default: '' }) readonly value!: string
  @Prop({ required: false, default: undefined }) readonly label!: string
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean
  @Prop({ type: Boolean, default: false }) readonly widget!: boolean
  @Prop({ type: Boolean, default: false }) readonly attach!: boolean
  @Prop({ type: Boolean, default: false }) readonly showCurrent!: boolean
  @Prop({ required: false, default: null }) color: string
  @Prop({ required: false, default: 1 }) step: number
  @Prop({ required: false, default: '35px' }) nudgeLeft: string
  @Prop({ required: false, default: '3px' }) nudgeBottom: string

  colors = colors
  open = false
  selected = {
    hour: null,
    minute: null,
    meridiem: null,
  }
  inputString = null

  isIOS = false

  @Watch('open')
  openChange(value: boolean): void {
    if (!value) {
      this.$emit('menu-closed', this.inputString)
    }
  }

  @Watch('value', { immediate: true })
  handleValueChanged(): void {
    if (dayjs(this.value, 'HH:mm').isValid()) {
      this.selected = {
        hour: dayjs(this.value, 'HH:mm').format('hh'),
        minute: dayjs(this.value, 'HH:mm').format('mm'),
        meridiem: dayjs(this.value, 'HH:mm').format('A'),
      }
      this.updateInputString(true)
    } else if (!this.value) {
      this.selected = {
        hour: null,
        minute: null,
        meridiem: null,
      }
      this.inputString = null
    }
  }

  handleKeyupEnter(e: any): void {
    this.open = false
    e.target.blur()
  }

  handleFocusOut(): void {
    if (!this.open) {
      this.$emit('menu-closed', this.inputString)
    }
  }

  get borderColor(): string {
    return this.open
      ? this.color || colors['primary']
      : colors['gray-border-mid']
  }

  get hours(): string[] {
    return [...Array(12).keys()].map((hour) => toTwoDigits(hour + 1))
  }

  get minutes(): string[] {
    return [...Array(60).keys()].reduce((arr, min) => {
      if (min % this.step === 0) {
        arr.push(toTwoDigits(min))
      }
      return arr
    }, [])
  }

  get currHour(): string {
    return dayjs().format('hh')
  }

  get currMin(): string {
    let minutes = parseFloat(dayjs().format('mm'))
    minutes = Math.floor(minutes / this.step) * this.step
    return toTwoDigits(minutes)
  }

  get currMeridiem(): string {
    return dayjs().format('A')
  }

  setHour(hour: number): void {
    this.selected.hour = hour
    this.updateInputString()
  }

  setMinute(min: number): void {
    this.selected.minute = min
    this.updateInputString()
  }

  setMeridiem(meridiem: string): void {
    this.selected.meridiem = meridiem
    this.updateInputString()
  }

  handleClick(e: any): void {
    e.preventDefault()
  }

  handleInput(e: any): void {
    // Match ##:## or #:##
    const regex = /[0-9]{1,2}:[0-9]{2}/g
    if (regex.test(e)) {
      this.handleChange(e)
    }
  }

  handleChange(e: any): void {
    const match = e.split(/[:\s]/)

    let meridiem = this.selected.meridiem
    let hour = 0
    if (match[0] || match[0] === 0) {
      meridiem = match[0] >= 12 ? 'PM' : 'AM'
      hour = match[0] % 12 || 12
    }

    this.selected = {
      hour: toTwoDigits(hour),
      minute: toTwoDigits(
        match[1] ? Math.floor(match[1] / this.step) * this.step : 0
      ),
      meridiem,
    }

    this.updateInputString()
  }

  updateInputString(skipEmit = false): void {
    const hour = parseFloat(this.selected.hour || 12)
    const meridiem = this.selected.meridiem || 'AM'
    const isMidnight = meridiem === 'AM' && hour === 12
    const convertedHour = toTwoDigits(
      (hour + ((meridiem === 'PM' && hour < 12) || isMidnight ? 12 : 0)) % 24
    )
    const minute = this.selected.minute || '00'
    this.inputString = `${convertedHour}:${minute}`

    if (dayjs(this.inputString, 'HH:mm').isValid() && !skipEmit) {
      this.$emit('input', this.inputString)
    }
  }

  get cssVars(): Record<string, string> {
    const selectedColor =
      colors[this.color] || this.color || colors['secondary']
    const lightColor =
      colors[`${this.color}-light`] || this.color
        ? `${this.color}1A`
        : colors['secondary-light']

    return {
      '--cu-time-picker-selected-color': selectedColor,
      '--cu-time-picker-light-color': lightColor,
    }
  }

  mounted(): void {
    this.isIOS = isIOSDevice()
  }
}
