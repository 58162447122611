import type from '@/services/type'
import { Vehicle, VehicleAmenityDTO, VehicleDetailEntity } from '@/models/dto'

export const getEmptyVehicle = async (): Promise<VehicleDetailEntity> => {
  const vehicle = new VehicleDetailEntity(null)
  vehicle.vehicleAmenityDTOs = await getVehicleAmenityTypes()
  return vehicle
}

export const getVehicleMakeAndLicense = (vehicle: Vehicle): string => {
  if (!vehicle) {
    return 'No Vehicle'
  }
  if (vehicle.vehicleMake && vehicle.vehicleModel && vehicle.licensePlate) {
    return `${vehicle.licensePlate} • ${vehicle.vehicleMake} ${vehicle.vehicleModel}`
  }
  return vehicle.vehicleMake || vehicle.vehicleName || `${vehicle.vehicleId}`
}

export const getVehicleAmenityTypes = async (): Promise<
  VehicleAmenityDTO[]
> => {
  const response = await type.amenity({ pageSize: -1 })
  const amenities = (response?.data?.resultList || []).filter(
    (amenity) => !['CDC Compliant', 'DOD Compliant'].includes(amenity.label)
  )
  return amenities.map((amenity) => ({
    isSupported: false,
    amenityId: amenity.id,
    amenityName: amenity.label,
  }))
}

export const vehicleAmenityOrder = [
  'Bathroom',
  'Luggage',
  'Outlets',
  'ADA Compliant',
  'Wifi',
  'TV Screens',
  'Leather Seats',
  'Seat Belts',
]

export const vehicleTypeOrder = [
  'Charter Bus',
  'Mini Bus',
  'School Bus',
  'Party Bus',
  'Sprinter',
  'Van',
  'SUV',
  'Sedan',
  'Limousine',
  'Trolley',
  'Mini Coach',
]
