
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import CUCompanyCard from '@/components/CUCompanyCard.vue'
import customerAccountClient from '@/services/customerAccount'
import { Action } from '@/models/dto/Action'
import {
  countryPhoneFormatFilter,
  formatAddressName,
  formattedPhoneToNumber,
  phoneFormatFilter,
} from '@/utils/string'
import AutocompleteAddress from '@/components/AutocompleteAddress.vue'
import { states } from '@/utils/states'
import { Address } from '@/models/dto'
import CompanyGroupSelector from '@/components/CompanyGroupSelector.vue'
import sidebar from '@/store/modules/sidebar'
import industryClient from '@/services/industries'
import { AxiosResponse } from 'axios'
import { validationRules, validateRules } from '@/utils/rules'
import {
  CustomerAccountDetailEntity,
  CustomerAccountRequest,
} from '@/models/dto/CustomerAccount'
import { hasRequiredAddressFields } from '@/utils/validators'
import { EventBus } from '@/utils/eventBus'

@Component({
  components: {
    CUCompanyCard,
    AutocompleteAddress,
    CompanyGroupSelector,
  },
})
export default class CompanySidebarDetail extends Vue {
  @Prop({ required: true }) readonly customerAccountId!: number
  @Prop({ type: Boolean, default: false }) readonly contentOnly!: number
  @Prop({ type: Boolean, default: false }) readonly hideFooter!: boolean

  @Watch('companyString')
  onCompanyChange(): void {
    if (!this.loading) {
      this.$emit('company-sidebar-detail:modified')
    }
  }

  get companyString(): string {
    let str = ''

    if (!this.company) {
      return ''
    }

    const {
      name,
      websiteUrl,
      email,
      phone,
      phoneCountryKey,
      fax,
      address,
      industryId,
      customerAccountGroups,
    } = this.company

    str = str.concat(
      name,
      websiteUrl,
      email,
      phone?.replace(/\D+/g, '') || '',
      phoneCountryKey || '',
      fax?.replace(/\D+/g, '') || '',
      address && Object.keys(address).length > 0 ? JSON.stringify(address) : '',
      industryId?.toString(),
      customerAccountGroups?.length.toString()
    )

    return str
  }

  states = states
  rules = validationRules

  company: CustomerAccountDetailEntity = null
  initialCompany: CustomerAccountDetailEntity = null
  loading = true
  submitting = false
  industries = []
  errors = {
    city: null,
    state: null,
    zip: null,
  }

  handleChangeAddressInput(input: string): void {
    this.company.address = { ...this.company.address, street1: input || '' }
  }

  handlePhoneChange(data: { phone: string; country: string }): void {
    this.company.phone = data.phone
    this.company.phoneCountryKey = data.country
  }

  handleCompanyDelete(): void {
    // TODO
    return
  }

  close(): void {
    sidebar.pop()
  }

  handleCompanyAddressChange(address: Address): void {
    this.errors = {
      city: null,
      state: null,
      zip: null,
    }
    if (address) {
      this.company = {
        ...this.company,
        address: { ...address, street2: this.company.address?.street2 },
      }
    } else {
      this.company.address.title = null
    }
  }

  async submit(): Promise<void> {
    this.errors = {
      city: null,
      state: null,
      zip: null,
    }
    const isValidAddress = hasRequiredAddressFields(this.company.address)
    if (!(await validateRules(this)) || !isValidAddress) {
      if (!isValidAddress) {
        this.errors = {
          city: !this.company?.address?.city
            ? 'Required to save address'
            : null,
          state: !this.company?.address?.state
            ? 'Required to save address'
            : null,
          zip: !this.company?.address?.postalCode
            ? 'Required to save address'
            : null,
        }
      }
      return
    }
    this.submitting = true
    let address = this.company.address
    if (address) {
      address.addressName = formatAddressName(address)
      if (address.addressName === '') {
        address = null
      }
    }
    if (this.company.fax) {
      this.company.fax = phoneFormatFilter(this.company.fax)
    }
    const phone = (this.company.phone || '').replace(/\D+/g, '')
    await customerAccountClient.update(
      this.company.customerAccountId,
      new CustomerAccountRequest({ ...this.company, phone, address })
    )
    this.$emit('refresh')
    this.load()
    this.submitting = false
    EventBus.$emit('company-sidebar-detail:submit-success')
    sidebar.pop()
  }

  actions: Action[] = [
    {
      label: 'Delete',
      event: 'company:delete',
    },
  ]

  async load(): Promise<void> {
    if (this.customerAccountId) {
      await customerAccountClient
        .byId(this.customerAccountId)
        .then((res) => res.data)
        .then((company) => (this.company = company))
    } else {
      this.company = new CustomerAccountDetailEntity()
    }
    this.company.phone = countryPhoneFormatFilter(
      this.company.phone,
      this.company.phoneCountryKey
    )
    if (!this.company.address) {
      this.company = { ...this.company, address: new Address() }
    }
    this.initialCompany = { ...this.company }
  }

  async mounted(): Promise<void> {
    await this.load()

    const industryResponse: AxiosResponse = await industryClient.tableView({
      pageSize: -1,
      page: 1,
    })
    this.industries = [
      ...industryResponse.data.resultList.map((el) => {
        return { text: el.label, value: el.industryId }
      }),
    ]

    if (this.company.address === null) {
      this.company.address = new Address()
    }

    this.loading = false
  }
}
