
import { Component, Vue } from 'vue-property-decorator'
import { EventBus } from '@/utils/eventBus'
import CUSnackbar from '@/components/CUSnackbar.vue'

const SNACKBAR_HEIGHT = '70px'

@Component({
  components: { CUSnackbar },
})
export default class TheSnackbar extends Vue {
  message = ''
  isError = false
  show = false
  renderHTML = false
  timeout = 5000

  SNACKBAR_HEIGHT = SNACKBAR_HEIGHT

  snackbarMessage(message: string, isError = false, timeout = 5000): void {
    this.timeout = timeout
    this.message = message
    this.isError = isError
    this.show = true
  }

  mounted(): void {
    EventBus.$on('snackbar:success', (message: string) =>
      this.snackbarMessage(message, false)
    )
    EventBus.$on('snackbar:error', (message: string) =>
      this.snackbarMessage(message, true)
    )
    EventBus.$on('snackbar-hold:error', (message: string) =>
      this.snackbarMessage(message, true, -1)
    )
  }

  beforeDestroy(): void {
    EventBus.$off('snackbar:success', (message: string) =>
      this.snackbarMessage(message, false)
    )
    EventBus.$off('snackbar:error', (message: string) =>
      this.snackbarMessage(message, true)
    )
    EventBus.$off('snackbar-hold:error', (message: string) =>
      this.snackbarMessage(message, true, -1)
    )
  }
}
