
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class CUCheckbox extends Vue {
  @Prop({ required: false, default: '' }) readonly label!: string
  @Prop({ required: false, default: 'primary' }) readonly color!: string
  @Prop({ type: Boolean, default: false }) readonly error!: boolean
  @Prop({ type: Boolean, default: true }) readonly disableAnimation!: boolean
  @Prop({ type: Boolean, default: false }) readonly round!: boolean
}
