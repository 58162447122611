var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex align-stretch justify-space-between position-relative margin-r-0 min-w-fit-content padding-x-3 border-radius-10",class:{
    'max-w-724': _vm.dispatch,
    'min-h-92': !_vm.departure && !_vm.arrival,
  },style:({
    backgroundColor:
      _vm.commute.trackingStatus !== _vm.TrackingStatus.Completed
        ? _vm.commute.tagBackground
        : null,
  }),on:{"mouseleave":_vm.handleMouseLeave}},[_c('div',{staticClass:"d-flex align-top position-relative padding-t-1 padding-b-2 margin-t-n1 flex-shrink-0 flex-grow-1",style:({ maxWidth: '35%' }),on:{"mouseover":_vm.handleMouseOver}},[(_vm.stop)?_c('div',{staticClass:"w-16 h-16 d-flex align-center justify-center font-12 rounded-circle text-white z-5",class:{
        'background-green': _vm.stop.dropoffDatetime || _vm.stop.pickupDatetime,
        'background-bg-gray-4': !_vm.stop.dropoffDatetime && !_vm.stop.pickupDatetime,
      },style:({ marginTop: '15px' })},[_vm._v(" "+_vm._s(_vm.stop.orderIndex + 1)+" ")]):_c('div',{staticClass:"w-16 h-16 d-flex align-center justify-center background-bg-gray-4 font-12 rounded-circle text-white z-5",style:({ marginTop: '15px' })},[_vm._v(" G ")]),_c('div',{staticClass:"position-absolute left-8 w-1 background-bg-gray-4",class:{
        'top-20 h-full': _vm.departure,
        'top-0 h-20': _vm.arrival,
        'top-0 h-full': !_vm.departure && !_vm.arrival,
      }}),_c('div',{staticClass:"position-relative flex-grow-1",class:{ 'max-w-324': _vm.dispatch }},[_c('StopWithNotes',{staticClass:"min-w-272 padding-t-3",class:{
          'border-t-1 border-x-0 border-b-0 border-solid border-gray-border-mid':
            !_vm.departure && !_vm.hideDivider,
        },attrs:{"stop":_vm.stop,"garage":_vm.garage,"garage-note":_vm.garageNote,"show-edit-note":_vm.showEditNote && !_vm.isReferral,"title":_vm.addressTitle,"addresses":_vm.addresses,"hide-add-notes":_vm.isReferral},on:{"add-note":_vm.handleAddNote}})],1)]),_c('div',{staticClass:"d-flex flex-shrink-1 flex-grow-1",style:({ width: '55%' }),on:{"mouseover":_vm.handleMouseOver}},[_c('v-col',{staticClass:"px-4 d-flex flex-column",class:{
        'border-t-1 border-x-0 border-b-0 border-solid border-gray-border-mid pt-2':
          !_vm.departure && !_vm.hideDivider,
      }},[(
          (_vm.showFirstStopEstimation && _vm.timeFromPreviousStop) ||
          _vm.showGarageArrivalStopEstimation
        )?_c('div',{staticClass:"pl-4 pr-1 py-2 background-white border-solid border-1 border-radius-5 border-gray-border-mid h-28 d-flex align-center justify-space-between",style:({ width: '100%' })},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('span',{staticClass:"font-11 font-family"},[_vm._v(" "+_vm._s(_vm.firstStopLabel)+" ")]),_c('span',{staticClass:"font-11 font-family"},[_vm._v("  - "+_vm._s(_vm.secondStopLabel)+":  ")]),_c('span',{staticClass:"font-11 font-family font-bold"},[_vm._v(" "+_vm._s(_vm.arrival ? _vm.timeGarage : _vm.timeFromPrevious)+" | "+_vm._s(Math.round(_vm.timeFromPreviousStop.distanceFromPrevious))+" mi. ")])]),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"font-11 font-family"},[_vm._v("Total: ")]),_c('span',{staticClass:"font-11 font-family font-bold"},[_vm._v(" "+_vm._s(_vm.totalTime)+" | "+_vm._s(_vm.totalMiles)+" mi. ")])])]):_vm._e(),_c('div',{staticClass:"d-flex flex-shrink-1 justify-space-between h-full padding-b-2 pt-2",on:{"mouseover":_vm.handleMouseOver}},[(_vm.stop)?_c('div',[(_vm.stop.dropoffDatetime)?_c('p',{staticClass:"font-bold text-green",class:{
              'margin-b-1': _vm.stop.pickupDatetime || _vm.spotTime,
              'margin-b-0': !_vm.stop.pickupDatetime && !_vm.spotTime,
            }},[_vm._v(" Dropoff ")]):_vm._e(),(_vm.stop.pickupDatetime)?_c('p',{staticClass:"font-bold text-green",class:{
              'margin-b-1': _vm.spotTime,
              'margin-b-0': !_vm.spotTime,
            }},[_vm._v(" Pickup ")]):_vm._e(),(_vm.spotTime)?_c('div',{staticClass:"position-relative margin-b-0"},[_c('p',{staticClass:"font-bold text-green"},[_vm._v("Depart")]),_c('div',{staticClass:"position-absolute left-n3 top-7 h-6 w-6 background-secondary rounded-circle"}),_c('div',{staticClass:"position-absolute left-n3 h-6 w-6 background-secondary rounded-circle",style:({ top: '-16px' })}),_c('div',{staticClass:"position-absolute border-dashed border-1 border-r-0 border-y-0 border-secondary",style:({ left: '-10px', top: '-15px', bottom: '8px' })})]):_vm._e()]):(_vm.garageTime)?_c('div',[(_vm.arrivalTime)?_c('p',{staticClass:"font-bold margin-b-1 text-green"},[_vm._v(" Arrive ")]):_vm._e(),_c('p',{staticClass:"font-bold margin-b-0 text-green"},[_vm._v(" "+_vm._s(_vm.departure ? 'Depart' : 'Return')+" ")])]):_vm._e(),(_vm.stop)?_c('div',[(_vm.stop.address && _vm.stop.dropoffDatetime)?_c('p',{class:{
              'margin-b-1': _vm.stop.pickupDatetime || _vm.spotTime,
              'margin-b-0': !_vm.stop.pickupDatetime && !_vm.spotTime,
            }},[_vm._v(" "+_vm._s(_vm.formatMediumDateLongTime(_vm.stop.dropoffDatetime, { tz: _vm.stop.address.timeZone, showDot: true, showTimezone: true, showMeridianUpper: true, }))+" ")]):_vm._e(),(_vm.stop.address && _vm.spotTime)?_c('p',{staticClass:"margin-b-1 d-flex justify-end"},[_vm._v(" "+_vm._s(_vm.formatMediumDateLongTime(_vm.spotTime.spotTime, { tz: _vm.stop.address.timeZone, showDot: true, showTimezone: true, showMeridianUpper: true, }))+" ")]):_vm._e(),(_vm.stop.address && _vm.stop.pickupDatetime)?_c('div',{staticClass:"margin-b-0 d-flex justify-end"},[(_vm.spotTime)?_c('p',[_vm._v(" "+_vm._s(_vm.formatLongTime(_vm.stop.pickupDatetime, { tz: _vm.stop.address.timeZone, showTimezone: true, showMeridianUpper: true, }))+" ")]):_c('p',[_vm._v(" "+_vm._s(_vm.formatMediumDateLongTime(_vm.stop.pickupDatetime, { tz: _vm.stop.address.timeZone, showDot: true, showTimezone: true, showMeridianUpper: true, }))+" ")])]):_vm._e()]):_vm._e(),(_vm.garageTime)?_c('div',[(_vm.arrivalTime)?[_c('p',{staticClass:"margin-b-1"},[_vm._v(" "+_vm._s(_vm.formatMediumDateLongTime(_vm.arrivalTime, { tz: _vm.garage.address.timeZone, showDot: true, showTimezone: true, showMeridianUpper: true, }))+" ")]),_c('p',{staticClass:"margin-b-0 d-flex justify-end"},[_vm._v(" "+_vm._s(_vm.formatLongTime(_vm.garageTime, { tz: _vm.garage.address.timeZone, showTimezone: true, showMeridianUpper: true, }))+" ")])]:_c('p',{staticClass:"margin-b-0"},[_vm._v(" "+_vm._s(_vm.formatMediumDateLongTime(_vm.garageTime, { tz: _vm.garage.address.timeZone, showDot: true, showTimezone: true, showMeridianUpper: true, }))+" ")])],2):_vm._e()])]),_c('v-divider',{staticClass:"h-full margin-x-4",attrs:{"vertical":""}}),_c('div',{staticClass:"d-flex h-full padding-b-2 padding-t-9",class:{
        'border-t-1 border-x-0 border-b-0 border-solid border-gray-border-mid':
          !_vm.departure && (_vm.dispatch || _vm.trackingStatus) && !_vm.hideDivider,
        'justify-space-between': !_vm.dispatch || _vm.isCompleted,
        'background-bg-gray':
          !_vm.dispatch &&
          !(_vm.trackingStatus || _vm.driverCommuteData.commutingDriverId),
        'border-radius-top-left-30 border-radius-top-right-30':
          !_vm.dispatch && !_vm.trackingStatus && _vm.departure,
        'border-radius-bottom-left-30 border-radius-bottom-right-30':
          !_vm.dispatch && !_vm.trackingStatus && _vm.arrival,
        'padding-t-4': (_vm.departure || _vm.arrival) && !_vm.garage && !_vm.stop,
      },style:({ width: _vm.rightColumnWidth })},[(_vm.journeyStop)?_c('div',[(_vm.journeyStop.reachedTimeStamp)?_c('p',{staticClass:"margin-b-0"},[_vm._v(" "+_vm._s(_vm.displayDate(_vm.journeyStop.reachedTimeStamp))+" ")]):_vm._e(),(_vm.journeyStop.departedTimeStamp)?_c('p',{staticClass:"margin-t-1 margin-b-0"},[_vm._v(" "+_vm._s(_vm.displayDate(_vm.journeyStop.departedTimeStamp))+" ")]):_vm._e(),(
            _vm.journeyStop.stop.dropOffDateTime &&
            _vm.journeyStop.stop.pickUpDateTime &&
            !_vm.journeyStop.departedTimeStamp
          )?_c('div',{staticClass:"h-24"}):_vm._e(),(_vm.spotTime)?_c('div',{staticClass:"h-24"}):_vm._e()]):_vm._e(),_c('div',{staticClass:"position-relative"},[(_vm.driverCommuteData.commutingDriverId)?_c('div',{staticClass:"position-absolute top-n6",style:({ width: _vm.rightColumnWidth })},[_c('CUTooltip',{attrs:{"tooltip-text":_vm.commute.driverInfoString,"disabled":!_vm.commute.driverInfoString,"max-width":"300px","bottom":""}},[_c('div',{staticClass:"d-flex justify-space-between h-20"},[_c('div',{staticClass:"d-flex align-center"},[_c('p',[_vm._v(_vm._s(_vm.commute.arrivalTimeString))]),(
                    !_vm.dispatch &&
                    _vm.commute.tagString &&
                    _vm.commute.trackingStatus !== _vm.TrackingStatus.Completed
                  )?_c('CUTag',{staticClass:"font-bold margin-l-3",attrs:{"background":_vm.commute.tagBackground,"border":_vm.commute.tagColor,"tag":_vm.commute.tagString,"font-size":8,"dense":""}}):_vm._e()],1),(!_vm.isCompleted)?_c('div',{staticClass:"d-flex align-center margin-t-1 flex-shrink-0",class:{ 'w-92': !_vm.dispatch }},[_c('CUIcon',{staticClass:"margin-b-1",class:{
                    'margin-r-2': !_vm.dispatch || !_vm.isCompleted,
                  },attrs:{"height":"12px","width":"12px","color":_vm.statusColors[_vm.commute.trackingStatus]}},[_vm._v(" dot ")]),(!_vm.dispatch)?_c('p',{staticClass:"margin-b-1 font-semibold",class:`text-${_vm.statusColors[_vm.commute.trackingStatus]}`},[_vm._v(" "+_vm._s(_vm.commute.trackingStatus)+" ")]):_vm._e()],1):_vm._e()]),(_vm.showDispatchCommuteTag)?_c('CUTag',{staticClass:"font-bold",attrs:{"background":_vm.commute.tagBackground,"border":_vm.commute.tagColor,"tag":_vm.commute.tagString,"font-size":8,"dense":""}}):_vm._e()],1)],1):_vm._e(),((_vm.departure || _vm.arrival) && _vm.journeyTime)?_c('div',{staticClass:"d-flex"},[(_vm.journeyTime.time)?_c('p',{staticClass:"margin-b-0 white-space-nowrap"},[_vm._v(" "+_vm._s(_vm.displayDate(_vm.journeyTime.time, _vm.dispatch))+" ")]):_vm._e(),(!_vm.dispatch && _vm.journeyTime.odometer)?_c('CUTooltip',{attrs:{"bottom":"","max-width":"200px","tooltip-text":`${_vm.numberWithCommas(_vm.journeyTime.odometer, 0)} mi`}},[(!_vm.dispatch)?_c('CUIcon',{style:({ marginLeft: '2px' }),attrs:{"color":"gray-icon-dark","width":"16px","height":"16px"}},[_vm._v(" odometer ")]):_vm._e()],1):_vm._e()],1):_vm._e()]),(_vm.trackingStatus)?_c('div',[_c('div',{staticClass:"d-flex align-center h-20",class:{ 'w-92': !_vm.dispatch },style:({ marginTop: !_vm.dispatch ? '2px' : null })},[_c('CUIcon',{class:{
              'margin-r-2 margin-b-1': !_vm.dispatch || !_vm.isCompleted,
            },attrs:{"height":"12px","width":"12px","color":_vm.statusColors[_vm.trackingStatus]}},[_vm._v(" dot ")]),(!_vm.dispatch || !_vm.isCompleted)?_c('p',{staticClass:"margin-b-1 font-semibold",class:`text-${_vm.statusColors[_vm.trackingStatus]}`},[_vm._v(" "+_vm._s(_vm.trackingStatusName)+" ")]):_vm._e()],1),(_vm.sequentialTrackingStatus)?_c('div',{staticClass:"d-flex align-center h-20 margin-t-1",class:{ 'w-92': !_vm.dispatch }},[_c('CUIcon',{class:{
              'margin-r-2 margin-b-1': !_vm.dispatch,
            },attrs:{"height":"12px","width":"12px","color":_vm.statusColors[_vm.sequentialTrackingStatus]}},[_vm._v(" dot ")]),(!_vm.dispatch)?_c('p',{staticClass:"margin-b-1 font-semibold",class:`text-${_vm.statusColors[_vm.sequentialTrackingStatus]}`},[_vm._v(" "+_vm._s(_vm.sequentialTrackingStatus)+" ")]):_vm._e()],1):_vm._e(),(_vm.spotTime)?_c('div',{staticClass:"h-24"}):_vm._e()]):_vm._e(),(!_vm.dispatch && !_vm.trackingStatus && _vm.stop && _vm.stop.orderIndex === 0)?_c('div',{staticClass:"d-flex align-center justify-center w-full position-relative top-20 z-1"},[_c('p',[_vm._v("No tracking information")])]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }