import { NavigationGuardNext, Location, RouteConfig } from 'vue-router'
import auth from '@/store/modules/auth'
import app from '@/store/modules/app'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'
import { beforeEnterCheckAccess, handleHomepageRedirect } from '@/utils/router'

export const routes: RouteConfig[] = [
  {
    path: '/',
    component: () => import('@/views/Empty.vue'),
    children: [
      {
        path: '',
        name: 'home',
        redirect: handleHomepageRedirect,
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/Home.vue'),
        children: [
          {
            path: 'quotes',
            component: () => import('@/views/Empty.vue'),
            beforeEnter: (_to, _from, next): void => {
              beforeEnterCheckAccess(ACCESS_SETTINGS_ROLES.QUOTES, next)
            },
            children: [
              {
                path: '',
                name: 'quotes',
                component: () => import('@/views/Quotes.vue'),
              },
              {
                path: 'detail/:id',
                name: 'quotes.detail',
                component: () => import('@/views/QuoteDetails.vue'),
              },
              {
                path: 'detail/:id/:data',
                name: 'quotes.duplicate',
                component: () => import('@/views/QuoteDetails.vue'),
              },
            ],
          },
          {
            path: 'reservations',
            component: () => import('@/views/Empty.vue'),
            beforeEnter: (_to, _from, next): void => {
              beforeEnterCheckAccess(ACCESS_SETTINGS_ROLES.RESERVATIONS, next)
            },
            children: [
              {
                path: '',
                name: 'reservations',
                component: () => import('@/views/Reservations.vue'),
              },
              {
                path: 'detail/:id',
                name: 'reservations.detail',
                component: () => import('@/views/ReservationDetails.vue'),
              },
            ],
          },
          {
            path: 'dispatch',
            name: 'dispatch',
            component: () => import('@/views/Dispatch.vue'),
            beforeEnter: (_to, _from, next): void => {
              beforeEnterCheckAccess(ACCESS_SETTINGS_ROLES.DISPATCH, next)
            },
          },
          {
            path: 'assignments',
            name: 'assignments',
            component: () => import('@/views/Assignments.vue'),
            beforeEnter: (_to, _from, next): void => {
              beforeEnterCheckAccess(ACCESS_SETTINGS_ROLES.DISPATCH, next)
            },
          },
          {
            path: 'payments',
            name: 'payments',
            component: () => import('@/views/Payments.vue'),
          },
          {
            path: 'contacts',
            component: () => import('@/views/Empty.vue'),
            beforeEnter: (_to, _from, next): void => {
              beforeEnterCheckAccess(ACCESS_SETTINGS_ROLES.CONTACTS, next)
            },
            children: [
              {
                path: '',
                name: 'contacts',
                component: () => import('@/views/Contacts.vue'),
              },
              {
                path: 'detail/:id',
                name: 'contacts.detail',
                component: () => import('@/views/ContactDetails.vue'),
              },
            ],
          },
          {
            path: 'companies',
            component: () => import('@/views/Empty.vue'),
            beforeEnter: (_to, _from, next): void => {
              beforeEnterCheckAccess(ACCESS_SETTINGS_ROLES.CONTACTS, next)
            },
            children: [
              {
                path: '',
                name: 'companies',
                component: () => import('@/views/Companies.vue'),
              },
              {
                path: 'detail/:id',
                name: 'companies.detail',
                component: () => import('@/views/CompanyDetails.vue'),
              },
            ],
          },
          {
            path: 'tickets',
            name: 'tickets',
            component: () => import('@/views/Tickets.vue'),
            beforeEnter: (_to, _from, next): void => {
              beforeEnterCheckAccess(ACCESS_SETTINGS_ROLES.OPERATIONS, next)
            },
          },
          {
            path: 'drivers',
            name: 'drivers',
            component: () => import('@/views/Drivers.vue'),
            beforeEnter: (_to, _from, next): void => {
              beforeEnterCheckAccess(ACCESS_SETTINGS_ROLES.OPERATIONS, next)
            },
          },
          {
            path: 'change-password',
            name: 'change-password',
            component: () => import('@/views/ChangePassword.vue'),
          },
          {
            path: 'vehicles',
            name: 'vehicles',
            component: () => import('@/views/Vehicles.vue'),
            beforeEnter: (_to, _from, next): void => {
              beforeEnterCheckAccess(ACCESS_SETTINGS_ROLES.OPERATIONS, next)
            },
          },
          {
            path: 'driver-pay',
            name: 'driver-pay',
            component: () => import('@/views/DriverPay.vue'),
            beforeEnter: (
              _to: Location,
              _from: Location,
              next: NavigationGuardNext
            ): void => {
              beforeEnterCheckAccess(ACCESS_SETTINGS_ROLES.OPERATIONS, next)
            },
          },
          {
            path: 'transactions',
            name: 'transactions',
            component: () => import('@/views/Transactions.vue'),
          },
          {
            path: 'refunds',
            name: 'refunds',
            component: () => import('@/views/Refunds.vue'),
          },
          {
            path: 'reservation-audits',
            name: 'reservation-audits',
            component: () => import('@/views/ReservationAudits.vue'),
          },
          {
            path: 'availability',
            name: 'availability',
            component: () => import('@/views/AvailabilityCalendar.vue'),
          },
          {
            path: 'place',
            name: 'place',
            component: () => import('@/views/Empty.vue'),
          },
          {
            path: 'settings',
            component: () => import('@/views/Empty.vue'),
            children: [
              {
                path: '',
                name: 'settings',
                component: () => import('@/views/Settings.vue'),
              },
              {
                path: 'general',
                component: () => import('@/views/Empty.vue'),
                children: [
                  {
                    path: '',
                    name: 'settings.general',
                    component: () => import('@/views/Settings.vue'),
                  },
                  {
                    path: 'company-profile',
                    name: 'settings.general.company-profile',
                    component: () => import('@/views/Settings.vue'),
                  },
                  {
                    path: 'defaults',
                    name: 'settings.general.defaults',
                    component: () => import('@/views/Settings.vue'),
                  },
                  {
                    path: 'notifications',
                    name: 'settings.general.notifications',
                    component: () => import('@/views/Settings.vue'),
                  },
                  {
                    path: 'branding',
                    name: 'settings.general.branding',
                    component: () => import('@/views/Settings.vue'),
                  },
                  {
                    path: 'terms',
                    component: () => import('@/views/Empty.vue'),
                    children: [
                      {
                        path: '',
                        name: 'settings.general.terms',
                        component: () => import('@/views/Settings.vue'),
                      },
                      {
                        path: 'add',
                        name: 'settings.general.terms.add',
                        component: () => import('@/views/Settings.vue'),
                      },
                      {
                        path: 'edit/:termsId',
                        name: 'settings.general.terms.edit',
                        component: () => import('@/views/Settings.vue'),
                        props: (router) => ({
                          termsId: router.params.id,
                        }),
                      },
                    ],
                  },
                ],
              },
              {
                path: 'rates',
                name: 'settings.rates',
                component: () => import('@/views/Settings.vue'),
              },
              {
                path: 'users',
                name: 'settings.users',
                component: () => import('@/views/Settings.vue'),
              },
              {
                path: 'charges',
                name: 'settings.charges',
                component: () => import('@/views/Settings.vue'),
              },
              {
                path: 'driver-pay',
                name: 'settings.driver-pay',
                component: () => import('@/views/Settings.vue'),
              },
              {
                path: 'garages',
                name: 'settings.garages',
                component: () => import('@/views/Settings.vue'),
              },
              {
                path: 'saved-stops',
                name: 'settings.saved-stops',
                component: () => import('@/views/Settings.vue'),
              },
              {
                path: 'industries',
                name: 'settings.industries',
                component: () => import('@/views/Settings.vue'),
              },
              {
                path: 'templates',
                name: 'settings.templates',
                component: () => import('@/views/Settings.vue'),
              },
              {
                path: 'integrations',
                name: 'settings.integrations',
                component: () => import('@/views/Settings.vue'),
              },
              {
                path: 'profile',
                name: 'settings.profile',
                component: () => import('@/views/Settings.vue'),
              },
            ],
          },
          {
            path: 'industries',
            name: 'industries',
            component: () => import('@/views/Industries.vue'),
          },
          {
            path: 'credits',
            name: 'credits',
            component: () => import('@/views/Credits.vue'),
          },
          {
            path: 'charges',
            name: 'charges',
            component: () => import('@/views/Charges.vue'),
          },
          {
            path: 'admin',
            component: () => import('@/views/Empty.vue'),
            children: [
              {
                path: 'companies',
                name: 'admin.companies',
                component: () => import('@/views/AdminCompanies.vue'),
              },
              {
                path: 'users',
                name: 'admin.users',
                component: () => import('@/views/AdminUsers.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'checkout/:id',
        name: 'checkout',
        component: () => import('@/views/Checkout.vue'),
      },
      {
        path: 'widget/:company',
        name: 'widget',
        component: () => import('@/views/Widget.vue'),
      },
      {
        path: 'terms/:hash',
        name: 'public-terms',
        component: () => import('@/views/PublicTerms.vue'),
      },
      {
        path: 'invoice/:id',
        name: 'invoice',
        component: () => import('@/views/Invoice.vue'),
      },
      {
        path: 'tracking/:id',
        name: 'tracking',
        component: () => import('@/views/Tracking.vue'),
      },
      {
        path: '',
        name: 'preauth',
        component: () => import('@/views/Empty.vue'),
        children: [
          {
            path: 'login',
            name: 'login',
            component: () =>
              import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
            beforeEnter: (_to, _from, next): void => {
              if (auth.getIsTokenSet) {
                next({ name: 'home' })
              } else {
                next()
              }
            },
          },
          {
            path: 'setPassword/:hash',
            name: 'set-password',
            component: () =>
              import(
                /* webpackChunkName: "setPassword" */ '@/views/SetPassword.vue'
              ),
            props: (router) => ({
              hash: router.params.hash,
            }),
            beforeEnter: (_to, _from, next): void => {
              if (auth.getIsTokenSet) {
                next({ name: 'home' })
              } else {
                next()
              }
            },
          },
          {
            path: '/downloadApp',
            name: 'download-app',
            component: () => import('@/views/DownloadApp.vue'),
          },
          {
            path: 'forgotPassword',
            name: 'forgot-password',
            component: () =>
              import(
                /* webpackChunkName: "forgotPassword" */ '@/views/ForgotPassword.vue'
              ),
          },
          {
            path: '/*',
            name: 'not-found',
            component: () =>
              import(
                /* webpackChunkName: "not-found" */ '@/views/NotFound.vue'
              ),
          },
        ],
      },
    ],
  },
]
