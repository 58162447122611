import { Group } from '@/models/dto/User'

export const userGroups: Group[] = [
  {
    groupId: 1,
    label: 'Admin',
    key: 'admin',
  },
  {
    groupId: 2,
    label: 'User',
    key: 'user',
  },
  {
    groupId: 4,
    label: 'Driver',
    key: 'driver',
  },
]

export enum UserGroupIds {
  ADMIN = 1,
  USER = 2,
  DRIVER = 4,
}
