import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Industry } from '@/models/dto/Industry'

const httpService: HttpService = new HttpService()

export default {
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<Industry>>> {
    const { sorts, filters, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }

    query = encodeURI(query)
    return httpService.get(`https://${apiBaseUrl()}/tables/industries?${query}`)
  },
  addIndustry(
    industryRequest: Partial<Industry>
  ): Promise<AxiosResponse<number>> {
    const url = `https://${apiBaseUrl()}/v2/industry`
    return httpService.post(url, industryRequest)
  },
  modifyIndustry(
    industryRequest: Partial<Industry>
  ): Promise<AxiosResponse<null>> {
    const url = `https://${apiBaseUrl()}/v2/industry/${
      industryRequest.industryId
    }`
    return httpService.patch(url, industryRequest)
  },
  getIndustry(industryId: number): Promise<AxiosResponse<Industry>> {
    const url = `https://${apiBaseUrl()}/v2/industry/${industryId}`
    return httpService.get(url)
  },
  deleteIndustry(industryId: number): Promise<AxiosResponse<Industry>> {
    const url = `https://${apiBaseUrl()}/v2/industry/${industryId}`
    return httpService.delete(url)
  },
}
