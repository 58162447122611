
import { DriverRate } from '@/models/DriverPay'
import {
  currencyFilter,
  percentageFormatter,
  stringToFloat,
} from '@/utils/string'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class DriverInfoDriverPayTabBulkRatesForm extends Vue {
  @Prop({ required: true }) rate!: DriverRate

  hourlyRateCheck = false
  dailyRateCheck = false
  percentageCheck = false
  perTripMinHoursCheck = false
  perTripMinRateCheck = false
  perDiemCheck = false

  handleChange(name: string, event: string): void {
    const value = stringToFloat(event)
    this.$emit('input', { [name]: value })
  }

  get displayHourlyRate(): string {
    return this.rate.hourlyRate ? currencyFilter(this.rate.hourlyRate) : ''
  }

  get displayDailyRate(): string {
    return this.rate.dailyRate ? currencyFilter(this.rate.dailyRate) : ''
  }

  get displayPercentage(): string {
    return this.rate.percentageBase
      ? percentageFormatter(this.rate.percentageBase)
      : ''
  }

  get displayMinRate(): string {
    return this.rate.perTripMinRate ? currencyFilter(this.rate.perTripMinRate) : ''
  }

  get displayPerDiemRate(): string {
    return this.rate.perDiem ? currencyFilter(this.rate.perDiem) : ''
  }
}
