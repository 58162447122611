
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class CUExpansionPanel<T> extends Vue {
  @Prop({ required: false }) readonly items!: T[]
  @Prop({ required: false, default: '' }) readonly label!: string
  @Prop({ required: false, default: '20px' }) readonly arrowWidth!: string
  @Prop({ required: false, default: '5' }) readonly paddingY!: string
  @Prop({ type: Boolean, default: false }) readonly arrowLeft!: boolean
  @Prop({ type: Boolean, default: false }) readonly readonly!: boolean
  @Prop({ type: Boolean, default: false }) readonly defaultClosed!: boolean
  @Prop({ type: Boolean, default: false }) readonly block!: boolean

  expanded = this.items?.length
    ? Array.from({ length: this.items.length - 1 }, (_, index) =>
        this.defaultClosed && index === 0 ? 0 : index
      )
    : Number(this.defaultClosed ? undefined : 0)
}
