
import { Vue, Component, Prop } from 'vue-property-decorator'
import types from '@/store/modules/types'
@Component({})
export default class DriverInfoDriverPayTabDeleteConfirmation extends Vue {
  @Prop({ required: true }) vehicleTypeId!: number

  get vehicleName(): string {
    const vt = types.getVehicleTypes.find((v) => v.id === this.vehicleTypeId)
    return vt?.label
  }
}
