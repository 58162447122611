
import Vue from 'vue'
import { loadPaymentCDNs } from '@/utils/external'
import { EventBus } from './utils/eventBus'
import TheSnackbar from '@/components/TheSnackbar.vue'

export default Vue.extend({
  name: 'App',
  components: {
    TheSnackbar,
  },
  provide() {
    return {
      appOptions: this.appOptionsComputed,
    }
  },
  data() {
    return {
      appOptions: { showUpdateUI: false },
    }
  },
  computed: {
    appOptionsComputed() {
      return this.appOptions
    },
  },
  mounted(): void {
    loadPaymentCDNs()
    EventBus.$on(
      'update:show-update-ui',
      (e) => (this.appOptions.showUpdateUI = e)
    )
    EventBus.$on('app:force-refresh', this.forceUpdate)
  },
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener('waiting', () => {
        this.appOptions.showUpdateUI = true
      })
    }
  },
  methods: {
    async forceUpdate() {
      if (!this.$workbox) {
        return
      }
      this.appOptions.showUpdateUI = false
      await this.$workbox.messageSW({ type: 'SKIP_WAITING' })
    },
  },
})
