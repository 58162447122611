import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { ApiResult } from '@/models/dto/ApiResult'
import { CompanyStop } from '@/models/CompanyStop'

const httpService: HttpService = new HttpService()

export default {
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<CompanyStop>>> {
    const { sorts = null, filters = null, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = apiBaseUrl()

    return httpService.get(`https://${host}/companyStops?${query}`)
  },
  byId(id: number): Promise<AxiosResponse<CompanyStop>> {
    return httpService.get(`https://${apiBaseUrl()}/stops/company/${id}`)
  },
  delete(id: number): Promise<AxiosResponse<ApiResult>> {
    return httpService.delete(`https://${apiBaseUrl()}/stops/company/${id}`)
  },
  create(stop: CompanyStop): Promise<AxiosResponse<CompanyStop>> {
    stop.isActive = true
    return httpService.post(`https://${apiBaseUrl()}/stops/company`, stop)
  },
  update(stop: CompanyStop, id: number): Promise<AxiosResponse<CompanyStop>> {
    stop.isActive = true
    return httpService.patch(
      `https://${apiBaseUrl()}/stops/company/${id}`,
      stop
    )
  },
}
