
import { UploadedFile } from '@/models/dto/UploadedFile'
import { baseUrl } from '@/utils/env'
import { EventBus } from '@/utils/eventBus'
import pluralize from 'pluralize'
import { Vue, Component, Prop } from 'vue-property-decorator'

const defaultFileTypes = ['image/png', 'application/pdf', 'image/jpeg']

@Component({})
export default class CUFileUpload extends Vue {
  @Prop({ default: 'Upload Files' }) buttonText: string
  @Prop({ default: () => defaultFileTypes }) acceptedFileTypes: string[]
  @Prop({ default: 'Unsupported file type' })
  rejectedFileTypeErrorMessage: string
  @Prop({ default: 10 * 1024 * 1024 }) maximumFileSize: number
  @Prop({ default: 5 }) numberOfAvailableSlots: number
  @Prop({ required: false, default: () => [] }) value: File[]
  @Prop({ required: false, default: () => [] }) uploadedFiles: UploadedFile[]
  @Prop({ required: false, default: false }) isLoading: boolean
  @Prop({ required: false, default: true }) showAddFileButton: boolean

  error = null
  pluralize = pluralize

  get fileExists(): boolean {
    return !!this.value.length || !!this.uploadedFiles.length
  }

  get inputAcceptedFileTypes(): string {
    return this.acceptedFileTypes.join(',')
  }

  get valueLength(): number {
    return this.value?.length || 0
  }

  get uploadedFilesLength(): number {
    return this.uploadedFiles?.length || 0
  }

  get availableSlots(): number {
    return (
      this.numberOfAvailableSlots -
      (this.valueLength + this.uploadedFilesLength)
    )
  }

  handleFileUpload(event: Event): void {
    const files = (event.target as HTMLInputElement).files
    this.uploadFiles(files)
    ;(event.target as HTMLInputElement).value = ''
  }

  handleDragOver(event: DragEvent): void {
    event.dataTransfer.dropEffect = 'copy'
  }

  handleDrop(event: DragEvent): void {
    const files = event.dataTransfer.files
    this.uploadFiles(files)
  }

  uploadFiles(files: any): void {
    if (!files) {
      return
    }
    if (files.length > this.availableSlots) {
      EventBus.$emit(
        'snackbar:error',
        `Uploads are limited to ${this.numberOfAvailableSlots} ${pluralize(
          'file',
          this.numberOfAvailableSlots
        )}`
      )
      return
    }
    for (const file of files) {
      if (!this.acceptedFileTypes.includes(file.type)) {
        EventBus.$emit('snackbar:error', this.rejectedFileTypeErrorMessage)
        return
      } else if (file.size > this.maximumFileSize) {
        EventBus.$emit('snackbar:error', 'File size is too large')
        return
      }
      this.$emit('upload', file)
    }
  }

  removeValueFile(index: number): void {
    this.$emit('delete-file', index)
  }

  removeUploadedFile(id: number): void {
    this.$emit('delete-uploaded-file', id)
  }

  handleOpenUploadedFile(file: UploadedFile): void {
    const host = baseUrl()
    window.open(`https://${host}${file.url}`, '_blank')
  }

  handleOpenFile(file: File): void {
    this.openFileInNewTab(file)
  }

  openFileInNewTab = (file: File): void => {
    const fileURL = URL.createObjectURL(file)

    window.open(fileURL, '_blank')

    // Optionally release the created object URL after some time
    setTimeout(() => {
      URL.revokeObjectURL(fileURL)
    }, 1000 * 60) // Here we're releasing it after 1 minute, but you can adjust this
  }

  triggerFileInput(): void {
    const fileInput: any = this.$refs.fileInput
    fileInput.click()
  }
}
