
import { AssignedDriver, AssignedVehicle } from '@/models/dto'
import assignment from '@/store/modules/assignment'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class AssignmentsAvatar extends Vue {
  @Prop({ required: false, default: null }) vehicleAssignment: AssignedVehicle
  @Prop({ required: false, default: null }) driverAssignment: AssignedDriver
  @Prop({ type: Boolean, default: false }) small: boolean

  get iconWidth(): string {
    return this.small ? '16px' : '20px'
  }

  get warningSize(): number {
    return this.small ? 14 : 16
  }

  get isVehicle(): boolean {
    return !!this.vehicleAssignment
  }

  get driverInitials(): string {
    if (!this.driverAssignment?.name) {
      return ''
    }
    return `${this.driverAssignment.name[0] || ''}${
      this.driverAssignment.name.split(' ').filter((n) => !!n)[1][0] || ''
    }`
  }

  get hasConflict(): boolean {
    if (this.vehicleAssignment) {
      const isVehicleUnavailable = !!assignment.unavailableVehicles.find(
        (uv) =>
          uv.vehicleId === this.vehicleAssignment.id &&
          uv.reservationId !== assignment.selectedReservationId
      )

      const vehicleHasTimeOff = !!assignment.getVehicleTimeOff.find(
        (vto) => vto.vehicleId === this.vehicleAssignment.id
      )

      return isVehicleUnavailable || vehicleHasTimeOff
    } else {
      return !!assignment.unavailableDrivers.find(
        (ud) =>
          ud.driverId === this.driverAssignment.id &&
          ud.reservationId !== assignment.selectedReservationId
      )
    }
  }

  get isAssigned(): boolean {
    return !!this.vehicleAssignment?.id || !!this.driverAssignment?.id
  }

  get isConfirmed(): boolean {
    return (
      this.vehicleAssignment?.isConfirmed || this.driverAssignment?.isConfirmed
    )
  }
}
