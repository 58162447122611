import { VuexModule, Module, Action } from 'vuex-class-modules'
import CUDataTableCustomizeColumnHeader from '@/components/CUDataTableCustomizeColumnHeader.vue'
import { v4, validate } from 'uuid'
import store from '@/store/index'
import { TableViewFilter, TableViewSort } from '@/models/TableView'
import { DataTableColumn } from '@/models/DataTableColumn'
import _cloneDeep from 'lodash.clonedeep'
import { CurrentSort, SavedView } from '@/models/dto/SavedView'
import {
  getEmptyValueForFilterColumn,
  isNotEmptyFilter,
} from '@/utils/filtering'
import { EventBus } from '@/utils/eventBus'
import dayjs from 'dayjs'
import { predefinedFutureDate, predefinedPastDate } from '@/utils/datePicker'
@Module({ generateMutationSetters: true })
class ColumnsModule extends VuexModule {
  // -- STATE -- //

  // Columns
  customizeHeaderColumn = {
    _t_id: v4(), // Not used in any saved views, so just needs to be random
    text: 'Customize Columns',
    value: 'customize-columns',
    type: 'customize-columns',
    headerComponent: CUDataTableCustomizeColumnHeader,
    filterable: false,
    sortable: false,
    width: '100%',
  }
  tableColumns = []

  // Filtering
  singleSelectFilters = []
  searchBarFilters = []
  multiSelectFilters = []
  filterableColumns = []
  currentSort: CurrentSort = null
  isDownloadEnabled = false
  isExportSummaryEnabled = false

  // Saved Views
  currentSavedView: SavedView | undefined = undefined
  savedViews: SavedView[] = []
  tableId = ''
  pageSize = 10

  // -- GETTERS -- //

  // Columns
  get getColumns() {
    return this.tableColumns.filter(
      (col) => col.type !== 'customize-columns' && !col.invisible
    )
  }

  get getAllColumns() {
    return this.tableColumns
  }

  get getVisibleColumns() {
    return this.tableColumns.filter((col) => !col.hidden && !col.invisible)
  }

  // Filtering
  get getFilters() {
    return {
      singleSelectFilters: this.singleSelectFilters,
      searchBarFilters: this.searchBarFilters,
      multiSelectFilters: this.multiSelectFilters,
    }
  }

  get getSearchBarFilters() {
    return this.searchBarFilters
  }

  get getSingleSelectFilters() {
    return this.singleSelectFilters
  }

  get getMultiSelectFilters() {
    return this.multiSelectFilters
  }

  get getFilterableColumns() {
    return this.filterableColumns
  }

  get getSearchableColumns(): DataTableColumn[] {
    return this.tableColumns.filter((col) => col.filterBySearch)
  }

  get getCurrentSort() {
    return this.currentSort
  }

  get getIsDownloadEnabled() {
    return this.isDownloadEnabled
  }

  get getIsExportSummaryEnabled() {
    return this.isExportSummaryEnabled
  }

  // ACTIONS
  // Saved Views
  get getPageSize(): number {
    return this.pageSize
  }

  get getCurrentSavedView(): SavedView {
    return this.currentSavedView
  }

  get getCurrentSavedViewId(): number {
    if (this.currentSavedView) {
      return this.currentSavedView.savedViewId
    } else {
      return -1
    }
  }

  get getAllSavedViews(): SavedView[] {
    return this.savedViews
  }

  get getTableId(): string {
    return this.tableId
  }

  get areAllFiltersEmpty(): boolean {
    return (
      this.filterableColumns.filter(
        (col) => col.filterInterface && isNotEmptyFilter(col.filterValue)
      ).length === 0
    )
  }

  get areMoreFiltersSelected(): boolean {
    const hiddenFilters = this.filterableColumns.filter(
      (col) => col.filterInterface && !col.filterHiddenOnTopBar === false
    )
    return !!hiddenFilters.find(
      (col) => col.filterInterface && isNotEmptyFilter(col.filterValue)
    )
  }

  // -- ACTIONS -- //
  @Action
  setPageSize(pageSize: number): void {
    this.pageSize = pageSize
  }

  // Column Actions
  @Action
  clearColumns() {
    this.tableColumns = []
  }

  @Action
  setColumns(columns) {
    this.tableColumns = columns
    this.initializeFilterableColumns(columns)
  }

  @Action
  setColumnsWithCustomizeHeader(columns) {
    this.tableColumns = columns.filter(
      (col) => col.type !== 'customize-columns'
    )
    this.tableColumns.push(this.customizeHeaderColumn)
  }

  @Action
  setActive(_t_id) {
    const col = this.tableColumns.find((c) => c._t_id === _t_id)
    if (col) {
      col.hidden = !col.hidden
    }
  }

  @Action
  setIsDownloadEnabled(isEnabled: boolean) {
    this.isDownloadEnabled = isEnabled
  }

  @Action
  setIsExportSummaryEnabled(isEnabled: boolean) {
    this.isExportSummaryEnabled = isEnabled
  }

  // Filter Actions

  // Update the stored value for a select/date-picker, and then
  // add to the singleSelectFilters array
  @Action
  updateFilters({ column, value, filterType = 'eq', filterLogic = 'and' }) {
    const didUpdateFilterValue = this.updateFilterValues({
      column,
      value,
      filterType,
      filterLogic,
    })
    if (!didUpdateFilterValue) {
      return
    }
    if (isNotEmptyFilter(value)) {
      this.addSelectableFilter({ column, value, filterType, filterLogic })
    } else {
      this.clearSelectableFilter({ column })
    }
  }

  // Given a search query, build the needed filters and
  // then add to the searchBarFilters array
  @Action
  updateSearchFilters(searchQuery: string): void {
    if (!searchQuery) {
      this.searchBarFilters = []
      return
    }
    const filtersToAdd = []

    for (const col of this.getSearchableColumns) {
      const filterType = this.isNumberOrDateType(col) ? 'eq' : 'contains'
      // Only set filterAsIs to false if the value is explicitly false
      const filterAsIs = col.filterAsIs !== false
      if (Array.isArray(col.filterProp)) {
        for (const filterProp of col.filterProp) {
          filtersToAdd.push({
            column: {
              _t_id: v4(),
              filterType,
              filterAsIs,
              filterProp,
            },
            value: searchQuery,
          })
        }
      } else {
        filtersToAdd.push({
          column: {
            _t_id: v4(),
            filterType,
            filterAsIs,
            filterProp: col.filterProp,
          },
          value: searchQuery,
        })
      }
    }

    this.searchBarFilters = filtersToAdd
  }

  // Assumes the filter value has already been updated
  // Given a column and a value, build the required filter
  // and then add to the singleSelectFilters array
  @Action
  addSelectableFilter({ column, value, filterType, filterLogic = 'and' }) {
    this.singleSelectFilters = this.singleSelectFilters.filter(
      (f) => f.columnId !== column._t_id
    )

    if (column.filterInterface === 'date-range' && value.length > 1) {
      if (value[0] !== null && value[0] !== predefinedPastDate) {
        const gteFilter = this.buildFilter(column, value[0], 'gte')
        this.singleSelectFilters.push(gteFilter)
      }

      if (value[1] !== null && value[1] !== predefinedFutureDate) {
        const lteFilter = this.buildFilter(column, value[1], 'lte')
        this.singleSelectFilters.push(lteFilter)
      }
    } else if (column.filterInterface === 'price-range' && value.length > 1) {
      if (value[0] !== null) {
        const gteFilter = this.buildFilter(column, value[0].toString(), 'gte')
        this.singleSelectFilters.push(gteFilter)
      }

      if (value[1] !== null) {
        const lteFilter = this.buildFilter(column, value[1].toString(), 'lte')
        this.singleSelectFilters.push(lteFilter)
      }
    } else if (column.filterInterface === 'multi-select') {
      this.multiSelectFilters = this.multiSelectFilters.reduce((arr, f) => {
        if (f.columnId !== column._t_id) {
          arr.push(f)
        }
        return arr
      }, [])
      for (const filter of value) {
        const filterToAdd = this.buildFilter(column, filter, filterType)
        this.multiSelectFilters.push(filterToAdd)
      }
    } else if (column.filterInterface === 'select') {
      const newFilter = this.buildFilter(column, value, filterType, filterLogic)
      this.singleSelectFilters.push(newFilter)
    } else {
      const newFilter = this.buildFilter(column, value, filterType, filterLogic)
      this.singleSelectFilters.push(newFilter)
    }
  }

  @Action
  clearSelectableFilter({ column }) {
    this.singleSelectFilters = this.singleSelectFilters.filter(
      (f) => f.columnId !== column._t_id
    )
    this.multiSelectFilters = this.multiSelectFilters.filter(
      (f) => f.columnId !== column._t_id
    )
  }

  @Action
  clearAllFilters(): void {
    this.searchBarFilters = []
    this.singleSelectFilters = []
    this.multiSelectFilters = []
    this.initializeFilterableColumns(this.tableColumns)
  }

  @Action
  clearSingleSelectFilters(): void {
    this.singleSelectFilters = []
  }

  @Action
  clearMultiSelectFilters(): void {
    this.multiSelectFilters = []
  }

  @Action
  clearSearchBarFilters(): void {
    this.searchBarFilters = []
  }

  // Usage on the More Filters sidebar--values for filters are
  // stored locally there, and then only applied when Apply is
  // clicked, which calls this method
  // For each column, find its equivalent in filterableColumns.
  // If the values are not the same, add/remove filters accordingly
  @Action
  syncFilterableColumns(updatedFilterableColumns): void {
    for (const updatedColumn of updatedFilterableColumns) {
      const currentColumn = this.filterableColumns.find(
        (col) => col._t_id === updatedColumn._t_id
      )
      const updatedFilterValue = updatedColumn.filterValue
      if (currentColumn.filterValue !== updatedFilterValue) {
        currentColumn.filterValue = updatedFilterValue
        this.updateFilters({
          column: currentColumn,
          value: updatedFilterValue,
          filterType: updatedColumn.filterType,
          filterLogic: updatedColumn.filterLogic,
        })
      }
    }
  }

  // sorts
  @Action
  clearCurrentSorts() {
    this.currentSort = null
  }

  @Action
  addColumnSort(sortObject: TableViewSort) {
    this.currentSort = sortObject
  }

  // Saved View Actions
  @Action
  applySavedView(savedView: SavedView): void {
    this.currentSavedView = savedView
    let currentColumns = _cloneDeep(this.getAllColumns)
    let model = []

    for (const savedViewColumn of savedView.viewSettings.columns) {
      const col = currentColumns.find((c) => c._t_id === savedViewColumn._t_id)
      if (!col) {
        continue
      }
      col.hidden = savedViewColumn.hidden
      col.filterValue = savedViewColumn.filterValue
      col.filterType = savedViewColumn.filterType
      col.filterLogic = savedViewColumn.filterLogic

      // Intentional single equals
      if (savedViewColumn.filterValue != null) {
        // If a date range filter is of 'In the Future' or 'In the Past'
        // set the date cap to the current date
        if (
          col.filterInterface === 'date-range' &&
          Array.isArray(savedViewColumn.filterValue) &&
          (savedViewColumn.filterValue.includes(null) ||
            savedViewColumn.filterValue.includes(predefinedFutureDate) ||
            savedViewColumn.filterValue.includes(predefinedPastDate))
        ) {
          const dateIndex = savedViewColumn.filterValue.findIndex(
            (v) => !!v && v !== predefinedFutureDate && v !== predefinedPastDate
          )
          if (dateIndex > -1) {
            if (dateIndex === 0) {
              col.filterValue = [
                dayjs().format('YYYY-MM-DD'),
                predefinedFutureDate,
              ]
            } else {
              col.filterValue = [
                predefinedPastDate,
                dayjs().format('YYYY-MM-DD'),
              ]
            }
          }
        }

        this.updateFilters({
          column: col,
          value: col.filterValue,
          filterType: col.filterType,
          filterLogic: col.filterLogic,
        })
      } else if (col.filterInterface) {
        const emptyValue = getEmptyValueForFilterColumn(col)
        this.updateFilters({
          column: col,
          value: emptyValue,
          filterType: col.filterType,
          filterLogic: col.filterLogic,
        })
      }

      model.push(col)
      currentColumns = currentColumns.filter(
        (c) => c._t_id !== savedViewColumn._t_id
      )
    }

    const pageSize = savedView.viewSettings.pageSize || 10
    const { currentSort } = savedView.viewSettings
    this.currentSort = currentSort
    EventBus.$emit('table-views:set-options', { pageSize, currentSort })

    model = model.concat(currentColumns)
    this.tableColumns = model
  }

  @Action
  setSavedViews(savedViews: SavedView[]) {
    this.savedViews = savedViews
  }

  @Action
  clearSavedViews() {
    this.savedViews = []
  }

  @Action
  setTableId(tableId: string): void {
    this.tableId = tableId
  }

  @Action
  clearTableId(): void {
    this.tableId = ''
  }

  // HELPER METHODS
  buildFilter(
    col: DataTableColumn,
    value: string | number,
    filterType: string = undefined,
    filterLogic: string = undefined
  ): TableViewFilter {
    filterType = filterType || col.filterType
    if (!filterLogic) {
      filterLogic = col.filterLogic
    }

    const filterAsIs = col.filterAsIs !== false
    return {
      column: {
        _t_id: v4(),
        filterType,
        filterProp: col.filterProp,
        filterAsIs,
        filterLogic,
      },
      value,
      columnId: col._t_id,
    }
  }

  initializeFilterableColumns(columns: DataTableColumn[]): void {
    let filterableColumns = _cloneDeep(columns.filter((col) => col.filterable))

    // Initialize the values for each interface
    filterableColumns = filterableColumns.map((c) => {
      let filterValue
      if (c.filterInterface === 'date-range') {
        filterValue = []
      } else if (c.filterInterface === 'price-range') {
        filterValue = []
      } else if (c.filterInterface === 'select') {
        filterValue = ''
      }

      if (c.filterInterface === 'select') {
        c.filterDropdownItems.unshift({ text: 'All', value: -1 })
        filterValue = -1
      }
      return { ...c, filterValue }
    })

    const dateRangeFilters = filterableColumns.filter(
      (c) => c.filterInterface === 'date-range'
    )
    const priceRangeFilters = filterableColumns.filter(
      (c) => c.filterInterface === 'price-range'
    )
    const nonRangeFilters = filterableColumns.filter(
      (c) =>
        c.filterInterface !== 'date-range' &&
        c.filterInterface !== 'price-range'
    )
    this.filterableColumns = [
      ...nonRangeFilters,
      ...priceRangeFilters,
      ...dateRangeFilters,
    ]
  }

  // Update the stored value for a given select/date-picker
  // in the filter row + more filters sidebar
  updateFilterValues({ column, value, filterType, filterLogic }): boolean {
    const filterableColumnsIndex = this.filterableColumns.findIndex(
      (col) => col._t_id === column._t_id
    )

    if (filterableColumnsIndex === -1) {
      return false
    }

    this.filterableColumns[filterableColumnsIndex].filterValue = value
    this.filterableColumns[filterableColumnsIndex].filterType = filterType
    this.filterableColumns[filterableColumnsIndex].filterLogic = filterLogic

    const tableColumnsIndex = this.tableColumns.findIndex(
      (col) => col._t_id === column._t_id
    )
    if (tableColumnsIndex !== -1) {
      this.tableColumns[tableColumnsIndex].filterValue = value
      this.tableColumns[tableColumnsIndex].filterType = filterType
      this.tableColumns[tableColumnsIndex].filterLogic = filterLogic
    }
    return true
  }

  isNumberOrDateType(col) {
    return (
      col.filterPropertyType === 'number' || col.filterPropertyType === 'date'
    )
  }
}
export default new ColumnsModule({ store, name: 'columns' })
