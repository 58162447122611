
import deepClone from '@/utils/deepClone'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CUSkeletonLoader extends Vue {
  @Prop({ type: String, default: '', required: false }) readonly type: string
  @Prop({ type: String, default: '', required: false }) readonly width: string
  @Prop({ type: String, default: '', required: false }) readonly height: string
  @Prop({ type: String, default: '', required: false })
  readonly classes: string
  @Prop({ type: Boolean, default: false, required: false })
  readonly multiply: boolean

  get computedHeight(): string {
    if (this.height) {
      return this.height
    }
    if (this.type === 'button') {
      return '40px'
    }
    if (this.type === 'text-button') {
      return '24px'
    }
    if (this.type === 'h1') {
      return '40px'
    }
    if (this.type === 'h3') {
      return '27px'
    }
    if (this.type === 'text') {
      return '18px'
    }
    if (this.type === 'detail-text') {
      return '16px'
    }
    if (this.type === 'icon') {
      return '24px'
    }
    return '14px'
  }

  get computedWidth(): string {
    if (this.width) {
      return this.width
    }
    if (this.type === 'icon') {
      return '24px'
    }
    if (this.type === 'avatar') {
      return this.computedHeight
    }
    return '100%'
  }

  get styles(): { [key: string]: string | number } {
    const styles = {
      height: this.computedHeight,
      width: this.computedWidth,
    }
    if (this.type === 'chip') {
      styles['border-radius'] = this.computedHeight
    }
    return styles
  }

  get computedClasses(): string {
    let classes = deepClone(this.classes)
    if (this.type === 'avatar') {
      classes = `${classes} border-radius-round`
    }
    if (this.multiply) {
      classes = `${classes} multiply`
    }
    if (this.type === 'text-button') {
      classes = `${classes} margin-y-2`
    }
    return classes
  }
}
