
import { Element } from '@/models/dto/Action'
import deepClone from '@/utils/deepClone'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class CUTagCloud extends Vue {
  @Prop({ required: false, default: 'Items' }) readonly title: string
  @Prop({ required: false, default: 'bold' }) readonly titleWeight: string
  @Prop({ required: false, default: '15px 12px' }) readonly gapStyle: string
  @Prop({ required: true, default: () => [] }) items: Element[]

  @Watch('items', { immediate: true })
  handleUpdateItems(): void {
    this.tags = deepClone(this.items)
  }

  tags: Element[] = []

  get areAllTagsSelected(): boolean {
    return this.tags.every((el) => el.active)
  }

  handleSelectTag(tag: Element): void {
    tag.active = !tag.active
    this.$emit('input', this.tags)
  }

  handleSelectAll(): void {
    this.tags.map((tag) => {
      tag.active = true
    })
    this.$emit('input', this.tags)
  }
}
