import { Stop } from './Stop'
import { Address } from './Address'
import { Vehicle, VehicleType } from './Vehicle'
import { DriverAssignment, AssignedDriver } from './DriverAssignment'

export interface VehicleAssignment {
  active: boolean
  createdOn: string
  driverAssignments?: DriverAssignment[]
  endDatetime: string
  firstAddress: Address
  firstAddressId: number
  firstStop?: Stop | null
  firstStopId?: number | null
  lastAddress: Address
  lastAddressId: number
  lastStop: Stop
  lastStopId: number
  reservationId: number
  startDatetime: string
  tripAssignmentId: number
  updatedOn?: string
  vehicle: Vehicle
  vehicleId: number
  vehicleName?: string
  vehicleType: VehicleType
  vehicleTypeId: number
  vehicleTypeLabel?: string
  isMissingCharterUpId?: boolean
  isConfirmed: boolean
}

// eslint-disable-next-line no-redeclare
export class VehicleAssignment implements VehicleAssignment {
  constructor(vehicleAssignment?: Partial<VehicleAssignment>) {
    this.driverAssignments = []
    Object.assign(this, vehicleAssignment)
  }
}

export interface AssignmentStops {
  reservationId?: number
  firstStop?: Stop
  firstStopId?: number
  lastStop?: Stop
  lastStopId?: number
  firstAddress?: Address
  firstAddressId?: number
  lastAddress?: Address
  lastAddressId?: number
}

export interface CreateAssignmentPayload extends AssignmentStops {
  vehicleId: number
  vehicleTypeId: number
  hardConflictOverride: boolean
  driverAssignments: DriverAssignment[]
}

export interface AssignedVehicle {
  isMissingCharterUpId?: boolean
  tripAssignmentId: number
  type: string
  originalType?: string
  vehicleId?: number
  vehicleTypeId?: number
  originalTypeId?: number
  isConfirmed: boolean
  id: number
  name: string
  errorMessage: string
  driverCount?: number
  drivers: AssignedDriver[]
  charterUpVehicleId?: number
}

export interface VehicleConflict {
  reservationId: number
  reservationManagedId: string
  reservationType: number
  conflictType: number
  customerName: string
  customerAccountName: string
  vehicleId: number
  vehicleType: number
  vehicleName: string
}

export interface RowAssignment {
  vehicleId: string
  vehicleName: string
  vehicleType: string
  drivers: RowDriver[]
  count?: number
}

export interface RowDriver {
  id: number
  name: string
  statusId: number
  count?: number
}
