
import { Vue, Component, Prop } from 'vue-property-decorator'
import { rules, validateRules } from '@/utils/rules'
import sidebar from '@/store/modules/sidebar'
import { states } from '@/utils/states'
import { CustomerAccountRequest } from '@/models/dto/CustomerAccount'
import AutocompleteAddress from '@/components/AutocompleteAddress.vue'
import { Address } from '@/models/dto'
import { formatAddressName, formattedPhoneToNumber } from '@/utils/string'
import CompanyGroupSelector from '@/components/CompanyGroupSelector.vue'
import { AxiosResponse } from 'axios'
import industryClient from '@/services/industries'
import customerAccounts from '@/services/customerAccount'
import { hasRequiredAddressFields } from '@/utils/validators'
@Component({
  components: {
    AutocompleteAddress,
    CompanyGroupSelector,
  },
})
export default class CreateCompanyForm extends Vue {
  @Prop({ type: Boolean, required: false }) companyOnly: boolean
  @Prop({ type: Boolean, required: false }) submitting: boolean

  rules = rules
  states = states
  industries = []

  company: Partial<CustomerAccountRequest> = {
    name: '',
    websiteUrl: '',
    email: '',
    phone: '',
    fax: '',
    address: new Address(),
    customerAccountGroups: [],
  }
  errors = {
    city: null,
    state: null,
    zip: null,
  }

  handleChangeAddressInput(input: string): void {
    this.company.address = { ...this.company.address, street1: input || '' }
  }

  handleChangeAddress(address: Address): void {
    this.errors = {
      city: null,
      state: null,
      zip: null,
    }
    if (address) {
      this.company = { ...this.company, address: { ...address } }
    } else {
      this.company.address.title = null
    }
  }

  handlePhoneChange(data: { phone: string; country: string }): void {
    this.company.phone = data.phone
    this.company.phoneCountryKey = data.country
  }

  clear(): void {
    sidebar.pop()
  }

  async submit(): Promise<void> {
    this.errors = {
      city: null,
      state: null,
      zip: null,
    }
    const isValidAddress = hasRequiredAddressFields(this.company.address)
    if (!(await validateRules(this)) || !isValidAddress) {
      if (!isValidAddress) {
        this.errors = {
          city: !this.company?.address?.city
            ? 'Required to save address'
            : null,
          state: !this.company?.address?.state
            ? 'Required to save address'
            : null,
          zip: !this.company?.address?.postalCode
            ? 'Required to save address'
            : null,
        }
      }
      return
    }

    let address = this.company.address
    if (!this.company.address?.state) {
      address = null
    }

    const newCompany: CustomerAccountRequest = {
      address,
      companyId: null,
      customerAccountReferralTerms: '',
      customerAccountNotes: this.company.customerAccountNotes,
      websiteUrl: this.company.websiteUrl,
      email: this.company.email,
      fax: formattedPhoneToNumber(this.company.fax),
      industryId: this.company.industryId,
      lightLogoUrl: '',
      name: this.company.name,
      notes: '',
      parentCustomerAccountId: '',
      phone: formattedPhoneToNumber(this.company.phone),
      phoneCountryKey: this.company.phoneCountryKey,
      referralTermsName: '',
      tags: [],
      teamLogoImage: null,
      useCustomReferralTerms: false,
      customerAccountGroups: this.company.customerAccountGroups,
    }

    if (this.company.address && newCompany.address) {
      newCompany.address.lat = null
      newCompany.address.lng = null
      newCompany.address.timeZone = null
      newCompany.address.addressName = formatAddressName(this.company.address)
    }

    const res = await customerAccounts.create(newCompany)
    this.$emit('company:created', { id: res.data, name: newCompany.name })
  }

  async load(): Promise<void> {
    const industryResponse: AxiosResponse = await industryClient.tableView({
      pageSize: -1,
      page: 1,
    })

    this.industries = [
      ...industryResponse.data.resultList.map((el) => {
        return { text: el.label, value: el.industryId }
      }),
    ]
  }

  mounted(): void {
    this.load()
  }
}
