
import { Vue, Component, Inject } from 'vue-property-decorator'
import sidebar from '@/store/modules/sidebar'
import CUDataTableCustomizeColumn from '@/components/CUDataTableCustomizeColumn.vue'
import columns from '@/store/modules/columns'

@Component({})
export default class CUDataTableCustomizeColumnHeader extends Vue {
  @Inject({ default: false }) isPdfExportEnabled: boolean

  get actions() {
    const cols = [
      {
        label: 'Edit Columns',
        icon: 'edit',
        event: (): void => this.openCustomizeColumnSidebar(),
      },
    ]

    if (columns.isDownloadEnabled) {
      cols.push({
        label: 'Download Excel',
        icon: 'download',
        event: (): void => this.handleExcelDownload(),
      })
    }

    if (columns.isExportSummaryEnabled && this.isPdfExportEnabled) {
      cols.push({
        label: 'Download Summary',
        icon: 'pdf',
        event: (): void => this.handlePdfDownload(),
      })
    }
    return cols
  }

  handleExcelDownload(): void {
    this.$emit('handle-excel-download')
  }

  handlePdfDownload(): void {
    this.$emit('handle-pdf-download')
  }

  openCustomizeColumnSidebar(): void {
    sidebar.push({
      component: CUDataTableCustomizeColumn,
      title: 'Edit Columns',
    })
  }
}
