var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"position-relative",on:{"mouseenter":() => (_vm.isHovered = true),"mouseleave":() => (_vm.isHovered = false)}},[_c('v-menu',{ref:"menu",staticClass:"z-105",attrs:{"offset-overflow":"","nudge-left":_vm.menuLeftOffset,"nudge-bottom":_vm.nudgeBottom,"transition":"slide-y-transition","content-class":"v-menu__content--shadow-light overflow-hidden min-w-0","bottom":"","attach":_vm.attach,"offset-y":"","rounded":"4","close-on-content-click":false},scopedSlots:_vm._u([(_vm.displayAsButton)?{key:"activator",fn:function({ on }){return [_c('CUButton',_vm._g({attrs:{"outlined":"","padding-x":12,"unset-width":""}},on),[_vm._v(" "+_vm._s(_vm.activatorTextFieldValue)+" "),_c('CUIcon',{staticClass:"margin-l-2",attrs:{"aria-label":"Select Dates","width":"20px","color":"primary"}},[_vm._v(" keyboard_arrow_down ")])],1)]}}:{key:"activator",fn:function({ on }){return [(_vm.$attrs.label)?_c('label',{staticClass:"font-14"},[_vm._v(_vm._s(_vm.$attrs.label))]):_vm._e(),_c('CUTextField',_vm._g(_vm._b({ref:"activator-text-field",staticClass:"cu-date-picker__activator-text-field",class:{
          'padding-r-5': !_vm.activatorTextFieldValue && !_vm.constantWidth,
          'v-input--widget': _vm.widget,
        },style:({
          width: _vm.activatorWidth,
          maxWidth: _vm.activatorWidth,
        }),attrs:{"disabled":_vm.disabled,"readonly":_vm.isRange,"label":_vm.label,"dense":_vm.dense,"placeholder":_vm.$attrs.placeholder || 'MM/DD/YY',"value":_vm.activatorTextFieldValue},on:{"input":_vm.handleActivatorInput,"change":_vm.handleActivatorChange,"focus":function($event){_vm.isActivatorFocused = true},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;return _vm.closeMenu.apply(null, arguments)}},scopedSlots:_vm._u([(!_vm.noIcon && ((!_vm.label && _vm.widget) || !_vm.widget))?{key:"prepend-inner",fn:function(){return [_c('CUIcon',_vm._g({key:"disabled ? 'disabled-calendar' : 'active-calendar'",attrs:{"color":"gray-icon-light"}},!_vm.disabled ? on : {}),[_vm._v(" calendar ")])]},proxy:true}:null,(_vm.displayClearIcon)?{key:"append",fn:function(){return [_c('div',{staticClass:"w-20"},[_c('v-btn',{attrs:{"icon":"","x-small":"","width":"20px","height":"20px"},on:{"click":_vm.clear}},[_c('CUIcon',{attrs:{"aria-label":"Clear","height":"19px","width":"19px","color":"bg-gray-5"}},[_vm._v(" close ")])],1)],1)]},proxy:true}:null],null,true)},'CUTextField',_vm.$attrs,false),on))]}},_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(props){return [_vm._t(slot,null,null,props)]}}})],null,true),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('div',{ref:"popover",staticClass:"position-relative"},[(_vm.isRange)?_c('div',{staticClass:"position-absolute background-bg-gray-2 z-4 left-252",style:({
          height: '237px',
          width: '1px',
          marginTop: '70px',
        }),attrs:{"role":"decorative","vertical":""}}):_vm._e(),(_vm.isRange && !_vm.hidePredefinedDates)?_c('div',{staticClass:"d-flex padding-y-3",style:({ marginLeft: '15px' })},_vm._l((_vm.predefinedDates),function(btn,btnIndex){return _c('CUButton',{key:`date-range-btn-${btnIndex}-${btn.key}`,staticClass:"margin-r-3 border-solid border-a-1 border-bg-gray-4",attrs:{"id":`date-range-btn-${btnIndex}-${btn.key}`,"outlined":btn.key !== _vm.selectedPredefined,"height":"32px","font-weight":"400","padding-x":"12px","unset-width":"","color":_vm.selectedPredefined === btn.key ? 'primary' : 'text-dark-gray'},on:{"click":function($event){return _vm.handlePredefinedClick(btn)}}},[_c('span',{staticClass:"font-400"},[_vm._v(_vm._s(btn.text))])])}),1):_vm._e(),(_vm.isRange)?_c('v-divider',{staticClass:"border-bg-gray-2",style:({ margin: '0 15px' })}):_vm._e(),_c('vc-date-picker',{ref:"calendar",style:(_vm.cssVars),attrs:{"columns":_vm.isRange ? 2 : 1,"step":1,"title-position":"left","attributes":_vm.attributes,"select-attribute":{
          highlight: _vm.selectedDateStyle,
        },"drag-attribute":{
          highlight: _vm.dragStyle,
        },"value":_vm.isRange ? _vm.selectedCalendarRange : _vm.selectedCalendarDate,"is-range":_vm.isRange},on:{"input":_vm.handleDatePickerInput,"dayclick":_vm.handleDayClick},scopedSlots:_vm._u([{key:"header-left-button",fn:function(){return [_c('CUIcon',{attrs:{"id":"cu-date-picker--arrow-back","aria-label":"Previous"}},[_vm._v(" keyboard_arrow_left ")])]},proxy:true},{key:"header-right-button",fn:function(){return [_c('CUIcon',{attrs:{"id":"cu-date-picker--arrow-forward","aria-label":"Next"}},[_vm._v(" keyboard_arrow_right ")])]},proxy:true}])}),(_vm.isRange)?_c('CUDatePickerFooter',{ref:"footer",attrs:{"start-date-input-value":_vm.startDateInputValue,"end-date-input-value":_vm.endDateInputValue},on:{"date-picker:start-date-input":function($event){_vm.startDateInputValue = $event},"date-picker:end-date-input":function($event){_vm.endDateInputValue = $event},"date-picker:start-date-focus":function($event){_vm.cachedStartDate = _vm.startDateInputValue},"date-picker:end-date-focus":function($event){_vm.cachedEndDate = _vm.endDateInputValue},"date-picker:start-date-change":_vm.handleStartDateInput,"date-picker:end-date-change":_vm.handleEndDateInput,"date-picker:close":_vm.closeMenu,"date-picker:confirm-dates":_vm.confirmDates}}):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }