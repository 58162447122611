
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import AutocompleteAddress from '@/components/AutocompleteAddress.vue'
import { Address } from '@/models/dto'
import { states } from '@/utils/states'

@Component({
  components: { AutocompleteAddress },
})
export default class EditableAutocompleteAddress extends Vue {
  @Prop({ required: true }) readonly value!: Address

  states = states
  address = new Address()

  @Watch('value', { immediate: true })
  onValueChange(): void {
    this.address = this.value || new Address()
  }

  handleChangeAddress(event: Address): void {
    this.address = event
    this.$emit('input', event)
  }
}
