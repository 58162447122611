import auth from '@/store/modules/auth'
import dayjs from 'dayjs'

const DEFAULT_COLOR = '#069E8A'

export const dragStyle = (color: string): any => {
  const _color = color || DEFAULT_COLOR
  return {
    start: {
      fillMode: 'outline',
      contentStyle: { color: '#FFF', fontWeight: '700 !important' },
      style: {
        borderColor: _color,
        backgroundColor: _color,
        color: '#FFF',
      },
    },
    base: {
      fillMode: 'light',
      contentStyle: { color: _color },
      style: { background: _color, opacity: '20%' },
    },
    end: {
      fillMode: 'outline',
      contentStyle: { color: '#FFF', fontWeight: '700 !important' },
      style: {
        borderColor: _color,
        backgroundColor: _color,
        color: '#FFF',
      },
    },
  }
}

export const selectedDateStyle = (color: string): any => {
  const _color = color || DEFAULT_COLOR
  return {
    start: {
      fillMode: 'solid',
      class: 'selected-date',
      contentStyle: {
        color: '#FFF',
        fontWeight: '700 !important',
      },
      style: {
        borderColor: _color,
        backgroundColor: _color,
        color: '#FFF',
      },
    },
    base: {
      fillMode: 'light',
      contentStyle: { color: _color },
      style: { background: _color, opacity: '20%' },
    },
    end: {
      fillMode: 'solid',
      contentStyle: {
        fontWeight: '700 !important',
      },
      style: {
        background: _color,
      },
    },
  }
}

export const todayStyle = (color: string): any => {
  const _color = color || DEFAULT_COLOR
  return {
    highlight: {
      fillMode: 'solid',
      style: {
        background: _color,
        opacity: '20%',
      },
      contentStyle: {
        fontWeight: '700 !important',
        color: _color,
      },
    },
    dates: new Date(),
  }
}

const tz = { timeZone: auth.getUserTimeZone }
const tomorrow = new Date().getTime() + 24 * 60 * 60 * 1000
export const predefinedDates = [
  {
    text: 'Today',
    key: 'today',
    startDate: new Date(new Date().toLocaleString('en-US', tz)),
    endDate: new Date(new Date().toLocaleString('en-US', tz)),
    focusDate: true,
  },
  {
    text: 'Tomorrow',
    key: 'tomorrow',
    startDate: new Date(new Date(tomorrow).toLocaleString('en-US', tz)),
    endDate: new Date(new Date(tomorrow).toLocaleString('en-US', tz)),
    focusDate: true,
  },
  {
    text: 'In the Future',
    key: 'future',
    startDate: new Date(),
    endDate: null,
    attributes: [
      {
        highlight: selectedDateStyle,
        dates: new Date(),
      },
      {
        highlight: dragStyle,
        dates: {
          start: new Date(),
        },
      },
    ],
    startDateInputValue: dayjs().format('M/D/YY'),
    endDateInputValue: 'Future',
  },
  {
    text: 'In the Past',
    key: 'past',
    startDate: null,
    endDate: new Date(),
    attributes: [
      {
        highlight: selectedDateStyle,
        dates: new Date(),
      },
      {
        highlight: dragStyle,
        dates: {
          end: new Date(),
        },
      },
    ],
    startDateInputValue: 'Past',
    endDateInputValue: dayjs().format('M/D/YY'),
  },
]

export const predefinedDateValues = [
  {
    key: 'today',
    label: 'Today',
  },
  {
    key: 'tomorrow',
    label: 'Tomorrow',
  },
  {
    key: 'future',
    label: 'In the Future',
  },
  {
    key: 'past',
    label: 'In the Past',
  },
]
