
import { Reservation } from '@/models/dto/Reservation'
import ReservationStore from '@/store/modules/reservation'
import { SourceCategory } from '@/utils/enum'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { reservationStatusTypes } from '@/data/types'

@Component
export default class ReservationDetailPanelHeader extends mixins(DateMixin) {
  statusOptions = reservationStatusTypes

  get reservation(): Reservation {
    return ReservationStore.reservation
  }

  get isReferral(): boolean {
    return this.reservation?.sourceCategory === SourceCategory.REFERRAL
  }

  get referralStatus(): string {
    const resStatus = reservationStatusTypes.find(
      (option) => option.key === this.reservation?.reservationStatus
    )
    return this.isReferral ? resStatus?.label : null
  }

  get reservationTripTypeLabel(): string {
    return this.reservation?.tripType?.label || '-'
  }
}
