
import { Component, Prop, Vue, Inject, Watch } from 'vue-property-decorator'
import { validationRules, validateRules } from '@/utils/rules'
import CUUserCard from '@/components/CUUserCard.vue'
import customerClient from '@/services/customer'
import sidebar from '@/store/modules/sidebar'
import AutocompleteAddress from '@/components/AutocompleteAddress.vue'
import { states } from '@/utils/states'
import { ContactRequest, ContactTypeKey, Customer } from '@/models/dto/Customer'
import { Address } from '@/models/dto'
import { AxiosResponse } from 'axios'
import industryClient from '@/services/industries'
import { hasRequiredAddressFields } from '@/utils/validators'
import { formatAddressName } from '@/utils/string'
import customerAccount from '@/services/customerAccount'
import CreateCompanyForm from './CreateCompanyForm.vue'
import { EventBus } from '@/utils/eventBus'
import auth from '@/store/modules/auth'
import { QuickbooksCustomerAssignRequest } from '@/models/dto/Quickbooks'
import quickbooksService from '@/services/quickbooks'
import CUQuickbooksCustomerSync from './CUQuickbooksCustomerSync.vue'
import HoldUpModal from './HoldUpModal.vue'
import app from '@/store/modules/app'

@Component({
  components: {
    CUUserCard,
    AutocompleteAddress,
    CUQuickbooksCustomerSync,
    HoldUpModal,
  },
})
export default class ContactSidebarDetail extends Vue {
  @Prop({ required: false }) readonly userId: number
  @Prop({ required: false }) readonly tripId: number
  @Prop({ required: false, default: false }) readonly simple!: number
  @Prop({ default: false, type: Boolean })
  readonly showContactsTVButton!: boolean
  @Prop({ required: false, default: '' }) readonly contactType: ContactTypeKey
  @Prop({ type: Boolean, default: false }) readonly contentOnly!: boolean
  @Prop({ type: Boolean, default: false }) readonly hideFooter!: boolean
  @Prop({ type: Number, required: false })
  readonly defaultCustomerAccountId!: number
  @Prop({ required: false, type: Boolean })
  readonly lockCompanyField!: boolean

  @Inject({ default: false }) isInContactDetailView: boolean

  @Watch('stringifiedUser')
  handleUserUpdate(newUser: string, oldUser: string): void {
    if (newUser === oldUser || !oldUser) {
      return
    }

    if (
      !this.loading &&
      !this.loadingIndustries &&
      !this.loadingCustomerAccounts
    ) {
      this.hasChanged = true
      this.$emit('contact-sidebar-detail:contact-modified')
    }
  }

  @Watch('auth.isQuickbooksIntegrationEnabled', {
    immediate: false,
    deep: false,
  })
  onQuickbooksIntegrationChanged(newValue: boolean): void {
    if (newValue === true) {
      this.fetchQuickbooksCustomerDetail()
      this.fetchQuickBooksContacts()
    }
  }

  auth = auth

  initialUser: Customer = null
  user: Customer | null = null
  loading = true
  loadingIndustries = true
  loadingCustomerAccounts = true
  submitting = false
  industries = []
  customerAccounts = []
  errors = {
    city: null,
    state: null,
    zip: null,
  }
  quickbooksCustomerId = null
  createNewQuickbooksCustomer = false
  deleteQuickbooksIntegration = false
  quickbooksContacts = []
  originalQuickbooksCustomerId = null
  quickBooksSyncToggle = false
  showCustomerCreationOverrideModal = false
  quickBooksError = false
  hasChanged = false

  states = states
  rules = validationRules

  // For use in the handleUserUpdate watcher above,
  // allowing us to check whether or not the user has been modified, rather than
  // deep watching the user object
  // This allows us to ignore differences in phone formatting, etc.
  get stringifiedUser(): string {
    let str = ''

    if (!this.user) {
      return ''
    }

    str += this.user.firstName || ''
    str += this.user.lastName || ''
    str += this.user.email || ''
    str += this.user.phone?.replace(/\D+/g, '') || ''
    str += this.user.phoneExtension || ''
    str += this.user.phoneCountryKey || ''
    str +=
      this.user.address && Object.keys(this.user.address).length > 0
        ? JSON.stringify(this.user.address)
        : ''
    str += this.user.title || ''
    str += this.user.customerAccountId || ''
    str += this.user.industryId || ''
    str += this.quickbooksCustomerId || ''
    str += this.createNewQuickbooksCustomer || ''

    return str
  }

  get isTripContact(): boolean {
    return this.contactType === 'Trip'
  }

  get confirmQBCustomerCreationText(): string {
    let displayName
    if (this.user?.customerAccountName) {
      displayName = this.user.customerAccountName
    } else if (this.user?.firstName && this.user?.lastName) {
      displayName = `${this.user?.firstName} ${this.user?.lastName}`
    } else if (this.user?.firstName) {
      displayName = `${this.user?.firstName}`
    } else if (this.userId === Number(app.charterupReferralUserId)) {
      displayName = 'CharterUp'
    }
    return `Your QuickBooks account already has a customer with this name. A new customer with name <strong>${displayName} (#)</strong> will be created instead and synced to this Busify contact.`
  }

  updateCustomer(partialCustomer: Partial<Customer>): void {
    this.user = Object.assign({}, this.user, partialCustomer)
  }

  close(): void {
    sidebar.pop()
  }

  handlePhoneChange(data: { phone: string; country: string }): void {
    this.user.phone = data.phone
    this.user.phoneCountryKey = data.country
  }

  handleUserAddressChange(address: Address): void {
    this.errors = {
      city: null,
      state: null,
      zip: null,
    }
    if (address) {
      this.user = {
        ...this.user,
        address: { ...address, street2: this.user.address?.street2 },
      }
    } else {
      this.user.address.title = null
    }
  }

  handleUserAddressInputChange(input: string): void {
    this.user.address = { ...this.user.address, street1: input }
  }

  handleQuickBooksSyncToggle(value: boolean): void {
    this.quickBooksSyncToggle = value
  }

  handleQuickbooksSync({
    sync,
    contactId,
    createNewCustomer = false,
  }: {
    sync: boolean
    contactId: string
    createNewCustomer?: boolean
  }): void {
    this.quickBooksError = false
    if (sync) {
      if (createNewCustomer) {
        this.createNewQuickbooksCustomer = true
        this.quickbooksCustomerId = null
        this.deleteQuickbooksIntegration = false
        return
      }
      this.createNewQuickbooksCustomer = false
      this.quickbooksCustomerId = contactId
      this.deleteQuickbooksIntegration = false
      return
    }
    this.createNewQuickbooksCustomer = false
    this.quickbooksCustomerId = null
    this.deleteQuickbooksIntegration = true
  }

  async submit(): Promise<void> {
    if (!(await this.verifyContactRequest())) {
      return
    }
    this.submitting = true
    try {
      if (!(await this.submitQuickBooksCustomer())) {
        return
      }
      await this.submitContactRequest()
    } catch (err: any) {
      EventBus.$emit('snackbar:error', err.response.data.message)
    }
  }

  async submitQuickBooksCustomer(): Promise<boolean> {
    if (this.createNewQuickbooksCustomer) {
      try {
        const response =
          await quickbooksService.createQuickbooksCustomerWithBusifyContactId(
            auth.getCompanyId,
            this.user.customerId
          )
        this.originalQuickbooksCustomerId =
          response.data.customerSync.quickbooksCustomerId
        this.quickbooksCustomerId = this.originalQuickbooksCustomerId
        this.createNewQuickbooksCustomer = false
        this.deleteQuickbooksIntegration = false
        this.fetchQuickBooksContacts()
      } catch (e: any) {
        if (
          e.response.data.status === 'BAD_REQUEST' &&
          (e.response.data.message.includes('Duplicate Name') ||
            e.response.data.message.includes('already exists'))
        ) {
          this.showCustomerCreationOverrideModal = true
          this.submitting = false
          return false
        }
        console.log(e)
        EventBus.$emit(
          'snackbar:error',
          'Unable to create new Quickbooks Customer'
        )
      }
    } else if (this.deleteQuickbooksIntegration) {
      try {
        await quickbooksService.deleteBusifyContactQuickbooksCustomerAssociation(
          auth.getCompanyId,
          this.user.customerId
        )
      } catch (e) {
        console.log(e)
        EventBus.$emit(
          'snackbar:error',
          'Unable to delete Quickbooks Customer Sync'
        )
      }
    } else if (
      this.quickbooksCustomerId &&
      this.quickbooksCustomerId !== this.originalQuickbooksCustomerId
    ) {
      try {
        const request: QuickbooksCustomerAssignRequest = {
          busifyId: this.user.customerId,
          qboId: this.quickbooksCustomerId,
        }
        await quickbooksService.assignQuickbooksCustomer(
          auth.getCompanyId,
          request
        )
      } catch (e) {
        console.log(e)
        EventBus.$emit(
          'snackbar:error',
          'Unable to connect contact to Quickbooks Customer'
        )
        this.submitting = false
        return
      }
    }
    return true
  }

  async submitContactRequest(): Promise<void> {
    const contactRequest = new ContactRequest(this.user)
    if (contactRequest.phone) {
      const unmaskedValue = contactRequest.phone.replace(/\D+/g, '')
      contactRequest.phone = unmaskedValue
    }
    if (contactRequest.address?.addressName) {
      contactRequest.address.addressName = formatAddressName(
        contactRequest.address
      )
    }
    if (
      Object.keys(contactRequest.address).length === 0 ||
      Object.values(contactRequest.address).every((el) => !el)
    ) {
      contactRequest.address = null
    }
    await customerClient.update(this.user.customerId, contactRequest)
    this.handleSuccessfulSubmit(contactRequest)
  }

  async verifyContactRequest(): Promise<boolean> {
    this.errors = {
      city: null,
      state: null,
      zip: null,
    }
    const contactRequest = new ContactRequest(this.user)
    if (contactRequest.phone) {
      const unmaskedValue = contactRequest.phone.replace(/\D+/g, '')
      contactRequest.phone = unmaskedValue
    }
    if (contactRequest.address?.addressName) {
      contactRequest.address.addressName = formatAddressName(
        contactRequest.address
      )
    }
    if (
      Object.keys(contactRequest.address).length === 0 ||
      Object.values(contactRequest.address).every((el) => !el)
    ) {
      contactRequest.address = null
    }
    const isValidAddress = hasRequiredAddressFields(contactRequest.address)
    if (!(await validateRules(this)) || !isValidAddress) {
      if (!isValidAddress) {
        this.errors = {
          city: !contactRequest.address?.city
            ? 'Required to save address'
            : null,
          state: !contactRequest.address?.state
            ? 'Required to save address'
            : null,
          zip: !contactRequest.address?.postalCode
            ? 'Required to save address'
            : null,
        }
      }
      return false
    }
    if (
      this.quickBooksSyncToggle &&
      !this.quickbooksCustomerId &&
      !this.createNewQuickbooksCustomer
    ) {
      this.quickBooksError = true
      return false
    }
    return true
  }

  closeQuickBooksCustomerCreationOverrideModal(): void {
    this.$emit('contact-sidebar-detail:contact-modified')
  }

  async overrideCustomerCreation(): Promise<void> {
    this.submitting = true
    try {
      const response =
        await quickbooksService.createQuickbooksCustomerWithBusifyContactId(
          auth.getCompanyId,
          this.user.customerId,
          true
        )
      this.originalQuickbooksCustomerId =
        response.data.customerSync.quickbooksCustomerId
      this.quickbooksCustomerId = this.originalQuickbooksCustomerId
      this.createNewQuickbooksCustomer = false
      this.deleteQuickbooksIntegration = false
      this.fetchQuickBooksContacts()
      await this.submitContactRequest()
    } catch (e) {
      console.log(e)
      EventBus.$emit(
        'snackbar:error',
        'Unable to create new Quickbooks Customer'
      )
    }
    this.submitting = false
  }

  handleSuccessfulSubmit(contactRequest: ContactRequest): void {
    this.initialUser = { ...this.user }
    const updatedContact = {
      id: this.user.customerId,
      customerId: this.user.customerId,
      customer: contactRequest,
      firstName: contactRequest.firstName,
      lastName: contactRequest.lastName,
      email: contactRequest.email,
      phone: contactRequest.phone,
      phoneCountryKey: contactRequest.phoneCountryKey,
      phoneExtension: contactRequest.phoneExtension,
    }
    this.$emit('refresh', updatedContact)
    EventBus.$emit('contact-sidebar:update', {
      contact: updatedContact,
      contactType: this.contactType,
      tripId: this.tripId,
    })
    this.submitting = false
    sidebar.pop()
  }

  async loadCustomerAccounts(): Promise<void> {
    const res = await customerAccount.tableView({ page: 1, pageSize: -1 })
    this.customerAccounts = res.data.resultList

    if (this.defaultCustomerAccountId) {
      this.user.customerAccountId = this.defaultCustomerAccountId
    }
    this.loadingCustomerAccounts = false
  }

  async loadIndustries(): Promise<void> {
    const industryResponse: AxiosResponse = await industryClient.tableView({
      pageSize: -1,
      page: 1,
    })
    this.industries = [
      ...industryResponse.data.resultList.map((el) => {
        return { text: el.label, value: el.industryId }
      }),
    ]
    this.loadingIndustries = false
  }

  handleOpenCreateCompany(): void {
    sidebar.push({
      component: CreateCompanyForm,
      props: { companyOnly: true },
      on: {
        'company:created': (id: number) => {
          if (this.isInContactDetailView) {
            this.handleCompanyCreatedInDetailView(id)
          } else {
            sidebar.pop()
          }
        },
      },
    })
  }

  async handleCompanyCreatedInDetailView(id: number): Promise<void> {
    await this.loadCustomerAccounts()
    this.user.customerAccountId = id
    sidebar.pop()
  }

  async created(): Promise<void> {
    this.loadIndustries()
    this.loadCustomerAccounts()

    if (this.userId) {
      try {
        const customerPromise = customerClient.byId(this.userId)

        if (auth.isQuickbooksIntegrationEnabled) {
          this.fetchQuickbooksCustomerDetail()
        }

        const quickbooksContactsPromise = this.fetchQuickBooksContacts()

        const [customerResult, quickbooksContactsResult] =
          await Promise.allSettled([customerPromise, quickbooksContactsPromise])

        if (customerResult.status === 'fulfilled') {
          this.user = customerResult.value?.data?.customer
        } else {
          console.log('Error with customer fetch:', customerResult.reason)
        }

        if (quickbooksContactsResult.status === 'fulfilled') {
          // handle quickbooksContactsResult.value if needed
        } else {
          console.log(
            'Error with Quickbooks contacts fetch:',
            quickbooksContactsResult.reason
          )
        }
      } catch (e) {
        console.log('Unexpected error:', e)
      }
    }

    if (!this.user.address) {
      this.user = { ...this.user, address: new Address() }
    }

    this.initialUser = { ...this.user }
    this.loading = false
  }

  async fetchQuickbooksCustomerDetail(): Promise<void> {
    try {
      const quickbooksDetail =
        await quickbooksService.getContactQuickbooksDetail(
          auth.getCompanyId,
          this.userId
        )

      this.quickbooksCustomerId =
        quickbooksDetail?.data?.customerSync?.quickbooksCustomerId
      if (this.quickbooksCustomerId) {
        this.quickBooksSyncToggle = true
      }
    } catch (error) {
      console.log('Error with Quickbooks fetch:', error)
    }
  }

  async fetchQuickBooksContacts(): Promise<void> {
    if (!auth.isQuickbooksIntegrationEnabled) {
      return
    }
    try {
      const response = await quickbooksService.getCompanyQuickbooksCustomers(
        auth.getCompanyId
      )
      const customers = response.data.customers
      this.quickbooksContacts = [
        ...customers.map((contact) => {
          return {
            text: `${contact.name} (ID: ${contact.id})`,
            value: contact.id,
          }
        }),
      ]
    } catch (e) {
      console.log(e)
    }
  }
}
