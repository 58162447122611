
import { ContactRequest, ContactTypeKey, Customer } from '@/models/dto/Customer'
import { CustomerAccount } from '@/models/dto/CustomerAccount'
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
import { formatFullName } from '@/utils/string'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ContactSidebarDetail from './ContactSidebarDetail.vue'
import CreateContactSidebar from './CreateContactSidebar.vue'
import { filter } from '@/utils/filter'
import customerClient from '@/services/customer'
import customerAccountClient from '@/services/customerAccount'
import auth from '@/store/modules/auth'
import reservation from '@/store/modules/reservation'
import reservationClient from '@/services/reservation'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'
import ReservationDetailPanel from './ReservationDetailPanel.vue'
@Component({
  name: 'CustomerContactSearch',
})
export default class CustomerContactSearch extends Vue {
  @Prop({ required: false, default: false, type: Boolean })
  readonly hideCompanies!: boolean
  @Prop({ required: false, default: false, type: Boolean })
  readonly hideContacts!: boolean
  @Prop({ required: false, default: undefined })
  readonly customerAccountId!: number
  @Prop({ required: false, default: null }) title: string
  @Prop({ required: false, default: null }) tripId: number
  @Prop({ type: Boolean, default: false }) unsetWidth: boolean
  @Prop({ type: Boolean, default: false }) isDispatch: boolean
  @Prop({ default: false, type: Boolean }) readonly noMargin: boolean
  @Prop({ default: false, type: Boolean }) readonly error!: boolean
  @Prop({ default: false, type: Boolean }) readonly isTripContact!: boolean
  @Prop({ default: false, type: Boolean }) readonly preventGlobalEmit: boolean
  @Prop({ required: false, default: undefined })
  readonly prepopulateCreateContact: Partial<ContactRequest>

  formatFullName = formatFullName

  showAll = false
  showMenu = false
  searchTerm = ''
  debounce = null
  loading = false
  searchResultsMenuIsOpen = false
  searchInputIsFocused = false

  companiesList = []
  companyListPage = 1
  allCompaniesLoaded = false
  contactsList = []
  contactListPage = 1
  PAGE_SIZE = 15
  allContactsLoaded = false

  @Watch('searchTerm')
  handleSearchTermUpdate(searchTerm: string): void {
    this.$emit('contact-search:reset-error')
    if (searchTerm) {
      this.loading = true
    }

    if (searchTerm && this.searchInputIsFocused) {
      this.searchResultsMenuIsOpen = true
    }

    if (!searchTerm && this.customerAccountId) {
      this.onCustomerAccountIdChange(this.customerAccountId)
      return
    }

    if (this.debounce) {
      window.clearTimeout(this.debounce)
    }

    this.debounce = setTimeout(this.search, 250)
  }

  @Watch('searchInputIsFocused')
  onSearchInputFocus(isFocused: boolean): void {
    if (isFocused && this.searchTerm) {
      this.searchResultsMenuIsOpen = true
    }
  }

  @Watch('customerAccountId', { immediate: true })
  async onCustomerAccountIdChange(customerAccountId: number): Promise<void> {
    if (customerAccountId) {
      await this.searchContacts({ returnAllInCompany: true })
    }
  }

  get contactType(): ContactTypeKey {
    if (this.title !== 'Billing Contact' && !this.isTripContact) {
      return 'Booking'
    } else if (this.isTripContact) {
      return 'Trip'
    } else if (this.title === 'Billing Contact') {
      return 'Billing'
    }
    return ''
  }

  get canViewContacts(): boolean {
    return auth.getUserRoleNames.includes(ACCESS_SETTINGS_ROLES.CONTACTS)
  }

  async handleEndReached(): Promise<void> {
    if (this.allCompaniesLoaded && this.allContactsLoaded) {
      return
    }

    if (this.loading) {
      return
    }

    try {
      this.loading = true
      if (!this.allCompaniesLoaded && !this.hideCompanies) {
        await this.searchCompanies()
        this.companyListPage += 1
      }

      if (
        (this.allCompaniesLoaded || this.hideCompanies) &&
        !this.allContactsLoaded
      ) {
        await this.searchContacts()
        this.contactListPage += 1
      }
    } catch (e) {
      console.log(e)
    } finally {
      this.loading = false
    }
  }

  async search(): Promise<void> {
    this.companiesList = []
    this.contactsList = []
    this.allCompaniesLoaded = false
    this.allContactsLoaded = false
    this.companyListPage = 1
    this.contactListPage = 1
    if (!this.searchTerm) {
      this.loading = false
      return
    }

    if (!this.hideCompanies) {
      await this.searchCompanies()
    }

    if (!this.hideContacts && (this.allCompaniesLoaded || this.hideCompanies)) {
      await this.searchContacts()
    }

    this.loading = false
  }

  async searchContacts(
    options: { returnAllInCompany: boolean } = { returnAllInCompany: false }
  ): Promise<void> {
    const contactFilters = filter()

    // Contact Filters
    const nameFilter = {
      column: {
        _t_id: '53a8508a-8d4c-47e3-9b1b-a4422a34dd0e',
        filterAsIs: true,
        filterProp: 'fullName',
        filterType: 'contains',
      },
      value: this.searchTerm,
    }

    const emailFilter = {
      column: {
        _t_id: '0cf16b29-28c4-4825-8439-eb14af5736bb',
        filterAsIs: true,
        filterProp: 'email',
        filterType: 'contains',
      },
      value: this.searchTerm,
    }

    const phoneNumberFilter = {
      column: {
        _t_id: '86039f46-31ac-4750-8134-08fc83b8fcbf',
        filterAsIs: true,
        filterProp: 'phone',
        filterType: 'contains',
      },
      value: this.searchTerm,
    }

    if (this.customerAccountId) {
      const customerAccountIdFilter = {
        column: {
          _t_id: '96016d43-d04e-4a65-917c-d2309ee7f019',
          filterProp: 'customerAccountId',
          filterType: 'eq',
        },
        value: this.customerAccountId.toString(),
      }

      const topLevelFilter = contactFilters.createParent('and')
      const searchFilter = contactFilters.createParent('or', topLevelFilter)
      const customerAccountFilter = contactFilters.createParent(
        'and',
        topLevelFilter
      )

      contactFilters.add(customerAccountFilter, customerAccountIdFilter)

      if (!options.returnAllInCompany) {
        contactFilters.add(searchFilter, nameFilter)
        contactFilters.add(searchFilter, phoneNumberFilter)
        contactFilters.add(searchFilter, emailFilter)
      }
    } else {
      const topLevelFilter = contactFilters.createParent('or')
      contactFilters.add(topLevelFilter, nameFilter)
      contactFilters.add(topLevelFilter, phoneNumberFilter)
      contactFilters.add(topLevelFilter, emailFilter)
    }

    const { data } = await customerClient.tableView({
      pageSize: this.PAGE_SIZE,
      page: this.contactListPage,
      filters: contactFilters.asQueryParams(),
    })

    const { resultList, count } = data
    this.contactsList.push(...resultList)
    this.allContactsLoaded = this.contactsList.length === count
  }

  async searchCompanies(): Promise<void> {
    const companyFilters = filter()
    const nameFilter = {
      column: {
        _t_id: 'de0c532f-90bd-4279-9129-cc1e00bec0a8',
        filterProp: 'name',
        filterType: 'contains',
        filterAsIs: true,
      },
      value: this.searchTerm,
    }

    const phoneFilter = {
      column: {
        _t_id: '2a023ded-5144-4c4c-963d-91a3ec9d4171',
        filterProp: 'phone',
        filterType: 'contains',
        filterAsIs: true,
      },
      value: this.searchTerm,
    }

    const emailFilter = {
      column: {
        _t_id: 'b433d578-1b03-4044-a1d0-c9fd515b72e5',
        filterProp: 'email',
        filterType: 'contains',
        filterAsIs: true,
      },
      value: this.searchTerm,
    }

    const topLevelFilter = companyFilters.createParent('or')
    companyFilters.add(topLevelFilter, nameFilter)
    companyFilters.add(topLevelFilter, phoneFilter)
    companyFilters.add(topLevelFilter, emailFilter)

    const { data } = await customerAccountClient.tableView({
      pageSize: this.PAGE_SIZE,
      page: this.companyListPage,
      filters: companyFilters.asQueryParams(),
    })

    const { resultList, count } = data
    this.companiesList.push(...resultList)
    this.allCompaniesLoaded = count === this.companiesList.length
  }

  selectContact(contact: Customer): void {
    this.showMenu = false
    this.searchTerm = formatFullName(contact)
    if (
      this.title !== 'Trip Contact' &&
      (!contact.email || !contact.lastName)
    ) {
      sidebar.push({
        component: ContactSidebarDetail,
        props: {
          userId: contact.customerId,
          simple: true,
          contactType: this.contactType,
          tripId: this.tripId,
        },
        on: {
          refresh: (updatedContact) => {
            this.$emit('contact-search:contact', updatedContact)
          },
        },
      })
    } else {
      const contactEvent = {
        ...contact,
        customer: contact,
        id: contact.customerId,
      }
      this.$emit('contact-search:contact', contactEvent)
    }
  }

  selectCompany(company: CustomerAccount): void {
    this.showMenu = false
    this.$emit('contact-search:company', company)
  }

  handleCreateClick(): void {
    if (this.hideCompanies) {
      this.openCreateContact(false)
    }
  }

  async handleDispatchContactCreate(id: number): Promise<void> {
    try {
      await reservationClient.updateReservationTripContact(
        reservation.reservation?.reservationId,
        id
      )
    } catch {
      return
    } finally {
      sidebar.popAllAndPush({
        component: ReservationDetailPanel,
        props: {
          reservationId: reservation.reservation?.reservationId,
          managedId: reservation.reservation?.managedId,
        },
        width: 764,
        wide: true,
      })
    }
  }

  openCreateContact(isCompany: boolean): void {
    if (this.isDispatch) {
      sidebar.push({
        component: CreateContactSidebar,
        props: {
          companyOnly: false,
          contactType: 'Trip',
          tripId: reservation.reservation.tripId,
        },
        on: {
          submit: (id) => this.handleDispatchContactCreate(id),
        },
      })
    } else {
      sidebar.push({
        component: CreateContactSidebar,
        props: {
          companyOnly: isCompany,
          contactType: this.contactType,
          tripId: this.tripId,
          prepopulateContact: this.prepopulateCreateContact,
          preventGlobalEmit: this.preventGlobalEmit,
        },
        on: {
          'submit:contact': (updatedContact) => {
            this.$emit('contact-search:contact', updatedContact)
          },
          'contact:created': (updatedContact) => {
            this.$emit('contact-search:contact', updatedContact)
          },
          on: {
            'contact:created': (updatedContact) => {
              this.$emit('contact-search:contact', updatedContact)
            },
          },
        },
      })
    }
  }
}
