var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CUModal',{attrs:{"value":_vm.value,"simple-header":"","justify-title":"left","width":"373px"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Change Password")]},proxy:true},{key:"text",fn:function(){return [_c('v-form',{ref:"changePasswordForm"},[(!_vm.isAdmin)?_c('CUTextField',{attrs:{"id":"my-profile-password-text-current-password","label":_vm.formFields.currentPassword && 'Current Password',"placeholder":"Current Password","rules":[
          (v) => !!v || 'Please enter your current password',
          (v) => _vm.passwordLength(v),
        ],"type":"password","required":!_vm.isAdmin,"outlined":""},model:{value:(_vm.formFields.currentPassword),callback:function ($$v) {_vm.$set(_vm.formFields, "currentPassword", $$v)},expression:"formFields.currentPassword"}}):_vm._e(),_c('CUTextField',{attrs:{"id":"my-profile-password-text-new-password","label":_vm.formFields.newPassword && 'New Password',"placeholder":"New Password","rules":[
          (v) => !!v || 'Please enter a new password',
          (v) => _vm.passwordLength(v),
        ],"type":"password","required":"","outlined":""},model:{value:(_vm.formFields.newPassword),callback:function ($$v) {_vm.$set(_vm.formFields, "newPassword", $$v)},expression:"formFields.newPassword"}}),_c('CUTextField',{attrs:{"id":"my-profile-password-text-confirm-new-password","label":_vm.formFields.confirmNewPassword && 'Confirm New Password',"placeholder":"Confirm New Password","rules":[
          (v) => !!v || 'Please confirm your password',
          (v) => v === _vm.formFields.newPassword || 'New passwords must match',
        ],"type":"password","required":"","outlined":""},model:{value:(_vm.formFields.confirmNewPassword),callback:function ($$v) {_vm.$set(_vm.formFields, "confirmNewPassword", $$v)},expression:"formFields.confirmNewPassword"}})],1)]},proxy:true},{key:"actions",fn:function(){return [_c('div',{staticClass:"d-flex justify-end w-full margin-t-n1 margin-b-2"},[_c('CUButton',{attrs:{"small":"","outlined":"","width":"108px"},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('CUButton',{staticClass:"margin-l-4 margin-r-2",attrs:{"small":"","color":"primary","width":"108px","disabled":_vm.disabled,"loading":_vm.submitting},on:{"click":_vm.submit}},[_vm._v(" Apply ")])],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }