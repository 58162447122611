import { Address } from './Address'
import { Note } from './Note'
import { SpotTime } from './SpotTime'
import { v4 } from 'uuid'
export interface Stop {
  orderIndex: number
  isArrived: boolean
  addressId: number
  isPickupEstimated: boolean
  isDropoffEstimated: boolean
  stopId: number
  tripId: number
  pickupDatetime: string
  dropoffDatetime: string
  startDatetime?: string
  createdOn: string
  updatedOn?: string
  address: Address
  spotTime?: SpotTime
  stopNotes?: Note[]
  notes?: string
  active: boolean
  stopType?: string
  pickupDate: string
  pickupTime: string
  dropoffDate: string
  dropoffTime: string
  id?: string
}

export interface StopErrors {
  address?: boolean
  spotTime?: {
    isBeforePrevStop?: boolean
    isBeforeDropoff?: boolean
  }
  dropoff?: {
    missingTime?: boolean
    missingDate?: boolean
    dateOutOfOrder?: boolean
    timeOutOfOrder?: boolean
  }
  pickup?: {
    missingTime?: boolean
    missingDate?: boolean
    dateOutOfOrder?: boolean
    timeOutOfOrder?: boolean
  }
}

export interface StopResult<Stop> {
  stop: Stop
}

// eslint-disable-next-line no-redeclare
export class Stop implements Stop {
  constructor(stop?: Partial<Stop>) {
    this.active = true
    this.id = v4()
    Object.assign(this, stop)
  }
}
