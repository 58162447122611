export type InvoiceStatus = 'DRAFT' | 'REVIEWED' | 'PENDING' | 'PAID'
export const REVIEWED = 'REVIEWED'
export const DRAFT = 'DRAFT'
export const PENDING = 'PENDING'
export const PAID = 'PAID'

export interface Invoice {
  id: string
  reservationId: string
  start: string
  startTimezone: string
  stop: string
  stopTimezone: string
  status: InvoiceStatus
  totalCharges: number
  driver: User
  bookingContact: User
  company: CompanyInfo
  duplicateIndex: number
}

interface CompanyInfo {
  customerAccountId: string
  name: string
}

interface User {
  userId: number
  name: string
}

export interface InvoicePagedResult {
  content: Invoice[]
  totalElements: number
  totalPages: number
  numberOfElements: number
}
