import { Action, Module, VuexModule } from 'vuex-class-modules'
import store from '@/store/index'
import { Sidebar, SidebarContent, SidebarState } from '@/models/Sidebar'
import Placeholder from '@/views/Placeholder.vue'
import sidebar from './sidebar'

const CLOSED_WIDTH = 0
const DEFAULT_OPEN_WIDTH = 380
const DEFAULT_HEIGHT = 380

@Module({ generateMutationSetters: true })
class MiniSidebarModule extends VuexModule implements Sidebar {
  state = SidebarState.CLOSED
  _components: SidebarContent[] = []

  get components(): SidebarContent[] {
    return this._components
  }

  get content(): SidebarContent {
    return this._components[this._components.length - 1]
  }

  get stackLength(): number {
    return this._components.length
  }

  get width(): number {
    if (this.isOpen) {
      return this.content.width || DEFAULT_OPEN_WIDTH
    }
    return CLOSED_WIDTH
  }

  get height(): number {
    return this.content?.height || DEFAULT_HEIGHT
  }

  get offset(): number {
    return this.content?.offset || sidebar?.width || 0
  }

  get isOpen(): boolean {
    return this._components.length > 0
  }

  get isClosed(): boolean {
    return this._components.length === 0
  }

  @Action
  setHeight(height: number): void {
    this._components[this._components.length - 1].height = height
  }

  @Action
  open(): void {
    this.state = SidebarState.OPEN
  }

  @Action
  toggle(sidebarContent: SidebarContent): void {
    if (this.state === SidebarState.OPEN) {
      this.pop()
    } else if (this.state === SidebarState.CLOSED) {
      this.popAllAndPush(sidebarContent)
    }
  }

  @Action
  close(): void {
    this.state = SidebarState.CLOSED
    this._components = []
  }

  @Action
  push(sidebarContent: SidebarContent): void {
    this.openSidebar(sidebarContent)
  }

  @Action
  popAllAndPush(sidebarContent: SidebarContent): void {
    this._components = []
    this.openSidebar(sidebarContent)
  }

  @Action
  pop(): void {
    this._components.pop()
    if (this._components.length === 0) {
      this.close()
    }
  }

  openSidebar({
    title = '',
    component = Placeholder,
    props = {},
    on = {},
    width = DEFAULT_OPEN_WIDTH,
    height = DEFAULT_HEIGHT,
    persist = false,
    fab = false,
    wide = false,
    offset = sidebar.width,
    state = SidebarState.OPEN,
  }) {
    this.state = state

    this._components.push({
      title,
      component,
      props,
      on,
      width,
      height,
      fab,
      wide,
      offset,
      persist,
    })
  }
}

export default new MiniSidebarModule({ store, name: 'miniSidebar' })
