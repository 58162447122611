
import { VehicleTimeOff } from '@/models/dto'
import vehicle from '@/services/vehicle'
import auth from '@/store/modules/auth'
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
import { openInNewTab } from '@/utils/router'
import { validateRules, validationRules } from '@/utils/rules'
import dayjs from 'dayjs'
import { Vue, Component, Prop } from 'vue-property-decorator'
import HoldUpModal from '@/components/HoldUpModal.vue'

@Component({ components: { HoldUpModal } })
export default class VehicleTimeOffSidebar extends Vue {
  @Prop({ required: true }) vehicleId: number
  @Prop({ required: false, default: null }) vehicleTimeOff: VehicleTimeOff

  rules = validationRules
  startDate = ''
  startTime = ''
  endDate = ''
  endTime = ''
  allDay = false
  notes = ''
  submitting = false
  showConfirmModal = false
  confirmModalData: { resId: number; managedId: string }[] = []

  get isModeEdit(): boolean {
    return !!this.vehicleTimeOff
  }

  get title(): string {
    return this.isModeEdit ? 'Edit Vehicle Time Off' : 'Add Vehicle Time Off'
  }

  get areDatesInOrder(): boolean {
    if (
      !(this.startDate && this.endDate) ||
      !(this.allDay || (this.startTime && this.endTime))
    ) {
      return true
    }
    return dayjs(`${this.startDate}T${this.startTime || '00:00'}:00`).isBefore(
      `${this.endDate}T${this.endTime || '23:59'}:59`
    )
  }

  openReservation(resId: number): void {
    openInNewTab(`/reservations/detail/${resId}`)
  }

  getResString(id: string, idx: number): string {
    return `${id}${
      this.confirmModalData.length > 1 && idx < this.confirmModalData.length - 2
        ? '<span class="font-regular text-black">, </span>'
        : ''
    }${
      this.confirmModalData.length > 1 &&
      idx === this.confirmModalData.length - 2
        ? ' <span class="font-regular text-black">and </span>'
        : ''
    }`
  }

  async submit(override = false): Promise<void> {
    if (!(await validateRules(this)) || !this.areDatesInOrder) {
      return
    }
    this.submitting = true

    const tz =
      auth.getUser?.timeZone || auth.getCompanyTimeZone || 'America/New_York'
    const start = dayjs
      .tz(
        `${this.startDate}T${this.startTime || '00:00'}:00`,
        auth.getUserTimeZone
      )
      .tz(tz)
      .format('YYYY-MM-DDTHH:mm:ss')
    const end = dayjs
      .tz(`${this.endDate}T${this.endTime || '23:59'}:59`, auth.getUserTimeZone)
      .tz(tz)
      .format('YYYY-MM-DDTHH:mm:ss')
    const vehicleTimeOff = {
      id: this.vehicleTimeOff?.id,
      vehicleId: this.vehicleId,
      indefinite: false,
      start,
      end,
      notes: this.notes,
    }

    if (this.allDay) {
      vehicleTimeOff.start = dayjs
        .tz(this.startDate, auth.getUserTimeZone)
        .startOf('day')
        .tz(tz)
        .format('YYYY-MM-DDTHH:mm:ss')
      vehicleTimeOff.end = dayjs
        .tz(this.endDate, auth.getUserTimeZone)
        .endOf('day')
        .tz(tz)
        .format('YYYY-MM-DDTHH:mm:ss')
    }

    try {
      if (this.isModeEdit) {
        await vehicle.editTimeOff(this.vehicleId, vehicleTimeOff, override)
      } else {
        await vehicle.addTimeOff(this.vehicleId, vehicleTimeOff, override)
      }
      EventBus.$emit('snackbar:success', 'Successfully saved vehicle time off.')
    } catch (err: any) {
      if (err?.response?.status === 409) {
        this.confirmModalData = err.response.data.map((e) => ({
          resId: e.reservationId,
          managedId: e.managedId,
        }))
        this.showConfirmModal = true
        this.submitting = false
        return
      } else {
        EventBus.$emit('snackbar:error', 'Error saving vehicle time off.')
      }
    }

    EventBus.$emit('time-off:update')
    sidebar.pop()
  }

  handleConfirm(): void {
    this.showConfirmModal = false
    this.submit(true)
  }

  cancel(): void {
    sidebar.pop()
  }

  mounted(): void {
    if (this.vehicleTimeOff) {
      this.startDate = dayjs(this.vehicleTimeOff.start).format('YYYY-MM-DD')
      this.endDate = dayjs(this.vehicleTimeOff.end).format('YYYY-MM-DD')
      this.startTime = dayjs(this.vehicleTimeOff.start).format('HH:mm')
      this.endTime = dayjs(this.vehicleTimeOff.end).format('HH:mm')
      this.notes = this.vehicleTimeOff.notes
      this.allDay =
        this.startTime ===
          dayjs(this.vehicleTimeOff.start).startOf('day').format('HH:mm') &&
        this.endTime ===
          dayjs(this.vehicleTimeOff.end).endOf('day').format('HH:mm')
    }
  }
}
