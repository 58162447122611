
import {
  ReservationDetail,
  AssignedVehicle,
  Assignments,
  Vehicle,
  Trip,
  AssignedDriver,
} from '@/models/dto'
import { formatFullName } from '@/utils/string'
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUExpansionPanel from '@/components/CUExpansionPanel.vue'
import sidebar from '@/store/modules/sidebar'
import auth from '@/store/modules/auth'
import deepClone from '@/utils/deepClone'
import tripClient from '@/services/trip'
import { EventBus } from '@/utils/eventBus'
import { AxiosResponse } from 'axios'
import reservation from '@/store/modules/reservation'
import { SourceCategory } from '@/utils/enum'
import HoldUpModal from '@/components/HoldUpModal.vue'
import DriverAssignmentTag from './DriverAssignmentTag.vue'
import { getFilteredAssignments } from '@/utils/assignment'
import AssignmentsSidebar from '@/components/AssignmentsSidebar.vue'
import AssignmentsAvatar from '@/components/AssignmentsAvatar.vue'
import AssignmentsSidebarHeader from '@/components/AssignmentsSidebarHeader.vue'
import assignment from '@/store/modules/assignment'

@Component({
  components: {
    DriverAssignmentTag,
    CUExpansionPanel,
    HoldUpModal,
    AssignmentsAvatar,
    AssignmentsSidebarHeader,
  },
})
export default class ReservationAssignments extends Vue {
  @Prop({ required: true }) reservation!: ReservationDetail
  @Prop({ type: Boolean, default: false }) expandable: boolean

  formatFullName = formatFullName
  state = reservation
  assignment = assignment

  isReferral: boolean =
    this.reservation.sourceCategory === SourceCategory.REFERRAL
  isModalOpen = false

  get loading(): boolean {
    return assignment.isRefreshing || assignment.isLoading
  }

  get isReservationCancelled(): boolean {
    return this.reservation.reservationStatus === 'cancelled'
  }

  get isReservationDeleted(): boolean {
    return !this.reservation.active
  }

  get requiredVehicles(): AssignedVehicle[] {
    return this.reservation.requiredVehicles.reduce((arr, vehicle) => {
      if (vehicle.quantity === 1) {
        arr.push({
          ...vehicle,
          type: vehicle.vehicleType.label,
          vehicleTypeId: vehicle.vehicleType.id,
        })
      } else {
        arr.push(
          ...[...Array(vehicle.quantity).keys()].map((key) => {
            return {
              ...vehicle,
              type: `${vehicle.vehicleType.label} ${key + 1}`,
              vehicleTypeId: vehicle.vehicleType.id,
            }
          })
        )
      }
      return arr
    }, [])
  }

  get enableDriverAssignmentAcceptance(): boolean {
    return auth.getCompany?.enableDriverAssignmentAcceptance
  }

  get requiredDrivers(): number {
    return this.reservation.requiredDrivers < this.requiredVehicles.length
      ? this.requiredVehicles.length
      : this.reservation.requiredDrivers
  }

  get assigned(): Assignments {
    const assignedDrivers = this.state.assignments.reduce((sum, assignment) => {
      sum +=
        assignment.driverAssignments.filter((d) => d.isConfirmed).length || 0
      return sum
    }, 0)
    const assignedVehicles = this.state.assignments.filter((a) => a.isConfirmed)
    return {
      drivers: `${assignedDrivers}/${this.requiredDrivers}`,
      vehicles: `${assignedVehicles.length}/${this.requiredVehicles.length}`,
      areDriversFull: assignedDrivers === this.requiredDrivers,
      areVehiclesFull: assignedVehicles.length === this.requiredVehicles.length,
    }
  }

  get filteredAssignments(): AssignedVehicle[] {
    return getFilteredAssignments(
      this.state.reservation,
      this.state.assignments,
      assignment.getDriverAssignmentStatuses
    ).assignments
  }

  get isVehicleConflicted(): (id: number) => boolean {
    return (id: number) =>
      assignment.unavailableVehicles?.find(
        (vehicle) =>
          vehicle.vehicleId === id &&
          vehicle.reservationId !== this.reservation.reservationId
      ) && id !== null
  }

  get isDriverConflicted(): (id: number) => boolean {
    return (id: number) =>
      assignment.unavailableDrivers?.find(
        (driver) =>
          driver.driverId === id &&
          driver.reservationId !== this.reservation.reservationId
      ) && id !== null
  }

  getStatusForDriver(driver: AssignedDriver): string {
    if (!!driver.id && !driver.isConfirmed) {
      return 'Draft'
    }
    const status = assignment.getDriverAssignmentStatuses.find(
      (s) => s.userId === driver.id
    )
    return status?.driverAssignmentStatusType?.label
  }

  getTextColorForDriver(driver: AssignedDriver): string {
    const status = this.getStatusForDriver(driver)

    if (driver.name === '--' || !driver.name) {
      return 'text-text-gray-1'
    }

    if (this.isReservationCancelled || this.isReservationDeleted) {
      return 'text-text-gray-1'
    }

    if (!this.enableDriverAssignmentAcceptance) {
      return 'text-secondary'
    }
    switch (status) {
      case 'Draft':
      case 'Pending':
        return 'text-text-gray-2'
      case 'Rejected':
        return 'text-error'
      default:
        return 'text-secondary'
    }
  }

  getTextColorForVehicle(vehicle: AssignedVehicle): string {
    if (
      this.isReservationCancelled ||
      this.isReservationDeleted ||
      vehicle.name === '--' ||
      !vehicle.name ||
      (!!vehicle.id && !vehicle.isConfirmed)
    ) {
      return 'text-text-gray-2'
    }

    return 'text-secondary'
  }

  updateTrip(newAssignments: AssignedVehicle[]): Promise<AxiosResponse<Trip>> {
    // update required vehicles (trip.vehicles)
    const tripVehicles = newAssignments.map((newAssignment) => {
      const types = deepClone(assignment.getVehicleTypes)
      let type = types.find((type) => {
        return type.id === newAssignment.vehicleTypeId
      })
      if (!type) {
        type = types.find((type) => {
          return type.id === 1
        })
      }
      const vehicle = new Vehicle()
      Object.assign(vehicle, {
        quantity: 1,
        vehicleType: {
          id: type.id,
          key: type.key,
        },
        vehicleTypeId: type.id,
        vehicleTypeKey: type.key,
      })
      return vehicle
    })

    // update required drivers (trip.requiredDrivers)
    const requiredDriversCount = newAssignments.reduce((sum, vehicle) => {
      sum += vehicle.drivers?.length || 1
      return sum
    }, 0)

    const payload = {
      vehicles: tripVehicles,
      requiredDrivers: requiredDriversCount,
    }

    return tripClient.editTripDriversAndVehicles(
      this.reservation.tripId,
      payload
    )
  }

  async handleEditAssignments(): Promise<void> {
    assignment.setIsNonDefaultView(true)
    await assignment.setSelectedIds({
      reservationId: this.reservation.reservationId,
      vehicleId: null,
      vehicleIdx: 0,
    })

    sidebar.push({
      component: AssignmentsSidebar,
      persist: true,
      width: 512,
    })
  }

  snackbarSyncSuccess(message: string): void {
    EventBus.$emit('snackbar:success', message)
  }
}
