
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import {
  AccessSettings,
  DEFAULT_ACCESS_SETTINGS,
} from '@/models/AccessSettings'
import { Role } from '@/models/dto/User'
import { EventBus } from '@/utils/eventBus'
import auth from '@/store/modules/auth'

@Component
export default class UserAccessSettings extends Vue {
  @Prop({ required: false, default: () => [] }) roles!: Role[]
  @Prop({ required: false, default: '' }) userType!: string
  @Prop({ required: true }) creatingUser!: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean

  accessSettings = DEFAULT_ACCESS_SETTINGS
  accessSettingsLabels = []

  get accessSettingItems() {
    const { reservationSummary, reservations, quotes, ...accessSettings } =
      this.accessSettings
    // Nest the reservation summary under accessSettings.reservation
    return {
      quotes,
      reservations: {
        ...reservations,
        subsettings: { reservationSummary },
      },
      ...accessSettings,
    }
  }

  @Watch('userType')
  onUserTypeChange(_: string, oldUserType: string): void {
    if ((oldUserType == null || oldUserType === '') && !this.creatingUser) {
      return
    }

    if (oldUserType) {
      this.accessSettings = DEFAULT_ACCESS_SETTINGS
    }
    if (this.userType.includes('User')) {
      this.accessSettings = {
        ...this.accessSettings,
        companySettings: {
          ...this.accessSettings.companySettings,
          enabled: false,
        },
        reservationSummary: {
          ...this.accessSettings.reservationSummary,
          enabled: false,
        },
      }
    }

    this.updateAccessSettings(this.accessSettings)
  }

  handleAccessSettingsToggleChange(key, value): void {
    this.updateAccessSettings({
      ...this.accessSettings,
      [key]: {
        ...this.accessSettings[key],
        enabled: value,
      },
    })
  }

  updateAccessSettings(accessSettings: AccessSettings): void {
    this.accessSettings = accessSettings

    const activeAccessSettings = Object.keys(accessSettings).filter(
      (key) => accessSettings[key as keyof AccessSettings].enabled
    ) as string[]
    const accessSettingsRoleNames = activeAccessSettings.map(
      (key) => accessSettings[key as keyof AccessSettings].roleName
    )

    EventBus.$emit('user-access-settings:updated', accessSettingsRoleNames)
  }

  mounted(): void {
    if (this.roles?.length > 0) {
      // existing user
      this.accessSettings = Object.keys(this.accessSettings).reduce(
        (acc, key) => {
          const role = this.roles.find(
            (role) => role.roleName === this.accessSettings[key].roleName
          )

          return {
            ...acc,
            [key]: {
              ...this.accessSettings[key],
              enabled: !!role,
            },
          }
        },
        {} as AccessSettings
      )
    }

    this.updateAccessSettings(this.accessSettings)
  }
}
