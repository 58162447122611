
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { selectedDateStyle, dragStyle, todayStyle } from '@/data/datePicker'
import colors from '@/scss/_colors-export.scss'
import dayjs from 'dayjs'

@Component({})
export default class CUDatePickerCalendar extends Vue {
  @Prop({ required: false, type: Boolean }) isRange!: boolean
  @Prop({ required: false, default: 'secondary' }) color!: string
  @Prop({ required: false, default: '' }) value!: string | string[]
  @Prop({ required: false, default: undefined }) defaultDisplayedDate!: string
  @Prop({ required: false, default: undefined }) predefinedValue!: string
  attributes = []
  isDragging = false
  hasInput = false

  @Watch('color', { immediate: true })
  onColorUpdate(color: string): void {
    if (!color) {
      this.attributes = []
    }
    this.attributes = [todayStyle(color)]
  }

  /**
   * When we update the value, focus the calendar month on the new value.
   * If the value/oldValue are strings, then we're working with the single date picker, and we
   * should focus on that month.
   * If they're arrays, then we're working with a range picker.
   * If we're working with a predefined date of In the Future or In the Past, then
   * focus on the first date or last date in the array, since that'll be the current present day.
   * Otherwise, grab the newest date in the value array (since that's the one the user has just changed)
   * and focus the calendar there
   */
  @Watch('value', { immediate: true })
  onValueUpdate(
    newValue: string | string[],
    oldValue: string | string[]
  ): void {
    if (!newValue?.length) {
      this.attributes = [todayStyle(this.primaryColor)]
      return
    }
    this.attributes = [todayStyle(this.primaryColor)]
    this.$nextTick(() => {
      const cal: any = this.$refs.calendar
      if (!cal) {
        return
      }
      if (!Array.isArray(newValue)) {
        cal.move(newValue)
        return
      } else if (this.predefinedValue === 'future') {
        cal.move(newValue[0])
        return
      } else if (this.predefinedValue === 'past') {
        cal.move(newValue[1])
        return
      }

      const changedValue = (newValue as string[]).filter(
        (d) => !oldValue?.includes(d)
      )[0]
      if (changedValue) {
        const jsDate = dayjs(changedValue).toDate()
        cal.move(jsDate)
      }
    })
  }

  @Watch('defaultDisplayedDate', { immediate: true })
  onDefaultDisplayedDateChange(date: string): void {
    if (!date) {
      return
    } else {
      this.$nextTick(() => {
        const cal: any = this.$refs.calendar
        if (cal && !this.value) {
          cal.move(this.defaultDisplayedDate)
        }
      })
    }
  }

  get calendarValue(): Date | any {
    if (this.isRange && Array.isArray(this.value)) {
      const arr = ((this.value as string[]) || [])
        .filter((d) => !!d)
        .map((d) => dayjs(d).toDate())

      return {
        start: arr?.[0],
        end: arr?.[1],
      }
    }

    return dayjs(this.value as string).toDate()
  }

  get columns(): number {
    return this.isRange ? 2 : 1
  }

  get primaryColor(): string {
    if (colors[this.color]) {
      return colors[this.color]
    }
    return this.color
  }

  get lightColor(): string {
    const lightColor = `${this.color}-light`
    if (colors[lightColor]) {
      return colors[lightColor]
    }

    return `${this.color}1A`
  }

  get style(): Record<string, string> {
    return {
      '--vc-date-picker-selected-color': this.primaryColor,
      '--vc-date-picker-light-color': this.lightColor,
    }
  }

  get selectedDateStyle(): any {
    return selectedDateStyle(this.primaryColor)
  }

  get dragStyle(): any {
    return dragStyle(this.primaryColor)
  }

  handleInput(e: Date | { start: Date; end: Date }): void {
    if (!e) {
      return
    }
    if (this.isRange) {
      const { start, end } = e as { start: Date; end: Date }
      const startDate = dayjs(start).format('YYYY-MM-DD')
      const endDate = dayjs(end).format('YYYY-MM-DD')
      this.$emit('input', [startDate, endDate])
    } else {
      this.$emit('input', dayjs(e as Date).format('YYYY-MM-DD'))
    }

    this.hasInput = true
  }

  handleDayClick(): void {
    this.attributes = [todayStyle(this.primaryColor)]
    this.$emit('dayclick')

    const cal: any = this.$refs['calendar']

    // Workaround for selecting the same dates as before--if selecting
    // the same range as before, we're getting a dayclick event but
    // not an input event. If we're dragging a date but haven't received
    // an input event, then abandon the current drag
    if (this.isDragging && !this.hasInput) {
      cal.dragValue = null
      this.isDragging = false
    } else if (this.hasInput) {
      this.hasInput = false
      this.isDragging = false
    } else {
      this.isDragging = true
    }
  }
}
