
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Role, SupportedVehicleType } from '@/models/dto'
import {
  countryPhoneFormatFilter,
  formatFullName,
  formattedPhoneToNumber,
} from '@/utils/string'
import sidebar from '@/store/modules/sidebar'
import { UserDetailDriver } from '@/models/dto/UserDetailDriver'
import DriverInfoDetailsTab from '@/components/DriverInfoDetailsTab.vue'
import DriverInfoCertificationsTab from '@/components/DriverInfoCertificationsTab.vue'
import DriverInfoDriverPayTab from '@/components/DriverInfoDriverPayTab.vue'
import { validateRules, validationRules } from '@/utils/rules'
import CreateUserTypeSelector from '@/components/CreateUserTypeSelector.vue'
import typeClient from '@/services/type'
import userClient from '@/services/user'
import driverClient from '@/services/driver'
import { UploadedFile } from '@/models/dto/UploadedFile'
import { EventBus } from '@/utils/eventBus'
import { Tab } from '@/models/dto/Tab'
import HoldUpModal from '@/components/HoldUpModal.vue'
import { Action } from '@/models/dto/Action'
import ChangePassword from '@/components/ChangePassword.vue'
import auth, { checkIsDriverOnly } from '@/store/modules/auth'
import { UserGroupIds } from '@/data/userGroups'
import AssignmentConflictsMessages from '@/components/AssignmentConflictsMessages.vue'

@Component({
  components: {
    DriverInfoDetailsTab,
    CreateUserTypeSelector,
    HoldUpModal,
    ChangePassword,
    AssignmentConflictsMessages,
  },
})
export default class EditUserSidebar extends Vue {
  @Prop({ required: true, default: null }) user: UserDetailDriver
  @Prop({ required: false, default: null }) userId: number
  @Prop({ type: Boolean, default: false }) driverOnly: boolean
  @Prop({ type: Boolean, default: false }) showConflicts: boolean

  formatFullName = formatFullName

  rules = validationRules
  newUser = new UserDetailDriver()
  newDriver = new UserDetailDriver()
  initialDriver = false
  needsOfficeUserUpgrade = false
  userTypeChanged = false
  loading = true
  selectedUserType = ''
  isHoldUpModalOpen = false
  showChangePasswordModal = false
  oldType = null
  tabIndex = 0
  roles: Role[] = null
  accessSettingsRoleNames: string[] = []
  vehicleTypes = []
  ignoreAccessSettingsUpdates = false
  errors = {
    email: null,
  }
  driverDocumentsToAdd: File[] = []
  driverDocumentIdsToDelete: number[] = []

  @Watch('user', { immediate: true })
  handleChangeUser(): void {
    this.load()
  }

  @Watch('newUser', { deep: true })
  handleChangeNewUser(): void {
    this.resetErrors()
  }

  get isAdmin(): boolean {
    return auth.getUser?.groupId === UserGroupIds.ADMIN
  }

  get tabs(): Tab[] {
    let label =
      this.newUser.treatAsDriver &&
      !this.isHoldUpModalOpen &&
      (this.newUser.groupId === UserGroupIds.ADMIN ||
        this.newUser.groupId === UserGroupIds.USER)
        ? this.newUser.groupName.replace(' + Driver', '')
        : null

    if (this.needsOfficeUserUpgrade) {
      label = 'Office User'
    }

    const tabs = []
    if (label) {
      tabs.push({
        key: 'user-sidebar-group-tab',
        label,
        hash: 'group',
        component: CreateUserTypeSelector,
        props: {
          hideHoverText: true,
          selectedUserType: this.needsOfficeUserUpgrade
            ? 'Office User + Driver'
            : this.newUser?.groupName,
          width: '400px',
          roles: this.roles,
        },
        on: {
          handleUserTypeChange: ($event) => this.handleUserTypeChange($event),
        },
      })
    }

    if (label || checkIsDriverOnly(this.roles) || this.driverOnly) {
      tabs.push(
        {
          key: 'user-sidebar-driver-tab',
          hash: 'details',
          label: label ? 'Driver' : 'Details',
          component: DriverInfoDetailsTab,
          props: {
            driver: this.newUser,
          },
          on: {
            update: ($event) => this.handleUpdateDriver($event),
          },
        },
        {
          key: 'user-sidebar-driver-certifications-tab',
          label: 'Certifications',
          hash: 'certifications',
          component: DriverInfoCertificationsTab,
          props: {
            driver: this.newUser,
          },
          on: {
            update: ($event) => this.handleUpdateDriver($event),
            'documents:add': ($event) => this.handleAddDriverDocuments($event),
            'documents:delete': ($event) =>
              this.handleDeleteDriverDocuments($event),
          },
        }
      )
    }

    if (label || checkIsDriverOnly(this.roles) || this.driverOnly) {
      tabs.push({
        key: 'user-sidebar-driver-tab',
        label: 'Driver Pay',
        hash: 'driver-pay',
        ref: 'driverPayTab',
        component: DriverInfoDriverPayTab,
        props: {
          vehicleTypes: this.vehicleTypes,
          driverIds: [this.user?.userId],
        },
        on: {
          input: ($event) => this.handleUpdateDriver($event),
        },
      })
    }

    return tabs
  }

  get actions(): Action[] {
    const actions = this.isAdmin
      ? [
          {
            label: 'Change Password',
            event: 'driver:change-password',
            icon: 'edit',
            iconLabel: 'Edit',
            id: 'change-driver-password',
          },
          {
            label: 'Remove from Account',
            event: 'driver:delete',
            icon: 'delete',
            iconLabel: 'Delete',
            id: 'delete-driver',
          },
        ]
      : []
    if (this.newUser.treatAsDriver && this.newDriver.driverActivated) {
      actions.splice(1, 0, {
        label: 'Deactivate Driver',
        event: 'driver:deactivate',
        icon: 'cached',
        iconLabel: 'Deactivate',
        id: 'deactivate-driver',
      })
    } else if (this.newUser.treatAsDriver) {
      actions.splice(1, 0, {
        label: 'Activate Driver',
        event: 'driver:activate',
        icon: 'cached',
        iconLabel: 'Activate',
        id: 'activate-driver',
      })
    }
    if (
      checkIsDriverOnly(this.roles) &&
      this.driverOnly &&
      (this.isUserAdmin || this.needsOfficeUserUpgrade)
    ) {
      actions.splice(2, 0, {
        label: 'Upgrade to Office User',
        event: 'driver:upgrade',
        icon: 'upgrade',
        iconLabel: 'Upgrade',
        id: 'upgrade-driver',
      })
    }
    return actions
  }

  get isUserAdmin(): boolean {
    return auth.getUser.groupId === UserGroupIds.ADMIN
  }

  async handleDriverDeactivate(): Promise<void> {
    try {
      await driverClient.deactivateDriver(this.newDriver.userId)
    } catch (err) {
      EventBus.$emit('snackbar:error', err)
    } finally {
      EventBus.$emit('refresh-tableview')
      sidebar.close()
    }
  }

  async handleDriverActivate(): Promise<void> {
    try {
      await driverClient.activateDriver(this.newDriver.userId)
    } catch (err) {
      EventBus.$emit('snackbar:error', err)
    } finally {
      EventBus.$emit('refresh-tableview')
      sidebar.close()
    }
  }

  handleDriverUpgrade(): void {
    this.needsOfficeUserUpgrade = true
    this.newUser.groupName = 'Office User + Driver' // the default group for an upgraded user
  }

  async handleDriverDelete(): Promise<void> {
    try {
      await userClient.delete(this.newDriver.userId)
    } catch (err) {
      EventBus.$emit('snackbar:error', err)
    } finally {
      EventBus.$emit('refresh-tableview')
      sidebar.close()
    }
  }

  handleTabChange(index: number[]): void {
    this.tabIndex = index[0]
  }

  handleHoldUpModalInput(input: boolean): void {
    if (!input && this.oldType && !this.needsOfficeUserUpgrade) {
      this.confirmUserTypeChange(this.oldType)
    }
  }

  handleUserTypeChange(type: string): void {
    if (this.needsOfficeUserUpgrade && type === 'Driver') {
      this.needsOfficeUserUpgrade = false
    }

    this.oldType = this.newUser.groupName
    this.userTypeChanged = true
    this.newUser.groupName = type
  }

  handleCancel(oldType: string): void {
    if (!this.needsOfficeUserUpgrade) {
      this.confirmUserTypeChange(oldType)
    }
  }

  async confirmUserTypeChange(type: string): Promise<void> {
    this.loading = true
    this.ignoreAccessSettingsUpdates = true
    this.oldType = null
    this.newUser.groupName = type
    const tempUser = { ...this.newUser }
    switch (type) {
      case 'Admin':
        tempUser.groupId = UserGroupIds.ADMIN
        tempUser.treatAsDriver = false
        break
      case 'Office User':
        tempUser.groupId = UserGroupIds.USER
        tempUser.treatAsDriver = false
        break
      case 'Driver':
        tempUser.groupId = UserGroupIds.DRIVER
        tempUser.treatAsDriver = true
        break
      case 'Admin + Driver':
        tempUser.groupId = UserGroupIds.ADMIN
        tempUser.treatAsDriver = true
        break
      case 'Office User + Driver':
        tempUser.groupId = UserGroupIds.USER
        tempUser.treatAsDriver = true
        break
    }
    this.newUser = tempUser
    try {
      if (
        this.newUser.treatAsDriver &&
        Object.keys(this.newDriver).length !== 0
      ) {
        this.newDriver = { ...this.newDriver }
      } else if (this.newUser.treatAsDriver) {
        const vehicles = await this.getDriverSupportedVehicles()
        this.newDriver = new UserDetailDriver({
          driverSupportedVehicles: vehicles,
        })
        this.newUser.driverSupportedVehicles = vehicles
      }

      await this.submit()
      EventBus.$emit('refresh-tableview')
    } catch (err) {
      EventBus.$emit('snackbar:error', err)
    } finally {
      this.loading = false
    }
  }

  handleUpdateDriver(driver: Partial<UserDetailDriver>): void {
    this.newUser = { ...this.newUser, ...driver }
    this.newDriver = { ...this.newDriver, ...driver }
  }

  handleAddDriverDocuments(docs: File[]): void {
    this.driverDocumentsToAdd = docs
  }

  handleDeleteDriverDocuments(docIds: number[]): void {
    this.driverDocumentIdsToDelete = docIds
  }

  cancel(): void {
    sidebar.pop()
  }

  handleSubmit(): void {
    // TODO: This is a very non-ideal way to submit driver rates. Let's fix this down the road
    EventBus.$emit('edit-user:submit')
    if (this.needsOfficeUserUpgrade || this.userTypeChanged) {
      this.isHoldUpModalOpen = true // on confirm, will call submit
    } else {
      this.submit()
    }
  }

  async submit(): Promise<void> {
    if (!(await validateRules(this))) {
      return
    }
    this.loading = true

    const allDocuments = []

    if (this.newUser.treatAsDriver) {
      try {
        const driverDocuments = await this.driverDocumentUploadSubmit()
        allDocuments.push(
          ...(this.newDriver?.driverDocuments || []).filter(
            (document) => !this.driverDocumentIdsToDelete.includes(document.id)
          ),
          ...driverDocuments
        )
      } catch (err) {
        EventBus.$emit('edit-user:error', err)
      }

      const driver = new UserDetailDriver({
        ...this.newDriver,
        firstName: this.newUser.firstName,
        lastName: this.newUser.lastName,
        email: this.newUser.email,
        phone: formattedPhoneToNumber(this.newUser.phone),
        locale: 'en_US',
        groupId: this.needsOfficeUserUpgrade
          ? UserGroupIds.USER
          : this.newUser.groupId,
        userRoleNames: this.needsOfficeUserUpgrade
          ? this.getRoles(UserGroupIds.USER)
          : this.getRoles(this.newUser.groupId),
        driverDocuments: allDocuments,
      })

      try {
        await driverClient.makeDriver(this.newUser.userId)
        await driverClient.update(this.user.userId, driver)
        EventBus.$emit('edit-user:refresh', this.user.userId)
        EventBus.$emit('refresh-tableview')
        sidebar.close()
      } catch (err) {
        this.handleDriverClientError(err)
      } finally {
        this.loading = false
      }
    } else {
      try {
        await driverClient.deleteDriver(this.user.userId)
        await userClient.update(this.user.userId, {
          ...this.newUser,
          phone: formattedPhoneToNumber(this.newUser.phone),
          userRoleNames: this.getRoles(this.newUser.groupId),
        })
        EventBus.$emit('edit-user:refresh', this.user.userId)
      } catch (err) {
        EventBus.$emit('edit-user:error', err)
      }

      sidebar.close()
      EventBus.$emit('refresh-tableview')
      this.loading = false
    }
  }

  handleDriverClientError(e: any): void {
    const message = e.response.data.message
    if (message.includes('email')) {
      this.errors.email = 'A driver with this email already exists'
      return
    }
    EventBus.$emit('vehicles:error')
  }

  getRoles(groupId: number): string[] {
    const groups = this.newUser.treatAsDriver ? ['is_driver'] : []
    const admin = ['is_free_admin', 'is_paid_admin']
    const user = ['is_free_user', 'is_paid_user']

    if (
      this.newUser.company?.isAdminCompany ||
      this.newDriver.company?.isAdminCompany
    ) {
      admin.push('is_admin_admin')
      user.push('is_admin_user')
    }
    switch (groupId) {
      case UserGroupIds.ADMIN:
        return [...groups, ...admin, ...user, ...this.accessSettingsRoleNames]
      case UserGroupIds.USER:
        return [...groups, ...user, ...this.accessSettingsRoleNames]
      default:
        return [...groups, ...this.accessSettingsRoleNames]
    }
  }

  async getDriverSupportedVehicles(
    supportedVehicles: SupportedVehicleType[] = []
  ): Promise<SupportedVehicleType[]> {
    const res = await typeClient.vehicleTypeTableView({ pageSize: -1 })
    this.vehicleTypes = res.data.resultList

    if (!supportedVehicles?.length) {
      return this.vehicleTypes.map((v) => {
        return { ...v, vehicleTypeId: v.id, supported: false }
      })
    }

    return supportedVehicles.map((vehicle) => {
      return {
        ...vehicle,
        label: this.vehicleTypes.find((v) => v.id === vehicle.vehicleTypeId)
          ?.label,
      }
    })
  }

  async loadDriver(): Promise<boolean> {
    const res = await driverClient.byId(this.user.userId)
    this.newDriver = await res.data.driver
    this.newDriver.driverSupportedVehicles =
      await this.getDriverSupportedVehicles(
        this.newDriver.driverSupportedVehicles
      )
    if (this.newDriver.groupId === UserGroupIds.ADMIN) {
      this.newUser = {
        ...this.newDriver,
        treatAsDriver: true,
        groupName: 'Admin + Driver',
      }
    } else if (this.newDriver.groupId === UserGroupIds.USER) {
      this.newUser = {
        ...this.newDriver,
        treatAsDriver: true,
        groupName: 'Office User + Driver',
      }
    } else {
      this.newUser = {
        ...this.newDriver,
        treatAsDriver: true,
        groupName: 'Driver',
      }
    }
    return true
  }

  getUserGroupId(type: string): number {
    switch (type) {
      case 'Admin':
        return UserGroupIds.ADMIN
      case 'Office User':
        return UserGroupIds.USER
      case 'Driver':
        return UserGroupIds.DRIVER
      case 'Admin + Driver':
        return UserGroupIds.ADMIN
      case 'Office User + Driver':
        return UserGroupIds.USER
    }
  }

  resetErrors(): void {
    this.errors = {
      email: null,
    }
  }

  async load(): Promise<void> {
    if (!this.user) {
      return
    }
    const roles = await userClient.roles(this.user.userId)
    this.roles = roles.data.roles
    if (
      this.roles.some((role) => role.roleName === 'is_driver') ||
      this.driverOnly
    ) {
      this.initialDriver = await this.loadDriver()
    } else {
      try {
        const res = await driverClient.byId(this.user.userId)
        this.newDriver = res.data.driver
        this.newDriver.driverSupportedVehicles =
          await this.getDriverSupportedVehicles(
            this.newDriver.driverSupportedVehicles
          )
      } catch {
        // User does not have an associated driver, pass
      }
      this.newUser = {
        ...this.user,
        groupId: this.user.groupId || this.getUserGroupId(this.user.groupName),
      }
    }
    this.newUser.phone = countryPhoneFormatFilter(
      this.newUser.phone,
      this.newUser.phoneCountryKey
    )
    this.loading = false
  }

  updateAccessSettings(accessSettingsRoleNames: string[]): void {
    if (!this.isHoldUpModalOpen && !this.ignoreAccessSettingsUpdates) {
      this.accessSettingsRoleNames = accessSettingsRoleNames
    }
  }

  async driverDocumentUploadSubmit(): Promise<UploadedFile[]> {
    if (!this.driverDocumentsToAdd?.length) {
      return []
    }
    try {
      const payload = new FormData()
      for (const file of this.driverDocumentsToAdd) {
        payload.append(`files`, file)
      }

      const response = await driverClient.uploadDriverDocuments(payload)
      return response.data?.driverDocuments
    } catch (error) {
      console.log(error)
    }
    return []
  }

  async getDriverDetail(): Promise<void> {
    try {
      if (this.user === null && !!this.userId) {
        const res = await driverClient.byId(this.userId)
        this.user = res.data.driver
      }
    } catch (error) {
      console.log(error)
    }
  }

  created(): void {
    this.getDriverDetail()
  }

  mounted(): void {
    EventBus.$on('user-access-settings:updated', this.updateAccessSettings)
  }

  beforeDestroy(): void {
    EventBus.$off('user-access-settings:updated', this.updateAccessSettings)
  }
}
