import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { ResponseEntity } from '@/services/common/ResponseEntity'
import {
  CharterUpApiCredential,
  CharterUpApiIntegration,
  CharterUpMarketRateRequest,
  CharterUpRate,
  CharterUpSyncRequest,
  CharterUpSyncResult,
  CharterUpVehicle,
} from '@/models/dto/CharterUp'

const httpService: HttpService = new HttpService()

export default {
  getCharterUpIntegration(): Promise<
    AxiosResponse<ResponseEntity<CharterUpApiIntegration>>
  > {
    const url = `https://${apiBaseUrl()}/integrations/charterup`
    return httpService.get(url)
  },
  addCharterUpIntegration(
    credential: CharterUpApiCredential
  ): Promise<AxiosResponse<ResponseEntity<void>>> {
    const url = `https://${apiBaseUrl()}/integrations/charterup`
    return httpService.post(url, credential)
  },
  deleteCharterUpIntegration(): Promise<AxiosResponse<ResponseEntity<void>>> {
    const url = `https://${apiBaseUrl()}/integrations/charterup`
    return httpService.delete(url)
  },
  sync(
    request: CharterUpSyncRequest
  ): Promise<AxiosResponse<ResponseEntity<CharterUpSyncResult>>> {
    const url = `https://${apiBaseUrl()}/charterup/sync`
    return httpService.post(url, request)
  },
  syncReferrals(): Promise<AxiosResponse<ResponseEntity<CharterUpSyncResult>>> {
    const url = `https://${apiBaseUrl()}/charterup/referrals/sync`
    return httpService.post(url, null)
  },
  getRates(): Promise<AxiosResponse<ResponseEntity<CharterUpRate[]>>> {
    const url = `https://${apiBaseUrl()}/charterup/rates`
    return httpService.get(url)
  },
  createRate(
    rate: CharterUpMarketRateRequest
  ): Promise<AxiosResponse<ResponseEntity<void>>> {
    const url = `https://${apiBaseUrl()}/charterup/rates`
    return httpService.post(url, rate)
  },
  updateRate(
    rate: CharterUpMarketRateRequest
  ): Promise<AxiosResponse<ResponseEntity<void>>> {
    const { marketRateId: id } = rate
    const url = `https://${apiBaseUrl()}/charterup/rates/${id}`
    return httpService.patch(url, rate)
  },
  deleteRate(id: number): Promise<AxiosResponse<ResponseEntity<void>>> {
    const url = `https://${apiBaseUrl()}/charterup/rates/${id}`
    return httpService.delete(url)
  },
  getVehicles(): Promise<AxiosResponse<ResponseEntity<CharterUpVehicle[]>>> {
    const url = `https://${apiBaseUrl()}/charterup/vehicles`
    return httpService.get(url)
  },
}
