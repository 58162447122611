
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Editor, EditorContent } from '@tiptap/vue-2'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'

interface RichTextButton {
  id: string
  key: string
  clickHandler: () => void
  icon: string
}

@Component({ components: { EditorContent } })
export default class CURichText extends Vue {
  @Prop({ type: String, default: '' }) readonly label!: string
  @Prop({ type: String, default: '' }) readonly placeholder!: string
  @Prop({ type: Boolean, default: false }) readonly readOnly!: boolean
  @Prop({ type: String, default: '' }) readonly note!: string
  @Prop({ type: Number, default: 160 }) readonly height!: number
  @Prop({ type: Boolean, default: false }) readonly unsetHeight!: boolean
  @Prop({ type: Boolean, default: false }) readonly blueLink!: boolean
  @Prop({ type: String, default: 'Insert Link' }) readonly linkTitle!: string

  editor = null
  link = {
    showModal: false,
    url: '',
    text: '',
  }
  textValue = ''

  @Watch('note')
  noteChanged(value: string): void {
    // HTML
    const isSame = this.editor.getHTML() === value

    if (isSame) {
      return
    }

    this.editor.commands.setContent(value, false)
  }

  @Watch('readOnly')
  readOnlyChanged(value: boolean): void {
    this.editor.setOptions({ editable: value })
  }

  @Watch('link.showModal')
  showLinkModalChanged(newVal: boolean, oldVal: boolean): void {
    if (!oldVal) {
      const { from, to } = this.editor.state.selection
      this.link.text = this.editor.state.doc.textBetween(from, to, ' ')
    }
  }

  get inputVal(): string {
    return this.note
  }

  set inputVal(val: string) {
    this.$emit('text-change', val)
  }

  get boxHeight(): number {
    // 42 is the height of the formatting options toolbar
    return this.height - 42
  }

  closeLinkModal(): void {
    this.link.showModal = false
    this.link.url = ''
    this.link.text = ''
  }

  handleLinkClick(): void {
    if (this.editor.isActive('link')) {
      this.editor.chain().focus().unsetLink().run()
      return
    }
    this.link.showModal = true
  }

  setLink(): void {
    const addHttp = this.link.url.includes('http') ? '' : 'http://'
    const linkToAdd = `<a href="${addHttp}${this.link.url}" target="_blank">${this.link.text}</a>`
    this.editor.commands.insertContent(linkToAdd)

    this.closeLinkModal()
  }

  get buttons(): RichTextButton[] {
    return [
      {
        id: 'bold-button',
        key: 'bold',
        clickHandler: () => this.editor?.chain().focus().toggleBold().run(),
        icon: 'bold',
      },
      {
        id: 'italic-button',
        key: 'italic',
        clickHandler: () => this.editor?.chain().focus().toggleItalic().run(),
        icon: 'italic',
      },
      {
        id: 'strikethrough-button',
        key: 'strike',
        clickHandler: () => this.editor?.chain().focus().toggleStrike().run(),
        icon: 'strikethrough',
      },
      {
        id: 'list-button',
        key: 'bulletList',
        clickHandler: () =>
          this.editor?.chain().focus().toggleBulletList().run(),
        icon: 'list',
      },
      {
        id: 'link-button',
        key: 'link',
        clickHandler: this.handleLinkClick,
        icon: 'link',
      },
    ]
  }

  mounted(): void {
    this.editor = new Editor({
      content: this.note,
      editable: !this.readOnly,
      extensions: [
        StarterKit,
        Link.configure({
          openOnClick: true,
          autolink: true,
        }),
        Placeholder.configure({
          placeholder: this.placeholder,
        }),
      ],
      onUpdate: () => {
        this.$emit('html-change', this.editor.getHTML())
        this.$emit('text-change', this.editor.getText())
      },
    })
  }

  beforeUnmount(): void {
    this.editor.destroy()
  }
}
