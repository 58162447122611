import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { Quote } from '@/models/dto/Quote'
import {
  PlaceSearch,
  ApiResult,
  TripEstimation,
  TripType,
  VehicleType,
  Address,
} from '@/models/dto'
import { CompanyResult } from '@/models/dto/Company'
import { WidgetSubmitPayload } from '@/models/dto/Widget'
import { ItineraryResult } from '@/models/dto/Itinerary'

const httpService: HttpService = new HttpService()

export default {
  companyByIdentifier(
    identifier: string
  ): Promise<AxiosResponse<CompanyResult>> {
    return httpService.get(
      `https://${apiBaseUrl()}/widget/company/${identifier}`
    )
  },
  tripEstimations(quote: Quote): Promise<AxiosResponse<TripEstimation[]>> {
    return httpService.post(
      `https://${apiBaseUrl()}/widget/tripEstimations`,
      quote
    )
  },
  tripTypes(): Promise<AxiosResponse<TripType[]>> {
    return httpService.get(`https://${apiBaseUrl()}/widget/types/trip-types`)
  },

  vehicleTypes(companyId: number): Promise<AxiosResponse<VehicleType[]>> {
    return httpService.get(`https://${apiBaseUrl()}/vehicleTypes/${companyId}`)
  },
  submit(
    identifier: string,
    payload: WidgetSubmitPayload
  ): Promise<AxiosResponse<ApiResult>> {
    return httpService.post(
      `https://${apiBaseUrl()}/widget/submit/${identifier}`,
      payload
    )
  },
  uploadItineraries(files: FormData): Promise<AxiosResponse<ItineraryResult>> {
    const url = `https://${apiBaseUrl()}/widget/uploadItineraries`
    return httpService.post(url, files)
  },
  placesBySearchTerm(
    term: string,
    lat?: number,
    long?: number
  ): Promise<AxiosResponse<PlaceSearch[]>> {
    let url = ``
    if (lat && long) {
      url = `https://${apiBaseUrl()}/widget/places?term=${term}&lat=${lat}&long=${long}`
    } else {
      url = `https://${apiBaseUrl()}/widget/places?term=${term}`
    }
    return httpService.get(url)
  },
  placesById(placeId: string): Promise<AxiosResponse<Partial<Address>>> {
    const url = `https://${apiBaseUrl()}/widget/places/${placeId}`
    return httpService.get(url)
  },
}
