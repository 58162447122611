
import {
  Address,
  SpotTime,
  Stop,
  StopEstimation,
  TripEstimation,
} from '@/models/dto'
import { Inject, Prop } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import StopWithNotes from '@/components/StopWithNotes.vue'
import { Garage } from '@/models/dto/Garage'
import { Journey, JourneyStop, JourneyTime } from '@/models/dto/Journey'
import dayjs from 'dayjs'
import { Note } from '@/models/dto/Note'
import { TrackingStatus } from '@/utils/enum'
import { formatFullName, numberWithCommas } from '@/utils/string'
import sidebar from '@/store/modules/sidebar'
import StopNotesSidebar from './StopNotesSidebar.vue'
import { EventBus } from '@/utils/eventBus'
import auth from '@/store/modules/auth'
import driverClient from '@/services/driver'
import { DriverCommuteInfo } from '@/models/dto/Driver'
import colors from '@/scss/_colors-export.scss'
import { formatSecondsToDuration } from '@/utils/time'
import { estimationTime } from '@/utils/estimationTime'
import quote from '@/store/modules/quote'
import reservation from '@/store/modules/reservation'
import { getMinDate, getMaxDate } from '@/utils/getDates'
import { ref } from 'vue'

@Component({
  components: {
    StopWithNotes,
  },
})
export default class ReservationTrackingRow extends mixins(DateMixin) {
  @Prop({ required: false }) stop: Stop
  @Prop({ required: false }) journeyStop: JourneyStop
  @Prop({ required: false }) garage: Garage
  @Prop({ required: false }) garageTime: string
  @Prop({ required: false }) arrivalTime: string
  @Prop({ required: false, default: auth.getUserTimeZone }) timeZone: string
  @Prop({ required: false }) garageNote: string
  @Prop({ required: false }) spotTime: SpotTime
  @Prop({ required: false }) journeyTime: JourneyTime
  @Prop({ required: false }) trackingStatus: string
  @Prop({ required: false, default: () => ({}) })
  driverCommuteData: Partial<Journey>
  @Prop({ type: Boolean, default: false }) departure: boolean
  @Prop({ type: Boolean, default: false }) arrival: boolean
  @Prop({ type: Boolean, default: false }) dispatch: boolean
  @Prop({ required: true }) addresses: Address[]
  @Prop({ type: Boolean, default: false }) isReferral: boolean
  @Prop({ type: Boolean, default: false }) hideDivider: boolean
  @Prop({ required: false }) readonly tripEstimation!: TripEstimation
  @Inject({ default: false }) isReservationTrip: boolean

  numberWithCommas = numberWithCommas
  TrackingStatus = TrackingStatus
  formatSecondsToDuration = formatSecondsToDuration
  showEditNote = false
  newNote: string = null
  driver = null

  get note(): Note {
    return this.stop?.stopNotes?.[0] || new Note()
  }

  get trackingStatusName(): string {
    if (this.trackingStatus === TrackingStatus.Completed) {
      if (this.departure) {
        return 'Start'
      }
      if (this.arrival) {
        return 'End'
      }
    }
    if (this.trackingStatus === TrackingStatus.Reached) {
      return TrackingStatus.Completed
    }
    return this.trackingStatus
  }

  get sequentialTrackingStatus(): string {
    if (this.stop?.pickupDatetime && this.stop?.dropoffDatetime) {
      if (this.trackingStatus === TrackingStatus.Reached) {
        return TrackingStatus.Upcoming
      } else if (this.trackingStatus === TrackingStatus.Completed) {
        return TrackingStatus.Completed
      }
    }
    return null
  }

  get rightColumnWidth(): string {
    return this.dispatch ? '88px' : '293px'
  }

  get stopOrder(): number {
    return (
      this.stop?.orderIndex || this.tripEstimation?.stopEstimations?.length + 1
    )
  }

  get addressTitle(): string {
    if (this.departure) {
      return 'Departure Garage'
    } else if (this.arrival) {
      return 'Arrival Garage'
    }
    return null
  }

  get secondStopLabel(): string {
    if (this.arrival) {
      return this.lg ? 'Garage' : 'G'
    } else {
      const stopLabel = this.lg ? 'Stop' : 'S'
      const stopOrder = this.stop?.orderIndex > 0 ? this.stopOrder + 1 : 1
      return `${stopLabel} ${stopOrder}`
    }
  }

  get firstStopLabel(): string {
    if (this.stop?.orderIndex === 0) {
      return this.lg ? 'Garage' : 'G'
    } else {
      return this.lg ? `Stop ${this.stopOrder}` : `S ${this.stopOrder}`
    }
  }

  get totalTime(): string {
    if (this.arrival) {
      if (this.getTotalGarageTime() > 0) {
        return this.formatSecondsToDuration(this.getTotalGarageTime())
      } else {
        return this.timeGarage
      }
    } else {
      return this.formatSecondsToDuration(
        this.getTotalEstimationTime(this.stop?.orderIndex)
      )
    }
  }

  get totalMiles(): number {
    return this.getTotalDistanceFromPrevious(
      this.arrival
        ? this.tripEstimation?.stopEstimations?.length - 1
        : this.stop?.orderIndex - 1
    )
  }

  get statusColors(): { [key: string]: string } {
    return {
      [TrackingStatus.Upcoming]: 'primary',
      [TrackingStatus.EnRoute]: 'additional-blue',
      [TrackingStatus.Reached]: 'secondary',
      [TrackingStatus.Completed]: 'secondary',
    }
  }

  get isCompleted(): boolean {
    return (
      this.trackingStatus === TrackingStatus.Completed ||
      this.trackingStatus === TrackingStatus.Reached
    )
  }

  get showDispatchCommuteTag(): boolean {
    return (
      this.dispatch &&
      this.commute.tagString &&
      this.commute.trackingStatus !== TrackingStatus.Completed &&
      this.trackingStatus !== TrackingStatus.Completed
    )
  }

  get timeFromPreviousStop(): StopEstimation {
    if (this.showGarageArrivalStopEstimation) {
      const index = this.tripEstimation?.garageEstimations?.length
      return this.tripEstimation?.garageEstimations[index - 1]
    }
    const index = this.stop?.orderIndex
    const timeFromPrevious =
      index === 0
        ? this.tripEstimation?.garageEstimations?.[index]
        : this.tripEstimation?.stopEstimations?.[index - 1]

    return timeFromPrevious
  }

  getTotalDistanceFromPrevious(index: number): number {
    let totalDistance =
      this.tripEstimation?.garageEstimations?.[0]?.distanceFromPrevious || 0

    for (let i = 0; i <= index; i++) {
      const stopEstimation = this.tripEstimation?.stopEstimations[i]
      totalDistance += stopEstimation?.distanceFromPrevious
    }

    if (this.arrival) {
      totalDistance +=
        this.tripEstimation?.garageEstimations?.[1]?.distanceFromPrevious || 0
    }

    return Math.round(totalDistance)
  }

  get timeFromPrevious(): string {
    const { tripEstimation, isReservationTrip, stop } = this
    const currentIndex = stop?.orderIndex || 0

    const trip = isReservationTrip
      ? reservation.trip
      : quote.trips?.find(({ tripId }) => tripId === tripEstimation.tripId)

    const currentStop = getMinDate(
      stop.spotTime?.spotTime,
      stop.pickupDatetime,
      stop.dropoffDatetime
    )
    const previousStop =
      currentIndex === 0
        ? trip?.garageTimes?.departureTime
        : getMaxDate(
            trip?.stops[currentIndex - 1].spotTime?.spotTime,
            trip?.stops[currentIndex - 1].pickupDatetime,
            trip?.stops[currentIndex - 1].dropoffDatetime
          )

    const seconds =
      previousStop && currentStop ? dayjs(currentStop).diff(previousStop) : null

    return formatSecondsToDuration(seconds / 1000)
  }

  get timeGarage(): string {
    const { tripEstimation, isReservationTrip } = this
    const index = tripEstimation?.stopEstimations.length || 0

    const trip = isReservationTrip
      ? reservation.trip
      : quote.trips?.find(({ tripId }) => tripId === tripEstimation.tripId)

    const currentStop = trip?.garageTimes?.returnTime

    const previousStop =
      index === 0
        ? trip?.garageTimes?.departureTime
        : getMaxDate(
            trip?.stops[index].spotTime?.spotTime,
            trip?.stops[index].pickupDatetime,
            trip?.stops[index].dropoffDatetime
          )

    const seconds =
      previousStop && currentStop ? dayjs(currentStop).diff(previousStop) : null

    return formatSecondsToDuration(seconds / 1000)
  }

  getTotalEstimationTime(index: number): number {
    const { tripEstimation, isReservationTrip } = this

    const trip = isReservationTrip
      ? reservation.trip
      : quote.trips?.find(({ tripId }) => tripId === tripEstimation.tripId)

    let seconds = 0

    for (let i = 0; i <= index; i++) {
      const previousStop =
        i === 0
          ? trip?.garageTimes?.departureTime
          : getMaxDate(
              trip?.stops[i - 1].spotTime?.spotTime,
              trip?.stops[i - 1].pickupDatetime,
              trip?.stops[i - 1].dropoffDatetime
            )
      const currentStop = getMinDate(
        trip?.stops[i].spotTime?.spotTime,
        trip?.stops[i].pickupDatetime,
        trip?.stops[i].dropoffDatetime
      )

      seconds +=
        previousStop && currentStop
          ? dayjs(currentStop).diff(previousStop)
          : null
    }

    return seconds / 1000
  }

  getTotalGarageTime(): number {
    const { tripEstimation, isReservationTrip } = this
    const index = tripEstimation?.stopEstimations.length

    const trip = isReservationTrip
      ? reservation.trip
      : quote.trips?.find(({ tripId }) => tripId === tripEstimation.tripId)

    let seconds = 0

    for (let i = 0; i <= index; i++) {
      const previousStop =
        i === 0
          ? trip?.garageTimes?.departureTime
          : getMaxDate(
              trip?.stops[i - 1].spotTime?.spotTime,
              trip?.stops[i - 1].pickupDatetime,
              trip?.stops[i - 1].dropoffDatetime
            )
      const currentStop =
        index === i
          ? trip?.garageTimes?.returnTime
          : getMinDate(
              trip?.stops[i].spotTime?.spotTime,
              trip?.stops[i].pickupDatetime,
              trip?.stops[i].dropoffDatetime
            )

      seconds +=
        previousStop && currentStop
          ? dayjs(currentStop).diff(previousStop)
          : null
    }
    return seconds / 1000
  }

  get showFirstStopEstimation(): boolean {
    return !!(
      this.tripEstimation?.garageEstimations?.[0]?.timeFromPrevious ||
      this.stop?.orderIndex !== 0
    )
  }

  get showGarageArrivalStopEstimation(): boolean {
    return !!(
      this.tripEstimation?.garageEstimations?.[1]?.timeFromPrevious > 0 &&
      this.arrival
    )
  }

  get commute(): DriverCommuteInfo {
    if (!this.driverCommuteData?.commutingDriverId) {
      return new DriverCommuteInfo()
    }
    const duration = this.driverCommuteData.driverCommuteDurationInMinutes
    const start = this.formatShortTime(
      this.driverCommuteData.driverCommuteStart,
      {
        tz: this.garage.address.timeZone || auth.getUserTimeZone,
        showTimezone: true,
        showMeridianUpper: true,
      }
    )
    const eta = this.dispatch
      ? dayjs(this.driverCommuteData.driverCommuteStart)
          .tz(this.garage.address.timeZone || auth.getUserTimeZone)
          .add(duration, 'minutes')
          .format('hh:mm A')
      : this.formatShortTime(
          dayjs(this.driverCommuteData.driverCommuteStart)
            .add(duration, 'minutes')
            .toISOString(),
          {
            tz: this.garage.address.timeZone || auth.getUserTimeZone,
            showMeridianUpper: true,
          }
        )
    const finish = this.dispatch
      ? dayjs(this.driverCommuteData.driverCommuteStart)
          .tz(this.garage.address.timeZone || auth.getUserTimeZone)
          .format('hh:mm A')
      : this.formatLongDateLongTime(this.driverCommuteData.driverCommuteStart, {
          tz: this.garage.address.timeZone || auth.getUserTimeZone,
          showDot: true,
          showTimezone: true,
          showMeridianUpper: true,
        })
    const isLate = dayjs(this.driverCommuteData.driverCommuteStart)
      .add(duration, 'minutes')
      .isAfter(this.arrivalTime || this.garageTime, 'minutes')

    return {
      trackingStatus:
        duration === 0 ? TrackingStatus.Completed : TrackingStatus.EnRoute,
      tagString: isLate ? 'Late' : 'On Time',
      tagColor: isLate ? 'rust' : 'secondary',
      tagBackground: colors[isLate ? 'rust-10' : 'secondary-light'],
      arrivalTimeString: `${duration === 0 || this.dispatch ? '' : 'ETA '}${
        duration === 0 ? finish : eta
      }`,
      driverInfoString: this.driver
        ? `${formatFullName(this.driver)}${
            start && duration !== 0 ? `, as of ${start}` : ''
          }`
        : null,
    }
  }

  displayDate(date: string, timeOnly = false): string {
    const timeZone = this.journeyStop?.stop?.address?.timeZone || this.timeZone
    if (!this.dispatch && !timeOnly) {
      return this.formatLongDateLongTime(date, {
        tz: timeZone,
        showDot: true,
        showTimezone: true,
        showMeridianUpper: true,
      })
    }
    return dayjs(date).tz(timeZone).format('hh:mm A')
  }

  handleEnterPress(): void {
    this.handleMouseLeave()
  }

  handleMouseOver(): void {
    if (
      this.stop?.stopNotes?.[0]?.note ||
      this.stop?.stopNotes?.[0]?.html ||
      this.garageNote ||
      this.stop?.notes
    ) {
      this.showEditNote = true
    }
  }

  handleMouseLeave(): void {
    this.showEditNote = false
  }

  handleAddNote(): void {
    this.showEditNote = false
    const note = this.stop
      ? this.stop.stopNotes[0] || new Note({ note: this.stop?.notes })
      : new Note({ html: this.garageNote })
    sidebar.push({
      component: StopNotesSidebar,
      props: {
        note,
        id: this.garage ? this.garage.companyId : this.stop.stopId,
        isGarage: !!this.garage,
        isReturning: this.arrival,
      },
      on: {
        submit: () => EventBus.$emit('refresh-reservation'),
      },
    })
  }

  async loadDriverData(): Promise<void> {
    const driver = await driverClient.byId(
      this.driverCommuteData.commutingDriverId
    )
    this.driver = driver?.data?.driver
  }

  get lg(): boolean {
    return this.$vuetify.breakpoint.width >= 1500
  }

  mounted(): void {
    if (this.driverCommuteData?.commutingDriverId) {
      this.loadDriverData()
    }
  }
}
