
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Tab } from '@/models/dto/Tab'
import { getWidthOfTextInFont } from '@/utils/string'
import { EventBus } from '@/utils/eventBus'

@Component
export default class CUTabs extends Vue {
  @Prop({ required: true, default: () => [] }) readonly tabs!: Tab[]
  @Prop({ type: Boolean, default: false }) readonly isSubTab!: boolean
  @Prop({ required: false, default: 'white' }) readonly backgroundColor!: string
  @Prop({ required: false, default: 'primary' }) readonly color!: string
  @Prop({ required: false, default: 50 }) readonly editFieldLimit!: number
  option = 0
  hover = -1
  tabName = ''
  newTabName = ''
  editTabIdx = null
  canvas = null

  @Watch('tabs.length')
  onTabsLengthChange(): void {
    this.$nextTick(() => {
      const hash =
        this.tabs[this.option]?.hash ||
        this.tabs[this.option]?.subtabHashes?.[0] ||
        ''
      if (hash?.replace('#', '') !== this.$route.hash?.replace('#', '')) {
        this.$router.replace({ hash })
      }
    })
  }

  get tabColor(): string {
    if (this.isSubTab) {
      if (this.tabs[this.option]?.error) {
        return 'error'
      }
      return this.color
    }
    return 'black'
  }

  get editFieldWidth(): number {
    const textWidth = getWidthOfTextInFont(
      this.newTabName,
      '14px',
      '700',
      this.canvas
    )
    return Math.min(textWidth, 260)
  }

  getPaddingStyle(tab: Tab, idx: number): string {
    if (idx === this.editTabIdx) {
      return tab.error ? 'padding: 0 70px' : 'padding: 0 60px'
    }
    if (
      tab.removable &&
      tab.editable &&
      this.option === idx &&
      this.hover === idx
    ) {
      return tab.error ? 'padding: 0 52px' : 'padding: 0 42px'
    } else if (
      (tab.removable || tab.editable) &&
      this.option === idx &&
      this.hover === idx
    ) {
      return tab.error ? 'padding: 0 40px' : 'padding: 0 30px'
    } else if (tab.error && !this.isSubTab) {
      return 'padding: 0 28px'
    }
    return null
  }

  onTabChange(_index?: number, ignoreTitle = false): void {
    this.editTabIdx = null
    const title = this.tabs[this.option]?.title
    if (!ignoreTitle && title && document) {
      document.title = title
    }
    this.$emit('tab:change', [this.option])
  }

  handleEditTabName(idx: number): void {
    this.editTabIdx = idx
    this.tabName = this.tabs[idx].label
    this.newTabName = this.tabs[idx].label
  }

  handleSaveTabName(idx: number): void {
    this.$emit('tab:edit', { idx, name: this.newTabName })
    this.tabName = null
    this.editTabIdx = null
  }

  handleDeleteTab(idx: number): void {
    if (this.editTabIdx !== idx) {
      this.$emit('tab:delete', [idx])
    }
    this.tabName = null
    this.editTabIdx = null
  }

  handleMouseOver(idx: number): void {
    this.hover = idx
  }

  handleMouseLeave(): void {
    this.hover = -1
  }

  handleBlur(idx: number): void {
    if (idx === this.editTabIdx) {
      this.editTabIdx = null
    }
  }

  resetSubtabs(): void {
    if (this.isSubTab) {
      this.option = 0
    }
  }

  handleTabClick(tab: Tab): void {
    if (tab.subtabHashes?.length) {
      EventBus.$emit('reset:subtabs')
    }

    let hash = null

    if (tab.hash && this.$route.hash !== tab.hash) {
      try {
        hash = tab.hash
      } catch (e) {
        console.error(e)
      }
    } else if (
      tab.subtabHashes?.length &&
      tab.subtabHashes[0] !== this.$route.hash
    ) {
      try {
        hash = tab.subtabHashes[0]
      } catch (e) {
        console.error(e)
      }
    }

    if (hash && this.$route.hash?.replace('#', '') !== hash?.replace('#', '')) {
      this.$router.replace({ hash })
    }
  }

  @Watch('$route.hash')
  onRouteHashChange(): void {
    this.setTabsFromHash()
  }

  setTabsFromHash(): void {
    if (!this.$route.hash) {
      this.onTabChange(-1, true)
      return
    }

    const routeHash = this.$route.hash.replace('#', '')
    let tabIdx = -1

    // Check if any of the parent tabs have a matching hash
    const matchingParentTabIdx = this.tabs.findIndex(
      ({ hash }) => hash?.replace('#', '') === routeHash
    )

    // Check if any of the parent tabs have a matching subtab hash
    const matchingSubtabIdx = this.tabs.findIndex((tab) => {
      if (!tab.subtabHashes) {
        return false
      }
      const subtabHashes = tab.subtabHashes.map((h) => h.replace('#', ''))
      return subtabHashes.includes(routeHash)
    })

    // Update tab selection to the matching index
    tabIdx =
      matchingParentTabIdx >= 0 ? matchingParentTabIdx : matchingSubtabIdx

    if (tabIdx >= 0) {
      this.option = tabIdx
      this.hover = tabIdx
      this.onTabChange(-1, true)
    }
  }

  mounted(): void {
    EventBus.$on('reset:subtabs', this.resetSubtabs)

    this.setTabsFromHash()

    const title = this.tabs[this.option]?.title
    const hasSubtabs = !!this.tabs[this.option]?.subtabHashes?.length
    if (title && document && !hasSubtabs) {
      document.title = title
    }
  }

  get pageTitle(): string {
    return this.tabs[this.option]?.title || undefined
  }

  beforeDestroy(): void {
    EventBus.$off('reset:subtabs', this.resetSubtabs)
  }
}
