import { Action, Module, VuexModule } from 'vuex-class-modules'
import { Route, Location } from 'vue-router/types/router'
import systemParameter from '@/services/systemParameter'
import { SystemParameter } from '@/models/dto/SystemParameter'

// register module (could be in any file)
import store from '@/store/index'
import auth from '@/store/modules/auth'
import { isDevEnvironment } from '@/utils/env'

@Module({ generateMutationSetters: true })
class AppModule extends VuexModule {
  // state
  appTitle = 'Busify'
  lastRoute: Location | null = null
  systemParameters: SystemParameter[] = []
  areLoadersEnabled = false
  areParametersLoaded = false

  // getters
  get getAppTitle() {
    return this.appTitle
  }

  get isCustomersEnvironment(): boolean {
    const host = window?.location?.host?.split(':')[0] || null
    return host?.includes('customers')
  }

  get getLastRoute() {
    return this.lastRoute
  }

  get getSystemParameters() {
    return this.systemParameters
  }

  get getAreLoadersEnabled() {
    return this.areLoadersEnabled
  }

  get isQuickbooksIntegrationFeatureEnabled(): boolean {
    return isFeatureToggledOnForThisCompany(
      'isQuickbooksIntegrationEnabled',
      this.systemParameters
    )
  }

  get isReservationPdfExportEnabled(): boolean {
    return this.isSystemParameterTrue('isReservationPdfExportEnabled')
  }

  get isPostTripEnabled(): boolean {
    return this.isSystemParameterTrue('postTripEnabled') || isDevEnvironment()
  }

  get isDateFormatSettingEnabled(): boolean {
    return (
      this.isSystemParameterTrue('isDateFormatSettingEnabled') ||
      isDevEnvironment()
    )
  }

  get isDuplicateReservationEnabled(): boolean {
    return this.isSystemParameterTrue('isDuplicateReservationEnabled')
  }

  get isSoldOutSidebarEnabled(): boolean {
    return this.isSystemParameterTrue('isSoldOutSidebarEnabled')
  }

  get isDriverGridEnabled(): boolean {
    return this.isSystemParameterTrue('isDriverGridEnabled')
  }

  get charterupReferralUserId(): string {
    const referralParameter = checkSystemParameter(
      this.systemParameters,
      'charterUpReferralUserId'
    )
    return referralParameter.value
  }

  get areSystemParametersLoaded(): boolean {
    return this.areParametersLoaded
  }

  isSystemParameterTrue(param: string): boolean {
    const value =
      this.systemParameters.find(({ name }) => name === param)?.value || null
    return value === 'true' || value === `${auth.getCompanyId}`
  }

  // mutations (mutations are autogenerated for each root level state field)

  // actions
  @Action
  saveLastRoute(route: Route): void {
    this.lastRoute = {
      name: route?.name || undefined,
      path: route?.path,
      hash: route?.hash,
      query: route?.query,
      params: route?.params,
    }
  }

  @Action
  async fetchSystemParameters() {
    const response = await systemParameter.tableView({
      sorts: null,
      filters: null,
      pageSize: -1,
      page: 1,
    })
    this.systemParameters = response.data.resultList
    this.areParametersLoaded = true
    this.calculateSystemParameters()
  }

  @Action
  calculateSystemParameters() {
    this.areLoadersEnabled = checkIfSkeletonLoadersAreEnabled(
      this.systemParameters
    )
  }
}

// PRIVATE HELPERS
const checkSystemParameter = (
  systemParameters: SystemParameter[],
  systemParameterName: string
): SystemParameter | null => {
  return systemParameters.find(
    (systemParameter) => systemParameter.name === systemParameterName
  )
}

const isFeatureToggledOnForThisCompany = (
  name: string,
  systemParameters: SystemParameter[]
): boolean => {
  const parameter = systemParameters.find(
    (parameter) => parameter.name === name
  )

  if (!parameter || !parameter.value) {
    return false
  }

  if (parameter.value === 'true') {
    return true
  }

  const companiesEnabled = JSON.parse(parameter.value).map((x: any) =>
    Number(x)
  )
  return !!companiesEnabled.find((companyId) => companyId === auth.getCompanyId)
}

const checkIfSkeletonLoadersAreEnabled = (
  systemParameters: SystemParameter[]
): boolean => {
  const foundParameter = checkSystemParameter(systemParameters, 'cupopsLoaders')
  return foundParameter?.value === 'true'
}
export default new AppModule({ store, name: 'app' })
