var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"padding-y-2"},[_c('div',{staticClass:"d-flex align-center padding-x-2 transition-all transition-duration-300",class:_vm.selectedVehicleClasses,style:({ gap: '12px' })},[(!_vm.useResDetailView)?_c('CUCheckbox',{staticClass:"flex-shrink-0",attrs:{"input-value":_vm.isVehicleSelected,"round":""},on:{"input":_vm.handleUpdateVehicleCheckbox}}):_vm._e(),_c('AssignmentsAvatar',{staticClass:"cursor-pointer",attrs:{"vehicle-assignment":_vm.vehicleAssignment},on:{"click":_vm.handleVehicleAvatarClick}}),_c('CUVehicleSelect',{staticClass:"w-full margin-b-1 transition-ease transition-duration-300",attrs:{"selected-vehicle-name":_vm.vehicleAssignment.name,"selected-vehicle-id":_vm.vehicleAssignment.id,"default-type":_vm.vehicleAssignment.type,"placeholder":`Select ${_vm.vehicleAssignment.type || 'Vehicle'}`,"label":_vm.vehicleAssignment.type,"items":_vm.assignment.vehiclesWithInactiveFlag},on:{"change":function($event){return _vm.assignment.handleChangeVehicle({ oldIdx: _vm.vehicleIdx, id: $event })}},scopedSlots:_vm._u([(_vm.vehicleAssignment.id && !_vm.vehicleAssignment.isConfirmed)?{key:"tag",fn:function(){return [_c('CUTag',{staticClass:"margin-r-2",attrs:{"tag":"Draft","font-size":12,"dense":"","border":"bg-gray-5","background":"bg-gray-1"}})]},proxy:true}:null],null,true)}),_c('AssignmentsSelectStackActions',{attrs:{"assignment":_vm.vehicleAssignment,"idx":_vm.vehicleIdx},on:{"confirm":function($event){return _vm.handleConfirmVehicle(_vm.vehicleAssignment.id)},"clear":_vm.handleClearVehicle}})],1),_vm._l((_vm.vehicleAssignment.drivers),function(driverAssignment,driverIdx){return _c('div',{key:`driver-assignment-${driverAssignment.id}-${driverIdx}`,staticClass:"d-flex align-center padding-x-2 margin-t-1 transition-all transition-duration-300",class:{
      'padding-y-2 background-secondary-light':
        driverAssignment.id &&
        driverAssignment.isConfirmed &&
        _vm.isDriverSelected(driverAssignment.id, driverIdx),
      'padding-y-2 background-bg-gray-1':
        driverAssignment.id &&
        !driverAssignment.isConfirmed &&
        _vm.isDriverSelected(driverAssignment.id, driverIdx),
      'padding-y-2 background-primary-10':
        !driverAssignment.id &&
        _vm.isDriverSelected(driverAssignment.id, driverIdx),
      'padding-y-1': !_vm.isDriverSelected(driverAssignment.id, driverIdx),
    },style:({ gap: '12px' })},[(!_vm.useResDetailView && _vm.app.isDriverGridEnabled)?_c('CUCheckbox',{staticClass:"flex-shrink-0",attrs:{"input-value":_vm.isDriverSelected(driverAssignment.id, driverIdx),"round":""},on:{"input":(e) => _vm.handleUpdateDriverCheckbox(e, driverAssignment.id, driverIdx)}}):(!_vm.useResDetailView)?_c('div',{style:({ height: '22px', width: '22px', flexShrink: '0' })}):_vm._e(),_c('AssignmentsAvatar',{staticClass:"cursor-pointer",attrs:{"driver-assignment":driverAssignment},on:{"click":function($event){return _vm.handleDriverAvatarClick(_vm.vehicleIdx, driverIdx)}}}),_c('CUSelect',{staticClass:"w-full transition-ease transition-duration-300",class:{
        'margin-b-n5': !driverAssignment.errorMessage,
      },attrs:{"id":`assigned-driver-${driverAssignment.id}`,"value":driverAssignment.id,"error-messages":driverAssignment.errorMessage,"placeholder":"Select Driver","searchable":"","tag":"Draft","label":driverAssignment.id ? 'Driver' : null,"items":_vm.driversSortedByName,"item-text":"name","item-value":"driverId"},on:{"input":function($event){return _vm.assignment.handleChangeDriver({
          vehicleIdx: _vm.vehicleIdx,
          driverIdx: driverIdx,
          id: $event,
        })}},scopedSlots:_vm._u([(_vm.driverAssignmentTags[driverIdx])?{key:"tag",fn:function(){return [_c('CUTag',{staticClass:"margin-r-2",attrs:{"tag":_vm.driverAssignmentTags[driverIdx].tag,"font-size":12,"dense":"","border":_vm.driverAssignmentTags[driverIdx].border,"background":_vm.driverAssignmentTags[driverIdx].bg}})]},proxy:true}:null,{key:"item",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center justify-space-between w-full"},[_c('span',{staticClass:"body-2-regular",class:{
              'text-text-gray-1': item.status === 'Rejected',
            }},[_vm._v(" "+_vm._s(item.name)+" ")]),(item.status)?_c('DriverAssignmentTag',{attrs:{"assignment-status":item.status}}):_vm._e()],1)]}}],null,true)}),_c('AssignmentsSelectStackActions',{attrs:{"assignment":driverAssignment,"idx":driverIdx},on:{"confirm":function($event){return _vm.handleConfirmDriver(driverIdx)},"clear":function($event){return _vm.handleClearDriver(driverIdx)}}})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }