
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Contact } from '@/models/Contact'
import RecipientList from '@/components/RecipientList.vue'
import { validateRules } from '@/utils/rules'
import CUCheckbox from '@/components/CUCheckbox.vue'
import auth from '@/store/modules/auth'
import rstate from '@/store/modules/reservation'
import quickbooks from '@/services/quickbooks'

@Component({
  components: { CUCheckbox, RecipientList },
})
export default class SendTripCancellationSidebar extends Vue {
  @Prop({ required: false, default: () => [] }) readonly contacts!: Contact[]
  recipients: Contact[] = []
  sendEmailToCustomer = false
  showTextArea = false
  cancellationNote = ''
  loading = false
  voidQuickbooksInvoice = true

  // POST LAUNCH
  // preview(): void {
  //   this.$emit('preview', auth.getUser, this.cancellationNote)
  // }

  cancel(): void {
    this.$emit('cancel')
  }

  submit(): void {
    if (this.sendEmailToCustomer) {
      validateRules(this).then((isValid) => {
        if (isValid) {
          this.loading = true
          this.$emit('notify', this.recipients, this.cancellationNote)
          this.$emit('submit')
        }
      })
      return
    }
    if (this.voidQuickbooksInvoice) {
      try {
        quickbooks.voidInvoiceByReservationId(
          auth.getCompanyId,
          rstate.reservation.reservationId
        )
      } catch (e) {
        console.log(e)
      } finally {
        rstate.fetchReservationInvoiceDetails()
      }
    }
    this.loading = true
    this.$emit('submit')
  }

  get invoiceNumber(): string {
    return rstate.reservationInvoiceDetails?.invoiceNumber || null
  }

  get voidQuickbooksInvoiceLabel(): string {
    return `Void synced QuickBooks Invoice (ID ${this.invoiceNumber})`
  }

  mounted(): void {
    this.recipients = [...this.contacts]
  }
}
