var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"file-upload"},[_vm._l((_vm.uploadedFiles),function(uploadedFile,index){return _c('div',{key:`uploaded-file-${uploadedFile.id}-${index}`,staticClass:"d-flex justify-space-between align-center border-radius-8 padding-x-1 cursor-pointer border-solid border-1 border-gray-border-mid margin-t-3"},[_c('div',{staticClass:"padding-l-1 overflow-hidden text-overflow-ellipsis font-14 d-flex align-center text-secondary",style:({
        width: 'calc(100% - 25px)',
      }),on:{"click":() => _vm.handleOpenUploadedFile(uploadedFile)}},[_c('CUIcon',{key:"draft-icon",staticClass:"margin-l-1 margin-b-n1",attrs:{"width":"24px","height":"24px","color":"#707179"}},[_vm._v(" draft ")]),_c('CUTooltip',{attrs:{"tooltip-text":uploadedFile.fileName,"bottom":""}},[_c('div',{staticClass:"white-space-nowrap overflow-hidden text-overflow-ellipsis text-secondary",style:({
            width: '170px',
          })},[_vm._v(" "+_vm._s(uploadedFile.fileName)+" ")])])],1),_c('v-btn',{staticClass:"align-center margin-r-1",attrs:{"id":"quote-widget-upload-remove-file","icon":"","x-small":""},on:{"click":() => _vm.removeUploadedFile(uploadedFile.id)}},[_c('CUIcon',{key:"driver-details-document-upload-close",staticClass:"cursor-pointer",attrs:{"color":"#1C1B1F","width":"16px","height":"16px"}},[_vm._v(" close ")])],1)],1)}),_vm._l((_vm.value),function(file,index){return _c('div',{key:`file-${file.name}-${index}`,staticClass:"d-flex justify-space-between align-center border-radius-8 padding-x-1 border-solid cursor-pointer border-1 border-gray-border-mid margin-t-3"},[_c('div',{staticClass:"padding-l-1 overflow-hidden text-overflow-ellipsis font-14 d-flex align-center text-secondary",style:({
        width: 'calc(100% - 25px)',
      }),on:{"click":() => _vm.handleOpenFile(file)}},[_c('CUIcon',{key:"draft-icon",staticClass:"margin-l-1 margin-b-n1",attrs:{"width":"24px","height":"24px","color":"#707179"}},[_vm._v(" draft ")]),_c('CUTooltip',{attrs:{"tooltip-text":file.name,"bottom":""}},[_c('div',{staticClass:"white-space-nowrap overflow-hidden text-overflow-ellipsis text-secondary",style:({
            width: '170px',
          })},[_vm._v(" "+_vm._s(file.name)+" ")])]),(_vm.isLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"orange"}}):_vm._e()],1),_c('v-btn',{staticClass:"align-center margin-r-1",attrs:{"id":"quote-widget-upload-remove-file","icon":"","x-small":""},on:{"click":() => _vm.removeValueFile(index)}},[_c('CUIcon',{key:"driver-details-document-upload-close",staticClass:"cursor-pointer",attrs:{"color":"#1C1B1F","width":"16px","height":"16px"}},[_vm._v(" close ")])],1)],1)}),_c('v-slide-y-transition',{attrs:{"hide-on-leave":""}},[(_vm.availableSlots && _vm.showAddFileButton)?_c('div',[_c('div',{staticClass:"w-full h-100 d-flex flex-column align-center hover:background-primary-orange-3 hover:cursor-pointer justify-center position-relative border-radius-20 border-1 border-dashed padding-x-2 margin-y-2 text-primary border-color-secondary",on:{"dragover":function($event){$event.preventDefault();return _vm.handleDragOver.apply(null, arguments)},"drop":function($event){$event.preventDefault();return _vm.handleDrop.apply(null, arguments)},"click":_vm.triggerFileInput}},[_c('CUIcon',{attrs:{"color":"primary"}},[_vm._v("upload_alt")]),_c('p',{style:({
            'text-align': 'center',
          })},[_vm._v(" Drop up to "+_vm._s(_vm.availableSlots)+" "+_vm._s(_vm.pluralize('file', _vm.availableSlots))+" here or "),_c('a',{staticClass:"browse-link text-primary"},[_vm._v("browse files")]),_vm._v(" on your device "),_c('input',{ref:"fileInput",staticClass:"opacity-0",style:({
              display: 'none',
            }),attrs:{"type":"file","multiple":"","accept":_vm.inputAcceptedFileTypes,"size":_vm.maximumFileSize},on:{"change":_vm.handleFileUpload}})])],1)]):_vm._e()])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }