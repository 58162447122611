
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import client from '@/services/reservation'
import assignmentClient from '@/services/tripAssignment'
import trackingClient from '@/services/tracking'
import tripClient from '@/services/trip'
import contactClient from '@/services/customer'

import sidebar from '@/store/modules/sidebar'
import auth from '@/store/modules/auth'
import reservation from '@/store/modules/reservation'

import {
  ContactTypeKeys,
  ReservationStatus,
  SourceCategory,
} from '@/utils/enum'
import { EventBus } from '@/utils/eventBus'
import { formatFullName } from '@/utils/string'

import { Action } from '@/models/dto/Action'
import { Contact } from '@/models/Contact'
import { Comment } from '@/models/Comment'
import { ReservationStatusUpdate, UserDetail } from '@/models/dto'
import { ContactTypeKey, SimpleContact } from '@/models/dto/Customer'

import AddContactSidebar from './AddContactSidebar.vue'
import ContactSidebarDetail from './ContactSidebarDetail.vue'
import CreateContactSidebar from './CreateContactSidebar.vue'
import ReservationDetailPanelHeader from './ReservationDetailPanelHeader.vue'
import ReservationDetailPanelContactInfo from './ReservationDetailPanelContactInfo.vue'
import ReservationDetailPanelItinerary from './ReservationDetailPanelItinerary.vue'
import ReservationDetailPanelNotes from './ReservationDetailPanelNotes.vue'
import ReservationTrackingMap from '@/components/ReservationTrackingMap.vue'
import ReservationDetailPanelDriverMessaging from './ReservationDetailPanelDriverMessaging.vue'
import ReservationAssignments from './ReservationAssignments.vue'
import SendTripCancellationSidebar from './SendTripCancellationSidebar.vue'
import SendDriverTripSheetSidebar from '@/components/SendDriverTripSheetSidebar.vue'
import { uniqueBy } from '@/utils/array'
import { saveAs } from 'file-saver'
import { parseAxiosError } from '@/utils/error'
import messages from '@/data/messages'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'
import { getSelectedDataFromResState } from '@/utils/assignment'
import assignment from '@/store/modules/assignment'

@Component({
  components: {
    ReservationDetailPanelHeader,
    ReservationDetailPanelContactInfo,
    ReservationDetailPanelItinerary,
    ReservationAssignments,
    ReservationDetailPanelNotes,
    ReservationTrackingMap,
    ReservationDetailPanelDriverMessaging,
  },
})
export default class ReservationDetailPanel extends Vue {
  @Prop({ required: false, default: null })
  readonly reservationId!: number
  @Prop({ required: false, default: null })
  readonly managedId!: string
  @Prop({ required: false, default: null })
  readonly onUpdate!: () => void
  @Prop({ type: Boolean, default: false })
  readonly popOnClose!: boolean

  @Watch('reservationId', { immediate: true })
  onReservationIdChange(): void {
    this.loading = true
    this.loadReservationDetail()

    this.$nextTick(() => {
      if (this.$router.currentRoute.hash !== `#${this.reservationId}`) {
        this.$router.replace({ hash: `#${this.reservationId}` })
      }
    })
  }

  state = reservation
  loading = true
  driverMessages: Comment[] = []
  vehicle: Action = null

  actions: Action[] = [
    {
      label: 'Send Driver Trip Sheet',
      id: 'send-driver-trip-sheet',
      event: 'reservation:send-driver-trip-sheet',
      icon: 'pdf',
    },
  ]

  get canLinkToDetailPage(): boolean {
    return auth.getUserRoleNames.includes(ACCESS_SETTINGS_ROLES.RESERVATIONS)
  }

  get title(): string {
    if (!this.managedId && !this.state.reservation?.managedId) {
      return null
    }
    return `Res. ID: ${this.managedId || !this.state.reservation?.managedId}${
      this.state.reservation?.managedReferralId ||
      this.state.reservation?.referralId
        ? ` (Referral ID: ${
            this.state.reservation?.managedReferralId ||
            this.state.reservation?.referralId
          })`
        : ''
    }`
  }

  get drivers(): { email: string; type: string }[] {
    const contacts = this.state.assignments
      .flatMap((assignment) => assignment.driverAssignments)
      .map(({ driver: { email } }) => ({ email, type: 'Driver' }))
    return uniqueBy(contacts, 'email')
  }

  get isReferral(): boolean {
    return this.state.reservation?.sourceCategory === SourceCategory.REFERRAL
  }

  get hasFarmout(): boolean {
    return !!this.state.reservation?.affiliateOffers?.filter(
      (offer) => offer.isActive
    )?.length
  }

  get vehicles(): { label: string; id: number; event: string }[] {
    const assigned = []
    const unassigned = this.state.reservation.journeys?.reduce((arr, j) => {
      const existingIndex = arr.findIndex((el) => el.id === j.vehicle.vehicleId)
      const newVehicle = {
        label: j.vehicle.vehicleName
          ? j.vehicle.vehicleName
          : `Vehicle ${j.vehicle.vehicleId}`,
        id: j.vehicle.vehicleId,
        event: 'input',
      }
      if (existingIndex !== -1) {
        arr.splice(existingIndex, 1)
      }
      if (j.finished && !j.finishDatetime) {
        arr.push(newVehicle)
      } else {
        assigned.push(newVehicle)
      }
      return arr
    }, [])
    return [...assigned, ...unassigned]
  }

  handleChangeVehicle(val: Action): void {
    if (val) {
      this.vehicle = val
    }
  }

  handleSendDriverTripSheet(): void {
    sidebar.push({
      title: 'Send Driver Trip Sheet',
      component: SendDriverTripSheetSidebar,
      props: { drivers: this.drivers },
      on: {
        cancel: this.handleSubmissionCancel,
        notify: this.handleSendDriverTripSheetEmails,
        preview: this.handleSendDriverTripSheetPreviewEmail,
        download: this.handleDownloadDriverTripSheet,
      },
    })
  }

  handleSubmissionCancel(): void {
    sidebar.pop()
  }

  async handleSendDriverTripSheetEmails(contacts: Contact[]): Promise<void> {
    const contactEmails = contacts.map((c) => c.email)
    try {
      await client.sendDriverTripSheet(
        this.reservationId,
        contactEmails,
        auth.getUserTimeZone
      )
      EventBus.$emit('snackbar:success', messages.success.emailSent)
      sidebar.pop()
    } catch (e: any) {
      EventBus.$emit('snackbar:error', parseAxiosError(e))
    }
  }

  // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
  handleSendDriverTripSheetPreviewEmail(user: UserDetail): void {
    // TODO: Send preview
  }

  async handleDownloadDriverTripSheet(): Promise<void> {
    const hash = this.state.reservation?.hash
    const reservationId = this.state.reservation?.managedId
    if (hash && reservationId) {
      const name = `${reservationId}_Reservation_Itinerary.pdf`
      const res = await client.downloadItinerary(hash)
      saveAs(res.data, name)
    }
  }

  async setReservationsStatus(reservationStatus: string): Promise<void> {
    if (reservationStatus === 'cancelled') {
      this.handleSendTripCancellation()
    } else {
      reservation.updateReservation({ reservationStatus })
      const statusUpdate: ReservationStatusUpdate = {
        reservationIds: [this.state.reservation?.reservationId],
        reservationStatus,
      }
      try {
        await client.updateReservationStatus(statusUpdate)
        if (this.onUpdate) {
          this.onUpdate()
        }
        this.loadReservationDetail()
      } catch (error) {
        console.log(error)
      }
    }
  }

  async handleSendTripCancellation(): Promise<void> {
    const billingCustomer = this.state.reservation?.billingCustomerId
      ? await contactClient.byId(this.state.reservation.billingCustomerId)
      : null
    const contacts = [
      {
        email: this.state.reservation?.customerEmail,
        type: ContactTypeKeys.BOOKING,
      },
      {
        email: billingCustomer?.data.customer.email,
        type: ContactTypeKeys.BOOKING,
      },
    ]
    sidebar.push({
      title: 'Cancel Trip',
      component: SendTripCancellationSidebar,
      props: {
        contacts: contacts.filter((c) => !!c.email),
      },
      on: {
        cancel: () => sidebar.pop(),
        notify: this.handleSendTripCancellationEmails,
        preview: this.handleSendTripCancellationPreviewEmail,
        submit: this.handleTripCancellation,
      },
    })
  }

  async handleSendTripCancellationEmails(
    contacts: Contact[],
    note: string
  ): Promise<void> {
    const tos = contacts.map((c) => c.email)
    try {
      await client.sendCancellationEmail(this.reservationId, tos, note)
      EventBus.$emit('snackbar:success', 'Email sent successfully!')
    } catch {
      EventBus.$emit('snackbar:error', 'Error sending email.')
    }
  }

  async handleSendTripCancellationPreviewEmail(
    user: UserDetail,
    note: string
  ): Promise<void> {
    try {
      await client.sendCancellationEmail(this.reservationId, [user.email], note)
      EventBus.$emit('snackbar:success', 'Email sent successfully!')
    } catch {
      EventBus.$emit('snackbar:error', 'Error sending email.')
    }
  }

  async handleTripCancellation(): Promise<void> {
    const payload: ReservationStatusUpdate = {
      reservationIds: [this.state.reservation?.reservationId],
      reservationStatus: ReservationStatus.Cancelled,
    }
    try {
      await client.updateReservationStatus(payload)
      await this.loadReservationDetail()
      EventBus.$emit('snackbar:success', 'Trip cancelled successfully!')
    } catch {
      EventBus.$emit('snackbar:error', 'Error cancelling trip.')
    }
    sidebar.pop()
  }

  close(): void {
    reservation.clear()
    if (this.popOnClose) {
      sidebar.pop()
    } else {
      sidebar.close()
    }
  }

  addDriverMessage(message: string): void {
    const reservationComment = {
      note: message,
      reservation: {
        id: this.reservationId,
      },
    }
    client.addComment(reservationComment)
    const comment: Comment = {
      text: message,
      createdAt: new Date(),
      createdById: auth.userId,
      fullName: formatFullName(auth.user),
    }
    this.driverMessages.push(comment)
  }

  prepareDriverMessages(): void {
    this.driverMessages = this.state.reservation.reservationComments.map(
      (comment) => {
        const message: Comment = {
          text: comment.comment,
          createdAt: new Date(comment.createdOn),
          createdById: comment.userId,
          fullName: comment.userName,
        }
        return message
      }
    )
  }

  handleEditContact(options: {
    contactId: number
    contactType: ContactTypeKey
  }): void {
    sidebar.push({
      persist: true,
      component: ContactSidebarDetail,
      props: {
        userId: options.contactId,
        simple: true,
        contactType: options.contactType,
        tripId: this.state.reservation?.tripId,
      },
    })
  }

  handleChangeContact(type: string): void {
    sidebar.push({
      component: AddContactSidebar,
      persist: true,
      title: `${type} Contact`,
      props: {
        contactId: null,
        label: type,
        contactType: type,
        tripId: this.state.reservation?.tripId,
        isDispatch: true,
      },
      width: 450,
    })
  }

  handleNewContact(): void {
    sidebar.push({
      component: CreateContactSidebar,
      props: {
        companyOnly: false,
        contactType: 'Trip',
        tripId: this.state.reservation.tripId,
      },
    })
  }

  async handleUpdateContact(options: {
    contact: SimpleContact
    contactType: ContactTypeKey
    tripId: number
  }): Promise<void> {
    if (options?.contactType !== 'Trip') {
      return
    }

    try {
      await client.updateReservationTripContact(
        this.state.reservation?.reservationId,
        options.contact?.id || null
      )
    } catch {
      return
    } finally {
      this.loadReservationDetail()
    }
  }

  async loadReservationDetail(): Promise<void> {
    if (!this.reservationId) {
      return
    }
    const [response, assignments] = await Promise.all([
      client.byId(this.reservationId),
      assignmentClient.byReservationIds([this.reservationId]),
    ])
    reservation.setReservation(response.data)
    reservation.setAssignments(assignments.data.vehicleAssignments)

    const res = await tripClient.byId(response.data.tripId)
    reservation.setTrip(res.data.trip)

    // init assignment store to preload assignment data
    getSelectedDataFromResState()

    const tracking = await trackingClient.byIdsList({
      reservationId: this.reservationId,
      idsList: this.state.assignments?.map((assignment) => {
        return {
          journeyId: this.state.reservation.journeys[0].journeyId,
          vehicleId: assignment.vehicleId,
        }
      }),
    })
    reservation.setTracking(tracking.data)
    this.vehicle = this.vehicles[0] || null
    this.loading = false
    this.prepareDriverMessages()
  }

  beforeMount(): void {
    if (
      this.state?.reservation &&
      this.reservationId !== this.state?.reservation?.reservationId
    ) {
      this.state.clear()
      assignment.clearSelected()
    }
  }

  mounted(): void {
    EventBus.$on('contact:update', this.handleUpdateContact)
    EventBus.$on('contact-sidebar:update', this.handleUpdateContact)
    EventBus.$on('dispatch:contact-create', this.handleNewContact)
    EventBus.$on('refresh-reservation', this.loadReservationDetail)
  }

  beforeDestroy(): void {
    EventBus.$off('contact:update', this.handleUpdateContact)
    EventBus.$off('contact-sidebar:update', this.handleUpdateContact)
    EventBus.$off('dispatch:contact-create', this.handleNewContact)
    EventBus.$off('refresh-reservation', this.loadReservationDetail)

    this.$router.replace({ hash: '' })
  }
}
