import { render, staticRenderFns } from "./CUCheckbox.vue?vue&type=template&id=5541ce90&scoped=true"
import script from "./CUCheckbox.vue?vue&type=script&lang=ts"
export * from "./CUCheckbox.vue?vue&type=script&lang=ts"
import style0 from "./CUCheckbox.vue?vue&type=style&index=0&id=5541ce90&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5541ce90",
  null
  
)

export default component.exports