
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CUTag extends Vue {
  @Prop({ type: Boolean, default: false }) readonly dense!: boolean
  @Prop({ type: Boolean, default: false }) readonly denser!: boolean
  @Prop({ required: false, default: 'primary' }) readonly border!: string
  @Prop({ required: false, default: 'primary-10' }) readonly background!: string
  @Prop({ required: false, default: 14 }) readonly fontSize!: number
  @Prop({ required: false, default: null }) readonly lineHeight!: number
  @Prop({ required: false }) readonly tag!: string
  @Prop({ required: false }) readonly icon!: string
  @Prop({ required: false }) readonly additionalStyle!: Record<string, string>

  get paddingStyle(): Record<string, string> {
    if (this.additionalStyle) {
      return this.additionalStyle
    }
    if (this.denser) {
      return {
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingLeft: '9px',
        paddingRight: '9px',
      }
    } else if (this.dense) {
      return {
        paddingTop: '0px',
        paddingBottom: '0px',
        paddingLeft: '12px',
        paddingRight: '12px',
      }
    }
    return {
      paddingTop: '3px',
      paddingBottom: '3px',
      paddingLeft: '12px',
      paddingRight: '12px',
    }
  }
}
