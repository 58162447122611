var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex h-full"},[_c('section',{staticClass:"w-full",class:{
      'overflow-y-hidden': !_vm.attachDatePicker,
      'overflow-visible': _vm.attachDatePicker,
    }},[_c('v-data-table',_vm._b({staticClass:"v-data-table--simple v-data-table--show-select",class:{
        'v-data-table--rows-selected': !!_vm.selected.length,
        'v-data-table--simple-default': !_vm.dense,
        'v-data-table--simple-dense': _vm.dense,
        'v-data-table--error': _vm.error,
      },style:({ boxShadow: 'none' }),attrs:{"headers":_vm.tableHeaders,"items":_vm.items,"item-key":_vm.itemKey,"no-data-text":_vm.noDataText,"checkbox-color":"transparent","dense":"","disable-sort":"","disable-pagination":"","hide-default-footer":""},on:{"input":function($event){return _vm.$emit('input', $event)},"item-selected":_vm.handleItemSelected,"toggle-select-all":function($event){return _vm.$emit('toggle-select-all', $event)}},scopedSlots:_vm._u([(_vm.selected.length && _vm.bulkActions.length)?{key:"header",fn:function(){return [_c('thead',{staticClass:"position-relative w-0 h-0 top-0 left-0 z-5"},[_c('tr',{staticClass:"overwrite-row-height h-0"},[_c('th',{style:({ height: '0px', border: 'none' })},[_c('CUDataTableActionRow',{attrs:{"actions":_vm.bulkActions,"rows":_vm.selected,"simple":""}})],1)])])]},proxy:true}:(_vm.$attrs['hide-default-header'])?{key:"header",fn:function(){return _vm._l((_vm.tableHeaders),function(hd,idx){return _c('th',{key:`hd-${idx}-${hd.text}`,staticClass:"padding-x-3",style:({ height: 0, width: hd.width }),attrs:{"align":hd.align || 'start'}})})},proxy:true}:null,_vm._l((_vm.headerColumns),function(col,index){return {key:col.headerName,fn:function(props){return [(col.headerComponent)?_c('span',{key:`header-${col.headerName}-${index}`},[_c(col.headerComponent,{tag:"component"})],1):(!!props)?_c('span',{key:`header-${col.headerName}-${index}`,staticClass:"font-book font-14 text-text-gray-4 padding-r-1",class:{ 'text-transparent': _vm.selected.length && _vm.bulkActions },style:(_vm.selected.length && _vm.bulkActions ? 'opacity: 0;' : '')},[_vm._v(" "+_vm._s(props.header.text)+" ")]):_vm._e()]}}}),{key:"item",fn:function({ headers, item, index, isSelected, select }){return [_c('tr',{class:{
            'text-text-gray-1': item.isVoid,
          }},_vm._l((headers),function(hd,idx){return _c('td',{key:`row-${index}-col-${idx}`,class:{
              [`text-${hd.align}`]: hd.align,
              'cursor-pointer': _vm.clickable,
            },attrs:{"width":hd.width},on:{"click":function($event){_vm.clickable ? _vm.$emit('click', item) : () => {}}}},[(hd.value === 'data-table-select' && !_vm.disabled)?_c('CUCheckbox',{attrs:{"hide-details":"","error":_vm.error,"input-value":isSelected},on:{"change":function($event){return select($event)}}}):_vm._e(),(hd.value === '#action')?_c('div',{class:`w-full d-flex align-center justify-${
                hd.align || 'start'
              }`},[_c('CUSimpleTableActionsCell',{attrs:{"item":item,"actions":_vm.rowActions(item),"is-active":_vm.isActive(item),"space-between-actions":_vm.spaceBetweenActions},on:{"action":function($event){return _vm.$emit($event, item, index)}}})],1):(hd.type === 'component')?_c(hd.component,{tag:"component",attrs:{"item":item}}):(hd.editable && hd.type === 'date')?_c('div',{class:`w-full d-flex align-center justify-${
                hd.align || 'start'
              }`},[_c('CUDatePicker2',{staticClass:"v-input--denser margin-b-n1",style:({
                  maxWidth: hd.fieldWidth || '100%',
                  height: '38px',
                }),attrs:{"simple":"","hide-details":"","attach":_vm.attachDatePicker,"value":_vm.toDatePickerFormat(item, hd),"width":hd.fieldWidth,"disabled":!isSelected && _vm.selectToEdit,"error":item.dateError,"nudge-left":_vm.nudgeDatePickerLeft,"nudge-bottom":_vm.nudgeDatePickerBottom,"hide-icon":"","clearable":""},on:{"input":function($event){_vm.$emit(
                    'edit',
                    {
                      ...item,
                      [hd.value]: _vm.fromDatePickerFormat($event),
                    },
                    index
                  )}}})],1):(hd.type === 'toggle')?_c('div',{class:`w-full d-flex align-center justify-${
                hd.align || 'end'
              }`},[_c('v-switch',{staticClass:"margin-x-1 margin-y-0 padding-y-0 h-32",attrs:{"input-value":!!_vm.op(item, hd.value),"ripple":false},on:{"change":function($event){!!hd.onToggle ? hd.onToggle(item, index) : null}}})],1):(hd.editable && hd.valueType)?_c('div',{class:`w-full d-flex align-center justify-${
                hd.align || 'start'
              }`},[(_vm.textFieldValues[index])?_c('CUTextField',{staticClass:"v-input--denser top-5",style:({ maxWidth: hd.fieldWidth || '100%' }),attrs:{"hide-details":"","hide-spin-buttons":"","disabled":!isSelected && _vm.selectToEdit,"placeholder":hd.placeholder,"reverse":hd.reverse},on:{"blur":function($event){return _vm.handleTextFieldBlur(index, idx - 1, hd.valueType, hd.value)}},model:{value:(_vm.textFieldValues[index][idx - 1]),callback:function ($$v) {_vm.$set(_vm.textFieldValues[index], idx - 1, $$v)},expression:"textFieldValues[index][idx - 1]"}}):_vm._e()],1):(hd.editable)?_c('div',{class:`w-full d-flex align-center justify-${
                hd.align || 'start'
              }`},[_c('CUTextField',{staticClass:"v-input--denser top-5",style:({ maxWidth: hd.fieldWidth || '100%' }),attrs:{"value":_vm.op(item, hd.value),"hide-details":"","hide-spin-buttons":"","type":hd.type || typeof _vm.op(item, hd.value),"disabled":!isSelected && _vm.selectToEdit,"placeholder":hd.placeholder,"reverse":hd.reverse,"error":hd.showError && item.error},on:{"input":function($event){return _vm.$emit('edit', { ...item, [hd.value]: $event }, index)}}})],1):_c('span',{class:`${_vm.columnStyle(item, hd)} ${_vm.cellStyle(item)}`,on:{"click":function($event){!!hd.onClick ? hd.onClick(item, index) : null}}},[_vm._v(" "+_vm._s(_vm.cellValue(item, hd) || hd.placeholder)+" ")])],1)}),0)]}},(_vm.borderTop)?{key:"top",fn:function(){return [_c('v-divider',{staticClass:"border-gray-border-light"})]},proxy:true}:null,(_vm.borderBottom)?{key:"footer",fn:function(){return [_c('v-divider',{staticClass:"border-gray-border-light"})]},proxy:true}:null,_vm._l((_vm.$scopedSlots),function(_,slot){return {key:slot,fn:function(){return [_vm._t(slot)]},proxy:true}})],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},'v-data-table',_vm.$attrs,false))],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }