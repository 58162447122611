export default {
  success: {
    charterUpIntegration: 'CharterUP Integration Successful!',
    charterUpIntegrationDisabled: 'CharterUP Integration Successfully Removed!',
    charterUpRateCreated: 'Rate created successfully!',
    charterUpRateDeleted: 'Rate deleted successfully!',
    charterUpRateUpdated: 'Rate updated successfully!',
    charterUpSync: 'Data synced successfully!',
    charterUpSyncReferrals: 'Referrals synced successfully!',
    markupCreated: 'Markup created successfully!',
    customChargeCreated: 'Custom charge created successfully!',
    taxCreated: 'Tax created successfully!',
    garageDeleted: 'Garages deleted successfully!',
    industriesDeleted: 'Industries deleted successfully!',
    emailSent: 'Email sent successfully!',
  },
  errors: {
    charterUpIntegration:
      'CharterUP integration failed. Please check your credentials and try again.',
    charterUpIntegrationDisabled: 'CharterUP Integration Removal Failed!',
    charterUpRateCreated: 'Rate creation failed!',
    charterUpRateDeleted: 'Rate deletion failed!',
    charterUpRateUpdated: 'Rate update failed!',
    charterUpSync: 'Data sync failed!',
    charterUpSyncReferrals: 'Referral sync failed!',
    markupCreated: 'Markup creation failed!',
    customChargeCreated: 'Custom charge creation failed!',
    taxCreated: 'Tax creation failed!',
    conflictingVehicleRate:
      'A rate for this vehicle or vehicle type already exists. Delete the existing rate before adding a new one.',
    defaultTermsRemoval:
      'The default terms cannot be removed. Please select a new default and try again.',
    industriesDeleted:
      'There was an error deleting some or all of the selected industries.',
  },
  holdup: {
    charterUpSync: 'Are you sure you want to sync your data?',
  },
  partial: {
    charterUpSync: 'Data synced with some failures. Please retry.',
  },
}
