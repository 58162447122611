export const loadCDN = (url) => {
  const scriptTag = document.createElement('script')
  scriptTag.src = url
  document.body.appendChild(scriptTag)
}

export const loadPaymentCDNs = () => {
  const host = window.location.host
  const isProduction = host === 'app.busify.com'

  let authNetCDNScript
  let squareCDNScript
  if (isProduction) {
    authNetCDNScript = 'https://js.authorize.net/v1/Accept.js'
    squareCDNScript = 'https://web.squarecdn.com/v1/square.js'
  } else {
    authNetCDNScript = 'https://jstest.authorize.net/v1/Accept.js'
    squareCDNScript = 'https://sandbox.web.squarecdn.com/v1/square.js'
  }

  loadCDN(authNetCDNScript)
  loadCDN(squareCDNScript)
}
