
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Stop } from '@/models/dto'
import GmapCustomMarker from 'vue2-gmap-custom-marker'
import { gmapApi } from 'vue2-google-maps'
import { busifyMapStyles, defaultMapStyles } from '@/data/mapStyles'
import colors from '@/scss/_colors-export.scss'
import { TrackingResponse } from '@/models/dto/Tracking'
import TrackingIcon from '@/assets/images/TrackingIcon.vue'

@Component({
  components: {
    GmapCustomMarker,
    TrackingIcon,
  },
})
export default class ReservationTrackingMap extends Vue {
  @Prop({ required: true }) stops: Stop[]
  @Prop({ required: false }) vehicles: {
    label: string
    id: number
    event: string
  }[]
  @Prop({ required: false, default: null }) currentStop: Stop
  @Prop({ required: false, default: null }) selectedVehicle: {
    label: string
    id: number
    event: string
  }
  @Prop({ required: false }) tracking: TrackingResponse
  @Prop({ required: false, default: '400px' }) readonly height: string
  @Prop({ required: false, default: null }) readonly color: string
  @Prop({ type: Boolean, default: false }) hideRoute: boolean
  @Prop({ type: Boolean, default: false }) hideTracking: boolean
  @Prop({ type: Boolean, default: false }) hideZoomButtons: boolean
  @Prop({ type: Boolean, default: false }) hideStyles: boolean
  @Prop({ type: Boolean, default: false }) hideRounded: boolean
  @Prop({ type: Boolean, default: false }) hideVehicles: boolean
  @Prop({ type: Boolean, default: false }) showBus: boolean
  @Prop({ type: Boolean, default: false }) lineRoute: boolean
  @Prop({ type: Boolean, default: false }) widget: boolean
  @Prop({ type: Boolean, default: false }) mobile: boolean
  @Prop({ type: Boolean, default: false }) useCache: boolean
  @Prop({ type: Boolean, default: false }) isShown: boolean

  vehicle: number = null
  busifyMapStyles = busifyMapStyles
  defaultMapStyles = defaultMapStyles
  trackingPoints = []
  trackingMarkers = []
  paths = []
  persistentPaths = []
  updatingLocation = false
  firstFlag = true
  cacheStops = []

  @Watch('vehicles')
  @Watch('selectedVehicle')
  updateVehicle(): void {
    if (this.selectedVehicle) {
      this.vehicle = this.selectedVehicle.id
    } else {
      this.vehicle = this.vehicles?.length ? this.vehicles[0].id : null
    }
    this.trackingMarkers = !this.hideTracking
      ? this.trackingPoints?.find((n) => n.id === this.vehicle)?.points || []
      : []
  }

  @Watch('selectedVehicle.id')
  handleUpdatedVehicleId(): void {
    if (this.showBus) {
      this.setTrackingBusBounds()
    }
  }

  @Watch('tracking')
  updateTracking(): void {
    this.updateTrackingPoints()
  }

  @Watch('mapStops')
  handleUpdateStops(oldStops: Stop[], newStops: Stop[]): void {
    if (oldStops === newStops && this.useCache) {
      return
    }
    this.clearMapPaths()
    this.$nextTick(() => {
      this.setBounds()
      this.getRoute()
    })
  }

  get vehicleIndex(): number {
    return this.vehicles?.findIndex((v) => v.id === this.vehicle) || 0
  }

  get google(): any {
    return gmapApi()
  }

  get ref(): any {
    return this.$refs.mapRef
  }

  get mapStops(): Stop[] {
    if (!this.useCache) {
      return this.stops
    }

    const simplified = this.stops.map(({ address }) => new Stop({ address }))
    const areAddressesEqual =
      simplified.every(
        (stop, i) =>
          stop.address?.lat === this.cacheStops?.[i]?.address?.lat &&
          stop.address?.lng === this.cacheStops?.[i]?.address?.lng
      ) && simplified.length === this.cacheStops.length
    if (!this.isShown || areAddressesEqual) {
      return this.cacheStops
    }

    this.cacheStops = simplified
    return simplified
  }

  get stopMarkers(): { lat: number; lng: number; angle: number }[] {
    if (this.useCache && !this.isShown) {
      return []
    }
    return this.mapStops
      ?.filter((s) => !!s.address)
      .reduce((arr, stop) => {
        const filteredArr = arr.filter(
          (el) => el.lat === stop.address.lat && el.lng === stop.address.lng
        )
        arr.push({
          lat: stop.address.lat,
          lng: stop.address.lng,
          angle: filteredArr.length ? 30 + (filteredArr.length - 1) * 60 : 0,
        })
        if (filteredArr.length === 1) {
          arr[arr.indexOf(filteredArr[0])].angle = -30
        }
        return arr
      }, [])
  }

  get trackingBus(): { lat: number; lng: number; angle: number } {
    const points = this.trackingPoints?.find(
      ({ id }) => id === this.selectedVehicle?.id
    )?.points
    if (!this.selectedVehicle?.id || !this.google?.maps?.geometry || !points) {
      if (this.google?.maps?.geometry) {
        this.setTrackingBusBounds()
      }
      return null
    }

    const lastPoint = points[points.length - 1]
    const prevPoint =
      points.length > 1 ? points[points.length - 2] : this.stopMarkers[0]
    const a = new this.google.maps.LatLng(prevPoint.lat, prevPoint.lng)
    const b = new this.google.maps.LatLng(lastPoint?.lat, lastPoint?.lng)
    const angle = this.google.maps.geometry.spherical.computeHeading(a, b)

    if (this.firstFlag) {
      this.setTrackingBusBounds()
    }

    const lat = lastPoint.lat
    const lng = lastPoint.lng
    return { lat, lng, angle }
  }

  async setTrackingBusBounds(): Promise<void> {
    const map = await this.ref.$mapPromise
    const bounds = new this.google.maps.LatLngBounds()
    if (this.trackingBus) {
      const bus = new this.google.maps.LatLng(
        this.trackingBus.lat,
        this.trackingBus.lng
      )
      bounds.extend(bus)
    }

    const currAddr = this.currentStop?.address
    if (currAddr) {
      const curr = new this.google.maps.LatLng(currAddr.lat, currAddr.lng)
      bounds.extend(curr)
    }

    const nextAddr =
      this.currentStop?.orderIndex === 0 ? this.mapStops[1]?.address : null
    if (nextAddr) {
      const next = new this.google.maps.LatLng(nextAddr.lat, nextAddr.lng)
      bounds.extend(next)
    }

    this.firstFlag = false
    map.fitBounds(bounds)
  }

  async getRoute(): Promise<void> {
    let points = null
    const map = await this.ref.$mapPromise

    const bounds = new this.google.maps.LatLngBounds()
    const firstStopMarker = this.stopMarkers[0]
    const lastStopMarker = this.stopMarkers[this.stopMarkers.length - 1]
    if (!firstStopMarker || !lastStopMarker) {
      return
    }
    const directionsRequest = {
      origin: new this.google.maps.LatLng(
        firstStopMarker.lat,
        firstStopMarker.lng
      ),
      destination: new this.google.maps.LatLng(
        lastStopMarker.lat,
        lastStopMarker.lng
      ),
      travelMode: 'DRIVING',
      waypoints: [],
    }
    if (this.stopMarkers.length > 2) {
      directionsRequest.waypoints = this.stopMarkers
        .slice(1, -1)
        .map((stop) => {
          const stopLocation = new this.google.maps.LatLng(stop.lat, stop.lng)
          bounds.extend(stopLocation)
          return {
            location: stopLocation,
            stopover: true,
          }
        })
    }
    bounds.extend(firstStopMarker)
    bounds.extend(lastStopMarker)

    if (!this.persistentPaths?.length) {
      const directionsService = new this.google.maps.DirectionsService()
      await directionsService.route(directionsRequest, (response, status) => {
        if (status === 'OK') {
          const routeData = response.routes[0]?.overview_path
          points = routeData.map((point) => {
            return {
              lat: point.lat(),
              lng: point.lng(),
              angle: 0,
            }
          })
          this.plotMapPoints(map, points, 'bg-gray-4', this.showBus)
        }
      })
    }
    this.updateTrackingPoints()
  }

  updateTrackingPoints(): void {
    if (this.tracking?.trackingJourneyDataList) {
      const skip = Math.ceil(
        this.tracking.trackingJourneyDataList
          .map(({ gpsData }) => gpsData)
          .flat().length / 500
      )
      const points = this.tracking.trackingJourneyDataList
        .filter(({ vehicleId }) => !!vehicleId)
        .reduce((arr, dataList) => {
          const skippedGpsPoints = []
          for (let i = 0; i < (dataList?.gpsData || []).length; i += skip) {
            const point = dataList.gpsData[i]
            skippedGpsPoints.push({
              lat: parseFloat(point.lat),
              lng: parseFloat(point.lng),
              angle: 0,
            })
          }
          if (
            dataList?.gpsData?.length &&
            (dataList.gpsData.length - 1) % skip !== 0
          ) {
            skippedGpsPoints.push({
              lat: parseFloat(
                dataList.gpsData[dataList.gpsData.length - 1].lat
              ),
              lng: parseFloat(
                dataList.gpsData[dataList.gpsData.length - 1].lng
              ),
              angle: 0,
            })
          }
          const idx = arr.findIndex(({ id }) => id === dataList.vehicleId)
          if (idx !== -1) {
            arr[idx] = { id: dataList.vehicleId, points: skippedGpsPoints }
          } else {
            arr.push({ id: dataList.vehicleId, points: skippedGpsPoints })
          }
          return arr
        }, [])

      const isUpdated =
        points?.find(({ id }) => id === this.vehicle)?.points?.length !==
        this.trackingPoints?.find(({ id }) => id === this.vehicle)?.points
          ?.length

      if (isUpdated) {
        this.updatingLocation = true
        setTimeout(() => (this.updatingLocation = false), 600)
      }

      this.trackingPoints = points
      this.trackingMarkers = !this.hideTracking
        ? this.trackingPoints?.find((n) => n.id === this.vehicle)?.points || []
        : []
    }
  }

  plotMapPoints(
    map: any,
    path: { lat: number; lng: number; angle: number }[],
    color = 'bg-gray-4',
    persist = false
  ): void {
    const lineSymbol = {
      path: this.google.maps.SymbolPath.CIRCLE,
      fillOpacity: 1,
      scale: 4,
    }
    const line = new this.google.maps.Polyline({
      map,
      path,
      geodesic: true,
      strokeColor: this.lineRoute ? colors['secondary'] : colors[color],
      strokeOpacity: this.lineRoute ? 1 : 0,
      icons: this.lineRoute
        ? null
        : [
            {
              icon: lineSymbol,
              offset: '0',
              repeat: '10px',
            },
          ],
    })
    if (persist) {
      this.persistentPaths.push(line)
    } else {
      this.paths.push(line)
    }
  }

  clearMapPaths(): void {
    this.paths.map((path) => {
      path.setMap(null)
    })
    this.paths = []
  }

  handleZoomIn(): void {
    this.ref.$mapObject.setZoom(this.ref.$mapObject.getZoom() + 1)
  }

  handleZoomOut(): void {
    this.ref.$mapObject.setZoom(this.ref.$mapObject.getZoom() - 1)
  }

  async setBounds(): Promise<void> {
    if (this.showBus) {
      return
    }
    const map = await this.ref.$mapPromise

    const bounds = new this.google.maps.LatLngBounds()
    this.mapStops
      .filter((s) => !!s.address)
      .map((stop) => {
        const tempStop = { lat: stop.address.lat, lng: stop.address.lng }
        bounds.extend(tempStop)
      })
    map.fitBounds(bounds)
  }

  mounted(): void {
    this.vehicle = this.vehicles?.length ? this.vehicles[0].id : null
    this.setBounds()
    if (!this.hideRoute) {
      this.getRoute()
    }
  }
}
