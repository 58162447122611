
import { Component, Prop, Vue } from 'vue-property-decorator'
import ErrorLabel from '@/components/ErrorLabel.vue'

@Component({
  components: { ErrorLabel },
})
export default class CUSnackbar extends Vue {
  @Prop({ type: Boolean, default: false }) readonly value!: boolean
  @Prop({ type: Boolean, default: false }) readonly error!: boolean
  @Prop({ required: false, default: '' }) readonly message!: string
  @Prop({ required: false, default: '' }) readonly color!: string
  @Prop({ required: false, default: -1 }) readonly timeout!: number

  get backgroundColor(): string {
    if (this.color) {
      return this.color
    }
    return this.error ? 'background-additional-red-2' : 'background-green-10'
  }
}
