import {
  isFutureDate,
  isNotEmpty,
  isNotEmptyInput,
  isValidAddress,
  isValidEmail,
  verifyPhoneLength,
} from '@/utils/validators'
import { Address } from '@/models/dto'

export type ValidationRule<T> = (type: T) => boolean | string
const validationPass = (): ValidationRule<unknown> => () => true

export const rules = {
  required: (): ValidationRule<unknown>[] => [validationPass()],
  email: (): ValidationRule<unknown>[] => [validationPass()],
  address: (): ValidationRule<unknown>[] => [validationPass()],
  length: (): ValidationRule<unknown>[] => [validationPass()],
  phoneNumber: (): ValidationRule<unknown>[] => [validationPass()],
  futureDate: (): ValidationRule<unknown>[] => [validationPass()],
}

export const validationRules = {
  required: (message: string): ValidationRule<string>[] => [
    (value: string) => isNotEmptyInput(value) || message,
  ],
  email: (message: string): ValidationRule<string>[] => [
    (value: string) => isValidEmail(value) || message,
  ],
  address: (message: string): ValidationRule<Address>[] => [
    (value: Address) => isValidAddress(value) || message,
  ],
  length: (length: number, message: string): ValidationRule<string>[] => [
    (value: string) => (value || '').length <= length || message,
  ],
  phoneNumber: (message: string): ValidationRule<string>[] => [
    (value: string) =>
      (isNotEmpty(value) && verifyPhoneLength(value)) || message,
  ],
  futureDate: (
    date: string,
    m1: string,
    m2: string
  ): ValidationRule<string>[] => [
    (value: string) =>
      isNotEmptyInput(value) ? isFutureDate(date, value) || m2 : m1,
  ],
}

const validate = (self) => {
  return new Promise((resolve) => {
    self.$nextTick(() => {
      resolve(self.$refs.form['validate']())
    })
  })
}

export const validateRules = async (self): Promise<boolean> => {
  self.rules = validationRules
  const res = await validate(self)
  if (!res) {
    self.rules = rules
    return false
  }
  return true
}
