import dayjs, { OpUnitType } from 'dayjs'
import auth from '@/store/modules/auth'
// import advancedFormat from 'dayjs/plugin/advancedFormat'
// dayjs.extend(advancedFormat)

export const isBeforeInLocalTime = (
  dateA: string,
  dateB: string,
  timezone: string,
  unit: OpUnitType = 'second'
): boolean => {
  const a = dayjs(dateA).tz(timezone)
  const b = dayjs(dateB).tz(timezone)
  return a.isBefore(b, unit)
}

export const isoToHourMinute = (
  date: string,
  timeZone: string = null,
  showTz = false
): string => {
  let clock = dayjs(date)
  if (timeZone) {
    clock = clock.tz(timeZone)
  }
  return clock.format(showTz ? 'h:mma z' : 'h:mma')
}

export const isoToHourMinuteMeridiemTimeZone = (
  date: string,
  timeZone: string
): string => {
  const clock = dayjs(date).tz(timeZone)
  return clock.format('h:mm A z')
}

export const displayInLocalTimezone = (date: string): string => {
  const userTimezone = dayjs.tz.guess()
  const dateFormat = auth.shouldDisplayDayLeading ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
  return dayjs.tz(date, userTimezone).format(`${dateFormat} h:mm A z`)
}

export const displayDateRangeWithDayOfWeek = (
  start: string,
  end: string
): string => {
  const dateFormat = auth.shouldDisplayDayLeading
    ? 'dddd, DD/MM/YY'
    : 'dddd, MM/DD/YY'
  const startFormatted = dayjs(start).format(dateFormat)
  const endFormatted = dayjs(end).format(dateFormat)

  return `${startFormatted} - ${endFormatted}`
}

export const displayInLocalTimezoneWithDot = (
  date: string,
  smallYearFormat = false,
  timeZone: string = null,
  hideTz = false,
  hideDot = false
): string => {
  if (!date) {
    return ''
  }

  const dateTime = dayjs(date)

  const format = smallYearFormat
    ? `MM/DD/YY ${hideDot ? '' : '⸱ '}hh:mm A ${hideTz ? '' : 'z'}`
    : `MM/DD/YYYY ${hideDot ? '' : '⸱ '}hh:mm A ${hideTz ? '' : 'z'}`

  if (!timeZone || timeZone === 'Z') {
    return dateTime.utc().format(format)
  }

  if (!timeZone) {
    return dateTime.format(format)
  }

  // Check if timeZone is in offset format
  const offsetRegex = /^([+-])(\d{2}):(\d{2})$/
  if (offsetRegex.test(timeZone)) {
    // Convert the dateTime into UTC and then use utcOffset
    const [_, sign, hours, minutes] = timeZone.match(offsetRegex)
    const totalMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10)
    const offsetMinutes = sign === '+' ? totalMinutes : -totalMinutes
    return dateTime.utc().utcOffset(offsetMinutes).format(format)
  } else {
    // It's a timezone string
    return dateTime.tz(timeZone).format(format)
  }
}

export const isoToDatetime = (
  date: string,
  fullMonthAndDay = false,
  timeZone: string = null
): string => {
  if (!dayjs(date).isValid()) {
    return ''
  }
  const format = fullMonthAndDay ? 'MM/DD/YY h:mm A' : 'M/D/YY h:mm A'

  if (timeZone) {
    return dayjs(date).tz(timeZone).format(format)
  }
  return dayjs(date).format(format)
}

export const isoToDayYearAtTime = (
  date: string,
  timeZone: string = null
): string => {
  if (!dayjs(date).isValid()) {
    return ''
  }
  let calendar = dayjs(date)
  if (timeZone) {
    calendar = calendar.tz(timeZone)
  }
  return `${calendar.format('MMM D, YYYY')} at ${calendar.format('h:mm A z')}`
}

export const isoToJSDate = (date: string): Date => {
  return dayjs(date, 'YYYY-MM-DD').toDate()
}

export const jsDateToISO = (date: Date): string => {
  return dayjs(date).format('YYYY-MM-DD')
}

export const isoToMDY = (date: string): string => {
  const day = dayjs(date, 'YYYY-MM-DD')
  return day.isValid() ? day.format('M/D/YY') : ''
}

export const isoToShortMonthYear = (date: string): string => {
  const day = dayjs(date, 'YYYY-MM-DD')
  return day.isValid() ? day.format("MMM 'YY") : ''
}

export const isoToShortMonthDay = (date: string): string => {
  const day = dayjs(date, 'YYYY-MM-DD')
  return day.isValid() ? day.format('MMM D') : ''
}

export const monthName = (date: string): string => {
  return !dayjs(date).isValid() ? '' : dayjs(date).format('MMMM')
}

export const isoToDate = (date: string | dayjs.Dayjs): string => {
  return !dayjs(date).isValid() ? '' : dayjs(date).format('YYYY-MM-DD')
}

export const isoToDateAndTime = (date: string | dayjs.Dayjs): string => {
  return !dayjs(date).isValid() ? '' : dayjs(date).format('YYYY-MM-DD HH:mm')
}

export const isSameDay = (
  fst: string | dayjs.Dayjs,
  snd: string | dayjs.Dayjs
): boolean => {
  return isoToDate(fst) === isoToDate(snd)
}

export const isInvalidRange = (
  fst: string | dayjs.Dayjs,
  snd: string | dayjs.Dayjs
): boolean => {
  const start = dayjs(fst)
  const end = dayjs(snd)
  return end.isBefore(start)
}

export const isInDateRange = (
  start: string | dayjs.Dayjs,
  end: string | dayjs.Dayjs,
  date: string | dayjs.Dayjs = dayjs()
): boolean => {
  return (
    !isInvalidRange(start, end) &&
    dayjs(start).isSameOrBefore(dayjs(date)) &&
    dayjs(end).isSameOrAfter(dayjs(date))
  )
}

export const redate = (isoDate: string, isoNewDate: string): string => {
  if (!isoDate || !isoNewDate) {
    return ''
  }
  const newDate = dayjs(isoNewDate)
  return dayjs(isoDate)
    .set('year', newDate.year())
    .set('month', newDate.month())
    .set('date', newDate.date())
    .toISOString()
}

export const indexToWeekday = (index: number): string => {
  const weekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]
  return weekdays[index]
}

export const isAfterTodayTz = (date: string, tz: string): boolean => {
  if (!date || !tz) {
    return false
  }
  const dateWithoutTz = date.split('+')[0]
  const todayAsString = isoToDatetime(dayjs().format(), false, tz)
  return dayjs(isoToDatetime(dateWithoutTz)).tz(tz).isAfter(todayAsString)
}

export const isBeforeTodayTz = (date: string, tz: string): boolean => {
  if (!date || !tz) {
    return false
  }
  const dateWithoutTz = date.split('+')[0]
  const todayAsString = isoToDatetime(dayjs().format(), false, tz)
  return dayjs(isoToDatetime(dateWithoutTz)).tz(tz).isBefore(todayAsString)
}
