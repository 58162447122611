
import { Model, Prop, Watch, Vue, Component } from 'vue-property-decorator'

@Component
export default class CUModal extends Vue {
  @Model('input') readonly value!: boolean | null
  @Prop({
    type: String,
    required: false,
    default: '500px',
  })
  width!: string
  @Prop({
    type: String,
    required: false,
    default: '500px',
  })
  maxWidth!: string
  @Prop({
    type: Boolean,
    default: false,
  })
  rounded!: boolean
  @Prop({
    type: Boolean,
    default: false,
  })
  simpleHeader!: boolean
  @Prop({
    required: false,
    default: 'center',
  })
  justifyTitle!: string

  @Watch('isOpen')
  isDialogOpenChanged(value: boolean): void {
    this.$emit('input', value)
  }
  @Watch('value', { immediate: true })
  valueChanged(value: boolean): void {
    this.isOpen = value
  }

  isOpen = false
}
