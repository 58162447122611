import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import { routes } from './routes'
import auth from '@/store/modules/auth'
import app from '@/store/modules/app'
import sidebar from '@/store/modules/sidebar'
import { UserGroupIds } from '@/data/userGroups'
import { baseUrl } from '@/utils/env'

// Suppresses a console error in tests
if (!process || process.env.NODE_ENV !== 'test') {
  Vue.use(VueRouter)
}
Vue.use(VueMeta)

const publicPageNames = ['widget', 'tracking', 'public-terms']

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (from.path !== to.path) {
    sidebar.close()
  }

  app.saveLastRoute(from)
  if (auth.getUserId) {
    auth.refreshUser()
    app.fetchSystemParameters()
  }
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  if (requiresAuth && !auth.getIsTokenSet) {
    next({ name: 'login' })
  } else if (requiresAuth && auth.getIsDriverOnly) {
    next({ name: 'download-app' })
  } else if (
    to.name.includes('admin') &&
    auth.getUser?.groupId !== UserGroupIds.ADMIN
  ) {
    next({ name: 'not-found' })
  } else if (
    window.location.host.split('.')[0] === 'customers' &&
    !publicPageNames.includes(to.name)
  ) {
    window.location.href = `https://${baseUrl()}${to.fullPath || '/'}`
  } else {
    next()
  }
})

export default router
