import { AxiosResponse } from 'axios'
import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { TrackingRequest, TrackingResponse } from '@/models/dto/Tracking'

const httpService: HttpService = new HttpService()

export default {
  byIdsList(params: TrackingRequest): Promise<AxiosResponse<TrackingResponse>> {
    return httpService.post(`https://${apiBaseUrl()}/tracking/data`, params)
  },
  byReservationHash(hash: string): Promise<AxiosResponse<TrackingResponse>> {
    return httpService.get(`https://${apiBaseUrl()}/tracking/data/${hash}`)
  },
}
