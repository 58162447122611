import { Vehicle, VehicleType } from '@/models/dto/Vehicle'
import dayjs from 'dayjs'

export interface AssignmentTotals {
  numResWithUnassignedDrivers: number
  numResWithUnassignedVehicles: number
  numUnassignedReservations: number
  numDraftAssignments: number
}

export interface AssignmentsVehicleBlock extends Vehicle {
  isVehicleType: boolean
  vehicleId: number
  vehicleType: VehicleType
  dummy: boolean
  collapsed: boolean
  posRowBlocks: AssignmentsRowBlock[]
  hasConflict: boolean
}

export interface AssignmentsDriverBlock {
  driverName: string
  email: string
  userId: number
  top: number
  posRowBlocks: AssignmentsRowBlock[]
  hasConflict: boolean
}

export interface AssignmentsRowBlock {
  reservationId: number
  managedId: string
  vehicleId: number
  vehicleTypeId: number
  vehicleAssignmentIndex?: number
  driverAssignmentIndex?: number
  driverDisplayAssignmentIndex?: number
  top: number
  start: number
  end: number
  startDatetime: string
  startTimeZone: string
  endDatetime: string
  endTimeZone: string
  startState: string
  startCity: string
  endState: string
  endCity: string
  conflict: { start: number; end: number } | null
  isConfirmed: boolean
  isTimeoff: boolean
  userId?: number
  drivers: { driverName: string; isConfirmed: boolean; userId: number }[]
}

// eslint-disable-next-line no-redeclare
export class AssignmentsVehicleBlock implements AssignmentsVehicleBlock {
  constructor(assignmentsVehicleBlock?: Partial<AssignmentsVehicleBlock>) {
    this.collapsed = false
    Object.assign(this, assignmentsVehicleBlock)
  }
}

export type AssignmentsViewMode = 'bus' | 'driver'

export type TimelineScaleOptionsType =
  | '12 hours'
  | '24 hours'
  | '3 days'
  | '7 days'

export const TimelineScaleOptions: Record<string, TimelineScaleOptionsType> = {
  HALFDAY: '12 hours',
  DAY: '24 hours',
  HALFWEEK: '3 days',
  WEEK: '7 days',
}

export const TimelineScaleOptionsValues: Record<string, number> = {
  HALFDAY: 1950,
  DAY: 930,
  HALFWEEK: 288,
  WEEK: 175,
}

export const TimelineScaleOptionDays: Record<string, number> = {
  HALFDAY: 1,
  DAY: 2,
  HALFWEEK: 4,
  WEEK: 7,
}

export const TimelineScaleOptionZeroOffset: Record<string, number> = {
  HALFDAY: 121,
  DAY: 135,
  HALFWEEK: 160,
  WEEK: 24,
}
