import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Vehicle } from '@/models/dto/Vehicle'
import {
  ApiResult,
  DeleteVehiclePhotoPayload,
  VehicleDetailEntity,
  VehiclePhotoDTO,
  VehicleTimeOff,
} from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<Vehicle>>> {
    const { sorts = null, filters = null, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = apiBaseUrl()

    return httpService.get(`https://${host}/tables/vehicles?${query}`)
  },
  byId(vehicleId: number): Promise<AxiosResponse<VehicleDetailEntity>> {
    return httpService.get(`https://${apiBaseUrl()}/v2/vehicles/${vehicleId}`)
  },
  create(vehicle: VehicleDetailEntity): Promise<AxiosResponse<number>> {
    return httpService.post(`https://${apiBaseUrl()}/v2/vehicles`, vehicle)
  },
  update(vehicle: VehicleDetailEntity): Promise<AxiosResponse<void>> {
    return httpService.put(
      `https://${apiBaseUrl()}/v2/vehicles/${vehicle.vehicleId}`,
      vehicle
    )
  },
  delete(vehicleId: number): Promise<AxiosResponse<ApiResult>> {
    return httpService.delete(
      `https://${apiBaseUrl()}/v2/vehicles/${vehicleId}`
    )
  },
  makeVehiclePhotoDefault(
    vehicleId: number,
    vehiclePhoto: VehiclePhotoDTO
  ): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/vehicles/${vehicleId}/updateVehiclePhoto`
    return httpService.patch(url, vehiclePhoto)
  },
  deleteVehiclePhotos(
    vehicleId: number,
    payload: DeleteVehiclePhotoPayload
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${apiBaseUrl()}/vehicles/${vehicleId}/removeVehiclePhotos`
    return httpService.delete(url, {
      data: payload,
    })
  },
  deletePhotos(
    vehicleId: number,
    vehiclePhotos: DeleteVehiclePhotoPayload
  ): Promise<AxiosResponse<boolean>> {
    const url = `https://${apiBaseUrl()}/v2/photos/vehicles/${vehicleId}/vehiclePhotos/remove`
    return httpService.post<boolean, DeleteVehiclePhotoPayload>(
      url,
      vehiclePhotos
    )
  },
  getTimeOff(vehicleId: number): Promise<AxiosResponse<VehicleTimeOff[]>> {
    const url = `https://${apiBaseUrl()}/v2/vehicles/${vehicleId}/timeoff`
    return httpService.get(url)
  },
  addTimeOff(
    vehicleId: number,
    vehicleTimeOff: VehicleTimeOff,
    conflictOverride: boolean
  ): Promise<AxiosResponse<boolean>> {
    const url = `https://${apiBaseUrl()}/v2/vehicles/${vehicleId}/timeoff?conflictOverride=${conflictOverride}`
    return httpService.post(url, vehicleTimeOff)
  },
  editTimeOff(
    vehicleId: number,
    vehicleTimeOff: VehicleTimeOff,
    conflictOverride: boolean
  ): Promise<AxiosResponse<boolean>> {
    const url = `https://${apiBaseUrl()}/v2/vehicles/${vehicleId}/timeoff?conflictOverride=${conflictOverride}`
    return httpService.put(url, vehicleTimeOff)
  },
  deleteTimeOff(
    vehicleId: number,
    vehicleTimeOffId: number
  ): Promise<AxiosResponse<boolean>> {
    const url = `https://${apiBaseUrl()}/v2/vehicles/${vehicleId}/timeoff/${vehicleTimeOffId}`
    return httpService.delete(url)
  },
  getTimeOffByDateRange(
    startDate: string,
    endDate: string
  ): Promise<AxiosResponse<VehicleTimeOff[]>> {
    const url = `https://${apiBaseUrl()}/v2/vehicles/timeoff?start=${startDate}&end=${endDate}`
    return httpService.get(url)
  },
}
