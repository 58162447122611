var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"position-relative flex-grow-1 transition-duration-300 z-5",class:{
    'background-secondary-light': !_vm.hideStyles,
    'background-gray-bg': _vm.hideStyles,
    'hide-map-rounded': _vm.hideRounded,
    'rounded-xl': !_vm.hideRounded,
    'widget-map': _vm.widget,
  },style:({ height: _vm.height })},[_c('GmapMap',{ref:"mapRef",staticClass:"h-full w-full",attrs:{"center":{ lat: 33.75, lng: -84.39 },"options":{
      streetViewControl: false,
      fullscreenControl: !_vm.mobile && !_vm.showBus,
      fullscreenControlOptions: {
        position: _vm.google ? _vm.google.maps.ControlPosition.RIGHT_BOTTOM : null,
      },
      zoomControl: false,
      mapTypeControl: false,
      gestureHandling: _vm.mobile ? 'greedy' : 'auto',
      styles: _vm.hideStyles ? _vm.defaultMapStyles : _vm.busifyMapStyles,
    }}},[_vm._l((_vm.stopMarkers),function(marker,index){return _c('GmapCustomMarker',{key:`map-stop-marker-${marker.lat}-${index}`,attrs:{"marker":{
        lat: marker.lat,
        lng: marker.lng,
      },"z-index":999}},[_c('div',{staticClass:"position-relative",class:{
          [`rotate-${marker.angle}`]: marker.angle > 0,
          [`rotate-n${marker.angle * -1}`]: marker.angle < 0,
        },style:({ transformOrigin: 'bottom' })},[_c('CUIcon',{staticClass:"margin-a-n1",attrs:{"height":"35px","width":"27px","color":_vm.hideStyles ? 'primary' : 'green'}},[_vm._v(" map_marker ")]),_c('p',{staticClass:"position-absolute top-0 margin-a-0 text-white font-12",class:{
            'left-6': index < 10,
            'left-3': index >= 10,
            [`rotate-n${marker.angle}`]: marker.angle > 0,
            [`rotate-${marker.angle * -1}`]: marker.angle < 0,
          }},[_vm._v(" "+_vm._s(index + 1)+" ")])],1)])}),_vm._l((_vm.trackingMarkers),function(marker,index){return _c('GmapCustomMarker',{key:`map-tracking-marker-${marker.lat}-${index}`,attrs:{"marker":{ lat: marker.lat, lng: marker.lng },"offset-y":4}},[_c('div',{staticClass:"h-8 w-8 rounded-circle background-secondary"})])}),(_vm.showBus && _vm.trackingBus)?_c('GmapCustomMarker',{key:"map-tracking-bus-icon",staticClass:"position-relative",class:{
        'transition-ease transition-duration-600': _vm.updatingLocation,
      },attrs:{"marker":{ lat: _vm.trackingBus.lat, lng: _vm.trackingBus.lng }}},[_c('div',{staticClass:"position-relative",class:{
          'transition-ease transition-duration-600': _vm.updatingLocation,
        },style:({
          transformOrigin: 'bottom',
          transform: `rotate(${_vm.trackingBus.angle}deg)`,
        })},[_c('TrackingIcon',{staticClass:"position-relative top-36",attrs:{"height":"74px","width":"74px"}})],1)]):_vm._e()],2),(_vm.vehicle)?_c('CUSelect',{staticClass:"position-absolute top-12 left-16 right-16 transition-ease transition-duration-200",class:{
      'opacity-0': _vm.hideVehicles,
      'opacity-1': !_vm.hideVehicles,
      'w-176': !_vm.mobile,
    },attrs:{"items":_vm.vehicles,"item-text":"label","item-value":"id","attach":"","highlight-color":_vm.color ? 'gray' : null,"border-color":_vm.color,"label":"Vehicle"},on:{"input":function($event){return _vm.$emit('vehicle-change', _vm.vehicles[_vm.vehicleIndex])}},model:{value:(_vm.vehicle),callback:function ($$v) {_vm.vehicle=$$v},expression:"vehicle"}}):_vm._e(),(!_vm.hideZoomButtons)?_c('div',{staticClass:"position-absolute top-12 right-16 d-flex flex-column align-center justify-space-between padding-x-1 padding-y-1 margin-b-2 background-white rounded-lg border-1 border-solid border-gray-border-mid"},[_c('CUButton',{attrs:{"color":"white","height":"32px","width":"32px"},on:{"click":_vm.handleZoomIn}},[_c('CUIcon',{attrs:{"aria-label":"Zoom In","height":"22px","width":"22px","color":"gray-icon-dark"}},[_vm._v(" add ")])],1),_c('v-divider',{staticClass:"w-three-fourths margin-y-1"}),_c('CUButton',{attrs:{"color":"white","height":"32px","width":"32px"},on:{"click":_vm.handleZoomOut}},[_c('CUIcon',{attrs:{"aria-label":"Zoom Out","height":"20px","width":"20px","color":"gray-icon-dark"}},[_vm._v(" minus ")])],1)],1):_vm._e(),(_vm.showBus)?_c('div',{staticClass:"position-absolute bottom-12 right-16 d-flex flex-column align-center justify-space-between padding-x-1 padding-y-1 margin-b-2 background-white rounded-lg border-1 border-solid border-gray-border-mid transition-ease transition-duration-200",class:{
      'bottom-12': !_vm.mobile,
      'bottom-32': _vm.mobile,
      'opacity-0': _vm.hideVehicles,
      'opacity-1': !_vm.hideVehicles,
    }},[_c('CUButton',{attrs:{"color":"white","height":"32px","width":"32px"},on:{"click":_vm.setTrackingBusBounds}},[_c('CUIcon',{attrs:{"aria-label":"Center Map","height":"20px","width":"20px","color":"gray-icon-dark"}},[_vm._v(" center_map ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }