var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"position-relative"},[(_vm.isRange)?_c('div',{staticClass:"position-absolute background-bg-gray-2 z-4",style:({
      height: '237px',
      width: '1px',
      left: '249px',
      marginTop: '8px',
    }),attrs:{"role":"decorative","vertical":""}}):_vm._e(),_c('vc-date-picker',{ref:"calendar",style:(_vm.style),attrs:{"columns":_vm.columns,"step":1,"title-position":"left","attributes":_vm.attributes,"select-attribute":{
      highlight: _vm.selectedDateStyle,
    },"drag-attribute":{
      highlight: _vm.dragStyle,
    },"value":_vm.calendarValue,"is-range":_vm.isRange},on:{"input":_vm.handleInput,"dayclick":_vm.handleDayClick},scopedSlots:_vm._u([{key:"header-left-button",fn:function(){return [_c('CUIcon',{attrs:{"id":"cu-date-picker--arrow-back","aria-label":"Previous"}},[_vm._v(" keyboard_arrow_left ")])]},proxy:true},{key:"header-right-button",fn:function(){return [_c('CUIcon',{attrs:{"id":"cu-date-picker--arrow-forward","aria-label":"Next"}},[_vm._v(" keyboard_arrow_right ")])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }