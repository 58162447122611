
import { TableAction } from '@/models/TableAction'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DataTableColumn } from '@/models/DataTableColumn'
import op from 'simple-object-path'
import CUDataTableRowEditActions from '@/components/CUDataTableRowEditActions.vue'
import { EventBus } from '@/utils/eventBus'

@Component({
  components: { CUDataTableRowEditActions },
})
export default class CUDataTableCell extends Vue {
  EventBus = EventBus
  @Prop({
    type: Object,
    required: true,
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  row!: any

  @Prop({
    type: Number,
    required: true,
  })
  rowIndex!: number

  @Prop({
    type: Object,
    required: true,
  })
  column!: DataTableColumn

  @Prop({
    type: Array,
    required: false,
    default: undefined,
  })
  actions!: TableAction[]

  op = op

  @Prop({
    type: String,
    required: false,
  })
  itemKey!: string

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get cellItem(): any {
    return op(this.row, this.column.value)
  }

  get computedCellItemText(): unknown {
    if (this.column.computedText) {
      return this.column.computedText(this.row)
    }
    return this.cellItem
  }

  get slotBindProps(): Record<string, unknown> {
    return Object.assign(
      {},
      this.$attrs,
      this.$props,
      this.column.columnSlotProps ? this.column.columnSlotProps(this.row) : {}
    )
  }
}
