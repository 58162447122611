
import { Vue, Component } from 'vue-property-decorator'
import AssignmentConflictsMessages from '@/components/AssignmentConflictsMessages.vue'

@Component({
  components: {
    AssignmentConflictsMessages,
  },
})
export default class AssignmentConflictsSidebar extends Vue {
  loading = false

  submit(): void {
    this.loading = true
    this.$emit('submit')
  }
}
