import axios, { AxiosRequestConfig } from 'axios'
import auth from '@/store/modules/auth'

const hostPrefix = (prefix: string | null) => (prefix ? `${prefix}.` : '')

export const apiBaseUrl = (prefix = 'api'): string => {
  let host
  if (process.env.VUE_APP_BASE_URL_OVERRIDE) {
    host = process.env.VUE_APP_BASE_URL_OVERRIDE
  } else {
    host = window.location.host.split(':')[0]
    if (host === 'localhost') {
      host = 'dev.busify.com'
    } else {
      const env = window.location.host.split('.').slice(-3)[0]
      if (
        env === 'dev' ||
        env === 'test' ||
        env === 'stage' ||
        env === 'auto'
      ) {
        host = `${env}.busify.com`
      } else {
        // must be prod
        host = 'app.busify.com'
      }
    }
  }
  axios.defaults.baseURL = `https://${hostPrefix(prefix)}${host}`
  return `${hostPrefix(prefix)}${host}`
}

export const isDevEnvironment = (): boolean => {
  const host = window?.location?.host?.split(':')[0] || null
  return !!host.match(/dev\.busify\.com$/) || host === 'localhost'
}

export const isTestEnvironment = (): boolean => {
  const host = window?.location?.host?.split(':')[0] || null
  return !!host.match(/test\.busify\.com$/)
}

export const isAutoEnvironment = (): boolean => {
  const host = window?.location?.host?.split(':')[0] || null
  return !!host.match(/auto\.busify\.com$/)
}

export const isStageEnvironment = (): boolean => {
  const host = window?.location?.host?.split(':')[0] || null
  return !!host.match(/stage\.busify\.com$/)
}

export const isProdEnvironment = (): boolean => {
  const host = window?.location?.host?.split(':')[0] || null
  return host === 'app.busify.com'
}

export const currentBaseUrl = (): string => {
  return window.location.origin
}

export const busifyPayBaseUrl = (): string => {
  if (isDevEnvironment()) {
    return 'https://pay.dev.busify.com'
  } else if (isTestEnvironment()) {
    return 'https://pay.test.busify.com'
  } else if (isStageEnvironment()) {
    return 'https://pay.stage.busify.com'
  } else if (isAutoEnvironment()) {
    return 'https://pay.auto.busify.com'
  }
  return 'https://pay.app.busify.com'
}

export const busifyPayEnvironment = (): string => {
  return isProdEnvironment() ? 'live' : 'sandbox'
}

/** FOR LOCAL ENDPOINT TESTING ONLY **/
export const apiConfig = (config?: AxiosRequestConfig): AxiosRequestConfig => {
  const host = window.location.host.split(':')[0]
  const params = {
    actorUserId: auth.getUserId,
    actorCompanyId: auth.getUser.companyId,
    actorType: auth.getUser.groupName,
  }
  return host === 'localhost'
    ? { ...config, params: { ...params, ...config?.params } }
    : { ...config }
}

export const baseUrl = (prefixToRemove = 'api.'): string => {
  return apiBaseUrl().replace(prefixToRemove, '')
}

export const staticResource = (resourcePath?: string): string => {
  return resourcePath ? `${currentBaseUrl()}${resourcePath}` : ''
}
