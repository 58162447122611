import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { AxiosResponse } from 'axios'
import { AmenityType } from '@/models/dto/Amenity'
import { Status } from '@/models/dto/Status'
import { Severity } from '@/models/dto/Severity'
import { Priority } from '@/models/dto/Priority'
import {
  PaymentMethodType,
  PaymentType,
  TripChargeType,
  TripType,
  Type,
  VehicleType,
} from '@/models/dto'
import {
  TicketType,
  TicketSeverityType,
  TicketStatusType,
} from '@/models/dto/Ticket'
import { RecurrenceType } from '@/models/dto/Recurrence'
import { MarkupType } from '@/models/dto/Markup'
import {
  LineItemChargeType,
  LineItemSectionType,
} from '@/models/dto/LineItemCharge'
import { MarketRateType, RateType } from '@/models/dto/Rate'

const httpService: HttpService = new HttpService()

export default {
  vehicleTypeTableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<VehicleType>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const host = apiBaseUrl()
    const url = `https://${host}/tables/vehicleTypes?${query}`

    return httpService.get(url)
  },

  amenity(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<AmenityType>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const host = apiBaseUrl()
    const url = `https://${host}/tables/amenityTypes?${query}`

    return httpService.get(url)
  },
  ticket(): Promise<AxiosResponse<TicketType[]>> {
    const host = apiBaseUrl()
    const url = `https://${host}/types/ticket-types`

    return httpService.get(url)
  },
  ticketSeverity(): Promise<AxiosResponse<TicketSeverityType[]>> {
    const host = apiBaseUrl()
    const url = `https://${host}/types/ticket-severity-types`

    return httpService.get(url)
  },
  ticketStatus(): Promise<AxiosResponse<TicketStatusType[]>> {
    const host = apiBaseUrl()
    const url = `https://${host}/types/ticket-status-types`

    return httpService.get(url)
  },
  expiration(): Promise<AxiosResponse<Type[]>> {
    const host = apiBaseUrl()
    const url = `https://${host}/types/expiration-types`

    return httpService.get(url)
  },

  // TODO: Replace with API endpoint call once one exists
  statusTypes(): Promise<{ data: Status[] }> {
    return new Promise((resolve) => {
      const data: Status[] = [
        { id: 1, key: 'lead', label: 'Lead', description: '' },
        { id: 2, key: 'sent', label: 'Sent', description: '' },
        { id: 3, key: 'converted', label: 'Converted', description: '' },
        { id: 4, key: 'lost', label: 'Lost', description: '' },
        { id: 5, key: 'dont_quote', label: "Don't Quote", description: '' },
        { id: 6, key: 'sold_out', label: 'Sold Out', description: '' },
      ]
      resolve({ data })
    })
  },

  severityTypes(): Promise<AxiosResponse<Severity[]>> {
    return httpService.get(`https://${apiBaseUrl()}/types/severity-types`)
  },

  // TODO: Replace with API endpoint call once one exists
  priorityTypes(): Promise<{ data: Priority[] }> {
    return new Promise((resolve) => {
      const data: Priority[] = [
        { id: 1, key: '', label: 'None' },
        { id: 2, key: 'low', label: 'Low', description: '' },
        { id: 3, key: 'medium', label: 'Medium', description: '' },
        { id: 4, key: 'high', label: 'High', description: '' },
      ]
      resolve({ data })
    })
  },

  tripTypes(): Promise<AxiosResponse<TripType[]>> {
    return httpService.get(`https://${apiBaseUrl()}/types/trip-types`)
  },

  vehicleTypes(enabledSyncOnly = false): Promise<AxiosResponse<VehicleType[]>> {
    return httpService.get(
      `https://${apiBaseUrl()}/types/vehicle-types?enabledSyncOnly=${enabledSyncOnly}`
    )
  },

  rateTypes(): Promise<AxiosResponse<RateType[]>> {
    return httpService.get(`https://${apiBaseUrl()}/types/rate-types`)
  },

  marketRateTypes(): Promise<AxiosResponse<MarketRateType[]>> {
    return httpService.get(`https://${apiBaseUrl()}/types/market-rate-types`)
  },

  paymentTypes(): Promise<AxiosResponse<PaymentType[]>> {
    return httpService.get(`https://${apiBaseUrl()}/types/payment-types`)
  },

  paymentMethodTypes(): Promise<AxiosResponse<PaymentMethodType[]>> {
    return httpService.get(`https://${apiBaseUrl()}/types/payment-method-types`)
  },

  recurrenceTypes(): Promise<
    AxiosResponse<{ recurrenceTypes: RecurrenceType[] }>
  > {
    return httpService.get(`https://${apiBaseUrl()}/recurrenceTypes`)
  },

  tripChargeTypes(): Promise<AxiosResponse<TripChargeType[]>> {
    return httpService.get(`https://${apiBaseUrl()}/types/charge-types`)
  },

  markupTypes(): Promise<AxiosResponse<TableViewResult<MarkupType>>> {
    return httpService.get(`https://${apiBaseUrl()}/tables/markup-types`)
  },

  lineItemChargeTypes(): Promise<AxiosResponse<LineItemChargeType[]>> {
    return httpService.get(
      `https://${apiBaseUrl()}/types/line-item-charge-types`
    )
  },

  lineItemSectionTypes(): Promise<AxiosResponse<LineItemSectionType[]>> {
    return httpService.get(
      `https://${apiBaseUrl()}/types/line-item-section-types`
    )
  },
  pricingSelectionTypes(): Promise<AxiosResponse<Type[]>> {
    return httpService.get(`https://${apiBaseUrl()}/types/pricing-selection`)
  },
}
