
import { Prop } from 'vue-property-decorator'

import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
@Component({})
export default class CUDatePickerLabel extends mixins(DateMixin) {
  @Prop({ required: false, type: Boolean }) isRange!: boolean
  @Prop({ required: false, default: undefined }) predefinedValue!: string
  @Prop({ required: false, default: '' }) value!: string | string[]
  @Prop({ required: false, type: Object }) activatorListeners!: Record<
    string,
    any
  >

  get formattedDateDisplay(): string {
    if (this.predefinedValue === 'future') {
      return 'In the Future'
    } else if (this.predefinedValue === 'past') {
      return 'In the Past'
    }

    if (Array.isArray(this.value)) {
      return this.value
        .filter((d) => !!d)
        .map((date) => this.formatMediumDate(date))
        .join(' - ')
    }

    return this.formatMediumDate(this.value)
  }
}
