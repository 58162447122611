/* eslint-disable no-unused-vars */
export enum PaymentMethodKeys {
  CREDIT_CARD = 'credit_card',
  ACH = 'ach',
  CHECK = 'check',
  WIRE = 'wire',
  OTHER = 'other',
}

export enum QuotePaymentMethodIds {
  CREDIT_CARD = 1,
  ACH = 2,
  CHECK = 3,
  BILLING = 4,
  REFUND = 5,
  WIRE = 6,
  OTHER = 7,
}

export type PaymentMethodTypes =
  | 'credit_card'
  | 'ach'
  | 'check'
  | 'wire'
  | 'other'

export interface PaymentMethodType {
  id: number
  active: boolean
  key: PaymentMethodKeys
  label?: string
  description?: string
}

export interface PaymentMethod {
  id?: number
  quoteId?: number
  paymentMethodTypeId: number
  paymentMethodType: PaymentMethodType
  processingFee: number
  note: string
  isActive?: boolean
  isLinkToSettingsIntegrations?: boolean
}

export interface TripPaymentMethod {
  tripPaymentMethodId: number
  tripId: number
  paymentMethodTypeId: number
  paymentMethodType: PaymentMethodType
  isAllowed: boolean
  processingFee: number
  note: string
}

export interface DefaultCompanyPaymentMethod {
  companyId?: number
  defaultCompanyPaymentMethodId?: number
  isActive: boolean
  note?: string
  paymentMethodType: PaymentMethodType
  paymentMethodTypeId: number
  processingFee?: number
}
