import { apiBaseUrl, currentBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import {
  QuickbooksAuthLinkResult,
  QuickbooksCustomersResult,
  CompanyQuickbooksSettingsResult,
  QuickbooksAuthCallbackInfo,
  CompanyQuickbooksSettings,
  QuickbooksCustomerAssignResponse,
  QuickbooksCustomerAssignRequest,
  ContactQuickbooksDetailResponse,
  ReservationInvoiceDetailsResult,
  QuickBooksActivityLogPagedResult,
  QuickbooksTaxCodeResponse,
} from '@/models/dto/Quickbooks'
import { Result } from '@/models/dto/Result'
import { TableViewParameters } from '@/models/TableView'
import { client } from '@/services/apollo/config'
import { gql } from 'apollo-boost'
import dayjs from 'dayjs'
import auth from '@/store/modules/auth'

const httpService: HttpService = new HttpService()

const LIST_QUICKBOOKS_ACTIVITY_LOGS_QUERY = `
  query Query($page: Int, $size: Int) {
    listQuickBooksActivityLogs(page: $page, size: $size) {
      content {
        id
        companyId
        user
        description
        realmId
        createdOn
        date
        hour
      }
      last
      totalPages
      totalElements
      first
      numberOfElements
      size
      number
      empty
    }
  }
`

export default {
  getCompanyQuickbooksSettings(
    companyId: number
  ): Promise<AxiosResponse<CompanyQuickbooksSettingsResult>> {
    return httpService.get(
      `https://${apiBaseUrl()}/qbo/${companyId}/company-settings`
    )
  },
  patchCompanyQuickbooksSettings(
    companyId: number,
    companyQuickbooksSettings: Partial<CompanyQuickbooksSettings>
  ): Promise<AxiosResponse<CompanyQuickbooksSettingsResult>> {
    return httpService.patch(
      `https://${apiBaseUrl()}/qbo/${companyId}/company-settings`,
      companyQuickbooksSettings
    )
  },
  quickbooksAuthRequest(
    companyId: number
  ): Promise<AxiosResponse<QuickbooksAuthLinkResult>> {
    const baseRedirectURL = currentBaseUrl()
    return httpService.post(
      `https://${apiBaseUrl()}/qbo/${companyId}/auth-request`,
      { baseRedirectURL }
    )
  },
  assignQuickbooksCustomer(
    companyId: number,
    request: QuickbooksCustomerAssignRequest
  ): Promise<AxiosResponse<QuickbooksCustomerAssignResponse>> {
    return httpService.post(
      `https://${apiBaseUrl()}/qbo/${companyId}/customers`,
      request
    )
  },
  quickbooksAuthCallback(
    quickbooksAuthCallbackInfo: QuickbooksAuthCallbackInfo
  ): Promise<AxiosResponse<CompanyQuickbooksSettingsResult>> {
    const code = quickbooksAuthCallbackInfo.code
    const realmId = quickbooksAuthCallbackInfo.realmId
    const state = quickbooksAuthCallbackInfo.state
    return httpService.get(
      `https://${apiBaseUrl()}/qbo/auth-callback?code=${code}&state=${state}&realmId=${realmId}`
    )
  },
  getCompanyQuickbooksCustomers(
    companyId: number
  ): Promise<AxiosResponse<QuickbooksCustomersResult>> {
    return httpService.get(`https://${apiBaseUrl()}/qbo/${companyId}/customers`)
  },
  getContactQuickbooksDetail(
    companyId: number,
    contactId: number
  ): Promise<AxiosResponse<ContactQuickbooksDetailResponse>> {
    return httpService.get(
      `https://${apiBaseUrl()}/qbo/${companyId}/customers/${contactId}`
    )
  },
  async getQuickBooksActivityLogsTableView(
    params: TableViewParameters
  ): Promise<QuickBooksActivityLogPagedResult> {
    const { pageSize = 10, page = 1 } = params

    const result = await client.query({
      query: gql(LIST_QUICKBOOKS_ACTIVITY_LOGS_QUERY),
      variables: {
        page: page - 1,
        size: pageSize,
      },
      fetchPolicy: 'no-cache',
    })
    return result.data.listQuickBooksActivityLogs
  },
  createInvoiceFromReservations(
    companyId: number,
    reservationId: number
  ): Promise<AxiosResponse<Result>> {
    const request = {
      reservationId,
    }
    return httpService.post(
      `https://${apiBaseUrl()}/qbo/${companyId}/invoices`,
      request
    )
  },
  togglePaymentsAndChargesSyncForReservation(
    companyId: number,
    reservationId: number,
    toggle: boolean
  ): Promise<AxiosResponse<Result>> {
    return httpService.post(
      `https://${apiBaseUrl()}/qbo/${companyId}/invoices/${reservationId}/toggle-sync`,
      { paymentsAndChargesSyncEnabled: toggle }
    )
  },
  updateQuickbooksCustomerForInvoice(
    companyId: number,
    invoiceId: string
  ): Promise<AxiosResponse<Result>> {
    return httpService.post(
      `https://${apiBaseUrl()}/qbo/${companyId}/invoices/${invoiceId}/refresh-customer`,
      {}
    )
  },
  getInvoiceReservationDetails(
    companyId: number,
    reservationId: number
  ): Promise<AxiosResponse<ReservationInvoiceDetailsResult>> {
    return httpService.get(
      `https://${apiBaseUrl()}/qbo/${companyId}/invoices/${reservationId}`
    )
  },
  createQuickbooksCustomerWithBusifyContactId(
    companyId: number,
    contactId: number,
    forceCreation = false
  ): Promise<AxiosResponse<ContactQuickbooksDetailResponse>> {
    return httpService.post(
      `https://${apiBaseUrl()}/qbo/${companyId}/customers/${contactId}?forceCreation=${forceCreation}`,
      {}
    )
  },
  deleteBusifyContactQuickbooksCustomerAssociation(
    companyId: number,
    contactId: number
  ): Promise<AxiosResponse<Result>> {
    return httpService.delete(
      `https://${apiBaseUrl()}/qbo/${companyId}/customers/${contactId}`
    )
  },
  deleteQuickbooksIntegration(
    companyId: number
  ): Promise<AxiosResponse<Result>> {
    return httpService.delete(`https://${apiBaseUrl()}/qbo/${companyId}`)
  },
  deleteQuickbooksInvoice(
    companyId: number,
    invoiceId: string
  ): Promise<AxiosResponse<Result>> {
    return httpService.delete(
      `https://${apiBaseUrl()}/qbo/${companyId}/invoices/${invoiceId}`
    )
  },
  voidInvoiceByReservationId(
    companyId: number,
    reservationId: number
  ): Promise<AxiosResponse<Result>> {
    return httpService.post(
      `https://${apiBaseUrl()}/qbo/${companyId}/invoices/${reservationId}/void`,
      {}
    )
  },
  exportQuickBooksActivityLogs(params: TableViewParameters): Promise<any> {
    const { page, pageSize } = params

    const host = apiBaseUrl()
    const url = `https://${host}/download/csv?actorTimeZone=${auth.getUserTimeZone}`
    return httpService.post(
      url,
      {
        operationName: 'ListQuickBooksActivityLogs',
        query: LIST_QUICKBOOKS_ACTIVITY_LOGS_QUERY,
        variables: {
          page: page - 1,
          size: pageSize,
        },
      },
      {
        responseType: 'blob',
      }
    )
  },
  getCompanyTaxCodes(companyId): Promise<AxiosResponse<QuickbooksTaxCodeResponse>> {
    const url = `https://${apiBaseUrl()}/qbo/${companyId}/tax-codes`
    return httpService.get(url)
  }
}
