
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class CUChip extends Vue {
  @Prop({ type: Boolean, default: false }) readonly value!: boolean
  @Prop({ required: false, default: 'primary' }) readonly border!: string
  @Prop({ required: false, default: 'transparent' })
  readonly background!: string
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean
}
