var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"d-flex align-center margin-b-3"},[_c('p',{class:`font-${_vm.titleWeight} font-16`},[_vm._v(_vm._s(_vm.title))]),_c('CUButton',{staticClass:"margin-l-2 padding-x-4",class:{
        'opacity-1': !_vm.areAllTagsSelected,
        'opacity-0': _vm.areAllTagsSelected,
      },attrs:{"color":"secondary","opaque":"","unset-width":"","height":"34px","disabled":_vm.areAllTagsSelected},on:{"click":_vm.handleSelectAll},scopedSlots:_vm._u([{key:"prepend-icon",fn:function(){return [_c('CUIcon',{staticClass:"margin-r-n2",attrs:{"color":"secondary","width":"22px","height":"22px"}},[_vm._v(" confirm ")])]},proxy:true}])},[_c('span',{staticClass:"body-2-bold"},[_vm._v("Select All")])])],1),_c('div',{staticClass:"d-flex flex-wrap",style:({ gap: _vm.gapStyle })},_vm._l((_vm.tags),function(tag,idx){return _c('div',{key:`tag-cloud-el-${tag.id}-${idx}`,on:{"click":function($event){return _vm.handleSelectTag(tag)}}},[_c('div',{staticClass:"d-flex align-center justify-center padding-x-4 padding-y-1 rounded-pill border-1 border-solid font-14 cursor-pointer text-not-selectable",class:{
          'background-secondary-light border-secondary text-secondary':
            tag.active,
          'background-gray-bg border-gray-text-dark text-gray-text-dark':
            !tag.active,
        }},[_c('span',[_vm._v(_vm._s(tag.label))]),(!tag.active)?_c('CUIcon',{key:"tag-cloud-add-icon",staticClass:"margin-l-2",attrs:{"width":"12px","height":"12px"}},[_vm._v(" add ")]):_c('CUIcon',{key:"tag-cloud-confirm-icon",staticClass:"margin-l-2 margin-r-n1",style:({ marginTop: '2px' }),attrs:{"width":"16px","height":"16px"}},[_vm._v(" confirm ")])],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }