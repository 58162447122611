import Vue from 'vue'
import Component from 'vue-class-component'
import dayjs, { Dayjs } from 'dayjs'
import auth from '@/store/modules/auth'
import { DateOptions, ParseableDate } from '@/models/Date'
@Component
export default class DateMixin extends Vue {
  /**
   * Date and Time Formatting Guide:
   *
   * Date Formats:
   * - **shortDate**: M/D/YY
   * - **mediumDate**: MM/DD/YY
   * - **longDate**: MM/DD/YYYY
   *
   * Time Formats:
   * - **shortTime**: h:mm
   * - **longTime**: hh:mm
   *
   * Options:
   * - **showMeridianUpper**: Add " AM" or " PM" to the time.
   * - **showMeridianLower**: Add "am" or "pm" to the time.
   * - **showTimezone**: Add " EST" or corresponding timezone abbreviation to the time.
   * - **showDot**: Display a dot between the date and time.
   *
   */

  /**
   * Formats the given date into either M/D/YY or D/M/YY format
   *
   * @param {ParseableDate} date - The date to be formatted in ISO format.
   * @param {DateOptions} options - The options for parsing the date.
   * @returns {string} The formatted short date.
   */
  formatShortDate(date: ParseableDate, options: DateOptions = {}): string {
    const parsedDate = this.parseDate(date, options)
    if (!parsedDate) {
      return ''
    }
    const format = auth.shouldDisplayDayLeading ? 'D/M/YY' : 'M/D/YY'
    return parsedDate.format(format)
  }

  /**
   * Formats the given date into either MM/DD/YY or DD/MM/YY format, with zero-padded day and month.
   *
   * @param {ParseableDate} date - The date to be formatted in ISO format.
   * @param {DateOptions} options - The options for parsing the date.
   * @returns {string} The formatted medium date.
   */
  formatMediumDate(date: ParseableDate, options: DateOptions = {}): string {
    const parsedDate = this.parseDate(date, options)
    if (!parsedDate) {
      return ''
    }
    const format = auth.shouldDisplayDayLeading ? 'DD/MM/YY' : 'MM/DD/YY'
    return parsedDate.format(format)
  }

  /**
   * Formats the given date into either MM/DD/YYYY or DD/MM/YYYY format, with zero-padded day, month, and a full year.
   *
   * @param {ParseableDate} date - The date to be formatted in ISO format.
   * @param {DateOptions} options - The options for parsing the date.
   * @returns {string} The formatted long date.
   */
  formatLongDate(date: ParseableDate, options: DateOptions = {}): string {
    const parsedDate = this.parseDate(date, options)
    if (!parsedDate) {
      return ''
    }
    const format = auth.shouldDisplayDayLeading ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
    return parsedDate.format(format)
  }

  /**
   * Formats the given date into a short time format (e.g., 5:30).
   *
   * @param {ParseableDate} date - The date to be formatted in ISO format.
   * @param {DateOptions} options - The options for parsing the date, including potential timezone or meridian.
   * @returns {string} The formatted short time.
   */
  formatShortTime(date: ParseableDate, options: DateOptions = {}): string {
    const parsedDate = this.parseDate(date, options)
    if (!parsedDate) {
      return ''
    }
    const timeFormat = 'h:mm'
    const format = `${timeFormat}${this.optionsToTimeFormat(options)}`.trim()
    return parsedDate.format(format)
  }

  /**
   * Formats the given date into a long time format with zero-padded hours and minutes (e.g., 05:30).
   *
   * @param {ParseableDate} date - The date to be formatted in ISO format.
   * @param {DateOptions} options - The options for parsing the date, including potential timezone or meridian.
   * @returns {string} The formatted long time.
   */
  formatLongTime(date: ParseableDate, options: DateOptions = {}): string {
    const parsedDate = this.parseDate(date, options)
    if (!parsedDate) {
      return ''
    }
    const timeFormat = 'hh:mm'
    const format = `${timeFormat}${this.optionsToTimeFormat(options)}`.trim()
    return parsedDate.format(format)
  }

  /**
   * Formats the given date into a military (24-hour) time format (e.g., 17:30).
   *
   * @param {ParseableDate} date - The date to be formatted in ISO format.
   * @param {DateOptions} options - The options for parsing the date, including potential timezone.
   * @returns {string} The formatted military time.
   */
  formatMilitaryTime(date: ParseableDate, options: DateOptions = {}): string {
    const parsedDate = this.parseDate(date, options)
    if (!parsedDate) {
      return ''
    }
    const timeFormat = 'HH:mm'
    const format = `${timeFormat}${this.optionsToTimeFormat(options)}`.trim()
    return parsedDate.format(format)
  }

  // -- COMPOUND METHODS -- //

  /**
   * Formats the given date into a short date and short time format (e.g., 5/7/23 5:30).
   * @param {string} date - The date to be formatted in ISO format.
   * @param {DateOptions} options - The options for parsing the date, including meridian and timezone display.
   * @returns {string} The formatted date string.
   */
  formatShortDateShortTime(
    date: ParseableDate,
    options: DateOptions = {}
  ): string {
    const shortDate = this.formatShortDate(date, options)
    const shortTime = this.formatShortTime(date, options)
    return this.formatDateAndTime(shortDate, shortTime, options)
  }

  /**
   * Formats the given date into a short date and short time format (e.g., 5/7/23 05:30).
   * @param {string} date - The date to be formatted in ISO format.
   * @param {DateOptions} options - The options for parsing the date, including meridian and timezone display.
   * @returns {string} The formatted date string
   */
  formatShortDateLongTime(
    date: ParseableDate,
    options: DateOptions = {}
  ): string {
    const shortDate = this.formatShortDate(date, options)
    const longTime = this.formatLongTime(date, options)
    return this.formatDateAndTime(shortDate, longTime, options)
  }

  /**
   * Formats the given date into a medium date and short time format (e.g., 05/07/23 5:30).
   * @param {ParseableDate} date - The date to be formatted in ISO format.
   * @param {DateOptions} options - The options for parsing the date, including meridian and timezone display.
   * @returns {string} The formatted date string.
   */
  formatMediumDateShortTime(
    date: ParseableDate,
    options: DateOptions = {}
  ): string {
    const mediumDate = this.formatMediumDate(date, options)
    const shortTime = this.formatShortTime(date, options)
    return this.formatDateAndTime(mediumDate, shortTime, options)
  }

  /**
   * Formats the given date into a medium date and short time format (e.g., 05/07/23 5:30).
   * @param {string} date - The date to be formatted in ISO format.
   * @param {DateOptions} options - The options for parsing the date, including meridian and timezone display.
   * @returns {string} The formatted date string.
   */
  formatMediumDateLongTime(date: string, options: DateOptions = {}): string {
    const mediumDate = this.formatMediumDate(date, options)
    const longTime = this.formatLongTime(date, options)
    if (!mediumDate && !longTime) {
      return ''
    }
    if (options.showDot) {
      return `${mediumDate} ⸱ ${longTime}`
    }
    return `${mediumDate} ${longTime}`
  }

  /**
   * Formats the given date into a long date and short time format (e.g., 05/07/2023 5:30).
   * @param {ParseableDate} date - The date to be formatted in ISO format.
   * @param {DateOptions} options - The options for parsing the date, including meridian and timezone display.
   * @returns {string} The formatted date string.
   */
  formatLongDateShortTime(
    date: ParseableDate,
    options: DateOptions = {}
  ): string {
    const longDate = this.formatLongDate(date, options)
    const shortTime = this.formatShortTime(date, options)
    return this.formatDateAndTime(longDate, shortTime, options)
  }

  /**
   * Formats the given date into a long date and long time format (e.g., 05/07/2023 05:30).
   * @param {ParseableDate} date - The date to be formatted in ISO format.
   * @param {DateOptions} options - The options for parsing the date, including meridian and timezone display.
   * @returns {string} The formatted date string.
   */
  formatLongDateLongTime(
    date: ParseableDate,
    options: DateOptions = {}
  ): string {
    const longDate = this.formatLongDate(date, options)
    const longTime = this.formatLongTime(date, options)
    return this.formatDateAndTime(longDate, longTime, options)
  }

  formatInputToISODate(date: string, options: DateOptions = {}): string {
    let inputFormats = []
    if (auth.shouldDisplayDayLeading) {
      inputFormats = [
        'D/M/YYYY',
        'DD/M/YYYY',
        'DD/MM/YYYY',
        'D/MM/YYYY',
        'D/M/YY',
        'DD/M/YY',
        'DD/MM/YY',
        'D/MM/YY',
      ]
    } else {
      inputFormats = [
        'M/D/YYYY',
        'MM/D/YYYY',
        'MM/DD/YYYY',
        'M/DD/YYYY',
        'M/D/YY',
        'MM/D/YY',
        'MM/DD/YY',
        'M/DD/YY',
      ]
    }

    if (!dayjs(date, inputFormats).isValid()) {
      return ''
    }

    const parsedDate = dayjs(date, inputFormats)
    if (options.tz) {
      return parsedDate.tz(options.tz).format('YYYY-MM-DD')
    }
    return parsedDate.format('YYYY-MM-DD')
  }

  /**
   * Formats the given date to display the weekday, followed by short date and short time format (e.g., Mon, 5/7/23, 5:30).
   * @param {ParseableDate} date - The date to be formatted in ISO format.
   * @param {DateOptions} options - The options for parsing the date, including meridian and timezone display.
   * @returns {string} The formatted date string with the weekday.
   */
  formatWeekdayShortDateShortTime(
    date: ParseableDate,
    options: DateOptions = {}
  ): string {
    const weekdayShortDate = this.formatWeekdayShortDate(date, options)
    const shortTime = this.formatShortTime(date, options)
    return `${weekdayShortDate}, ${shortTime}`
  }

  formatWeekdayShortDate(
    date: ParseableDate,
    options: DateOptions = {}
  ): string {
    const parsedDate = this.parseDate(date, options)
    const weekday = parsedDate.format('ddd')
    const shortDate = this.formatShortDate(date, options)
    return `${weekday}, ${shortDate}`
  }

  formatWeekdayLongDate(
    date: ParseableDate,
    options: DateOptions = {}
  ): string {
    const parsedDate = this.parseDate(date, options)
    return parsedDate.format('MMMM DD, YYYY')
  }

  formatMonthYear(date: string, options: DateOptions = {}): string {
    const parsedDate = this.parseDate(date, options)
    return parsedDate.format("MMM 'YY")
  }

  formatShortMonthDay(date: string, options: DateOptions = {}): string {
    const parsedDate = this.parseDate(date, options)
    const format = auth.shouldDisplayDayLeading ? 'D/M' : 'M/D'
    return parsedDate.format(format)
  }

  formatMonthDay(date: string, options: DateOptions = {}): string {
    const parsedDate = this.parseDate(date, options)
    const format = auth.shouldDisplayDayLeading ? 'D MMM' : 'MMM D'
    return parsedDate.format(format)
  }

  /* -- Helper Methods -- */

  /**
   * Returns a string representing the time format based on the provided options.
   * @param options - An object containing options for formatting the time.
   * @param options.showTimezone - Whether to include the timezone in the format.
   * @param options.showMeridianLower - Whether to include the meridian (am/pm) in lowercase in the format.
   * @param options.showMeridianUpper - Whether to include the meridian (AM/PM) in uppercase in the format.
   * @returns A string representing the time format based on the provided options.
   */
  optionsToTimeFormat(options: DateOptions = {}): string {
    const { showTimezone, showMeridianLower, showMeridianUpper } = options

    let format = ''
    if (showMeridianUpper) {
      format += ' A'
    }

    if (showMeridianLower) {
      format += 'a'
    }

    if (showTimezone) {
      format += ' z'
    }

    return format
  }

  formatDateAndTime(date: string, time: string, options: DateOptions): string {
    if (!date && !time) {
      return ''
    } else if (!date) {
      return time
    } else if (!time) {
      return date
    }

    if (options.showDot) {
      return `${date} ⸱ ${time}`
    }

    if (options.showComma) {
      return `${date}, ${time}`
    }
    return `${date} ${time}`
  }

  parseDate(date: ParseableDate, options: DateOptions = {}): Dayjs {
    if (!date) {
      return null
    }
    const { tz } = options
    let parsedDate = dayjs(date)
    if (tz) {
      parsedDate = tz === 'Z' ? dayjs.utc(date) : dayjs(date).tz(tz)
    }
    return parsedDate
  }
}
