
import { AssignedDriver, AssignedVehicle } from '@/models/dto'
import assignment from '@/store/modules/assignment'
import { Vue, Component, Prop } from 'vue-property-decorator'
import DriverAssignmentTag from '@/components/DriverAssignmentTag.vue'
import auth from '@/store/modules/auth'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'
import sidebar from '@/store/modules/sidebar'
import EditUserSidebar from './EditUserSidebar.vue'
import driverClient from '@/services/driver'
import VehicleSidebar from './VehicleSidebar.vue'
import AssignmentsAvatar from '@/components/AssignmentsAvatar.vue'
import AssignmentsSelectStackActions from '@/components/AssignmentsSelectStackActions.vue'
import { Driver } from '@/models/dto/Driver'
import app from '@/store/modules/app'

@Component({
  components: {
    DriverAssignmentTag,
    AssignmentsAvatar,
    AssignmentsSelectStackActions,
  },
})
export default class AssignmentsSelectStack extends Vue {
  @Prop({ required: true }) vehicleAssignment: AssignedVehicle
  @Prop({ required: true }) vehicleIdx: number

  app = app
  assignment = assignment

  get useResDetailView(): boolean {
    return assignment.getIsNonDefaultView
  }

  get isVehicleSelected(): boolean {
    if (this.useResDetailView) {
      return false
    }
    if (
      assignment.getSelectedDriverId ||
      assignment.getSelectedDriverIdx ||
      assignment.getSelectedDriverIdx === 0
    ) {
      return false
    }
    if (assignment.getSelectedVehicleId) {
      return (
        assignment.getSelectedBlock?.vehicleId === this.vehicleAssignment?.id
      )
    }
    return assignment.getSelectedVehicleIdx === this.vehicleIdx
  }

  isDriverSelected(id: number, idx: number): boolean {
    if (this.useResDetailView) {
      return false
    }
    const isRightVehicle = assignment.getSelectedVehicleIdx === this.vehicleIdx
    if (assignment.getSelectedDriverId) {
      return isRightVehicle && assignment.getSelectedDriverId === id
    }
    return isRightVehicle && assignment.getSelectedDriverIdx === idx
  }

  get selectedVehicleClasses(): string {
    if (!this.isVehicleSelected) {
      return 'padding-y-1'
    }
    if (!this.vehicleAssignment.id) {
      return 'padding-y-2 background-primary-10'
    } else if (this.vehicleAssignment.isConfirmed) {
      return 'padding-y-2 background-secondary-light'
    } else {
      return 'padding-y-2 background-bg-gray-1'
    }
  }

  get driverAssignmentTags(): {
    tag: string
    border: string
    bg: string
  }[] {
    return this.vehicleAssignment.drivers.map((driverAssignment) => {
      if (!driverAssignment.id) {
        return null
      } else if (!driverAssignment.isConfirmed) {
        return { tag: 'Draft', border: 'bg-gray-5', bg: 'bg-gray-1' }
      } else if (driverAssignment.assignmentStatusKey === 'pending') {
        return { tag: 'Pending', border: 'bg-gray-5', bg: 'bg-gray-1' }
      } else if (driverAssignment.assignmentStatusKey === 'accepted') {
        return { tag: 'Accepted', border: 'secondary', bg: 'secondary-light' }
      } else if (driverAssignment.assignmentStatusKey === 'rejected') {
        return { tag: 'Rejected', border: 'rust', bg: 'rust-10' }
      }
    })
  }

  get driversSortedByName(): Driver[] {
    if (!this.assignment?.drivers) {
      return []
    }
    return [...this.assignment.drivers].sort((a, b) =>
      a.name.localeCompare(b.name)
    )
  }

  get vehicleAvatar(): string {
    if (!this.vehicleAssignment.id || !this.vehicleAssignment.isConfirmed) {
      return 'vehicle'
    }
    const vehicleHasConflict = assignment.unavailableVehicles.some(
      (uv) =>
        uv.vehicleId === this.vehicleAssignment.id &&
        uv.reservationId !== assignment.selectedReservationId
    )
    if (vehicleHasConflict) {
      return 'conflict'
    }
    return this.vehicleAssignment.name.substring(0, 2)
  }

  getDriverAvatar(driverAssignment: AssignedDriver): string {
    if (!driverAssignment.id || !driverAssignment.isConfirmed) {
      return 'driver'
    }
    const driverHasConflict = assignment.unavailableDrivers.find(
      (ud) =>
        ud.driverId === driverAssignment.id &&
        ud.reservationId !== assignment.selectedReservationId
    )
    if (driverHasConflict) {
      return 'conflict'
    }
    return `${driverAssignment.name[0]}${
      driverAssignment.name.split(' ')[1][0]
    }`
  }

  getDriverAvatarBorderRadius(idx: number): string {
    let borderRadius = ''
    if (idx === this.vehicleAssignment.drivers.length - 1) {
      borderRadius = 'border-radius-bottom-left-10'
    } else {
      borderRadius = 'none'
    }
    return borderRadius
  }

  getDriverAvatarRightBorderRadius(idx: number): string {
    let borderRadius = ''
    if (idx === this.vehicleAssignment.drivers.length - 1) {
      borderRadius = 'border-radius-bottom-right-10'
    } else {
      borderRadius = 'none'
    }
    return borderRadius
  }

  handleUpdateVehicleCheckbox(isChecked: boolean): void {
    if (isChecked) {
      assignment.setSelectedIds({
        reservationId: assignment.getSelectedReservation.reservationId,
        vehicleId: this.vehicleAssignment.id,
        vehicleIdx: this.vehicleIdx,
      })
    } else {
      assignment.setSelectedIds({
        reservationId: assignment.getSelectedReservation.reservationId,
        vehicleId: null,
        vehicleIdx: null,
      })
    }
  }

  handleUpdateDriverCheckbox(
    isChecked: boolean,
    id: number,
    idx: number
  ): void {
    if (isChecked) {
      assignment.setSelectedIds({
        reservationId: assignment.getSelectedReservation.reservationId,
        vehicleId: this.vehicleAssignment.id,
        vehicleIdx: this.vehicleIdx,
        driverId: id,
        driverIdx: idx,
        isDriver: true,
      })
    } else {
      assignment.setSelectedIds({
        reservationId: assignment.getSelectedReservation.reservationId,
        vehicleId: null,
        vehicleIdx: null,
        driverId: null,
        driverIdx: null,
        isDriver: true,
      })
    }
  }

  async handleDriverAvatarClick(
    vehicleIdx: number,
    driverIdx: number
  ): Promise<void> {
    if (
      assignment.isReferral ||
      !assignment.getSelectedAssignments[vehicleIdx].drivers?.[driverIdx]?.id ||
      !auth.getUserRoleNames.includes(ACCESS_SETTINGS_ROLES.OPERATIONS)
    ) {
      return
    }

    const res = await driverClient.byId(
      assignment.getSelectedAssignments[vehicleIdx].drivers[driverIdx].id
    )
    assignment.updateConflicts()
    sidebar.push({
      component: EditUserSidebar,
      props: { user: res.data.driver, driverOnly: true, showConflicts: true },
      width: 675,
    })
  }

  handleVehicleAvatarClick(): Promise<void> {
    if (
      assignment.isReferral ||
      !this.vehicleAssignment.id ||
      !auth.getUserRoleNames.includes(ACCESS_SETTINGS_ROLES.OPERATIONS)
    ) {
      return
    }

    assignment.updateConflicts()
    const allVehicleConflicts = assignment.getVehicleConflicts.filter(
      (conflict) => conflict.id === this.vehicleAssignment.id
    )
    allVehicleConflicts.push(
      ...assignment.getVehicleTimeOffConflicts.filter(
        (conflict) => conflict.id === this.vehicleAssignment.id
      )
    )
    sidebar.push({
      component: VehicleSidebar,
      width: 672,
      props: {
        vehicleConflicts: allVehicleConflicts,
        unavailableVehicles: assignment.getUnavailableVehicles,
        reservationId: assignment.getSelectedReservation.reservationId,
        isModeEdit: true,
        activeVehicleDetailId: this.vehicleAssignment.id,
      },
    })
  }

  handleAssign(id: number): void {
    assignment.setSelectedIds({
      reservationId: assignment.getSelectedReservation.reservationId,
      vehicleId: id,
      vehicleIdx: this.vehicleIdx,
    })
    assignment.setIsAssigning(this.vehicleIdx)
    sidebar.hide()
  }

  handleClearVehicle(): void {
    if (this.vehicleAssignment.isConfirmed) {
      assignment.setVehicleStatus({
        id: this.vehicleAssignment.id,
        isConfirmed: false,
      })
    } else {
      assignment.handleChangeVehicle({ oldIdx: this.vehicleIdx, id: null })
    }
  }

  handleConfirmVehicle(id: number): void {
    assignment.setVehicleStatus({ id, isConfirmed: true })
  }

  handleClearDriver(driverIdx: number): void {
    if (this.vehicleAssignment.drivers[driverIdx].isConfirmed) {
      assignment.setDriverStatus({
        id: this.vehicleAssignment.drivers[driverIdx].id,
        idx: driverIdx,
        isConfirmed: false,
      })
    } else {
      assignment.handleChangeDriver({
        vehicleIdx: this.vehicleIdx,
        driverIdx,
        id: null,
      })
    }
  }

  handleConfirmDriver(idx: number): void {
    assignment.setDriverStatus({
      id: this.vehicleAssignment.drivers[idx].id,
      idx,
      isConfirmed: true,
    })
  }
}
