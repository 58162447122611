import dayjs, { Dayjs } from 'dayjs'
import minMax from 'dayjs/plugin/minMax'

dayjs.extend(minMax)

export const getMinDate = (...dates): Dayjs | null => {
  const validDates = dates
    .filter((date) => date != null)
    .map((date) => dayjs(date))
    .filter((date) => date.isValid())
  return validDates.length ? dayjs.min(...validDates) : null
}

export const getMaxDate = (...dates): Dayjs | null => {
  const validDates = dates
    .filter((date) => date != null)
    .map((date) => dayjs(date))
    .filter((date) => date.isValid())
  return validDates.length ? dayjs.max(...validDates) : null
}
