export const LineItemSectionTypes = [
  { id: 1, key: 'base_fare', label: 'Base Fare', description: '' },
  { id: 2, key: 'itemized_charge', label: 'Itemized Charge', description: '' },
  { id: 3, key: 'subtotal', label: 'Subtotal', description: '' },
]

export const LineItemChargeTypes = [
  { id: 1, key: 'flat_rate', label: 'Flat Rate', description: '' },
  { id: 2, key: 'percentage', label: 'Percentage', description: '' },
  { id: 11, key: 'per_quantity', label: 'Per Quantity', description: '' },
]
