
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import client from '@/services/vehicle'
import garage from '@/services/garage'
import type from '@/services/type'
import auth from '@/store/modules/auth'
import {
  VehicleAmenityDTO,
  VehicleDetailEntity,
  VehicleTimeOff,
} from '@/models/dto/VehicleDetail'
import { VehicleType } from '@/models/dto/Vehicle'
import { Garage } from '@/models/dto/Garage'
import VehicleSidebarAmenities from '@/components/VehicleSidebarAmenities.vue'
import { validationRules, validateRules } from '@/utils/rules'
import { EventBus } from '@/utils/eventBus'
import sidebar from '@/store/modules/sidebar'
import { getEmptyVehicle, vehicleAmenityOrder } from '@/utils/vehicle'
import { Action, Element } from '@/models/dto/Action'
import HoldUpModal from './HoldUpModal.vue'
import { AssignedVehicle, VehicleConflict } from '@/models/dto'
import deepClone from '@/utils/deepClone'
import AssignmentConflictsMessages from '@/components/AssignmentConflictsMessages.vue'
import VehicleSidebarDetails from '@/components/VehicleSidebarDetails.vue'
import app from '@/store/modules/app'
import { Tab } from '@/models/dto/Tab'
import VehicleSidebarAvailability from './VehicleSidebarAvailability.vue'
import dayjs from 'dayjs'
import { openInNewTab } from '@/utils/router'

@Component({
  components: {
    VehicleSidebarAmenities,
    VehicleSidebarDetails,
    HoldUpModal,
    AssignmentConflictsMessages,
  },
})
export default class VehicleSidebar extends Vue {
  @Prop({ required: false, default: null })
  readonly activeVehicleDetailId!: number
  @Prop({ type: Boolean, default: false })
  readonly isModeEdit!: boolean
  @Prop({ type: Boolean, default: false })
  readonly back!: boolean
  @Prop({ type: String, default: 'Vehicle Detail' })
  title!: string
  @Prop({ required: false, default: () => [] })
  vehicleConflicts!: AssignedVehicle[]
  @Prop({ required: false, default: () => [] })
  unavailableVehicles!: VehicleConflict[]
  @Prop({ required: false }) reservationId: number
  @Prop({ required: false, default: true }) showEditHeader: boolean

  auth = auth
  rules = validationRules

  garages: Garage[] | null = []
  vehicleTypes: VehicleType[] | null = []
  vehicle = new VehicleDetailEntity(null)
  indefiniteTimeOff = null

  loading = true
  submitting = false
  isModalOpen = false
  isEdited = false
  errors = {
    vinNumber: null,
  }
  timeOffModalData = null
  showTimeOffModal = false

  @Watch('vehicle', { deep: true })
  @Watch('indefiniteTimeOff', { deep: true })
  vehicleChange(): void {
    this.isEdited = true
    this.resetErrors()
  }

  get isModeAdd(): boolean {
    return !this.activeVehicleDetailId
  }

  get isApplyButtonDisabled(): boolean {
    return (
      this.submitting || this.loading || (this.isModeEdit && !this.isEdited)
    )
  }

  get isTabView(): boolean {
    return (
      !this.isModeAdd && app.isSystemParameterTrue('isVehicleTimeOffEnabled')
    )
  }

  get tabs(): Tab[] {
    return [
      {
        label: 'Details',
        component: VehicleSidebarDetails,
        id: 'vehicle-panel-details',
        hash: 'details',
        props: {
          dummy: this.vehicle.dummy,
          tagCloudList: this.tagCloudList,
        },
        on: {
          'update:cloud': (e) => this.handleUpdateAmenities(e),
          'update:dummy': (e) => {
            this.vehicle.dummy = e
          },
        },
      },
      {
        label: 'Availability',
        hash: 'availability',
        component: VehicleSidebarAvailability,
        props: {
          vehicleId: this.activeVehicleDetailId,
        },
        id: 'vehicle-panel-availability',
        on: {
          'update:indefinite': (e: VehicleTimeOff) =>
            (this.indefiniteTimeOff = e),
        },
      },
    ]
  }

  actions: Action[] = [
    {
      label: 'Delete',
      event: 'vehicle:delete',
    },
  ]

  get tagCloudList(): Element[] {
    const amenities = deepClone(this.vehicle.vehicleAmenityDTOs)
    return amenities
      .map((a) => ({
        id: a.amenityId,
        label: a.amenityName,
        active: a.isSupported,
      }))
      .sort((a, b) => {
        return (
          vehicleAmenityOrder.indexOf(a.label) -
          vehicleAmenityOrder.indexOf(b.label)
        )
      })
  }

  handleUpdateAmenities(list: Element[]): void {
    this.vehicle.vehicleAmenityDTOs.map((amenity) => {
      amenity.isSupported = list.find(
        ({ id }) => id === amenity.amenityId
      ).active
    })
  }

  resetErrors(): void {
    this.errors = {
      vinNumber: null,
    }
  }

  deleteVehicle(): void {
    client
      .delete(this.vehicle.vehicleId)
      .then(
        () => EventBus.$emit('vehicles:refresh'),
        (err) => EventBus.$emit('vehicles:error', err)
      )
      .finally(this.close)
  }

  cancel(): void {
    if (this.isModeAdd) {
      this.clear()
    } else if (this.back) {
      this.$emit('close')
    } else {
      this.close()
    }
  }

  async submit(timeOffOverride = false): Promise<void> {
    if (!(await validateRules(this))) {
      return
    }

    this.submitting = true

    if (this.isModeEdit && this.indefiniteTimeOff) {
      const savedWithNoAssignmentError = await this.submitIndefiniteTimeOff(
        timeOffOverride
      )
      if (!savedWithNoAssignmentError) {
        return
      }
    }

    this.vehicle.companyId = this.auth.user.companyId
    this.vehicle.active = true

    const vehicleRequest: VehicleDetailEntity = this.vehicle

    try {
      if (this.isModeAdd) {
        await client.create(vehicleRequest)
      } else if (this.isModeEdit) {
        await client.update(vehicleRequest)
      }
      EventBus.$emit('vehicles:refresh')
    } catch (err) {
      this.handleSubmitError(err)
    } finally {
      this.submitting = false
    }
  }

  async submitIndefiniteTimeOff(timeOffOverride = false): Promise<boolean> {
    if (!this.indefiniteTimeOff) {
      return true
    }

    if (!timeOffOverride) {
      // if user overrides conflicts, this date has already been formatted on first attempted submission
      const tz =
        auth.getUser?.timeZone || auth.getCompanyTimeZone || 'America/New_York'
      this.indefiniteTimeOff.start = dayjs
        .utc(this.indefiniteTimeOff.start)
        .tz(tz)
        .format('YYYY-MM-DDTHH:mm:ss')
      this.indefiniteTimeOff.end = dayjs
        .utc(this.indefiniteTimeOff.end)
        .tz(tz)
        .format('YYYY-MM-DDTHH:mm:ss')
    }

    try {
      if (this.indefiniteTimeOff.id) {
        await client.editTimeOff(
          this.activeVehicleDetailId,
          this.indefiniteTimeOff,
          timeOffOverride
        )
      } else if (this.indefiniteTimeOff) {
        await client.addTimeOff(
          this.activeVehicleDetailId,
          this.indefiniteTimeOff,
          timeOffOverride
        )
      }
    } catch (err: any) {
      if (err?.response?.status === 409) {
        this.timeOffModalData = err.response.data.map((e) => ({
          resId: e.reservationId,
          managedId: e.managedId,
        }))
        this.showTimeOffModal = true
        this.submitting = false
        return false
      } else {
        EventBus.$emit('snackbar:error', 'Error saving vehicle time off.')
      }
    }
    return true
  }

  openReservation(resId: number): void {
    openInNewTab(`/reservations/detail/${resId}`)
  }

  getResString(id: string, idx: number): string {
    return `${id}${
      this.timeOffModalData.length > 1 && idx < this.timeOffModalData.length - 2
        ? '<span class="font-regular text-black">, </span>'
        : ''
    }${
      this.timeOffModalData.length > 1 &&
      idx === this.timeOffModalData.length - 2
        ? ' <span class="font-regular text-black">and </span>'
        : ''
    }`
  }

  handleSubmitError(e: any): void {
    const message = e.response.data.message
    if (message.includes('VIN')) {
      this.errors.vinNumber = 'A vehicle with this VIN already exists'
      return
    }
    EventBus.$emit('vehicles:error')
  }

  handleSetVehicleTypeId(value: number): void {
    this.vehicle.vehicleTypeId = value
  }

  handleSetVehicleAmenities(amenities: VehicleAmenityDTO[]): void {
    this.vehicle.vehicleAmenityDTOs = amenities
  }

  handleDeleteVehicle(): void {
    this.isModalOpen = true
  }

  async getGarages(): Promise<void> {
    const garageResponse = await garage.tableView({ pageSize: -1 })
    this.garages = garageResponse.data.resultList
  }

  async getVehicleDetail(): Promise<void> {
    if (!this.activeVehicleDetailId) {
      this.vehicle = await getEmptyVehicle()
      return
    }
    const response = await client.byId(this.activeVehicleDetailId)
    this.vehicle = response.data

    if (this.title === '') {
      this.title = this.vehicle.vehicleName
    }
  }

  async getVehicleTypes(): Promise<void> {
    const response = await type.vehicleTypeTableView({ pageSize: -1, page: 1 })
    this.vehicleTypes = response.data.resultList
  }

  close(): void {
    sidebar.pop()
  }

  async clear(): Promise<void> {
    this.vehicle = await getEmptyVehicle()
    this.submitting = false
    if (this.$refs.form?.['reset']) {
      this.$refs.form['reset']()
    }
  }

  @Watch('activeVehicleDetailId')
  async onActiveVehicleDetailIdChange(): Promise<void> {
    this.loading = true
    await this.getVehicleDetail()
    this.loading = false
  }

  async mounted(): Promise<void> {
    EventBus.$on('set-vehicle-amenities', this.handleSetVehicleAmenities)

    await Promise.all([this.getVehicleDetail(), this.getVehicleTypes()])
    this.getGarages()

    this.isEdited = false
    this.loading = false
  }

  beforeDestroy(): void {
    EventBus.$off('set-vehicle-amenities', this.handleSetVehicleAmenities)
  }
}
