
import { DriverRate } from '@/models/DriverPay'
import {
  currencyFilter,
  percentageFormatter,
  stringToFloat,
} from '@/utils/string'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class DriverInfoDriverPayTabSingleRatesForm extends Vue {
  @Prop({ required: true }) rate!: DriverRate

  handleChange(name: string, event: string): void {
    let value = stringToFloat(event, name === 'percentage' ? 0 : 2)
    if (value === 0 || isNaN(value)) {
      value = null
    }
    this.$emit('input', { [name]: value })
  }

  get displayHourlyRate(): string {
    return this.rate.hourlyRate != null
      ? currencyFilter(this.rate.hourlyRate)
      : ''
  }

  get displayDailyRate(): string {
    return this.rate.dailyRate != null
      ? currencyFilter(this.rate.dailyRate)
      : ''
  }

  get displayPercentageBaseFare(): string {
    return this.rate.percentageBase != null
      ? percentageFormatter(this.rate.percentageBase, 0)
      : ''
  }

  get displayPercentageTotalCharges(): string {
    return this.rate.percentageTotal != null
      ? percentageFormatter(this.rate.percentageTotal, 0)
      : ''
  }

  get displayMinRate(): string {
    return this.rate.perTripMinRate != null
      ? currencyFilter(this.rate.perTripMinRate)
      : ''
  }

  get displayPerDiemRate(): string {
    return this.rate.perDiem != null ? currencyFilter(this.rate.perDiem) : ''
  }

  get displayOvernightMinHours(): string {
    return this.rate.overnightMinHours != null
      ? this.rate.overnightMinHours.toString()
      : ''
  }

  get displayLongTripMinHours(): string {
    return this.rate.longTripMinHours != null
      ? this.rate.longTripMinHours.toString()
      : ''
  }
}
