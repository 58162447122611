// TODO: any reference to customer including this file's name will need to be changed
// to contact once the backend is in place
import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import {
  Customer,
  ContactRequest,
  CustomerRequest,
  CreateCustomerResponse,
} from '@/models/dto/Customer'
import { NotificationsSetting } from '@/models/NotificationsSetting'

const httpService: HttpService = new HttpService()

export default {
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<Customer>>> {
    const { sorts, filters, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}&v2=true`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }

    query = encodeURI(query)
    return httpService.get(`https://${apiBaseUrl()}/tables/customers?${query}`)
  },
  update(
    customerId: number,
    customer: ContactRequest | Partial<Customer>
  ): Promise<AxiosResponse<number>> {
    return httpService.patch(
      `https://${apiBaseUrl()}/customers/${customerId}`,
      customer
    )
  },
  create(
    customer: ContactRequest
  ): Promise<AxiosResponse<CreateCustomerResponse>> {
    return httpService.post(`https://${apiBaseUrl()}/customers`, customer)
  },
  byId(customerId: number): Promise<AxiosResponse<CustomerRequest>> {
    return httpService.get(`https://${apiBaseUrl()}/customers/${customerId}`)
  },
  delete(id: number): Promise<AxiosResponse<void>> {
    return httpService.delete(`https://${apiBaseUrl()}/v2/customers/${id}`)
  },
  updateNotificationsSetting(
    companyId: number,
    notificationsSetting: Partial<NotificationsSetting>
  ): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/notifications/customers/${companyId}`
    return httpService.patch(url, { ...notificationsSetting })
  },
}
