import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { Note } from '@/models/dto/Note'
import { Stop, StopResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  updateStopNotes(
    stopId: number,
    stopNotes: Partial<Note>[]
  ): Promise<AxiosResponse<StopResult<Stop>>> {
    const payload = {
      stopId,
      stopNotes,
    }
    return httpService.patch(
      `https://${apiBaseUrl()}/v2/stops/${stopId}/note`,
      payload
    )
  },
}
