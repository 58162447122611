
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CUDatePickerFooter extends Vue {
  @Prop({ required: true }) startDateInputValue!: string
  @Prop({ required: true }) endDateInputValue!: string

  focusStartInput(): void {
    const input: any = this.$refs['start-date-input']
    setTimeout(() => input.focus(), 0)
  }
}
