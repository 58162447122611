import { Reservation, Trip } from '@/models/dto'
import dayjs from 'dayjs'
import { ReservationInformation } from '@/models/ReservationInformation'
import client from '@/services/reservation'
import quoteClient from '@/services/quotes'
import assignmentClient from '@/services/tripAssignment'
import trackingClient from '@/services/tracking'
import { getTripById, getTripEstimation } from '@/utils/trip'
import { PricingMethod, ReservationStatus } from '@/utils/enum'
import { Quote } from '@/models/dto/Quote'
import { Pricing } from '@/models/dto/Pricing'
import { apiBaseUrl } from './env'
import auth from '@/store/modules/auth'
import { fetchInvoices } from '@/services/driverPayTableViews'
import { Invoice } from '@/models/Invoice'
import quickbooks from '@/services/quickbooks'

export const getReservationLocalStartDatetime = (
  reservation: Reservation
): string => {
  if (reservation.trip.garageTimes?.departureTime) {
    const garageTimeZone =
      reservation.trip.garageTimes.garage?.address?.timeZone
    return dayjs(reservation.trip.garageTimes.departureTime)
      .tz(garageTimeZone)
      .format('YYYY-MM-DD')
  } else if (reservation.trip.stops[0].pickupDatetime) {
    const pickupDatetime = reservation.trip.stops[0].pickupDatetime
    const localTimeZone = reservation.trip.stops[0].address.timeZone
    return dayjs(pickupDatetime).tz(localTimeZone).format('YYYY-MM-DD')
  } else {
    const localTimeZone = reservation.trip.stops[0].address.timeZone
    return dayjs(reservation.trip.startDate)
      .tz(localTimeZone)
      .format('YYYY-MM-DD')
  }
}

export const getReservationLocalEndDatetime = (
  reservation: Reservation
): string => {
  const firstStopTimeZone = reservation.trip.stops[0].address.timeZone
  if (reservation.trip.garageTimes?.returnTime) {
    const returnGarageTimeZone =
      reservation.trip.garageTimes.returnGarage?.address?.timeZone
    return dayjs(reservation.trip.garageTimes.departureTime)
      .tz(returnGarageTimeZone)
      .format('YYYY-MM-DD')
  } else if (
    reservation.trip.stops[reservation.trip.stops.length - 1].dropoffDatetime
  ) {
    const lastStop = reservation.trip.stops[reservation.trip.stops.length - 1]
    const dropoffDatetime = lastStop.dropoffDatetime
    const timeZone = lastStop.address.timeZone
    return dayjs(dropoffDatetime).tz(timeZone).format('YYYY-MM-DD')
  } else if (reservation.trip.endDate) {
    return dayjs(reservation.trip.endDate)
      .tz(firstStopTimeZone)
      .format('YYYY-MM-DD')
  } else {
    return dayjs(reservation.trip.startDate)
      .tz(firstStopTimeZone)
      .format('YYYY-MM-DD')
  }
}

export const getReservationFirstStopCity = (
  reservation: Reservation
): string => {
  const { city } = reservation.trip.stops[0].address
  return city
}

export const getTrackingLink = (reservation: Partial<Reservation>): string => {
  return `https://customers.${apiBaseUrl(null)}/tracking/${reservation.hash}`
}

export const getReservationLastStopCity = (
  reservation: Reservation
): string => {
  const { city } =
    reservation.trip.stops[reservation.trip.stops.length - 1].address
  return city
}

export const getReservationById = async (
  reservationId: number
): Promise<Reservation> => {
  const res = await client.byId(reservationId)
  return res.data
}

export const withAllPricingMethods = (quote: Quote, trip: Trip): Quote => {
  if (!quote || !trip) {
    return quote
  }
  const tripVehiclesAndStops: Trip = {
    ...trip,
    vehicles: trip.vehicles?.filter((v) => !!v.vehicleTypeId),
    stops: trip.stops?.filter((s) => !!s.address?.addressId),
  }
  const tripAllPricingMethods = [
    { ...tripVehiclesAndStops, pricingMethod: PricingMethod.DAILY_RATE },
    { ...tripVehiclesAndStops, pricingMethod: PricingMethod.HOURLY_RATE },
    { ...tripVehiclesAndStops, pricingMethod: PricingMethod.MILEAGE_RATE },
  ]

  return { ...quote, trips: tripAllPricingMethods }
}

export const getTripPricing = async (
  quote: Quote,
  trip: Trip,
  overrideLiveMiles: number = null
): Promise<Pricing> => {
  const res = await quoteClient.pricing(
    withAllPricingMethods(quote, trip),
    overrideLiveMiles
  )
  return res.data
}

const isValidTrackingStatus = (status: ReservationStatus): boolean => {
  return (
    status === ReservationStatus.InProgress ||
    status === ReservationStatus.Completed
  )
}

export const fillReservationInformation = async (
  reservation: Reservation,
  reservationId: number
): Promise<ReservationInformation> => {
  reservation.trip = setTripDateTimes(reservation?.trip)
  let trip = reservation?.tripResult?.trip
  trip = setTripDateTimes(trip)
  const [
    tripEstimationResponse,
    assignmentsResponse,
    quoteResponse,
    invoicesResponse,
    trackingResponse,
    reservationInvoiceDetailsResponse,
  ] = await Promise.allSettled([
    getTripEstimation(trip),
    assignmentClient.byReservationIds([reservationId]),
    quoteClient.byId(reservation.quoteId),
    getInvoices(reservation.reservationId),
    isValidTrackingStatus(reservation.reservationStatus as ReservationStatus)
      ? getTracking(reservation)
      : Promise.resolve(null),
    auth.isQuickbooksIntegrationEnabled
      ? quickbooks.getInvoiceReservationDetails(
          auth.getCompanyId,
          reservationId
        )
      : Promise.resolve(null),
  ])

  const assignments =
    assignmentsResponse.status === 'fulfilled'
      ? assignmentsResponse?.value.data?.vehicleAssignments || []
      : []
  const tracking =
    trackingResponse.status === 'fulfilled'
      ? trackingResponse?.value?.data
      : null
  const quote =
    quoteResponse.status === 'fulfilled'
      ? quoteResponse?.value?.data?.quote
      : null
  const invoices =
    invoicesResponse.status === 'fulfilled' ? invoicesResponse?.value : null
  const reservationInvoiceDetails =
    reservationInvoiceDetailsResponse?.status === 'fulfilled'
      ? reservationInvoiceDetailsResponse?.value?.data?.invoiceDetails || null
      : null

  return {
    reservation,
    trip,
    tripEstimation:
      tripEstimationResponse.status === 'fulfilled'
        ? tripEstimationResponse.value
        : null,
    assignments,
    tracking,
    invoices,
    quote,
    reservationInvoiceDetails,
  }
}

export const getReservationInformation = async (
  reservationId: number
): Promise<ReservationInformation> => {
  try {
    const reservation = await getReservationById(reservationId)
    const details = await fillReservationInformation(reservation, reservationId)
    const pricing = await getTripPricing(details.quote, details.trip)
    return {
      ...details,
      pricing,
    }
  } catch (error) {
    console.error(error)
  }
}

export const getTracking = async (reservation: Reservation): Promise<any> => {
  return await trackingClient.byIdsList({
    reservationId: reservation.reservationId,
    idsList: reservation.journeys.map((journey) => {
      return {
        journeyId: journey?.journeyId,
        vehicleId: journey?.vehicle?.vehicleId,
      }
    }),
  })
}

export const setTripDateTimes = (trip: Trip): Trip => {
  if (!trip?.stops) {
    return trip
  }
  trip.stops.map((stop) => {
    const tz = stop?.address?.timeZone || auth.getUserTimeZone
    if (stop.pickupDatetime) {
      stop.pickupTime = dayjs(stop.pickupDatetime).tz(tz).format('HH:mm')
      stop.pickupDate = dayjs(stop.pickupDatetime).tz(tz).format('YYYY-MM-DD')
    }
    if (stop.dropoffDatetime) {
      stop.dropoffTime = dayjs(stop.dropoffDatetime).tz(tz).format('HH:mm')
      stop.dropoffDate = dayjs(stop.dropoffDatetime).tz(tz).format('YYYY-MM-DD')
    }
  })
  return trip
}

export const getInvoices = async (
  reservationId: number
): Promise<Invoice[]> => {
  if (!reservationId) {
    return
  }

  const invoiceRes = await fetchInvoices({
    page: 0,
    pageSize: 5000,
    sorts: undefined,
    filters: {
      reservationId,
    },
  })
  const driverPayInvoices: Invoice[] = invoiceRes.content || []
  driverPayInvoices.sort((a, b) => (a.id < b.id ? 1 : -1))
  return driverPayInvoices
}
