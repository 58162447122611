
import { VehicleAmenityDTO } from '@/models/dto'
import { Component, Vue, Prop } from 'vue-property-decorator'
import deepClone from '@/utils/deepClone'

@Component
export default class VehicleSidebarAmenities extends Vue {
  @Prop({ required: false, default: [] })
  readonly amenities!: VehicleAmenityDTO[]

  get areAllAmenitiesSelected(): boolean {
    const supportedAmenities = this.amenities.filter((amenity) => {
      return amenity.isSupported
    })
    return supportedAmenities.length === this.amenities.length
  }

  toggleAllSelected(): void {
    const toggledAmenities = deepClone(this.amenities).map((amenity) => {
      amenity.isSupported = !this.areAllAmenitiesSelected
      return amenity
    })
    this.$emit('update:vehicle-amenities', toggledAmenities)
  }

  toggleAmenity(value: boolean, amenityId: number): void {
    const amenities = deepClone(this.amenities)
    const amenity = amenities.find((amenity) => {
      return amenity.amenityId === amenityId
    })
    amenity.isSupported = value || false
    this.$emit('update:vehicle-amenities', amenities)
  }
}
