
import { Component, Prop, Vue } from 'vue-property-decorator'
import { countryPhoneFormatFilter } from '@/utils/string'
import { CustomerAccountDetailEntity } from '@/models/dto/CustomerAccount'

@Component({})
export default class CUCompanyCard extends Vue {
  @Prop({ required: true }) readonly company!: CustomerAccountDetailEntity

  countryPhoneFormatFilter = countryPhoneFormatFilter

  get formattedWebsiteUrl(): string {
    return this.company.websiteUrl.toLowerCase().replace(/^https?:\/\//, '')
  }
}
