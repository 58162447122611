import { Pricing, TripPricing } from '@/models/dto/Pricing'
import { PricingMethod } from '@/utils/enum'
import { QuotePricing } from '@/models/QuotePricing'
import { distinctBy, groupBy } from '@/utils/reducers'
import { toTitle } from '@/utils/string'
import {
  VehicleDailyRate,
  VehicleHourlyRate,
  VehicleMileageRate,
} from '@/models/VehicleRate'
import { TripEstimation } from '@/models/dto/Trip'
import { Type } from '@/models/dto/Type'
import { TripPricingLog } from '@/models/dto/TripPricingLog'

export const quotePricing = (pricing: Pricing): QuotePricing => {
  const pricings = pricing?.pricings || []
  const byMethod = pricings.reduce(groupBy('pricingMethod'), {})
  const daily = byMethod[PricingMethod.DAILY_RATE] || []
  const hourly = byMethod[PricingMethod.HOURLY_RATE] || []
  const mileage = byMethod[PricingMethod.MILEAGE_RATE] || []
  return {
    daily: daily.reduce(distinctBy('tripId'), {}) || {},
    hourly: hourly.reduce(distinctBy('tripId'), {}) || {},
    mileage: mileage.reduce(distinctBy('tripId'), {}) || {},
  }
}

export const vehicleDailyRates = (
  tripPricing: TripPricing,
  vehicleNameMap: Record<number, string>
): VehicleDailyRate[] => {
  const vehicleIdMap = tripPricing?.pricingVehicleIdMap
  return (tripPricing?.pricingVehicles || []).map((pricing, index) => {
    const vehicleId = vehicleIdMap[index]
    const vehicle: string = vehicleId
      ? vehicleNameMap[vehicleId]
      : toTitle(pricing.vehicleType || '')
    const quantity: number = pricing.vehicleQuantity || 0
    const rate: number = pricing.highMarketRate || 0
    const quoted: number = pricing.calendarDays || 0
    const markup: number = tripPricing.operatorMarkupPercentage || 0
    const name: string = tripPricing.operatorMarkups?.[0]?.name || '--'
    const baseFare: number = quantity * rate * quoted * ((100 + markup) / 100)
    return {
      vehicle,
      quantity,
      rate,
      quoted,
      markup,
      name,
      baseFare,
    }
  })
}

export const vehicleHourlyRates = (
  tripPricing: TripPricing,
  vehicleNameMap: Record<number, string>
): VehicleHourlyRate[] => {
  const vehicleIdMap = tripPricing?.pricingVehicleIdMap
  return (tripPricing?.pricingVehicles || []).map((pricing, index) => {
    const vehicleId = vehicleIdMap[index]
    const vehicle: string = vehicleId
      ? vehicleNameMap[vehicleId]
      : toTitle(pricing.vehicleType || '')
    const quantity: number = pricing.vehicleQuantity || 0
    const rate: number = pricing.highMarketRate || 0
    const minimum: number = pricing.hourlyMinimum || 0
    const thereafter: number = pricing.lowMarketRate || 0
    const quoted: number = tripPricing.actualHours || 0
    const totalHours: number = pricing.deadHours + pricing.hours
    const markup: number = tripPricing.operatorMarkupPercentage || 0
    const name: string = tripPricing.operatorMarkups?.[0]?.name || '--'

    const baseFare: number =
      quantity *
      (rate * minimum + thereafter * Math.max(totalHours - minimum, 0)) *
      ((100 + markup) / 100)
    return {
      vehicle,
      quantity,
      rate,
      minimum,
      thereafter,
      quoted,
      markup,
      name,
      baseFare,
      totalHours,
    }
  })
}

export const vehicleMileageRates = (
  tripPricing: TripPricing,
  vehicleNameMap: Record<number, string>
): VehicleMileageRate[] => {
  const vehicleIdMap = tripPricing?.pricingVehicleIdMap
  return (tripPricing?.pricingVehicles || []).map((pricing, index) => {
    const vehicleId = vehicleIdMap[index]
    const vehicle: string = vehicleId
      ? vehicleNameMap[vehicleId]
      : toTitle(pricing.vehicleType || '')
    const quantity: number = pricing.vehicleQuantity || 0
    const deadMiles: number = pricing.deadMileHighRate || 0
    const deadMilesQuoted: number = pricing.deadMiles || 0
    const liveMiles: number = pricing.highMarketRate || 0
    const liveMilesQuoted: number = pricing.miles || 0
    const markup: number = tripPricing.operatorMarkupPercentage || 0
    const name: string = tripPricing.operatorMarkups?.[0]?.name || '--'
    const baseFare: number =
      quantity *
      (deadMiles * deadMilesQuoted + liveMiles * liveMilesQuoted) *
      ((100 + markup) / 100)
    return {
      vehicle,
      quantity,
      deadMiles,
      deadMilesQuoted,
      liveMiles,
      liveMilesQuoted,
      markup,
      name,
      baseFare,
    }
  })
}

export const convertTripPricingToBaseFarePricing = (
  tripPricingLog: TripPricingLog,
  pricing: TripPricing
) => {
  if (!tripPricingLog || !pricing) {
    return null
  }
  const result = { ...tripPricingLog } as any
  result.pricingVehicleIdMap = pricing.pricingVehicleIdMap
  if (tripPricingLog.operatorMarkupName) {
    result.operatorMarkups = [
      {
        name: tripPricingLog.operatorMarkupName,
        markupPercentage: pricing.tripMarkupPercentage,
      },
    ]
  }

  return result
}

export const isPricingSelectorHighest = (
  pricingSelectionType: Type
): boolean => {
  switch (pricingSelectionType.key) {
    case 'highest_daily':
    case 'highest_mileage':
    case 'highest_choose':
      return true
    default:
      return false
  }
}

export const isPricingSelectionIdHighest = (
  pricingSelectionId: number
): boolean => {
  const pricingSelectionType = pricingSelectionTypes.find(
    ({ id }) => id === pricingSelectionId
  )
  if (!pricingSelectionType) {
    return false
  }
  return isPricingSelectorHighest(pricingSelectionType)
}

export const pricingSelectionIdToRateTypes = (pricingSelectionId: number) => {
  const pricingSelectionType = pricingSelectionTypes.find(
    ({ id }) => id === pricingSelectionId
  )
  if (!pricingSelectionType) {
    return []
  }
  return pricingSelectionToRateTypes(pricingSelectionType)
}

export const pricingSelectionToRateTypes = (
  pricingSelectionType: Type
): Type[] => {
  const { key } = pricingSelectionType
  const dailyRateType = rateTypes.find(({ key }) => key === 'daily')
  const hourlyRateType = rateTypes.find(({ key }) => key === 'hourly')
  const mileageRateType = rateTypes.find(({ key }) => key === 'mileage')
  switch (key) {
    case 'choose_daily':
      return [dailyRateType]
    case 'choose_mileage':
      return [mileageRateType]
    case 'choose_hourly':
      return [hourlyRateType]
    case 'choose_daily_mileage':
      return [dailyRateType, mileageRateType]
    case 'choose_daily_hourly':
      return [dailyRateType, hourlyRateType]
    case 'choose_hourly_mileage':
      return [mileageRateType, hourlyRateType]
    case 'choose_daily_mileage_hourly':
      return [dailyRateType, hourlyRateType, mileageRateType]
    default:
      return []
  }
}

export const rateTypesToPricingSelectionKey = (rateTypes: Type[]): string => {
  const hasHourly = !!rateTypes.find(({ key }) => key === 'hourly')
  const hasDaily = !!rateTypes.find(({ key }) => key === 'daily')
  const hasMileage = !!rateTypes.find(({ key }) => key === 'mileage')

  if (hasHourly && hasDaily && hasMileage) {
    return 'choose_daily_mileage_hourly'
  } else if (hasDaily && hasMileage) {
    return 'choose_daily_mileage'
  } else if (hasDaily && hasHourly) {
    return 'choose_daily_hourly'
  } else if (hasHourly && hasMileage) {
    return 'choose_hourly_mileage'
  } else if (hasDaily) {
    return 'choose_daily'
  } else if (hasHourly) {
    return 'choose_hourly'
  } else if (hasMileage) {
    return 'choose_mileage'
  } else {
    return 'choose_override'
  }
}

export const rateTypes: Type[] = [
  {
    id: 1,
    key: 'daily',
    label: 'Daily',
    description: '',
  },
  {
    id: 2,
    key: 'hourly',
    label: 'Hourly',
    description: '',
  },
  {
    id: 3,
    key: 'mileage',
    label: 'Mileage',
    description: '',
  },
]

export const pricingSelectionTypes = [
  {
    id: 1,
    key: 'highest_daily',
    label: 'Highest Daily',
    description: 'Trip priced by the highest rate, which is the daily rate',
  },
  {
    id: 2,
    key: 'highest_mileage',
    label: 'Highest Mileage',
    description: 'Trip priced by the highest rate, which is the mileage rate',
  },
  {
    id: 3,
    key: 'highest_hourly',
    label: 'Highest Hourly',
    description: 'Trip priced by the highest rate, which is the hourly rate',
  },
  {
    id: 4,
    key: 'choose_daily',
    label: 'Choose Daily',
    description: 'Trip priced by user selecting the daily rate',
  },
  {
    id: 5,
    key: 'choose_mileage',
    label: 'Choose Mileage',
    description: 'Trip priced by the user selecting the mileage rate',
  },
  {
    id: 6,
    key: 'choose_hourly',
    label: 'Choose Hourly',
    description: 'Trip priced by the user selecting the hourly rate',
  },
  {
    id: 7,
    key: 'choose_daily_mileage',
    label: 'Choose Daily and Mileage',
    description: 'Trip priced by user selecting the daily and mileage rates',
  },
  {
    id: 8,
    key: 'choose_daily_hourly',
    label: 'Choose Daily and Hourly',
    description: 'Trip priced by user selecting the daily and hourly rates',
  },
  {
    id: 9,
    key: 'choose_hourly_mileage',
    label: 'Choose Hourly and Mileage',
    description: 'Trip priced by user selecting the hourly and mileage rates',
  },
  {
    id: 10,
    key: 'choose_daily_mileage_hourly',
    label: 'Choose Daily, Mileage, and Hourly',
    description:
      'Trip priced by user selecting daily, mileage, and hourly rates',
  },
  {
    id: 11,
    key: 'choose_override',
    label: 'Choose Override',
    description: 'Trip priced by user manually setting the base fare',
  },
]
