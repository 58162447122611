export interface Note {
  active: boolean
  createdBy: number
  createdOn: string
  html: string
  note: string
  noteId: number
  noteType: number
}

export interface CreateNotePayload {
  html: string
  note: string
}

export interface FormattedNote {
  canExpand: boolean
  notes: string
}

// eslint-disable-next-line no-redeclare
export class Note implements Note {
  constructor(note?: Partial<Note>) {
    Object.assign(this, note)
  }
}
