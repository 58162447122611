
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import client from '@/services/customerAccount'
import gclient from '@/services/customerAccountGroup'
import { Action } from '@/models/dto/Action'
import {
  CustomerAccountDetailEntity,
  CustomerAccountGroup,
} from '@/models/dto/CustomerAccount'
import { filter } from '@/utils/filter'
import { v4 } from 'uuid'
import sidebar from '@/store/modules/sidebar'
import CompanySidebarDetail from './CompanySidebarDetail.vue'
import { countryPhoneFormatFilter } from '@/utils/string'
import quote from '@/store/modules/quote'
import { EventBus } from '@/utils/eventBus'
import auth from '@/store/modules/auth'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'

@Component({})
export default class CUCustomerAccountCard extends Vue {
  @Prop({ required: false, default: null }) readonly customerAccountId!: number
  @Prop({ required: false }) readonly label!: string
  @Prop({ required: false, default: 'white' }) readonly background!: string
  @Prop({ required: false, default: '0' }) readonly offset!: string
  @Prop({ required: false, default: '324' }) readonly width!: string
  @Prop({ required: false, default: null }) readonly groupId: number
  @Prop({ required: false, default: () => [] }) readonly actions!: Action[]
  @Prop({ type: Boolean, default: false }) readonly summary!: boolean
  @Prop({ type: Boolean, default: false }) readonly quoteSummary!: boolean
  @Prop({ type: Boolean, default: false }) readonly unsetWidth!: boolean
  @Prop({ type: Boolean, default: false }) readonly persistSelect!: boolean
  @Prop({ type: Boolean, default: false }) readonly linkContact!: boolean
  @Prop({ type: Boolean, default: false }) readonly hideLoader!: boolean
  @Prop({ type: Boolean, default: false }) readonly hideAddGroup!: boolean
  @Prop({ type: Boolean, default: false }) readonly hideTag!: boolean

  customerAccount: CustomerAccountDetailEntity | null = null
  groups: CustomerAccountGroup[] = null
  selectedGroupId: number = null
  showGroupSelect = false

  state = quote

  countryPhoneFormatFilter = countryPhoneFormatFilter

  @Watch('customerAccountId')
  onCustomerAccountIdChange(customerAccountId: number): void {
    this.loadUser(customerAccountId)
  }

  @Watch('groupId', { immediate: true })
  onGroupIdChange(): void {
    this.selectedGroupId = this.groupId
  }

  get website(): string {
    return this.customerAccount?.websiteUrl
  }

  get loaderHeight(): string {
    return this.summary ? '64px' : '129px'
  }

  get selectedGroup(): CustomerAccountGroup {
    return this.groups?.find(
      (group) => group.customerAccountGroupId === this.selectedGroupId
    )
  }

  get isConverted(): boolean {
    return !!this.state.quote?.isConverted
  }

  // TODO: surface on backend
  get owner(): string {
    return this.customerAccount?.owner || 'Noam Beno'
  }

  handleCreateNewGroup(): void {
    sidebar.push({
      component: CompanySidebarDetail,
      props: { customerAccountId: this.customerAccountId },
      on: { refresh: this.load },
    })
  }

  handleHideGroups(): void {
    this.showGroupSelect = false
    this.$emit('group:hide')
  }

  handleSelectGroup(id: number): void {
    this.$emit(
      'group:change',
      this.groups.find((group) => group.customerAccountGroupId === id)
    )
    this.selectedGroupId = id
    this.showGroupSelect = false
  }

  handleActionClick(event: string): void {
    if (event === 'contact:group-add' || event === 'contact:group-edit') {
      this.showGroupSelect = true
    } else if (event === 'contact:group-delete') {
      this.showGroupSelect = false
    }
    this.$emit(event)
  }

  get canViewContacts(): boolean {
    return auth.getUserRoleNames.includes(ACCESS_SETTINGS_ROLES.CONTACTS)
  }

  handleNameClick(): void {
    if (!this.linkContact || !this.canViewContacts) {
      return
    }
    sidebar.push({
      component: CompanySidebarDetail,
      props: { customerAccountId: this.customerAccountId },
      on: { refresh: this.loadUser },
    })
  }

  loadUser(customerAccountId: number = this.customerAccountId): void {
    if (!customerAccountId) {
      return
    }
    client
      .byId(this.customerAccountId, { slim: true })
      .then((res) => res.data)
      .then((customerAccount) => (this.customerAccount = customerAccount))
  }

  async load(): Promise<void> {
    const filters = filter()
    const parentFilter = filters.createParent('and')
    filters.add(parentFilter, {
      column: {
        _t_id: v4(),
        value: 'customerAccountId',
        filterType: 'eq',
      },
      value: this.customerAccountId,
    })
    const params = {
      page: 1,
      pageSize: -1,
      filters: filters.asQueryParams(),
      sorts: undefined,
    }
    const groups = await gclient.tableView(params)
    this.groups = groups.data.resultList
  }

  mounted(): void {
    EventBus.$on('customer-account:refresh', this.loadUser)

    this.load()
    this.loadUser()
  }

  beforeDestroy(): void {
    EventBus.$off('customer-account:refresh', this.loadUser)
  }
}
