
import { Prop, Watch } from 'vue-property-decorator'
import Component, { mixins } from 'vue-class-component'
import CUDatePickerCalendar from '@/components/CUDatePickerCalendar.vue'
import CUDatePickerInput from '@/components/CUDatePickerInput.vue'
import CUDatePickerButton from '@/components/CUDatePickerButton.vue'
import CUDatePickerPredefined from '@/components/CUDatePickerPredefined.vue'
import CUDatePickerFooter2 from '@/components/CUDatePickerFooter2.vue'
import CUDatePickerLabel from '@/components/CUDatePickerLabel.vue'
import { predefinedFutureDate, predefinedPastDate } from '@/utils/datePicker'
import DateMixin from '@/mixins/DateMixin'
import dayjs from 'dayjs'
import auth from '@/store/modules/auth'

@Component({
  components: {
    CUDatePickerCalendar,
    CUDatePickerInput,
    CUDatePickerButton,
    CUDatePickerPredefined,
    CUDatePickerFooter2,
    CUDatePickerLabel,
  },
  inheritAttrs: false,
})
export default class CUDatePicker2 extends mixins(DateMixin) {
  @Prop({ required: false, type: Boolean }) displayAsButton!: boolean
  @Prop({ required: false, type: Boolean }) displayAsLabel!: boolean
  @Prop({ required: false, type: Boolean }) isRange!: boolean
  @Prop({ required: false, type: Boolean }) hidePredefined!: boolean
  @Prop({ required: false, type: Boolean }) hideFooterInputs!: boolean
  @Prop({ required: false, type: Boolean }) hideDetails!: boolean
  @Prop({ required: false }) nudgeTop!: string | number
  @Prop({ required: false }) nudgeBottom!: string | number
  @Prop({ required: false }) nudgeLeft!: string | number
  @Prop({ required: false, type: Boolean }) attach!: boolean
  @Prop({ required: false, type: Boolean }) persistentBorder!: boolean
  @Prop({ required: false, type: Boolean }) persistentLabel!: boolean
  @Prop({ required: false, type: Boolean }) boldValue!: boolean
  @Prop({ required: false, default: undefined }) defaultDisplayedDate!: string
  @Prop({ required: false, type: Boolean }) error!: boolean
  @Prop({ required: false, type: Boolean }) expanded!: boolean
  @Prop({ required: false, default: undefined }) errorMessages!: string[]
  @Prop({ required: false }) width!: number | string
  @Prop({ required: false, default: undefined }) predefinedDateKeys!: string[]

  // A string in YYYY-MM-DD format or an array of two strings in YYYY-MM-DD format
  @Prop({ required: false }) value!: string | string[]

  predefinedValue = null
  menuValue = false
  cachedStartDate = null
  cachedEndDate = null
  staticClass = ''
  staticStyle = ''

  @Watch('menuValue')
  onMenuValueChange(newValue: boolean): void {
    if (!this.isRange || !newValue) {
      return
    }

    this.cachedStartDate = this.value?.[0]
    this.cachedEndDate = this.value?.[1]
  }

  // When the value changes for the date picker, check if it matches
  // one of our predefined options -- if so, set the predefined value
  @Watch('value', { immediate: true })
  onValueChange(value: string | string[]): void {
    if (!this.isRange || !Array.isArray(value) || !value) {
      return
    }

    if (value.includes(predefinedFutureDate)) {
      this.predefinedValue = 'future'
    } else if (value.includes(predefinedPastDate)) {
      this.predefinedValue = 'past'
    } else if (value[0] === value[1]) {
      const currentDate = dayjs().tz(this.tz).format('YYYY-MM-DD')
      const tomorrowDate = dayjs()
        .tz(this.tz)
        .add(1, 'day')
        .format('YYYY-MM-DD')

      if (value.includes(currentDate)) {
        this.predefinedValue = 'today'
      } else if (value.includes(tomorrowDate)) {
        this.predefinedValue = 'tomorrow'
      } else {
        this.predefinedValue = null
      }
    } else {
      this.predefinedValue = null
    }
  }

  get tz(): string {
    return auth.getUserTimeZone
  }

  get componentClass(): string {
    return this.$vnode.data.staticClass
  }

  get componentStyle() {
    return this.$vnode.data?.style
  }

  get inputProps(): Record<string, string | boolean | number> {
    return { ...this.$props, ...this.$attrs }
  }

  get shouldDisplayFooter(): boolean {
    return this.isRange || this.hideFooterInputs
  }

  get shouldDisplayPredefined(): boolean {
    if (this.hidePredefined) {
      return false
    } else if (this.isRange || !!this.predefinedDateKeys) {
      return true
    }
    return false
  }

  get menuNudgeTop(): number | string {
    if (this.nudgeTop) {
      return this.nudgeTop
    }

    if (this.nudgeBottom) {
      return null
    }

    if (this.displayAsButton) {
      return '0px'
    }

    if (!this.hideDetails) {
      return '18px'
    }

    return null
  }

  handleInput(e: string): void {
    this.$nextTick(() => {
      this.$emit('input', e)
    })
  }

  handleInputClear(): void {
    this.$emit('clear')
    this.predefinedValue = null
  }

  handleCalendarInput(e: string | string[]): void {
    this.$emit('input', e)
    if (!this.shouldDisplayFooter) {
      this.menuValue = false
    }
  }

  handleCalendarDayClick(): void {
    this.predefinedValue = null
  }

  handleFooterChange(): void {
    this.predefinedValue = null
  }

  handleFooterConfirm(): void {
    this.$emit('confirm')
    this.menuValue = false
  }

  handleFooterCancel(): void {
    this.menuValue = false
    this.$emit(
      'input',
      [this.cachedStartDate, this.cachedEndDate].filter((d) => !!d)
    )
  }

  handlePredefinedToday(): void {
    const today = dayjs().tz(this.tz).format('YYYY-MM-DD')
    const dates = this.isRange ? [today, today] : today
    this.$emit('input', dates)
  }

  handlePredefinedTomorrow(): void {
    const tomorrow = dayjs().tz(this.tz).add(1, 'day').format('YYYY-MM-DD')
    const dates = this.isRange ? [tomorrow, tomorrow] : tomorrow
    this.$emit('input', dates)
  }

  handlePredefinedFuture(): void {
    const today = dayjs().tz(this.tz).format('YYYY-MM-DD')
    const dates = [today, predefinedFutureDate]
    this.$emit('input', dates)
  }

  handlePredefinedPast(): void {
    const today = dayjs().tz(this.tz).format('YYYY-MM-DD')
    const dates = [predefinedPastDate, today]
    this.$emit('input', dates)
  }
}
