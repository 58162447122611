import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { apiBaseUrl } from '@/utils/env'

const httpService: HttpService = new HttpService()

export default {
  count(): Promise<
    AxiosResponse<{
      numResWithUnassignedDrivers: number
      numResWithUnassignedVehicles: number
      numDraftAssignments: number
    }>
  > {
    const url = `https://${apiBaseUrl()}/tripAssignments/count`
    return httpService.get(url)
  },
  setStatus(
    id: number,
    payload: { isConfirmed: boolean; isDriver: boolean }
  ): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/tripAssignments/setStatus/${id}`
    return httpService.post(url, payload)
  },
  confirmAll(): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/tripAssignments/confirmAll`
    return httpService.get(url)
  },
}
