
import { Vue, Component, Prop } from 'vue-property-decorator'
import type from '@/services/type'
import { VehicleType } from '@/models/dto'
import CUCheckbox from '@/components/CUCheckbox.vue'
import GrayBus from '@/assets/images/GrayBus.vue'

@Component({
  components: {
    CUCheckbox,
    GrayBus,
  },
})
export default class CUVehicleSelect extends Vue {
  @Prop({
    required: true,
    default: () => [],
  })
  items!: any[]

  @Prop({
    required: false,
  })
  placeholder: string

  @Prop({
    required: false,
  })
  label: string

  @Prop({
    required: false,
    default: () => [],
  })
  rules: any[]

  @Prop({
    required: false,
    default: null,
  })
  avatar: string

  @Prop({
    required: false,
    default: 'border-radius-top-left-10 border-radius-bottom-left-10',
  })
  avatarBorderRadius: string

  @Prop({
    required: false,
    default: 'border-radius-top-right-10 border-radius-bottom-right-10',
  })
  avatarRightBorderRadius: string

  @Prop({
    required: false,
    default: null,
  })
  defaultType: string

  @Prop({
    required: false,
    default: null,
  })
  selectedVehicleName: string

  @Prop({
    required: false,
    default: null,
  })
  selectedVehicleId: number

  @Prop({
    required: false,
    default: null,
  })
  selectedVehicleTypeId: number

  @Prop({
    type: Boolean,
    default: false,
  })
  canSelectType: boolean

  @Prop({
    required: false,
    default: null,
  })
  errorMessage: string

  @Prop({
    type: Boolean,
    default: false,
  })
  disabled: boolean

  @Prop({
    type: Boolean,
    default: false,
  })
  disableVehicleType: boolean

  showSpecific = true
  vehicleTypes: VehicleType[] = []
  selectedVehicleTypes: { id: number; selected: boolean }[] = null
  open = false
  searchTerm = ''

  get selectionName(): string {
    return this.selectedVehicleName
  }

  get allCleared(): boolean {
    return (
      this.selectedVehicleTypes?.every((el) => el.selected === false) || false
    )
  }

  get filteredItems(): any {
    return this.items?.filter((item) => {
      const isItemTypeSelected = this.selectedVehicleTypes?.find(
        (type) => type.id === item.vehicleTypeId
      )?.selected
      const isItemSearched = item.vehicleName
        ?.toLowerCase()
        .includes(this.searchTerm.toLowerCase())
      return (
        (isItemTypeSelected && isItemSearched) ||
        (this.allCleared && isItemSearched)
      )
    })
  }

  get filteredTypes(): VehicleType[] {
    return this.vehicleTypes?.filter((type) => {
      const isItemSearched = type.label
        ?.toLowerCase()
        .includes(this.searchTerm.toLowerCase())
      return isItemSearched
    })
  }

  get borderColor(): string {
    return this.open && !this.avatar
      ? 'primary'
      : 'gray-border-mid'
  }

  handleClickAll(): void {
    if (this.disableVehicleType) {
      return
    }
    this.selectedVehicleTypes = this.selectedVehicleTypes.map((type) => {
      return { ...type, selected: true }
    })
  }

  handleClickClear(): void {
    if (this.disableVehicleType) {
      return
    }
    this.selectedVehicleTypes = this.selectedVehicleTypes.map((type) => {
      return { ...type, selected: false }
    })
    this.searchTerm = ''
  }

  handleVehicleTypeChange(i: number): void {
    Vue.set(this.selectedVehicleTypes, i, {
      ...this.selectedVehicleTypes[i],
      selected: !this.selectedVehicleTypes[i].selected,
    })
  }

  handleSelectVehicle(id: number): void {
    this.$emit('change:vehicle', id)
    this.$emit('change', id)
    this.open = false
  }

  handleSelectVehicleType(id: number): void {
    this.$emit('change:type', id)
    this.$emit('change', id)
    this.open = false
  }

  handleClickAvatar(e): void {
    if (this.avatar !== 'driver' && this.avatar !== 'vehicle') {
      this.$emit('avatar:click')
    }
  }

  isVehicleTypeSelected(id: number): boolean {
    return this.selectedVehicleTypes.find(
      (vehicleType) => vehicleType.id === id
    ).selected
  }

  async load(): Promise<void> {
    const response = await type.vehicleTypeTableView({ pageSize: -1, page: 1 })
    this.vehicleTypes = [...response.data.resultList]
    this.selectedVehicleTypes = this.vehicleTypes.map((type) => {
      return {
        id: type.id,
        selected: this.defaultType?.includes(type.label) || false,
      }
    })
    this.showSpecific = !this.canSelectType
  }

  mounted(): void {
    this.load()
  }
}
