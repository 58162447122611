import op from 'simple-object-path'

export const groupBy =
  (key: string) =>
  <T>(acc: Record<string, T[]>, x: T): Record<string, T[]> => {
    const k = String(op(x, key))
    if (!k) {
      return acc
    } else if (!acc[k]) {
      acc[k] = [x]
    } else {
      acc[k] = [...acc[k], x]
    }
    return acc
  }

export const mergeBy =
  <T>(key: string, mergeFn: (x: T, y: T) => T) =>
  (acc: Record<string, T>, x: T): Record<string, T> => {
    const k = String(op(x, key))
    if (!k) {
      return acc
    } else if (!acc[k]) {
      acc[k] = x
    } else {
      acc[k] = mergeFn(acc[k], x)
    }
    return acc
  }

export const distinctBy =
  <T>(key: string) =>
  (acc: Record<string, T>, x: T): Record<string, T> => {
    const k = String(op(x, key))
    if (!k) {
      return acc
    } else if (!acc[k]) {
      acc[k] = x
    }
    return acc
  }

export const sumBy =
  <T>(key: string) =>
  (acc: number, x: T): number => {
    const value = op(x, key)
    if (typeof value === 'string') {
      const parsed = Number.parseFloat(value)
      return !isNaN(parsed) ? acc + parsed : acc
    }
    return typeof value === 'number' ? acc + value : acc
  }

export const sum = (acc: number, val: number): number => acc + val

export const containsTruthyValue = (obj: Record<string, boolean>): boolean => {
  return Object.values(obj).reduce((acc, val) => acc || val, false)
}
