var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-menu',{attrs:{"offset-y":"","max-width":"min-content","content-class":"margin-t-n5","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('CUTextField',_vm._g(_vm._b({staticClass:"margin-b-n6 cu-vehicle-select",attrs:{"readonly":"","error-messages":_vm.errorMessage,"value":_vm.selectedVehicleName,"label":_vm.selectedVehicleName && _vm.label,"disabled":_vm.disabled,"manual-border-color":"","border-color":_vm.borderColor,"placeholder":`Select ${_vm.defaultType || 'Vehicle'}`,"rules":_vm.rules},scopedSlots:_vm._u([(!_vm.disabled)?{key:"append",fn:function(){return [(_vm.$scopedSlots.tag)?_vm._t("tag"):_vm._e(),_c('CUIcon',{staticClass:"cursor-pointer",attrs:{"aria-label":"Dropdown","color":"gray-icon-light"},on:{"click":on.click}},[_vm._v(" keyboard_arrow_down ")]),(_vm.avatar && _vm.avatarRightBorderRadius)?_c('div',{staticClass:"position-absolute top-0 right-0"},[_c('div',{staticClass:"background-white position-absolute top-0 bottom-0 right-0 w-8 h-44 d-flex align-center justify-center cursor-pointer border-y-1 border-r-1 border-l-0 border-solid",class:{
                'border-red-error': _vm.errorMessage && !_vm.disabled,
                'border-gray-border-mid': !_vm.errorMessage || _vm.disabled,
                [`${_vm.avatarRightBorderRadius}`]: _vm.avatarRightBorderRadius,
              },on:{"click":function($event){$event.stopPropagation();return _vm.handleClickAvatar.apply(null, arguments)}}})]):_vm._e()]},proxy:true}:null,(_vm.avatar)?{key:"prepend-inner",fn:function(){return [_c('div',{staticClass:"w-40 h-44 margin-a-0"}),_c('div',{staticClass:"background-secondary-light position-absolute top-0 bottom-0 left-0 w-44 d-flex align-center justify-center cursor-pointer border-y-1 border-l-1 border-r-0 border-solid",class:{
              'background-additional-red-2':
                _vm.avatar === 'conflict' && !_vm.disabled,
              'background-bg-gray-2':
                _vm.avatar === 'driver' || _vm.avatar === 'vehicle' || _vm.disabled,
              'background-secondary-light': _vm.avatar,
              'border-red-error': _vm.errorMessage && !_vm.disabled,
              'border-gray-border-mid': !_vm.errorMessage || _vm.disabled,
              [`${_vm.avatarBorderRadius}`]: _vm.avatarBorderRadius,
            },on:{"click":function($event){$event.stopPropagation();return _vm.handleClickAvatar.apply(null, arguments)}}},[(_vm.avatar === 'conflict')?_c('CUIcon',{key:"cu-vehicle-select-avatar-warning",attrs:{"width":"20px","color":_vm.disabled ? 'bg-gray-5' : 'additional-red'}},[_vm._v(" warning ")]):(_vm.avatar === 'vehicle_confirmed')?_c('CUIcon',{key:"cu-vehicle-select-avatar-confirmed-bus",attrs:{"width":"20px","color":"secondary"}},[_vm._v(" bus ")]):(_vm.avatar === 'vehicle')?_c('CUIcon',{key:"cu-vehicle-select-avatar-unassigned-bus",attrs:{"width":"20px","color":"bg-gray-5"}},[_vm._v(" bus ")]):_c('div',{staticClass:"d-flex align-center justify-center w-28 h-28 rounded-circle",class:{
                'background-secondary': !_vm.disabled,
                'background-bg-gray-5': _vm.disabled,
              }},[_c('p',{staticClass:"text-white font-12 font-bold capitalize"},[_vm._v(" "+_vm._s(_vm.avatar.toUpperCase())+" ")])])],1)]},proxy:true}:null],null,true)},'CUTextField',attrs,false),on))]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[(_vm.showSpecific)?_c('div',{staticClass:"d-flex padding-l-4 padding-y-2 margin-b-1"},[_c('div',{staticClass:"min-w-128 padding-r-4 border-l-0 border-t-0 border-b-0 border-r-1 border-solid border-bg-gray-3"},[_c('p',{staticClass:"font-bold margin-b-2"},[_vm._v("Vehicle Type")]),_c('div',{staticClass:"d-flex margin-b-4"},[_c('p',{staticClass:"font-bold margin-r-6",class:{
              'text-secondary cursor-pointer': !_vm.disableVehicleType,
              'text-secondary-light': _vm.disableVehicleType,
            },on:{"click":_vm.handleClickAll}},[_vm._v(" All ")]),_c('p',{staticClass:"font-bold",class:{
              'text-secondary cursor-pointer': !_vm.disableVehicleType,
              'text-secondary-light': _vm.disableVehicleType,
            },on:{"click":_vm.handleClickClear}},[_vm._v(" Clear ")])]),_vm._l((_vm.vehicleTypes),function(type,i){return _c('div',{key:`vehicle-type-${type.id}`},[_c('CUCheckbox',{staticClass:"margin-b-4",attrs:{"hide-details":"","ripple":false,"disabled":_vm.disableVehicleType,"input-value":_vm.isVehicleTypeSelected(type.id),"label":type.label},on:{"change":function($event){return _vm.handleVehicleTypeChange(i)}}})],1)})],2),_c('div',{staticClass:"flex-grow-1"},[_c('div',[(_vm.canSelectType)?[_c('div',{staticClass:"margin-x-2 margin-b-1 padding-x-3 padding-y-2 border-radius-10 cursor-pointer hover:background-bg-gray-1 transition-duration-300",style:({ transitionProperty: 'background' }),on:{"click":function($event){_vm.showSpecific = false}}},[_c('p',[_vm._v("Select Vehicle Type")])]),_c('div',{staticClass:"margin-x-2 padding-x-3 padding-y-2 border-radius-10 background-primary-10 cursor-pointer"},[_c('p',{staticClass:"text-primary font-semibold"},[_vm._v(" Select Specific Vehicle ")])]),_c('v-divider',{staticClass:"margin-a-2"})]:_vm._e(),_c('CUTextField',{staticClass:"margin-x-2 margin-b-2",attrs:{"border-color":"gray-border-mid","placeholder":"Search","hide-details":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('CUIcon',{style:({ marginTop: '-2px' }),attrs:{"width":"16px","color":"gray-icon-light"}},[_vm._v(" search ")])]},proxy:true}],null,false,1435944122),model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],2),_c('CUScroll',{staticClass:"min-w-184",attrs:{"height":"410px","no-fade":""}},_vm._l((_vm.filteredItems),function(item){return _c('div',{key:item.vehicleId,staticClass:"cursor-pointer padding-y-2 padding-l-4 padding-r-2 transition-ease transition-duration-300",class:{
              'background-primary-10 hover:none':
                item.vehicleId === _vm.selectedVehicleId,
              'background-white hover:background-bg-gray-1':
                item.vehicleId !== _vm.selectedVehicleId,
            },on:{"click":function($event){return _vm.handleSelectVehicle(item.vehicleId)}}},[_c('div',{staticClass:"d-flex align-start justify-space-between w-full"},[_c('div',{staticClass:"font-14"},[_vm._v(_vm._s(item.vehicleName))]),(item.isInactiveFromTimeOff)?_c('CUTag',{attrs:{"tag":"Inactive","border":"gray-text-dark","background":"bg-gray-2","denser":""}}):_vm._e()],1)])}),0)],1)]):_c('div',{staticClass:"padding-y-2"},[_c('div',{staticClass:"padding-x-3 margin-b-1 padding-y-2 margin-x-2 border-radius-10 background-primary-10 cursor-pointer"},[_c('p',{staticClass:"text-primary font-semibold"},[_vm._v("Select Vehicle Type")])]),_c('div',{staticClass:"padding-x-3 padding-y-2 margin-x-2 border-radius-10 cursor-pointer hover:background-bg-gray-1 transition-duration-300",style:({ transitionProperty: 'background' }),on:{"click":function($event){_vm.showSpecific = true}}},[_c('p',[_vm._v("Select Specific Vehicle")])]),_c('div',{staticClass:"margin-x-2 margin-t-2"},[_c('v-divider')],1),_c('CUScroll',{attrs:{"height":"375px","no-fade":""}},[_c('v-list',{staticClass:"margin-l-2"},_vm._l((_vm.filteredTypes),function(item){return _c('v-list-item',{key:`vehicle-type-${item.id}`,staticClass:"font-14 cursor-pointer transition-ease transition-duration-300",class:{
              'background-primary-10 hover:none':
                item.id === _vm.selectedVehicleTypeId,
              'background-white hover:background-bg-gray-1':
                item.id !== _vm.selectedVehicleTypeId,
            },on:{"click":function($event){return _vm.handleSelectVehicleType(item.id)}}},[_c('div',[_vm._v(_vm._s(item.label))])])}),1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }