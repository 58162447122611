import { Address } from '@/models/dto'
import dayjs from 'dayjs'

export const isNotEmpty = (val: any): boolean => {
  return !!val
}

export const isNotEmptyInput = (val: any): boolean => {
  return !(
    val === '' ||
    val == null ||
    val?.length === 0 ||
    (isNaN(val) && typeof val === 'number') ||
    (typeof val === 'object' && !Object.keys(val).length)
  )
}

export const isNotEmptyArray = (val: any): boolean => {
  if (!val) {
    return false
  }
  if (!val?.length) {
    return false
  }
  return true
}

export const isNotNegative = (val: any): boolean => {
  if (isNaN(Number(val))) {
    return true
  }
  return Number(val) >= 0
}

export const verifyPhoneLength = (phoneNumber: string): boolean => {
  return (
    phoneNumber != null &&
    (phoneNumber.replace(/[^0-9]/g, '').length === 10 ||
      phoneNumber.replace(/[^0-9]/g, '').length === 12)
  )
}

export const isCardNumberValidLength = (cardNumber: string): boolean => {
  if (!cardNumber) {
    return false
  }
  cardNumber = cardNumber.split(/[^\d]/).join('')
  if (cardNumber.length < 15 || cardNumber.length > 16) {
    return false
  }
  return true
}

export const isFutureDate = (curr: string, next: string): boolean => {
  return dayjs(next).isSameOrAfter(curr, 'day')
}

// Source: https://v2.vuejs.org/v2/cookbook/form-validation.html?redirect=true#Using-Custom-Validation
export const isValidEmail = (email: string): boolean => {
  return (
    isNotEmpty(email) &&
    !!email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  )
}

export const hasRequiredAddressFields = (address: Address): boolean => {
  return (
    !address ||
    Object.keys(address).length === 0 ||
    Object.values(address).every((el) => !el) ||
    (!!address.city && !!address.state && !!address.postalCode)
  )
}

export const isValidAddress = (address: Address): boolean => {
  return (
    !!address &&
    typeof address === 'object' &&
    Object.keys(address).includes('addressId')
  )
}

export const isDateFormat = (input: string, format: string): boolean => {
  if (!input) {
    return true
  }

  // Convert the format to a regular expression pattern
  let pattern = format
    .replace(/#/g, '\\d') // Replace # with \d (digit)
    .replace(/\//g, '\\/') // Escape slashes

  // Match the input string against the pattern
  let regex = new RegExp(`^${pattern}$`)
  return regex.test(input)
}
