import ApolloClient, { InMemoryCache } from 'apollo-boost'
import VueApollo from 'vue-apollo'
import { typeDefs } from './typedefs'
import { load } from '@/utils/localStorage'
import { apiBaseUrl } from '@/utils/env'

export const client = new ApolloClient({
  uri: `https://${apiBaseUrl()}/graphql`,
  cache: new InMemoryCache(),
  typeDefs,
  request: (operation) => {
    const token = load('token')
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    })
  },
})

export const apolloProvider = new VueApollo({
  defaultClient: client,
})
