
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import dayjs from 'dayjs'
import VehicleTypes from '@/components/VehicleTypes.vue'
import { states } from '@/utils/states'
import EditableAutocompleteAddress from '@/components/EditableAutocompleteAddress.vue'
import { Element } from '@/models/dto/Action'
import deepClone from '@/utils/deepClone'
import { vehicleTypeOrder } from '@/utils/vehicle'
import { UserDetailDriver } from '@/models/dto/UserDetailDriver'
import { Note } from '@/models/dto/Note'

@Component({
  components: {
    VehicleTypes,
    EditableAutocompleteAddress,
  },
})
export default class DriverInfoDetailsTab extends Vue {
  @Prop({ required: true }) driver: UserDetailDriver

  dayjs = dayjs
  states = states
  Note = Note

  notes = ''

  @Watch('driver', { immediate: true, deep: true })
  handleSetNotes(): void {
    this.notes = this.driver?.driverNotes?.[0]?.html || ''
  }

  handleUpdateNote(html: string): void {
    const newNote = deepClone(this.driver?.driverNotes?.[0] || {})
    this.$emit('update', { driverNotes: [{ ...newNote, html }] })
  }

  get tagCloudList(): Element[] {
    const vehicleTypes = deepClone(this.driver.driverSupportedVehicles)
    return (vehicleTypes || [])
      .map((t) => ({
        id: t.vehicleTypeId,
        label: t.label,
        active: t.supported,
      }))
      .sort(
        (a, b) =>
          vehicleTypeOrder.indexOf(a.label) - vehicleTypeOrder.indexOf(b.label)
      )
  }

  handleUpdateVehicleTypes(list: Element[]): void {
    const driverSupportedVehicles = deepClone(
      this.driver.driverSupportedVehicles
    )
    driverSupportedVehicles.map((vehicle) => {
      vehicle.supported = list.find(
        ({ id }) => id === vehicle.vehicleTypeId
      ).active
    })
    this.$emit('update', { driverSupportedVehicles })
  }
}
