
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class CUQuickbooksCustomerSync extends Vue {
  @Prop({ required: true, default: null }) companyId: number
  @Prop({ required: false, default: false }) hideToggle: boolean
  @Prop({ required: false, default: null }) quickbooksCustomerId: string
  @Prop({ required: false, default: false })
  createNewQuickbooksCustomer: boolean
  @Prop({ required: false, default: () => [] }) quickbooksContacts: any[]
  @Prop({ required: false, default: false })
  syncWithQuickBooks: boolean
  @Prop({ required: false, default: false }) error: boolean

  CREATE_NEW_PLACEHOLDER = {
    text: 'CREATE_NEW_PLACEHOLDER',
    value: 'CREATE_NEW_PLACEHOLDER',
  }

  get items(): any[] {
    if (this.createNewQuickbooksCustomer) {
      return [this.CREATE_NEW_PLACEHOLDER, ...this.quickbooksContacts]
    }
    return [...this.quickbooksContacts]
  }

  toggleSyncWithQuickbooks(): void {
    if (!this.syncWithQuickBooks) {
      this.$emit('update:sync-toggle', true)
      return
    }
    const syncData = { sync: false, contactId: null }
    this.$emit('update:sync-toggle', false)
    this.$emit('update:sync', syncData)
  }

  get selectValue(): any {
    return this.createNewQuickbooksCustomer
      ? this.CREATE_NEW_PLACEHOLDER
      : this.quickbooksCustomerId
  }

  handleSelectQuickbooksContact(
    value: string,
    createNewCustomer = false
  ): void {
    let syncData
    if (createNewCustomer) {
      syncData = { sync: true, contactId: null, createNewCustomer: true }
    } else if (value === null) {
      syncData = { sync: false, contactId: value }
    } else {
      syncData = { sync: true, contactId: value }
    }
    this.$emit('update:sync', syncData)
  }
}
