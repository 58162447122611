
import sidebar from '@/store/modules/sidebar'
import { EventBus } from '@/utils/eventBus'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import CreateContactForm from '@/components/CreateContactForm.vue'
import CreateCompanyForm from '@/components/CreateCompanyForm.vue'
import { ContactRequest, ContactTypeKey } from '@/models/dto/Customer'
import { UserDetail } from '@/models/dto'

@Component({
  components: {
    CreateContactForm,
    CreateCompanyForm,
  },
})
export default class CreateContactSidebar extends Vue {
  @Prop({ default: false, type: Boolean }) readonly companyOnly: boolean
  @Prop({ default: false, required: false })
  readonly preventUpdateCustomer: boolean
  @Prop({ default: false, required: false })
  readonly preventGlobalEmit: boolean
  @Prop({ required: false, default: '' }) readonly contactType: ContactTypeKey
  @Prop({ required: false, default: null }) tripId: number
  @Prop({ required: false, default: undefined })
  readonly prepopulateContact: Partial<ContactRequest>
  @Prop({ required: false, default: false })
  displayBulkImport: boolean

  isCompanyFormDisplayed = false
  newCompany = null
  newContact = null
  submitting = false

  @Watch('companyOnly', { immediate: true })
  handleChangeCompanyOnly(): void {
    this.isCompanyFormDisplayed = this.companyOnly
  }

  get isTripContact(): boolean {
    return this.contactType === 'Trip'
  }

  handleSwitchToCompany(): void {
    this.isCompanyFormDisplayed = true
  }

  handleCancelCompany(): void {
    this.isCompanyFormDisplayed = false
    if (this.companyOnly) {
      sidebar.pop()
    }
  }

  handleCompanyCreated(company: { id: number; name: string }): void {
    this.newCompany = company
    this.isCompanyFormDisplayed = false
    this.submitting = false
    if (this.companyOnly) {
      this.$emit('submit', company.id)
      if (!this.preventUpdateCustomer) {
        EventBus.$emit('contact-search:new-company', company.id)
      }
      sidebar.pop()
    }
  }

  handleContactCreated(customer: UserDetail): void {
    this.newContact = customer
    const simpleContact = {
      id: customer.userId,
      ...customer,
    }

    this.$emit('submit', customer.userId)
    this.$emit('submit:contact', simpleContact)
    if (!this.preventGlobalEmit) {
      EventBus.$emit('contact-sidebar:update', {
        contact: simpleContact,
        contactType: this.contactType,
        tripId: this.tripId === null ? -1 : this.tripId,
      })
    }

    EventBus.$emit('contact-sidebar:update-quote-contacts')
  }

  mounted(): void {
    this.isCompanyFormDisplayed = this.companyOnly
  }
}
