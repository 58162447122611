
import { Prop, Watch } from 'vue-property-decorator'
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import auth from '@/store/modules/auth'
import { Comment } from '@/models/Comment'
import dayjs from 'dayjs'

@Component({})
export default class CUCommentArea extends mixins(DateMixin) {
  @Prop({ required: false, default: () => [], type: Array })
  readonly comments!: Comment[]
  @Prop({ required: false, default: '176px' })
  readonly commentAreaHeight: string

  newComment = ''
  auth = auth
  scroll: number = null

  @Watch('comments', { immediate: true })
  onCommentChange(): void {
    this.$nextTick(() => this.scrollToBottom())
  }

  scrollToBottom(): void {
    if (!this.comments.length) {
      return
    }
    this.scroll = this.$refs['comment-area-wrapper']['clientHeight']
  }

  commentDateTimeHandler(comment: Comment): string {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const date = dayjs(comment.createdAt).toISOString()
    return this.formatMediumDateShortTime(date, {
      tz: timezone,
      showMeridianUpper: true,
    })
  }

  submitNote(): void {
    if (!this.newComment) {
      return
    }
    this.$emit('submit:comment', this.newComment)
    this.newComment = ''
  }
}
