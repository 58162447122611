var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CUTextField',_vm._g(_vm._b({staticClass:"cu-currency-field",class:{
    'cu-text-field--prepend-inner-value': !_vm.negative && !_vm.hideCurrencyLabel,
    'cu-text-field--prepend-minus-dollar': _vm.negative && !_vm.hideCurrencyLabel,
  },attrs:{"type":"number","step":"0.01","min":"0","value":_vm.$attrs.value,"disabled":_vm.disabled},scopedSlots:_vm._u([(!_vm.hideCurrencyLabel)?{key:"prepend-inner",fn:function(){return [(!_vm.negative)?_c('span',{class:{
        'text-text-dark-gray': !_vm.disabled,
        'text-gray-text-light': _vm.disabled,
      },style:({ marginTop: '13px' })},[_vm._v(" $ ")]):_c('span',{staticClass:"text-text-dark-gray",style:({ marginTop: '13px' })},[_vm._v(" -$ ")])]},proxy:true}:null],null,true)},'CUTextField',_vm.$attrs,false),_vm.listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }