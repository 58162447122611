var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-expansion-panels',_vm._b({attrs:{"flat":"","accordion":"","readonly":_vm.readonly},on:{"change":function($event){return _vm.$emit('change', $event)}},model:{value:(_vm.expanded),callback:function ($$v) {_vm.expanded=$$v},expression:"expanded"}},'v-expansion-panels',_vm.$attrs,false),[(!_vm.items)?_c('v-expansion-panel',[(_vm.block)?_c('v-expansion-panel-header',{staticClass:"padding-x-5 padding-y-3 border-1 border-solid border-bg-gray-4 border-radius-top-left-10 border-radius-top-right-10 transition-all transition-duration-300",class:{
        [`padding-y-${_vm.paddingY} min-h-0`]: _vm.paddingY,
        'custom-expansion-block-shadow border-radius-bottom-left-10 border-radius-bottom-right-10':
          _vm.expanded !== 0,
        'background-bg-gray': _vm.expanded === 0,
      },attrs:{"hide-actions":""}},[(_vm.$scopedSlots.header)?_vm._t("header"):_vm._e()],2):_c('v-expansion-panel-header',{staticClass:"padding-x-0",class:{ [`padding-y-${_vm.paddingY} min-h-0`]: _vm.paddingY },scopedSlots:_vm._u([{key:"actions",fn:function(){return [(!_vm.readonly)?_c('CUIcon',{staticClass:"margin-r-1 transition-duration-300",class:{
            'rotate-n90': _vm.expanded === 0 && _vm.arrowLeft,
            'rotate-90': _vm.expanded === 0 && !_vm.arrowLeft,
          },attrs:{"aria-label":_vm.expanded === 0 ? 'Condense' : 'Expand',"color":_vm.expanded === 0 ? 'black' : 'gray-icon-light',"width":_vm.arrowWidth}},[_vm._v(" keyboard_arrow_right ")]):_vm._e(),(_vm.$scopedSlots.header && _vm.arrowLeft)?_vm._t("header"):(_vm.label && _vm.arrowLeft)?_c('p',{staticClass:"font-bold",style:({ marginTop: '2px' })},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()]},proxy:true}],null,true)},[(_vm.$scopedSlots.header && !_vm.arrowLeft)?_vm._t("header"):(_vm.label && !_vm.arrowLeft)?_c('span',{staticClass:"font-bold font-16"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e()],2),_c('v-expansion-panel-content',[_vm._t("default")],2)],1):_vm._l((_vm.items),function(item,idx){return _c('v-expansion-panel',{key:`expansion-panel-item-${idx}-${item}`,on:{"click":function($event){return _vm.handleUpdateItemExpanded(idx)}}},[_c('v-expansion-panel-header',{staticClass:"padding-x-0",class:{ [`padding-y-${_vm.paddingY} min-h-0`]: _vm.paddingY },scopedSlots:_vm._u([{key:"actions",fn:function(){return [(!_vm.readonly)?_c('CUIcon',{staticClass:"transition-duration-300",class:{ 'rotate-90': _vm.expanded === idx },attrs:{"aria-label":_vm.expanded === idx ? 'Condense' : 'Expand',"color":_vm.expanded === idx ? 'black' : 'gray-icon-light'}},[_vm._v(" keyboard_arrow_right ")]):_vm._e()]},proxy:true}],null,true)},[(_vm.$scopedSlots.header)?_vm._t("header",null,null,{ item, idx }):(_vm.label)?_c('span',{staticClass:"font-bold font-16"},[_vm._v(_vm._s(_vm.label))]):_vm._e()],2),_c('v-expansion-panel-content',[_vm._t("default",null,null,{ item, idx })],2)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }