
import ReservationStore from '@/store/modules/reservation'
import { Action } from '@/models/dto/Action'
import { Customer, SimpleContact } from '@/models/dto/Customer'
import { countryPhoneFormatFilter, phoneFormatFilter } from '@/utils/string'
import { AxiosResponse } from 'axios'
import { Component, Vue } from 'vue-property-decorator'
import CUContactCard from './CUContactCard.vue'
import contactClient from '@/services/customer'
import CustomerContactSearch from './CustomerContactSearch.vue'
import CUCustomerAccountCard from '@/components/CUCustomerAccountCard.vue'
import { SourceCategory } from '@/utils/enum'
import { Reservation } from '@/models/dto/Reservation'
import auth from '@/store/modules/auth'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'
import { AffiliateOffer } from '@/models/dto/Affiliate'

@Component({
  components: { CUContactCard, CustomerContactSearch, CUCustomerAccountCard },
})
export default class ReservationDetailPanelContactInfo extends Vue {
  allContacts: SimpleContact[] = null
  phoneFormatFilter = phoneFormatFilter

  loading = true

  tripContactActions: Action[] = [
    { label: 'Remove', event: 'contact:delete' },
    ...(this.canViewContacts ? [{ label: 'Edit', event: 'contact:edit' }] : []),
    { label: 'Change', event: 'contact:change' },
  ]

  get affiliateOffers(): AffiliateOffer[] {
    return (
      ReservationStore.reservation?.affiliateOffers.filter(
        (offer) => offer.isActive
      ) || []
    )
  }

  get reservation(): Reservation {
    return ReservationStore.reservation
  }

  get isReferral(): boolean {
    return this.reservation?.sourceCategory === SourceCategory.REFERRAL
  }

  get tripContact(): SimpleContact {
    const contact = ReservationStore.trip?.tripContact || {}
    return new SimpleContact({
      ...contact,
    })
  }

  get canViewContacts(): boolean {
    return auth.getUserRoleNames.includes(ACCESS_SETTINGS_ROLES.CONTACTS)
  }

  async getContacts(): Promise<void> {
    const response: AxiosResponse = await contactClient.tableView({
      pageSize: -1,
      page: 1,
    })

    // TODO: replace job title, customerSince, and owner once surfaced on backend
    this.allContacts = response.data.resultList.map((customer: Customer) => {
      return {
        customer,
        firstName: customer.firstName,
        lastName: customer.lastName,
        id: customer.customerId,
        email: customer.email,
        phone: countryPhoneFormatFilter(
          customer.phone,
          customer.phoneCountryKey
        ),
        company: customer.customerAccount,
        title: 'Owner',
        owner: null,
        customerSince: '02/01/22',
      }
    })
  }

  async mounted(): Promise<void> {
    await this.getContacts()
    this.loading = false
  }
}
