
import { Component, Prop, Vue } from 'vue-property-decorator'
import colors from '@/scss/_colors-export.scss'
import { hexToRgb } from '@/utils/color'
import { numberToPixels } from '@/utils/style'
@Component({})
export default class CUButton extends Vue {
  @Prop({ required: false, default: 'primary' })
  readonly color!: string

  @Prop({ required: false })
  readonly fontWeight!: string

  @Prop({ required: false, type: Boolean })
  readonly footer!: boolean

  @Prop({ required: false, default: undefined })
  readonly height!: number | string

  @Prop({ required: false, default: false, type: Boolean })
  readonly opaque!: boolean

  @Prop({ required: false, default: undefined })
  readonly paddingX!: string | number

  @Prop({ required: false, default: undefined })
  readonly paddingY!: string | number

  @Prop({ required: false, type: Boolean })
  readonly unsetWidth!: boolean

  @Prop({ required: false, default: 120 })
  readonly width!: string | number

  @Prop({ required: false, type: Boolean }) readonly hideBg!: boolean

  @Prop({ required: false, type: Boolean }) readonly small!: boolean

  get calculatedHeight(): string {
    if (this.height) {
      return numberToPixels(this.height)
    }
    return this.small ? '36px' : '40px'
  }

  get hasPrependIcon(): boolean {
    return !!this.$slots['prepend-icon']
  }

  get hasAppendIcon(): boolean {
    return !!this.$slots['append-icon']
  }

  get classes(): string {
    let classes = ''
    if (this.opaque) {
      classes = `${classes} cu-button--opaque`
    }
    if (this.hideBg) {
      classes = `${classes} cu-button--hide-bg`
    }

    return classes
  }

  get inlineStyles(): Record<string, string> {
    let styles: Record<string, string> = {}
    styles = { ...this.bgColorFaint }
    if (this.width && !this.unsetWidth) {
      styles.width = numberToPixels(this.width)
    }
    if (this.unsetWidth) {
      const paddingX = this.small ? '16px' : '24px'
      styles.paddingLeft = paddingX
      styles.paddingRight = paddingX
    }
    if (this.paddingX != null) {
      const paddingX = numberToPixels(this.paddingX)
      styles.paddingLeft = paddingX
      styles.paddingRight = paddingX
    }
    if (this.paddingY) {
      const paddingY = numberToPixels(this.paddingY)
      styles.paddingTop = paddingY
      styles.paddingBottom = paddingY
    }
    return styles
  }

  get bgColorFaint(): Record<string, string> {
    if (colors[this.color]) {
      const hex = hexToRgb(colors[this.color])
      if (hex) {
        const rgb = `rgba(${hex.r}, ${hex.g}, ${hex.b}, 0.1)`
        return {
          '--btn-color': rgb,
        }
      }
    }
    return {}
  }
}
