import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { DateInterval, DispatchResult } from '@/models/dto/Dispatch'

const httpService: HttpService = new HttpService()

export default {
  getDispatchData(
    payload: DateInterval
  ): Promise<AxiosResponse<DispatchResult>> {
    return httpService.post(
      `https://${apiBaseUrl()}/tripAssignments/getDispatchView`,
      payload
    )
  },
}
