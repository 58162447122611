import { apiBaseUrl } from '@/utils/env'
import {
  ApiResult,
  AvailableDriverListResult,
  AvailableVehicleListResult,
  DeleteAssignmentList,
  DriverStatusListResult,
  ReservationDriverAssignmentStatusResult,
  TripAssignmentListResult,
  UnvailableDriverListResult,
  UnvailableVehicleListResult,
} from '@/models/dto'
import { HttpService } from '@/services/common/HttpService'
import { Axios, AxiosResponse } from 'axios'

const httpService: HttpService = new HttpService()

interface ReservationIdPayload {
  reservationIds: number[]
}

export default {
  byReservationIds(
    reservationIds: number[]
  ): Promise<AxiosResponse<TripAssignmentListResult>> {
    const payload = { reservationIds }
    const host = apiBaseUrl()
    const url = `https://${host}/tripAssignments/reservation`
    return httpService.post<TripAssignmentListResult, ReservationIdPayload>(
      url,
      payload
    )
  },
  availableVehicles(
    companyId: number,
    reservationIds: number[]
  ): Promise<AxiosResponse<AvailableVehicleListResult>> {
    const payload = reservationIds
    const host = apiBaseUrl()
    const url = `https://${host}/tripAssignments/availableVehicles/${companyId}`
    return httpService.post<AvailableVehicleListResult, number[]>(url, payload)
  },
  unavailableDrivers(
    companyId: number,
    reservationIds: number[]
  ): Promise<AxiosResponse<UnvailableDriverListResult>> {
    const payload = reservationIds
    const host = apiBaseUrl()
    const url = `https://${host}/tripAssignments/unavailableDrivers/${companyId}`
    return httpService.post<UnvailableDriverListResult, number[]>(url, payload)
  },
  unavailableVehicles(
    companyId: number,
    reservationIds: number[]
  ): Promise<AxiosResponse<UnvailableVehicleListResult>> {
    const payload = reservationIds
    const host = apiBaseUrl()
    const url = `https://${host}/tripAssignments/unavailableVehicles/${companyId}`
    return httpService.post<UnvailableVehicleListResult, number[]>(url, payload)
  },
  deleteAssignments(
    assignmentIds: number[]
  ): Promise<AxiosResponse<ApiResult>> {
    const payload = { deleteAssignmentIds: assignmentIds }
    const host = apiBaseUrl()
    const url = `https://${host}/tripAssignments/deleteAssignments`
    return httpService.delete<ApiResult, DeleteAssignmentList>(url, {
      data: payload,
    })
  },
  createAssignments(payload: any): Promise<AxiosResponse<ApiResult>> {
    const host = apiBaseUrl()
    const url = `https://${host}/tripAssignments/createAssignment`
    return httpService.post<ApiResult, DeleteAssignmentList>(url, payload)
  },
  getDriverListForReservationAssignmentPanel(
    reservationId: number
  ): Promise<AxiosResponse<DriverStatusListResult>> {
    const url = `https://${apiBaseUrl()}/tripAssignments/driverList/${reservationId}`
    return httpService.get(url)
  },
  getDriverAssignmentStatusForReservation(
    reservationId: number
  ): Promise<AxiosResponse<ReservationDriverAssignmentStatusResult>> {
    const url = `https://${apiBaseUrl()}/tripAssignments/status/${reservationId}`
    return httpService.get(url)
  },
}
