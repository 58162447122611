import {
  CompanyChargeOption,
  LineItemCharge,
  toCompanyChargeOption,
} from '@/models/dto/LineItemCharge'
import { LineItemChargeTypes, LineItemSectionTypes } from '@/utils/enum'
import { Trip } from '@/models/dto'
import {
  getTripBaseFare,
  getTripTotalBaseFare,
  getTripSubtotal,
  getTripTotal,
  getRecurringTripTotal,
} from '@/utils/trip'
import client from '@/services/companyChargeOption'
import currency from 'currency.js'

export const BASE_FARE_CHARGE_TYPE = {
  key: 'base_fare',
  label: 'Base Fare',
  id: 1,
}

export const isPercent = (lineItemCharge: LineItemCharge): boolean => {
  const type = LineItemChargeTypes.PERCENTAGE
  return lineItemCharge?.lineItemChargeType?.key === type
}

export const toLineItemCharge = (
  companyChargeOption: CompanyChargeOption
): LineItemCharge => {
  return new LineItemCharge({
    lineItemChargeType: companyChargeOption?.lineItemChargeType,
    lineItemChargeTypeId: companyChargeOption?.lineItemChargeTypeId,
    lineItemSectionType: companyChargeOption?.lineItemSectionType,
    lineItemSectionTypeId: companyChargeOption?.lineItemSectionTypeId,
    percentage: companyChargeOption?.percentage,
    rate: companyChargeOption?.rate,
    name: companyChargeOption?.name,
    quantity: companyChargeOption?.quantity || 1,
  })
}

export const lineItemChargesBySectionType = (
  trip: Trip,
  type: LineItemSectionTypes
): LineItemCharge[] => {
  const predicate = (charge) =>
    charge.lineItemSectionType?.key === type && charge.isActive
  return trip?.lineItemCharges?.filter(predicate)
}

export const lineItemChargeBaseAmount = (
  lineItemCharge: LineItemCharge,
  trip: Trip
): number => {
  switch (lineItemCharge?.lineItemSectionType?.key) {
    case LineItemSectionTypes.BASE_FARE:
      return getTripBaseFare(trip)
    case LineItemSectionTypes.ITEMIZED_CHARGE:
      return getTripTotalBaseFare(trip)
    case LineItemSectionTypes.SUBTOTAL:
      return getTripSubtotal(trip)
    default:
      return getTripBaseFare(trip)
  }
}

export const lineItemChargeAmount = (
  lineItemCharge: LineItemCharge,
  baseAmount = 0
): number => {
  const quantity = lineItemCharge.quantity || 1
  if (isPercent(lineItemCharge)) {
    const percentage = currency(lineItemCharge.percentage || 0)
    return currency(baseAmount)
      .multiply(quantity)
      .multiply(percentage)
      .divide(100).value
  }
  const rate = lineItemCharge.rate || 0
  return currency(rate).multiply(quantity).value
}

export const lineItemChargeTotal = (
  lineItemCharges: LineItemCharge[],
  baseAmount = 0
): number => {
  return (lineItemCharges || [])
    .map((lineItemCharge) => lineItemChargeAmount(lineItemCharge, baseAmount))
    .reduce((x, y) => x.add(y), currency(0)).value
}

export const addDefaultChargesToTrip = (trip?: Trip): Promise<Trip> => {
  const t: Trip = !trip ? new Trip() : trip
  return client
    .tableView({ page: -1, pageSize: -1 })
    .then((res) => res.data.resultList)
    .then((charges) => (charges || []).map(toCompanyChargeOption))
    .then((charges) => charges.filter((c) => c.isDefault))
    .then((options) => options.map(toLineItemCharge))
    .then((charges) => {
      const lineItemCharges = [...(t.lineItemCharges || []), ...charges].map(
        (charge, lineNumber) => ({ ...charge, lineNumber })
      )
      const trip: Trip = { ...t, lineItemCharges }
      const total = getTripTotal(trip)
      const recurringTripTotal = getRecurringTripTotal(trip)
      return { ...trip, total, recurringTripTotal }
    })
}
