
import CUSidebarContent from '@/layouts/CUSidebarContent.vue'
import { Action } from '@/models/dto/Action'
import sidebar from '@/store/modules/sidebar'
import { countryPhoneFormatFilter, phoneFormatFilter } from '@/utils/string'
import { AxiosResponse } from 'axios'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import CUContactCard from './CUContactCard.vue'
import contactClient from '@/services/customer'
import {
  ContactRequest,
  ContactTypeKey,
  Customer,
  SimpleContact,
} from '@/models/dto/Customer'
import ContactSidebarDetail from './ContactSidebarDetail.vue'
import { EventBus } from '@/utils/eventBus'
import auth from '@/store/modules/auth'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'

@Component({
  components: { CUSidebarContent, CUContactCard },
})
export default class AddContactSidebar extends Vue {
  @Prop({ required: false, default: null }) readonly contactId: number
  @Prop({ required: false, default: null }) readonly tripId: number
  @Prop({ required: true }) readonly label: string
  @Prop({ required: false, default: '' }) readonly contactType: ContactTypeKey
  @Prop({ required: false, default: undefined })
  readonly prepopulateCreateContact: Partial<ContactRequest>
  @Prop({ type: Boolean, default: false }) isDispatch: boolean
  @Prop({ type: Boolean, default: false }) localEmitOnly: boolean
  @Prop({ type: Number, required: false }) readonly customerAccountId!: number

  selectedContact = null
  allContacts = []

  loading = false

  contactActions: Action[] = [
    ...(this.canViewContacts ? [{ label: 'Edit', event: 'contact:edit' }] : []),
    { label: 'Change', event: 'contact:change' },
  ]

  @Watch('contactId', { immediate: true })
  async onContactIdChange(contactId: number): Promise<void> {
    if (contactId) {
      this.loading = true
      const contactRes = await contactClient.byId(this.contactId)
      this.selectedContact = contactRes.data.customer
      this.loading = false
    }
  }

  get labelString(): string {
    return `${this.label} Contact`
  }

  get isTripContact(): boolean {
    return this.contactType === 'Trip'
  }

  get canViewContacts(): boolean {
    return auth.getUserRoleNames.includes(ACCESS_SETTINGS_ROLES.CONTACTS)
  }

  handleEditContact(): void {
    sidebar.push({
      component: ContactSidebarDetail,
      props: {
        userId: this.selectedContact.id,
        simple: true,
        contactType: this.contactType,
        tripId: this.tripId,
      },
      on: {
        refresh: this.refresh,
      },
    })
  }

  handleChangeContact(contact: SimpleContact): void {
    this.selectedContact = contact
  }

  handleDeleteContact(): void {
    this.selectedContact = null
  }

  close(): void {
    sidebar.pop()
  }

  cancel(): void {
    sidebar.pop()
  }

  submit(): void {
    ;(this.localEmitOnly ? this : EventBus).$emit('contact:update', {
      contact: this.selectedContact,
      contactType: this.contactType,
      tripId: this.tripId,
    })
    this.close()
  }

  refresh() {
    // this.onContactIdChange(this.contactId)
    const contactCard = this.$refs['contact-card'] as CUContactCard
    if (contactCard) {
      contactCard.loadUser()
    }
  }

  async getContacts(): Promise<void> {
    const response: AxiosResponse = await contactClient.tableView({
      pageSize: -1,
      page: 1,
    })

    // TODO: replace job title, customerSince, and owner once surfaced on backend
    this.allContacts = response.data.resultList.map((customer: Customer) => {
      return {
        customer,
        firstName: customer.firstName,
        lastName: customer.lastName,
        id: customer.customerId,
        email: customer.email,
        phone: countryPhoneFormatFilter(
          customer.phone,
          customer.phoneCountryKey
        ),
        phoneCountryKey: customer.phoneCountryKey,
        company: customer.customerAccount,
        title: 'Owner',
        owner: null,
        customerSince: '02/01/22',
      }
    })
  }
}
