
import { Vue, Component, Prop } from 'vue-property-decorator'
import sidebar from '@/store/modules/sidebar'
import { Note } from '@/models/dto/Note'
import stopClient from '@/services/stop'
import tripClient from '@/services/trip'
import reservation from '@/store/modules/reservation'
import { Trip } from '@/models/dto'

@Component({})
export default class StopNotesSidebar extends Vue {
  @Prop({ required: true }) id: number
  @Prop({ required: false }) trip: Trip
  @Prop({ required: false, default: null }) note: Note
  @Prop({ type: Boolean, default: false }) isGarage: boolean
  @Prop({ type: Boolean, default: false }) isReturning: boolean
  newNote = new Note()
  loading = false

  get placeholder(): string {
    return this.isGarage
      ? 'Add notes relating to this garage that your drivers will see.'
      : 'Add notes relating to this stop that your customers and drivers will see.'
  }

  handleNoteTextChange(text: string): void {
    this.newNote.note = text
  }

  handleNoteHtmlChange(html: string): void {
    this.newNote.html = html
  }

  close(): void {
    sidebar.pop()
  }

  handleClearNote(): void {
    this.newNote.html = ''
    this.newNote.note = ''
    this.submit()
  }

  async submit(): Promise<void> {
    this.loading = true
    if (!this.isGarage && this.id) {
      const res = await stopClient.updateStopNotes(this.id, [this.newNote])
      this.newNote.noteId = res.data.stop?.stopNotes?.[0]?.noteId || null
    } else if (this.id) {
      const payload = this.trip ? { ...this.trip } : { ...reservation.trip }
      if (this.id === payload.garageTimes.garageId && !this.isReturning) {
        payload.garageTimes.garageTimeNotes = this.newNote.note
        payload.garageTimes.htmlGarageTimeNotes = this.newNote.html
      } else if (
        this.id === payload.garageTimes.returnGarageId &&
        this.isReturning
      ) {
        payload.garageTimes.returnGarageTimeNotes = this.newNote.note
        payload.garageTimes.htmlReturnGarageTimeNotes = this.newNote.html
      }
      try {
        await tripClient.modifyTripByStops({
          tripId: payload.tripId,
          hardConflictOverride: true,
          payload,
        })
      } catch {
        // Pass, new quote where trip hasn't been saved yet
      }
    }
    this.loading = false
    this.$emit('submit', this.newNote)
    sidebar.close()
  }

  mounted(): void {
    this.newNote = { ...this.note }
  }
}
