
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import client from '@/services/customer'
import { countryPhoneFormatFilter, formatFullName } from '@/utils/string'
import { ContactTypeKey, Customer, SimpleContact } from '@/models/dto/Customer'
import { Action } from '@/models/dto/Action'
import sidebar from '@/store/modules/sidebar'
import ContactSidebarDetail from './ContactSidebarDetail.vue'
import CompanySidebarDetail from './CompanySidebarDetail.vue'
import auth from '@/store/modules/auth'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'

@Component({})
export default class CUContactCard extends Vue {
  @Prop({ required: false, default: null }) readonly userId!: number
  @Prop({ required: false, default: null }) readonly contact!: SimpleContact
  @Prop({ required: false }) readonly label!: string
  @Prop({ required: false, default: 'gray-bg' }) readonly background!: string
  @Prop({ required: false, default: '0' }) readonly offset!: string
  @Prop({ required: false, default: '324' }) readonly width!: string
  @Prop({ required: false, default: () => [] }) readonly actions!: Action[]
  @Prop({ type: Boolean, default: false }) readonly summary!: boolean
  @Prop({ type: Boolean, default: false }) readonly linkContact!: boolean
  @Prop({ type: Boolean, default: false }) readonly unsetWidth!: boolean
  @Prop({ type: Boolean, default: false }) readonly hideLoader!: boolean
  @Prop({ type: Boolean, default: false }) readonly hideEmail!: boolean
  @Prop({ required: false, default: '' }) readonly contactType!: ContactTypeKey
  user: Customer | null = null

  @Watch('userId')
  onUserIdChange(): void {
    this.loadUser()
  }

  @Watch('contact')
  onContactChange(): void {
    this.loadUser()
  }

  get loaderHeight(): string {
    return this.summary ? '64px' : '129px'
  }

  get name(): string {
    return formatFullName(this.user)
  }

  get description(): string {
    return this.user?.title
  }

  get company(): string {
    return this.user?.customerAccountName
  }

  get companyId(): number {
    return this.user?.customerAccountId
  }

  get email(): string {
    return this.user?.email
  }

  get phoneNumber(): string {
    let phoneNumber = countryPhoneFormatFilter(
      this.user?.phone,
      this.user?.phoneCountryKey
    )
    if (this.user?.phoneExtension) {
      phoneNumber = `${phoneNumber} ext. ${this.user?.phoneExtension}`
    }
    return phoneNumber
  }

  get contactOwner(): string {
    // TODO: Update once field is supported by API.
    return 'Noam Beno'
  }

  get limitDisplayedFields(): boolean {
    return this.contactType === 'Booking' || this.contactType === 'Trip'
  }

  get canViewContacts(): boolean {
    return auth.getUserRoleNames.includes(ACCESS_SETTINGS_ROLES.CONTACTS)
  }

  get tagColors(): { background: string; border: string; text: string } {
    if (this.label === 'Affiliate') {
      return {
        background: 'additional-blue-2',
        border: 'additional-blue',
        text: 'additional-blue',
      }
    } else {
      return {
        background: 'bg-gray-2',
        border: 'gray-border-dark',
        text: 'black',
      }
    }
  }

  handleClickName(): void {
    if (!this.linkContact || !this.canViewContacts) {
      return
    }
    sidebar.push({
      component: ContactSidebarDetail,
      props: {
        userId: this.userId || this.contact.id,
        simple: true,
        contactType: this.contactType,
      },
    })
  }

  loadUser(): void {
    if (this.userId) {
      client
        .byId(this.userId)
        .then((res) => res.data)
        .then((user) => (this.user = user.customer))
    } else if (this.contact) {
      this.user = new Customer({
        firstName: this.contact.firstName,
        lastName: this.contact.lastName,
        email: this.contact.email,
        phone: this.contact.phone,
        phoneExtension: this.contact.phoneExtension,
        phoneCountryKey: this.contact.phoneCountryKey,
        title: this.contact.customer?.title || this.contact.title,
        customerAccountId: this.contact.customerAccountId,
        customerAccountName: this.contact.customerAccountName,
      })
    }
  }

  openCompany(customerAccountId: number): void {
    sidebar.push({
      component: CompanySidebarDetail,
      props: { customerAccountId },
    })
  }

  mounted(): void {
    this.loadUser()
  }
}
