
import { Prop, Watch } from 'vue-property-decorator'
import Component, { mixins } from 'vue-class-component'
import { predefinedFutureDate, predefinedPastDate } from '@/utils/datePicker'
import DateMixin from '@/mixins/DateMixin'
import dayjs from 'dayjs'

@Component({})
export default class CUDatePickerFooter2 extends mixins(DateMixin) {
  @Prop({ required: false, default: () => [] }) value!: string[]
  @Prop({ required: false, default: false }) menuValue!: boolean
  @Prop({ required: false, default: false }) hideInputs!: boolean

  @Watch('menuValue', { immediate: true })
  onMenuValueChange(newMenuValue: boolean, oldMenuValue: boolean): void {
    if (newMenuValue && !oldMenuValue) {
      this.$nextTick(this.focusStartInput)
    }
  }

  get formattedStartDate(): string {
    if (!Array.isArray(this.value) || !this.value?.length || !this.value?.[0]) {
      return ''
    }

    const startDate = this.value[0]
    if (startDate === predefinedPastDate) {
      return 'Past'
    }
    return this.formatShortDate(startDate)
  }

  get formattedEndDate(): string {
    if (!Array.isArray(this.value) || !this.value?.length || !this.value?.[1]) {
      return ''
    }

    const endDate = this.value[1]
    if (endDate === predefinedFutureDate) {
      return 'Future'
    }
    return this.formatShortDate(endDate)
  }

  handleStartDateChange(e: string): void {
    const date = this.parseInputToISODate(e)
    if (!date) {
      this.resetStartDateInput()
      return
    }
    let value = []
    if (dayjs(date).isAfter(dayjs(this.value[1]))) {
      value = [this.value[1], date]
    } else {
      value = [date, this.value[1]]
    }
    this.$emit('input', value)
    this.$emit('change', value)
  }

  handleEndDateChange(e: string): void {
    const date = this.parseInputToISODate(e)
    if (!date) {
      this.resetEndDateInput()
      return
    }
    let value = []
    if (dayjs(date).isBefore(dayjs(this.value[0]))) {
      value = [date, this.value[0]]
    } else {
      value = [this.value[0], date]
    }
    this.$emit('input', value)
    this.$emit('change', value)
  }

  /**
   * Resets the start date input to its original value when an invalid input is detected.
   * @returns {void}
   */
  resetStartDateInput(): void {
    const input: any = this.$refs['start-date-input']
    input.$refs['v-text-field'].lazyValue = this.formattedStartDate
  }

  resetEndDateInput(): void {
    const input: any = this.$refs['end-date-input']
    input.$refs['v-text-field'].lazyValue = this.formattedEndDate
  }

  // Check if the event matches some format of dates between #/#/## and ##/##/####
  // Return it in YYYY-MM-DD format
  parseInputToISODate(e: string): string {
    const dateRegexPattern = /^(\d{1,2}\/\d{1,2}\/\d{2,4})$/
    if (!e || !dateRegexPattern.test(e)) {
      return null
    }
    return this.formatInputToISODate(e)
  }

  focusStartInput(): void {
    const input: any = this.$refs['start-date-input']
    if (input) {
      setTimeout(() => {
        input.focus()
      }, 50)
    }
  }
}
