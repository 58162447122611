
import { Vue, Component, Prop } from 'vue-property-decorator'
@Component({})
export default class CUCurrencyField extends Vue {
  @Prop({ default: false, type: Boolean }) negative!: boolean
  @Prop({ default: false, type: Boolean }) hideCurrencyLabel!: boolean
  @Prop({ default: false, type: Boolean }) allowEmitNull!: boolean
  @Prop({ default: false, type: Boolean }) disabled!: boolean

  get listeners(): Record<string, unknown> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this
    return Object.assign({}, this.$listeners, {
      input(event: InputEvent) {
        vm.$emit('input', event)
      },
      change(event: any) {
        if (event === '' && vm.allowEmitNull) {
          vm.$emit('input', null)
          vm.$emit('change', null)
          return
        }
        vm.$emit('input', Number(event).toFixed(2))
        vm.$emit('change', Number(event).toFixed(2))
      },
    })
  }
}
