import { VuexModule, Module, Action } from 'vuex-class-modules'
import { Vue } from 'vue-property-decorator'
import store from '@/store/index'
import dayjs from 'dayjs'
import {
  AssignmentTotals,
  AssignmentsRowBlock,
  TimelineScaleOptions,
  AssignmentsVehicleBlock,
  AssignmentsViewMode,
  AssignmentsDriverBlock,
} from '@/models/dto/AssignmentsGrid'
import {
  convertVehicleRowsToDriverRows,
  filterReservationsByScale,
  getAssignmentBlocks,
  getLocalFormattedReservations,
  getReservationRowBlocksForDriver,
  getReservationRowBlocksForVehicle,
  getSelectedDataFromResId,
  getTotals,
  getVehicleAndVehicleTypeBlocks,
  handleUpdateReservationNotifications,
  saveAssignments,
} from '@/utils/assignment'
import { EventBus } from '@/utils/eventBus'
import {
  AssignedVehicle,
  DriverConflict,
  FormattedAssignmentConflict,
  Reservation,
  Trip,
  Vehicle,
  VehicleAssignment,
  VehicleConflict,
  VehicleTimeOff,
  VehicleType,
} from '@/models/dto'
import sidebar from '@/store/modules/sidebar'
import { Driver, DriverAssignmentStatus } from '@/models/dto/Driver'
import { SourceCategory } from '@/utils/enum'
import { load, save } from '@/utils/localStorage'
import deepClone from '@/utils/deepClone'
import AssignmentsSidebar from '@/components/AssignmentsSidebar.vue'
import { groupBy } from '@/utils/reducers'
import vehicle from '@/services/vehicle'

@Module({ generateMutationSetters: true })
class AssignmentModule extends VuexModule {
  // state
  currentDate: dayjs.Dayjs = dayjs().startOf('day')
  scrollPosition = 0
  scale = load('assignmentsScale') || TimelineScaleOptions.DAY
  multiSwitchViewMode: AssignmentsViewMode = 'bus'
  scaleOptions = [
    TimelineScaleOptions.HALFDAY,
    TimelineScaleOptions.DAY,
    TimelineScaleOptions.HALFWEEK,
    TimelineScaleOptions.WEEK,
  ]
  isNonDefaultView = false
  preserveDataOnInit = false

  loading = false
  refreshing = false
  refreshingAssignments = false

  loadedReservations: Reservation[] = []
  loadedVehicleRows: AssignmentsVehicleBlock[] = []
  loadedDriverRows: AssignmentsDriverBlock[] = []

  vehicles: Vehicle[] = []
  drivers: Driver[] = []
  unavailableVehicles: VehicleConflict[] = []
  unavailableDrivers: DriverConflict[] = []
  driverAssignmentStatuses: DriverAssignmentStatus[] = []
  vehicleTypes: VehicleType[] = []
  vehicleConflicts: FormattedAssignmentConflict[] = []
  driverConflicts: FormattedAssignmentConflict[] = []
  vehicleTimeOff: VehicleTimeOff[] = []
  vehicleTimeOffConflicts: FormattedAssignmentConflict[] = []

  selectedReservationId: number = null
  selectedVehicleIdx: number = null
  selectedVehicleId: number = null
  selectedDriverIdx: number = null
  selectedDriverId: number = null
  selectedAssignments: AssignedVehicle[] = []
  selectedReservation: Reservation = null
  selectedTrip: Trip = null
  selectedBlock: AssignmentsRowBlock = null

  addedVehicleConflicts = 0
  isAssigningIdx = null
  sendAppNotification = true
  sendEmailNotification = true

  numUnassignedReservations: number = null
  numDraftAssignments: number = null

  timeoff: VehicleTimeOff[] = []

  // getters
  get getIsNonDefaultView(): boolean {
    return this.isNonDefaultView
  }

  get getPreserveDataOnInit(): boolean {
    return this.preserveDataOnInit
  }

  get getScale(): string {
    return this.scale
  }

  get getScaleOptions(): string[] {
    return this.scaleOptions
  }

  get getIsAssigning(): boolean {
    return this.isAssigningIdx !== null
  }

  get getCurrentDate(): dayjs.Dayjs {
    return this.currentDate
  }

  get getCurrentDateAsISO(): string {
    return this.currentDate.format('YYYY-MM-DD')
  }

  get getCurrentDateAsJSDate(): Date {
    return this.currentDate.toDate()
  }

  get getLoadedRows(): AssignmentsVehicleBlock[] | AssignmentsDriverBlock[] {
    if (this.multiSwitchViewMode === 'driver') {
      return this.loadedDriverRows
    }
    return this.loadedVehicleRows
  }

  get getLoadedVehicleRows(): AssignmentsVehicleBlock[] {
    return this.loadedVehicleRows
  }

  get getVehicleTypes(): VehicleType[] {
    return this.vehicleTypes
  }

  get getLoadedReservations(): Reservation[] {
    return this.loadedReservations
  }

  get getUnavailableVehicles(): VehicleConflict[] {
    return this.unavailableVehicles
  }

  get getUnavailableDrivers(): DriverConflict[] {
    return this.unavailableDrivers
  }

  get getDriverAssignmentStatuses(): DriverAssignmentStatus[] {
    return this.driverAssignmentStatuses
  }

  get getVehicleConflicts(): FormattedAssignmentConflict[] {
    return this.vehicleConflicts
  }

  get getVehicleTimeOffConflicts(): FormattedAssignmentConflict[] {
    return this.vehicleTimeOffConflicts
  }

  get getVehicleTimeOff(): VehicleTimeOff[] {
    return this.vehicleTimeOff
  }

  get vehiclesWithInactiveFlag(): Vehicle[] {
    return this.vehicles.map((v) => ({
      ...v,
      isInactiveFromTimeOff: this.vehicleTimeOff.some(
        (timeoff) => timeoff.indefinite && timeoff.vehicleId === v.vehicleId
      ),
    }))
  }

  get getDriverConflicts(): FormattedAssignmentConflict[] {
    return this.driverConflicts
  }

  get isLoading(): boolean {
    return this.loading
  }

  get isRefreshing(): boolean {
    return this.refreshing
  }

  get getNumUnassignedReservations(): number {
    return this.numUnassignedReservations
  }

  get getNumDraftAssignments(): number {
    return this.numDraftAssignments
  }

  get getSelectedReservation(): Reservation {
    return this.selectedReservation
  }

  get getSelectedTrip(): Trip {
    return this.selectedTrip
  }

  get isReferral(): boolean {
    return (
      this.getSelectedReservation?.sourceCategory === SourceCategory.REFERRAL
    )
  }

  get getSelectedBlock(): AssignmentsRowBlock {
    return this.selectedBlock
  }

  get getSendAppNotification(): boolean {
    return this.sendAppNotification
  }

  get getSendEmailNotification(): boolean {
    return this.sendEmailNotification
  }

  get getSelectedAssignments(): AssignedVehicle[] {
    return this.selectedAssignments
  }

  get getSelectedVehicleId(): number {
    return this.selectedVehicleId
  }

  get getSelectedVehicleIdx(): number {
    return this.selectedVehicleIdx
  }

  get getSelectedDriverIdx(): number {
    return this.selectedDriverIdx
  }

  get getSelectedDriverId(): number {
    return this.selectedDriverId
  }

  get isSelectedMode(): boolean {
    return !!this.selectedReservation || !!this.selectedBlock
  }

  get vehicleTimeoffs(): VehicleTimeOff[] {
    return this.timeoff
  }

  // LOADING
  @Action
  async init(): Promise<void> {
    this.loading = true
    this.isNonDefaultView = false
    if (this.preserveDataOnInit) {
      this.selectedVehicleIdx = 0
      this.selectedBlock = this.loadedVehicleRows
        .find((r) => r.vehicleId === this.selectedAssignments[0].id)
        ?.posRowBlocks?.find(
          (prb) => prb.reservationId === this.selectedReservationId
        )
      this.setMultiSwitchViewMode('bus')
      this.preserveDataOnInit = false

      sidebar.popAllAndPush({
        component: AssignmentsSidebar,
        persist: true,
        width: 512,
      })
    } else {
      this.setCurrentDate(dayjs())
      this.clearSelected()
      await this.refresh()
    }
    this.loading = false
    this.fetchVehicleTimeoff()
  }

  @Action
  async refresh(): Promise<void> {
    this.refreshing = true
    this.loadVehicleTimeoff(
      this.currentDate.toISOString(),
      this.currentDate.add(28, 'days').toISOString()
    )
    const [assignmentBlocks, totals] = await Promise.all([
      getAssignmentBlocks(),
      getTotals(),
    ])
    this.setLoadedVehicleRows(assignmentBlocks)
    this.setTotals(totals)
    if (this.isSelectedMode) {
      this.refreshSelected()
      await getSelectedDataFromResId(this.selectedReservationId)
    }
    this.refreshing = false
  }

  @Action
  clearVehiclesAndDrivers(): void {
    this.vehicles = []
    this.drivers = []
  }

  @Action
  refreshLocalData(): void {
    this.setLoadedReservations(getLocalFormattedReservations())
    const blocks = getVehicleAndVehicleTypeBlocks(
      this.vehicleTypes,
      this.vehicles,
      this.loadedReservations,
      this.timeoff
    )
    this.setLoadedVehicleRows(blocks)
    this.refreshSelected()
    this.selectedReservation = this.loadedReservations.find(
      (r) => r.reservationId === this.selectedReservationId
    )
    this.fetchVehicleTimeOff()
  }

  @Action
  setLoadedVehicleRows(blocks: AssignmentsVehicleBlock[]): void {
    this.loadedVehicleRows = blocks.map((block) => ({
      ...block,
      collapsed:
        this.loadedVehicleRows.find(
          (row) => row.vehicleTypeId === block.vehicleTypeId
        )?.collapsed || false,
    }))
    this.loadedDriverRows = convertVehicleRowsToDriverRows()
  }

  @Action
  setLoadedReservations(reservations: any[]): void {
    this.loadedReservations = reservations
  }

  @Action
  setVehicleData(data: {
    vehicles: Vehicle[]
    vehicleTypes: VehicleType[]
  }): void {
    this.vehicles = data.vehicles
    this.vehicleTypes = data.vehicleTypes
  }

  @Action
  setDrivers(drivers: Driver[]): void {
    this.drivers = drivers
  }

  // CALENDAR VIEW
  @Action
  handleCollapseVehicleType(typeId: number): boolean {
    this.loadedVehicleRows.map(
      (v) =>
        (v.collapsed = v.vehicleTypeId === typeId ? !v.collapsed : v.collapsed)
    )
    return this.selectedBlock?.vehicleTypeId === typeId
  }

  @Action
  setMultiSwitchViewMode(mode: AssignmentsViewMode): void {
    this.multiSwitchViewMode = mode
  }

  // SELECTED
  @Action
  setIsNonDefaultView(isNonDefault: boolean) {
    this.isNonDefaultView = isNonDefault
  }

  @Action
  setPreserveDataOnInit(preserve: boolean) {
    this.preserveDataOnInit = preserve
  }

  @Action
  setSelectedReservationId(id: number): void {
    this.selectedReservationId = id
  }

  @Action
  async setSelectedIds(data: {
    reservationId: number
    vehicleId?: number
    driverId?: number
    vehicleIdx?: number
    driverIdx?: number
    isDriver?: boolean
  }): Promise<void> {
    this.selectedVehicleId = null
    this.selectedVehicleIdx = null
    this.selectedDriverId = null
    this.selectedDriverIdx = null

    const updateReservation = this.selectedReservationId !== data.reservationId
    if (updateReservation) {
      this.clearSelected()
    }

    this.selectedVehicleIdx = data.vehicleIdx
    this.selectedVehicleId = data.vehicleId
    if (data.isDriver) {
      this.setMultiSwitchViewMode('driver')
      this.selectedDriverIdx = data.driverIdx
      this.selectedDriverId = data.driverId
      this.selectedBlock = this.loadedDriverRows
        .find((r) => r.userId === data.driverId)
        ?.posRowBlocks?.find((prb) => prb.reservationId === data.reservationId)
    } else {
      this.setMultiSwitchViewMode('bus')
      this.selectedBlock = this.loadedVehicleRows
        .find((r) => r.vehicleId === data.vehicleId)
        ?.posRowBlocks?.find((prb) => prb.reservationId === data.reservationId)
    }

    if (updateReservation) {
      this.selectedReservationId = data.reservationId
      await getSelectedDataFromResId(data.reservationId)
    }
  }

  @Action
  setSelectedData(data: {
    reservation: Reservation
    trip: Trip
    assignments: AssignedVehicle[]
    drivers: Driver[]
    unavailableVehicles: VehicleConflict[]
    unavailableDrivers: DriverConflict[]
    driverAssignmentStatuses: DriverAssignmentStatus[]
  }): void {
    this.selectedReservation = data.reservation
    this.selectedTrip = data.trip
    this.selectedAssignments = data.assignments
    this.drivers = data.drivers
    this.unavailableDrivers = data.unavailableDrivers
    this.unavailableVehicles = data.unavailableVehicles
    this.driverAssignmentStatuses = data.driverAssignmentStatuses
    handleUpdateReservationNotifications()
    this.fetchVehicleTimeOff()
  }

  @Action
  setScrollPositionFromReservation(): void {
    this.scrollPosition = this.selectedReservation.start - 100
    EventBus.$emit('reset-scroll', { offset: this.scrollPosition, id: null })
  }

  @Action
  setSendEmailNotification(send: boolean): void {
    this.sendEmailNotification = send
  }

  @Action
  setSendAppNotification(send: boolean): void {
    this.sendAppNotification = send
  }

  @Action
  refreshSelected(): void {
    if (this.selectedReservationId || this.selectedBlock?.vehicleId) {
      this.setSelectedIds({
        reservationId: this.selectedReservationId,
        vehicleId: this.selectedBlock?.vehicleId,
        vehicleIdx: null,
      })
    }
  }

  @Action
  clearSelected(): void {
    this.selectedBlock = null
    this.selectedReservation = null
    this.selectedTrip = null
    this.selectedReservationId = null
    this.selectedVehicleId = null
    this.selectedVehicleIdx = null
    this.selectedDriverId = null
    this.selectedDriverIdx = null
    this.selectedAssignments = []
    this.refreshLocalData()
  }

  @Action
  dragVehicleBlockTo(data: {
    dragDistance: number
    block: AssignmentsRowBlock
  }): void {
    if (Math.abs(data.dragDistance) < 16) {
      return
    }
    const selectedId = this.selectedVehicleId
    const oldIdx = this.selectedAssignments.findIndex(
      (a) => a.id === selectedId
    )
    const rows = this.loadedVehicleRows.map((row) => {
      const max = row.posRowBlocks?.length
        ? row.posRowBlocks.reduce((prev, current) =>
            prev && prev.top > current.top ? prev : current
          ).top
        : 0
      return {
        vehicleId: row.vehicleId,
        collapsed: row.collapsed,
        height: (max + 1) * 32,
      }
    })

    let i = rows.findIndex((row) => row.vehicleId === selectedId)
    const increment = data.dragDistance > 0 ? 1 : -1
    let draggedPixels =
      increment < 0
        ? data.block.top * 32
        : rows[i].height - (data.block.top + 1) * 32

    while (draggedPixels < data.dragDistance * increment - 16) {
      i += increment
      if (!rows[i]) {
        break
      }

      draggedPixels +=
        !rows[i].collapsed || !rows[i].vehicleId ? rows[i].height : 0
    }

    if (rows[i]?.vehicleId && rows[i]?.vehicleId === data.block.vehicleId) {
      return
    } else if (rows[i]?.vehicleId) {
      this.handleChangeVehicle({ oldIdx, id: rows[i].vehicleId })
    }
  }

  @Action
  fetchVehicleTimeoff(): void {
    this.loadVehicleTimeoff(
      this.currentDate.toISOString(),
      this.currentDate.add(28, 'day').toISOString()
    )
  }

  async loadVehicleTimeoff(start: string, end: string): Promise<void> {
    try {
      const response = await vehicle.getTimeOffByDateRange(start, end)
      this.timeoff = response.data
    } catch (error) {
      console.error(error)
    }
  }

  dragDriverBlockTo(data: {
    dragDistance: number
    block: AssignmentsRowBlock
  }): void {
    if (Math.abs(data.dragDistance) < 16) {
      return
    }

    const selectedId = this.selectedDriverId
    const vehicleIdx = this.selectedAssignments.findIndex((a) =>
      a.drivers.find((d) => d.id === selectedId)
    )
    const driverIdx = this.selectedAssignments[vehicleIdx].drivers.findIndex(
      (d) => d.id === selectedId
    )

    const rows = this.loadedDriverRows.map((row) => {
      const max = row.posRowBlocks?.length
        ? row.posRowBlocks.reduce((prev, current) =>
            prev && prev.top > current.top ? prev : current
          ).top
        : 0
      return {
        userId: row.userId,
        height: (max + 1) * 32,
      }
    })

    let i = rows.findIndex((row) => row.userId === this.selectedDriverId)
    const increment = data.dragDistance > 0 ? 1 : -1
    let draggedPixels =
      increment < 0
        ? data.block.top * 32
        : rows[i].height - (data.block.top + 1) * 32

    while (draggedPixels < data.dragDistance * increment - 16) {
      i += increment
      if (!rows[i]) {
        break
      }

      draggedPixels += rows[i].userId ? rows[i].height : 0
    }

    if (rows[i]?.userId && rows[i]?.userId === data.block.userId) {
      return
    } else if (rows[i]?.userId) {
      this.handleChangeDriver({
        vehicleIdx,
        driverIdx,
        id: rows[i].userId,
      })
    }
  }

  // UPDATING ASSIGNMENTS
  @Action
  handleAddVehicle(): void {
    const totalDrivers = this.selectedAssignments.reduce((sum, vehicle) => {
      sum +=
        vehicle.drivers?.reduce((sum, driver) => {
          sum += driver.id ? 1 : 0
          return sum
        }, 0) || 0
      return sum
    }, 0)
    this.selectedAssignments.push({
      tripAssignmentId: null,
      id: null,
      name: null,
      type: null,
      originalType: null,
      isConfirmed: false,
      errorMessage: null,
      vehicleTypeId: null,
      originalTypeId: null,
      drivers: [
        {
          tripAssignmentId: null,
          id: null,
          email: null,
          key: `unassigned-driver-${this.selectedAssignments.length}-0`,
          label: `Driver ${totalDrivers + 1}`,
          name: null,
          errorMessage: null,
          isConfirmed: false,
          assignmentStatusKey: null,
        },
      ],
    })
    this.resetConflicts()
  }

  @Action
  handleAddDriver(i: number): void {
    this.selectedAssignments[i].drivers.push({
      tripAssignmentId: null,
      id: null,
      email: null,
      key: null,
      label: null,
      name: null,
      errorMessage: null,
      isConfirmed: false,
      assignmentStatusKey: null,
    })
    this.resetConflicts()
  }

  @Action
  handleChangeVehicle(data: { oldIdx: number; id: number }): void {
    if (
      this.selectedAssignments.some((a) => a.id === data.id) &&
      !this.refreshingAssignments &&
      !!data.id
    ) {
      EventBus.$emit('snackbar:error', 'Cannot repeat a vehicle assignment')
      return
    }

    const newVehicle: any = this.vehicles.find((v) => v.vehicleId === data.id)
    const oldAssignment = deepClone(this.selectedAssignments)[data.oldIdx]
    const oldVehicleId = oldAssignment?.id
    const oldVehicleTypeId = oldAssignment?.vehicleTypeId

    if (!data.id && !oldVehicleId) {
      if (oldAssignment.isConfirmed) {
        this.selectedAssignments[data.oldIdx].isConfirmed = false
      } else {
        this.selectedAssignments.splice(data.oldIdx, 1)
      }
      return
    }

    if (
      this.selectedBlock?.vehicleId !== oldVehicleId &&
      oldVehicleId &&
      newVehicle
    ) {
      this.selectedBlock = this.loadedVehicleRows
        .find((r) => r.vehicleId === oldVehicleId)
        ?.posRowBlocks?.find(
          (prb) => prb.reservationId === this.selectedReservationId
        )
    }

    this.selectedAssignments[data.oldIdx] = {
      id: newVehicle?.vehicleId,
      tripAssignmentId: newVehicle?.tripAssignmentId,
      name: newVehicle?.vehicleName,
      type: newVehicle?.vehicleTypeName,
      originalType: this.selectedAssignments[data.oldIdx].originalType,
      isConfirmed: false,
      errorMessage: newVehicle?.errorMessage,
      vehicleTypeId: newVehicle?.vehicleTypeId,
      originalTypeId: this.selectedAssignments[data.oldIdx].originalTypeId,
      drivers: this.selectedAssignments[data.oldIdx].drivers,
      isMissingCharterUpId: newVehicle && !newVehicle.charterUpVehicleId,
    }

    const oldRow = this.loadedVehicleRows.find(
      (row) => row.vehicleId === oldVehicleId
    )
    const newRow = this.loadedVehicleRows.find(
      (row) => row.vehicleId === data.id
    )
    const tempReservations = filterReservationsByScale(this.loadedReservations)
    tempReservations.find(
      (res) => res.reservationId === this.selectedReservationId
    ).vehicleAssignments = this.selectedAssignments.map((a) => ({
      vehicleId: a.id,
    })) as VehicleAssignment[]

    if (oldVehicleId) {
      oldRow.posRowBlocks = getReservationRowBlocksForVehicle(
        new Vehicle({
          vehicleId: oldVehicleId,
          vehicleTypeId: oldVehicleTypeId,
        }),
        tempReservations,
        this.vehicleTimeoffs
      )

      oldRow.hasConflict = oldRow.posRowBlocks.some((b) => b.conflict)
    }

    if (data.id) {
      newRow.posRowBlocks = getReservationRowBlocksForVehicle(
        newVehicle,
        tempReservations,
        this.vehicleTimeoffs
      )

      newRow.posRowBlocks.find(
        (block) => block.reservationId === this.selectedReservationId
      ).isConfirmed = false

      newRow.hasConflict = newRow.posRowBlocks.some((b) => b.conflict)

      this.setSelectedIds({
        reservationId: this.selectedReservationId,
        vehicleId: data.id,
        vehicleIdx: data.oldIdx,
      })
    }

    this.resetConflicts()
  }

  @Action
  handleChangeDriver(data: {
    vehicleIdx: number
    driverIdx: number
    id: number
  }): void {
    const newDriver: any = this.drivers.find(
      (driver) => driver.driverId === data.id
    )
    const oldAssignment = deepClone(this.selectedAssignments)[data.vehicleIdx]
    const oldVehicleId = oldAssignment?.id
    const oldUserId = oldAssignment?.drivers?.[data.driverIdx]?.id

    if (!data.id && !oldUserId) {
      if (oldAssignment.drivers[data.driverIdx].isConfirmed) {
        this.selectedAssignments[data.vehicleIdx].drivers[
          data.driverIdx
        ].isConfirmed = false
      } else {
        this.selectedAssignments[data.vehicleIdx].drivers.splice(
          data.driverIdx,
          1
        )
      }
      return
    }

    Vue.set(this.selectedAssignments[data.vehicleIdx].drivers, data.driverIdx, {
      id: data.id,
      email: newDriver?.email,
      key: `unassigned-driver-${newDriver?.userId}`,
      label: `Driver ${data.driverIdx + 1}`,
      name: newDriver?.name,
      errorMessage: null,
      isMissingCharterUpId: !newDriver?.charterUpDriverId,
      isConfirmed: false,
    })

    const oldRow = this.loadedDriverRows.find((row) => row.userId === oldUserId)
    const newRow = this.loadedDriverRows.find((row) => row.userId === data.id)

    const tempReservations = filterReservationsByScale(this.loadedReservations)

    tempReservations.find(
      (res) => res.reservationId === this.selectedReservationId
    ).vehicleAssignments = this.selectedAssignments.map(
      (a) =>
        ({
          vehicleId: a.id,
          drivers: a.drivers.map((d) => ({ driverId: d.id })),
        } as any)
    ) as VehicleAssignment[]

    if (oldRow && oldUserId) {
      oldRow.posRowBlocks = getReservationRowBlocksForDriver(
        oldUserId,
        tempReservations
      )

      oldRow.hasConflict = oldRow.posRowBlocks.some((b) => b.conflict)
      oldRow.top = Math.max(...oldRow.posRowBlocks.map((b) => b.top))
    }

    if (newRow && data.id) {
      newRow.posRowBlocks = getReservationRowBlocksForDriver(
        data.id,
        tempReservations
      )

      const rowBlocks = newRow.posRowBlocks.find(
        (block) => block.reservationId === this.selectedReservationId
      )
      if (rowBlocks) {
        rowBlocks.isConfirmed = false
      }
      newRow.top = Math.max(...newRow.posRowBlocks.map((b) => b.top))

      newRow.hasConflict = newRow.posRowBlocks.some((b) => b.conflict)

      this.setSelectedIds({
        reservationId: this.selectedReservationId,
        vehicleId: oldVehicleId,
        vehicleIdx: data.vehicleIdx,
        driverId: data.id,
        driverIdx: data.driverIdx,
        isDriver: true,
      })
    }

    this.resetConflicts()
  }

  @Action
  checkForDriverConflicts(): boolean {
    let conflictExists = false
    const driverIds = this.selectedAssignments
      .flatMap((a) => a.drivers)
      .map((d) => d.id)
    this.selectedAssignments = this.selectedAssignments.map((assignment) => {
      if (assignment.id === null) {
        assignment.drivers = assignment.drivers.map((driver) => {
          if (driver.id) {
            driver.errorMessage = 'Driver must be assigned with a vehicle'
            conflictExists = true
          }
          return driver
        })
      }
      assignment.drivers = assignment.drivers.map((driver) => {
        const isDuplicateDriver =
          driverIds.filter((id) => id === driver.id).length > 1
        if (driver.id !== null && isDuplicateDriver) {
          driver.errorMessage = 'Driver assignment repeated'
          conflictExists = true
        }
        return driver
      })

      if (!assignment.type) {
        assignment.type = 'Charter Bus'
        assignment.vehicleTypeId = 1
      }

      return assignment
    })

    return conflictExists
  }

  setVehicleStatus(data: { id: number; isConfirmed: boolean }): void {
    const assignment = this.selectedAssignments.find((a) => a.id === data.id)
    if (!assignment?.id) {
      return
    }
    const block = this.loadedVehicleRows
      .find((vehicle) => vehicle.vehicleId === assignment.id)
      .posRowBlocks.find(
        (block) => block.reservationId === this.selectedReservationId
      )

    // Accounts for reservations with no grid view
    if (block) {
      block.isConfirmed = data.isConfirmed
    }
    assignment.isConfirmed = data.isConfirmed
    if (this.selectedBlock?.vehicleId === data.id) {
      this.selectedBlock.isConfirmed = data.isConfirmed
    }
  }

  setDriverStatus(data: {
    id: number
    idx: number
    isConfirmed: boolean
  }): void {
    Vue.set(
      this.selectedAssignments.find((a) =>
        a.drivers.find((d) => d.id === data.id)
      ).drivers,
      data.idx,
      {
        ...this.selectedAssignments.find((a) =>
          a.drivers.find((d) => d.id === data.id)
        ).drivers[data.idx],
        isConfirmed: data.isConfirmed,
      }
    )

    const block = this.loadedDriverRows
      .find((driver) => driver.userId === data.id)
      .posRowBlocks.find(
        (block) => block.reservationId === this.selectedReservationId
      )
    // Accounts for reservations with no grid view
    if (block) {
      block.isConfirmed = data.isConfirmed
    }
  }

  @Action
  updateConflicts(): boolean {
    this.addedVehicleConflicts =
      this.selectedAssignments.length -
      this.selectedReservation.vehicleAssignments.length

    this.vehicleConflicts = this.selectedAssignments.reduce((arr, vehicle) => {
      const conflictByConflictType = this.unavailableVehicles
        .filter(
          (uv) =>
            uv.vehicleId === vehicle.id &&
            uv.reservationId !== this.selectedReservationId
        )
        .reduce(groupBy('conflictType'), {})
      arr.push(
        ...Object.values(conflictByConflictType).map((data) => ({
          id: vehicle.id,
          name: vehicle.name,
          data,
        }))
      )
      return arr
    }, [])

    this.vehicleTimeOffConflicts = this.selectedAssignments.reduce(
      (arr, vehicle) => {
        const timeOffConflicts = this.vehicleTimeOff
          .filter((timeoff) => timeoff.vehicleId === vehicle.id)
          .map((timeoff) =>
            this.timeOffToFormattedConflictAdapter(
              timeoff,
              this.selectedReservation
            )
          )
        arr.push(...timeOffConflicts)
        return arr
      },
      []
    )

    this.driverConflicts = this.selectedAssignments
      .flatMap((a) => a.drivers)
      .reduce((arr, driver) => {
        const conflictByConflictType = this.unavailableDrivers
          .filter(
            (uv) =>
              uv.driverId === driver.id &&
              uv.reservationId !== this.selectedReservationId
          )
          .reduce(groupBy('conflictType'), {})
        arr.push(
          ...Object.values(conflictByConflictType).map((data) => ({
            id: driver.id,
            name: driver.name,
            data,
          }))
        )
        return arr
      }, [])

    return (
      !!this.vehicleConflicts.length ||
      !!this.driverConflicts.length ||
      !!this.vehicleTimeOffConflicts.length ||
      this.addedVehicleConflicts !== 0
    )
  }

  async fetchVehicleTimeOff(): Promise<void> {
    try {
      if (!this.selectedReservation) {
        return
      }

      const BUFFER_TIME = 1 // hardcoded buffer time for short trip turnarounds
      const start = dayjs(this.selectedReservation.startDate).subtract(
        BUFFER_TIME,
        'hour'
      )
      const end = dayjs(
        this.selectedReservation.endDate ||
          this.selectedReservation.garageTimes?.returnTime
      ).add(BUFFER_TIME, 'hour')
      const response = await vehicle.getTimeOffByDateRange(
        start.toISOString(),
        end.toISOString()
      )
      this.vehicleTimeOff = response?.data || []
    } catch (error) {
      console.error('Error fetching vehicle time off conflicts', error)
    }
  }

  timeOffToFormattedConflictAdapter(
    timeOff: VehicleTimeOff,
    reservation: Reservation
  ): FormattedAssignmentConflict {
    const timeOffStart = dayjs(timeOff.start)
    const timeOffEnd = dayjs(timeOff.end)
    const reservationStart = dayjs(reservation.startDate)
    const reservationEnd = dayjs(
      reservation.endDate || reservation.garageTimes?.returnTime
    )

    // default is a timeoff conflict with buffer time which is 1 hour
    let name = 'Potential Conflict - Short Turnaround with Time Off'
    let description =
      'Scheduled for time off that overlaps with the buffer time of this trip.'
    let conflictType = 7
    if (timeOff.indefinite) {
      name = 'Potential Conflict - Out Indefinitely'
      description = 'Marked as “vehicle out indefinitely”'
      conflictType = 6
    } else if (
      (timeOffStart.isBefore(reservationStart) &&
        timeOffEnd.isAfter(reservationEnd)) ||
      (timeOffStart.isAfter(reservationStart) &&
        timeOffStart.isBefore(reservationEnd)) ||
      (timeOffEnd.isAfter(reservationStart) &&
        timeOffEnd.isBefore(reservationEnd))
    ) {
      name = 'Potential Conflict - Scheduled for Time Off'
      description = 'Scheduled for time off that overlaps with this trip.'
      conflictType = 5
    }

    return {
      id: timeOff.vehicleId,
      name,
      data: [
        {
          reservationId: reservation.reservationId,
          reservationManagedId: reservation.managedId,
          reservationType: reservation.reservationType,
          conflictType,
          customerName: reservation.customerName,
          customerAccountName: reservation.customerAccountName,
          vehicleId: timeOff.vehicleId,
          vehicleType: -1,
          vehicleName: this.vehicles.find(
            (v) => v.vehicleId === timeOff.vehicleId
          )?.vehicleName,
        },
      ],
      content: name,
      description,
    }
  }

  @Action
  resetConflicts(): void {
    this.selectedAssignments = this.selectedAssignments.map((assignment) => {
      assignment.errorMessage = null
      assignment.drivers.map((driver) => (driver.errorMessage = null))
      return assignment
    })
  }

  @Action
  async saveSelected(skipReferralSync = false): Promise<void> {
    this.selectedAssignments.map((a) => ({
      ...a,
      vehicleTypeId: a.vehicleTypeId || 1,
      type: a.type || 'Charter Bus',
    }))
    const successful = await saveAssignments(skipReferralSync)
    if (successful) {
      sidebar.close()
      this.clearSelected()
      this.refresh()
    }
  }

  @Action
  setIsAssigning(vehicleIdx: number): void {
    this.isAssigningIdx = vehicleIdx
  }

  @Action
  setAssignedFromCalendar(vehicleId: number): void {
    this.isAssigningIdx = null
    sidebar.show()
    this.handleChangeVehicle({ oldIdx: this.selectedVehicleIdx, id: vehicleId })
  }

  // GENERAL UPDATES
  @Action
  setCurrentDate(date: dayjs.Dayjs): void {
    this.currentDate = date.startOf('day')
  }

  @Action
  decrementDate(): void {
    let days: number
    switch (this.scale) {
      case TimelineScaleOptions.HALFWEEK:
        days = -3
        break
      case TimelineScaleOptions.WEEK:
        days = -7
        break
      default:
        days = -1
    }
    this.currentDate = this.currentDate.add(days, 'day')
    this.refresh()
  }

  @Action
  incrementDate(): void {
    let days: number
    switch (this.scale) {
      case TimelineScaleOptions.HALFWEEK:
        days = 3
        break
      case TimelineScaleOptions.WEEK:
        days = 7
        break
      default:
        days = 1
    }
    this.currentDate = this.currentDate.add(days, 'day')
    this.refresh()
  }

  @Action
  setScale(scale: string): void {
    this.scale = scale

    EventBus.$emit('reset-scroll', { offset: 0, id: null })
    save('assignmentsScale', this.scale)
  }

  @Action
  setTotals(totals: AssignmentTotals): void {
    this.numDraftAssignments = totals?.numDraftAssignments
    this.numUnassignedReservations = totals?.numUnassignedReservations
  }

  @Action
  updateNumUnassignedReservations(count: number): void {
    this.numUnassignedReservations = count
  }

  @Action
  setLoading(loading: boolean): void {
    this.loading = loading
  }

  @Action
  setRefreshing(refreshing: boolean): void {
    this.refreshing = refreshing
  }
}

export default new AssignmentModule({ store, name: 'assignment' })
