
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { isNotEmptyInput } from '@/utils/validators'
import auth from '@/store/modules/auth'
import authClient from '@/services/auth'
import { EventBus } from '@/utils/eventBus'
import { UserGroupIds } from '@/data/userGroups'

@Component({})
export default class ChangePassword extends Vue {
  @Prop({ required: true, type: Boolean }) readonly value!: boolean
  @Prop({ type: Boolean, default: false }) readonly global!: boolean
  @Prop({ required: false, default: null }) readonly id!: number
  isNotEmptyInput = isNotEmptyInput
  submitting = false
  formFields: Record<string, string> = {
    newPassword: '',
    confirmNewPassword: '',
    currentPassword: '',
  }
  disabled = false

  get isAdmin(): boolean {
    return auth.getUser?.groupId === UserGroupIds.ADMIN
  }

  @Watch('formFields', { deep: true })
  onFormUpdate(): void {
    this.disabled = false
  }

  @Watch('value')
  onValueChange(newVal: boolean): void {
    if (newVal) {
      return
    }
    this.$refs['changePasswordForm']['reset']()
  }

  passwordLength(password: string): boolean | string {
    return password?.length >= 3 || 'New password must be at least 3 characters'
  }

  close(): void {
    if (this.global) {
      auth.closeChangePassword()
    }
    this.$emit('hide-modal')
  }

  async submit(): Promise<void> {
    const form: any = this.$refs['changePasswordForm']

    if (!form.validate()) {
      return
    }
    this.submitting = true

    const payload = {
      userId: this.id || auth.getUserId,
      newPassword: this.formFields.newPassword,
    }

    try {
      if (this.isAdmin) {
        await authClient.adminSetPassword(payload)
      } else {
        await authClient.setPassword({
          ...payload,
          currentPassword: this.formFields.currentPassword,
        })
      }
      EventBus.$emit('snackbar:success', 'Password changed successfully.')
    } catch {
      EventBus.$emit('snackbar:error', 'Error changing password.')
    } finally {
      if (this.global) {
        auth.closeChangePassword()
      }
      this.$emit('hide-modal')
      this.submitting = false
    }
  }
}
