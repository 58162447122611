var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"padding-x-5 padding-y-4"},[_c('div',{staticClass:"d-flex align-center margin-b-1",style:({ gap: '10px' })},[_c('CUCheckbox',{attrs:{"input-value":_vm.hourlyRateCheck,"hide-details":"","ripple":false},on:{"change":function($event){_vm.hourlyRateCheck = $event}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"w-112"},[_vm._v("Hourly Rate")])]},proxy:true}])}),_c('CUTextField',{staticClass:"max-w-120 transition-all transition-duration-300",class:{
          'opacity-1': _vm.hourlyRateCheck,
          'opacity-0': !_vm.hourlyRateCheck,
        },attrs:{"value":_vm.displayHourlyRate,"hide-details":""},on:{"change":function($event){return _vm.handleChange('hourlyRate', $event)}}})],1),_c('div',{staticClass:"d-flex align-center margin-b-1",style:({ gap: '10px' })},[_c('CUCheckbox',{attrs:{"input-value":_vm.dailyRateCheck,"hide-details":"","ripple":false},on:{"change":function($event){_vm.dailyRateCheck = $event}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"w-112"},[_vm._v("Daily Rate")])]},proxy:true}])}),_c('CUTextField',{staticClass:"max-w-120 transition-all transition-duration-300",class:{
          'opacity-1': _vm.dailyRateCheck,
          'opacity-0': !_vm.dailyRateCheck,
        },attrs:{"value":_vm.displayDailyRate,"hide-details":""},on:{"change":function($event){return _vm.handleChange('dailyRate', $event)}}})],1),_c('div',{staticClass:"d-flex align-center margin-b-1",style:({ gap: '10px' })},[_c('CUCheckbox',{attrs:{"input-value":_vm.percentageCheck,"hide-details":"","ripple":false},on:{"change":function($event){_vm.percentageCheck = $event}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"w-112"},[_vm._v("Percentage")])]},proxy:true}])}),_c('div',{staticClass:"d-flex align-center transition-all transition-duration-300",class:{
          'opacity-1': _vm.percentageCheck,
          'opacity-0': !_vm.percentageCheck,
        },style:({ gap: '10px' })},[_c('CUTextField',{staticClass:"max-w-60",class:{},attrs:{"value":_vm.displayPercentage,"hide-details":""},on:{"change":function($event){return _vm.handleChange('percentage', $event)}}}),_c('p',[_vm._v("of base fare")])],1)],1),_c('div',{staticClass:"d-flex align-center margin-b-1",style:({ gap: '10px' })},[_c('CUCheckbox',{attrs:{"input-value":_vm.perTripMinHoursCheck,"hide-details":"","ripple":false},on:{"change":function($event){_vm.perTripMinHoursCheck = $event}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"w-112"},[_vm._v("Min. hours")])]},proxy:true}])}),_c('div',{staticClass:"d-flex align-center transition-all transition-duration-300",class:{
          'opacity-1': _vm.perTripMinHoursCheck,
          'opacity-0': !_vm.perTripMinHoursCheck,
        },style:({ gap: '10px' })},[_c('CUTextField',{staticClass:"max-w-60",attrs:{"value":_vm.rate.perTripMinHours,"hide-details":""},on:{"change":function($event){return _vm.handleChange('perTripMinHours', $event)}}}),_c('p',[_vm._v("hours")])],1)],1),_c('div',{staticClass:"d-flex align-center margin-b-1",style:({ gap: '10px' })},[_c('CUCheckbox',{attrs:{"input-value":_vm.perTripMinRateCheck,"hide-details":"","ripple":false},on:{"change":function($event){_vm.perTripMinRateCheck = $event}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"w-112"},[_vm._v("Min. rate")])]},proxy:true}])}),_c('div',{staticClass:"d-flex align-center transition-all transition-duration-300",class:{
          'opacity-1': _vm.perTripMinRateCheck,
          'opacity-0': !_vm.perTripMinRateCheck,
        },style:({ gap: '10px' })},[_c('CUTextField',{staticClass:"max-w-120",attrs:{"value":_vm.displayMinRate,"hide-details":""},on:{"change":function($event){return _vm.handleChange('perTripMinRate', $event)}}}),_c('p',[_vm._v("total")])],1)],1),_c('div',{staticClass:"d-flex align-center",style:({ gap: '10px' })},[_c('CUCheckbox',{attrs:{"input-value":_vm.perDiemCheck,"hide-details":"","ripple":false},on:{"change":function($event){_vm.perDiemCheck = $event}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"w-112"},[_vm._v("Per diem")])]},proxy:true}])}),_c('div',{staticClass:"d-flex align-center transition-all transition-duration-300",class:{
          'opacity-1': _vm.perDiemCheck,
          'opacity-0': !_vm.perDiemCheck,
        },style:({ gap: '10px' })},[_c('CUTextField',{staticClass:"max-w-120",attrs:{"value":_vm.displayPerDiemRate,"hide-details":""},on:{"change":function($event){return _vm.handleChange('perDiem', $event)}}}),_c('p',[_vm._v("per day")])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }