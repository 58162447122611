import { Reservation } from '@/models/dto'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { pluralize } from '@/utils/string'

interface TimeObject {
  days: number
  hours: number
  minutes: number
  seconds: number
}

export const msToTimeLabel = (ms: number): string => {
  if (!ms && ms !== 0) {
    return null
  }

  const totalSeconds = Math.abs(Math.floor(ms / 1000))
  const totalMinutes = Math.floor(totalSeconds / 60)
  const totalHours = Math.floor(totalMinutes / 60)
  const totalDays = Math.floor(totalHours / 24)

  return `${totalDays ? `${totalDays} ${pluralize(totalDays, 'day')}, ` : ''}${
    totalHours % 24
  } ${pluralize(totalHours % 24, 'hour')}, ${totalMinutes % 60} ${pluralize(
    totalMinutes % 60,
    'minute'
  )}`
}

export const formatSecondsToDuration = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)

  return `${hours}h ${minutes}m`
}

export const calculatePlannedDays = (row: Reservation): string => {
  return (row.garageReturnTime || row.dropoffDate) &&
    (row.garageDepartureTime || row.pickupDate)
    ? `${
        Math.abs(
          dayjs(row.garageReturnTime || row.dropoffDate).diff(
            dayjs(row.garageDepartureTime || row.pickupDate),
            'days'
          )
        ) + 1
      }`
    : '--'
}

export const calculateEstimateTripTime = (row: Reservation): string => {
  const diffString = msToTimeLabel(
    dayjs(row.garageReturnTime || row.dropoffDate).diff(
      row.garageDepartureTime || row.pickupDate
    )
  )

  return diffString || '--'
}

export const timeDifferenceAsObject = (
  time1: dayjs.Dayjs,
  time2: dayjs.Dayjs
): TimeObject => {
  dayjs.extend(duration)
  let diff = time2.diff(time1)
  const multiplier = diff / Math.abs(diff)
  diff = diff * multiplier

  const days = dayjs.duration({ milliseconds: diff }).asDays()
  const hours = dayjs.duration({ days: days - Math.floor(days) }).asHours()
  const minutes = dayjs
    .duration({ hours: hours - Math.floor(hours) })
    .asMinutes()
  const seconds = dayjs
    .duration({ minutes: minutes - Math.floor(minutes) })
    .asSeconds()

  return {
    days: Math.floor(days) * multiplier,
    hours: Math.floor(hours) * multiplier,
    minutes: Math.floor(minutes) * multiplier,
    seconds: Math.floor(seconds) * multiplier,
  }
}

export const timeObjectToString = (timeObj: TimeObject): string => {
  let timeString = `${timeObj.hours}h ${timeObj.minutes}m`
  if (timeObj.days > 0) {
    timeString = `${timeObj.days}d ${timeString}`
  } else {
    timeString = `${timeString} ${timeObj.seconds}s`
  }
  return timeString
}

export const timeLabel = (time: string, format = 'HH:mm'): string => {
  const timeObj = dayjs(time, format)
  const hour = timeObj.hour() ? `${timeObj.hour()} hours` : ''
  const minute = timeObj.minute() ? `${timeObj.minute()} minutes` : ''
  const separator = timeObj.hour() && timeObj.minute() ? ', ' : ''
  return `${hour}${separator}${minute}`
}

export const timeDifferenceAsString = (
  time1: dayjs.Dayjs,
  time2: dayjs.Dayjs
): string => {
  return timeObjectToString(timeDifferenceAsObject(time1, time2))
}
export const DAYS_IN_WEEK = 7

export const arrivalTimeOffsetList = [
  { minutes: 5, label: '5 minutes' },
  { minutes: 10, label: '10 minutes' },
  { minutes: 15, label: '15 minutes' },
  { minutes: 20, label: '20 minutes' },
  { minutes: 25, label: '25 minutes' },
  { minutes: 30, label: '30 minutes' },
  { minutes: 45, label: '45 minutes' },
  { minutes: 60, label: '60 minutes' },
]

export const spotTimeOffsetList = [
  { minutes: 5, label: '5 minutes' },
  { minutes: 10, label: '10 minutes' },
  { minutes: 15, label: '15 minutes' },
  { minutes: 20, label: '20 minutes' },
  { minutes: 25, label: '25 minutes' },
  { minutes: 30, label: '30 minutes' },
  { minutes: 45, label: '45 minutes' },
  { minutes: 60, label: '60 minutes' },
  { minutes: 75, label: '75 minutes' },
  { minutes: 90, label: '90 minutes' },
  { minutes: 105, label: '105 minutes' },
  { minutes: 120, label: '120 minutes' },
]

export const preTripArrivalTimeOffsetList = [
  { time: '00:05:00', label: '5 minutes' },
  { time: '00:10:00', label: '10 minutes' },
  { time: '00:15:00', label: '15 minutes' },
  { time: '00:20:00', label: '20 minutes' },
  { time: '00:25:00', label: '25 minutes' },
  { time: '00:30:00', label: '30 minutes' },
  { time: '00:45:00', label: '45 minutes' },
  { time: '01:00:00', label: '60 minutes' },
]

export const drivingTimeLabel = (
  seconds: number,
  format = 'H [Hrs], m [Min]'
): string => {
  const duration = dayjs.duration(seconds, 'seconds')
  return duration.format(format)
}

export const timeZones = [
  {
    id: 0,
    label: 'Eastern Time',
    zoneName: 'America/New_York',
  },
  {
    id: 1,
    label: 'Central Time',
    zoneName: 'America/Chicago',
  },
  {
    id: 2,
    label: 'Mountain Time',
    zoneName: 'America/Denver',
  },
  {
    id: 3,
    label: 'Pacific Time',
    zoneName: 'America/Los_Angeles',
  },
  {
    id: 4,
    label: 'Alaskan Time',
    zoneName: 'America/Anchorage',
  },
  {
    id: 5,
    label: 'Hawaiian Time',
    zoneName: 'Pacific/Honolulu',
  },
]

export const convertDurationToMinutesInteger = (time) => {
  const timeDuration = time.split(':')
  return dayjs
    .duration({
      hours: timeDuration[0],
      minutes: timeDuration[1],
      seconds: timeDuration[2],
    })
    .asMinutes()
}

// Convert minutes to hours and remaining minutes
export const convertMinutesToHoursAndMinutes = (
  minutes: number
): { hours: number; minutes: number } => {
  const hr = minutes < 0 ? Math.ceil(minutes / 60) : Math.floor(minutes / 60)
  const min = minutes % 60

  return {
    hours: hr,
    minutes: min,
  }
}

// Return the absolute difference in minutes between two ISO dates
export const getMinutesBetweenDates = (
  date1: string,
  date2: string
): number => {
  const date1Obj = dayjs.utc(date1).set('second', 0)
  const date2Obj = dayjs.utc(date2).set('second', 0)
  const diff = Math.abs(date2Obj.diff(date1Obj, 'minutes'))
  return diff
}
