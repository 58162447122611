
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TableAction } from '@/models/TableAction'

@Component
export default class CUDataTableActionRow extends Vue {
  @Prop({
    type: Array,
    required: true,
  })
  actions!: TableAction[]

  @Prop({
    type: Boolean,
    default: false,
  })
  simple!: boolean

  @Prop({
    required: false,
    default: undefined,
  })
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rows!: any[]

  dialogOpen = false
  dialogText: string | undefined = ''
  dialogPrimaryActionText = ''
  dialogSecondaryActionText = ''

  dialogConfirmFn = (): Record<never, never> => ({})
  currentAction: TableAction | undefined = undefined
  closeDialog(): void {
    this.dialogOpen = false
  }

  actionTooltip(action: TableAction): string {
    const defaultTooltip = 'This action cannot be done in bulk'
    switch (typeof action.tooltip) {
      case 'string':
        return action.tooltip || defaultTooltip
      case 'function':
        return action.tooltip(this.rows) || defaultTooltip
      default:
        return defaultTooltip
    }
  }

  actionColor(action: TableAction): string {
    return this.isActionDisabled(action) ? 'gray' : action.color || 'green'
  }

  handleAction(action: TableAction): void {
    if (action.action) {
      action.action(this.rows)
    }
  }

  isActionDisabled(action: TableAction): boolean {
    if (action.isSingleContactOnly && this.rows?.length > 1) {
      const check = this.rows[0].customerId
      return !this.rows.every((row) => row.customerId === check)
    }
    return (
      (action.isSingleSelectOnly && this.rows?.length > 1) ||
      (action.isMultiSelectOnly && this.rows?.length === 1) ||
      (typeof action.disabled === 'boolean' && action.disabled) ||
      (typeof action.disabled === 'function' && action.disabled(this.rows))
    )
  }
}
