
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class HoldUpModal extends Vue {
  @Prop({ type: Boolean, default: null }) readonly value!: boolean
  @Prop({ required: false, default: 'Confirm' }) readonly text!: string
  @Prop({ required: false, default: 'Cancel' }) readonly cancelText!: string
  @Prop({ required: false, default: 'item' }) readonly type!: string
  @Prop({
    required: false,
    default: 'Are you sure you want to delete these items?',
  })
  readonly confirmationQuestion!: string
  @Prop({ type: Number, default: null, required: false })
  readonly highlightNumber!: number
  @Prop({
    required: false,
    default: 'Hold up!',
  })
  readonly title!: string
  @Prop({ type: Boolean, default: false })
  readonly loading!: boolean

  handleConfirm(): void {
    this.$emit('confirm')
    this.$emit('input', false)
  }

  handleCancel(): void {
    this.$emit('cancel')
    this.$emit('input', false)
  }
}
