
import {
  Reservation,
  ReservationDetailStop,
  SpotTime,
  VehicleAssignment,
} from '@/models/dto'
import ReservationStore from '@/store/modules/reservation'
import { Action } from '@/models/dto/Action'
import { Journey, JourneyStop } from '@/models/dto/Journey'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import auth from '@/store/modules/auth'
import StopWithNotes from './StopWithNotes.vue'
import ReservationTrackingRow from './ReservationTrackingRow.vue'
import { TrackingStatus, SourceCategory } from '@/utils/enum'
import { Garage } from '@/models/dto/Garage'
import { TrackingResponse } from '@/models/dto/Tracking'

@Component({
  components: { StopWithNotes, ReservationTrackingRow },
})
export default class ReservationDetailPanelItinerary extends Vue {
  @Prop({ required: false, default: null }) vehicle: Action

  auth = auth
  isReferral: boolean =
    this.reservation.sourceCategory === SourceCategory.REFERRAL

  @Watch('vehicles')
  setInitialVehicle(): void {
    if (this.vehicles.length) {
      this.$emit('vehicle-change', this.vehicles[0])
    }
  }

  get reservation(): Reservation {
    return ReservationStore.reservation
  }

  get vehicleAssignments(): VehicleAssignment[] {
    return ReservationStore.assignments
  }

  get tracking(): TrackingResponse {
    return ReservationStore.tracking
  }

  get vehicles(): { label: string; id: number; event: string }[] {
    const assigned = []
    const unassigned = this.reservation.journeys?.reduce((arr, j) => {
      const existingIndex = arr.findIndex((el) => el.id === j.vehicle.vehicleId)
      const newVehicle = {
        label: j.vehicle.vehicleName
          ? j.vehicle.vehicleName
          : `Vehicle ${j.vehicle.vehicleId}`,
        id: j.vehicle.vehicleId,
        event: 'input',
      }
      if (existingIndex !== -1) {
        arr.splice(existingIndex, 1)
      }
      if (j.finished && !j.finishDatetime) {
        arr.push(newVehicle)
      } else {
        assigned.push(newVehicle)
      }
      return arr
    }, [])
    return [...assigned, ...unassigned]
  }

  get departureGarage(): {
    garage: Garage
    time: string
    arrivalTime: string
    timeZone: string
    notes: string
  } {
    return {
      garage: this.reservation.garageTimes?.garage || null,
      time: this.reservation.garageTimes?.departureTime || null,
      arrivalTime: this.reservation.garageTimes?.preTripArrivalTime || null,
      timeZone:
        this.reservation.garageTimes?.garage?.address?.timeZone ||
        this.reservation.stops[0]?.address?.timeZone ||
        auth.getUserTimeZone,
      notes:
        this.reservation.garageTimes?.htmlGarageTimeNotes ||
        this.reservation.garageTimes?.garageTimeNotes ||
        null,
    }
  }

  get arrivalGarage(): {
    garage: Garage
    time: string
    timeZone: string
    notes: string
  } {
    return {
      garage: this.reservation.garageTimes?.returnGarage || null,
      time: this.reservation.garageTimes?.returnTime || null,
      timeZone:
        this.reservation.garageTimes?.returnGarage?.address?.timeZone ||
        this.reservation.stops[(this.reservation.stops.length || 1) - 1]
          ?.address?.timeZone ||
        auth.getUserTimeZone,
      notes:
        this.reservation.garageTimes?.htmlReturnGarageTimeNotes ||
        this.reservation.garageTimes?.returnGarageTimeNotes ||
        null,
    }
  }

  get addresses(): string[] {
    return [
      this.reservation.garageTimes?.garage?.address.name,
      this.reservation.garageTimes?.returnGarage?.address.name,
    ].concat(
      ...this.reservation.stops.map((stop) => {
        return stop.address.name
      })
    )
  }

  get journeys(): any {
    return (
      this.reservation.journeys
        ?.filter((j) => j?.vehicle?.vehicleId)
        .reduce((obj, journey) => {
          obj[journey.vehicle.vehicleId] = journey
          return obj
        }, {}) || {}
    )
  }

  get isJourneyActive(): boolean {
    if (!this.vehicle) {
      return false
    }
    return (
      this.journeys[this.vehicle.id]?.started ||
      this.journeys[this.vehicle.id]?.commutingDriverId ||
      false
    )
  }

  get journeyStartTime(): { time: string } {
    if (!this.vehicle) {
      return null
    }
    return { time: this.journeys[this.vehicle.id]?.startDatetime }
  }

  get journeyFinishTime(): { time: string } {
    if (!this.vehicle) {
      return null
    }
    return { time: this.journeys[this.vehicle.id]?.finishDatetime }
  }

  get trackingStatus(): string[] {
    if (!this.vehicle || !this.journeys[this.vehicle.id]) {
      return []
    }
    const status = [
      this.journeys[this.vehicle.id]?.startDatetime
        ? TrackingStatus.Completed
        : null,
    ]
    const sortedStops = this.journeys[this.vehicle.id].journeyStops.sort(
      (a, b) => (a.stop.orderIndex > b.stop.orderIndex ? 1 : -1)
    )
    for (const [i, stop] of sortedStops.entries()) {
      if (stop.completed) {
        status.push(TrackingStatus.Completed)
      } else if (stop.reached) {
        status.push(TrackingStatus.Reached)
      } else if (status[i] === TrackingStatus.Completed) {
        status.push(TrackingStatus.EnRoute)
      } else {
        status.push(TrackingStatus.Upcoming)
      }
    }
    if (this.journeys[this.vehicle.id].finishDatetime) {
      status.push(TrackingStatus.Completed)
    } else if (status[status.length - 1] === TrackingStatus.Completed) {
      status.push(TrackingStatus.EnRoute)
    } else {
      status.push(TrackingStatus.Upcoming)
    }
    return status
  }

  get driverCommuteData(): Partial<Journey> {
    if (!this.vehicle) {
      return {}
    }
    return {
      commutingDriverId: this.journeys[this.vehicle.id]?.commutingDriverId,
      driverCommuteStart: this.journeys[this.vehicle.id]?.driverCommuteStart,
      driverCommuteDurationInMinutes:
        this.journeys[this.vehicle.id]?.driverCommuteDurationInMinutes,
    }
  }

  getSpotTime(stop: ReservationDetailStop): SpotTime {
    return this.reservation.spotTimes.find((spotTime) => {
      return spotTime.stopId === stop.stopId
    })
  }

  getJourneyStop(id: number): JourneyStop {
    if (!this.vehicle) {
      return null
    }
    return this.journeys[this.vehicle.id]?.journeyStops?.find(
      (s) => s.stop.stopId === id
    )
  }
}
