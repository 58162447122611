export const typeDefs = `#graphql
  type Invoice {
    id: ID
    reservationId: String
    managedId: String
    driverId: String
    bookingContactId: String
    vehicleId: String
    companyId: String
    start: String
    startTimezone: String
    stop: String
    stopTimezone: String
    startAddressId: String
    totalCharges: Float
    totalTime: Float
    paystubId: String
    manualOverride: Boolean
    status: String

    driver: User
    vehicle: Vehicle
    bookingContact: User
    company: Company
    startAddress: Address
  }

  type Vehicle {
    vehicleId: String
    vehicleName: String
  }

  type Company {
    customerAccountId: String
    name: String
  }

  type Address {
    addressId: String
    addressName: String
    street1: String
    street2: String
    city: String
    state: String
    postalCode: String
    lat: Float
    lng: Float
    timeZone: String
    title: String
    nearestMarketId: String
  }

  type Paystub {
    id: ID
    label: String
    status: String
    earliestPickupTimestamp: String
    latestDropoffTimestamp: String
    driver: User
    paidDate: String
    total: Float
  }

  type User {
    userId: ID
    name: String
  }

  type Sort {
    sorted: Boolean
    unsorted: Boolean
    empty: Boolean
  }

  type Pageable {
    sort: Sort
    offset: Int
    pageNumber: Int
    pageSize: Int
    unpaged: Boolean
    paged: Boolean
  }

  type InvoicePagedResult {
    content: [Invoice]
    pageable: Pageable
    last: Boolean
    totalPages: Int
    totalElements: Int
    first: Boolean
    numberOfElements: Int
    size: Int
    number: Int
    sort: Sort
    empty: Boolean
  }

  type PaystubPagedResult {
    content: [Paystub]
    pageable: Pageable
    last: Boolean
    totalPages: Int
    totalElements: Int
    first: Boolean
    numberOfElements: Int
    size: Int
    number: Int
    sort: Sort
    empty: Boolean
  }

  type Query {
    listInvoices(
      reservationId: String, 
      start: String, 
      stop: String, 
      driverIds: [Int], 
      status: [String], 
      sort: String, 
      page: Int, 
      size: Int
    ): InvoicePagedResult
    listPaystubs(
      label: String,
      status: [String], 
      driverIds: [Int], 
      paidDate: String,
      total: Float,
      sort: String,
      page: Int,
      size: Int
    ): PaystubPagedResult
  }
`
