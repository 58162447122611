var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('CUExpansionPanel',{attrs:{"arrow-left":"","readonly":!_vm.expandable,"padding-y":"4","label":"Assignment"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"d-flex align-center z-10"},[_c('p',{staticClass:"font-bold"},[_vm._v("Assignment")]),(!_vm.assignment.refreshing)?[_c('div',{staticClass:"d-flex align-center"},[_c('CUIcon',{staticClass:"margin-r-1 margin-l-4",attrs:{"aria-label":"Vehicle","color":_vm.assigned.areVehiclesFull ? 'secondary' : 'additional-red',"width":"18px"}},[_vm._v(" bus_plain ")]),_c('p',[_vm._v(_vm._s(_vm.assigned.vehicles))])],1),_c('div',{staticClass:"d-flex align-center"},[_c('CUIcon',{staticClass:"margin-r-1 margin-l-4",attrs:{"color":_vm.assigned.areDriversFull ? 'secondary' : 'additional-red',"width":"16px"}},[_vm._v(" driver ")]),_c('p',[_vm._v(_vm._s(_vm.assigned.drivers))])],1)]:_vm._e()],2)]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"d-flex justify-end position-relative margin-b-n8",style:({ top: '-37px' })},[_c('CUIcon',{staticClass:"cursor-pointer",attrs:{"width":"20px","color":"bg-gray-4"},on:{"click":_vm.handleEditAssignments}},[_vm._v(" edit ")])],1),(!_vm.loading)?_vm._l((_vm.filteredAssignments),function(vehicle,i){return _c('div',{key:`assigned-vehicle-${vehicle.type}-${i}`,staticClass:"margin-t-1"},[_c('div',{staticClass:"d-flex align-center margin-b-3",style:({ gap: '12px' })},[_c('AssignmentsAvatar',{attrs:{"small":"","vehicle-assignment":vehicle}}),_c('div',[_c('p',{staticClass:"font-12 text-text-gray-1"},[_vm._v(_vm._s(vehicle.type))]),_c('p',{class:_vm.getTextColorForVehicle(vehicle)},[_vm._v(" "+_vm._s(vehicle.name || '--')+" ")])]),_c('div',{staticClass:"d-flex align-end",style:({ marginBottom: '-1px' })},[(
                !vehicle.isConfirmed &&
                vehicle.id &&
                !_vm.isReservationCancelled &&
                !_vm.isReservationDeleted
              )?_c('CUTag',{staticClass:"margin-a-1",attrs:{"border":"bg-gray-5","background":"bg-gray-1","tag":"Draft","font-size":8,"additional-style":{
                paddingLeft: '10px',
                paddingRight: '10px',
                height: '17px',
              }}}):_vm._e()],1)],1),_vm._l((vehicle.drivers),function(driver,j){return _c('div',{key:`assigned-driver-${driver.key}-${j}`,staticClass:"d-flex align-center margin-b-3",style:({ gap: '12px' })},[_c('AssignmentsAvatar',{attrs:{"small":"","driver-assignment":driver}}),_c('div',[_c('p',{staticClass:"font-12 text-bg-gray-5"},[_vm._v(_vm._s(driver.label))]),_c('p',{class:_vm.getTextColorForDriver(driver)},[_vm._v(" "+_vm._s(driver.name || '--')+" ")])]),_c('div',{staticClass:"d-flex align-end",style:({ marginBottom: '-1px' })},[(
                _vm.getStatusForDriver(driver) &&
                _vm.enableDriverAssignmentAcceptance &&
                !_vm.isReservationCancelled &&
                !_vm.isReservationDeleted
              )?_c('DriverAssignmentTag',{attrs:{"assignment-status":_vm.getStatusForDriver(driver)}}):_vm._e()],1)],1)}),(i !== _vm.requiredVehicles.length - 1)?_c('v-divider',{staticClass:"margin-y-3"}):_vm._e()],2)}):_c('CUSkeletonLoader',{staticClass:"margin-y-2",attrs:{"height":"92px"}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }