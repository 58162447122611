import dayjs from 'dayjs'
import { ApiResult, Bid, PaymentType, Stop, TripPaymentMethod, Type } from '.'
import { CheckoutType } from './Checkout'
import { Customer } from './Customer'
import { GarageTimes } from './GarageTimes'
import { Vehicle } from './Vehicle'
import { Address } from './Address'
import { TableViewResult } from '../TableView'
import { OverageRate } from '@/models/dto/Rate'
import { Recurrence } from '@/models/dto/Recurrence'
import { LineItemCharge } from '@/models/dto/LineItemCharge'
import { TripMarkup } from '@/models/dto/Markup'
import { TripPricingLog } from './TripPricingLog'
import { TripPricingDetail } from './TripPricingDetail'
import { LineItemSectionTypes } from '@/utils/types'

export interface Trip {
  tripStatusId: number
  openForBid: boolean
  openForAllMarkets: boolean
  openForAllCompanies: boolean
  tripId: number
  active: boolean
  dueDate: string
  startDate: string
  total: number
  depositPercentage: number
  depositAmount: number
  amountDueNow: number
  quoteId: number
  requiredDrivers: number
  passengerCount: number
  description?: string
  distance: number
  drivingTime: number
  calendarDays: number
  deadDistance?: number
  deadSeconds?: number
  companyId: number
  customerId: number
  paymentTypeId: number
  tripTypeId: number
  notes?: string
  tripNotes: TripNote[]
  createdOn: dayjs.Dayjs
  updatedOn?: string
  leadCompanyId?: number
  leadGarageId?: number
  firstStopAddressId: number
  firstDropoffAddressId: number
  lineItemCharges: LineItemCharge[]
  tripMarkups: TripMarkup[]
  lastStopAddressId: number
  lastStopId: number
  biddingEndDate: dayjs.Dayjs
  maxBidAmount?: number
  bookItNowAmount?: number
  allowedBiddingCompanyType: string
  allowMultipleBids: boolean
  bidStart: dayjs.Dayjs
  pricingMethod: string
  pricingMarket: number
  pricingGarageId?: number
  routeName?: string
  vehicleNeededEntireTrip: boolean
  tripType: TripType
  checkoutType: CheckoutType
  paymentType: PaymentType
  stops: Stop[]
  rates?: OverageRate[]
  charges: TripCharge[]
  vehicles: Vehicle[]
  paymentMethods: TripPaymentMethod[]
  tripMarkets: TripMarket[]
  tripCompanies: TripCompany[]
  customer: Customer
  tripContact: TripContact
  tripContactId: number
  garageTimes: GarageTimes
  parentTripId?: number
  hasRecurrence: boolean
  recurrenceTripCount: number
  recurrences: Recurrence[]
  recurringTripTotal?: number
  recurringAmountDueNow?: number
  recurrenceText?: string
  requiredVehicles?: RequiredVehicle[]
  updateRecurringTrips: boolean
  updateRecurringTripsFromDate?: dayjs.Dayjs
  processingFeePercentage: number
  marketplaceDiscountPercent: number
  istripContactSelected: boolean
  processingFeeCharge: number
  endDate?: string
  hourlyDiff: number
  estimatedTimeLabel: string
  deadMileLabel: string
  liveMileLabel: string
  tripTypeKey: string
  pricingSelectionId?: number
  tripPricings?: TripPricingLog[]
  tripPricingDetail: TripPricingDetail
}

// eslint-disable-next-line no-redeclare
export class Trip implements Trip {
  constructor(trip?: Partial<Trip>) {
    this.rates = []
    this.charges = []
    this.vehicles = [new Vehicle()]
    this.paymentMethods = []
    this.total = 0
    this.distance = 0
    this.drivingTime = 0
    this.calendarDays = 0
    this.depositPercentage = trip?.depositPercentage || 0
    this.depositAmount = trip?.depositAmount || 0
    this.recurrenceTripCount = trip?.recurrenceTripCount || 1
    this.dueDate = trip?.dueDate || null
    this.paymentTypeId = 1
    this.pricingSelectionId = 1
    this.garageTimes = {
      companyId: null,
      tripId: null,
      departureTime: '',
      returnTime: '',
    }
    this.tripPricingDetail = new TripPricingDetail()
    this.tripId = trip?.tripId != null ? trip.tripId : 0
    this.stops = [
      new Stop({ orderIndex: 0, pickupDatetime: '' }),
      new Stop({ orderIndex: 1, dropoffDatetime: '' }),
    ]
    Object.assign(this, trip)
  }
}

export interface TripType {
  id: number
  label: string
  key: string
  description?: string
}

export interface TripChargeType {
  id: number
  label: string
  key: string
  description?: string
  active?: boolean
  companyId?: number
}

export interface TripCharge {
  tripChargeId: number
  tripId: number
  chargeTypeId: number
  amount: number
  chargeType: TripChargeType
}

export interface TripMarket {
  tripId: number
  marketId: number
}

export interface TripCompany {
  tripId: number
  companyId: number
  activationDatetime: Date
}

export interface TripContact {
  id?: number
  firstName: string
  lastName: string
  email: string
  phone: string
  phoneExtension?: string
  phoneCountryKey?: string
}

export interface TripContactUpdate {
  tripContact: {
    customerId: number
  }
}

export interface TripNote {
  noteId: number
  note: string
  html: string
  createdOn: Date
  createdBy: number
  noteType: number
  active: boolean
}

// eslint-disable-next-line no-redeclare
export class TripNote implements TripNote {
  constructor(tripNote?: Partial<TripNote>) {
    return Object.assign(this, tripNote)
  }
}

export interface RequiredVehicle {
  requiredVehicles: number
  vehicleType: string
  vehicleTypeId: number
}

export interface TripResult extends ApiResult {
  trip: Trip
}

export type TripTableViewResult = TableViewResult<TableViewTrip>
export interface TableViewTrip {
  bidCount: number
  parentReservationId?: number
  biddingEndDate: string
  bookItNowAmount?: number
  companyName: string
  bids?: Bid[] | null
  stops?: TableViewTripStop[] | null
  requiredVehicles?: TableViewTripRequiredVehicles[] | null
  requiredDrivers: number
  quoteId: number
  quoteManagedId: number
  tripId: number
  reservationId?: number
  managedId?: string
  total: number
  tripTypeLabel: string
  passengerCount: number
  referrals?: null
  customerId: number
  customerFirstName: string
  customerLastName: string
  maxBidAmount?: number
  notes?: string
}
export interface TableViewTripStop {
  stopId: number
  orderIndex: number
  address: Address
  addressId?: null
  pickupDate?: string | null
  dropoffDate?: string | null
}
export interface TableViewTripRequiredVehicles {
  vehicleTypeId: number
  vehicleType: string
  requiredVehicles: number
}

export interface StopEstimation {
  distanceFromPrevious: number
  timeFromPrevious: number
}

export interface TripEstimation {
  tripId: number
  deadDistance: number
  deadDuration: number
  distance: number
  duration: number
  durationInDays: number
  calendarDays: number
  itineraryHours: number
  itineraryHoursAsDays: number
  itineraryHoursAsSeconds: number
  startDate: string
  endDate: string
  estimatedEndDate: string
  deadMileLabel: string
  liveMileLabel: string
  estimatedTimeLabel: string
  tripEstimationLogs?: unknown
  timesFromPreviousStop: (number | null)[]
  firstDeadLegDuration?: number
  firstDeadLegDistance?: unknown
  lastDeadLegDuration?: number
  lastDeadLegDistance?: unknown
  nearestMarketId: number
  stopEstimations?: StopEstimation[]
  garageEstimations?: StopEstimation[]
}

export type TripStopTypeKey =
  | 'dropoff_only'
  | 'pickup_only'
  | 'dropoff_and_pickup'

export type TripStopCard = Stop | { type: string }

export type TripPricingSelection = 'Highest' | 'Choose'
