import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from '@/scss/_colors-export.scss'
import { toCamel } from '@/utils/string'
import Checkbox from '@/assets/icons/vuetify-replacements/Checkbox.vue'
import CheckboxFilled from '@/assets/icons/vuetify-replacements/CheckboxFilled.vue'
import Prev from '@/assets/icons/vuetify-replacements/Prev.vue'
import Next from '@/assets/icons/vuetify-replacements/Next.vue'
import Clear from '@/assets/icons/vuetify-replacements/Clear.vue'
import CheckboxMinus from '@/assets/icons/vuetify-replacements/CheckboxMinus.vue'
import Sort from '@/assets/icons/vuetify-replacements/Sort.vue'
Vue.use(Vuetify)

const caseConvertedColors: { [index: string]: string } = {}
for (const [key, value] of Object.entries(colors)) {
  const convertedKey: string = toCamel(`${key}`)
  caseConvertedColors[convertedKey] = value
}

export default new Vuetify({
  theme: {
    themes: {
      light: caseConvertedColors,
    },
  },
  icons: {
    values: {
      checkboxOff: {
        component: Checkbox,
        props: {
          name: 'checkboxOff',
        },
      },
      checkboxOn: {
        component: CheckboxFilled,
        props: {
          name: 'checkboxOn',
        },
      },
      prev: {
        component: Prev,
        props: {
          name: 'prev',
        },
      },
      next: {
        component: Next,
        props: {
          name: 'next',
        },
      },
      checkboxIndeterminate: {
        component: CheckboxMinus,
        props: {
          name: 'checkboxIndeterminate',
        },
      },
      clear: {
        component: Clear,
        props: {
          name: 'clear',
        },
      },
      sort: {
        component: Sort,
        props: {
          name: 'sort',
        },
      },
    },
  },
})
