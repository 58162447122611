
import { Vue, Component, Prop } from 'vue-property-decorator'
import colors from '@/scss/_colors-export.scss'

@Component
export default class CUTextArea extends Vue {
  @Prop({ required: false, default: undefined }) readonly borderColor: string
  @Prop({ type: Boolean, default: false }) widget!: boolean

  get cssVars(): Record<string, string> {
    const borderColorDefault =
      colors[this.borderColor] || colors['gray-border-mid']
    const borderColorActive =
      colors[this.borderColor] || this.borderColor || colors['primary']

    return {
      '--text-field-border-color-default': borderColorDefault,
      '--text-field-border-color-active': borderColorActive,
    }
  }

  get listeners(): Record<string, unknown> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this
    return Object.assign({}, this.$listeners, {
      input(event: InputEvent) {
        vm.$emit('input', event)
      },
    })
  }
}
