import { Action, Module, VuexModule } from 'vuex-class-modules'
import store from '@/store/index'
import { Sidebar, SidebarContent, SidebarState } from '@/models/Sidebar'
import Placeholder from '@/views/Placeholder.vue'
import miniSidebar from './miniSidebar'

@Module({ generateMutationSetters: true })
class SidebarModule extends VuexModule implements Sidebar {
  state = SidebarState.CLOSED
  overlayBackground = false
  _components: SidebarContent[] = []
  _fabComponent: SidebarContent | null = null

  CLOSED_WIDTH_WITH_FAB = 24
  CLOSED_WIDTH_WITHOUT_FAB = 0
  DEFAULT_WIDTH = 400

  get components(): SidebarContent[] {
    return this._components
  }

  get content(): SidebarContent {
    return this._components[this._components.length - 1]
  }

  get stackLength(): number {
    return this._components.length
  }

  get isOpen(): boolean {
    return this._components.length > 0
  }

  get isClosed(): boolean {
    return this._components.length === 0
  }

  get isHidden(): boolean {
    return this.state === SidebarState.HIDDEN
  }

  get fabComponent(): SidebarContent | null {
    return this._fabComponent
  }

  get width(): number {
    // sidebar is open, return the width
    if (this.isOpen && !this.isHidden) {
      return this.content.width || this.DEFAULT_WIDTH
    }

    // sidebar is closed, but has FAB, return the closed width with FAB
    if ((this.isClosed && this.fabComponent !== null) || this.isHidden) {
      return this.CLOSED_WIDTH_WITH_FAB
    }

    // otherwise, return the default closed width
    return this.CLOSED_WIDTH_WITHOUT_FAB
  }

  get showFAB(): boolean {
    return (
      this.content?.fab === true ||
      (this.isClosed && this.fabComponent !== null)
    )
  }

  @Action
  open(): void {
    this.state = SidebarState.OPEN
  }

  @Action
  toggle(sidebarContent: SidebarContent): void {
    if (
      this.state === SidebarState.OPEN &&
      sidebarContent.component === this.content.component
    ) {
      this.pop()
    } else {
      this.popAllAndPush(sidebarContent)
    }
  }

  @Action
  close(): void {
    miniSidebar.close()
    this.state = SidebarState.CLOSED
    if (this._components?.[0]?.onClose) {
      this._components[0].onClose()
    }
    this._components = []
  }

  @Action
  addFabComponent(fabComponent: SidebarContent | null): void {
    this._fabComponent = fabComponent
    this.overlayBackground = !!fabComponent?.overlayBackground
  }

  @Action
  push(sidebarContent: SidebarContent): void {
    this.openSidebar(sidebarContent)
    this.overlayBackground = !!sidebarContent?.overlayBackground
  }

  @Action
  popAllAndPush(sidebarContent: SidebarContent): void {
    this.close()
    this.openSidebar(sidebarContent)
  }

  @Action
  pop(): void {
    this._components.pop()
    if (this._components.length === 0) {
      this.close()
    }
  }

  @Action
  hide(): void {
    if (this.state === SidebarState.OPEN) {
      this.state = SidebarState.HIDDEN
    } else {
      this.state = SidebarState.OPEN
    }
  }

  @Action
  show(): void {
    if (this.state === SidebarState.HIDDEN) {
      this.state = SidebarState.OPEN
    }
  }

  @Action
  setTitle(title: string): void {
    if (!this._components.length) {
      return
    }
    this._components[this._components.length - 1].title = title
  }

  openSidebar({
    title = '',
    component = Placeholder,
    props = {},
    on = {},
    width = 450,
    persist = false,
    fab = false,
    wide = false,
    onClose = null,
    state = SidebarState.OPEN,
    overlayBackground = false,
  }) {
    this.state = state
    this.overlayBackground = overlayBackground

    this._components.push({
      title,
      component,
      props,
      on,
      width,
      fab,
      wide,
      persist,
      onClose,
    })
  }
}

// register module (could be in any file)
export default new SidebarModule({ store, name: 'sidebar' })
