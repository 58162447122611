/* eslint-disable no-unused-vars */
export enum KeyCode {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  Escape = 27,
  LeftArrow = 37,
  UpArrow = 38,
  RightArrow = 39,
  DownArrow = 40,
}

export enum ReservationType {
  CompanyOwned = 0,
  Referral = 1,
}

export enum ReservationNotificationType {
  DriverOnTheWay = 1,
  DriverInfo = 2,
}

export enum ReservationStatus {
  New = 'upcoming',
  Printed = 'printed',
  InProgress = 'started',
  Completed = 'finished',
  Cancelled = 'cancelled',
  Hold = 'hold',
  Confirmed = 'confirmed',
  Closed = 'closed',
}

export enum TrackingStatus {
  Completed = 'Completed',
  Upcoming = 'Upcoming',
  EnRoute = 'En Route',
  Reached = 'Reached',
}

export enum ReferralStatus {
  Offered = 'offered',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export enum PaymentStatus {
  NotPaid = 'not_paid',
  PartiallyPaid = 'partially_paid',
  FullyPaid = 'fully_paid',
}

export enum BidStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export enum BidStatusId {
  Pending = 1,
  Accepted = 2,
  Rejected = 3,
}

export enum TripTypeKeys {
  OneWay = 'one_way',
  RoundTrip = 'round_trip',
  Hourly = 'hourly',
  Daily = 'daily',
  Shuttle = 'shuttle',
}

export enum TakeRate {
  Default = 10,
}

export enum EldType {
  Trak4 = 1,
  Samsara = 2,
  KeepTruckin = 3,
}

export enum PaymentMethod {
  Card = 'credit_card',
  ACH = 'ach',
  Check = 'check',
  Wire = 'wire',
  Other = 'other',
}

export enum PricingMethod {
  HOURLY_RATE = 'hourly_rate',
  DAILY_RATE = 'daily_rate',
  MILEAGE_RATE = 'mileage',
}

export enum PricingMethodKeys {
  HOURLY_RATE = 'hourly_rate',
  DAILY_RATE = 'daily_rate',
  MILEAGE_RATE = 'mileage',
  DEAD_MILE_RATE = 'deadmile',
  TRANSFER_RATE = 'transfer_rate',
}

export enum PricingMethodLabels {
  HOURLY_RATE = 'Hourly Rate',
  DAILY_RATE = 'Daily Rate',
  MILEAGE_RATE = 'Mileage Rate',
  DEAD_MILE_RATE = 'Dead Mile Rate',
  TRANSFER_RATE = 'Transfer Rate',
}

export enum Days {
  SUNDAY = 'Sunday',
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
}

export enum RecurrenceType {
  DAILY = 'Daily',
  EVERY_WEEKDAY = 'Every Weekday',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  ANNUALLY = 'Annually',
  CUSTOM = 'Custom',
}

export enum TripNoteTypes {
  INTERNAL_NOTE_TYPE = 1,
  TRIP_NOTE_TYPE = 2,
  DRIVER_NOTE_TYPE = 3,
}

export enum TripChargeTypes {
  BASE_FARE = 'base_fare',
  LODGING = 'lodging',
  TOLLS = 'tolls',
  PROCESSING_FEES = 'processing_fees',
  PARKING = 'parking',
  MISC = 'misc',
  GRATUITY = 'gratuity',
  TIME = 'time',
  PERSONNEL = 'personnel',
  EQUIPMENT = 'equipment',
  TRIP_CHANGE = 'trip_change',
  REFUND_ADJUSTMENT = 'refund_adjustment',
  CLEANING_FEES = 'cleaning_fees',
  OVERTIME = 'overtime',
  DISCOUNT = 'discount',
  FUEL = 'fuel',
  WIFI = 'wi-fi',
  MARKUP = 'markup',
}

export enum BaseFareCalculationType {
  HIGHEST = 'Highest',
  CHOOSE = 'Choose',
}

export enum BaseFareCalculationTypeId {
  HIGHEST = 1,
  CHOOSE = 2,
}

export enum BaseFarePricingType {
  DAILY = 'Daily',
  HOURLY = 'Hourly',
  MILEAGE = 'Mileage',
}

export enum MarkupTypes {
  CUSTOMER = 'customer',
  CUSTOMER_ACCOUNT = 'customer_account',
  INDUSTRY = 'industry',
  DATE_RANGE = 'date_range',
  DAYS = 'days',
  EVENT = 'event',
  VEHICLE_TYPE = 'vehicle_type',
}

export enum LineItemChargeTypes {
  FLAT_RATE = 'flat_rate',
  PERCENTAGE = 'percentage',
  PER_PASSENGER = 'per_passenger',
  PER_DRIVER = 'per_driver',
  PER_VEHICLE = 'per_vehicle',
  PER_TOTAL_MILE = 'per_total_mile',
  PER_LIVE_MILE = 'per_live_mile',
  PER_DEAD_MILE = 'per_dead_mile',
  PER_HOUR = 'per_hour',
  PER_DAY = 'per_day',
  PER_QUANTITY = 'per_quantity',
}

export enum LineItemChargeTypeIds {
  FLAT_RATE = 1,
  PERCENTAGE = 2,
  PER_QUANTITY = 11,
}

export enum LineItemSectionTypes {
  BASE_FARE = 'base_fare',
  ITEMIZED_CHARGE = 'itemized_charge',
  SUBTOTAL = 'subtotal',
}

export enum LineItemSectionTypeIds {
  BASE_FARE = 1,
  ITEMIZED_CHARGE = 2,
  SUBTOTAL = 3,
}

export enum QuotePriorityKeys {
  NONE = '',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export enum QuotePriorityLabels {
  NONE = 'None',
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}

export enum MarketRateTypeKeys {
  DAILY_RATE = 'daily_rate',
  HOURLY_RATE = 'hourly_rate',
  MILEAGE_RATE = 'mileage',
  TRANSFER_RATE = 'transfer_rate',
  DEAD_MILE_RATE = 'deadmile',
}

export enum VehicleTypeKeys {
  CHARTER_BUS = 'charter_bus',
  MINI_BUS = 'mini_bus',
  SPRINTER = 'sprinter',
  PARTY_BUS = 'party_bus',
  SEDAN = 'sedan',
  SUV = 'suv',
  LIMOUSINE = 'limousine',
  TROLLEY = 'Trolley',
  SCHOOL_BUS = 'school_bus',
  VAN = 'van',
  MINI_COACH = 'mini_coach',
  UNASSIGNED = 'unassigned',
}

export enum VehicleTypeLabels {
  CHARTER_BUS = 'Charter Bus',
  MINI_BUS = 'Mini Bus',
  SPRINTER = 'Sprinter',
  PARTY_BUS = 'Party Bus',
  SEDAN = 'Sedan',
  SUV = 'SUV',
  LIMOUSINE = 'Limousine',
  TROLLEY = 'Trolley',
  SCHOOL_BUS = 'School Bus',
  VAN = 'Van',
  MINI_COACH = 'Mini Coach',
  UNASSIGNED = 'Unassigned',
}

export enum ContactType {
  Booking = 1,
  Billing = 2,
  Trip = 3,
}

export enum ContactTypeKeys {
  BOOKING = 'booking',
  BILLING = 'billing',
}

export enum MessageContactType {
  EMPLOYEE = 1,
  CUSTOMER = 2,
  BUSIFY = 3,
  UNKNOWN = 4,
}

export enum QuoteStatusTypeId {
  Lead = 1,
  Sent = 2,
  Converted = 3,
  Lost = 4,
  DoNotQuote = 5,
  SoldOut = 6,
}

export enum SourceCategory {
  MANUAL = 1,
  WEBSITE = 2,
  REFERRAL = 3,
}

export enum PricingSelectionId {
  ChooseOverride = 11,
}

export enum QuotePriorityTypeId {
  None = 1,
  Low = 2,
  Medium = 3,
  High = 4,
}

export enum NoteTypes {
  INTERNAL = 1,
  CUSTOMER = 2,
  DRIVER = 3,
}

export enum InstrumentType {
  BANK = 'BANK_ACCOUNT',
  CARD = 'PAYMENT_CARD',
}

export enum BusifyIntegration {
  PleaseNote = `Please note: dispute decisions are made solely by your customers’
        financial institution and are completely out of Busify’s control.`,
  WhileBusify = `While Busify will do everything in our power to assist you during a
        dispute, transaction disputes are between you, your customer, and the
        customers’ financial institution.`,
  PaymentProcessors = `As your payment processor, we have no control over the dispute process
        and only play the role of intermediary between you and your customers’
        financial institution.`,
  LearnMore = `to learn more about best practices when it comes to protecting yourself
        from charge disputes.`,
  SetDefault = 'Set as default payment processor',
  BusifyIntegration = `Busify's integration with QuickBooks Online allows you to automate the
      QuickBooks invoice creation process! Busify’s integration with QuickBooks
      online allows you to automate the QuickBooks invoice creation process!`,
  CreateQuickBooks = `You can create a QuickBooks Invoice for every Busify Reservation, match it
      to the appropriate customer, and we will automatically update your invoice
      based on payments and charges recorded in Busify.`,
  VisitLink = `Visit the link below and log into your QuickBooks Online account to
      connect it with Busify`,
  OneWayIntegration = `Note: this is a one-way integration from Busify to QuickBooks. Any updates
      you make in QuickBooks will not be reflected in Busify.`,
  LogQuickBooks = `Log in to QuickBooks Online`,
  ProcessingIntegration = `Take your operation to the next level with Busify’s fully integrated
      online payment processing!`,
  RapidPayOptions = `Collect payment faster and increase conversion with flexible online
      payment options preferred by customers`,
  PaymentTrackingFeature = ` Stay organized and easily track payments made directly in your Busify
      reservations`,
  InstantApprovalProcess = `Quick and easy online application, most accounts are instantly
      approved`,
}
