
import { Vue, Component, Prop } from 'vue-property-decorator'
import types from '@/store/modules/types'
@Component({})
export default class DriverInfoDriverPayTabFormHeader extends Vue {
  @Prop({ required: false }) selectedVehicleTypeId!: number
  @Prop({ required: false, default: () => [] }) selectedVehicleIds!: number[]

  get vehicleItems(): {
    label: string
    id: number
  }[] {
    const vehicleTypes = types.getVehicleTypes
    return this.selectedVehicleIds
      .map((id) => {
        const vehicleType = vehicleTypes.find((v) => v.id === id)
        return {
          label: vehicleType ? vehicleType.label : 'Default Rates',
          id: id || null,
        }
      })
      .sort((a, b) => {
        if (a.id === null) {
          return -1
        }
        if (b.id === null) {
          return 1
        }
        return a.id - b.id
      })
  }
}
