var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.editor)?_c('div',{class:{ 'h-full': _vm.unsetHeight }},[_c('label',{staticClass:"font-semibold"},[_vm._v(_vm._s(_vm.label))]),_c('CUScroll',{staticClass:"background-white border-1 border-solid border-gray-border-mid border-radius-top-right-12 border-radius-top-left-12 margin-t-1 padding-t-1 w-full",class:{
      'background-bg-gray text-gray-text-light border-radius-bottom-right-12 border-radius-bottom-left-12':
        _vm.readOnly,
      'editor-focus': _vm.editor.isFocused,
      'blue-link': _vm.blueLink,
    },style:(_vm.unsetHeight ? { height: 'calc(100% - 42px)' } : null),attrs:{"id":_vm.label,"gray-bg":_vm.readOnly,"height":_vm.unsetHeight ? '100%' : `${_vm.boxHeight}px`}},[_c('EditorContent',{staticClass:"font-14 h-full",attrs:{"editor":_vm.editor}})],1),(!_vm.readOnly)?_c('div',{staticClass:"background-white border-t-0 border-x-1 border-b-1 border-solid border-gray-border-mid border-radius-bottom-right-12 border-radius-bottom-left-12 padding-t-1 padding-l-1",class:{ 'menu-icons-focus': _vm.editor.isFocused }},_vm._l((_vm.buttons),function(button,buttonIndex){return _c('CUIcon',{key:`${button.id}-${buttonIndex}`,staticClass:"background-none padding-y-1 padding-l-2 padding-r-1 opacity-60 border-radius-4 hover:opacity-90 hover:background-gray-bg",class:{
        'opacity-90 background-gray-bg': _vm.editor.isActive(button.key),
        'padding-x-2': button.key === 'link',
      },attrs:{"id":button.id,"material":"","height":30,"width":30,"active-class":"opacity-90 background-gray-bg'"},on:{"click":button.clickHandler}},[_vm._v(" "+_vm._s(button.icon)+" ")])}),1):_vm._e(),_c('CUModal',{attrs:{"simple-header":"","justify-title":"left","width":"400"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.linkTitle))]},proxy:true},{key:"text",fn:function(){return [_c('CUTextField',{staticClass:"w-full margin-b-4",attrs:{"id":"link-modal-text-input","hide-details":"","label":_vm.link.text && 'Link Text',"type":"text","placeholder":"Link Text"},model:{value:(_vm.link.text),callback:function ($$v) {_vm.$set(_vm.link, "text", $$v)},expression:"link.text"}}),_c('CUTextField',{staticClass:"w-full",attrs:{"id":"link-modal-url-input","hide-details":"","url":"","label":_vm.link.url && 'Link URL',"type":"text","placeholder":"Link URL"},model:{value:(_vm.link.url),callback:function ($$v) {_vm.$set(_vm.link, "url", $$v)},expression:"link.url"}}),_c('v-divider',{staticClass:"margin-t-4 margin-b-2"})]},proxy:true},{key:"actions",fn:function(){return [_c('v-container',{staticClass:"padding-t-0"},[_c('v-row',{staticClass:"justify-end"},[_c('CUButton',{staticClass:"margin-r-4",attrs:{"id":"link-modal-close","outlined":""},on:{"click":_vm.closeLinkModal}},[_vm._v(" Cancel ")]),_c('CUButton',{attrs:{"id":"link-modal-save"},on:{"click":_vm.setLink}},[_vm._v("Apply")])],1)],1)]},proxy:true}],null,false,2650185306),model:{value:(_vm.link.showModal),callback:function ($$v) {_vm.$set(_vm.link, "showModal", $$v)},expression:"link.showModal"}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }