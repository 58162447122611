import { AxiosError } from 'axios'

/**
 *
 * @param message - Axios formats error messages like "code : [message]"
 * @returns The message without the code
 */
export function removeStatusCode(message: string): string {
  // amount to slice from start "xxx : [" -> 7,
  // amount to slice from end "]" -> 1
  return message.slice(7, -1)
}

export function toHTMLAndHyperlink(message: string): string {
  return `<span>${message.replace(
    'contact support',
    '<a href="https://www.busify.com/support" target="_blank">contact support</a>'
  )}</span>`
}

export function parseAxiosError(error: AxiosError, removeCode = true): string {
  let message = 'An unknown error occurred. Please contact support.'
  if (error?.response?.data?.message) {
    message = error.response.data.message
  } else if (error?.response?.data) {
    message = error.response.data
  } else if (error.message) {
    message = error.message
  }

  message = removeCode ? removeStatusCode(message) : message

  return toHTMLAndHyperlink(message)
}

export const parseExistingUserError = (err: string): string => {
  if (!err.includes('email')) {
    return ''
  }

  if (err.includes('admin')) {
    return 'An admin with this email already exists.'
  }

  if (err.includes('user')) {
    return 'A user with this email already exists.'
  }

  if (err.includes('customer')) {
    return 'A customer with this email already exists.'
  }

  if (err.includes('driver')) {
    return 'A driver with this email already exists.'
  }
  return ''
}
