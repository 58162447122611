
import { getWidthOfTextInFont } from '@/utils/string'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({})
export default class CUMultiSwitch extends Vue {
  @Prop({ required: true, default: '' }) readonly value!: string
  @Prop({ required: false, default: 0 }) readonly id!: number
  @Prop({ required: false, default: () => [] }) readonly labels!: string[]
  @Prop({ required: false, default: '#069E8A' }) readonly color!: string
  @Prop({ required: false, default: 'white' }) readonly background!: string
  @Prop({ required: false, default: 14 }) readonly size!: number
  @Prop({ type: Boolean, default: false }) readonly icon!: boolean
  @Prop({ type: Boolean, default: false }) readonly boldSelected!: boolean
  @Prop({ type: Boolean, default: false }) readonly wide!: boolean
  @Prop({ type: Boolean, default: false }) readonly readOnly!: boolean

  labelWidths = null
  offsetWidths = null
  index = 0
  canvas = null

  @Watch('value', { immediate: true })
  handleValueChanged(): void {
    this.index = this.labels.findIndex((el) => el === this.value)
  }

  get selectedWidth(): number {
    return this.labelWidths ? this.labelWidths[this.index] : null
  }
  get offsetWidth(): number {
    return this.offsetWidths ? this.offsetWidths[this.index] : null
  }

  get defaultIconColor(): string {
    return this.readOnly ? '#D0D0D0' : '#808189'
  }

  handleLabelClick(label: string, idx: number): void {
    if (this.readOnly) {
      return
    }
    this.index = idx
    this.$emit('input', label)
  }

  loadWidths(): void {
    this.labelWidths = this.labels.map((el) => {
      const width =
        (this.icon
          ? 39
          : getWidthOfTextInFont(el, '14px', '400', this.canvas)) +
        (this.wide ? 25 : 0)
      const padding = 24
      const border = 2
      return width + padding + border
    })
    let sum = -1
    this.offsetWidths = [0, ...this.labelWidths.map((el) => (sum = sum + el))]
  }

  mounted(): void {
    this.loadWidths()
  }
}
