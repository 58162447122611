
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import UserAccessSettings from '@/components/UserAccessSettings.vue'
import auth from '@/store/modules/auth'
import { Role } from '@/models/dto/User'
import { UserGroupIds } from '@/data/userGroups'

@Component({
  components: {
    UserAccessSettings,
  },
})
export default class CreateUserTypeSelector extends Vue {
  @Prop({ required: false }) selectedUserType!: string
  @Prop({ required: false, default: '100%' }) width!: string
  @Prop({ type: Boolean, default: false }) hideHoverText!: boolean
  @Prop({ type: Boolean, default: false }) creatingUser!: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ required: false, default: null }) roles!: Role[]

  helperText = ''
  selectionHover = false
  showHelperText = false

  userTypes = {
    Admin: 'Admins have access to everything in Busify',
    'Office User': "Office User's access can be customized",
    Driver: 'Drivers only have access to the Busify mobile app',
    'Admin + Driver': 'Admins have access to everything in Busify',
    'Office User + Driver': "Office User + Driver's access can be customized",
  }

  @Watch('selectedUserType', { immediate: true })
  selectedUser(): void {
    this.helperText = this.userTypes[this.selectedUserType]
    this.showHelperText = true
    this.selectionHover = true
  }

  get showAccessSettings(): boolean {
    return (
      this.selectedUserType !== 'Driver' &&
      this.selectedUserType !== 'Admin' &&
      this.selectedUserType !== 'Admin + Driver' &&
      !!this.selectedUserType &&
      auth.getUser.groupId === UserGroupIds.ADMIN
    )
  }

  get userTypesList(): string[] {
    return Object.keys(this.userTypes)
  }

  get dropdownHeight(): string {
    if (this.selectionHover) {
      return '-12px'
    }
    return `${this.userTypesList.length * 48 + 16 || 0}px`
  }

  leaveItem(): void {
    if (this.selectionHover) {
      return
    }
    this.showHelperText = false
    this.selectionHover = true
  }

  handleSelect(e: string): void {
    this.$emit('handleUserTypeChange', e)
  }

  hover(userType: string): void {
    if (!userType) {
      this.showHelperText = false
      return
    }
    this.helperText = this.userTypes[userType]
    this.showHelperText = true
    this.selectionHover = false
  }
}
