var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{style:(_vm.cssVars)},[_c('v-select',_vm._g(_vm._b({ref:"cu-select-ref",staticClass:"cu-select position-relative",class:{
      'v-select--inner-label': _vm.$attrs.label,
      'v-select--multiple': _vm.multiple,
      'v-select--inner-label-centered':
        _vm.$attrs.label && _vm.centeredLabel && !_vm.isSelection,
      'v-select--inner-label-avatar': _vm.$attrs.label && _vm.avatar,
      'border-radius-top-left-none border-radius-top-right-none border-radius-bottom-right-none':
        _vm.tempCheckoutStyle,
      'v-input--gray-label': _vm.borderColor,
      'select-all-placeholder-color': _vm.displaySelectAllInclusive,
      'v-input--widget': _vm.widget,
    },attrs:{"outlined":"","item-text":_vm.itemText,"item-value":_vm.itemValue,"items":_vm.filteredItems,"disabled":_vm.disabled,"multiple":_vm.multiple,"flat":"","item-color":_vm.highlightColor,"value":_vm.value,"menu-props":_vm.selectMenuProps},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.$scopedSlots.tag)?_vm._t("tag"):_vm._e(),_c('CUIcon',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideDropdownArrow),expression:"!hideDropdownArrow"}],attrs:{"aria-label":"Dropdown","color":"gray-icon-light"}},[_vm._v(" keyboard_arrow_down ")]),(_vm.avatar && _vm.avatarRightBorderRadius)?_c('div',{staticClass:"position-absolute top-0 right-n3"},[_c('div',{staticClass:"background-white position-absolute top-0 bottom-0 right-0 w-8 h-44 d-flex align-center justify-center cursor-pointer border-y-1 border-r-1 border-l-0 border-solid",class:{
            'border-red-error': _vm.$attrs['error-messages'] && !_vm.disabled,
            'border-gray-border-mid': !_vm.$attrs['error-messages'] || _vm.disabled,
            [`${_vm.avatarRightBorderRadius}`]: _vm.avatarRightBorderRadius,
          },on:{"click":function($event){$event.stopPropagation();return _vm.handleClickAvatar.apply(null, arguments)}}})]):_vm._e()]},proxy:true},(_vm.avatar)?{key:"prepend-inner",fn:function(){return [_c('div',{staticClass:"w-40 h-44 margin-a-0"}),_c('div',{staticClass:"background-secondary-light position-absolute top-0 bottom-0 left-0 w-44 d-flex align-center justify-center border-y-1 border-l-1 border-r-0 border-solid",class:{
          'background-additional-red-2': _vm.avatar === 'conflict' && !_vm.disabled,
          'background-bg-gray-2':
            _vm.avatar === 'driver' || _vm.avatar === 'vehicle' || _vm.disabled,
          'background-secondary-light': _vm.avatar,
          'border-red-error': _vm.$attrs['error-messages'] && !_vm.disabled,
          'border-gray-border-mid': !_vm.$attrs['error-messages'] || _vm.disabled,
          [`${_vm.avatarBorderRadius}`]: _vm.avatarBorderRadius,
        },on:{"click":function($event){$event.stopPropagation();return _vm.handleClickAvatar.apply(null, arguments)}}},[(_vm.avatar === 'conflict')?_c('CUIcon',{key:"cu-select-avatar-warning",attrs:{"width":"20px","color":_vm.disabled ? 'bg-gray-5' : 'additional-red'}},[_vm._v(" warning ")]):(_vm.avatar === 'driver')?_c('CUIcon',{key:"cu-select-avatar-unassigned-driver",attrs:{"width":"20px","color":"bg-gray-5"}},[_vm._v(" driver ")]):(_vm.avatar === 'driver_confirmed')?_c('CUIcon',{key:"cu-select-avatar-driver-confirmed",attrs:{"width":"20px","color":"secondary"}},[_vm._v(" driver ")]):(_vm.avatarIsIcon)?_c('CUIcon',{attrs:{"color":_vm.avatarIconColor,"width":"21px"}},[_vm._v(" "+_vm._s(_vm.avatar)+" ")]):_c('div',{staticClass:"d-flex align-center justify-center w-28 h-28 rounded-circle",class:{
            'background-secondary': !_vm.disabled,
            'background-bg-gray-5': _vm.disabled,
          }},[_c('p',{staticClass:"text-white font-12 font-bold capitalize"},[_vm._v(" "+_vm._s(_vm.avatar.toUpperCase())+" ")])])],1)]},proxy:true}:null,(_vm.searchable || _vm.displayCreateNew || _vm.displaySelectAll)?{key:"prepend-item",fn:function(){return [(_vm.displayCreateNew)?_c('div',[_c('div',{staticClass:"text-green font-bold d-flex align-center w-full padding-a-3 cursor-pointer",on:{"click":_vm.handleSelectCreateNew}},[_c('CUIcon',{staticClass:"margin-r-1",attrs:{"height":"14","width":"14"}},[_vm._v("add")]),_c('span',{staticClass:"body-2-bold"},[_vm._v(_vm._s(_vm.createNewText))])],1)]):_vm._e(),(_vm.searchable)?_c('div',[_c('CUTextField',{staticClass:"margin-x-2 v-input--36",attrs:{"clearable":"","hide-details":"","border-color":"bg-gray-2","placeholder":"Search","height":36},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('CUIcon',{style:({ marginTop: '-2px', paddingRight: '2px' }),attrs:{"width":"16px","height":"16px","color":"bg-gray-4"}},[_vm._v(" search ")])]},proxy:true}],null,false,311000741),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),(_vm.displaySelectAll && !_vm.search)?_c('div',[_c('div',{staticClass:"v-list-item primary--text v-list-item--link theme--light",class:{
            'v-list-item--active v-list-item--highlighted': _vm.isAllToggled,
          },on:{"click":_vm.toggleAllFilters}},[_c('div',{staticClass:"v-list-item__action margin-l-0",class:{
              'v-list-item__action margin-a-0': _vm.hasItemTag,
              'v-list-item__action margin-r-4': _vm.hasItemTag,
            }},[_c('v-simple-checkbox',{staticClass:"margin-a-0 padding-a-0 w-full",attrs:{"hide-details":"","color":"primary","value":_vm.isAllToggled,"ripple":false},on:{"click":_vm.toggleAllFilters}})],1),_c('span',{staticClass:"text-black font-14"},[_vm._v("All")])])]):_vm._e()]},proxy:true}:null,(_vm.createNewSelected)?{key:"selection",fn:function(){return [_c('div',[_c('div',{staticClass:"text-gray-text-light font-12"},[_vm._v(" "+_vm._s(_vm.$attrs.placeholder)+" ")]),_c('div',{staticClass:"text-secondary"},[_vm._v(_vm._s(_vm.createNewText))])])]},proxy:true}:(_vm.collapseSelection || _vm.displayAllPlaceholder)?{key:"selection",fn:function({ index }){return [(
          (_vm.displayAllPlaceholder || _vm.collapseSelection) &&
          _vm.shouldDisplayAllSelection
        )?[(index === 0)?_c('span',[_vm._v("All")]):_vm._e()]:(_vm.collapseSelection && _vm.collapsedItemCount > 0)?[(index === 0)?[_c('span',{staticClass:"overflow-hidden text-overflow-ellipsis white-space-nowrap",class:{
              'font-weight-bold': _vm.collapseSelectionBolded,
            },style:({
              maxWidth: '110px',
            })},[_vm._v(" "+_vm._s(_vm.selectedItemNames[0])+", ")]),_c('span',{staticClass:"text-gray-text-light white-space-nowrap"},[_vm._v("  +"+_vm._s(_vm.collapsedItemCount)+" ")])]:_vm._e()]:[(index === 0)?_c('span',{staticClass:"overflow-hidden text-overflow-ellipsis white-space-nowrap",class:{
            'font-weight-bold': _vm.collapseSelectionBolded,
          },style:({
            maxWidth: '110px',
          })},[_vm._v(" "+_vm._s(_vm.selectedItemNames.join(', '))+" ")]):_vm._e()]]}}:null,(_vm.recurrence)?{key:"item",fn:function({ item }){return [_c('div',{staticClass:"font-14"},[_vm._v(" "+_vm._s(item.label)+" ")])]}}:(_vm.createNewSelected)?{key:"item",fn:function({ item }){return [(item.value !== 'CREATE_NEW_PLACEHOLDER')?_c('div',[_vm._v(" "+_vm._s(item[`${_vm.itemText}`] || item)+" ")]):_vm._e()]}}:(_vm.hasItemTag)?{key:"item",fn:function({ item }){return [_c('div',{key:item.value,staticClass:"d-flex align-center justify-space-between w-full"},[_c('div',{staticClass:"d-flex align-center justify-start"},[_c('v-list-item-action',{staticClass:"margin-a-0"},[_c('v-checkbox',{attrs:{"ripple":false,"input-value":_vm.value.includes(item.value)}})],1),_c('div',{staticClass:"margin-l-4"},[_vm._v(_vm._s(item.text || item))])],1),(item.tag)?_c('CUTag',{staticClass:"margin-l-2",attrs:{"tag":item.tag,"font-size":12,"dense":"","border":item.tagBorder,"background":item.tagBackground}}):_vm._e()],1)]}}:null,_vm._l((_vm.$scopedSlots),function(index,name){return {key:name,fn:function(data){return [_vm._t(name,null,null,data)]}}})],null,true)},'v-select',_vm.$attrs,false),_vm.listeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }