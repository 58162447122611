import { Address, ApiResult, SupportedVehicleType } from '.'
import { Company } from './Company'
import { CustomerAccount } from './CustomerAccount'
import { Note } from './Note'

export interface UserDetail {
  active: boolean
  address?: Address
  userId?: number
  customerAccount?: CustomerAccount
  customerAccountName?: string
  customerAccountId?: number
  firstName: string
  lastName: string
  email: string
  emailSenderName?: string
  driverActivated?: boolean
  phone: string
  phoneExtension?: string
  phoneCountryKey?: string
  groupId: number
  groupName?: string
  companyId: number
  companyName: string
  userPhotoDTOs: UserPhoto[]
  userRoleNames: string[]
  company?: Company
  linkedAccountProfileId?: number
  isRA?: boolean
  isSDR?: boolean
  locale: string
  treatAsDriver: boolean
  timeZone?: string
}

export interface UserRequest {
  firstName: string
  lastName: string
  email: string
  groupName?: string
  companyName: string
  groupId: number
  companyId: number
  locale: string
  treatAsDriver: boolean
  phone: string
  licenseNumber: string
  drugTestNumber: string
  drugTestExpiration?: string
  licenseState: string
  licenseExpirationDay: number
  licenseExpirationMonth: number
  licenseExpirationYear: number
  address?: Address
  driverSupportedVehicles: SupportedVehicleType[]
  driverNotes: Partial<Note>[]
  userRoleNames: string[]
}

// eslint-disable-next-line no-redeclare
export class UserDetail implements UserDetail {
  constructor(user?: UserDetail) {
    Object.assign(this, user)
  }
}

// eslint-disable-next-line no-redeclare
export class UserRequest implements UserRequest {
  constructor(user?: UserRequest) {
    Object.assign(this, user)
  }
}

export interface UserPhoto {
  userPhotoId: number
  userId: number
  imagePath: string
  primaryImage: boolean
  active: boolean
}

export interface Group {
  groupId: number
  label: string
  key: string
}

export interface UserProfile {
  groupId: number
  roles: Role[]
}

export interface UserProfileResult extends ApiResult {
  userProfile: UserProfile
}

export interface Role {
  roleId: number
  roleName: string
  permissions: Permission[]
}

export interface Permission {
  permissionId: number
  permissionName: string
}

export interface UserAuthPayload {
  email: string
  password: string
}

export interface UserSetPassword {
  userId: number
  currentPassword?: string
  newPassword: string
}
export interface UserAuthResult extends ApiResult {
  token: string
  user: UserDetail
}

export interface UserResult extends ApiResult {
  user: UserDetail
}

export interface UsersResult extends ApiResult {
  users: UserDetail[]
}
