/* eslint-disable prettier/prettier */
export const countries = [
  { key: null, name: 'Not Specified', code: '', flag: null },
  { key: 'afghanistan', name: 'Afghanistan', code: '+93', flag: '🇦🇫' },
  { key: 'aland_islands', name: 'Aland Islands', code: '+358', flag: '🇦🇽' },
  { key: 'albania', name: 'Albania', code: '+355', flag: '🇦🇱' },
  { key: 'algeria', name: 'Algeria', code: '+213', flag: '🇩🇿' },
  { key: 'american_samoa', name: 'American Samoa', code: '+1684', flag: '🇦🇸' },
  { key: 'andorra', name: 'Andorra', code: '+376', flag: '🇦🇩' },
  { key: 'angola', name: 'Angola', code: '+244', flag: '🇦🇴' },
  { key: 'anguilla', name: 'Anguilla', code: '+1264', flag: '🇦🇮' },
  { key: 'antarctica', name: 'Antarctica', code: '+672', flag: '🇦🇶' },
  { key: 'antigua_and_barbuda', name: 'Antigua and Barbuda', code: '+1268', flag: '🇦🇬' },
  { key: 'argentina', name: 'Argentina', code: '+54', flag: '🇦🇷' },
  { key: 'armenia', name: 'Armenia', code: '+374', flag: '🇦🇲' },
  { key: 'aruba', name: 'Aruba', code: '+297', flag: '🇦🇼' },
  { key: 'australia', name: 'Australia', code: '+61', flag: '🇦🇺' },
  { key: 'austria', name: 'Austria', code: '+43', flag: '🇦🇹' },
  { key: 'azerbaijan', name: 'Azerbaijan', code: '+994', flag: '🇦🇿' },
  { key: 'bahamas', name: 'Bahamas', code: '+1242', flag: '🇧🇸' },
  { key: 'bahrain', name: 'Bahrain', code: '+973', flag: '🇧🇭' },
  { key: 'bangladesh', name: 'Bangladesh', code: '+880', flag: '🇧🇩' },
  { key: 'barbados', name: 'Barbados', code: '+1246', flag: '🇧🇧' },
  { key: 'belarus', name: 'Belarus', code: '+375', flag: '🇧🇾' },
  { key: 'belgium', name: 'Belgium', code: '+32', flag: '🇧🇪' },
  { key: 'belize', name: 'Belize', code: '+501', flag: '🇧🇿' },
  { key: 'benin', name: 'Benin', code: '+229', flag: '🇧🇯' },
  { key: 'bermuda', name: 'Bermuda', code: '+1441', flag: '🇧🇲' },
  { key: 'bhutan', name: 'Bhutan', code: '+975', flag: '🇧🇹' },
  { key: 'bolivia', name: 'Bolivia', code: '+591', flag: '🇧🇴' },
  { key: 'bosnia_and_herzegovina', name: 'Bosnia and Herzegovina', code: '+387', flag: '🇧🇦' },
  { key: 'botswana', name: 'Botswana', code: '+267', flag: '🇧🇼' },
  { key: 'brazil', name: 'Brazil', code: '+55', flag: '🇧🇷', mask: ['## #### ####', '## ##### ####'] },
  { key: 'indian_ocean', name: 'British Indian Ocean Territory', code: '+246', flag: '🇮🇴' },
  { key: 'brunei_darussalam', name: 'Brunei Darussalam', code: '+673', flag: '🇧🇳' },
  { key: 'bulgaria', name: 'Bulgaria', code: '+359', flag: '🇧🇬' },
  { key: 'burkina_faso', name: 'Burkina Faso', code: '+226', flag: '🇧🇫' },
  { key: 'burundi', name: 'Burundi', code: '+257', flag: '🇧🇮' },
  { key: 'cambodia', name: 'Cambodia', code: '+855', flag: '🇰🇭' },
  { key: 'cameroon', name: 'Cameroon', code: '+237', flag: '🇨🇲' },
  { key: 'canada', name: 'Canada', code: '+1', flag: '🇨🇦', mask: ['###-###-####']  },
  { key: 'cape_verde', name: 'Cape Verde', code: '+238', flag: '🇨🇻' },
  { key: 'cayman_islands', name: 'Cayman Islands', code: '+ 345', flag: '🇰🇾' },
  { key: 'central_african_republic', name: 'Central African Republic', code: '+236', flag: '🇨🇫' },
  { key: 'chad', name: 'Chad', code: '+235', flag: '🇹🇩' },
  { key: 'chile', name: 'Chile', code: '+56', flag: '🇨🇱' },
  { key: 'china', name: 'China', code: '+86', flag: '🇨🇳', mask: ['### ### ####', '### #### ####'] },
  { key: 'christmas_island', name: 'Christmas Island', code: '+61', flag: '🇨🇽' },
  { key: 'cocos_islands', name: 'Cocos (Keeling) Islands', code: '+61', flag: '🇨🇨' },
  { key: 'colombia', name: 'Colombia', code: '+57', flag: '🇨🇴' },
  { key: 'comoros', name: 'Comoros', code: '+269', flag: '🇰🇲' },
  { key: 'congo', name: 'Congo', code: '+242', flag: '🇨🇬' },
  { key: 'cook_islands', name: 'Cook Islands', code: '+682', flag: '🇨🇰' },
  { key: 'costa_rica', name: 'Costa Rica', code: '+506', flag: '🇨🇷' },
  { key: 'code_divoire', name: "Cote d'Ivoire", code: '+225', flag: '🇨🇮' },
  { key: 'croatia', name: 'Croatia', code: '+385', flag: '🇭🇷' },
  { key: 'cuba', name: 'Cuba', code: '+53', flag: '🇨🇺' },
  { key: 'cyprus', name: 'Cyprus', code: '+357', flag: '🇨🇾' },
  { key: 'czech_republic', name: 'Czech Republic', code: '+420', flag: '🇨🇿', mask: ['### ### ###'] },
  { key: 'denmark', name: 'Denmark', code: '+45', flag: '🇩🇰' },
  { key: 'djibouti', name: 'Djibouti', code: '+253', flag: '🇩🇯' },
  { key: 'dominica', name: 'Dominica', code: '+1767', flag: '🇩🇲' },
  { key: 'dominican_republic', name: 'Dominican Republic', code: '+1849', flag: '🇩🇴' },
  { key: 'ecuador', name: 'Ecuador', code: '+593', flag: '🇪🇨' },
  { key: 'egypt', name: 'Egypt', code: '+20', flag: '🇪🇬' },
  { key: 'el_salvador', name: 'El Salvador', code: '+503', flag: '🇸🇻' },
  { key: 'equatorial_guinea', name: 'Equatorial Guinea', code: '+240', flag: '🇬🇶' },
  { key: 'eritrea', name: 'Eritrea', code: '+291', flag: '🇪🇷' },
  { key: 'estonia', name: 'Estonia', code: '+372', flag: '🇪🇪' },
  { key: 'ethiopia', name: 'Ethiopia', code: '+251', flag: '🇪🇹' },
  { key: 'falkland_islands', name: 'Falkland Islands (Malvinas)', code: '+500', flag: '🇫🇰' },
  { key: 'faroe_islands', name: 'Faroe Islands', code: '+298', flag: '🇫🇴' },
  { key: 'fiji', name: 'Fiji', code: '+679', flag: '🇫🇯' },
  { key: 'finland', name: 'Finland', code: '+358', flag: '🇫🇮' },
  { key: 'france', name: 'France', code: '+33', flag: '🇫🇷' },
  { key: 'french_guiana', name: 'French Guiana', code: '+594', flag: '🇬🇫' },
  { key: 'french_polynesia', name: 'French Polynesia', code: '+689', flag: '🇵🇫' },
  { key: 'gabon', name: 'Gabon', code: '+241', flag: '🇬🇦' },
  { key: 'gambia', name: 'Gambia', code: '+220', flag: '🇬🇲' },
  { key: 'georgia', name: 'Georgia', code: '+995', flag: '🇬🇪' },
  { key: 'germany', name: 'Germany', code: '+49', flag: '🇩🇪' },
  { key: 'ghana', name: 'Ghana', code: '+233', flag: '🇬🇭' },
  { key: 'gibraltar', name: 'Gibraltar', code: '+350', flag: '🇬🇮' },
  { key: 'greece', name: 'Greece', code: '+30', flag: '🇬🇷' },
  { key: 'greenland', name: 'Greenland', code: '+299', flag: '🇬🇱' },
  { key: 'grenada', name: 'Grenada', code: '+1473', flag: '🇬🇩' },
  { key: 'guadeloupe', name: 'Guadeloupe', code: '+590', flag: '🇬🇵' },
  { key: 'guam', name: 'Guam', code: '+1671', flag: '🇬🇺' },
  { key: 'guatemala', name: 'Guatemala', code: '+502', flag: '🇬🇹' },
  { key: 'guernsey', name: 'Guernsey', code: '+44', flag: '🇬🇬' },
  { key: 'guinea', name: 'Guinea', code: '+224', flag: '🇬🇳' },
  { key: 'guinea-bissau', name: 'Guinea-Bissau', code: '+245', flag: '🇬🇼' },
  { key: 'guyana', name: 'Guyana', code: '+595', flag: '🇬🇾' },
  { key: 'haiti', name: 'Haiti', code: '+509', flag: '🇭🇹' },
  { key: 'holy_see', name: 'Holy See', code: '+379', flag: '🇻🇦' },
  { key: 'honduras', name: 'Honduras', code: '+504', flag: '🇭🇳' },
  { key: 'hong_kong', name: 'Hong Kong', code: '+852', flag: '🇭🇰' },
  { key: 'hungary', name: 'Hungary', code: '+36', flag: '🇭🇺' },
  { key: 'iceland', name: 'Iceland', code: '+354', flag: '🇮🇸' },
  { key: 'india', name: 'India', code: '+91', flag: '🇮🇳' },
  { key: 'indonesia', name: 'Indonesia', code: '+62', flag: '🇮🇩' },
  { key: 'iran', name: 'Iran', code: '+98', flag: '🇮🇷' },
  { key: 'iraq', name: 'Iraq', code: '+964', flag: '🇮🇶' },
  { key: 'ireland', name: 'Ireland', code: '+353', flag: '🇮🇪' },
  { key: 'isle_of_man', name: 'Isle of Man', code: '+44', flag: '🇮🇲' },
  { key: 'israel', name: 'Israel', code: '+972', flag: '🇮🇱' },
  { key: 'italy', name: 'Italy', code: '+39', flag: '🇮🇹' },
  { key: 'jamaica', name: 'Jamaica', code: '+1876', flag: '🇯🇲' },
  { key: 'japan', name: 'Japan', code: '+81', flag: '🇯🇵' },
  { key: 'jersey', name: 'Jersey', code: '+44', flag: '🇯🇪' },
  { key: 'jordan', name: 'Jordan', code: '+962', flag: '🇯🇴' },
  { key: 'kazakhstan', name: 'Kazakhstan', code: '+77', flag: '🇰🇿' },
  { key: 'kenya', name: 'Kenya', code: '+254', flag: '🇰🇪' },
  { key: 'kiribati', name: 'Kiribati', code: '+686', flag: '🇰🇮' },
  { key: 'republic_of_korea', name: "Korea, Democratic People's Republic of Korea", code: '+850', flag: '🇰🇵' },
  { key: 'republic_of_south_korea', name: 'Korea, Republic of South Korea', code: '+82', flag: '🇰🇷' },
  { key: 'kuwait', name: 'Kuwait', code: '+965', flag: '🇰🇼' },
  { key: 'kyrgyzstan', name: 'Kyrgyzstan', code: '+996', flag: '🇰🇬' },
  { key: 'laos', name: 'Laos', code: '+856', flag: '🇱🇦' },
  { key: 'latvia', name: 'Latvia', code: '+371', flag: '🇱🇻' },
  { key: 'lebanon', name: 'Lebanon', code: '+961', flag: '🇱🇧' },
  { key: 'lesotho', name: 'Lesotho', code: '+266', flag: '🇱🇸' },
  { key: 'liberia', name: 'Liberia', code: '+231', flag: '🇱🇷' },
  { key: 'libyan_arab_jamahiriya', name: 'Libyan Arab Jamahiriya', code: '+218', flag: '🇱🇾' },
  { key: 'liechtenstein', name: 'Liechtenstein', code: '+423', flag: '🇱🇮' },
  { key: 'lithuania', name: 'Lithuania', code: '+370', flag: '🇱🇹' },
  { key: 'luxembourg', name: 'Luxembourg', code: '+352', flag: '🇱🇺' },
  { key: 'macao', name: 'Macao', code: '+853', flag: '🇲🇴' },
  { key: 'macedonia', name: 'Macedonia', code: '+389', flag: '🇲🇰' },
  { key: 'macedonia', name: 'Macedonia', code: '+261', flag: '🇲🇬' },
  { key: 'malawi', name: 'Malawi', code: '+265', flag: '🇲🇼' },
  { key: 'malaysia', name: 'Malaysia', code: '+60', flag: '🇲🇾' },
  { key: 'maldives', name: 'Maldives', code: '+960', flag: '🇲🇻' },
  { key: 'mali', name: 'Mali', code: '+223', flag: '🇲🇱' },
  { key: 'malta', name: 'Malta', code: '+356', flag: '🇲🇹' },
  { key: 'marshall_islands', name: 'Marshall Islands', code: '+692', flag: '🇲🇭' },
  { key: 'martinique', name: 'Martinique', code: '+596', flag: '🇲🇶' },
  { key: 'mauritania', name: 'Mauritania', code: '+222', flag: '🇲🇷' },
  { key: 'mauritius', name: 'Mauritius', code: '+230' },
  { key: 'mayotte', name: 'Mayotte', code: '+262', flag: '🇾🇹' },
  { key: 'mexico', name: 'Mexico', code: '+52', flag: '🇲🇽' },
  { key: 'micronesia', name: 'Micronesia', code: '+691', flag: '🇫🇲' },
  { key: 'moldova', name: 'Moldova', code: '+373', flag: '🇲🇩' },
  { key: 'monaco', name: 'Monaco', code: '+377', flag: '🇲🇨' },
  { key: 'mongolia', name: 'Mongolia', code: '+976', flag: '🇲🇳' },
  { key: 'montenegro', name: 'Montenegro', code: '+382', flag: '🇲🇪' },
  { key: 'montserrat', name: 'Montserrat', code: '+1664', flag: '🇲🇸' },
  { key: 'morocco', name: 'Morocco', code: '+212', flag: '🇲🇦' },
  { key: 'mozambique', name: 'Mozambique', code: '+258', flag: '🇲🇿' },
  { key: 'myanmar', name: 'Myanmar', code: '+95', flag: '🇲🇲' },
  { key: 'namibia', name: 'Namibia', code: '+264', flag: '🇳🇦' },
  { key: 'nauru', name: 'Nauru', code: '+674', flag: '🇳🇷' },
  { key: 'nepal', name: 'Nepal', code: '+977', flag: '🇳🇵' },
  { key: 'netherlands', name: 'Netherlands', code: '+31', flag: '🇳🇱' },
  { key: 'netherlands_antilles', name: 'Netherlands Antilles', code: '+599', flag: '🇳🇱' },
  { key: 'new_caledonia', name: 'New Caledonia', code: '+687', flag: '🇳🇨' },
  { key: 'new_zealand', name: 'New Zealand', code: '+64', flag: '🇳🇿' },
  { key: 'nicaragua', name: 'Nicaragua', code: '+505', flag: '🇳🇮' },
  { key: 'niger', name: 'Niger', code: '+227', flag: '🇳🇪' },
  { key: 'nigeria', name: 'Nigeria', code: '+234', flag: '🇳🇬' },
  { key: 'niue', name: 'Niue', code: '+683', flag: '🇳🇺' },
  { key: 'norfork_island', name: 'Norfolk Island', code: '+672', flag: '🇳🇫' },
  { key: 'northern_mariana_islands', name: 'Northern Mariana Islands', code: '+1670', flag: '🏳' },
  { key: 'norway', name: 'Norway', code: '+47', flag: '🇳🇴' },
  { key: 'oman', name: 'Oman', code: '+968', flag: '🇴🇲' },
  { key: 'pakistan', name: 'Pakistan', code: '+92', flag: '🇵🇰' },
  { key: 'palau', name: 'Palau', code: '+680', flag: '🇵🇼' },
  { key: 'palestinian_territory', name: 'Palestinian Territory, Occupied', code: '+970', flag: '🇵🇸' },
  { key: 'panama', name: 'Panama', code: '+507', flag: '🇵🇦' },
  { key: 'papua_new_guinea', name: 'Papua New Guinea', code: '+675', flag: '🇵🇬' },
  { key: 'paraguay', name: 'Paraguay', code: '+595', flag: '🇵🇾' },
  { key: 'peru', name: 'Peru', code: '+51', flag: '🇵🇪' },
  { key: 'philippines', name: 'Philippines', code: '+63', flag: '🇵🇭' },
  { key: 'pitcairn', name: 'Pitcairn', code: '+872', flag: '🇵🇳' },
  { key: 'poland', name: 'Poland', code: '+48', flag: '🇵🇱' },
  { key: 'portugal', name: 'Portugal', code: '+351', flag: '🇵🇹' },
  { key: 'puerto_rico', name: 'Puerto Rico', code: '+1939', flag: '🇵🇷' },
  { key: 'qatar', name: 'Qatar', code: '+974', flag: '🇶🇦' },
  { key: 'romania', name: 'Romania', code: '+40', flag: '🇷🇴' },
  { key: 'russia', name: 'Russia', code: '+7', flag: '🇷🇺' },
  { key: 'rwanda', name: 'Rwanda', code: '+250', flag: '🇷🇼' },
  { key: 'reunion', name: 'Reunion', code: '+262', flag: '🇷🇪' },
  { key: 'saint_barthelemy', name: 'Saint Barthelemy', code: '+590', flag: '🇧🇱' },
  { key: 'saint_helena', name: 'Saint Helena, Ascension and Tristan Da Cunha', code: '+290', flag: '🇸🇭' },
  { key: 'saint_kitts_and_nevis', name: 'Saint Kitts and Nevis', code: '+1869', flag: '🇰🇳' },
  { key: 'saint_lucia', name: 'Saint Lucia', code: '+1758', flag: '🇱🇨' },
  { key: 'saint_martin', name: 'Saint Martin', code: '+590', flag: '🇲🇫' },
  { key: 'saint_pierre_and_miquelon', name: 'Saint Pierre and Miquelon', code: '+508', flag: '🇵🇲' },
  { key: 'saint_vincent', name: 'Saint Vincent and the Grenadines', code: '+1784', flag: '🇻🇨' },
  { key: 'samoa', name: 'Samoa', code: '+685', flag: '🇼🇸' },
  { key: 'san_marino', name: 'San Marino', code: '+378', flag: '🇸🇲' },
  { key: 'sao_tome_and_principe', name: 'Sao Tome and Principe', code: '+239', flag: '🇸🇹' },
  { key: 'saudi_arabia', name: 'Saudi Arabia', code: '+966', flag: '🇸🇩' },
  { key: 'senegal', name: 'Senegal', code: '+221', flag: '🇸🇳' },
  { key: 'serbia', name: 'Serbia', code: '+381', flag: '🇷🇸' },
  { key: 'seychelles', name: 'Seychelles', code: '+248', flag: '🇸🇨' },
  { key: 'sierra_leone', name: 'Sierra Leone', code: '+232', flag: '🇸🇱' },
  { key: 'singapore', name: 'Singapore', code: '+65', flag: '🇸🇬' },
  { key: 'slovakia', name: 'Slovakia', code: '+421', flag: '🇸🇰' },
  { key: 'slovenia', name: 'Slovenia', code: '+386', flag: '🇸🇮' },
  { key: 'solomon_islands', name: 'Solomon Islands', code: '+677', flag: '🇸🇧' },
  { key: 'somalia', name: 'Somalia', code: '+252', flag: '🇸🇴' },
  { key: 'south_africa', name: 'South Africa', code: '+27', flag: '🇿🇦' },
  { key: 'south_sudan', name: 'South Sudan', code: '+211', flag: '🇸🇸' },
  { key: 'south_georgia', name: 'South Georgia and the South Sandwich Islands', code: '+500', flag: '🇬🇸' },
  { key: 'spain', name: 'Spain', code: '+34', flag: '🇪🇸' },
  { key: 'sri_lanka', name: 'Sri Lanka', code: '+94', flag: '🇱🇰' },
  { key: 'sudan', name: 'Sudan', code: '+249', flag: '🇸🇩' },
  { key: 'suriname', name: 'Suriname', code: '+597', flag: '🇸🇷' },
  { key: 'svalbard_and_jan_mayen', name: 'Svalbard and Jan Mayen', code: '+47', flag: '🇸🇯' },
  { key: 'swaziland', name: 'Swaziland', code: '+268', flag: '🇸🇿' },
  { key: 'sweden', name: 'Sweden', code: '+46', flag: '🇸🇪' },
  { key: 'switzerland', name: 'Switzerland', code: '+41', flag: '🇨🇭' },
  { key: 'syrian_arab_republic', name: 'Syrian Arab Republic', code: '+963', flag: '🇸🇾' },
  { key: 'taiwan', name: 'Taiwan', code: '+886', flag: '🇹🇼' },
  { key: 'tajikistan', name: 'Tajikistan', code: '+992', flag: '🇹🇯' },
  { key: 'tanzania', name: 'Tanzania', code: '+255', flag: '🇹🇿' },
  { key: 'thailand', name: 'Thailand', code: '+66', flag: '🇹🇭' },
  { key: 'republic_of_the_congo', name: 'The Democratic Republic of the Congo', code: '+243', flag: '🇨🇩' },
  { key: 'timor-leste', name: 'Timor-Leste', code: '+670', flag: '🇹🇱' },
  { key: 'togo', name: 'Togo', code: '+228', flag: '🇹🇬' },
  { key: 'tokelau', name: 'Tokelau', code: '+690', flag: '🇹🇰' },
  { key: 'tonga', name: 'Tonga', code: '+676', flag: '🇹🇴' },
  { key: 'trinidad_and_tobago', name: 'Trinidad and Tobago', code: '+1868', flag: '🇹🇹' },
  { key: 'tunisia', name: 'Tunisia', code: '+216', flag: '🇹🇳' },
  { key: 'turkey', name: 'Turkey', code: '+90', flag: '🇹🇷' },
  { key: 'turkmenistan', name: 'Turkmenistan', code: '+993', flag: '🇹🇲' },
  { key: 'turks_and_caicos_islands', name: 'Turks and Caicos Islands', code: '+1649', flag: '🇹🇨' },
  { key: 'tuvalu', name: 'Tuvalu', code: '+688', flag: '🇹🇻' },
  { key: 'uganda', name: 'Uganda', code: '+256', flag: '🇺🇬' },
  { key: 'ukraine', name: 'Ukraine', code: '+380', flag: '🇺🇦' },
  { key: 'united_arab_emirates', name: 'United Arab Emirates', code: '+971', flag: '🇦🇪' },
  { key: 'united_kingdom', name: 'United Kingdom', code: '+44', flag: '🇬🇧' },
  { key: 'united_states', name: 'United States', code: '+1', flag: '🇺🇸', mask: ['(###) ###-####'] },
  { key: 'uruguay', name: 'Uruguay', code: '+598', flag: '🇺🇾' },
  { key: 'uzbekistan', name: 'Uzbekistan', code: '+998', flag: '🇺🇿' },
  { key: 'vanuatu', name: 'Vanuatu', code: '+678', flag: '🇻🇺' },
  { key: 'venezuela', name: 'Venezuela', code: '+58', flag: '🇻🇪' },
  { key: 'vietnam', name: 'Vietnam', code: '+84', flag: '🇻🇳' },
  { key: 'british_virgin_islands', name: 'Virgin Islands, British', code: '+1284', flag: '🇻🇬' },
  { key: 'us_virgin_islands', name: 'Virgin Islands, U.S.', code: '+1340', flag: '🇻🇮' },
  { key: 'wallis_and_futuna', name: 'Wallis and Futuna', code: '+681', flag: '🇼🇫' },
  { key: 'yemen', name: 'Yemen', code: '+967', flag: '🇾🇪' },
  { key: 'zambia', name: 'Zambia', code: '+260', flag: '🇿🇲' },
  { key: 'zimbabwe', name: 'Zimbabwe', code: '+263', flag: '🇿🇼' },
]

export const defaultMask = '################'
