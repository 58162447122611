import {
  TimelineScaleOptions,
  AssignmentTotals,
  AssignmentsRowBlock,
  AssignmentsVehicleBlock,
  AssignmentsDriverBlock,
} from '@/models/dto/AssignmentsGrid'
import { DateInterval } from '@/models/dto/Dispatch'
import { doesBlockOverlapDates } from '@/utils/dispatch'
import {
  AssignedDriver,
  AssignedVehicle,
  RequiredVehicleType,
  Reservation,
  ReservationDetail,
  RowAssignment,
  RowDriver,
  Trip,
  Vehicle,
  VehicleAssignment,
  VehicleTimeOff,
  VehicleType,
} from '@/models/dto'
import assignment from '@/store/modules/assignment'
import typeClient from '@/services/type'
import vehicle from '@/services/vehicle'
import dayjs from 'dayjs'
import { Driver, DriverAssignmentStatus } from '@/models/dto/Driver'
import assignmentClient from '@/services/assignments'
import reservationClient from '@/services/reservation'
import { v4 } from 'uuid'
import { filter } from './filter'
import tripAssignmentClient from '@/services/tripAssignment'
import deepClone from './deepClone'
import { formatFullName, pluralize } from './string'
import auth from '@/store/modules/auth'
import { buildFirstAndLastStops } from './assignments'
import { EventBus } from './eventBus'
import { AxiosResponse } from 'axios'
import tripClient from '@/services/trip'
import reservationState from '@/store/modules/reservation'
import { collapseWithCount } from './array'
import availability from '@/services/availability'
import driver from '@/services/driver'

const DRIVER_CONTACT_TYPE_ID = 4
const DRIVER_TRIP_ASSIGNMENT_EMAIL_NOTIFICATION_TYPE = 3
const DRIVER_TRIP_ASSIGNMENT_APP_NOTIFICATION_TYPE = 4
const VEHICLE_TYPE_LABELS = {
  charter_bus: 'Charter Bus',
  mini_bus: 'Mini Bus',
  sprinter: 'Sprinter',
  party_bus: 'Party Bus',
  sedan: 'Sedan',
  suv: 'SUV',
  limousine: 'Limousine',
  trolley: 'Trolley',
  school_bus: 'School Bus',
  van: 'Van',
  mini_coach: 'Mini Coach',
  unassigned: 'Unassigned',
}

export const getAssignmentBlocks = async (): Promise<
  AssignmentsVehicleBlock[]
> => {
  const interval = getDateRangeForData()
  const [reservations, vehicleData] = await Promise.all([
    getFormattedReservations(interval),
    getVehicleData(),
    getAllDrivers(),
  ])
  assignment.setLoadedReservations(reservations)

  const { vehicles, vehicleTypes } = vehicleData
  const blocks = getVehicleAndVehicleTypeBlocks(
    vehicleTypes,
    vehicles,
    reservations,
    assignment.vehicleTimeoffs
  )

  return blocks
}

export const getFormattedReservations = async (
  dispatchRequest: DateInterval
): Promise<any[]> => {
  if (
    !dispatchRequest?.endDatetime ||
    !dispatchRequest.startDatetime ||
    !dayjs(dispatchRequest.endDatetime).isValid() ||
    !dayjs(dispatchRequest.startDatetime).isValid()
  ) {
    return []
  }

  const { data: availabilityData } = await availability.getPanelData(
    `${dispatchRequest.startDatetime}T00:00:00`,
    `${dispatchRequest.endDatetime}T23:59:59`
  )

  const reservations = Object.entries(availabilityData.reservations)
    .flatMap(([vehicleType, vehicleReservations]) =>
      // filter duplicates for reservations with multiple assignments
      Array.from(new Set(vehicleReservations.map((v) => JSON.stringify(v))))
        .map((v) => JSON.parse(v))
        .map((reservation) => ({
          ...reservation,
          vehicleType: {
            key: vehicleType,
            label: VEHICLE_TYPE_LABELS[vehicleType],
          },
          vehicleAssignments: reservation.assignments,
          startDatetime: reservation.pickupDate,
          endDatetime: reservation.dropoffDate,
        }))
    )
    .reduce((acc, reservation) => {
      const { reservationId, vehicleAssignments } = reservation
      if (!acc[reservationId]) {
        acc[reservationId] = { ...reservation, vehicleAssignments: [] }
      }
      acc[reservationId].vehicleAssignments.push(...vehicleAssignments)
      return acc
    }, {})

  return Object.values(reservations)
    .map(formatReservation)
    .sort((a, b) => (dayjs(a.startDatetime).isAfter(b.startDatetime) ? 1 : -1))
}

const formatStartToEnd = (s: string, e: string, sTz?: string, eTz?: string) => {
  let startDatetime = sTz ? dayjs(s).tz(sTz) : dayjs(s)
  let endDatetime = eTz ? dayjs(e).tz(eTz) : dayjs(e)
  if (startDatetime.isAfter(endDatetime)) {
    ;[startDatetime, endDatetime] = [endDatetime, startDatetime]
  }
  return [startDatetime, endDatetime]
}

export const filterReservationsByScale = (reservations: any[]): any[] => {
  let days = 0
  switch (assignment.getScale) {
    case TimelineScaleOptions.HALFDAY:
      days = 2
      break
    case TimelineScaleOptions.DAY:
      days = 4
      break
    case TimelineScaleOptions.HALFWEEK:
      days = 12
      break
    default:
      days = 24
  }

  return deepClone(reservations).filter(
    (res) =>
      dayjs(res.endDatetime).isBetween(
        assignment.getCurrentDate,
        assignment.getCurrentDate.add(days, 'days')
      ) ||
      dayjs(res.startDatetime).isBetween(
        assignment.getCurrentDate,
        assignment.getCurrentDate.add(days, 'days')
      ) ||
      (dayjs(res.startDatetime).isBefore(assignment.getCurrentDate) &&
        dayjs(res.endDatetime).isAfter(
          assignment.getCurrentDate.add(days, 'days')
        ))
  )
}

export const formatReservation = (r: any): any => {
  if (!r?.startDatetime || !r?.endDatetime) {
    return r
  }

  const [startDatetime, endDatetime] = formatStartToEnd(
    r.startDatetime,
    r.endDatetime,
    r.startTimeZone,
    r.endTimeZone
  )
  const coords = getStartAndEndOffsetFromDates(
    startDatetime.format(),
    endDatetime.format()
  )

  return {
    ...r,
    startDatetime: startDatetime.format(),
    endDatetime: endDatetime.format(),
    startDate: startDatetime.format('YYYY-MM-DD HH:mm:ss'),
    endDate: endDatetime.format('YYYY-MM-DD HH:mm:ss'),
    start: Math.max(coords[0], 0),
    end: Math.max(coords[1], 0),
  }
}

export const getLocalFormattedReservations = (): any[] => {
  const reservations = assignment.loadedReservations.map((r: any) => {
    const coords = getStartAndEndOffsetFromDates(r.startDatetime, r.endDatetime)

    return {
      ...r,
      start: Math.max(coords[0], 0),
      end: Math.max(coords[1], 0),
    }
  })

  return reservations
}

export const getDateRangeForData = (
  date = assignment.getCurrentDate
): DateInterval => {
  return {
    startDatetime: date.add(-1, 'month').format('YYYY-MM-DD'),
    endDatetime: date.add(24, 'day').format('YYYY-MM-DD'),
  }
}

export const getVehicleAndVehicleTypeBlocks = (
  vehicleTypes: VehicleType[],
  vehicles: Vehicle[],
  reservations: any[],
  timeoff: VehicleTimeOff[] = []
): AssignmentsVehicleBlock[] => {
  const filteredReservations = filterReservationsByScale(reservations)

  let days = 0
  switch (assignment.getScale) {
    case TimelineScaleOptions.HALFDAY:
      days = 2
      break
    case TimelineScaleOptions.DAY:
      days = 4
      break
    case TimelineScaleOptions.HALFWEEK:
      days = 12
      break
    default:
      days = 24
  }

  const filteredTimeoff = timeoff.filter(
    (to) =>
      to.indefinite ||
      dayjs(to.end).isBetween(
        assignment.getCurrentDate,
        assignment.getCurrentDate.add(days, 'days')
      ) ||
      dayjs(to.start).isBetween(
        assignment.getCurrentDate,
        assignment.getCurrentDate.add(days, 'days')
      ) ||
      (dayjs(to.start).isBefore(assignment.getCurrentDate) &&
        dayjs(to.end).isAfter(assignment.getCurrentDate.add(days, 'days')))
  )

  return vehicleTypes.reduce((arr, vt) => {
    const filteredVehicles = vehicles
      .filter((v) => v.vehicleTypeId === vt.id)
      .map((v) => {
        const posRowBlocks = getReservationRowBlocksForVehicle(
          v,
          filteredReservations,
          filteredTimeoff
        )
        return {
          isVehicleType: false,
          ...v,
          collapsed: false,
          posRowBlocks,
          hasConflict: posRowBlocks.some((b) => b.conflict),
        }
      })
    if (filteredVehicles.length) {
      arr.push(
        new AssignmentsVehicleBlock({
          isVehicleType: true,
          vehicleTypeId: vt.id,
          vehicleType: vt,
        })
      )
      arr.push(...filteredVehicles)
    }
    return arr
  }, [])
}

export const getVehicleData = async (): Promise<{
  vehicles: Vehicle[]
  vehicleTypes: VehicleType[]
}> => {
  if (!assignment.vehicles?.length || !assignment.vehicleTypes?.length) {
    const [vehicleRes, vehicleTypeRes] = await Promise.all([
      vehicle.tableView({ pageSize: -1 }),
      typeClient.vehicleTypeTableView({ pageSize: -1 }),
    ])
    assignment.setVehicleData({
      vehicles: vehicleRes.data.resultList.sort((a, b) =>
        a.vehicleName.localeCompare(b.vehicleName)
      ),
      vehicleTypes: vehicleTypeRes.data.resultList,
    })
  }

  return {
    vehicles: assignment.vehicles,
    vehicleTypes: assignment.vehicleTypes,
  }
}

export const getDriverData = async (resId: number): Promise<Driver[]> => {
  const driverRes =
    await tripAssignmentClient.getDriverListForReservationAssignmentPanel(resId)
  return driverRes.data.drivers.map((d) => ({
    ...d,
    name: `${d.firstName} ${d.lastName}`,
  }))
}

export const getAllDrivers = async (): Promise<Driver[]> => {
  const driverRes = await driver.tableView({ pageSize: -1 })
  assignment.setDrivers(driverRes.data.resultList)
  return driverRes.data.resultList
}

export const convertVehicleRowsToDriverRows = (): AssignmentsDriverBlock[] => {
  const filteredReservations = filterReservationsByScale(
    assignment.getLoadedReservations
  )

  const drivers = deepClone(assignment.drivers)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((driver) => {
      const posRowBlocks = getReservationRowBlocksForDriver(
        driver.driverId,
        filteredReservations
      )
      return {
        userId: driver.driverId,
        driverName: driver.name,
        email: driver.email,
        posRowBlocks,
        top: Math.max(...posRowBlocks.map((b) => b.top)),
        hasConflict: posRowBlocks.some((b) => b.conflict),
      }
    })
  return [{ ...drivers[0], userId: null, top: 0, posRowBlocks: [] }, ...drivers]
}

export const getStartAndEndOffsetFromDates = (
  startDate: string,
  endDate: string
): number[] => {
  if (!startDate || !endDate) {
    return [null, null]
  }

  const minutesFromStart = dayjs(startDate).diff(
    assignment.getCurrentDate,
    'minutes'
  )
  const minutesFromEnd = dayjs(endDate).diff(
    assignment.getCurrentDate,
    'minutes'
  )

  let conversionScale = 0
  let zeroOffset = 0
  switch (assignment.getScale) {
    case TimelineScaleOptions.HALFDAY:
      conversionScale = 2400
      zeroOffset = 50
      break
    case TimelineScaleOptions.DAY:
      conversionScale = 1200
      zeroOffset = 25
      break
    case TimelineScaleOptions.HALFWEEK:
      conversionScale = 400
      zeroOffset = 8.33
      break
    case TimelineScaleOptions.WEEK:
      conversionScale = 200
      zeroOffset = 3.43
      break
  }

  return [
    (minutesFromStart * conversionScale) / 24 / 60 + zeroOffset,
    (minutesFromEnd * conversionScale) / 24 / 60 + zeroOffset,
  ]
}

export const getTotals = async (): Promise<AssignmentTotals> => {
  try {
    const filters = filter()
    const parentFilter = filters.createParent('and')
    filters.add(parentFilter, {
      column: {
        _t_id: v4(),
        value: 'assignedVehiclePercentage',
        filterAsIs: true,
        filterType: 'neq',
      },
      value: '100.00',
    })
    filters.add(parentFilter, {
      column: {
        _t_id: v4(),
        value: 'assignedDriverPercentage',
        filterAsIs: true,
        filterType: 'neq',
      },
      value: '100.00',
    })
    filters.add(parentFilter, {
      column: {
        _t_id: v4(),
        value: 'reservationStatusKey',
        filterAsIs: true,
        filterType: 'neq',
      },
      value: 'cancelled',
    })
    const params = { pageSize: 0, filters: filters.asQueryParams() }
    const [res, tv] = await Promise.all([
      assignmentClient.count(),
      reservationClient.tableView(params),
    ])
    return { ...res?.data, numUnassignedReservations: tv.data.count }
  } catch (err) {
    console.log('ERROR: ', err)
  }
}

export const getReservationRowBlocksForVehicle = (
  vehicle: Vehicle,
  reservations: any[],
  timeoff: VehicleTimeOff[] = []
): AssignmentsRowBlock[] => {
  const reservationsForVehicle = Object.values(reservations).filter((r) =>
    (r.vehicleAssignments || []).find(
      ({ vehicleId }) => vehicleId === vehicle.vehicleId
    )
  )

  const timeoffForVehicle = timeoff.filter(
    (to) => to.vehicleId === vehicle.vehicleId
  )

  const timeoffBlocks = timeoffForVehicle.map((to) => {
    if (to.indefinite) {
      // for indefinte time off set the end to 1 month from the start. This renders the block the entire view.
      to.end = assignment.getCurrentDate.add(28, 'days').format()
      // if to.start is before the current date, set it to the current date to properly render the block
      if (dayjs(to.start).isBefore(assignment.getCurrentDate)) {
        to.start = assignment.getCurrentDate.subtract(1, 'days').format()
      }
    }

    const [startDatetime, endDatetime] = formatStartToEnd(to.start, to.end)
    const coords = getStartAndEndOffsetFromDates(
      startDatetime.format(),
      endDatetime.format()
    )
    return {
      start: Math.max(coords[0], 0),
      end: Math.max(coords[1], 0),
      startDatetime: startDatetime.format(),
      endDatetime: endDatetime.format(),
      vehicleId: vehicle.vehicleId,
      vehicleTypeId: vehicle.vehicleTypeId,
      isTimeoff: true,
      // unused by timeoff
      reservationId: null,
      managedId: null,
      top: 0,
      startCity: null,
      startState: null,
      endCity: null,
      endState: null,
      startTimeZone: null,
      endTimeZone: null,
      conflict: null,
      isConfirmed: false,
      vehicleAssignmentIndex: null,
      drivers: [],
    }
  })

  const overlaps = Array(reservationsForVehicle.length).fill(null)
  overlaps[0] = 0

  const reservationBlockMap = reservationsForVehicle.map((res, i) => {
    let row = 0
    while (i) {
      const conflictCheck = reservationsForVehicle
        .filter((_, j) => overlaps[j] === row)
        .find((rowRes) =>
          doesBlockOverlapDates(
            res,
            rowRes.startDatetime,
            dayjs(rowRes.endDatetime).add(1, 'hour')
          )
        )

      if (!conflictCheck) {
        overlaps[i] = row
        break
      }
      row += 1
    }

    const vehicleAssignmentIndex =
      res.vehicleAssignments.length > 1
        ? res.vehicleAssignments.findIndex(
            (v) => v.vehicleId === vehicle.vehicleId
          ) + 1
        : null
    const drivers = deepClone(
      res.vehicleAssignments.find((v) => v.vehicleId === vehicle.vehicleId)
        ?.driverAssignments || []
    )
    const isConfirmed = !!res.vehicleAssignments.find(
      (v) => v.vehicleId === vehicle.vehicleId
    )?.isConfirmed
    const isConflictedWithRes =
      row !== 0 ||
      !!reservationsForVehicle.find(
        (conflictRes) =>
          res.reservationId !== conflictRes.reservationId &&
          doesBlockOverlapDates(
            conflictRes,
            res.startDatetime,
            dayjs(res.endDatetime).add(1, 'hour')
          )
      )
    const isConflictedWithTimeoff = timeoffForVehicle.find((to) =>
      doesBlockOverlapDates(res, dayjs(to.start), dayjs(to.end))
    )

    if (isConflictedWithTimeoff) {
      overlaps[i] += 1
    }

    const isConflicted = isConflictedWithRes || isConflictedWithTimeoff
    return {
      reservationId: res.reservationId,
      managedId: res.managedId,
      startDatetime: res.startDatetime,
      startTimeZone: res.startTimeZone,
      endDatetime: res.endDatetime,
      endTimeZone: res.endTimeZone,
      startState: res.firstStopState,
      startCity: res.firstStopCity,
      endState: res.lastStopState,
      endCity: res.lastStopCity,
      vehicleId: vehicle.vehicleId,
      vehicleTypeId: vehicle.vehicleTypeId,
      vehicleAssignmentIndex,
      top: overlaps[i],
      start: res.start,
      end: res.end,
      conflict: isConflicted
        ? {
            start: res.start,
            end: res.end,
          }
        : null,
      isConfirmed,
      isTimeoff: false,
      drivers,
    }
  })

  const timeoffAndReservationBlockMap =
    timeoffBlocks.concat(reservationBlockMap)
  return timeoffAndReservationBlockMap
}

export const getReservationRowBlocksForDriver = (
  driverId: number,
  reservations: any[]
): AssignmentsRowBlock[] => {
  const reservationsForDriver = reservations.filter((r) =>
    (r.vehicleAssignments || [])
      .flatMap((v) => v.drivers || [])
      .find((d) => d.driverId === driverId)
  )

  const overlaps = Array(reservationsForDriver.length).fill(null)
  overlaps[0] = 0

  const reservationBlockMap = reservationsForDriver.map((res, i) => {
    let row = 0
    while (i) {
      const conflictCheck = reservationsForDriver
        .filter((_, j) => overlaps[j] === row)
        .find((rowRes) =>
          doesBlockOverlapDates(
            res,
            rowRes.startDatetime,
            dayjs(rowRes.endDatetime).add(1, 'hour')
          )
        )

      if (!conflictCheck) {
        overlaps[i] = row
        break
      }
      row += 1
    }

    const vehicleAssignmentIndex = res.vehicleAssignments.findIndex((v) =>
      v.drivers.find((d) => d.driverId === driverId)
    )
    const displayIdx =
      res.vehicleAssignments.flatMap((v) => v.drivers || []).length > 1
        ? res.vehicleAssignments
            .flatMap((v) => v.drivers || [])
            .findIndex((d) => d.driverId === driverId) + 1
        : null
    const driverIdx = (
      res.vehicleAssignments[vehicleAssignmentIndex]?.drivers || []
    ).findIndex((d) => d.driverId === driverId)
    const isConfirmed = !!res.vehicleAssignments
      .flatMap((v) => v.drivers || [])
      .find((d) => d.driverId === driverId)?.isConfirmed
    const isConflicted =
      row !== 0 ||
      !!reservationsForDriver.find(
        (conflictRes) =>
          res.reservationId !== conflictRes.reservationId &&
          doesBlockOverlapDates(
            conflictRes,
            res.startDatetime,
            dayjs(res.endDatetime).add(1, 'hour')
          )
      )

    return {
      reservationId: res.reservationId,
      managedId: res.managedId,
      startDatetime: res.startDatetime,
      startTimeZone: res.startTimeZone,
      endDatetime: res.endDatetime,
      endTimeZone: res.endTimeZone,
      startState: res.firstStopState,
      startCity: res.firstStopCity,
      endState: res.lastStopState,
      endCity: res.lastStopCity,
      vehicleId: null,
      vehicleTypeId: null,
      userId: driverId,
      vehicleAssignmentIndex: vehicleAssignmentIndex + 1,
      driverAssignmentIndex: driverIdx,
      driverDisplayAssignmentIndex: displayIdx,
      top: overlaps[i],
      start: res.start,
      end: res.end,
      conflict: isConflicted
        ? {
            start: res.start,
            end: res.end,
          }
        : null,
      isTimeoff: false,
      isConfirmed,
      drivers: res.vehicleAssignments[vehicleAssignmentIndex]?.drivers || [],
    }
  })

  return reservationBlockMap
}

export const handleUpdateReservationNotifications = (): void => {
  const { defaultSendDriverAssignedEmail, defaultSendDriverAppNotification } =
    auth.getCompany?.notificationsSetting || {}

  const reservationSendEmailDefault =
    assignment.getSelectedReservation?.notifications.find(
      (n) =>
        n.contactType === DRIVER_CONTACT_TYPE_ID &&
        n.reservationNotificationType ===
          DRIVER_TRIP_ASSIGNMENT_EMAIL_NOTIFICATION_TYPE
    )
  const reservationSendAppNotificationDefault =
    assignment.getSelectedReservation?.notifications.find(
      (n) =>
        n.contactType === DRIVER_CONTACT_TYPE_ID &&
        n.reservationNotificationType ===
          DRIVER_TRIP_ASSIGNMENT_APP_NOTIFICATION_TYPE
    )

  // This toggle should be true if the setting is true or undefined (does not exist)
  assignment.setSendAppNotification(
    reservationSendAppNotificationDefault?.isActive !== false &&
      defaultSendDriverAppNotification !== false
  )
  assignment.setSendEmailNotification(
    reservationSendEmailDefault?.isActive !== false &&
      defaultSendDriverAssignedEmail !== false
  )
}

export const getSelectedDataFromResId = async (
  resId: number
): Promise<void> => {
  const [a, ud, uv, das, d, r] = await Promise.all([
    tripAssignmentClient.byReservationIds([resId]),
    tripAssignmentClient.unavailableDrivers(auth.getCompanyId, [resId]),
    tripAssignmentClient.unavailableVehicles(auth.getCompanyId, [resId]),
    tripAssignmentClient.getDriverAssignmentStatusForReservation(resId),
    getDriverData(resId),
    reservationClient.byId(resId),
  ])

  if (
    !assignment.getLoadedReservations.find((r) => r.reservationId === resId)
  ) {
    assignment.setLoadedReservations([r.data])
  }

  const trip = (await tripClient.byId(r.data.tripId)).data?.trip

  const res = getFilteredAssignments(
    r.data,
    a.data.vehicleAssignments,
    das.data.driverAssignmentStatuses
  )
  const coords = getStartAndEndOffsetFromDates(
    trip.garageTimes?.departureTime || trip.stops[0].pickupDatetime,
    trip.garageTimes?.returnTime ||
      trip.stops[trip.stops.length - 1].dropoffDatetime
  )
  const reservation = {
    ...r.data,
    vehicleAssignments: res.resAssignments,
    start: Math.max(coords[0], 0),
    end: Math.max(coords[1], 0),
  }
  const drivers = deepClone(assignment.drivers)
  drivers.map((driver) => {
    const driverOnReservation = d.find((d) => d.userId === driver.userId)
    if (driverOnReservation) {
      driver.status = driverOnReservation.status
    }
  })

  assignment.setSelectedData({
    reservation,
    trip,
    assignments: res.assignments,
    drivers,
    unavailableVehicles: uv.data.vehicleConflicts,
    unavailableDrivers: ud.data.driverConflicts,
    driverAssignmentStatuses: das.data.driverAssignmentStatuses,
  })
}

export const getFilteredAssignments = (
  reservation: Reservation | ReservationDetail,
  initialAssignments: VehicleAssignment[],
  driverStatuses: DriverAssignmentStatus[]
): { resAssignments: VehicleAssignment[]; assignments: AssignedVehicle[] } => {
  const requiredVehicles = []
  const resAssignments = []
  const assignedVehicles = deepClone(initialAssignments)
  const resRequiredVehicles = getRequiredVehicles(reservation)

  for (const requiredVehicle of resRequiredVehicles) {
    const assignedVehicleIndex = assignedVehicles.findIndex(
      (assignedVehicle) => {
        return assignedVehicle.vehicleTypeId === requiredVehicle.vehicleTypeId
      }
    )

    if (assignedVehicleIndex !== -1) {
      resAssignments.push(assignedVehicles[assignedVehicleIndex])
      assignedVehicles.splice(assignedVehicleIndex, 1)
    } else {
      requiredVehicles.push(requiredVehicle)
    }
  }

  const assignments = initialAssignments.map((assignment, i) => {
    let drivers = [
      {
        id: null,
        tripAssignmentId: null,
        label: `Driver 1`,
        name: '',
        key: `assignment-unassigned-driver-${i}-1`,
        errorMessage: null,
        isConfirmed: false,
        assignmentStatusKey: null,
      },
    ]
    if (assignment.driverAssignments?.length) {
      let driverCount = 0
      drivers = assignment.driverAssignments.map((driverAssignment) => {
        driverCount++
        const assignmentStatusKey = driverStatuses.find(
          (s) =>
            s.userId === driverAssignment.driver.driverId &&
            s.reservationId === reservation.reservationId
        )?.driverAssignmentStatusType.key
        return {
          label: `Driver ${driverCount}`,
          id: driverAssignment.driver.driverId,
          tripAssignmentId: driverAssignment.tripAssignmentId,
          name: formatFullName(driverAssignment.driver),
          key: `assigned-driver-${driverAssignment.driver.driverId}`,
          errorMessage: null,
          isConfirmed: driverAssignment.isConfirmed,
          assignmentStatusKey,
        }
      })
    }
    return {
      type: assignment.vehicleType.label,
      vehicleTypeId: assignment.vehicleType.id,
      isConfirmed: assignment.isConfirmed,
      errorMessage: null,
      id: assignment.vehicleId,
      tripAssignmentId: assignment.tripAssignmentId,
      name: assignment.vehicle.vehicleName,
      drivers,
    }
  })

  const unfilledAssignments = requiredVehicles.map((vehicle, i) => {
    resAssignments.push(new VehicleAssignment())
    return {
      type: vehicle.type,
      errorMessage: null,
      vehicleTypeId: vehicle.vehicleTypeId,
      isConfirmed: false,
      id: null,
      tripAssignmentId: null,
      name: '',
      drivers: [
        {
          id: null,
          tripAssignmentId: null,
          label: `Driver 1`,
          name: '',
          key: `unassigned-driver-${i}-1`,
          errorMessage: null,
          isConfirmed: false,
          assignmentStatusKey: null,
        },
      ],
    }
  })

  const allAssignments = [...assignments, ...unfilledAssignments]
  const currentDriverCount = allAssignments.reduce(
    (sum, assignment) => sum + assignment.drivers.length,
    0
  )
  const requiredDriverCount =
    reservation.requiredDrivers < resRequiredVehicles.length
      ? resRequiredVehicles.length
      : reservation.requiredDrivers
  const driversToDistribute = requiredDriverCount - currentDriverCount

  for (let i = 0; i < driversToDistribute; i++) {
    const assignment = [...allAssignments].sort((a, b) => {
      return a.drivers.length - b.drivers.length
    })[0]
    const driverCount = assignment.drivers.length
    assignment.drivers.push({
      id: null,
      tripAssignmentId: null,
      label: `Driver ${driverCount + 1}`,
      name: '',
      key: `unassigned-new-driver-${i}`,
      errorMessage: null,
      isConfirmed: false,
      assignmentStatusKey: null,
    })
  }
  return { resAssignments, assignments: allAssignments }
}

export const getFilteredAssignmentsFromRow = (
  row: Reservation
): {
  vehicleId: string
  vehicleName: string
  vehicleType: string
  drivers: { id: number; name: string; statusId: number }[]
}[] => {
  let assignments = (row?.vehicleAssignmentsString || '')
    .split('::')
    .map((v) => {
      const data = v.split('|')
      return {
        vehicleId: Number(data[0]),
        vehicleName: data[1],
        vehicleTypeId: Number(data[2]),
        driverIds: data.slice(3),
      }
    })
  const vehicleTypes = (row?.vehicleTypesString || '').split('::').map((v) => ({
    vehicleTypeId: Number(v.split('|')[0]),
    quantity: Number(v.split('|')[1]),
  }))
  const driverMap = (row?.driverAssignmentsString || '')
    .split('::')
    .reduce((map, v) => {
      const data = v.split('|')
      map[Number(data[0])] = {
        id: Number(data[0]),
        name: data[1],
        statusId: Number(data[2]),
      }
      return map
    }, {})

  const unassignedVehicles = []
  let res = vehicleTypes.reduce((arr, type) => {
    for (let i = 0; i < type.quantity; i++) {
      const existingAssignment = assignments.find(
        (a) => a.vehicleTypeId === type.vehicleTypeId
      )
      if (existingAssignment) {
        arr.push({
          vehicleId: existingAssignment.vehicleId.toString(),
          vehicleName: existingAssignment.vehicleName,
          drivers: existingAssignment.driverIds.map((id) => driverMap[id]),
          vehicleType: getVehicleTypeFromId(existingAssignment.vehicleTypeId),
        })
        assignments = assignments.filter(
          (a) => a.vehicleId !== existingAssignment.vehicleId
        )
      } else {
        unassignedVehicles.push({
          vehicleId: null,
          vehicleName: null,
          vehicleType: getVehicleTypeFromId(type.vehicleTypeId),
          drivers: [{ id: null, name: '1 Driver', statusId: null }],
        })
      }
    }
    return arr
  }, [])
  res = res.concat(unassignedVehicles)

  if (!res.length) {
    return res
  }

  const vehiclesToAdd = Math.max(
    (row.requiredVehicles as number) - res.length,
    0
  )
  for (let i = 0; i < vehiclesToAdd; i++) {
    res.push({
      vehicleId: null,
      vehicleName: null,
      vehicleType: getVehicleTypeFromId(1),
      drivers: [{ id: null, name: '1 Driver', statusId: null }],
    })
  }

  const requiredDriverCount =
    row?.requiredDrivers < res.length ? res.length : row?.requiredDrivers || 0
  const currentDriverCount = res.reduce((a, b) => a + b.drivers.length, 0)
  const driversToDistribute = requiredDriverCount - currentDriverCount

  for (let i = 0; i < driversToDistribute; i++) {
    const assignment = [...res].sort((a, b) => {
      return a.drivers.length - b.drivers.length
    })[0]
    assignment.drivers.push({ id: null, name: '1 Driver', statusId: null })
  }
  return res
}

export const collapseAssignments = (
  assignments: RowAssignment[]
): RowAssignment[] => {
  const initial = deepClone(assignments)

  return collapseWithCount<RowAssignment>(initial).map((vehicle) => {
    vehicle.vehicleName =
      vehicle.vehicleName ||
      `${vehicle.count} ${pluralize(vehicle.count, vehicle.vehicleType)}`

    vehicle.drivers = collapseWithCount<RowDriver>(vehicle.drivers).map(
      (driver) => {
        driver.name =
          driver.name === '1 Driver' || !driver.name
            ? `${driver.count} ${pluralize(driver.count, 'Driver')}`
            : driver.name
        return driver
      }
    )

    return vehicle
  })
}

const getVehicleTypeFromId = (vehicleTypeId: number): string => {
  switch (vehicleTypeId) {
    case 1:
      return 'Charter Bus'
    case 2:
      return 'Mini Bus'
    case 3:
      return 'Sprinter'
    case 4:
      return 'Party Bus'
    case 5:
      return 'Sedan'
    case 6:
      return 'SUV'
    case 7:
      return 'Limousine'
    case 8:
      return 'Trolley'
    case 9:
      return 'School Bus'
    case 10:
      return 'Van'
    case 11:
      return 'Mini Coach'
    default:
      return 'Charter Bus'
  }
}

export const getRequiredVehicles = (
  reservation: Reservation | ReservationDetail
): AssignedVehicle[] => {
  return (reservation.requiredVehicles as RequiredVehicleType[]).reduce(
    (arr, vehicle) => {
      if (vehicle.quantity === 1) {
        arr.push({
          ...vehicle,
          type: vehicle.vehicleType.label,
          vehicleTypeId: vehicle.vehicleType.id,
        })
      } else {
        arr.push(
          ...[...Array(vehicle.quantity).keys()].map((key) => {
            return {
              ...vehicle,
              type: `${vehicle.vehicleType.label} ${key + 1}`,
              vehicleTypeId: vehicle.vehicleType.id,
            }
          })
        )
      }
      return arr
    },
    []
  )
}

export const getRequiredDrivers = (
  reservation: Reservation,
  requiredVehicleCount: number
): number => {
  return reservation.requiredDrivers < requiredVehicleCount
    ? requiredVehicleCount
    : reservation.requiredDrivers
}

export const saveAssignments = async (
  skipReferralSync = false
): Promise<boolean> => {
  const old = deepClone(
    assignment.selectedReservation?.vehicleAssignments.filter(
      (v) => !!v.vehicleId
    )
  )
  const oldDriverIds = old
    .flatMap((a) => a.driverAssignments)
    .filter((da) => da.isConfirmed)
    .map((da) => da.userId)
  const newDriverIds = deepClone(assignment.selectedAssignments)
    .flatMap((va) => va.drivers)
    .filter((d) => d.isConfirmed && !!d.id)
    .map((d) => d.id)

  const stopInfo = buildFirstAndLastStops(
    assignment.selectedTrip,
    assignment.selectedReservationId
  )

  const getDeleteObject = (assignment) => {
    return [
      assignment.tripAssignmentId,
      ...assignment.driverAssignments.map((d) => d.tripAssignmentId),
    ]
  }

  const getCreateObject = (assignment) => {
    const driverAssignments =
      assignment.drivers
        ?.filter((da: AssignedDriver) => !!da.id)
        .map((da: AssignedDriver) => ({
          userId: da.id,
          hardConflictOverride: true,
          isConfirmed: da.isConfirmed,
          ...stopInfo,
        })) || []
    return {
      ...stopInfo,
      driverAssignments,
      vehicleId: assignment.id,
      vehicleTypeId: assignment.vehicleTypeId,
      isConfirmed: assignment.isConfirmed,
      hardConflictOverride: true,
    }
  }

  const toCreate = []
  const toDelete = []
  const toSetStatus = []
  const notifyDrivers = []
  for (const selectedAssignment of assignment.selectedAssignments) {
    if (!selectedAssignment.id) {
      continue
    }
    const existingIdx = old.findIndex(
      (v) =>
        selectedAssignment.id === v.vehicleId &&
        !!selectedAssignment.tripAssignmentId
    )
    if (existingIdx !== -1) {
      if (old[existingIdx].isConfirmed !== selectedAssignment.isConfirmed) {
        toSetStatus.push({
          tripAssignmentId: selectedAssignment.tripAssignmentId,
          isConfirmed: selectedAssignment.isConfirmed,
          isDriver: false,
        })
      }
      const updatedDriverStatuses = selectedAssignment.drivers.filter(
        (driver) =>
          driver.id &&
          old[existingIdx].driverAssignments.find((d) => d.userId === driver.id)
            ?.isConfirmed !== driver.isConfirmed
      )

      const oldFiltered = old[existingIdx].driverAssignments.filter(
        (a) => !selectedAssignment.drivers.find((b) => b.id === a.userId)
      )
      const newFiltered = selectedAssignment.drivers.filter(
        (a) =>
          !old[existingIdx].driverAssignments.find((b) => b.userId === a.id)
      )
      if (oldFiltered.length || newFiltered.length) {
        toDelete.push(...getDeleteObject(old[existingIdx]))
        toCreate.push(getCreateObject(selectedAssignment))
        notifyDrivers.push(...newFiltered)
      } else if (updatedDriverStatuses.length) {
        toSetStatus.push(
          ...updatedDriverStatuses.map((driver) => ({
            tripAssignmentId: driver.tripAssignmentId,
            isConfirmed: driver.isConfirmed,
            isDriver: true,
          }))
        )
      }
      old.splice(existingIdx, 1)
    } else {
      toCreate.push(getCreateObject(selectedAssignment))
      notifyDrivers.push(...selectedAssignment.drivers)
    }
  }
  for (const oldAssignment of old) {
    toDelete.push(...getDeleteObject(oldAssignment))
  }

  try {
    if (toDelete.length) {
      const res = await reservationClient.deleteAssignments(
        toDelete,
        skipReferralSync
      )
      if (assignment.isReferral && res.data?.failedCharterUPSync) {
        EventBus.$emit('referrals:failed-assignment')
        return false
      }
    }
    await updateTrip()
    if (toCreate.length) {
      const res = await reservationClient.createAssignments(
        toCreate,
        skipReferralSync
      )
      if (assignment.isReferral && res.data?.failedCharterUPSync) {
        EventBus.$emit('referrals:failed-assignment')
        return false
      }
    }
    if (toSetStatus.length) {
      await Promise.all(
        toSetStatus.map((data) =>
          assignmentClient.setStatus(data.tripAssignmentId, {
            isConfirmed: data.isConfirmed,
            isDriver: data.isDriver,
          })
        )
      )
    }

    if (newDriverIds.length) {
      await reservationClient.sendDriverAssigned(
        assignment.selectedReservationId,
        newDriverIds,
        assignment.getSendEmailNotification,
        assignment.getSendAppNotification
      )
    }
    if (auth.getCompany?.enableDriverAssignmentAcceptance) {
      const driversToRemove = oldDriverIds.filter(
        (id) => !newDriverIds.includes(id)
      )
      const driversToOffer = newDriverIds.filter(
        (id) => !oldDriverIds.includes(id)
      )

      let offerPromise = null
      let removalPromise = null
      if (driversToOffer.length) {
        offerPromise = reservationClient.offerTripAssignmentsToDrivers(
          assignment.selectedReservationId,
          driversToOffer
        )
      }
      if (driversToRemove.length) {
        removalPromise = reservationClient.clearDriverTripAssignmentOffers(
          assignment.selectedReservationId,
          driversToRemove
        )
      }
      await Promise.all([offerPromise, removalPromise])
    }
    if (assignment.getIsNonDefaultView) {
      EventBus.$emit(
        'refresh-reservation',
        assignment.getSelectedReservation.reservationId
      )
      EventBus.$emit('refresh-tableview')
    }

    if (assignment.isReferral && !skipReferralSync) {
      EventBus.$emit(
        'snackbar:success',
        'Referral assignments completed successfully'
      )
      return true
    } else {
      EventBus.$emit('snackbar:success', 'Assignments saved successfully.')
      return true
    }
  } catch (err) {
    EventBus.$emit('snackbar:error', 'Failed to save assignments')
    return false
  }
}

export const updateTrip = async (): Promise<AxiosResponse<Trip>> => {
  // update required vehicles (trip.vehicles)
  const tripVehicles = assignment.selectedAssignments.map((a) => {
    const types = deepClone(assignment.vehicleTypes)
    let type = types.find((type) => {
      return type.id === a.vehicleTypeId
    })
    if (!type) {
      type = types.find((type) => {
        return type.id === 1
      })
    }
    const vehicle = new Vehicle()
    Object.assign(vehicle, {
      quantity: 1,
      vehicleType: {
        id: type.id,
        key: type.key,
      },
      vehicleTypeId: type.id,
      vehicleTypeKey: type.key,
    })
    return vehicle
  })

  // update required drivers (trip.requiredDrivers)
  const requiredDriversCount = assignment.selectedAssignments.reduce(
    (sum, vehicle) => {
      sum += vehicle.drivers?.length || 1
      return sum
    },
    0
  )

  const payload = {
    vehicles: tripVehicles,
    requiredDrivers: requiredDriversCount,
  }

  return await tripClient.editTripDriversAndVehicles(
    assignment.selectedReservation.tripId,
    payload
  )
}

export const getSelectedDataFromResState = async (): Promise<void> => {
  const rstate = reservationState
  assignment.setCurrentDate(dayjs(rstate.reservation.startDate))

  const resId = rstate.reservation.reservationId
  const [ud, uv, das, d, _] = await Promise.all([
    tripAssignmentClient.unavailableDrivers(auth.getCompanyId, [resId]),
    tripAssignmentClient.unavailableVehicles(auth.getCompanyId, [resId]),
    tripAssignmentClient.getDriverAssignmentStatusForReservation(resId),
    getDriverData(resId),
    assignment.refresh(),
  ])

  if (
    !assignment.getLoadedReservations.find((r) => r.reservationId === resId)
  ) {
    assignment.setLoadedReservations([rstate.reservation])
  }

  const startDatetime = rstate.reservation?.startDate
  const endDatetime =
    rstate.trip.garageTimes?.returnTime ||
    rstate.trip.stops[rstate.trip.stops.length - 1].dropoffDatetime

  const filteredAssignments = getFilteredAssignments(
    rstate.reservation,
    rstate.assignments,
    das.data.driverAssignmentStatuses
  )

  assignment.setSelectedReservationId(resId)
  assignment.setSelectedData({
    reservation: formatReservation({
      ...rstate.reservation,
      startDatetime,
      endDatetime,
      vehicleAssignments: filteredAssignments.resAssignments,
    }),
    trip: rstate.trip,
    assignments: filteredAssignments.assignments,
    drivers: d,
    unavailableVehicles: uv.data.vehicleConflicts,
    unavailableDrivers: ud.data.driverConflicts,
    driverAssignmentStatuses: das.data.driverAssignmentStatuses,
  })
}
