import { Action, Module, VuexModule } from 'vuex-class-modules'
import store from '@/store/index'
import transformationSession from '@/services/transformation'
import { load, save } from '@/utils/localStorage'
import localCache from './localCache'
import auth from './auth'

@Module({ generateMutationSetters: true })
class ImpersonationModule extends VuexModule {
  isOpen = false
  hasTransformationSession: boolean = load('hasTransformationSession') || false

  get getIsOpen(): boolean {
    return this.isOpen
  }

  get getHasTransformationSession(): boolean {
    return this.hasTransformationSession
  }

  @Action
  open(): void {
    localCache.clearCache()
    this.isOpen = true
  }

  @Action
  close(): void {
    this.isOpen = false
  }

  @Action
  async fetchHasTransformationSession(): Promise<void> {
    this.hasTransformationSession =
      await transformationSession.hasActiveSession()
    save('hasTransformationSession', this.hasTransformationSession)
  }

  @Action
  async stopTransformationSession(): Promise<void> {
    await transformationSession.stop()
    await this.fetchHasTransformationSession()
    window.location.reload()
  }
}

export default new ImpersonationModule({ store, name: 'impersonation' })
