import { ApiResult } from './ApiResult'
import { UploadedFile } from './UploadedFile'
import { SupportedVehicleType } from './Vehicle'

export interface Driver {
  driverActivated?: boolean
  companyId: number
  email: string
  firstName: string
  lastName: string
  name?: string
  phone: number
  driverId?: number
  userId: number
  imagePath?: string
  status?: string
}

export interface DriverAvailability extends Driver {
  status: string
  isAvailable: boolean
}

export interface DriverRequest {
  active: boolean
  companyId: number
  companyName: string
  driverSupportedVehicles: SupportedVehicleType[]
  email: string
  firstName: string
  groupId: number
  lastName: string
  licenseExpirationDay: number
  licenseExpirationMonth: number
  licenseExpirationYear: number
  licenseNumber: string
  locale: string
  phone: string
  treatAsDriver: boolean
  userRoleNames: string[]
}

export interface DriverDocumentUploadResult extends ApiResult {
  driverDocuments: UploadedFile[]
}

// eslint-disable-next-line no-redeclare
export class DriverRequest implements DriverRequest {
  constructor(driverCommuteInfo?: Partial<DriverRequest>) {
    Object.assign(this, driverCommuteInfo)
  }
}

export interface DriverCommuteInfo {
  trackingStatus: string
  tagString: string
  tagColor: string
  tagBackground: string
  arrivalTimeString: string
  driverInfoString: string
}

// eslint-disable-next-line no-redeclare
export class DriverCommuteInfo implements DriverCommuteInfo {
  constructor(driverCommuteInfo?: Partial<DriverCommuteInfo>) {
    Object.assign(this, driverCommuteInfo)
  }
}

interface DriverAssignmentStatusType {
  id: number
  label: string
  key: string
  description: string
}

export interface DriverAssignmentStatus {
  reservationId: number
  userId: number
  driverAssignmentStatusType: DriverAssignmentStatusType
  note: string
  isActive: boolean
}

export const ASSIGNMENT_STATUSES = {
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  PENDING: 'Pending',
  DRAFT: 'Draft',
}
