
import { Component, Prop, Vue } from 'vue-property-decorator'
import { UserDetail } from '@/models/dto'
import { countryPhoneFormatFilter, formatFullName } from '@/utils/string'
import { Action } from '@/models/dto/Action'
import auth from '@/store/modules/auth'
import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'

@Component({})
export default class CUUserCard extends Vue {
  @Prop({ required: true }) readonly user!: UserDetail
  @Prop({ required: false }) readonly label!: string
  @Prop({ required: false, default: () => [] }) readonly actions!: Action[]

  get fullName(): string {
    return formatFullName(this.user)
  }

  get customerAccountName(): string {
    return this.user?.customerAccount?.name || ''
  }

  get phoneNumber(): string {
    let phoneNumber = countryPhoneFormatFilter(
      this.user.phone,
      this.user.phoneCountryKey
    )
    if (this.user.phoneExtension) {
      phoneNumber = `${phoneNumber} ext. ${this.user.phoneExtension}`
    }
    return phoneNumber
  }

  get canViewContacts(): boolean {
    return auth.getUserRoleNames.includes(ACCESS_SETTINGS_ROLES.CONTACTS)
  }
}
