var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.items)?_c('div',[_c('v-data-table',_vm._b({key:`v-data-table-${_vm.$vuetify.breakpoint.name}`,class:{
      'v-data-table--rows-selected': !!_vm.selected.length,
      'v-data-table--show-select': _vm.showSelect,
      'v-data-table--limit-height': _vm.limitTableToViewHeight,
      'elevation-0': _vm.flat,
      ..._vm.tableClasses,
    },attrs:{"headers":_vm.visibleColumns,"mobile-breakpoint":0,"items":_vm.items,"server-items-length":_vm.serverItemsLength,"disable-filtering":"","loader-height":2,"checkbox-color":"primary","show-select":_vm.showSelect,"options":_vm.options,"no-data-text":_vm.noDataText,"hide-default-footer":""},on:{"update:options":function($event){return _vm.$emit('update:options', $event)}},scopedSlots:_vm._u([(_vm.selected.length)?{key:"header",fn:function(){return [_c('thead',{staticClass:"position-relative w-0 h-0 top-0 left-0 z-5"},[_c('tr',[_c('th',{style:({ height: '0px', border: 'none' })},[_c('CUDataTableActionRow',{attrs:{"actions":_vm.actions,"rows":_vm.selected}})],1)])])]},proxy:true}:null,{key:`header.data-table-select`,fn:function({ on, props }){return [(!!props)?_c('v-simple-checkbox',{style:({ marginLeft: '-2px', marginBottom: '-5px' }),attrs:{"ripple":false,"value":props.value,"indeterminate":props.indeterminate,"color":"primary"},on:{"input":on.input}}):_vm._e()]}},{key:`item.data-table-select`,fn:function({ isSelected, select, index }){return [_c('v-simple-checkbox',{key:`data-table-checkbox-${index}`,style:({ marginLeft: '-2px' }),attrs:{"ripple":false,"value":isSelected,"color":"primary"},on:{"input":select}})]}},_vm._l((_vm.headerColumns),function(col,index){return {key:col.headerName,fn:function(props){return [_c('thead',{key:`header-${col.slotName}-${index}`,style:({
          float: col.value === 'customize-columns' ? 'right' : '',
        })},[_c('th',{staticClass:"text-start"},[(col.headerComponent)?_c('span',[_c(col.headerComponent,{tag:"component",class:{
                'opacity-0 pointer-events-none':
                  _vm.selected.length && col.value !== 'customize-columns',
              },on:{"handle-excel-download":function($event){return _vm.$emit('handle-excel-download')},"handle-pdf-download":_vm.handlePdfDownload}})],1):(!!props)?_c('span',{staticClass:"d-flex font-book font-14 text-text-gray-4 padding-r-1",class:{ 'text-transparent': _vm.selected.length },style:(_vm.selected.length ? 'opacity: 0;' : '')},[_vm._v(" "+_vm._s(props.header.text)+" ")]):_vm._e()])])]}}}),_vm._l((_vm.mappedColumns),function(col,index){return {key:col.slotName,fn:function({ item }){return [_c('td',{key:`td-${item[_vm.itemKey]}-${index}-${col._t_id}-${item.index || 0}`,class:{
          'w-full':
            index === _vm.mappedColumns.length - 1 && _vm.limitTableToViewHeight,
        }},[_c('CUDataTableCell',_vm._g({key:`data-table-cell-${item[_vm.itemKey]}-${col._t_id}-${index}-${
            item.index || 0
          }`,attrs:{"column":col,"row":item,"row-index":index,"actions":_vm.actions,"item-key":_vm.itemKey},on:{"refresh":function($event){return _vm.$emit('refresh')}}},_vm.$listeners))],1)]}}}),{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between border-thin border-solid border-gray-border-mid border-t-1 border-x-0 border-b-0"},[_c('div',{staticClass:"d-flex align-center v-data-table__row-select"},[_c('label',{staticClass:"font-16 font-semibold"},[_vm._v("Rows per page:")]),_c('v-select',{staticClass:"font-16 w-76 h-48 font-semibold",style:({ marginTop: '6px !important' }),attrs:{"flat":"","solo":"","color":"green","item-color":"green","value":_vm.options.pageSize,"items":[5, 10, 25, 50, 100]},on:{"change":function($event){return _vm.$emit('update:rows-per-page', $event)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('CUIcon',{attrs:{"aria-label":"Dropdown","color":"gray-icon-light"}},[_vm._v(" keyboard_arrow_down ")])]},proxy:true}],null,false,3055726827)})],1),_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":7,"color":"green","rounded":"4"},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}})],1)]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},'v-data-table',_vm.$attrs,false))],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }