import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Payment } from '@/models/dto/Payment'
import {
  CompanyPaymentGatewayDetail,
  GetCompanyPaymentGatewayResponse,
} from '@/models/PaymentGateway'
import { ApiResult, Type } from '@/models/dto'
import { CustomerPaymentProfile } from '@/models/dto/PaymentProfile'
import { filter } from '@/utils/filter'
import { RefundType } from '@/models/dto/Refund'
import { ChargeType } from '@/models/dto/Charge'

const httpService: HttpService = new HttpService()

export default {
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<Payment>>> {
    const { sorts, filters, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }

    query = encodeURI(query)
    return httpService.get(`https://${apiBaseUrl()}/tables/payments?${query}`)
  },
  createPaymentGateway(
    payload: CompanyPaymentGatewayDetail
  ): Promise<AxiosResponse<GetCompanyPaymentGatewayResponse>> {
    const url = `https://${apiBaseUrl()}/paymentGateway`
    return httpService.post(url, payload)
  },
  applyForBusifyPay(): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/paymentGateway/applyForBusifyPay`
    return httpService.post(url, {})
  },
  getDefaultPaymentGateway(
    companyId: number
  ): Promise<AxiosResponse<GetCompanyPaymentGatewayResponse>> {
    const url = `https://${apiBaseUrl()}/paymentGateway/company/${companyId}/default`
    return httpService.get(url)
  },
  updateDefaultPaymentGateway(
    paymentGatewayId: number
  ): Promise<AxiosResponse<GetCompanyPaymentGatewayResponse>> {
    const url = `https://${apiBaseUrl()}/paymentGateway/default/${paymentGatewayId}`
    return httpService.get(url)
  },
  updatePaymentGateway(
    modifiedGateway: CompanyPaymentGatewayDetail
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${apiBaseUrl()}/paymentGateway`
    return httpService.put(url, modifiedGateway)
  },
  getAllPaymentGateways(): Promise<
    AxiosResponse<GetCompanyPaymentGatewayResponse>
  > {
    const url = `https://${apiBaseUrl()}/paymentGateway`
    return httpService.get(url)
  },
  getCustomerPaymentProfiles(
    params: TableViewParameters,
    customerIds: number[]
  ): Promise<AxiosResponse<TableViewResult<CustomerPaymentProfile>>> {
    const { sorts, filters, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }

    const filterInstance = filter()
    const parentFilter = filterInstance.createParent('or')

    for (const customerId of customerIds) {
      filterInstance.add(parentFilter, {
        column: {
          _t_id: '4481ca2b-b707-4b96-b720-712cd6efebf5',
          value: 'customerId',
          filterType: 'eq',
        },
        value: customerId,
      })
    }

    query += `&${filterInstance.asQueryParams()}`
    query = encodeURI(query)
    return httpService.get(
      `https://${apiBaseUrl()}/tables/customerPaymentProfiles?${query}`
    )
  },
  getPaymentTypes(): Promise<AxiosResponse<Type[]>> {
    const url = `https://${apiBaseUrl()}/types/payment-types`
    return httpService.get(url)
  },
  getPaymentMethodTypes(): Promise<AxiosResponse<Type[]>> {
    const url = `https://${apiBaseUrl()}/types/payment-method-types`
    return httpService.get(url)
  },
  getRefundTypes(): Promise<AxiosResponse<RefundType[]>> {
    const url = `https://${apiBaseUrl()}/types/refund-types`
    return httpService.get(url)
  },
  getChargeTypes(): Promise<AxiosResponse<ChargeType[]>> {
    const url = `https://${apiBaseUrl()}/types/charge-types`
    return httpService.get(url)
  },
  getBusifyPayMerchantDetail(merchantId: string): Promise<any> {
    const url = `https://${apiBaseUrl()}/bpp/merchants/${merchantId}`
    return httpService.get(url)
  },
  void(id: number): Promise<AxiosResponse<ApiResult>> {
    const url = `https://${apiBaseUrl()}/payment/void/${id}`
    return httpService.delete(url)
  },
}
