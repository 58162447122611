import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import modules from './store/modules'
import '@/scss/main.scss'
import i18n from './i18n'
import '@/plugins/gmaps'
import '@/plugins/dayjs'
import '@/plugins/recaptcha'
import CUIcon from '@/components/CUIcon.vue'
import VueTheMask from 'vue-the-mask'
import CUTextField from '@/components/CUTextField.vue'
import CUSelect from '@/components/CUSelect.vue'
import CUVehicleSelect from '@/components/CUVehicleSelect.vue'
import CUModal from '@/components/CUModal.vue'
import CUTextArea from '@/components/CUTextArea.vue'
import CUDataTable from '@/components/CUDataTable.vue'
import CUDatePicker from '@/components/CUDatePicker.vue'
import CUSkeletonLoader from '@/components/CUSkeletonLoader.vue'
import CUPasswordField from '@/components/CUPasswordField.vue'
import CUTag from '@/components/CUTag.vue'
import CUChip from '@/components/CUChip.vue'
import CUButton from '@/components/CUButton.vue'
import CUSidebarContent from '@/layouts/CUSidebarContent.vue'
import CUSidebarContentWithFooterButtons from '@/components/CUSidebarContentWithFooterButtons.vue'
import CUTimePicker from '@/components/CUTimePicker.vue'
import CUTabs from '@/components/CUTabs.vue'
import CUScroll from '@/layouts/CUScroll.vue'
import CUCheckbox from '@/components/CUCheckbox.vue'
import VCalendar from 'v-calendar'
import CUCurrencyField from '@/components/CUCurrencyField.vue'
import CUPercentageField from '@/components/CUPercentageField.vue'
import CUMultiSwitch from '@/components/CUMultiSwitch.vue'
import CUSimpleTable from '@/components/CUSimpleTable.vue'
import CURichText from '@/components/CURichText.vue'
import CUAction from '@/components/CUAction.vue'
import CUTooltip from '@/components/CUTooltip.vue'
import CUPhoneField from '@/components/CUPhoneField.vue'
import CUCurrencyRangePicker from '@/components/CUCurrencyRangePicker.vue'
import CUExpansionPanel from '@/components/CUExpansionPanel.vue'
import wb from '@/registerServiceWorker'
import * as FS from '@fullstory/browser'
import CustomerContactSearch from '@/components/CustomerContactSearch.vue'
import CUTagCloud from '@/components/CUTagCloud.vue'
import CUDatePicker2 from '@/components/CUDatePicker2.vue'
import { apolloProvider } from './services/apollo/config'

Vue.use(VueTheMask)
Vue.use(VCalendar, {
  componentPrefix: 'vc',
})
Vue.component('CustomerContactSearch', CustomerContactSearch)
Vue.component('CUExpansionPanel', CUExpansionPanel)
Vue.component('CUIcon', CUIcon)
Vue.component('CUTextField', CUTextField)
Vue.component('CUCurrencyField', CUCurrencyField)
Vue.component('CUTextArea', CUTextArea)
Vue.component('CUSelect', CUSelect)
Vue.component('CUVehicleSelect', CUVehicleSelect)
Vue.component('CUModal', CUModal)
Vue.component('CUDataTable', CUDataTable)
Vue.component('CUDatePicker', CUDatePicker)
Vue.component('CUSkeletonLoader', CUSkeletonLoader)
Vue.component('CUPasswordField', CUPasswordField)
Vue.component('CUTag', CUTag)
Vue.component('CUChip', CUChip)
Vue.component('CUButton', CUButton)
Vue.component('CUTabs', CUTabs)
Vue.component('CUScroll', CUScroll)
Vue.component('CUSidebarContent', CUSidebarContent)
Vue.component(
  'CUSidebarContentWithFooterButtons',
  CUSidebarContentWithFooterButtons
)
Vue.component('CUTimePicker', CUTimePicker)
Vue.component('CUCheckbox', CUCheckbox)
Vue.component('CUMultiSwitch', CUMultiSwitch)
Vue.component('CUSimpleTable', CUSimpleTable)
Vue.component('CUPercentageField', CUPercentageField)
Vue.component('CUPhoneField', CUPhoneField)
Vue.component('CURichText', CURichText)
Vue.component('CUAction', CUAction)
Vue.component('CUTooltip', CUTooltip)
Vue.component('CUCurrencyRangePicker', CUCurrencyRangePicker)
Vue.component('CUTagCloud', CUTagCloud)
Vue.component('CUDatePicker2', CUDatePicker2)
Vue.config.productionTip = false
Vue.prototype.$workbox = wb
FS.init({ orgId: 'o-1HR4FH-na1' })
Vue.prototype.$FullStory = FS

new Vue({
  router,
  store,
  vuetify,
  i18n,
  apolloProvider,
  created() {
    if (modules.auth.getIsTokenSet) {
      modules.auth.autoLogin()
      modules.types.loadAllTypes()
    }
  },
  render: (h) => h(App),
}).$mount('#app')
