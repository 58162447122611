import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import {
  CheckoutQuote,
  QuoteConvertManualPayload,
  QuoteEmail,
  Quote,
} from '@/models/dto/Quote'
import {
  ApiResult,
  PaymentMethodTypes,
  PaymentTypeTypes,
  TripEstimation,
} from '@/models/dto'
import { Pricing } from '@/models/dto/Pricing'
import {
  CompanyPaymentGateway,
  PaymentGatewayConvertPayload,
} from '@/models/PaymentGateway'
import { Recurrence } from '@/models/dto/Recurrence'
import { TimeSeriesRequest, TimeSeriesResult } from '@/models/dto/Time'
import { EmailTemplate } from '@/models/dto/Template'
import { capitalizeFirstLetter } from '@/utils/string'
import { AcceptReferralResult } from '@/models/dto/Reservation'
import { PaymentTransaction } from '@/models/dto/PaymentTransaction'
import { CheckoutDocument } from '@/models/dto/CheckoutDocument'
import { Contact } from '@/models/Contact'
import { v4 as uuid } from 'uuid'

const httpService: HttpService = new HttpService()

export default {
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<Quote>>> {
    const { sorts, filters, pageSize = 10, page = 1, tz = null } = params
    let query = `&page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    if (tz) {
      query += `&actorTimeZone=${tz}`
    }

    query = encodeURI(query)
    return httpService.get(
      `https://${apiBaseUrl()}/tables/quotes?${query}&v2=true`
    )
  },
  export(params: TableViewParameters): any {
    const { sorts, filters, selectedColumns } = params

    let query = ''
    if (sorts) {
      query += sorts
    }
    if (filters) {
      query += query.length ? `&${filters}` : `${filters}`
    }
    if (selectedColumns) {
      query += query.length ? `&${selectedColumns}` : `${selectedColumns}`
    }
    query = encodeURI(query)
    const host = apiBaseUrl()
    const url = `https://${host}/tables/quotes/export?${query}`
    return httpService.get(url, { responseType: 'blob' })
  },
  byId(id: number | string): Promise<AxiosResponse<{ quote: Quote }>> {
    return httpService.get(`https://${apiBaseUrl()}/v2/quotes/${id}`)
  },
  byHash(quoteHash: string): Promise<AxiosResponse<CheckoutQuote>> {
    return httpService.get(
      `https://${apiBaseUrl()}/quoteDetail/checkout/${quoteHash}`
    )
  },
  getTransactions(
    quoteHash: string
  ): Promise<AxiosResponse<PaymentTransaction[]>> {
    return httpService.get(
      `https://${apiBaseUrl()}/transaction/summary/quote/${quoteHash}`
    )
  },
  getRecurrence(recurrences: Recurrence[]): Promise<AxiosResponse> {
    return httpService.post(
      `https://${apiBaseUrl()}/quotes/getRecurrenceEventDateList`,
      { recurrences }
    )
  },
  tripEstimations(quote: Quote): Promise<AxiosResponse<TripEstimation[]>> {
    return httpService.post(`https://${apiBaseUrl()}/tripEstimations`, quote)
  },
  pricing(
    quote: Quote,
    overrideLiveMiles = null
  ): Promise<AxiosResponse<Pricing>> {
    let url = `https://${apiBaseUrl()}/v2/pricings`
    if (overrideLiveMiles !== null) {
      url += `?overrideLiveMiles=${overrideLiveMiles}`
    }
    return httpService.post(url, quote)
  },
  update(quote: Quote): Promise<AxiosResponse<{ quote: Quote }>> {
    const url = `https://${apiBaseUrl()}/v2/quotes/full/${quote.quoteId}`
    return httpService.post(url, quote)
  },
  create(quote: Quote): Promise<AxiosResponse<{ quote: Quote }>> {
    const url = `https://${apiBaseUrl()}/v2/quotes/full`
    return httpService.post(url, quote)
  },
  updateQuoteStatuses(
    quoteIds: number[],
    statusId: number,
    customers: Contact[] = []
  ): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/quotes/updateQuoteStatuses`
    return httpService.post(url, { quoteIds, statusId, customers })
  },
  updateQuotePriorities(
    quoteIds: number[],
    priorityId: number
  ): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/quotes/updateQuotePriorities`
    return httpService.post(url, { quoteIds, priorityId })
  },
  bulkDelete(ids: number[]): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/quotes/delete`
    return httpService.post(url, ids)
  },
  getEmailTemplate(): Promise<
    AxiosResponse<{ emailCustomizationDTO: EmailTemplate }>
  > {
    const url = `https://${apiBaseUrl()}/v2/quotes/email`
    return httpService.get(url)
  },
  saveEmailTemplate(
    template: EmailTemplate
  ): Promise<AxiosResponse<{ emailCustomizationDTO: EmailTemplate }>> {
    const url = `https://${apiBaseUrl()}/v2/quotes/email`
    return httpService.post(url, template)
  },
  updateEmailTemplate(
    template: Partial<EmailTemplate>
  ): Promise<AxiosResponse<{ emailCustomizationDTO: EmailTemplate }>> {
    const url = `https://${apiBaseUrl()}/v2/quotes/email`
    return httpService.patch(url, template)
  },
  updateSendQuoteAsInvoice(
    quote: Partial<Quote>
  ): Promise<AxiosResponse<void>> {
    const url = `https://${apiBaseUrl()}/v2/quotes/updateSendAsInvoice/${
      quote.quoteId
    }`
    return httpService.patch(url, quote)
  },
  sendQuoteEmail(quoteEmail: QuoteEmail): Promise<AxiosResponse<void>> {
    const url = `https://${apiBaseUrl()}/quotes/sendEmail`
    return httpService.post(url, quoteEmail)
  },
  convertManually({
    quoteId,
    payment_method,
    payment_type,
    amountPaid,
    payload = null,
    confirmationEmails = [],
    customer_notes = null,
    internal_notes = null,
    reference_number = null,
    convertedBy = null,
    overrideExpiration = false,
    checkoutDocument = null,
    enablePONumber = false,
    poNumber = null,
    disableInvoiceCreation = false,
  }: {
    quoteId: number
    payment_method: PaymentMethodTypes
    payment_type: PaymentTypeTypes
    amountPaid: number
    payload?: QuoteConvertManualPayload
    confirmationEmails?: string[]
    customer_notes?: string
    internal_notes?: string
    reference_number?: string
    convertedBy?: number
    overrideExpiration?: boolean
    checkoutDocument?: CheckoutDocument
    enablePONumber?: boolean
    poNumber?: string
    disableInvoiceCreation?: boolean
  }): Promise<AxiosResponse> {
    let manualConvertPayload: QuoteConvertManualPayload
    if (payload) {
      manualConvertPayload = payload
    } else {
      manualConvertPayload = {
        meta: {
          billing: { check_number: reference_number },
        },
        manual: true,
        payment_method,
        payment_type,
        amountPaidAsCents: Math.floor(amountPaid * 100),
        confirmationEmails,
        internal_notes,
        customer_notes,
        convertedBy,
        overrideExpiration,
        checkoutDocument,
        enablePONumber,
        poNumber,
        disableInvoiceCreation,
      }
    }
    const url = `https://${apiBaseUrl()}/quotes/${quoteId}/convert`
    return httpService.post(url, manualConvertPayload)
  },
  async convertQuoteWithTokenizedPayment({
    quoteId,
    paymentInfo,
    payment_type,
    amountPaid,
    paymentMethodData = {},
    confirmationEmails = [],
    customer_notes = null,
    internal_notes = null,
    processingFeePercentage = 0,
    convertedBy = null,
    overrideExpiration = false,
    checkoutDocument,
    disableInvoiceCreation = false,
  }: {
    quoteId: number
    paymentInfo: PaymentGatewayConvertPayload
    payment_type: PaymentTypeTypes
    amountPaid: number
    paymentMethodData?: any
    confirmationEmails?: string[]
    customer_notes?: string
    internal_notes?: string
    processingFeePercentage?: number
    convertedBy?: number
    overrideExpiration?: boolean
    checkoutDocument?: CheckoutDocument
    disableInvoiceCreation?: boolean
  }): Promise<AxiosResponse> {
    let payload

    if (!quoteId) {
      throw new Error('Missing parameters in convertQuote()')
    }
    const description =
      paymentMethodData.mask && paymentMethodData.type_label
        ? `Card - ${capitalizeFirstLetter(paymentMethodData.type_label)} ${
            paymentMethodData.mask
          }`
        : null
    if (paymentInfo) {
      payload = {
        nonces: paymentInfo.tokens,
        payment_method: 'credit_card',
        payment_type,
        amountPaidAsCents: Math.floor(amountPaid * 100),
        description,
        billing: paymentMethodData,
        payment_gateway: paymentInfo.paymentGateway,
        confirmationEmails,
        internal_notes,
        customer_notes,
        processingFeePercentage: Number(processingFeePercentage),
        convertedBy,
        overrideExpiration,
        checkoutDocument,
        disableInvoiceCreation,
      }
    }

    const response = await httpService.post(
      `https://${apiBaseUrl()}/quotes/${quoteId}/convert`,
      payload
    )
    return response
  },
  async convertQuoteWithCustomerPaymentProfile(
    quoteId: number,
    paymentProfileId: number,
    paymentGateway: CompanyPaymentGateway,
    payment_type: PaymentTypeTypes,
    amountPaid: number,
    description: string,
    confirmationEmails: string[] = [],
    customer_notes: string = null,
    internal_notes: string = null,
    processingFeePercentage = 0,
    convertedBy: number = null,
    overrideExpiration = false,
    enablePONumber = false,
    poNumber = null,
    disableInvoiceCreation = false
  ): Promise<AxiosResponse> {
    const payload = {
      paymentProfileId,
      payment_gateway: paymentGateway,
      payment_method: 'credit_card',
      payment_type,
      amountPaidAsCents: Math.floor(amountPaid * 100),
      description,
      confirmationEmails,
      customer_notes,
      internal_notes,
      processingFeePercentage: Number(processingFeePercentage),
      convertedBy,
      overrideExpiration,
      enablePONumber,
      poNumber,
      disableInvoiceCreation,
    }

    if (!quoteId) {
      throw new Error('Missing parameters in convertQuote()')
    }

    const response = await httpService.post(
      `https://${apiBaseUrl()}/quotes/${quoteId}/convert`,
      payload
    )
    return response
  },
  async convertQuote(
    quote: CheckoutQuote,
    paymentInfo: PaymentGatewayConvertPayload,
    payment_type: PaymentTypeTypes,
    amountPaid: number,
    paymentMethodData = {}
  ): Promise<AxiosResponse> {
    let payload

    if (!quote) {
      throw new Error('Missing parameters in convertQuote()')
    }

    if (paymentInfo) {
      payload = {
        nonces: paymentInfo.tokens,
        payment_method: 'credit_card',
        payment_type,
        amountPaidAsCents: Math.floor(amountPaid * 100),
        billing: paymentMethodData,
        payment_gateway: paymentInfo.paymentGateway,
      }
    }
    // Leaving commented out for now, until we support ACH/Check Payments
    // else {
    //   payload = {
    //     meta: {
    //       notes: 'Manual conversion',
    //     },
    //   }
    // }

    const response = await httpService.post(
      `https://${apiBaseUrl()}/quotes/${quote.quoteId}/convert`,
      payload
    )
    return response
  },
  quoteTimeSeriesCustomerData(
    customerId: number,
    payload: TimeSeriesRequest
  ): Promise<AxiosResponse<TimeSeriesResult>> {
    const url = `https://${apiBaseUrl()}/quotes/timeSeries/${customerId}/customer`
    return httpService.post(url, payload)
  },
  quoteTimeSeriesCustomerAccountData(
    customerAccountId: number,
    payload: TimeSeriesRequest
  ): Promise<AxiosResponse<TimeSeriesResult>> {
    const url = `https://${apiBaseUrl()}/quotes/timeSeries/${customerAccountId}/customerAccount`
    return httpService.post(url, payload)
  },
  acceptReferral(
    reservationId: number,
    disableInvoiceCreation = false
  ): Promise<AxiosResponse<AcceptReferralResult>> {
    const query = 'referralAcceptanceESignature='
    const url = `https://${apiBaseUrl()}/charterup/referrals/accept/${reservationId}?disableInvoiceCreation=${disableInvoiceCreation}&${query}`
    return httpService.get(url)
  },
  rejectReferral(
    reservationId: number,
    rejectNote = ''
  ): Promise<AxiosResponse<ApiResult>> {
    let query = `?notes=${rejectNote}`
    query = encodeURI(query)
    const url = `https://${apiBaseUrl()}/charterup/referrals/reject/${reservationId}${query}`
    return httpService.get(url)
  },
  sendConfirmationEmail(
    quoteId: number,
    confirmationEmails?: string[]
  ): Promise<AxiosResponse> {
    const sendConfirmationEmailPayload = {
      confirmationEmails,
    }
    const url = `https://${apiBaseUrl()}/quotes/${quoteId}/sendConfirmationEmail`
    return httpService.post(url, sendConfirmationEmailPayload)
  },
  initializeQuoteConversion(
    quoteId: number,
    sessionId: string,
    payFull: boolean,
    paymentMethod: 'card' | 'bank',
    processingFee,
    apiKey: string
  ): Promise<any> {
    const payload = {
      quoteId,
      paymentChoice: payFull ? 'FULL' : 'DUE_NOW',
      paymentMethod,
      processingFee,
      sessionId,
      apiKey,
    }
    const url = `https://${apiBaseUrl()}/quotes/payment/initialize`
    return httpService.post(url, payload)
  },
  async convertQuoteWithBusifyPay({
    quoteId,
    payment_type,
    amountPaid,
    payment_method,
    confirmationEmails = [],
    customer_notes = null,
    internal_notes = null,
    processingFeePercentage = 0,
    convertedBy = null,
    overrideExpiration = false,
    checkoutDocument,
    disableInvoiceCreation = false,
    description = '',
    paymentId,
    sessionId,
  }: {
    quoteId: number
    payment_type: PaymentTypeTypes
    amountPaid: number
    confirmationEmails?: string[]
    customer_notes?: string
    internal_notes?: string
    processingFeePercentage?: number
    convertedBy?: number
    overrideExpiration?: boolean
    checkoutDocument?: CheckoutDocument
    disableInvoiceCreation?: boolean
    description: string
    paymentId: string
    payment_method: string
    sessionId: string
  }): Promise<any> {
    if (!quoteId) {
      throw new Error('Missing parameters in convertQuote()')
    }
    const payload = {
      payment_method,
      payment_type,
      amountPaidAsCents: Math.floor(amountPaid * 100),
      confirmationEmails,
      internal_notes,
      customer_notes,
      processingFeePercentage: Number(processingFeePercentage),
      convertedBy,
      overrideExpiration,
      checkoutDocument,
      disableInvoiceCreation,
      description,
      paymentId,
      sessionId,
      manual: true,
    }

    const url = `https://${apiBaseUrl()}/v2/quotes/${quoteId}/convert`
    return httpService.post(url, payload)
  },
}
