var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.supported && _vm.simple)?_c('div',{ref:`scroll-element-${_vm.id}`,staticClass:"scroll",class:{
    'scroll-g': _vm.grayBg,
    'scroll-x': _vm.horizontal || _vm.displayBoth,
    'scroll-y': !_vm.horizontal || _vm.displayBoth,
  },style:(_vm.styles),on:{"scroll":_vm.watchForEnd}},[_vm._t("default")],2):(_vm.supported)?_c('div',{staticClass:"position-relative",style:({
    marginBottom: (_vm.horizontal || _vm.displayBoth) && _vm.offset ? '-12px' : '',
    marginRight: (!_vm.horizontal || _vm.displayBoth) && _vm.offset ? '-12px' : '',
  }),attrs:{"id":"cu-scroll-wrapper"}},[_c('div',{ref:`scroll-element-${_vm.id}`,staticClass:"scroll",class:{
      'scroll-g': _vm.grayBg,
      'scroll-x': _vm.horizontal || _vm.displayBoth,
      'scroll-y': !_vm.horizontal || _vm.displayBoth,
      'scroll-l-wrapper': _vm.left,
    },style:(_vm.styles),on:{"scroll":_vm.watchForEnd}},[(_vm.left)?_c('div',{staticClass:"d-inline-block scroll-l"},[_vm._t("default")],2):_vm._t("default")],2)]):_c('div',[_c('div',{style:({
      ..._vm.styles,
      scrollbarWidth: 'thin',
    }),attrs:{"id":"cu-scroll-not-supported"}},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }