
import { ASSIGNMENT_STATUSES } from '@/models/dto/Driver'
import { Vue, Component, Prop } from 'vue-property-decorator'
import CUTag from './CUTag.vue'

@Component({ components: { CUTag } })
export default class DriverAssignmentTag extends Vue {
  @Prop({ required: false, default: 'Accepted' })
  readonly assignmentStatus!: string

  get border(): string {
    switch (this.assignmentStatus) {
      case ASSIGNMENT_STATUSES.DRAFT:
      case ASSIGNMENT_STATUSES.PENDING:
        return 'bg-gray-5'
      case ASSIGNMENT_STATUSES.ACCEPTED:
        return 'green'
      case ASSIGNMENT_STATUSES.REJECTED:
        return 'rust'
      default:
        return 'primary'
    }
  }

  get background(): string {
    switch (this.assignmentStatus) {
      case ASSIGNMENT_STATUSES.DRAFT:
      case ASSIGNMENT_STATUSES.PENDING:
        return 'bg-gray-1'
      case ASSIGNMENT_STATUSES.ACCEPTED:
        return 'secondary-light'
      case ASSIGNMENT_STATUSES.REJECTED:
        return 'additional-red-2'
      default:
        return 'primary-10'
    }
  }
}
