
import { Vue, Component } from 'vue-property-decorator'
import assignment from '@/store/modules/assignment'
import { EventBus } from '@/utils/eventBus'

@Component
export default class AssignmentsSidebarHeader extends Vue {
  assignment = assignment

  get loading(): boolean {
    return !assignment.getSelectedAssignments?.length
  }

  get isReservationCancelled(): boolean {
    return (
      assignment.selectedReservation?.reservationStatusKey === 'cancelled' ||
      assignment.selectedReservation?.reservationStatusKey === 'cancellation' ||
      !assignment.selectedReservation?.active
    )
  }

  handleExpandPanel(isExpanded: number): void {
    if (isExpanded !== undefined) {
      setTimeout(
        () =>
          EventBus.$emit('reset-scroll', {
            offset: 9999,
            id: 'assignment-sidebar-scroll-container',
          }),
        300
      )
    }
  }
}
