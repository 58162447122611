
import { Vue, Component } from 'vue-property-decorator'
import { AssignedVehicle } from '@/models/dto'
import AssignmentConflictsSidebar from '@/components/AssignmentConflictsSidebar.vue'
import sidebar from '@/store/modules/sidebar'
import assignment from '@/store/modules/assignment'
import { pluralize } from '@/utils/string'
import AssignmentsSelectStack from '@/components/AssignmentsSelectStack.vue'
import charterupClient from '@/services/charterup'
import { EventBus } from '@/utils/eventBus'
import messages from '@/data/messages'
import HoldUpModal from '@/components/HoldUpModal.vue'
import AssignmentsSidebarHeader from '@/components/AssignmentsSidebarHeader.vue'
import AssignmentsSidebarDriverNotifications from '@/components/AssignmentsSidebarDriverNotifications.vue'

@Component({
  components: {
    AssignmentsSidebarHeader,
    AssignmentsSidebarDriverNotifications,
    AssignmentsSelectStack,
    AssignmentConflictsSidebar,
    HoldUpModal,
  },
})
export default class AssignmentsSidebar extends Vue {
  submitting = false
  submittingWithDrafts = false
  isSyncDataModalOpen = false
  isFailedSyncModalOpen = false
  isSyncing = false

  assignment = assignment
  sidebar = sidebar
  pluralize = pluralize

  get loading(): boolean {
    return !assignment.getSelectedAssignments?.length
  }

  get draftExists(): boolean {
    const existsVehicleDraft = assignment.getSelectedAssignments.some(
      (v) => v.id && !v.isConfirmed
    )
    const existsDriverDraft = assignment.getSelectedAssignments
      .flatMap((v) => v.drivers)
      .some((d) => d.id && !d.isConfirmed)
    return existsVehicleDraft || existsDriverDraft
  }

  get useResDetailView(): boolean {
    return assignment.getIsNonDefaultView
  }

  get reservationCustomerAccountName(): string {
    return assignment.getSelectedReservation?.customerAccountId
      ? assignment.getSelectedTrip?.customer?.customerAccount?.name
      : null
  }

  get passengerCount(): number {
    return assignment.getSelectedReservation?.passengerCount
  }

  get vehicleAssignments(): AssignedVehicle[] {
    return assignment.getSelectedAssignments
  }

  close(saveDrafts = false): void {
    if (saveDrafts) {
      this.save()
    } else {
      if (!assignment.getIsNonDefaultView) {
        assignment.clearSelected()
        this.$router.replace({ hash: '' })
      } else {
        assignment.refresh()
      }
      sidebar.pop()
    }

    this.$emit('close')
  }

  async save(confirmAll = false, skipReferralSync = false): Promise<void> {
    if (assignment.isReferral) {
      if (
        assignment.getSelectedAssignments.some(
          (vehicle) =>
            vehicle.isMissingCharterUpId ||
            vehicle.drivers.some((driver) => driver.isMissingCharterUpId)
        )
      ) {
        this.isSyncDataModalOpen = true
        return
      }
    }

    if (confirmAll) {
      assignment.getSelectedAssignments.map((vehicle) => {
        if (vehicle.id && !vehicle.isConfirmed) {
          assignment.setVehicleStatus({ id: vehicle.id, isConfirmed: true })
        }
        vehicle.drivers.map((driver, idx) => {
          if (driver.id && !driver.isConfirmed) {
            assignment.setDriverStatus({
              id: driver.id,
              idx,
              isConfirmed: true,
            })
          }
        })
      })
    }

    const hasDriverConflicts = await assignment.checkForDriverConflicts()
    if (hasDriverConflicts) {
      return
    }

    const hasAssignmentConflicts = await assignment.updateConflicts()
    if (hasAssignmentConflicts) {
      sidebar.push({
        component: AssignmentConflictsSidebar,
        on: {
          back: () => sidebar.pop(),
          close: () => {
            sidebar.pop()
            this.close()
          },
          submit: () => this.handleSaveAssignments(skipReferralSync),
        },
      })
      return
    }

    if (confirmAll || !this.draftExists) {
      this.submitting = true
    } else {
      this.submittingWithDrafts = true
    }
    this.handleSaveAssignments(skipReferralSync)
  }

  async handleSaveAssignments(skipReferralSync = false): Promise<void> {
    await assignment.saveSelected(skipReferralSync)
    if (this.$router.currentRoute.hash !== '') {
      this.$router.replace({ hash: '' })
    }
    EventBus.$emit('dispatch:refresh')
  }

  handleReferralFailedAssignment(): void {
    this.isFailedSyncModalOpen = true
  }

  async handleSyncData(): Promise<void> {
    this.isSyncing = true
    try {
      const res = await charterupClient.sync({
        vehicles: true,
        drivers: true,
        garages: true,
      })
      if (!res.data.data.vehicles.success || !res.data.data.drivers.success) {
        EventBus.$emit('snackbar:error', messages.partial.charterUpSync)
      } else {
        EventBus.$emit('snackbar:success', messages.success.charterUpSync)
        this.closeModal()
      }
      assignment.clearVehiclesAndDrivers()
      await assignment.refresh()
    } catch (err) {
      EventBus.$emit('snackbar:error', messages.errors.charterUpSync)
    }
    this.isSyncDataModalOpen = false
    this.isSyncing = false
  }

  closeModal(): void {
    this.isSyncDataModalOpen = false
    this.isFailedSyncModalOpen = false
    this.submitting = false
    this.submittingWithDrafts = false
    this.isSyncing = false
  }

  created(): void {
    assignment.fetchVehicleTimeOff()
  }

  mounted(): void {
    EventBus.$on(
      'referrals:failed-assignment',
      this.handleReferralFailedAssignment
    )
  }
}
