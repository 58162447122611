export type VehicleType = {
  id: number
  key: string
  label?: string
  vehicleTypeId?: number
  description?: string
  active?: boolean
  companyId?: number | null
}

export type SupportedVehicleType = {
  vehicleTypeId: number
  supported?: boolean
  label?: string
}

export interface Vehicle {
  imagePath: string | null
  tripVehicleId: number
  tripId: number
  vehicleTypeId: number
  vehicleId: number
  quantity: number
  licensePlate?: string
  vehicleName?: string
  vehicleModel?: string
  vehicleMake?: string
  vehicleType: VehicleType
  vehicleTypeKey: string
  dummy: boolean
  isInactiveFromTimeOff?: boolean
}

// eslint-disable-next-line no-redeclare
export class Vehicle implements Vehicle {
  constructor(vehicle?: Partial<Vehicle>) {
    this.quantity = 1
    this.imagePath = null
    this.tripVehicleId = null
    this.tripId = null
    this.vehicleTypeId = null
    this.vehicleId = null
    this.vehicleType = null
    this.vehicleTypeKey = null
    this.dummy = false
    this.isInactiveFromTimeOff = false
    Object.assign(this, vehicle)
  }
}

export interface RequiredVehicleType {
  quantity: number
  vehicleId: number | null
  vehicleType: VehicleType
}
