
import colors from '@/scss/_colors-export.scss'
import auth from '@/store/modules/auth'
import { currencyFilter } from '@/utils/string'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class CUCurrencyRangePicker extends Vue {
  @Prop({ required: false, default: () => [] }) value: number[]
  @Prop({ required: false, default: '0px' }) nudgeLeft!: string
  @Prop({ required: false, default: '0px' }) nudgeBottom!: string
  @Prop({ type: Boolean, default: undefined }) attach!: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean

  open = false
  isHovered = false
  min = null
  max = null
  minRange = 0
  maxRange = Math.ceil((auth?.getCompany?.maxResBalance || 10000) / 25) * 25
  textFieldValue = ''
  range = [this.minRange, this.maxRange]
  debounce = null

  colors = colors

  @Watch('value', { immediate: true })
  handleUpdateValue(): void {
    this.min = this.toFormattedString(this.value?.[0])
    this.max = this.toFormattedString(this.value?.[1])
    this.range = [
      this.value?.[0] || this.minRange,
      this.value?.[1] || this.maxRange,
    ]
    this.setTextFieldValue()
  }

  get displayClearIcon(): boolean {
    return this.isHovered && this.textFieldValue && !this.disabled
  }

  get menuLeftOffset(): string {
    if (this.nudgeLeft) {
      return this.nudgeLeft
    }
    return this.attach ? '0px' : '40px'
  }

  handleRangeInput(range: number[]): void {
    this.min =
      range[0] === this.minRange ? null : this.toFormattedString(range[0])
    this.max =
      range[1] === this.maxRange ? null : this.toFormattedString(range[1])
  }

  handleCurrencyFieldBlur(key: string): void {
    this[key] = this.toFormattedString(this[key])
    this.$set(
      this.range,
      key === 'min' ? 0 : 1,
      this[key] ? this.toNumber(this[key]) : this[`${key}Range`]
    )

    if (
      this.min &&
      this.max &&
      this.toNumber(this.min) > this.toNumber(this.max)
    ) {
      ;[this.min, this.max] = [this.max, this.min]
    }
  }

  toNumber(val: string): number {
    if (!val) {
      return null
    }
    return parseFloat(val.replace(/[^0-9.]/g, ''))
  }

  toFormattedString(val: number): string {
    if (!val) {
      return null
    }
    return currencyFilter(parseFloat(val.toString().replace(/[^\d.-]/g, '')))
  }

  setTextFieldValue(): void {
    if (this.min && this.max) {
      this.textFieldValue = `${this.min} - ${this.max}`
    } else if (this.min) {
      this.textFieldValue = `Min: ${this.min}`
    } else if (this.max) {
      this.textFieldValue = `Max: ${this.max}`
    } else {
      this.textFieldValue = ''
    }
  }

  cancel(): void {
    this.open = false
  }

  done(): void {
    this.setTextFieldValue()
    if (this.min || this.max) {
      this.$emit('input', [this.toNumber(this.min), this.toNumber(this.max)])
    }
    this.open = false
  }

  clear(): void {
    this.$emit('input', null)
  }
}
