import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import {
  CustomerAccount,
  CustomerAccountDetailEntity,
  CustomerAccountRequest,
} from '@/models/dto/CustomerAccount'
import { NotificationsSetting } from '@/models/NotificationsSetting'

const httpService: HttpService = new HttpService()

export default {
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<CustomerAccount>>> {
    const { sorts, filters, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }

    query = encodeURI(query)
    return httpService.get(
      `https://${apiBaseUrl()}/tables/customer-accounts?${query}`
    )
  },
  update(
    accountId: number,
    customerAccount: CustomerAccountRequest
  ): Promise<AxiosResponse<number>> {
    return httpService.patch(
      `https://${apiBaseUrl()}/customerAccounts/${accountId}`,
      customerAccount
    )
  },
  byId(
    accountId: number,
    options: { slim?: boolean } = { slim: false }
  ): Promise<AxiosResponse<CustomerAccountDetailEntity>> {
    const url = `https://${apiBaseUrl()}/customerAccounts/${accountId}?slim=${
      options.slim
    }`
    return httpService.get(url)
  },
  create(
    customerAccount: CustomerAccountRequest
  ): Promise<AxiosResponse<number>> {
    return httpService.post(
      `https://${apiBaseUrl()}/customerAccounts`,
      customerAccount
    )
  },
  delete(id: number): Promise<AxiosResponse<void>> {
    return httpService.delete(`https://${apiBaseUrl()}/customerAccounts/${id}`)
  },
  updateNotificationsSetting(
    customerAccountId: number,
    notificationsSetting: NotificationsSetting
  ): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/notifications/customerAccounts/${customerAccountId}`
    return httpService.patch(url, { ...notificationsSetting })
  },
}
