
import { Vue, Component, Prop } from 'vue-property-decorator'
import dayjs from 'dayjs'
import { states } from '@/utils/states'
import CUFileUpload from './CUFileUpload.vue'
import { isDateFormat } from '@/utils/validators'
import { UserDetailDriver } from '@/models/dto/UserDetailDriver'
import { UploadedFile } from '@/models/dto/UploadedFile'

@Component({
  components: {
    CUFileUpload,
  },
})
export default class DriverInfoCertificationsTab extends Vue {
  @Prop({ required: true }) driver: UserDetailDriver

  dayjs = dayjs
  states = states
  isDateFormat = isDateFormat

  driverDocumentsToAdd: File[] = []
  driverDocumentIdsToDelete: number[] = []

  get licenseExpiration(): string {
    // Use the 1st of the month as a default value for day if it's not provided
    const year = this.driver.licenseExpirationYear
    const month = this.driver.licenseExpirationMonth
      ? this.driver.licenseExpirationMonth - 1
      : null
    const day = this.driver.licenseExpirationDay || 1

    if (year && month != null) {
      return dayjs(new Date(year, month, day)).format('YYYY-MM-DD')
    }
    return null
  }

  get drugTestExpiration(): string {
    const { drugTestExpiration } = this.driver
    return drugTestExpiration || ''
  }

  get medicalCardExpiration(): string {
    const { medicalCardExpiration } = this.driver
    return medicalCardExpiration || ''
  }

  get firstAidCardExpiration(): string {
    const { firstAidCardExpiration } = this.driver
    return firstAidCardExpiration || ''
  }

  get currentExistingDriverDocuments(): UploadedFile[] {
    const uploadedFiles = []
    if (this.driver.driverDocuments && this.driver.driverDocuments.length) {
      uploadedFiles.push(
        ...this.driver.driverDocuments.filter(
          (document) => !this.driverDocumentIdsToDelete.includes(document.id)
        )
      )
    }
    return uploadedFiles
  }

  handleUploadDocument(file: File): void {
    this.driverDocumentsToAdd.push(file)
    this.$emit('documents:add', this.driverDocumentsToAdd)
  }

  handleDeleteNewDocument(index: number): void {
    this.driverDocumentsToAdd.splice(index, 1)
    this.$emit('documents:add', this.driverDocumentsToAdd)
  }

  handleDeleteExistingDocument(id: number): void {
    this.driverDocumentIdsToDelete.push(id)
    this.$emit('documents:delete', this.driverDocumentIdsToDelete)
  }

  updateLicenseExpiration(input: string): void {
    if (!input) {
      this.$emit('update', {
        licenseExpirationDay: null,
        licenseExpirationMonth: null,
        licenseExpirationYear: null,
      })
      return
    } else if (input.length < 5) {
      return
    }
    const [year, month, day] = input.split('-')
    this.$emit('update', {
      licenseExpirationDay: Number(day),
      licenseExpirationMonth: Number(month),
      licenseExpirationYear: Number(year),
    })
  }

  handleDrugTestExpirationInput(drugTestExpiration: string): void {
    this.$emit('update', { drugTestExpiration })
  }

  handleMedicalCardExpirationInput(medicalCardExpiration: string): void {
    this.$emit('update', { medicalCardExpiration })
  }

  handleFirstAidCardExpirationInput(firstAidCardExpiration: string): void {
    this.$emit('update', { firstAidCardExpiration })
  }
}
