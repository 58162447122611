
import sidebar from '@/store/modules/sidebar'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { validateRules, validationRules } from '@/utils/rules'
import { AxiosResponse } from 'axios'
import companyClient from '@/services/customerAccount'
import industryClient from '@/services/industries'
import { ContactRequest } from '@/models/dto/Customer'
import AutocompleteAddress from '@/components/AutocompleteAddress.vue'
import { Address } from '@/models/dto'
import { states } from '@/utils/states'
import { formatAddressName } from '@/utils/string'
import auth from '@/store/modules/auth'
import contactClient from '@/services/customer'
import { hasRequiredAddressFields } from '@/utils/validators'
import { EventBus } from '@/utils/eventBus'
import { TableViewResult } from '@/models/TableView'
import { CustomerAccount } from '@/models/dto/CustomerAccount'
import { Industry } from '@/models/dto/Industry'
import { parseExistingUserError } from '@/utils/error'
import CUQuickbooksCustomerSync from './CUQuickbooksCustomerSync.vue'
import quickbooksService from '@/services/quickbooks'
import { QuickbooksCustomerAssignRequest } from '@/models/dto/Quickbooks'

@Component({
  components: {
    AutocompleteAddress,
    CUQuickbooksCustomerSync,
  },
})
export default class CreateContactForm extends Vue {
  @Prop({ required: false, default: null }) company: {
    id: number
    name: string
  }
  @Prop({ default: false, type: Boolean }) readonly isTripContact: boolean
  @Prop({ type: Boolean, required: false }) submitting: boolean
  @Prop({ required: false, default: undefined })
  readonly prepopulateContact: Partial<ContactRequest>
  @Prop({ required: false, type: Boolean })
  readonly lockCompanyField!: boolean

  @Prop({ required: false, default: false }) displayBulkImport: boolean

  created(): void {
    this.load()
    if (auth.isQuickbooksIntegrationEnabled) {
      this.fetchQuickBooksContacts()
    }
  }
  rules = validationRules
  states = states
  auth = auth
  industries = []
  companies = []
  isLoadingCompaniesAndIndustries = false
  quickbooksContacts = []
  quickBooksSyncToggle = false
  quickBooksError = false

  @Watch('company', { immediate: true })
  handleNewId(): void {
    this.load()
  }

  get isCompanySelectDisabled(): boolean {
    return (
      !!this.prepopulateContact?.customerAccountId ||
      !!this.company?.id ||
      this.lockCompanyField
    )
  }

  contact: Partial<ContactRequest> = {
    firstName: '',
    lastName: '',
    email: '',
    address: new Address(),
    phone: '',
    phoneExtension: '',
    title: '',
    customerAccountId: null,
    owner: '',
    industryId: null,
  }
  quickbooksCustomerId = null
  createNewQuickbooksCustomer = false
  errors = {
    city: null,
    state: null,
    zip: null,
    email: null,
  }

  handleChangeAddressInput(input: string): void {
    this.contact.address = { ...this.contact.address, street1: input }
  }

  handleChangeAddress(address: Address): void {
    this.errors = {
      ...this.errors,
      city: null,
      state: null,
      zip: null,
    }
    if (address) {
      this.contact = { ...this.contact, address: { ...address } }
    } else {
      this.contact.address.title = null
    }
  }

  handleQuickBooksSyncToggle(value: boolean): void {
    this.quickBooksSyncToggle = value
  }

  handleQuickbooksSync({
    sync,
    contactId,
    createNewCustomer = false,
  }: {
    sync: boolean
    contactId: string
    createNewCustomer?: boolean
  }): void {
    this.quickBooksError = false
    if (sync) {
      if (createNewCustomer) {
        this.createNewQuickbooksCustomer = true
        this.quickbooksCustomerId = null
        return
      }
      this.createNewQuickbooksCustomer = false
      this.quickbooksCustomerId = contactId
      return
    }
    this.createNewQuickbooksCustomer = false
    this.quickbooksCustomerId = null
  }

  handlePhoneChange(data: { phone: string; country: string }): void {
    this.contact.phone = data.phone
    this.contact.phoneCountryKey = data.country
  }

  clear(): void {
    sidebar.pop()
  }

  async submit(): Promise<void> {
    this.errors = {
      ...this.errors,
      city: null,
      state: null,
      zip: null,
    }

    let customerAccountName = null
    if (this.contact.customerAccountId) {
      customerAccountName = this.companies.find(
        ({ value }) => value === this.contact.customerAccountId
      )?.text
    }
    const newContact: ContactRequest = {
      active: true,
      address: this.contact.address,
      companyId: this.auth.user.companyId,
      customerAccountId: this.contact.customerAccountId,
      customerAccountName,
      email: this.contact.email,
      title: this.contact.title,
      firstName: this.contact.firstName,
      industryDescription: null,
      industryId: this.contact.industryId,
      lastName: this.contact.lastName,
      phone: (this.contact.phone || '').replace(/\D+/g, ''),
      phoneExtension: this.contact.phoneExtension,
      phoneCountryKey: this.contact.phoneCountryKey,
    }

    if (newContact.address?.addressName) {
      newContact.address.addressName = formatAddressName(newContact.address)
    }
    if (
      Object.keys(newContact.address).length === 0 ||
      Object.values(newContact.address).every((el) => !el)
    ) {
      newContact.address = null
    }
    const isValidAddress = hasRequiredAddressFields(newContact.address)
    if (!(await validateRules(this)) || !isValidAddress) {
      if (!isValidAddress) {
        this.errors = {
          ...this.errors,
          city: !newContact.address?.city ? 'Required to save address' : null,
          state: !newContact.address?.state ? 'Required to save address' : null,
          zip: !newContact.address?.postalCode
            ? 'Required to save address'
            : null,
        }
      }
      return
    }
    if (
      !this.isTripContact &&
      this.quickBooksSyncToggle &&
      !this.quickbooksCustomerId &&
      !this.createNewQuickbooksCustomer
    ) {
      this.quickBooksError = true
      return
    }

    try {
      const {
        data: { customer },
      } = await contactClient.create(newContact)
      if (this.createNewQuickbooksCustomer) {
        try {
          const response =
            await quickbooksService.createQuickbooksCustomerWithBusifyContactId(
              auth.getCompanyId,
              customer.userId,
              true
            )
          this.quickbooksCustomerId =
            response?.data?.customerSync?.quickbooksCustomerId || null
          this.createNewQuickbooksCustomer = false
          this.fetchQuickBooksContacts()
        } catch (e) {
          console.log(e)
          EventBus.$emit(
            'snackbar:error',
            'Unable to create new Quickbooks Customer'
          )
        }
      } else if (this.quickbooksCustomerId) {
        try {
          const request: QuickbooksCustomerAssignRequest = {
            busifyId: customer.userId,
            qboId: this.quickbooksCustomerId,
          }
          await quickbooksService.assignQuickbooksCustomer(
            auth.getCompanyId,
            request
          )
        } catch (e) {
          console.log(e)
          EventBus.$emit(
            'snackbar:error',
            'Unable to connect contact to Quickbooks Customer'
          )
        }
      }
      customer.customerId = customer.userId
      this.$emit('contact:created', customer)
      EventBus.$emit('snackbar:success', 'Contact created successfully')
      sidebar.pop()
    } catch (err: any) {
      const errorMessage = err.response.data.message
      if (errorMessage.toLowerCase().includes('email')) {
        this.errors.email = parseExistingUserError(errorMessage)
      } else {
        EventBus.$emit('snackbar:error', errorMessage)
      }
    }
  }

  async load(): Promise<void> {
    this.isLoadingCompaniesAndIndustries = true
    let industryPromise: Promise<AxiosResponse<TableViewResult<Industry>>>
    let companyPromise: Promise<
      AxiosResponse<TableViewResult<CustomerAccount> | undefined>
    >

    try {
      industryPromise = industryClient.tableView({
        pageSize: -1,
        page: 1,
      })

      if (
        this.prepopulateContact?.customerAccountId &&
        this.prepopulateContact?.customerAccountName
      ) {
        this.companies = [
          {
            text: this.prepopulateContact.customerAccountName,
            value: this.prepopulateContact.customerAccountId,
          },
        ]
      } else if (this.company) {
        this.companies = [
          {
            text: this.company.name,
            value: this.company.id,
          },
        ]
        this.contact.customerAccountId = this.company.id
      } else {
        companyPromise = companyClient.tableView({
          pageSize: -1,
          page: 1,
        })
      }

      const [industryResponse, companyResponse] = await Promise.all([
        industryPromise,
        companyPromise,
      ])

      this.industries = [
        ...industryResponse.data.resultList.map((el) => {
          return { text: el.label, value: el.industryId }
        }),
      ]

      if (companyResponse) {
        this.companies = [
          ...companyResponse.data.resultList.map((company) => {
            return { text: company.name, value: company.customerAccountId }
          }),
        ]
      }

      Object.assign(this.contact, this.prepopulateContact)
    } catch (err: any) {
      console.debug(err)
    } finally {
      this.isLoadingCompaniesAndIndustries = false
    }
  }

  async fetchQuickBooksContacts(): Promise<void> {
    try {
      const response = await quickbooksService.getCompanyQuickbooksCustomers(
        auth.getCompanyId
      )
      const customers = response.data.customers
      this.quickbooksContacts = [
        ...customers.map((contact) => {
          return {
            text: `${contact.name} (ID: ${contact.id})`,
            value: contact.id,
          }
        }),
      ]
    } catch (e) {
      console.log(e)
    }
  }

  mounted(): void {
    if (auth.isQuickBooksAskToSyncWhenCreateNewContact) {
      this.quickBooksSyncToggle = true
    }
  }
}
