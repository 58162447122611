
import DateMixin from '@/mixins/DateMixin'
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { numberToPixels } from '@/utils/style'
import auth from '@/store/modules/auth'
import { predefinedFutureDate, predefinedPastDate } from '@/utils/datePicker'

@Component({})
export default class CUDatePickerInput extends mixins(DateMixin) {
  @Prop({ required: false, type: Boolean }) disabled!: boolean
  @Prop({ required: false, type: Boolean }) readonly!: boolean
  @Prop({ required: false, type: Boolean }) clearable!: boolean
  @Prop({ required: false, type: Boolean }) hideIcon!: boolean
  @Prop({ required: false, type: Boolean }) simple!: boolean
  @Prop({ required: false, type: Boolean }) expanded!: boolean
  @Prop({ required: false, type: Boolean }) isRange!: boolean

  // If true, always display the placeholder and a label
  @Prop({ required: false, type: Boolean }) persistentLabel!: boolean
  @Prop({ required: false, type: Boolean }) persistentBorder!: boolean
  @Prop({ required: false, type: Boolean }) boldValue!: boolean
  @Prop({ required: false, type: Boolean }) error!: boolean
  @Prop({ required: false, default: undefined }) errorMessages!: string[]

  @Prop({ required: false, default: undefined }) predefinedValue!: string
  @Prop({ required: false }) width!: number | string
  @Prop({ required: false, default: 'primary' }) color!: string
  @Prop({ required: false, default: undefined }) label!: string
  @Prop({ required: false, type: Object }) activatorListeners!: Record<
    string,
    any
  >

  @Prop({ required: false, default: '' }) value!: string | string[]

  isHovered = false

  get formattedDisplayDate(): string {
    if (!this.value?.length) {
      return ''
    }

    if (this.predefinedValue && this.isRange) {
      switch (this.predefinedValue) {
        case 'future':
          return 'In the Future'
        case 'past':
          return 'In the Past'
        default:
          break
      }
    }

    if (Array.isArray(this.value) && this.value[0] === predefinedPastDate) {
      return 'In the Past'
    } else if (
      Array.isArray(this.value) &&
      this.value[1] === predefinedFutureDate
    ) {
      return 'In the Future'
    }

    if (Array.isArray(this.value) && this.value[0] === this.value[1]) {
      return this.formatShortDate(this.value[0])
    }

    if (Array.isArray(this.value)) {
      return this.value
        .filter((d) => !!d)
        .map((date) => this.formatShortDate(date))
        .join(' - ')
    }

    return this.formatShortDate(this.value)
  }

  get isDisabled(): boolean {
    return this.disabled
  }

  get isReadonly(): boolean {
    return this.readonly || Array.isArray(this.value) || this.isRange
  }

  get isEmpty(): boolean {
    if (Array.isArray(this.value)) {
      return this.value.filter((d) => !!d).length === 0
    }

    return !this.value?.length
  }

  get inputStyle(): Record<string, string> {
    let style = {}

    if (this.width) {
      const width = numberToPixels(this.width)
      style = {
        ...style,
        width,
        maxWidth: width,
      }
    }

    return style
  }

  get placeholder(): string {
    if (this.$attrs.placeholder) {
      return this.$attrs.placeholder
    }
    if (this.label && !this.persistentLabel) {
      return this.label
    }
    return auth.shouldDisplayDayLeading ? 'DD/MM/YY' : 'MM/DD/YY'
  }

  get inputLabel(): string {
    if (!this.label) {
      return null
    }

    if (this.simple) {
      return null
    }

    if (this.persistentLabel) {
      return this.label
    }

    if (this.value?.length) {
      return this.label
    }

    if (Array.isArray(this.value)) {
      if (this.value.filter((d) => !!d).length) {
        return this.label
      }
    } else if (this.value?.length) {
      return this.label
    }

    return null
  }

  get shouldHideIcon(): boolean {
    if (this.hideIcon) {
      return true
    }

    if (this.expanded && !!this.inputLabel) {
      return true
    }

    return false
  }

  handleInput(e: string): void {
    const dateRegexPattern = /^(\d{1,2}\/\d{1,2}\/\d{2,4})$/
    if (!e || !dateRegexPattern.test(e)) {
      return
    }

    const parsedDate = this.formatInputToISODate(e)
    this.$emit('input', parsedDate)
  }

  handleChange(e: string): void {
    if (!e) {
      this.$emit('input', '')
      this.$emit('change', '')
      return
    }
    const dateRegexPattern = /^(\d{1,2}\/\d{1,2}\/\d{2,4})$/
    if (!dateRegexPattern.test(e)) {
      this.$emit('change', this.value)
      const input: any = this.$refs['input']
      input.$refs['v-text-field'].lazyValue = this.formattedDisplayDate
      return
    }
    const parsedDate = this.formatInputToISODate(e)
    this.$emit('change', parsedDate)
  }

  handleClear(): void {
    if (this.isRange) {
      this.$emit('input', [])
      this.$emit('change', [])
    } else {
      this.$emit('input', '')
      this.$emit('change', '')
    }
    this.$emit('clear')

    const input: any = this.$refs.input
    input.$refs['v-text-field'].blur()
  }
}
