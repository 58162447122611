var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"position-relative d-flex align-center justify-center rounded-pill",class:{
      'dashed-border': !_vm.isAssigned,
      'background-secondary': _vm.isAssigned && _vm.isConfirmed,
      'solid-border': _vm.isAssigned && !_vm.isConfirmed,
      'h-36 w-36': !_vm.small,
      'h-28 w-28': _vm.small,
    }},[(_vm.isVehicle)?[(!_vm.isAssigned)?_c('CUIcon',{attrs:{"color":"gray-icon-light","width":_vm.iconWidth}},[_vm._v(" bus_plain ")]):(_vm.isConfirmed)?_c('CUIcon',{attrs:{"color":"white","width":_vm.iconWidth}},[_vm._v(" bus ")]):_c('CUIcon',{attrs:{"color":"gray-icon-dark","width":_vm.iconWidth}},[_vm._v(" bus_plain ")])]:[(!_vm.isAssigned || !_vm.driverInitials)?_c('CUIcon',{attrs:{"color":"gray-icon-light","width":_vm.iconWidth}},[_vm._v(" driver ")]):_vm._e(),_c('p',{staticClass:"font-bold",class:{
          'text-white': _vm.isConfirmed,
          'text-gray-icon-dark': !_vm.isConfirmed,
          'font-14': !_vm.small,
          'font-12': _vm.small,
        },style:({ lineHeight: '11px' })},[_vm._v(" "+_vm._s(_vm.driverInitials)+" ")])],(_vm.isConfirmed && _vm.hasConflict)?_c('div',{staticClass:"position-absolute d-flex align-center justify-center rounded-pill background-white border-1 border-solid border-white",style:({
        top: '-6px',
        right: '-6px',
        height: `${_vm.warningSize + 4}px`,
        width: `${_vm.warningSize + 4}px`,
      })},[_c('CUIcon',{attrs:{"color":"red-error","width":`${_vm.warningSize}px`}},[_vm._v("warning")])],1):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }