
import { TripNoteTypes } from '@/utils/enum'
import { Component, Prop, Vue } from 'vue-property-decorator'
import ReservationStore from '@/store/modules/reservation'
import CUExpansionPanel from '@/components/CUExpansionPanel.vue'
import sidebar from '@/store/modules/sidebar'
import ReservationNotesSidebar from './ReservationNotesSidebar.vue'
import { Reservation, TripNote } from '@/models/dto'
import deepClone from '@/utils/deepClone'
import tripClient from '@/services/trip'

@Component({
  components: { CUExpansionPanel },
})
export default class ReservationDetailPanelNotes extends Vue {
  @Prop({ type: Boolean, default: false }) expandable: boolean

  types = TripNoteTypes
  isModeEdit = false

  get reservation(): Reservation {
    return ReservationStore.reservation
  }

  get notes(): any[] {
    return this.reservation.tripNotesList
  }

  get tripId(): number {
    return this.reservation.tripId
  }

  getNote(type: number): TripNote {
    return this.notes.find((note) => note.noteType === type) || new TripNote()
  }

  get tripNote(): string {
    const note = this.getNote(this.types.TRIP_NOTE_TYPE)
    if (note?.note?.length) {
      return note.html || note.note
    }
    return '--'
  }

  get internalNote(): string {
    const note = this.getNote(this.types.INTERNAL_NOTE_TYPE)
    if (note?.note?.length) {
      return note.html || note.note
    }
    return '--'
  }

  get driverNote(): string {
    const note = this.getNote(this.types.DRIVER_NOTE_TYPE)
    if (note?.note?.length) {
      return note.html || note.note
    }
    return '--'
  }

  async handleNoteChange(e: {
    trip: TripNote
    internal: TripNote
    driver: TripNote
  }): Promise<void> {
    let notes = deepClone(this.notes)
    if (e.trip.html) {
      notes = this.setTripNote(
        e.trip.html,
        e.trip.note,
        this.types.TRIP_NOTE_TYPE,
        notes
      )
    }
    if (e.internal.html) {
      notes = this.setTripNote(
        e.internal.html,
        e.internal.note,
        this.types.INTERNAL_NOTE_TYPE,
        notes
      )
    }
    if (e.driver.html) {
      notes = this.setTripNote(
        e.driver.html,
        e.driver.note,
        this.types.DRIVER_NOTE_TYPE,
        notes
      )
    }
    try {
      await tripClient.editTripNotes(this.tripId, {
        tripNotes: notes,
      })
      ReservationStore.setTrip({ ...ReservationStore.trip, tripNotes: notes })

      this.$emit('reservation:refresh')
      if (!this.expandable) {
        sidebar.pop()
      }
    } catch (error) {
      console.log(error)
    }
  }

  setTripNote(
    html: string,
    text: string,
    type: number,
    notes: TripNote[]
  ): TripNote[] {
    const noteIdx = notes.findIndex((note) => note.noteType === type)
    const tripNote: TripNote = notes[noteIdx]

    if (tripNote) {
      const updatedNote = { ...tripNote, html, note: text }
      const tripNotes = [...notes]
      tripNotes.splice(noteIdx, 1, updatedNote)
      notes = tripNotes
    } else {
      const updatedNote = {
        noteId: null,
        noteType: type,
        note: text,
        html,
        createdOn: null,
        createdBy: null,
        active: null,
      }
      const tripNotes = [...notes, updatedNote]
      notes = tripNotes
    }
    return notes
  }

  handleEditNotes(): void {
    sidebar.push({
      title: 'Notes',
      component: ReservationNotesSidebar,
      props: {
        tripNotes: this.getNote(this.types.TRIP_NOTE_TYPE),
        internalNotes: this.getNote(this.types.INTERNAL_NOTE_TYPE),
        driverNotes: this.getNote(this.types.DRIVER_NOTE_TYPE),
      },
      on: {
        'note:change': this.handleNoteChange,
      },
      width: 400,
    })
  }
}
