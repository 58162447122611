import { Priority } from '@/models/dto/Priority'
import { Status } from '@/models/dto/Status'

export const reservationStatusTypes: Status[] = [
  { id: 1, key: 'upcoming', label: 'New' },
  { id: 6, key: 'confirmed', label: 'Confirmed' },
  { id: 8, key: 'printed', label: 'Printed' },
  { id: 2, key: 'started', label: 'In Progress' },
  { id: 3, key: 'finished', label: 'Completed' },
  { id: 7, key: 'closed', label: 'Closed' },
  { id: 4, key: 'cancelled', label: 'Cancelled' },
  { id: 5, key: 'hold', label: 'On Hold' },
]

export const quoteStatusTypes: Status[] = [
  { id: 1, key: 'lead', label: 'Lead', description: '' },
  { id: 2, key: 'sent', label: 'Sent', description: '' },
  { id: 3, key: 'converted', label: 'Converted', description: '' },
  { id: 4, key: 'lost', label: 'Lost', description: '' },
  { id: 5, key: 'dont_quote', label: "Don't Quote", description: '' },
  { id: 6, key: 'sold_out', label: 'Sold Out', description: '' },
]

export const quotePriorityTypes: Priority[] = [
  { id: 1, key: '', label: 'None' },
  { id: 2, key: 'low', label: 'Low', description: '' },
  { id: 3, key: 'medium', label: 'Medium', description: '' },
  { id: 4, key: 'high', label: 'High', description: '' },
]

export const socialLinkTypes: {
  typeKey: string
  socialMediaTypeId: number
}[] = [
  { typeKey: 'facebook', socialMediaTypeId: 1 },
  { typeKey: 'instagram', socialMediaTypeId: 2 },
  { typeKey: 'twitter', socialMediaTypeId: 3 },
  { typeKey: 'linkedin', socialMediaTypeId: 4 },
]
