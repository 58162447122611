import { Action, Module, VuexModule } from 'vuex-class-modules'
import store from '@/store'
import { Vehicle, VehicleType } from '@/models/dto/Vehicle'
import typeClient from '@/services/type'
import vehicleClient from '@/services/vehicle'
import garageClient from '@/services/garage'
import { Type } from '@/models/dto/Type'
import { TripChargeType, TripType } from '@/models/dto/Trip'
import { TypeLoadingInformation } from '@/models/Type'
import { distinctBy } from '@/utils/reducers'
import { Garage } from '@/models/dto/Garage'

@Module({ generateMutationSetters: true })
class TypesModule extends VuexModule {
  _vehicleTypes: VehicleType[] = []

  _tripChargeTypes: TripChargeType[] = []
  _pricingSelectionTypes: Type[] = []
  _tripTypes: Record<string, TripType> = {}
  _vehicles: Vehicle[] = []
  _garages: Garage[] = []
  _loadingInformation: TypeLoadingInformation = {
    isLoadingTripTypes: true,
    isLoadingVehicleTypes: true,
    isLoadingVehicles: true,
    isLoadingGarages: true,
  }

  get getVehicleTypes(): VehicleType[] {
    return this._vehicleTypes
  }

  get pricingSelectionTypes() {
    return this._pricingSelectionTypes
  }

  get tripChargeTypes() {
    return this._tripChargeTypes
  }

  get tripTypes(): Record<string, TripType> {
    return this._tripTypes
  }

  get loadingInformation(): TypeLoadingInformation {
    return this._loadingInformation
  }

  get vehicles(): Vehicle[] {
    return this._vehicles
  }

  get garages(): Garage[] {
    return this._garages
  }

  @Action
  async loadTripTypes(): Promise<void> {
    try {
      const tripTypesResponse = await typeClient.tripTypes()
      this._tripTypes = tripTypesResponse.data.reduce(distinctBy('id'), {})
    } catch (e) {
      console.log(e)
    } finally {
      this.setLoadingInformation({
        isLoadingTripTypes: false,
      })
    }
  }

  @Action
  async loadVehicleTypes(): Promise<void> {
    try {
      const vehicleTypesResponse = await typeClient.vehicleTypes()
      this._vehicleTypes = vehicleTypesResponse.data
    } catch (e) {
      console.log(e)
    } finally {
      this.setLoadingInformation({
        isLoadingVehicleTypes: false,
      })
    }
  }

  @Action
  async loadVehicles(): Promise<void> {
    try {
      const vehiclesResponse = await vehicleClient.tableView({
        page: 1,
        pageSize: -1,
      })
      this._vehicles = vehiclesResponse.data.resultList
    } catch (e) {
      console.log(e)
    } finally {
      this.setLoadingInformation({
        isLoadingVehicles: false,
      })
    }
  }

  @Action
  async loadGarages(): Promise<void> {
    try {
      const garageResponse = await garageClient.tableView({
        page: -1,
        pageSize: -1,
      })
      this._garages = garageResponse.data.resultList
    } catch (e) {
      console.log(e)
    } finally {
      this.setLoadingInformation({
        isLoadingGarages: false,
      })
    }
  }

  getAllTypes(): void {
    this.loadVehicleTypes()
    this.loadTripTypes()
    this.loadVehicles()
    this.loadGarages()
  }

  @Action
  setLoadingInformation(
    loadingInformation: Partial<TypeLoadingInformation>
  ): void {
    this._loadingInformation = {
      ...this._loadingInformation,
      ...loadingInformation,
    }
  }

  @Action
  async loadTripChargeTypes() {
    const res = await typeClient.tripChargeTypes()
    this._tripChargeTypes = res.data
  }

  @Action
  async loadPricingSelectorTypes() {
    const res = await typeClient.pricingSelectionTypes()
    this._pricingSelectionTypes = res.data
  }

  @Action
  async loadAllTypes(): Promise<void> {
    await Promise.all([
      this.loadVehicleTypes(),
      this.loadTripChargeTypes(),
      this.loadPricingSelectorTypes(),
    ])
  }
}
export default new TypesModule({ store, name: 'types' })
