
import { Component, Prop, Vue } from 'vue-property-decorator'
import CUCommentArea from '@/components/CUCommentArea.vue'

@Component({
  components: { CUCommentArea },
})
export default class ReservationDetailPanelDriverMessaging extends Vue {
  @Prop({ required: false, default: [] })
  readonly comments!: Comment[]
}
