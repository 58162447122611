import { Coordinates, DateInterval, DispatchBlock } from '@/models/dto/Dispatch'
import auth from '@/store/modules/auth'
import client from '@/services/dispatch'
import dayjs, { Dayjs } from 'dayjs'
import { buildModel } from './assignments'
export const DispatchViewOptions: Record<string, string> = {
  month: 'month',
  week: 'week',
  day: 'day',
  list: 'list',
}

export const getFormattedReservations = async (
  dispatchRequest: DateInterval
): Promise<any[]> => {
  const { data: dispatchData } = await client.getDispatchData(dispatchRequest)
  let { reservations } = dispatchData

  reservations = reservations.map((r) => {
    let startDatetime = dayjs(r.startDatetime)
    let endDatetime = dayjs(r.endDatetime)
    if (startDatetime.isAfter(endDatetime)) {
      const start = startDatetime
      startDatetime = endDatetime
      endDatetime = start
    }
    const durationInHours = endDatetime.diff(startDatetime, 'hours')
    const model = buildModel(
      r.vehicleAssignments,
      r.requiredVehicles,
      r.requiredDrivers
    )
    return {
      ...r,
      startDatetime: startDatetime.format(),
      endDatetime: endDatetime.format(),
      startDate: startDatetime.format('YYYY-MM-DD HH:mm:ss'),
      endDate: endDatetime.format('YYYY-MM-DD HH:mm:ss'),
      model,
      durationInHours,
    }
  })

  return reservations
}

export const doesBlockOverlapDates = (
  block: DispatchBlock,
  startDate: string | Dayjs | Date,
  endDate: string | Dayjs | Date,
  inclusive = true
): boolean => {
  if (typeof startDate === 'string' || startDate instanceof Date) {
    startDate = dayjs(startDate)
  }

  if (typeof endDate === 'string' || endDate instanceof Date) {
    endDate = dayjs(endDate)
  }

  const blockStart = dayjs(block.startDatetime)
  const blockEnd = dayjs(block.endDatetime)

  if (inclusive) {
    if (
      blockStart.isSameOrAfter(startDate) &&
      blockStart.isSameOrBefore(endDate)
    ) {
      return true
    } else if (
      blockEnd.isSameOrAfter(startDate) &&
      blockEnd.isSameOrBefore(endDate)
    ) {
      return true
    } else if (blockStart.isBefore(startDate) && blockEnd.isAfter(endDate)) {
      return true
    }
  } else if (blockStart.isAfter(startDate) && blockStart.isBefore(endDate)) {
    return true
  } else if (blockEnd.isAfter(startDate) && blockEnd.isBefore(endDate)) {
    return true
  } else if (blockStart.isBefore(startDate) && blockEnd.isAfter(endDate)) {
    return true
  }

  return false
}

export const doBoxesOverlap = (a: Coordinates, b: Coordinates): boolean => {
  return a.x0 < b.x1 && a.x1 > b.x0 && a.y0 < b.y1 && a.y1 > b.y0
}

export const getReservationTimeLabel = (reservation: DispatchBlock): string => {
  const userTimeZone = auth.getUserTimeZone
  const startTime = dayjs(reservation.startDatetime).tz(
    reservation.startTimeZone || userTimeZone
  )
  const endTime = dayjs(reservation.endDatetime).tz(
    reservation.endTimeZone || userTimeZone
  )

  const startHour = getReservationTimeFormat(
    startTime,
    startTime.format('a') !== endTime.format('a')
  )
  const endHour = getReservationTimeFormat(endTime, true)

  let timeLabel = `${startHour}-${endHour}`

  const startTimeZone = reservation.startTimeZone
  const endTimeZone = reservation.endTimeZone

  if (startTimeZone !== userTimeZone || endTimeZone !== userTimeZone) {
    const tzLabel = dayjs().tz(endTimeZone).format('z')
    timeLabel = `${timeLabel} (${tzLabel})`
  }
  return timeLabel
}

export const getReservationTimeFormat = (
  time: Dayjs,
  meridiem = false
): string => {
  if (time.minute() !== 0) {
    return time.format(meridiem ? 'h:mma' : 'h:mm')
  }
  return time.format(meridiem ? 'ha' : 'h')
}
