
import { Component, Prop, Vue } from 'vue-property-decorator'
import { SimpleTableAction } from '@/models/SimpleTableAction'

@Component({})
export default class CUSimpleTableActionsCell<T> extends Vue {
  @Prop({ required: true }) readonly item!: T
  @Prop({ required: true }) readonly actions!: SimpleTableAction[]
  @Prop({ required: true }) readonly isActive!: boolean
  @Prop({ type: Boolean, default: false })
  readonly spaceBetweenActions!: boolean
}
