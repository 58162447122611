
import { Vue, Component, Watch } from 'vue-property-decorator'
import columns from '@/store/modules/columns'
import draggable from 'vuedraggable'
import { DataTableColumn } from '@/models/DataTableColumn'
import _cloneDeep from 'lodash.clonedeep'
import sidebar from '@/store/modules/sidebar'
@Component({
  components: { draggable },
})
export default class CUDataTableCustomizeColumn extends Vue {
  tab = null
  columns = columns
  draggedIndex: number | null = null
  sidebar = sidebar
  search = ''
  baseColumns: DataTableColumn[] = this.sortColumnsByVisibility(
    _cloneDeep(columns.getColumns)
  )
  visibilityModel: DataTableColumn[] = this.baseColumns.filter((col) =>
    col.text?.toLowerCase().includes(this.search.toLowerCase())
  )

  @Watch('search', { immediate: true })
  handleSearchChange(search: string): void {
    if (!search) {
      this.visibilityModel = this.baseColumns
    } else {
      this.visibilityModel = this.baseColumns.filter((col) =>
        col.text?.toLowerCase().includes(search.toLowerCase())
      )
    }
  }

  @Watch('visibilityModel')
  handleModelOrderChange(model: DataTableColumn[]): void {
    if (!this.search) {
      this.baseColumns = model
    }
  }

  toggleActive(_t_id: string): void {
    const visibilityCol = this.visibilityModel.find(
      (col) => col._t_id === _t_id
    )
    if (visibilityCol) {
      visibilityCol.hidden = !(visibilityCol.hidden || false)
    }
  }

  start(e: { oldIndex }): void {
    this.draggedIndex = e.oldIndex
  }

  end(): void {
    this.draggedIndex = null
  }

  sortColumnsByVisibility(cols: DataTableColumn[]): DataTableColumn[] {
    const visibleColumns = cols.filter((col) => !col.hidden)
    const hiddenColumns = cols.filter((col) => col.hidden)
    return visibleColumns.concat(hiddenColumns)
  }

  applyColumnChanges(): void {
    columns.setColumnsWithCustomizeHeader(this.baseColumns)
    sidebar.pop()
  }
}
