
import { Vue, Component, Prop } from 'vue-property-decorator'
import { numberToPixels } from '@/utils/style'
import sidebar from '@/store/modules/sidebar'

@Component({})
export default class CUSidebarContent extends Vue {
  @Prop({ required: false, default: '48px' })
  readonly headerHeight!: string | number

  @Prop({ required: false, default: '0px' })
  readonly customHeaderHeight!: string | number

  @Prop({ required: false, default: '81px' })
  readonly footerHeight!: string | number

  @Prop({ required: false, default: null })
  readonly scrollId!: string

  @Prop({ type: Boolean, required: false, default: false })
  readonly hideFooterDivider!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  readonly wide!: boolean

  get getHeaderHeight(): string {
    return numberToPixels(this.headerHeight)
  }

  get getCustomHeaderHeight(): string {
    return numberToPixels(this.customHeaderHeight)
  }

  get getContentHeight(): string {
    const headerHeight = `(${
      this.$scopedSlots.header ? this.getHeaderHeight : '0px'
    } + ${this.getCustomHeaderHeight})`
    const footerHeight = `(${
      this.$scopedSlots.footer ? this.getFooterHeight : '0px'
    })`
    const fabPadding = sidebar.fabComponent ? '48px' : '20px'
    const titlePadding = sidebar.content?.title ? '36px' : '0px'
    return `calc(100vh - 58px - ${headerHeight} - ${footerHeight} - ${fabPadding} - ${titlePadding})`
  }

  get getFooterHeight(): string {
    return numberToPixels(this.footerHeight)
  }
}
