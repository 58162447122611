
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { v4 } from 'uuid'
import { assertNonNullType } from 'graphql'
import { EventBus } from '@/utils/eventBus'

@Component({})
export default class CUScroll extends Vue {
  // width and height of the container
  @Prop({ required: false, default: null }) width: string
  @Prop({ required: false, default: null }) height: string
  @Prop({ required: false, default: null }) maxWidth: string
  @Prop({ required: false, default: null }) maxHeight: string
  // manually sets the scroll container's id
  @Prop({ required: false, default: null }) presetId: string
  // set scroll to a value to have content scroll down that far
  @Prop({ required: false, default: 0 }) scroll: number
  // prevents the scrollbar from fading when not in use
  @Prop({ type: Boolean, default: false }) noFade: boolean
  // shows a horizontal scrollbar instead of vertical
  @Prop({ type: Boolean, default: false }) horizontal: boolean
  // sets the background to light gray
  @Prop({ type: Boolean, default: false }) grayBg: boolean
  // offsets the scrollbar to be outside the content
  @Prop({ type: Boolean, default: false }) offset: boolean
  // uses a single div to allow more control over the height
  @Prop({ type: Boolean, default: false }) simple: boolean
  // positions the scrollbar on the left of the container
  @Prop({ type: Boolean, default: false }) left: boolean

  supported = navigator.userAgent.indexOf('AppleWebKit') !== -1
  active = assertNonNullType

  get id(): string {
    return this.presetId || v4()
  }

  get displayBoth(): boolean {
    return !!this.height && !!this.width
  }

  get styles(): Record<string, string> {
    const styles = {
      width: this.width,
      height: this.height,
      maxWidth: this.maxWidth,
      maxHeight: this.maxHeight,
      overflowX: 'auto',
      overflowY: 'auto',
    }
    if (this.supported) {
      styles.overflowX =
        this.horizontal || this.displayBoth ? 'scroll' : 'hidden'
      styles.overflowY =
        !this.horizontal || this.displayBoth ? 'scroll' : 'hidden'
    }
    return styles
  }

  @Watch('scroll')
  handleScroll(): void {
    const el: any = this.$refs[`scroll-element-${this.id}`]
    el.scrollTo(
      this.left
        ? { behavior: 'smooth', left: this.scroll - el.scrollWidth }
        : { behavior: 'smooth', top: this.scroll }
    )
  }

  watchForEnd(event: UIEvent): void {
    const el: any = this.$refs[`scroll-element-${this.id}`]
    if (el.scrollHeight - el.scrollTop === el.clientHeight) {
      this.$emit('scroll-end', event)

      // prevent scrolling for 50ms to prevent scroll-end from firing multiple times
      el.style.pointerEvents = 'none'
      setTimeout(() => {
        el.style.pointerEvents = 'auto'
      }, 50)
    }
  }

  hideScrollBar(el): void {
    el.classList.remove('scroll-hover')
    el.classList.remove('scroll-show')
  }

  showScrollBar(el): void {
    el.classList.add('scroll-show')
    setTimeout(() => {
      el.classList.add('scroll-hover')
    }, 400)
  }

  defineScrollbar(): void {
    let timer
    let mouseDown = false
    const el: any = this.$refs[`scroll-element-${this.id}`]

    if (this.noFade) {
      this.showScrollBar(el)
    } else {
      el.addEventListener('scroll', () => {
        if (!el.classList.contains('scroll-show')) {
          this.showScrollBar(el)
        }
        clearTimeout(timer)
        timer = setTimeout(() => {
          if (!mouseDown) {
            this.hideScrollBar(el)
          }
        }, 1000)
      })

      window.addEventListener('mousedown', (e) => {
        if (
          (!this.horizontal &&
            el.offsetWidth - e.offsetX <= 14 &&
            el.offsetWidth - e.offsetX >= 0) ||
          (this.horizontal &&
            el.offsetHeight - e.offsetY <= 14 &&
            el.offsetHeight - e.offsetY >= 0)
        ) {
          mouseDown = true
        }
      })

      window.addEventListener('mouseup', () => {
        mouseDown = false
        timer = setTimeout(() => {
          if (!mouseDown) {
            this.hideScrollBar(el)
          }
        }, 1000)
      })
    }
    if (this.left) {
      this.resetScroll({ offset: -9999, id: this.id })
    }
  }

  resetScroll(data: { offset: number; id: string }): void {
    const el: any = this.$refs[`scroll-element-${data.id || this.id}`]
    if (!el || !el?.scrollTo) {
      return
    } else if (this.left) {
      el.scrollTo(-1 * (el.scrollWidth - 1344) + data.offset, 0)
    } else {
      el.scrollTo({ behavior: 'smooth', top: data.offset })
    }
  }

  mounted(): void {
    EventBus.$on('reset-scroll', (data) => this.resetScroll(data))

    if (this.supported) {
      this.defineScrollbar()
    }
  }

  beforeDestroy(): void {
    EventBus.$off('reset-scroll', (data) => this.resetScroll(data))
  }
}
