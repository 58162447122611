var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"v-card-borderless background-gray-bg border-radius-20 padding-y-4 padding-x-3"},[(_vm.user && _vm.fullName)?_c('div',{staticClass:"margin-x-2 font-14 font-bold d-flex justify-space-between"},[(_vm.canViewContacts)?_c('router-link',{attrs:{"to":{
        name: 'contacts.detail',
        params: { id: String(_vm.user.customerId) },
      }}},[_vm._v(" "+_vm._s(_vm.fullName)+" ")]):_c('p',[_vm._v(_vm._s(_vm.fullName))]),_c('div',{staticClass:"d-flex"},[(_vm.label)?_c('CUTag',{staticClass:"d-flex align-center",attrs:{"dense":"","background":"bg-gray-2","border":"gray-border-dark"}},[_c('div',{staticClass:"font-12 font-bold",style:({ margin: '2px 0' })},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(_vm.actions.length)?_c('v-menu',{staticClass:"border-0",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('CUIcon',_vm._g({staticClass:"cursor-pointer margin-r-n1 margin-l-1",attrs:{"color":"gray-icon-light","width":"18px","height":"18px"}},on),[_vm._v(" options ")])]}}],null,false,946994469)},[_c('v-list',{staticClass:"font-14 font-semibold"},_vm._l((_vm.actions),function(action,idx){return _c('v-list-item',{key:`contact-card-dropdown-${action.label}-${idx}`,staticClass:"min-h-36",on:{"click":function($event){return _vm.$emit(action.event)}}},[_vm._v(" "+_vm._s(action.label)+" ")])}),1)],1):_vm._e()],1):_vm._e()],1)],1):_vm._e(),(_vm.user && _vm.user.customerAccount && _vm.customerAccountName)?_c('div',{staticClass:"margin-x-2 font-14"},[(_vm.user.title)?[_vm._v(_vm._s(_vm.user.title)+" at")]:_vm._e(),(_vm.canViewContacts)?_c('router-link',{attrs:{"to":{
        name: 'companies.detail',
        params: { id: _vm.user.customerAccountId },
      },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.customerAccountName)+" ")]):_c('p',[_vm._v(_vm._s(_vm.customerAccountName))])],2):_vm._e(),(_vm.user && _vm.user.email)?_c('div',{staticClass:"margin-x-2 margin-t-1 font-14"},[_vm._v(" "+_vm._s(_vm.user.email)+" ")]):_vm._e(),(_vm.user && _vm.user.phone)?_c('div',{staticClass:"margin-x-2 margin-t-1 font-14"},[_vm._v(" "+_vm._s(_vm.phoneNumber)+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }