
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CUSidebarContentWithFooterButtons extends Vue {
  @Prop({ required: false, type: Boolean }) readonly noStaticWidth!: boolean

  @Prop({ required: false, type: Boolean }) readonly primaryDisabled!: boolean
  @Prop({ required: false, type: Boolean }) readonly primaryLoading!: boolean
  @Prop({ required: false, type: Boolean }) readonly hidePrimary!: boolean
  @Prop({ required: false, default: 'Save' }) readonly primaryText!: string
  @Prop({ required: false, default: 130 }) readonly primaryWidth!: number
  @Prop({ required: false, type: String }) readonly primaryAppendIcon!: string

  @Prop({ required: false, type: Boolean }) readonly secondaryDisabled!: boolean
  @Prop({ required: false, type: Boolean }) readonly secondaryLoading!: boolean
  @Prop({ required: false, type: Boolean }) readonly hideSecondary!: boolean
  @Prop({ required: false, default: 'Cancel' }) readonly secondaryText!: string
  @Prop({ required: false, default: 130 }) readonly secondaryWidth!: number
}
