
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { staticResource } from '@/utils/env'

@Component
export default class DocumentUpload extends Vue {
  @Prop({ type: Boolean, default: false }) readonly logo!: boolean
  @Prop({ required: false, default: '' }) readonly logoUrl!: string
  @Prop({ required: true, type: String }) readonly uploadPrompt!: string
  uploading = false
  attachedFile = null
  displayUploadInput = false

  get photo(): string {
    if (!this.uploading) {
      return staticResource(this.logoUrl)
    }
    if (this.logoUrl?.[0] === '/') {
      return staticResource(this.logoUrl)
    }
    return this.logoUrl
  }

  handleBrowse(event: Event): void {
    this.uploading = true
    const target = event.target as HTMLInputElement
    this.attachedFile = target.files[0].name
    this.$emit('document:update', target.files[0])
  }

  handleDrop(event: DragEvent): void {
    this.uploading = true
    this.attachedFile = event.dataTransfer.files[0].name
    this.$emit('document:update', event.dataTransfer.files[0])
  }

  toggleDisplayUploadInput(): void {
    this.displayUploadInput = !this.displayUploadInput
  }

  handleRemoveFile(): void {
    this.attachedFile = null
    this.uploading = true
    this.displayUploadInput = true
    this.$emit('document:update', null)
  }
}
