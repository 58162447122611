
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { SimpleTableColumn } from '@/models/SimpleTableColumn'
import CUSimpleTableActionsCell from '@/components/CUSimpleTableActionsCell.vue'
import { SimpleTableAction } from '@/models/SimpleTableAction'
import CUCheckbox from '@/components/CUCheckbox.vue'
import op from 'simple-object-path'
import dayjs from 'dayjs'
import { currencyFilter } from '@/utils/string'
import CUDataTableActionRow from '@/components/CUDataTableActionRow.vue'
import { TableAction } from '@/models/TableAction'
import CUDataTableCustomizeColumnHeader from '@/components/CUDataTableCustomizeColumnHeader.vue'
@Component({
  components: {
    CUCheckbox,
    CUSimpleTableActionsCell,
    CUDataTableActionRow,
    CUDataTableCustomizeColumnHeader,
  },
})
export default class CUSimpleTable<T> extends Vue {
  @Prop({ type: Boolean, default: false }) readonly dense!: boolean
  @Prop({ default: () => [] }) readonly items!: T[]
  @Prop({ default: 'id' }) readonly itemKey!: string
  @Prop({ default: () => [] }) readonly headers!: SimpleTableColumn<T>[]
  @Prop({ default: () => [] }) readonly actions!: SimpleTableAction[]
  @Prop({ default: () => [] }) readonly bulkActions!: TableAction[]
  @Prop({ default: () => (_: T, __: SimpleTableAction) => true })
  readonly showAction!: (_: T, __: SimpleTableAction) => boolean
  @Prop({ type: Boolean, default: false })
  readonly spaceBetweenActions!: boolean
  @Prop({ type: Boolean, default: false })
  readonly borderTop!: boolean
  @Prop({ type: Boolean, default: false })
  readonly borderBottom!: boolean
  @Prop({ type: Boolean, default: false })
  readonly clickable!: boolean
  @Prop({ type: Boolean, default: false }) readonly selectToEdit!: boolean
  @Prop({ default: () => (_: T) => true }) readonly isActive!: (_: T) => boolean
  @Prop({ default: 'No items found' }) readonly noDataText!: boolean[]
  @Prop({ default: () => [] }) readonly value!: T[]
  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean
  @Prop({ default: false, type: Boolean }) readonly error!: boolean
  @Prop({ default: false, type: Boolean }) readonly attachDatePicker!: boolean
  @Prop({ default: undefined }) readonly nudgeDatePickerLeft!: string | number
  @Prop({ default: undefined }) readonly nudgeDatePickerBottom!: string | number

  op = op

  textFieldValues: string[][] = []
  selected = []

  get headerColumns(): any[] {
    return (
      this.bulkActions.length &&
      this.tableHeaders.map((col) => ({
        ...col,
        headerName: `header.${col.value}`,
      }))
    )
  }

  @Watch('items', { immediate: true })
  handleItemsChange(newItems: T[]): void {
    this.textFieldValues = newItems.map((item) => {
      return this.headers.map((hd) => {
        if (hd.valueType) {
          return this.getTextFieldValue(hd.valueType, op(item, hd.value))
        }
        return null
      })
    })
    if (this.selected.length) {
      this.selected = this.selected.map((s) => {
        return newItems.find((item) =>
          s ? item[this.itemKey] === s[this.itemKey] : false
        )
      })
    }
  }

  @Watch('value', { immediate: true })
  onValueChange(newValue: T[]): void {
    this.selected = newValue
  }

  columnStyle(item: T, header: SimpleTableColumn<T>): string {
    if (!header.cellClass) {
      return ''
    }
    const style =
      typeof header.cellClass === 'string'
        ? header.cellClass
        : header.cellClass.join(' ')
    return !this.cellValue(item, header) ? '' : style
  }

  cellStyle(item: T): string {
    return this.isActive(item)
      ? ''
      : 'text-gray-text-light text-decoration-line-through'
  }

  cellValue(item: T, header: SimpleTableColumn<T>): string {
    return header.formatter ? header.formatter(item) : op(item, header.value)
  }

  handleItemSelected(val: { item: T; value: boolean }): void {
    this.$emit('item-selected', val)
  }

  get tableHeaders(): SimpleTableColumn<T>[] {
    return this.actions.length
      ? [...this.headers, { text: '', value: '#action', width: 132 }]
      : this.headers
  }

  rowActions(item: T): SimpleTableAction[] {
    return this.actions.reduce(
      (acc, action) => (this.showAction(item, action) ? [...acc, action] : acc),
      []
    )
  }

  handleTextFieldBlur(
    row: number,
    col: number,
    type: string,
    value: string
  ): void {
    const formattedValue = this.getTextFieldValue(
      type,
      this.textFieldValues[row][col]
    )
    this.textFieldValues = [
      ...this.textFieldValues,
      (this.textFieldValues[row] = [
        ...(this.textFieldValues[row][col] = formattedValue),
      ]),
    ]
    this.$emit(
      'edit',
      {
        ...this.items[row],
        [value]: parseFloat(formattedValue.replace(/[^0-9.]/g, '')),
      },
      col
    )
  }

  getTextFieldValue(type: string, value: number | string): string {
    if (!value) {
      value = 0
    }
    switch (type) {
      case 'currency':
        return currencyFilter(
          parseFloat(value.toString().replace(/[^\d.-]/g, ''))
        )
      case 'percent':
        return `${parseFloat(value.toString().replace(/[^\d.-]/g, '')).toFixed(
          1
        )}%`
      default:
        return value.toString()
    }
  }

  toDatePickerFormat(item: T, header: SimpleTableColumn<T>): string {
    const ts = op(item, header.value)
    return ts ? dayjs(ts).format('YYYY-MM-DD') : ''
  }

  fromDatePickerFormat(date: string): string {
    return date ? dayjs(date, 'YYYY-MM-DD').toISOString() : ''
  }
}
