import { VuexModule, Module, Action } from 'vuex-class-modules'
import store from '@/store/index'

import companyStopsClient from '@/services/companyStops'
import {
  DEFAULT_STATE_STATUS,
  GenericStateStatus,
} from '@/models/GenericStateStatus'
import { filter } from '@/utils/filter'
import { TableViewResult } from '@/models/TableView'
import { CompanyStop } from '@/models/CompanyStop'

@Module({ generateMutationSetters: true })
class CompanyStopsModule extends VuexModule {
  _state: GenericStateStatus<TableViewResult<CompanyStop>> =
    DEFAULT_STATE_STATUS

  get companyStops(): GenericStateStatus<TableViewResult<CompanyStop>> {
    return this._state
  }

  async fetchCompanyStops() {
    this._state.loading = true
    const filters = filter()
    const params = {
      page: 1,
      pageSize: -1,
      filters: filters.asQueryParams(),
      sorts: undefined,
    }
    try {
      this._state.data = (await companyStopsClient.tableView(params)).data
      this._state.hasLoaded = true
    } catch (error) {
      this._state.error = true
    } finally {
      this._state.loading = false
    }
  }

  @Action
  loadSavedCompanyStops(forceRefresh = false): Promise<void> {
    if (!forceRefresh && this._state.hasLoaded) {
      return
    }

    return this.fetchCompanyStops()
  }

  get state(): GenericStateStatus<TableViewResult<CompanyStop>> {
    return this._state
  }
}

export default new CompanyStopsModule({ store, name: 'companyStops' })
