
import { SupportedVehicleType } from '@/models/dto'
import { Component, Vue, Prop } from 'vue-property-decorator'
import deepClone from '@/utils/deepClone'

@Component
export default class VehicleTypes extends Vue {
  @Prop({ required: false, default: () => [] })
  readonly vehicleTypes!: SupportedVehicleType[]

  get areAllTypesSelected(): boolean {
    const supportedTypes = this.vehicleTypes.filter((type) => {
      return type.supported
    })
    return supportedTypes.length === this.vehicleTypes.length
  }

  toggleAllSelected(): void {
    const toggledTypes = deepClone(this.vehicleTypes).map((type) => {
      type.supported = !this.areAllTypesSelected
      return type
    })
    this.$emit('update:vehicle-types', toggledTypes)
  }

  toggleVehicleType(value: boolean, typeId: number): void {
    const types = deepClone(this.vehicleTypes)
    const type = types.find((type) => {
      return type.vehicleTypeId === typeId
    })
    type.supported = value || false
    this.$emit('update:vehicle-types', types)
  }
}
