var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-menu',_vm._b({staticClass:"border-0",attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, value: open }){return [_c('div',_vm._g({staticClass:"d-flex align-center justify-center cursor-pointer",class:{
        'background-primary h-40 border-radius-10': _vm.filled,
        'w-120': _vm.filled && !_vm.width,
        [`w-${_vm.width}`]: _vm.filled && _vm.width,
      }},on),[_c('span',{staticClass:"d-flex align-center",class:{
          [`font-${_vm.size} font-${_vm.labelWeight} text-no-wrap text-${_vm.color} margin-l-1`]: true,
          [`overflow-hidden text-overflow-ellipsis max-w-${_vm.maxWidth}`]:
            _vm.maxWidth,
        }},[_vm._t("prepend"),_vm._v(" "+_vm._s(_vm.label)+" ")],2),(!_vm.hideIcon)?_c('CUIcon',{staticClass:"transition-ease transition-duration-300 margin-l-1",class:{ 'rotate-n180': open },attrs:{"aria-label":"Dropdown","color":_vm.iconColor || _vm.color,"width":_vm.iconSize,"height":_vm.iconSize}},[_vm._v(" keyboard_arrow_down ")]):_vm._e()],1)]}}],null,true)},'v-menu',_vm.$attrs,false),[(_vm.actions.length)?_c('v-list',{staticClass:"font-14 font-semibold"},[_vm._l((_vm.actions),function(action,idx){return [(!action.tooltip)?_c('v-list-item',{key:`action-dropdown-${_vm.label}-${action.label}-${idx}`,class:[
          `${_vm.itemClass} min-h-36`,
          { 'text-text-gray-1 no-hover': action.disabled },
        ],attrs:{"id":`action-dropdown-${_vm.label}-${action.label}-${idx}`},on:{"click":function($event){action.disabled || _vm.$emit(action.event, action)}}},[(action.icon)?_c('CUIcon',{staticClass:"margin-r-2",attrs:{"aria-label":action.iconLabel,"width":"24","height":"24","color":"bg-gray-4"}},[_vm._v(" "+_vm._s(action.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(action.label)+" ")],1):_c('CUTooltip',{key:`action-dropdown-${_vm.label}-${action.label}-${idx}`,attrs:{"left":"","tooltip-text":action.tooltip}},[_c('v-list-item',{key:`action-dropdown-${_vm.label}-${action.label}-${idx}`,staticClass:"d-flex align-center",class:[
            `${_vm.itemClass} min-h-36`,
            { 'text-text-gray-1': action.disabled },
          ],attrs:{"id":`action-dropdown-${_vm.label}-${action.label}-${idx}`}},[(action.icon)?_c('CUIcon',{staticClass:"margin-r-2",attrs:{"aria-label":action.iconLabel,"width":"24","height":"24","color":"bg-gray-4"}},[_vm._v(" "+_vm._s(action.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(action.label)+" ")],1)],1)]})],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }