import { VuexModule, Module, Action } from 'vuex-class-modules'
import axios from 'axios'

import auth from '@/services/auth'
import user from '@/services/user'
import router from '@/router'

import { UserAuthPayload, UserDetail, Role } from '@/models/dto'
import { save, load } from '@/utils/localStorage'

import transformationSession from '@/services/transformation'
import impersonation from './impersonation'

import * as FS from '@fullstory/browser'

// register module
import store from '@/store/index'
import company from '@/services/company'
import { Company } from '@/models/dto/Company'
import dayjs from 'dayjs'
import { formatFullName } from '@/utils/string'
import app from './app'
import quickbooks from '@/services/quickbooks'
import { isProdEnvironment } from '@/utils/env'
import { CompanyQuickbooksSettings } from '@/models/dto/Quickbooks'
import { CompanyBusifySettings } from '@/models/dto/BusifyPayments'
import payments from '@/services/payments'
import { CompanyPaymentGatewayDetail } from '@/models/PaymentGateway'

@Module({ generateMutationSetters: true })
class AuthModule extends VuexModule {
  // state
  userId: number | null = load('userId') || null
  user: UserDetail | null = load('user') || null
  company: Company | null = load('company') || null
  token: string | null = load('token') || null
  isTokenSet = !!load('token')
  roles: Role[] = load('roles') || []
  isDriverOnly = load('isDriverOnly') || false
  canTransformCompany = load('canTransformCompany') || false
  isOpen = false
  companyQuickBooksSettings: CompanyQuickbooksSettings | null =
    load('companyQuickBooksSettings') || null
  companyPaymentProcessor = load('companyPaymentProcessor') || null
  _maxCardTransactionAmount = load('maxCardTransactionAmount') || Infinity
  _maxAchTransactionAmount = load('maxCardTransactionAmount') || Infinity
  paymentGateways: CompanyPaymentGatewayDetail[] = []
  companyBusifySettings: CompanyBusifySettings | null =
    load('companyBusifySettings') || null
  _shouldDisplayDayLeading = false
  // getters
  get getUser() {
    return this.user
  }
  get getUserId() {
    return this.userId
  }
  get getToken() {
    return this.token
  }
  get getIsTokenSet() {
    return this.isTokenSet
  }
  get getRoles() {
    return this.roles
  }
  get getFullName() {
    if (!this.user?.firstName || !this.user?.lastName) {
      return ''
    }
    return `${this.user.firstName} ${this.user.lastName}`
  }
  get getIsDriverOnly() {
    return this.isDriverOnly
  }
  get getCanTransformCompany() {
    return this.canTransformCompany
  }

  get getCompanyId() {
    return this.user?.companyId
  }

  get getUserCompany() {
    return this.user?.company
  }

  get getCompany() {
    return this.company
  }

  get getShowQuoteWidgetOnCustomersFilter() {
    return this.company?.companyHasQuotesCreatedThroughWidget
  }

  get getIsOpen() {
    return this.isOpen
  }

  get getUserTimeZone() {
    return this.user?.timeZone || dayjs.tz.guess()
  }

  get getCompanyTimeZone() {
    return this.company?.address?.timeZone || dayjs.tz.guess()
  }

  get maxCardTransactionAmount(): number {
    return this.companyPaymentProcessor?.paymentGatewayTypeKey === 'busify_pay'
      ? this._maxCardTransactionAmount
      : Infinity
  }

  get isBusifyPayProcessor(): boolean {
    return this.company?.isBusifyPayProcessor
  }

  get maxAchTransactionAmount(): number {
    return this.companyPaymentProcessor?.paymentGatewayTypeKey === 'busify_pay'
      ? this._maxAchTransactionAmount
      : Infinity
  }

  get getUserRoleNames() {
    return this.roles?.map((role) => role.roleName)
  }

  get shouldDisplayDayLeading(): boolean {
    return (
      this.company?.defaultDateFormat === 'DD/MM/YYYY' ||
      this._shouldDisplayDayLeading
    )
  }

  get getCompanyQuickBooksSettings(): Partial<CompanyQuickbooksSettings> {
    return this.companyQuickBooksSettings || { enabled: false }
  }

  get isQuickbooksIntegrationEnabled(): boolean {
    return (
      (this.companyQuickBooksSettings?.enabled &&
        app.isQuickbooksIntegrationFeatureEnabled) ||
      false
    )
  }

  get isQuickBooksAskToSyncWhenCreateNewContact(): boolean {
    return (
      (this.companyQuickBooksSettings?.askToSyncWhenCreateNewContact &&
        app.isQuickbooksIntegrationFeatureEnabled) ||
      false
    )
  }

  get isQuickBooksAutomaticInvoiceCreation(): boolean {
    return (
      (this.companyQuickBooksSettings?.automaticInvoiceCreation &&
        app.isQuickbooksIntegrationFeatureEnabled) ||
      false
    )
  }

  // mutations (mutations are autogenerated for each root level state field)

  // actions
  @Action
  async login(payload: UserAuthPayload) {
    const response = await auth.login(payload)
    if (response.data.successful) {
      save('userId', response.data.user.userId)
      save('token', response.data.token)
      registerBearerToken(response.data.token)
      this.token = response.data.token
      this.user = response.data.user
      this.userId = response.data.user.userId
      this.isTokenSet = !!response.data.token
      await this.getUserDetail()
      await this.getUserCompanyDetail()
      await this.loadPaymentGateways()

      registerFSUser(response.data.user)
    }
  }

  @Action
  setCompany(company: Company) {
    this.company = company
  }

  @Action
  async autoLogin() {
    this.user = load('user')
    this.token = load('token')

    try {
      this.company = await this.getUserCompanyDetail()
    } catch (error) {
      console.log(error)
    }

    this.isTokenSet = !!load('token')
    if (this.token) {
      registerBearerToken(this.token)
      registerFSUser(this.user)
    }
  }

  @Action
  logout() {
    if (impersonation.getHasTransformationSession) {
      transformationSession.stop()
    }
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('user')
    window.localStorage.removeItem('company')
    window.localStorage.removeItem('userId')
    window.localStorage.removeItem('roles')
    window.localStorage.removeItem('isDriverOnly')
    window.localStorage.removeItem('hasTransformationSession')
    window.localStorage.removeItem('companyQuickBooksSettings')
    window.localStorage.removeItem('companyPaymentProcessor')
    window.localStorage.removeItem('maxCardTransactionAmount')
    window.localStorage.removeItem('maxAchTransactionAmount')
    this.userId = null
    this.user = null
    this.company = null
    this.companyQuickBooksSettings = null
    this.token = null
    this.roles = null
    this.isTokenSet = false
    this.isDriverOnly = false
    router.push({
      name: 'login',
    })
  }

  @Action
  async getUserProfile() {
    const response = await auth.getUserProfile()
    if (response.data.successful) {
      save('roles', response.data.userProfile.roles)
      this.roles = response.data.userProfile.roles
      save('user', this.user)
      const isDriverOnly = checkIsDriverOnly(response.data.userProfile.roles)
      this.isDriverOnly = isDriverOnly
      save('isDriverOnly', isDriverOnly)
      const canTransformCompany = checkCanTransformCompany(
        response.data.userProfile.roles
      )
      this.canTransformCompany = canTransformCompany
      save('canTransformCompany', canTransformCompany)
    }
  }

  @Action
  setPartialCompany(companyChanges: Partial<Company>): void {
    const updatedCompany = { ...this.company, ...companyChanges }
    this.company = updatedCompany
  }

  @Action
  setCompanyQuickBooksSettings(
    quickBooksSettingsChanges: Partial<CompanyQuickbooksSettings>
  ): void {
    const updatedQuickBooksSettings = {
      ...this.companyQuickBooksSettings,
      ...quickBooksSettingsChanges,
    }
    this.companyQuickBooksSettings = updatedQuickBooksSettings
  }

  @Action
  setCompanyBusifySettings(
    busifySettingsChanges: Partial<CompanyBusifySettings>
  ): void {
    const updatedBusifySettings = {
      ...this.companyBusifySettings,
      ...busifySettingsChanges,
    }
    this.companyBusifySettings = updatedBusifySettings
  }

  @Action
  async getUserDetail() {
    if (!this.userId) {
      return
    }

    const response = await user.byId(this.userId)

    // Seems like we don't have a `successful` property to check on this response?
    if (response.status === 200) {
      this.user = response.data.user
      save('user', response.data.user)
    }
  }

  @Action
  async getCompanyQuickBooksDetail(): Promise<void> {
    if (app.isQuickbooksIntegrationFeatureEnabled) {
      try {
        const qbResponse = await quickbooks.getCompanyQuickbooksSettings(
          this.user.companyId
        )
        this.companyQuickBooksSettings = qbResponse.data.companySettings
      } catch (e) {
        console.log('Error fetching Quickbooks settings:', e)
      }
    }
  }

  @Action
  async loadPaymentGateways(): Promise<void> {
    const allGatewaysRes = await payments.getAllPaymentGateways()
    this.paymentGateways = allGatewaysRes?.data?.paymentGateways || []
  }

  @Action
  async loadCompanyPaymentProcessor(): Promise<void> {
    if (!this.user?.companyId) {
      return
    }
    try {
      const res = await payments.getDefaultPaymentGateway(this.user.companyId)
      const paymentGateway = res.data.paymentGateways[0]
      this.companyPaymentProcessor = paymentGateway
      save('companyPaymentProcessor', paymentGateway)
      if (paymentGateway?.paymentGatewayTypeKey === 'busify_pay') {
        const res = await payments.getBusifyPayMerchantDetail(
          paymentGateway.apiKey
        )

        const {
          maxTransactionAmount: maxTransactionAmountInCents,
          achMaxTransactionAmount: achMaxTransactionAmountInCents,
        } = res.data.identity.entity
        const maxTransactionAmount = maxTransactionAmountInCents / 100
        const achMaxTransactionAmount = achMaxTransactionAmountInCents / 100
        if (maxTransactionAmount) {
          this._maxCardTransactionAmount = maxTransactionAmount
          save('maxCardTransactionAmount', maxTransactionAmount)
        }

        if (achMaxTransactionAmount) {
          this._maxAchTransactionAmount = achMaxTransactionAmount
          save('maxAchTransactionAmount', achMaxTransactionAmount)
        }
      }
    } catch (e) {
      console.log('Error fetching company payment processor:', e)
    }
  }

  @Action
  async getUserCompanyDetail() {
    if (!this.user?.companyId) {
      return
    }
    try {
      const response = await company.byId(this.user.companyId)
      const companyData = response.data.company
      if (!this.companyQuickBooksSettings) {
        this.getCompanyQuickBooksDetail()
      }
      if (!this.companyPaymentProcessor) {
        this.loadCompanyPaymentProcessor()
      }

      if (
        this.companyPaymentProcessor?.paymentGatewayTypeKey === 'busify_pay'
      ) {
        if (
          !this._maxCardTransactionAmount ||
          this.maxCardTransactionAmount === Infinity
        ) {
          this.loadCompanyPaymentProcessor()
        }
      }
      this.company = companyData
      return companyData
    } catch (e) {
      console.log(e)
      return null
    }
  }

  @Action
  setShouldDisplayDayLeading(shouldDisplayDayLeading: boolean | string): void {
    if (typeof shouldDisplayDayLeading === 'string') {
      this._shouldDisplayDayLeading = shouldDisplayDayLeading === 'DD/MM/YYYY'
      return
    }
    this._shouldDisplayDayLeading = shouldDisplayDayLeading
  }

  @Action
  refreshUser() {
    this.registerToken()
    this.getUserDetail()
    this.getUserProfile()
    this.getUserCompanyDetail()
    this.loadPaymentGateways()
  }

  @Action
  registerToken() {
    const token = load('token')
    if (token) {
      registerBearerToken(token)
    }
  }

  @Action
  openChangePassword() {
    this.isOpen = true
  }

  @Action
  closeChangePassword() {
    this.isOpen = false
  }
}

//private helpers
const registerBearerToken = (token: string): void => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

const registerFSUser = (user: UserDetail): void => {
  if (!user?.userId || !isProdEnvironment()) {
    return
  }
  FS.identify(user.userId.toString(), {
    displayName: formatFullName(user),
    email: user.email,
    phone: user.phone,
    companyId: user.companyId,
    companyName: user.companyName,
    groupName: user.groupName,
  })
}

export const checkIsDriverOnly = (roles: Role[]): boolean => {
  if (roles && roles.length) {
    const isDriver = roles.some((role) => role.roleName === 'is_driver')
    const isAdmin = roles.some((role) =>
      [
        'is_free_admin',
        'is_paid_admin',
        'is_broker_admin',
        'is_admin_admin',
      ].includes(role.roleName)
    )
    const isUser = roles.some((role) =>
      [
        'is_free_user',
        'is_paid_user',
        'is_broker_user',
        'is_report_admin',
      ].includes(role.roleName)
    )
    return !isAdmin && !isUser && isDriver
  }
  return false
}

const checkCanTransformCompany = (roles: Role[]): boolean => {
  return roles.some((role) => role.roleName === 'can_transform_all_companies')
}

export default new AuthModule({ store, name: 'auth' })
