import Placeholder from '@/views/Placeholder.vue'

export enum SidebarState {
  OPEN = 'open',
  HIDDEN = 'hidden',
  CLOSED = 'closed',
}

export interface Sidebar {
  state: SidebarState
  content: SidebarContent
  overlayBackground?: boolean
}

export interface SidebarContent {
  component: typeof Placeholder
  props?: Record<string, unknown>
  title?: string
  on?: Record<string, unknown>
  width?: number
  height?: number
  fab?: boolean
  wide?: boolean
  persist?: boolean
  overlayBackground?: boolean
  offset?: number
  onClose?: () => unknown
}

export enum SidebarName {
  VEHICLE = 'vehicle',
  DRIVER = 'driver',
}
