
import { Vue, Component, Prop } from 'vue-property-decorator'
import { pluralize } from '@/utils/string'
import assignment from '@/store/modules/assignment'
import {
  DriverConflict,
  FormattedAssignmentConflict,
  VehicleConflict,
} from '@/models/dto'
import sidebar from '@/store/modules/sidebar'
import VehicleSidebar from './VehicleSidebar.vue'

const HARD_CONFLICT = 1
const BUFFER_CONFLICT_SELF = 3
const BUFFER_CONFLICT_OTHER = 4
const TIMEOFF_CONFLICT = 5
const TIMEOFF_INDEFINITE_CONFLICT = 6
const TIMEOFF_BUFFER_CONFLICT = 7

@Component({})
export default class AssignmentConflictsMessages extends Vue {
  @Prop({ required: false, default: null }) vehicleId: number
  @Prop({ required: false, default: null }) driverId: number

  get isByIdView(): boolean {
    return !!this.vehicleId || !!this.driverId
  }

  hasTimeoffConflict(conflict: VehicleConflict | DriverConflict): boolean {
    return (
      conflict.conflictType === TIMEOFF_CONFLICT ||
      conflict.conflictType === TIMEOFF_INDEFINITE_CONFLICT ||
      conflict.conflictType === TIMEOFF_BUFFER_CONFLICT
    )
  }

  getVehicleName(data: VehicleConflict | DriverConflict): string {
    if ('vehicleName' in data) {
      return data.vehicleName
    }
    return 'Vehicle'
  }

  handleVehicleIdClick(conflict: VehicleConflict | DriverConflict): void {
    if ('vehicleId' in conflict) {
      this.$router.push({
        query: {
          sidebarName: 'vehicle',
          sidebarId: String(conflict.vehicleId),
        },
        hash: 'availability',
      })

      sidebar.push({
        component: VehicleSidebar,
        width: 672,
        props: {
          unavailableVehicles: assignment.getUnavailableVehicles,
          reservationId: assignment.getSelectedReservation.reservationId,
          isModeEdit: true,
          activeVehicleDetailId: conflict.vehicleId,
        },
      })
    }
  }

  get addedVehiclesConflictHtml(): string {
    const diff = assignment.addedVehicleConflicts
    if (diff === 0 || this.isByIdView) {
      return null
    }

    return `Are you sure you want to ${
      diff > 0 ? 'add' : 'remove'
    } <span class="font-bold">${Math.abs(diff)} 
    ${pluralize(Math.abs(diff), 'vehicle')}</span> ${
      diff > 0 ? 'to' : 'from'
    } this reservation? ${
      diff > 0 ? 'Any unassigned vehicles will be added as Charter Buses.' : ''
    }`
  }

  get conflicts(): FormattedAssignmentConflict[] {
    return [
      ...assignment.getVehicleConflicts.filter(
        (c) => c.id === this.vehicleId || !this.isByIdView
      ),
      ...assignment.getDriverConflicts.filter(
        (c) => c.id === this.driverId || !this.isByIdView
      ),
      ...assignment.getVehicleTimeOffConflicts.filter(
        (c) => c.id === this.vehicleId || !this.isByIdView
      ),
    ].map((conflict) => ({
      ...conflict,
      content: this.getConflictContent(conflict.id, conflict.data),
      description: this.getConflictDescription(conflict.data),
    }))
  }

  getConflictContent(
    id: number,
    data: VehicleConflict[] | DriverConflict[]
  ): string {
    switch (data[0].conflictType) {
      case HARD_CONFLICT:
        return `Potential Conflict - Double Booked with`
      case BUFFER_CONFLICT_SELF:
      case BUFFER_CONFLICT_OTHER:
        return `Potential Conflict - Short Turnaround with`
      case TIMEOFF_CONFLICT:
        return 'Potential Conflict - Scheduled for Time Off'
      case TIMEOFF_INDEFINITE_CONFLICT:
        return 'Potential Conflict - Out Indefinitely'
      case TIMEOFF_BUFFER_CONFLICT:
        return 'Potential Conflict - Short Turnaround with Time Off'
    }
  }

  getConflictDescription(data: VehicleConflict[] | DriverConflict[]): string {
    switch (data[0].conflictType) {
      case HARD_CONFLICT:
        return 'Assigned to another trip that overlaps with this trip.'
      case BUFFER_CONFLICT_SELF:
        return 'Assigned to another trip that begins within the buffer time of this trip.'
      case BUFFER_CONFLICT_OTHER:
        return 'Assigned to another trip whose buffer time overlaps with this trip.'
      case TIMEOFF_CONFLICT:
        return 'Scheduled for time off that overlaps with this trip.'
      case TIMEOFF_INDEFINITE_CONFLICT:
        return 'Marked as “vehicle out indefinitely”'
      case TIMEOFF_BUFFER_CONFLICT:
        return 'Scheduled for time off that overlaps with the buffer time of this trip.'
    }
  }

  getResIdPostfix(conflict: FormattedAssignmentConflict, idx: number): string {
    if (idx === conflict.data.length - 1) {
      return ''
    } else if (idx === conflict.data.length - 2) {
      return ' and'
    } else {
      return ','
    }
  }
}
