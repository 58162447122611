import { apiBaseUrl } from '@/utils/env'
import {
  Trip,
  TripContactUpdate,
  TripResult,
  TripTableViewResult,
} from '@/models/dto'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { TableViewParameters } from '@/models/TableView'
import {
  TripPricingDetail,
  TripPricingDetailRequest,
  TripPricingResponse,
} from '@/models/dto/TripPricingDetail'

const httpService: HttpService = new HttpService()

export default {
  tableView(
    params: TableViewParameters,
    quoteIdList: string | null = null
  ): Promise<AxiosResponse<TripTableViewResult>> {
    const { sorts = null, filters = null, pageSize = 10, page = 1 } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    if (quoteIdList) {
      query = `${query}&quoteId=${quoteIdList}`
    }
    query = encodeURI(query)
    const host = apiBaseUrl()
    const url = `https://${host}/tables/trips?${query}`
    return httpService.get(url)
  },
  byId(id: number): Promise<AxiosResponse<TripResult>> {
    const host = apiBaseUrl()
    const url = `https://${host}/v3/trips/${id}`
    return httpService.get(url)
  },
  partialUpdate(trip: Partial<Trip>): Promise<AxiosResponse<void>> {
    const host = apiBaseUrl()
    const url = `https://${host}/v2/trips/${trip.tripId}`
    return httpService.patch(url, trip)
  },
  modifyTripByStops(params: {
    tripId: number
    hardConflictOverride: boolean
    skipAssignmentsUpdate?: boolean
    payload: Trip
  }): Promise<AxiosResponse<void>> {
    return httpService.patch(
      `https://${apiBaseUrl()}/v3/trips/${
        params.tripId
      }/stops?hardConflictOverride=${
        params.hardConflictOverride
      }&skipAssignmentsUpdate=${params.skipAssignmentsUpdate || false}`,
      params.payload
    )
  },
  getRecurringTripCount(tripId: number, date: string): Promise<AxiosResponse> {
    return httpService.get(
      `https://${apiBaseUrl()}/trips/${tripId}/recurringTripCountFromDate?date=${date}`
    )
  },
  editTripContact(
    tripId: number,
    payload: TripContactUpdate
  ): Promise<AxiosResponse<void>> {
    return httpService.patch(
      `https://${apiBaseUrl()}/trips/${tripId}/tripContact`,
      payload
    )
  },
  editTripNotes(
    tripId: number,
    payload: Partial<Trip>
  ): Promise<AxiosResponse<void>> {
    return httpService.patch(
      `https://${apiBaseUrl()}/trips/notes/${tripId}`,
      payload
    )
  },
  editTripDriversAndVehicles(
    tripId: number,
    payload: Partial<Trip>
  ): Promise<AxiosResponse<Trip>> {
    return httpService.patch(
      `https://${apiBaseUrl()}/tripAssignments/updateDriversAndVehicles/${tripId}`,
      payload
    )
  },
  editTripName(
    tripId: number,
    payload: Partial<Trip>
  ): Promise<AxiosResponse<void>> {
    return httpService.patch(
      `https://${apiBaseUrl()}/trips/routeName/${tripId}?updateOnlyThisTrip=true`,
      payload
    )
  },
  getTripPricingDetail(
    payload: TripPricingDetailRequest
  ): Promise<AxiosResponse<TripPricingDetail>> {
    const url = `https://${apiBaseUrl()}/trips/pricing`
    return httpService.post(url, payload)
  },
  getTripPricingDetailByTripId(
    tripId: number
  ): Promise<AxiosResponse<TripPricingResponse>> {
    const url = `https://${apiBaseUrl()}/trips/${tripId}/pricing`
    return httpService.get(url)
  },
}
