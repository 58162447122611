
import {
  Component,
  Vue,
  Prop,
  Watch,
  Model,
  Inject,
} from 'vue-property-decorator'
import places from '@/services/places'
import companyClient from '@/services/company'
import {
  Address,
  AddressWithSavedNotes,
  ExistingPlace,
  PlaceSearch,
} from '@/models/dto'
import { ValidationRule } from '@/utils/rules'
import auth from '@/store/modules/auth'
import { Company } from '@/models/dto/Company'
import colors from '@/scss/_colors-export.scss'
import widgetService from '@/services/widget'
import companyStopsStore from '@/store/modules/companyStops'
import { CompanyStop } from '@/models/CompanyStop'

@Component
export default class AutocompleteAddress extends Vue {
  @Model('change') readonly value!: Address | null
  @Prop({ default: null }) existingCompany!: Company
  @Prop({ type: Boolean, default: false }) readonly autoFocus!: boolean
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean
  @Prop({ type: Boolean, default: false }) readonly hideOptions!: boolean
  @Prop({ default: null }) readonly tabIndex!: number
  @Prop({ default: '' }) readonly identifier!: string
  @Prop({ default: null }) readonly initialSearch!: string | null
  @Prop({ default: null }) readonly appendIcon!: string | null
  @Prop({ default: 'addressName' }) readonly inputValue: string | null
  @Prop({ default: null }) readonly contentWidth: string | null
  @Prop({ type: Boolean, default: false }) readonly validate!: boolean
  @Prop({ type: Boolean, default: false }) readonly validateNull!: boolean
  @Prop({ type: Boolean, default: false }) readonly public!: boolean
  @Prop({ type: Boolean, default: false }) readonly widget!: boolean
  @Prop({ required: false, default: '' }) readonly errorMessage!: boolean
  @Prop({ required: false, default: 'none' }) readonly maxHeight!: string
  @Prop({ required: false, default: colors['primary'] }) readonly color: string
  @Prop({ required: false, default: colors['primary'] })
  readonly borderColor: string
  @Prop({ required: false, default: false }) readonly useSavedStops: boolean

  @Inject({ default: null }) addresses: () => ExistingPlace[]

  loading = false
  selecting = false
  search: string | null = null
  debounce: number | null = null
  autocompleteItems: PlaceSearch[] = []
  isFocused = false
  operationsLocation = {
    lat: null,
    lng: null,
  }

  get existingAddresses(): ExistingPlace[] {
    const validAddresses = this.addresses
      ? this.addresses().filter((a) => a.description !== this.value.name)
      : []
    return this.search
      ? validAddresses.filter(
          (a) =>
            a.addressTitle?.toLowerCase().includes(this.search.toLowerCase()) ||
            a.description?.toLowerCase().includes(this.search.toLowerCase())
        )
      : validAddresses
  }

  placeSearchAdapter(companyStop: CompanyStop): PlaceSearch {
    const placeSearch = {
      description: companyStop.address.addressName,
      place: companyStop.address,
      place_id: undefined,
      matched_substrings: [],
      structured_formatting: undefined,
      terms: [],
      types: [],
      reference: undefined,
    }

    placeSearch.place = {
      ...companyStop.address,
      title: companyStop.name,
      name: companyStop.address.addressName,
      savedNotes: companyStop.notes,
    } as AddressWithSavedNotes

    return placeSearch
  }

  get savedStops(): CompanyStop[] {
    const { data } = companyStopsStore.state
    if (this.useSavedStops && data?.resultList) {
      if (this.search) {
        const lowerCaseSearch = this.search.toLowerCase()
        return data.resultList.filter(
          (stop) =>
            stop.address.addressName.toLowerCase().includes(lowerCaseSearch) ||
            stop.name.toLowerCase().includes(lowerCaseSearch)
        )
      }

      return data.resultList
    }

    return []
  }

  created(): void {
    if (this.value?.addressName) {
      this.search = this.value?.[this.inputValue] || null
    }
  }

  @Watch('existingCompany', { immediate: true })
  async handleSetCompanyData(): Promise<void> {
    if (this.existingCompany) {
      this.operationsLocation = {
        lat: this.existingCompany.address?.lat,
        lng: this.existingCompany.address?.lng,
      }
      return
    }

    const companyId = auth.getCompanyId
    const companyResponse = await companyClient.byId(companyId)
    const { lat, lng } = companyResponse.data?.company.address
    this.operationsLocation = {
      lat,
      lng,
    }
  }

  @Watch('search')
  searchChanged(value: string): void {
    if (value === this.value?.[this.inputValue]) {
      this.addressAutoComplete(value)
    }
    this.$emit('change:input', this.search)
  }

  @Watch('value', { immediate: true })
  addressNameChanged(value: Address): void {
    this.search = value?.[this.inputValue] || null
  }

  focus(): void {
    this.isFocused = true
  }

  blur(): void {
    this.isFocused = false
  }

  addressAutoComplete(input: string): void {
    if (!input) {
      this.clearPlace()
      return
    }
    if (this.debounce) {
      window.clearTimeout(this.debounce)
    }
    this.debounce = window.setTimeout(async () => {
      this.search = input
      this.loading = true
      let placeSearchResponse
      try {
        if (this.public || this.widget) {
          placeSearchResponse = await widgetService.placesBySearchTerm(
            input,
            this.operationsLocation.lat,
            this.operationsLocation.lng
          )
        } else {
          placeSearchResponse = await places.bySearchTerm(
            input,
            this.operationsLocation.lat,
            this.operationsLocation.lng
          )
        }
      } catch (e) {
        console.log(e)
      }

      this.autocompleteItems = placeSearchResponse.data || []
      this.loading = false
    }, 250)
  }

  async selectPlace(placeItem: PlaceSearch): Promise<void> {
    if (!placeItem) {
      return
    }
    this.selecting = true
    let place = null
    if (placeItem.place_id) {
      let placeResponse

      try {
        if (this.public || this.widget) {
          placeResponse = await widgetService.placesById(placeItem.place_id)
        } else {
          placeResponse = await places.byId(placeItem.place_id)
        }
      } catch (e) {
        console.error(e)
        return
      }

      const name = placeResponse.data?.addressName
      place = {
        ...placeResponse.data,
        lat: this.formatLatLng(placeResponse.data?.lat),
        lng: this.formatLatLng(placeResponse.data?.lng),
        name,
      }
    } else {
      place = placeItem.place
    }
    this.$nextTick(() => {
      this.$emit('change', place)
    })
    this.selecting = false
  }

  formatLatLng(num: number): number {
    return num ? Number(num.toFixed(Math.abs(num) < 100 ? 4 : 3)) : null
  }

  clearPlace(): void {
    this.autocompleteItems = []
    this.search = null
    this.$emit('change', null)
  }

  handleKeyEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.isFocused = false
      return
    } else {
      this.isFocused = true
    }
  }

  get validator(): ValidationRule<string>[] | string {
    const error =
      (this.validate || this.validateNull) &&
      !this.isFocused &&
      !this.selecting &&
      (!!this.search || this.validateNull) &&
      this.search !== this.value?.[this.inputValue]
    return error
      ? [(_: string) => this.errorMessage || false]
      : this.$attrs.rules
  }
}
