import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import {
  AvailabilityGetRequest,
  AvailabilityGetResponse,
  AvailabilityResponse,
  Holiday,
  SoldOut,
  AvailabilityPanelGetResponse,
} from '@/models/dto/Availability'

const httpService: HttpService = new HttpService()

export default {
  getData(
    dates: AvailabilityGetRequest,
    marketplaceOnly: boolean
  ): Promise<AxiosResponse<AvailabilityGetResponse>> {
    return httpService.post(
      `https://${apiBaseUrl()}/tripAssignments/getDispatchView?marketplaceOnly=${marketplaceOnly}`,
      dates
    )
  },
  getAvailabilityDays(
    dates: AvailabilityGetRequest
  ): Promise<AxiosResponse<AvailabilityResponse[]>> {
    let query = `startPeriod=${dates.startPeriod}&endPeriod=${dates.endPeriod}`
    query = encodeURI(query)
    const url = `https://${apiBaseUrl()}/reservations/availability?${query}`
    return httpService.get(url)
  },
  getHolidays(year: string): Promise<AxiosResponse<Holiday[]>> {
    const url = `https://date.nager.at/api/v3/PublicHolidays/${year}/US`
    return httpService.get(url)
  },
  setSoldOut(data: SoldOut): Promise<AxiosResponse> {
    const url = `https://${apiBaseUrl()}/reservations/availability/soldout`
    return httpService.put(url, data)
  },
  getPanelData(
    startPeriod: string,
    endPeriod: string
  ): Promise<AxiosResponse<AvailabilityPanelGetResponse>> {
    return httpService.get(
      `https://${apiBaseUrl()}/reservations/availability/panel?startPeriod=${startPeriod}&endPeriod=${endPeriod}`
    )
  },
}
