var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.labels.length)?_c('div',{staticClass:"padding-a-1 rounded-xl d-flex align-center justify-center position-relative",class:`background-${_vm.background}`},[(_vm.value)?_c('div',{staticClass:"border-1 border-solid margin-x-1 transition-fast-out-slow-in rounded-xl position-absolute z-1 background-white",style:(`
      height: calc(100% - 8px);
      left: ${_vm.offsetWidth}px;
      width: ${_vm.selectedWidth}px;
      border: 1px solid ${_vm.color};
    `)}):_vm._e(),_vm._l((_vm.labels),function(label,idx){return _c('CUTag',{key:`toggle-${label}-${idx}`,staticClass:"transition-fast-out-slow-in",class:{ 'cursor-pointer': !_vm.readOnly },attrs:{"id":`${label}-${_vm.id}`,"border":"transparent","background":"transparent"},on:{"click":function($event){return _vm.handleLabelClick(label, idx)}}},[(_vm.icon)?_c('CUIcon',{staticClass:"position-relative z-2",class:{
        'font-bold': label === _vm.value && _vm.boldSelected,
        'border-transparent': label !== _vm.value,
        'padding-x-3': _vm.wide,
        'margin-x-2': label,
      },style:({ color: _vm.index === idx ? _vm.color : _vm.defaultIconColor }),attrs:{"width":"22px"}},[_vm._v(" "+_vm._s(label)+" ")]):_c('span',{staticClass:"position-relative z-2 transition-fast-out-slow-in",class:{
        [`font-${_vm.size}`]: !!_vm.size,
        'font-bold': label === _vm.value && _vm.boldSelected,
        'border-transparent': label !== _vm.value,
        'padding-x-3': _vm.wide,
      },style:({ color: _vm.index === idx ? _vm.color : _vm.defaultIconColor })},[_vm._v(" "+_vm._s(label)+" ")])],1)})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }