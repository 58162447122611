import { Address, ApiResult, Group, SupportedVehicleType, UserDetail } from '.'

import { Note } from './Note'
import { UploadedFile } from './UploadedFile'

export interface UserDetailDriver extends UserDetail {
  drugTestNumber: string
  drugTestExpiration?: string
  medicalCardNumber?: string
  medicalCardExpiration?: string
  medicalCardState?: string
  firstAidCardNumber?: string
  firstAidCardExpiration?: string
  firstAidCardState?: string
  group?: Group
  isSignedUp?: boolean
  licenseState: string
  licenseNumber: string
  licenseExpirationDay: number
  licenseExpirationMonth: number
  licenseExpirationYear: number
  phone: string
  driverNotes: Partial<Note>[]
  timeZone?: string
  driverSupportedVehicles: SupportedVehicleType[]
  address: Address
  driverActivated: boolean
  driverDocuments?: UploadedFile[]
}

// eslint-disable-next-line no-redeclare
export class UserDetailDriver implements UserDetailDriver {
  constructor(driver?: Partial<UserDetailDriver>) {
    Object.assign(this, driver)
  }
}

export interface DriverResult extends ApiResult {
  driver: UserDetailDriver
}
