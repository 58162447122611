import { apiBaseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { CustomerAccountGroup } from '@/models/dto/CustomerAccount'

const httpService: HttpService = new HttpService()

export default {
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<CustomerAccountGroup>>> {
    const { sorts, filters, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }

    query = encodeURI(query)
    const url = `https://${apiBaseUrl()}/customer-account-groups?${query}`
    return httpService.get(url)
  },
}
