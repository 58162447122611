var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-menu',{attrs:{"open-on-click":false,"close-on-click":false,"content-class":(_vm.autocompleteItems.length ||
        _vm.existingAddresses.length ||
        _vm.savedStops.length) &&
      !_vm.hideOptions
        ? 'elevation-0 border-1 border-solid border-radius-12 border-gray-border-mid'
        : 'elevation-0',"nudge-bottom":_vm.widget ? '50' : '44',"max-height":_vm.maxHeight},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('CUTextField',_vm._g(_vm._b({class:`${_vm.$attrs['input-class']} ${_vm.widget ? 'v-input--widget' : ''}`,attrs:{"autofocus":_vm.autoFocus,"disabled":_vm.disabled,"value":_vm.search,"rules":_vm.validator,"label":_vm.$attrs.label && _vm.search ? _vm.$attrs.label : '',"border-color":_vm.borderColor,"autocomplete":"null"},on:{"blur":_vm.blur,"input":_vm.addressAutoComplete,"focus":_vm.focus},nativeOn:{"keyup":function($event){return _vm.handleKeyEvent.apply(null, arguments)}},scopedSlots:_vm._u([(_vm.widget && (!_vm.$attrs.label || !_vm.search))?{key:"prepend-inner",fn:function(){return [_c('CUIcon',{style:({ margin: '-1px 2px 0 0' }),attrs:{"color":"gray-icon-light","height":"15","width":"15"}},[_vm._v(" search ")])]},proxy:true}:null],null,true)},'CUTextField',_vm.$attrs,false),on)),(_vm.loading)?_c('div',[_c('v-progress-linear',{attrs:{"indeterminate":"","height":"1"}})],1):_vm._e()]}}]),model:{value:(_vm.isFocused),callback:function ($$v) {_vm.isFocused=$$v},expression:"isFocused"}},[(
        (_vm.autocompleteItems.length ||
          _vm.savedStops.length ||
          _vm.existingAddresses.length) &&
        !_vm.hideOptions
      )?_c('v-card',{staticClass:"rounded-lg v-card-borderless"},[(_vm.savedStops.length)?_c('v-list',_vm._l((_vm.savedStops),function(item,index){return _c('v-list-item',{key:`address-previous-item-${item.id}-${index}`,staticClass:"overflow-hidden text-overflow-ellipsis w-full cursor-pointer transition-ease transition-duration-200 hover:background-bg-gray-1",style:({ maxWidth: _vm.contentWidth }),attrs:{"id":`address-previous-item-${index}`},on:{"mousedown":function($event){_vm.selectPlace(_vm.placeSearchAdapter(item))}}},[_c('div',{staticClass:"d-flex align-center justify-center h-20 w-20 margin-r-2",style:({ marginLeft: '2px' })},[_c('CUIcon',{staticClass:"position-absolute",attrs:{"aria-label":"Saved Stop","height":"34","width":"34","color":_vm.color}},[_vm._v(" star ")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" - "+_vm._s(item.address.addressName)+" ")])],1)}),1):_vm._e(),(
          _vm.savedStops.length &&
          (_vm.autocompleteItems.length || _vm.existingAddresses.length)
        )?_c('div',{staticClass:"margin-x-4"},[_c('v-divider')],1):_vm._e(),(_vm.existingAddresses.length)?_c('v-list',_vm._l((_vm.existingAddresses),function(item,index){return _c('v-list-item',{key:`address-previous-item-${item.stopIndex}-${index}`,staticClass:"overflow-hidden text-overflow-ellipsis w-full cursor-pointer transition-ease transition-duration-200 hover:background-bg-gray-1",style:({ maxWidth: _vm.contentWidth }),attrs:{"id":`address-previous-item-${index}`},on:{"mousedown":function($event){return _vm.selectPlace(item)}}},[_c('div',{staticClass:"d-flex align-center justify-center h-20 w-20 margin-r-2",style:({ marginLeft: '2px' })},[_c('CUIcon',{staticClass:"position-absolute",attrs:{"aria-label":"Existing Address","height":"20","width":"20","color":_vm.color}},[_vm._v(" place_filled ")]),_c('p',{staticClass:"z-10 font-12 font-bold text-white",style:({ marginTop: '-3px' })},[_vm._v(" "+_vm._s(item.stopIndex)+" ")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(item.addressTitle)+" - "+_vm._s(item.description)+" ")])],1)}),1):_vm._e(),(_vm.existingAddresses.length && _vm.autocompleteItems.length)?_c('div',{staticClass:"margin-x-4"},[_c('v-divider')],1):_vm._e(),(_vm.autocompleteItems.length)?_c('v-list',_vm._l((_vm.autocompleteItems),function(item,index){return _c('v-list-item',{key:`address-autocomplete-item-${item.place_id}-${index}`,staticClass:"overflow-hidden text-overflow-ellipsis w-full cursor-pointer transition-ease transition-duration-200 hover:background-bg-gray",style:({ maxWidth: _vm.contentWidth }),attrs:{"id":`address-autocomplete-item-${index}`},on:{"mousedown":function($event){return _vm.selectPlace(item)}}},[_c('CUIcon',{staticClass:"margin-r-2",attrs:{"aria-label":"Address","color":_vm.color}},[_vm._v(" place ")]),_c('v-list-item-title',[_vm._v(" "+_vm._s(item.description)+" ")])],1)}),1):_vm._e()],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }