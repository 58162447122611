import { ACCESS_SETTINGS_ROLES } from '@/models/AccessSettings'
import Router from '@/router'
import auth from '@/store/modules/auth'
import { Location, RawLocation } from 'vue-router'

export const openInNewTab = (location: Location | RawLocation): void => {
  const routeData = Router.resolve(location)
  if (routeData) {
    window.open(routeData.href, '_blank')
  }
}

export const beforeEnterCheckAccess = (role: string, next): void => {
  if (!auth.getUserRoleNames.includes(role)) {
    next({ name: 'not-found' })
  } else {
    next()
  }
}

export const handleHomepageRedirect = (): string => {
  const roleNames = auth.getUserRoleNames
  if (roleNames.includes(ACCESS_SETTINGS_ROLES.QUOTES)) {
    return 'quotes'
  } else if (roleNames.includes(ACCESS_SETTINGS_ROLES.RESERVATIONS)) {
    return 'reservations'
  } else if (roleNames.includes(ACCESS_SETTINGS_ROLES.DISPATCH)) {
    return 'dispatch'
  } else if (roleNames.includes(ACCESS_SETTINGS_ROLES.CONTACTS)) {
    return 'contacts'
  } else if (roleNames.includes(ACCESS_SETTINGS_ROLES.OPERATIONS)) {
    return 'operations'
  }

  return 'settings'
}
