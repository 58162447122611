import { Trip, TripCharge, TripChargeType, TripEstimation } from '@/models/dto'
import dayjs from 'dayjs'
import { isNotEmpty } from '@/utils/validators'
import { LineItemSectionTypes, TripChargeTypes } from '@/utils/enum'
import { upsert } from '@/utils/array'
import {
  lineItemChargesBySectionType,
  lineItemChargeTotal,
} from '@/utils/charge'
import tripClient from '@/services/trip'
import {
  getTripEstimations,
  getTripPricing as getQuoteTripPricing,
} from '@/utils/quote'
import { Quote } from '@/models/dto/Quote'
import { TripPricing } from '@/models/dto/Pricing'
import currency from 'currency.js'
import {
  LineItemCharge,
  LineItemSectionType,
} from '@/models/dto/LineItemCharge'
import { TripPricingDetail } from '@/models/dto/TripPricingDetail'

export const tripPickupTime = (trip: Trip): string => {
  if (!trip) {
    return ''
  }
  return (trip.stops || [])
    .map((stop) => stop.pickupDatetime)
    .filter(isNotEmpty)
    .map(dayjs)
    .reduce(
      (acc, time) =>
        acc === '' || time.isBefore(dayjs(acc)) ? time.toISOString() : acc,
      ''
    )
}

export const tripPickupTimeZone = (trip: Trip): string => {
  return (trip.stops || []).filter((stop) => !!stop.address)?.[0]?.address
    ?.timeZone
}

export const tripDropoffTime = (trip: Trip): string => {
  if (!trip) {
    return ''
  }
  return (trip.stops || [])
    .map((stop) => stop.dropoffDatetime)
    .filter(isNotEmpty)
    .map(dayjs)
    .reduce(
      (acc, time) =>
        acc === '' || time.isAfter(dayjs(acc)) ? time.toISOString() : acc,
      ''
    )
}

export const addLineItemChargesToTrip = (
  trip: Trip,
  charges: LineItemCharge[]
): Trip => {
  for (const charge of charges) {
    trip.lineItemCharges.push({
      ...charge,
      lineNumber: trip.lineItemCharges.length,
    })
  }

  return trip
}

export const updateLineItemChargeOnTrip = (
  trip: Trip,
  chargeIdx: number,
  charge: LineItemCharge,
  section: LineItemSectionType
): Trip => {
  let count = 0
  for (let i = 0; i < trip.lineItemCharges.length; i++) {
    if (trip.lineItemCharges[i].lineItemSectionTypeId === section.id) {
      if (count === chargeIdx) {
        trip.lineItemCharges.splice(i, 1, charge)
        break
      }

      count++
    }
  }

  return trip
}

export const deleteLineItemChargeOnTrip = (
  trip: Trip,
  chargeIdx: number,
  section: LineItemSectionType
): Trip => {
  let count = 0
  for (let i = 0; i < trip.lineItemCharges.length; i++) {
    if (trip.lineItemCharges[i].lineItemSectionTypeId === section.id) {
      if (count === chargeIdx) {
        trip.lineItemCharges.splice(i, 1)
        break
      }

      count++
    }
  }

  return trip
}

export const setTripCharge = (
  trip: Trip,
  chargeType: TripChargeType,
  amount: number
): Trip => {
  const chargeTypeId = chargeType?.id
  const type = TripChargeTypes.BASE_FARE
  const predicate = (charge: TripCharge) => charge.chargeType?.key === type
  const charge: TripCharge = {
    tripChargeId: null,
    tripId: trip.tripId,
    amount,
    chargeType,
    chargeTypeId,
  }
  const charges = upsert(trip.charges, charge, predicate)
  return { ...trip, charges }
}

export const getTripBaseFare = (trip: Trip): number => {
  const type = TripChargeTypes.BASE_FARE
  const predicate = (charge) => charge.chargeType?.key === type
  return currency(trip?.charges?.find(predicate)?.amount || 0).value
}

export const getTripTotalBaseFare = (trip: Trip): number => {
  const charges = lineItemChargesBySectionType(
    trip,
    LineItemSectionTypes.BASE_FARE
  )
  const baseAmount = getTripBaseFare(trip)
  const chargeAmount = lineItemChargeTotal(charges, getTripBaseFare(trip))
  return currency(baseAmount).add(chargeAmount).value
}

export const getTripSubtotal = (trip: Trip): number => {
  const charges = lineItemChargesBySectionType(
    trip,
    LineItemSectionTypes.ITEMIZED_CHARGE
  )
  const baseAmount = getTripTotalBaseFare(trip)
  const chargeAmount = lineItemChargeTotal(charges, baseAmount)
  return currency(baseAmount).add(chargeAmount).value
}

export const getTripTotal = (trip: Trip): number => {
  const charges = lineItemChargesBySectionType(
    trip,
    LineItemSectionTypes.SUBTOTAL
  )
  const subtotal = getTripSubtotal(trip)
  const chargeAmount = lineItemChargeTotal(charges, subtotal)
  return currency(subtotal).add(chargeAmount).value
}

export const getRecurringTripTotal = (trip: Trip): number => {
  const total = getTripTotal(trip)
  const tripCount = trip.recurrenceTripCount || 1
  return currency(total).multiply(tripCount).value
}

export const getTripAmountDueNow = (trip: Trip): number => {
  if (trip.amountDueNow) {
    return trip.amountDueNow
  } else if (trip.depositAmount) {
    return currency(trip.depositAmount).divide(trip.recurrenceTripCount || 1)
      .value
  }
  const total = getTripTotal(trip)
  const depositPercentage = currency(trip.depositPercentage).divide(100)
  return currency(total).multiply(depositPercentage).value
}

export const getRecurringAmountDueNow = (
  trip: Trip,
  overrideAmount = false
): number => {
  if ((trip.recurringAmountDueNow || trip.depositAmount) && !overrideAmount) {
    return trip.recurringAmountDueNow || trip.depositAmount
  }
  const recurringTripTotal = getRecurringTripTotal(trip)
  const depositPercentage = currency(trip.depositPercentage).divide(100)
  return currency(recurringTripTotal).multiply(depositPercentage).value
}

export const getRecurringAmountDueLater = (trip: Trip): number => {
  const recurringTripTotal = getRecurringTripTotal(trip)
  const recurringAmountDueNow = getRecurringAmountDueNow(trip)
  return currency(recurringTripTotal).subtract(recurringAmountDueNow).value
}

export const getTripById = async (tripId: number): Promise<Trip> => {
  const res = await tripClient.byId(tripId)
  return res.data.trip
}

export const getTripEstimation = async (
  trip: Trip
): Promise<TripEstimation> => {
  const quote = new Quote({ trips: [trip] })
  const res = await getTripEstimations(quote)
  return res?.shift()
}

export const getTripPricing = async (trip: Trip): Promise<TripPricing> => {
  const quote = new Quote({ trips: [trip] })
  const res = await getQuoteTripPricing(quote)
  return res.pricings?.shift()
}

export const shouldFetchTripPricing = (trip: Trip): boolean => {
  if (!trip) {
    return false
  }
  // Trip must have at least one stop with an address
  const stopsWithAddresses = trip.stops?.filter((stop) => !!stop.address)
  if (!stopsWithAddresses?.length) {
    return false
  }

  if (trip.passengerCount == null || trip.requiredDrivers == null) {
    return false
  }

  return true
}

export const getPreviousSectionTotal = (
  section: LineItemSectionType,
  tripPricingDetail: TripPricingDetail
): number => {
  switch (section.key) {
    case LineItemSectionTypes.BASE_FARE:
      return tripPricingDetail.tripBaseFare
    case LineItemSectionTypes.ITEMIZED_CHARGE:
      return tripPricingDetail.sections['base_fare'].total
    case LineItemSectionTypes.SUBTOTAL:
      return tripPricingDetail.sections['itemized_charge'].total
    default:
      return 0
  }
}
