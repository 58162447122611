import { Action, Module, VuexModule } from 'vuex-class-modules'
import store from '@/store'
import { Quote } from '@/models/dto/Quote'
import { Trip, TripEstimation } from '@/models/dto'
import { Pricing, TripPricing } from '@/models/dto/Pricing'
import { QuotePricing } from '@/models/QuotePricing'
import { TripPricings } from '@/models/TripPricings'
import { QuoteInformation } from '@/models/QuoteInformation'
import {
  convertTripPricingToBaseFarePricing,
  isPricingSelectionIdHighest,
  pricingSelectionIdToRateTypes,
  quotePricing,
} from '@/utils/pricing'
import { distinctBy } from '@/utils/reducers'
import { PricingSelectionId, TripChargeTypes } from '@/utils/enum'
import typeStore from '@/store/modules/types'
import {
  addLineItemChargesToTrip,
  deleteLineItemChargeOnTrip,
  getRecurringAmountDueNow,
  getRecurringTripTotal,
  getTripAmountDueNow,
  getTripBaseFare,
  getTripTotal,
  setTripCharge,
  updateLineItemChargeOnTrip,
} from '@/utils/trip'
import { File as CustomFile } from '@/models/dto/File'
import quoteClient from '@/services/quotes'
import tripClient from '@/services/trip'
import { TripPricingDetailRequest } from '@/models/dto/TripPricingDetail'
import {
  LineItemCharge,
  LineItemSectionType,
} from '@/models/dto/LineItemCharge'
import { BASE_FARE_CHARGE_TYPE } from '@/utils/charge'

@Module({ generateMutationSetters: true })
class QuoteModule extends VuexModule {
  _quote: Quote = null
  _estimations: Record<string, TripEstimation> = null
  _pricing: QuotePricing = null
  _modified = false
  _disableQuickbooksInvoiceCreation = false

  getTripPricings(tripIdx: number): TripPricings {
    const trip: Trip = this.trips[tripIdx]
    const tripPricings = trip.tripPricings

    const pricingDaily: TripPricing = this.pricing.daily[trip?.tripId]
    const pricingHourly: TripPricing = this.pricing.hourly[trip?.tripId]
    const pricingMileage: TripPricing = this.pricing.mileage[trip?.tripId]

    if (tripPricings) {
      let tripPricingDaily = tripPricings.find(
        (tp) => tp.pricingTypeKey === 'daily'
      ) as any
      let tripPricingHourly = tripPricings.find(
        (tp) => tp.pricingTypeKey === 'hourly'
      ) as any
      let tripPricingMileage = tripPricings.find(
        (tp) => tp.pricingTypeKey === 'mileage'
      ) as any

      tripPricingDaily = convertTripPricingToBaseFarePricing(
        tripPricingDaily,
        pricingDaily
      )
      tripPricingHourly = convertTripPricingToBaseFarePricing(
        tripPricingHourly,
        pricingHourly
      )
      tripPricingMileage = convertTripPricingToBaseFarePricing(
        tripPricingMileage,
        pricingMileage
      )

      if (tripPricingDaily && tripPricingHourly && tripPricingMileage) {
        return {
          pricingDaily: tripPricingDaily,
          pricingHourly: tripPricingHourly,
          pricingMileage: tripPricingMileage,
        }
      }
    }

    return { pricingDaily, pricingHourly, pricingMileage }
  }

  get quote(): Quote {
    return this._quote
  }

  get trips(): Trip[] {
    return this._quote?.trips || []
  }

  get estimations(): Record<string, TripEstimation> {
    return this._estimations || {}
  }

  get pricing(): QuotePricing {
    return this._pricing || { daily: {}, hourly: {}, mileage: {} }
  }

  get modified(): boolean {
    return this._modified
  }

  get disableQuickbooksInvoiceCreation(): boolean {
    return this._disableQuickbooksInvoiceCreation
  }

  @Action
  setQuoteInformation(quoteInformation: QuoteInformation): void {
    this.setQuote(quoteInformation.quote)
    this.setTripEstimations(quoteInformation.estimations)
    this.setPricing(quoteInformation.pricing)
    this._modified = false
    this._disableQuickbooksInvoiceCreation = false
  }

  @Action
  setQuote(quote: Quote): void {
    this._quote = quote
  }

  @Action
  setTripEstimations(tripEstimations: TripEstimation[]): void {
    const estimations = tripEstimations || []
    this._estimations = estimations.reduce(distinctBy('tripId'), {})

    for (const [tripIdx, trip] of Object.entries(this.trips)) {
      const tripEstimation = estimations.find(
        (estimation) => estimation.tripId === trip.tripId
      )

      if (tripEstimation) {
        trip.distance = tripEstimation.distance
        trip.drivingTime = tripEstimation.duration

        this.updateTrip({ tripIdx: Number(tripIdx), trip, silent: true })
      }
    }
  }

  @Action
  setPricing(pricing: Pricing): void {
    this._pricing = quotePricing(pricing)

    for (const [tripIdx, trip] of Object.entries(this.trips)) {
      // If neither Highest/Choose is selected, or Choose is selected but neither Daily/Hourly/Mileage
      // are not selected, don't update the base fare on the trip
      const pricingSelectionId = trip.pricingSelectionId || 1
      const isOverrideSelected =
        trip.pricingSelectionId === PricingSelectionId.ChooseOverride

      if (isOverrideSelected) {
        trip.recurringAmountDueNow = null
        trip.amountDueNow = null
        trip.recurringAmountDueNow = getRecurringAmountDueNow(trip)
        trip.amountDueNow = getTripAmountDueNow(trip)

        this.updateTrip({
          tripIdx: Number(tripIdx),
          trip,
          silent: true,
        })
        continue
      }

      const tripPricing = this.getTripPricings(Number(tripIdx))

      const dailyPricing = tripPricing.pricingDaily?.baseAmount || 0
      const hourlyPricing = tripPricing.pricingHourly?.baseAmount || 0
      const mileagePricing = tripPricing.pricingMileage?.baseAmount || 0

      let baseFare = 0
      // If the pricing method is highest, return the highest of daily/hourly/mileage
      // pricing -- otherwise, return the sum of whichever are selected
      if (isPricingSelectionIdHighest(pricingSelectionId)) {
        baseFare = Math.max(dailyPricing, hourlyPricing, mileagePricing)
      } else {
        let sum = 0
        const rateTypes = pricingSelectionIdToRateTypes(pricingSelectionId)
        const rateTypeKeys = rateTypes.map(({ key }) => key)
        if (rateTypeKeys.includes('daily')) {
          sum += dailyPricing
        }
        if (rateTypeKeys.includes('hourly')) {
          sum += hourlyPricing
        }
        if (rateTypeKeys.includes('mileage')) {
          sum += mileagePricing
        }
        baseFare = sum
      }

      // Update the base fare charge with the new baseFare, and set all lineItemCharges
      // and markups to active
      const type = TripChargeTypes.BASE_FARE
      const chargeType = typeStore.tripChargeTypes.find((c) => c.key === type)

      if (!chargeType) {
        return
      }

      const tripWithCharge = setTripCharge(trip, chargeType, baseFare)
      const lineItemCharges = tripWithCharge.lineItemCharges.map((c) => ({
        ...c,
        isActive: true,
      }))

      const updatedTrip: Trip = {
        ...tripWithCharge,
        lineItemCharges,
        recurringAmountDueNow: null,
        amountDueNow: null,
      }

      // Update the totals and amountsDue, both for the single trip and for the recurringTrip
      updatedTrip.total = getTripTotal(updatedTrip)
      updatedTrip.recurringTripTotal = getRecurringTripTotal(updatedTrip)

      updatedTrip.recurringAmountDueNow = getRecurringAmountDueNow(updatedTrip)
      updatedTrip.amountDueNow = getTripAmountDueNow(updatedTrip)
      updatedTrip.depositAmount = getRecurringAmountDueNow(updatedTrip)

      this.updateTrip({
        tripIdx: Number(tripIdx),
        trip: updatedTrip,
        silent: true,
      })
    }
  }

  @Action
  async loadTripEstimations(): Promise<void> {
    try {
      const res = await quoteClient.tripEstimations(this._quote)
      this.setTripEstimations(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  @Action
  clear(): void {
    this._quote = null
    this._estimations = null
    this._pricing = null
    this._modified = false
  }

  @Action
  updateQuote(quote: Partial<Quote>): void {
    this._quote = {
      ...this._quote,
      ...quote,
    }
    this._modified = true
  }

  @Action
  updateAllTrips(trip: Partial<Trip>): void {
    this._quote.trips = this._quote.trips.map((t) => {
      return { ...t, ...trip }
    })
  }

  @Action
  updateTrip({
    tripIdx,
    trip,
    silent = false,
  }: {
    tripIdx: number
    trip: Partial<Trip>
    silent?: boolean
  }): void {
    const existing: Trip | undefined = this.trips[tripIdx]
    if (!existing) {
      return
    }

    const trips = [...this.trips]
    trips.splice(tripIdx, 1, { ...existing, ...trip })
    this._quote = { ...this._quote, trips }
    this._modified = this._modified || !silent
  }

  @Action
  addTrip(trip: Trip): void {
    this._quote = {
      ...this._quote,
      trips: [...this.trips, trip],
    }
    this._modified = true
  }

  @Action
  deleteTrip({ tripIdx }: { tripIdx: number }): void {
    const trip: Trip | undefined = this.trips[tripIdx]
    if (!trip) {
      return
    }

    const trips = [...this.trips]
    trips.splice(tripIdx, 1)
    this._quote = { ...this._quote, trips }
    this._modified = true
  }

  @Action
  setModified(modified: boolean) {
    this._modified = modified
  }

  @Action
  setDisableQuickbooksInvoiceCreation(disabled: boolean) {
    this._disableQuickbooksInvoiceCreation = disabled
  }

  @Action
  updateFiles(files: CustomFile[]) {
    this._quote.files = files
  }

  @Action
  updateTripBaseFare({ tripIdx, baseFare, pricingSelectionId }) {
    let trip = this.trips[tripIdx]
    trip = setTripCharge(trip, BASE_FARE_CHARGE_TYPE, baseFare)

    trip.pricingSelectionId = pricingSelectionId
    trip.tripPricingDetail.tripBaseFare = baseFare

    for (const lineItemCharge of trip.lineItemCharges) {
      if (lineItemCharge.lineItemSectionTypeId === 1) {
        lineItemCharge.isActive =
          pricingSelectionId !== PricingSelectionId.ChooseOverride
      }
    }

    this.updateTrip({
      tripIdx,
      trip,
    })

    this.updateTripPricingByChargesAndBaseFare({
      tripIdx,
      tripBaseFare: baseFare,
      lineItemCharges: trip.lineItemCharges,
    })
  }

  @Action
  addCharges({ tripIdx, charges }) {
    // Add each charge into the line item charges
    let trip = this.trips[tripIdx]
    trip = addLineItemChargesToTrip(trip, charges)

    this.updateTrip({ tripIdx, trip })

    this.updateTripPricingByChargesAndBaseFare({
      tripIdx,
      lineItemCharges: trip.lineItemCharges,
    })
  }

  @Action
  addCharge({ tripIdx, charge, section }) {
    let trip = this.trips[tripIdx]
    trip = addLineItemChargesToTrip(trip, [charge])
    this.updateTrip({ tripIdx, trip })

    this.updateTripPricingByChargesAndBaseFare({
      tripIdx,
      lineItemCharges: trip.lineItemCharges,
    })
  }

  @Action
  updateCharge({ tripIdx, chargeIdx, charge, section }) {
    let trip = this.trips[tripIdx]
    trip = updateLineItemChargeOnTrip(trip, chargeIdx, charge, section)

    this.updateTrip({ tripIdx, trip })
    this.updateTripPricingByChargesAndBaseFare({
      tripIdx,
      lineItemCharges: trip.lineItemCharges,
    })
  }

  @Action
  deleteCharge({
    tripIdx,
    chargeIdx,
    section,
  }: {
    tripIdx: number
    chargeIdx: number
    section: LineItemSectionType
  }) {
    let trip = this.trips[tripIdx]
    trip = deleteLineItemChargeOnTrip(trip, chargeIdx, section)

    // Update trip pricing detail
    const tripPricingDetail = trip.tripPricingDetail
    let sectionCharges = tripPricingDetail.sections[section.key].charges
    sectionCharges = sectionCharges.filter((_, i) => i !== chargeIdx)
    tripPricingDetail.sections[section.key].charges = sectionCharges

    this.updateTrip({
      tripIdx,
      trip: { ...trip, tripPricingDetail },
    })
    this.updateTripPricingByChargesAndBaseFare({
      tripIdx,
      lineItemCharges: trip.lineItemCharges,
    })
  }

  @Action
  async updateTripPricingByChargesAndBaseFare({
    tripIdx,
    lineItemCharges,
    tripBaseFare = null,
  }: {
    tripIdx: number
    lineItemCharges: LineItemCharge[]
    tripBaseFare?: number
  }) {
    const trip = this.trips[tripIdx]
    if (!trip) {
      return
    }
    tripBaseFare = tripBaseFare || getTripBaseFare(trip)

    await this.retrieveAndUpdateTripPricing({
      tripIdx,
      trip,
      charges: lineItemCharges,
      tripBaseFare,
    })
  }

  @Action
  async updateTripPricingByTripIndex({ tripIdx }): Promise<void> {
    const trip = this.trips[tripIdx]
    if (!trip) {
      return
    }

    const tripBaseFare = getTripBaseFare(trip)
    const charges = trip.lineItemCharges

    await this.retrieveAndUpdateTripPricing({
      tripIdx,
      trip,
      charges,
      tripBaseFare,
    })
  }

  @Action
  async retrieveAndUpdateTripPricing({ tripIdx, trip, charges, tripBaseFare }) {
    const request: TripPricingDetailRequest = {
      tripBaseFare,
      charges,
    }
    const response = await tripClient.getTripPricingDetail(request)
    const tripPricingDetail = (response.data as any).pricing
    this.updateTrip({ tripIdx, trip: { ...trip, tripPricingDetail } })
  }
}

export default new QuoteModule({ store, name: 'quote' })
