var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-btn',_vm._g(_vm._b({staticClass:"cu-button font-bold font-14",class:_vm.classes,style:(_vm.inlineStyles),attrs:{"small":"","color":_vm.color,"plain":_vm.opaque,"height":_vm.calculatedHeight},scopedSlots:_vm._u([(_vm.$scopedSlots.loader)?{key:"loader",fn:function(){return [_vm._t("loader")]},proxy:true}:null],null,true)},'v-btn',_vm.$attrs,false),_vm.$listeners),[_c('span',{class:{
      'd-flex': _vm.hasPrependIcon,
      'margin-r-2': _vm.hasPrependIcon && !_vm.small,
      'margin-r-1': _vm.hasPrependIcon && _vm.small,
    }},[_vm._t("prepend-icon")],2),_vm._t("default"),_c('span',{class:{
      'd-flex': _vm.hasAppendIcon,
      'margin-l-2': _vm.hasAppendIcon && !_vm.small,
      'margin-l-1': _vm.hasAppendIcon && _vm.small,
    }},[_vm._t("append-icon")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }