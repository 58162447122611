var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"padding-r-2",class:{
    'margin-l-5': _vm.title || _vm.stop,
    'max-w-full flex-shrink-1': _vm.addressMaxWidth,
    'flex-shrink-0': !_vm.addressMaxWidth,
  },style:({ minWidth: _vm.minWidth })},[_c('div',{style:({ maxWidth: 'fit-content' })},[(_vm.stopTitle)?_c('p',{staticClass:"font-semibold margin-b-1"},[_vm._v(" "+_vm._s(_vm.stopTitle)+" ")]):_vm._e()]),(_vm.stop && _vm.stop.address)?_c('p',{staticClass:"margin-b-1"},[_vm._v(" "+_vm._s(_vm.stop.address.name)+" ")]):(_vm.garage)?[(_vm.garage.address)?_c('p',{staticClass:"margin-b-1"},[_vm._v(" "+_vm._s(_vm.garage.name || _vm.garage.garageName)+" ")]):_vm._e()]:_vm._e(),(_vm.stop)?[(_vm.simpleNote)?_c('div',{class:{ 'blue-link': _vm.blueLink },style:({ maxWidth: `${_vm.noteMaxWidth}px` })},[_c('div',{staticClass:"d-flex z-5 margin-r-5"},[_c('div',{staticClass:"d-flex flex-grow-1 justify-space-between"},[_c('div',{staticClass:"text-gray-text-darkest margin-b-0 border-radius-5",class:{
              'overflow-hidden h-20': !_vm.expanded,
              'h-full': _vm.expanded,
            },style:({
              maxWidth: 'fit-content',
              lineBreak: _vm.expanded ? 'auto' : 'anywhere',
            })},[_c('div',{staticClass:"text-gray-text-darkest font-14",domProps:{"innerHTML":_vm._s(_vm.expanded ? _vm.fullStopNote : _vm.simpleNote)}})]),(_vm.formattedNotes.canExpand)?_c('CUIcon',{staticClass:"cursor-pointer min-w-20 margin-l-1 transition-property-transform transition-duration-300 transition-timing-function-ease border-radius-round hover:background-primary-10",class:{
              'rotate-n90': _vm.expanded,
            },attrs:{"color":"black","height":"20px","width":"20px"},on:{"click":function($event){return _vm.handleShowNote(_vm.formattedNotes)}}},[_vm._v(" keyboard_arrow_right ")]):_vm._e()],1),(_vm.showEditNote)?_c('div',{staticClass:"h-20 margin-r-n7 margin-l-2 cursor-pointer transition-property-transform transition-duration-300 transition-timing-function-ease border-radius-round hover:background-primary-10",on:{"click":function($event){return _vm.$emit('add-note')}}},[_c('CUIcon',{attrs:{"height":"20px","width":"20px","color":"gray-icon-light"}},[_vm._v(" edit ")])],1):_vm._e()])]):(!_vm.hideAddNotes)?_c('div',[_c('CUButton',{staticClass:"margin-l-n2",attrs:{"small":"","opaque":"","width":"106px","height":"24px","color":"secondary"},on:{"click":function($event){return _vm.$emit('add-note')}},scopedSlots:_vm._u([{key:"prepend-icon",fn:function(){return [_c('CUIcon',{attrs:{"width":"20","height":"20","color":"secondary"}},[_vm._v("add")])]},proxy:true}],null,false,4160072890)},[_vm._v(" Add Notes ")])],1):_vm._e()]:(_vm.garage)?[_c('div',{staticClass:"z-5"},[(_vm.simpleNote)?_c('div',{class:{ 'blue-link': _vm.blueLink },style:({ maxWidth: `${_vm.noteMaxWidth}px` })},[_c('div',{staticClass:"d-flex z-5 margin-r-5"},[_c('div',{staticClass:"d-flex flex-grow-1 justify-space-between"},[_c('div',{staticClass:"text-gray-text-darkest margin-b-0 border-radius-5",class:{
                'overflow-hidden h-20': !_vm.expanded,
                'h-full': _vm.expanded,
              },style:({
                maxWidth: 'fit-content',
                lineBreak: _vm.expanded ? 'normal' : 'anywhere',
              })},[_c('div',{staticClass:"text-gray-text-darkest font-14",domProps:{"innerHTML":_vm._s(_vm.expanded ? _vm.fullStopNote : _vm.simpleNote)}})]),(_vm.formattedNotes.canExpand)?_c('CUIcon',{staticClass:"cursor-pointer min-w-20 margin-l-1 transition-property-transform transition-duration-300 transition-timing-function-ease border-radius-round hover:background-primary-10",class:{
                'rotate-n90': _vm.expanded,
              },attrs:{"color":"black","height":"20px","width":"20px"},on:{"click":function($event){return _vm.handleShowNote(_vm.formattedNotes)}}},[_vm._v(" keyboard_arrow_right ")]):_vm._e()],1),(_vm.showEditNote)?_c('div',{staticClass:"h-20 margin-r-n7 margin-l-2 cursor-pointer transition-property-transform transition-duration-300 transition-timing-function-ease border-radius-round hover:background-primary-10",on:{"click":function($event){return _vm.$emit('add-note')}}},[_c('CUIcon',{attrs:{"height":"20px","width":"20px","color":"gray-icon-light"}},[_vm._v(" edit ")])],1):_vm._e()])]):_c('div',[_c('CUButton',{staticClass:"margin-l-n2",attrs:{"small":"","opaque":"","width":"106px","height":"24px","color":"secondary"},on:{"click":function($event){return _vm.$emit('add-note')}},scopedSlots:_vm._u([{key:"prepend-icon",fn:function(){return [_c('CUIcon',{attrs:{"width":"20","height":"20","color":"secondary"}},[_vm._v("add")])]},proxy:true}])},[_vm._v(" Add Notes ")])],1)])]:_vm._e(),_c('div',{ref:_vm.ref,style:({ width: 'fit-content' })},_vm._l((_vm.addresses),function(address,n){return _c('p',{key:`width-address-${address}-${n}`,staticClass:"margin-a-0 h-0 opacity-0",style:({ maxWidth: 'fit-content' })},[_vm._v(" "+_vm._s(address)+" ")])}),0)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }