import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'

const SITE_KEY = '6Lf5zAokAAAAAAQ5mfrnUd7LEdR0lnKhej0k68ug'

Vue.use(VueReCaptcha, {
  siteKey: SITE_KEY,
  loaderOptions: {
    autoHideBadge: true,
  },
})
