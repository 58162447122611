export interface GenericStateStatus<T> {
  data: T
  hasLoaded: boolean
  loading: boolean
  error: boolean
}

export const DEFAULT_STATE_STATUS = {
  data: null,
  hasLoaded: false,
  loading: false,
  error: false,
}
