
import { predefinedDateValues } from '@/data/datePicker'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class CUDatePickerPredefined extends Vue {
  @Prop({ required: false, type: String }) value!: string
  @Prop({
    required: false,
    default: () => predefinedDateValues.map((v) => v.key),
  })
  predefinedDateKeys!: string[]

  get predefinedDates(): { key: string; label: string }[] {
    return predefinedDateValues.filter((val) =>
      this.predefinedDateKeys.includes(val.key)
    )
  }

  handleInput(key: string): void {
    this.$emit('input', key)
    this.$emit(key)
  }
}
